import { Button, Popover, TextField, Tabs, Tab, Switch, FormControlLabel, FormGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { SECONDARY_COLOR } from '../../constants';
import _ from "lodash"
// import { Document, Page } from 'react-pdf';
import { getPdfUrl } from './formDownload';

const getFormName = (fileOb: string) => fileOb.split("--")[1]


export default function ViewSubmittedFormsAndApprove(props: {
    task: any,
    onReject: (rejectedReason: any, addedComment: any) => void,
    onApprove: (rejectedReason: any, addedComment: any) => void,
}) {
    const { allTaskData } = props.task
    const uploadedForms = allTaskData?.form_data || []
    const [rejectReason, setRejectReason] = useState({} as any)
    const [comment, setComment] = useState({} as any)

    const [tabI, setTabI] = React.useState(0);
    const handleChange = (event: any, newValue: number) => {
        setTabI(newValue);
    };
    const [rejectedForms, setRejectedForms] = useState({} as any)

    const selectedForm = uploadedForms[tabI]
    const url = selectedForm ? getPdfUrl(selectedForm) : ''
    useEffect(() => {
        setTabI(0)
    }, [allTaskData])

    const handleClickToggleReject = (checking: any) => {
        if(checking){
            setComment({ ...comment, [selectedForm]: "" })
        } else {
            setRejectReason({ ...rejectReason, [selectedForm]: "" })
        }
        setRejectedForms({ ...rejectedForms, [selectedForm]: !rejectedForms[selectedForm] })
    }

    const handleReject = () => {
        const comments = {} as any
        const rejectResp = {} as any
        
        Object.keys(rejectedForms).forEach(k => {
            if(rejectedForms[k]){
                rejectResp[k] = rejectReason[k] || true
            }
        })

        uploadedForms.forEach((k: any) => {
            if(!rejectedForms[k] && typeof(comment[k]) === 'string' && comment[k] !== "" ){
                comments[k] = comment[k]
            }
        })
        //console.log(rejectResp)
        props.onReject(rejectResp, comments)
    }

    const handleApprove = () => {
        const comments = {} as any
        const rejectResp = {} as any
        
        Object.keys(rejectedForms).forEach(k => {
            if(rejectedForms[k]){
                rejectResp[k] = rejectReason[k] || true
            }
        })

        uploadedForms.forEach((k: any) => {
            if(!rejectedForms[k] && typeof(comment[k]) === 'string' && comment[k] !== "" ){
                comments[k] = comment[k]
            }
        })
        // console.log(rejectResp)
        // console.log(comments)
        props.onApprove(rejectResp, comments)
    }

    return (
        <div>
            <Tabs
                value={selectedForm}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleChange}
            >
                {uploadedForms.map((f: any, i: number) => <Tab id={f} style={{
                    borderBottomColor: selectedForm == f ? SECONDARY_COLOR : 'transparent',
                    borderBottomStyle: 'solid', borderBottomWidth: 2
                }} label={`${i + 1}. ${getFormName(f)}`}
                    {...{ 'aria-controls': f }} />)}
            </Tabs>
            {selectedForm != '' && <div style={{ marginTop: 5 }}>
                <p>{selectedForm} : <a href={url} target="_blank" download="true">Download</a></p>
                {/* <Document file={{url:"https://www.orimi.com/pdf-test.pdf"}} /> */}
                <iframe style={{ height: '40vh', width: '100%' }} src={url} />
                <br />
                <div style={{ textAlign: 'right' }}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch onChange={e => handleClickToggleReject(e.target.checked)} checked={!!rejectedForms[selectedForm]} />
                        } label="Reject form" />
                    </FormGroup>
                    {rejectedForms[selectedForm] && <TextField
                        key={selectedForm}
                        id="outlined-multiline-flexible"
                        label="Reason to reject"
                        multiline
                        onChange={e => setRejectReason({ ...rejectReason, [selectedForm]: e.target.value })}
                        value={rejectReason[selectedForm]}
                    />}
                    {!rejectedForms[selectedForm] && <TextField
                        key={selectedForm}
                        id="outlined-multiline-flexible"
                        label="Comment"
                        multiline
                        onChange={e => setComment({ ...comment, [selectedForm]: e.target.value })}
                        value={comment[selectedForm]}
                    />}
                </div>
            </div>}
            <div style={{ textAlign: 'right' }}>
                <Button
                    className="btn_ btn_delete"
                    onClick={handleReject}>Reject</Button>
                <Button
                    className="btn_ btn_edit"
                    // disabled={(uploadedForms || []).some((f: string) => rejectedForms[f])}
                    onClick={handleApprove}>Approve</Button>
            </div>
        </div>
    )
}
