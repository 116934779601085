import { CLEAR_PENDING_ACTION, SET_PENDING_ACTION } from "../../types"

const initialState = null as any
export const pendingActionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CLEAR_PENDING_ACTION: {
            return null
        }
        case SET_PENDING_ACTION: {
            return action.payload
        }
        default:
            return state
    }
}