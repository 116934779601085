import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  TimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { usePermissions } from "react-admin";
import Grid from "@material-ui/core/Grid";
import { ActtionPoupBottemConerImg } from "../../components/imageRelatedComponenets/ActtionPoupBottemConerImg";
import { CloseButton } from "../../containers/drilDownview/widgets/CloseButton";

const PlanButton = ({
  id,
  lines,
  onPlan,
  onDeleteAndPlan,
  selectedOrderIds,
  onDeletePlan,
  planDisabled,
}) => {
  const [open, setOpen] = React.useState(false);
  const timeZoneOffset = moment().utcOffset() * 60 * 1000;

  const [startTime, setStartTime] = useState(moment().startOf("day").valueOf());
  const [minRMNeedDate, setMinRMNeedDate] = useState(null);
  const [checkRmNeed, setCheckRmNeed] = useState(false);
  const [planSelectedOrders, setPlanSelectedOrders] = useState(
    selectedOrderIds.length > 0
  );
  const { permissions } = usePermissions();

  const handleClickOpen = () => {
    setOpen(true);
    setPlanData((planData) => ({ ...planData, selectedLines: {} }));
  };

  const handleClose = () => {
    setOpen(false);
    setCheckRmNeed(false);
  };

  useEffect(() => {
    setPlanSelectedOrders(selectedOrderIds.length > 0);
  }, [selectedOrderIds]);

  const setPayloadBody = () => {
    const lines = [];
    for (let key in planData.selectedLines) {
      if (planData.selectedLines[key]) {
        lines.push(key);
      }
    }
    const data = {
      start_time: planData.start_time + startTime - moment().startOf("day"),
      end_time: planData.end_time,
      lines: lines.length > 0 ? lines : null,
      rm_need: minRMNeedDate == 0 ? 20 : minRMNeedDate,
      selected_d_ops: planSelectedOrders ? selectedOrderIds : [],
    };
    return data;
  };

  const options = {
    undoable: false,
    onSuccess: {
      notification: { body: "Action successful", level: "info" },
    },
    onError: {
      notification: { body: "Error: action not performed", level: "warning" },
    },
  };

  const selectOptions = lines.map((line) => ({
    id: line.id,
    code: line.code,
  }));

  const [planData, setPlanData] = useState({
    selectedLines: {},
    start_time: moment(moment().format("YYYY-MM-DD")).valueOf(),
    end_time:
      moment(moment().add(8, "days").format("YYYY-MM-DD")).valueOf() - 1,
  });

  const onChange = (key) => {
    return (e) => {
      setPlanData({ ...planData, [key]: e.target.value });
    };
  };

  const onSelect = (e, id) => {
    setPlanData((planData) => ({
      ...planData,
      selectedLines: {
        ...planData.selectedLines,
        [id]: !planData.selectedLines[id],
      },
    }));
  };

  const data = setPayloadBody();

  const setDate = (start_time, end_time) => {
    setPlanData((planData) => ({
      ...planData,
      start_time,
      end_time,
    }));
  };



  return (
    <div>
      <Button
        className="btn_ btn_primary"
        title="Opens a form to fill out planning parameters"
        variant="contained"
        onClick={handleClickOpen}
        disabled={!permissions?.permissions.includes("plan_edit_actions")}
      >
        Actions
      </Button>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        // fullWidth={true}
        // fullScreen={true}
        // fullHight={true}
        paperProps={{
          style: { borderRadius: 90 },
        }}
        autoDetectWindowHeight={false}
        autoScrollBodyContent={false}
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: 90,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ paddingTop: "10px" }} id="alert-dialog-title">
              {"Planing Options"}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
                marginRight: 20,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        {/* <DialogActions onClick={handleClose} style={{marginLeft:40}}>
          <CloseButton />
        </DialogActions> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: 30,
            marginTop: 25,
          }}
        >
          <h4 style={{ marginLeft: 30, color:'#00acee', fontWeight: '700', }}>Planing Options</h4>
          <div onClick={handleClose}><CloseButton /></div>
        </div> */}
        {/* <DialogTitle id="alert-dialog-title">{"Planing options "}</DialogTitle> */}

        <DialogContent style={{ overflow: "hidden" }}>
          <form id="formTest">
            <Grid container spacing={2}>
              <Grid container item xs={6}>
                {/* ist grid  */}
            
                <div
                  style={{
                    padding: 5,
                    minHeight: "200px",
                    width: "auto",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Set Planning Period
                    </p>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Start Time and Date
                    </p>
                  </div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      ampm={false}
                      variant="inline"
                      // label="Start time"
                      value={startTime}
                      onChange={(e) => {
                        setStartTime(moment(e).valueOf());
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <br />
                  <DateRangePicker
                    format="dd/MM/yyyy"
                    onChange={(date) => {
                      setDate(
                        date ? moment(date[0]).valueOf() : moment().valueOf(),
                        date
                          ? moment(date[1]).valueOf()
                          : moment().add(7, "days").valueOf()
                      );
                    }}
                    value={[
                      new Date(planData.start_time),
                      new Date(planData.end_time),
                    ]}
                    style={{}}
                  />
                  <br />
                  <label style={{ zIndex: 100000, marginTop: 10 }}>
                    <input
                      type="checkbox"
                      title="permissions"
                      disabled={
                        !permissions?.permissions.includes("plan_without_rm")
                      }
                      value={{ checkRmNeed }}
                      onChange={() => setCheckRmNeed(!checkRmNeed)}
                    ></input>{" "}
                    RM need before:
                    <input
                      style={{ width: "40px", marginLeft: 5 }}
                      min={0}
                      type="number"
                      value={minRMNeedDate}
                      disabled={!checkRmNeed}
                      onChange={(e) => {
                        setMinRMNeedDate(Math.abs(e.target.value));
                      }}
                    />{" "}
                    days
                  </label>
                  {planSelectedOrders && (
                    <div
                      style={{
                        color: "#fc6062",
                        fontSize: 13,
                        width: "75%",
                        marginTop: 10,
                      }}
                    >
                      <input
                        type="checkbox"
                        title="permissions"
                        checked={planSelectedOrders}
                        value={planSelectedOrders}
                        onChange={() =>
                          setPlanSelectedOrders(!planSelectedOrders)
                        }
                        style={{ marginRight: 5 }}
                      ></input>
                      * Only {selectedOrderIds.length} selected orders will be
                      planned. Uncheck if you want to plan without selecting
                      orders.
                    </div>
                  )}
                </div>
                <div style={{ marginBottom: -20, marginLeft: 10 }}>
                  <ActtionPoupBottemConerImg />
                </div>
              </Grid>
              <Grid container item xs={6}>
                <Grid item xs={12}>
                  <Card
                    style={{ padding: 5, border: "none", boxShadow: "none" }}
                  >
                    <p style={{ fontSize: 20 }}>Plan Only These Line</p>
                    <label style={{ marginBottom: 10, fontSize: 12 }}>
                      select lines only if you want to plan those line
                      specifically.
                      <br />
                      If you want to plan for all the lines keep the boxes in
                      the default state{" "}
                    </label>

                    <div
                      style={{
                        maxHeight: 230,
                        width: 420,
                        overflowY: "auto",
                        marginTop: 20,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          height: 600,
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {selectOptions.map((option, index) => (
                          <React.Fragment key={option.id}>
                            <p style={{ marginRight: 10 }}>
                              <input
                                type="checkbox"
                                value={planData.selectedLines[option.id]}
                                name={option.id}
                                onChange={(e) => onSelect(e, option.id)}
                                style={{ marginRight: 10 }}
                              />
                              {option.code}
                            </p>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      // marginBottom: -10,
                      // marginLeft: 60,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Button
                    onClick={handleClose}
                    className="btn_ btn_secondry"
                    style={{
                      color: "black",
                      marginRight: 20,
                      width: 110,
                      height: 40,
                      fontSize: "15px",
                      borderRadius: 5,
                      marginTop: "15px",
                      //boxShadow: '0px 5px 3px #9e9e9e'
                    }}
                  >
                    Cancel
                  </Button> */}

                    <Button
                      title="permissions"
                      disabled={
                        !permissions?.permissions.includes("plan_delete")
                      }
                      variant="contained"
                      className="btn_ btn_delete"
                      color=""
                      onClick={() => {
                        onDeletePlan(id, data);
                        handleClose();
                      }}
                      style={{
                        width: 110,
                        height: 40,
                        borderRadius: 5,
                        fontSize: "15px",
                        backgroundColor: "#fc6062",
                        marginTop: "15px",
                        // boxShadow: '0px 5px 3px #9e9e9e'
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      title="permissions"
                      variant="contained"
                      color="primary"
                      disabled={
                        planDisabled ||
                        !permissions?.permissions.includes("plan_with_rm")
                      }
                      onClick={() => {
                        onPlan(id, data);
                        handleClose();
                      }}
                      size="large"
                      className="btn_ btn_primary"
                      style={{
                        marginRight: 20,
                        // marginLeft: 20,
                        width: 110,
                        height: 40,
                        borderRadius: 5,
                        backgroundColor: "#2f75ff",
                        // boxShadow: '0px 5px 3px #9e9e9e',
                        marginTop: "15px",
                      }}
                    >
                      Plan
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PlanButton;
