export const LOAD_ALL_TASKS = "LOAD_ALL_TASKS";
export const LOAD_UNASSIGNED_TASKS = "LOAD_UNASSIGNED_TASKS";
export const SET_ALL_TASKS = "SET_ALL_TASKS";
export const SET_UNASSIGNED_TASKS = "SET_UNASSIGNED_TASKS";
export const SET_TNA_TASK_LOAD_SUCCESS = "SET_TNA_TASK_LOAD_SUCCESS";
export const COMPLETE_TASK = "COMPLETE_TASK";
export const SET_TASK_COMPLETE = "SET_TASK_COMPLETE";
export const START_TASK = "START_TASK";
export const SET_TASK_STARTED = "SET_TASK_STARTED";
export const SET_TASK_TRANSFERRED_TO_APPROVAL =
  "SET_TASK_TRANSFERRED_TO_APPROVAL";
export const UPLOAD_FORM = "UPLOAD_FORM";
export const GENERATE_AND_DOWNLOAD_FORM = "GENERATE_AND_DOWNLOAD_FORM"
export const SAVE_HOLIDAYS = "SAVE_HOLIDAYS";
export const DELETE_TNA_HOLIDAY = "DELETE_TNA_HOLIDAY";
export const SET_UPLOAD_FORM_SUCCESS = "SET_UPLOAD_FORM_SUCCESS";
export const SET_UPDATED_TASK = "SET_UPDATED_TASK";

//Template
export const LOAD_SDN = "LOAD_SDN";
export const LOAD_SDN_SUCCESS = "LOAD_SDN_SUCCESS";
export const LOAD_RP_SDN_SUCCESS = "LOAD_RP_SDN_SUCCESS";
export const LOAD_SDN_FAILURE = "LOAD_SDN_FAILURE";

export const LOAD_TEMPLATE = "LOAD_TEMPLATE";
export const LOAD_TEMPLATE_SUCCESS = "LOAD_TEMPLATE_SUCCESS";
export const LOAD_TEMPLATE_FAILURE = "LOAD_TEMPLATE_FAILURE";

export const GENERATE_TEMPLATE = "GENERATE_TEMPLATE";
export const GENERATE_TEMPLATE_SUCCESS = "GENERATE_TEMPLATE_SUCCESS";
export const GENERATE_TEMPLATE_ERROR = "GENERATE_TEMPLATE_ERROR";
export const TEMPLATE_RESET_UI_STATE = "TEMPLATE_RESET_UI_STATE";
export const CONFIRM_TEMPLATE = "CONFIRM_TEMPLATE";
export const CONFIRM_TEMPLATE_SUCCESS = "CONFIRM_TEMPLATE_SUCCESS";
export const CONFIRM_TEMPLATE_ERROR = "CONFIRM_TEMPLATE_ERROR";
export const DELETE_FLOW = "DELETE_FLOW";
export const DELETE_FLOW_SUCCESS = "DELETE_FLOW_SUCCESS";
export const DELETE_FLOW_ERROR = "DELETE_FLOW_ERROR";
export const FLOW_STATUS_CHANGE_FAIL = "FLOW_STATUS_CHANGE_FAIL"

export const SUBMIT_IQ_SERIAL = "SUBMIT_IQ_SERIAL";
export const SUBMIT_IQ_SERIAL_SUCCESS = "SUBMIT_IQ_SERIAL_SUCCESS";
export const SUBMIT_IQ_SERIAL_FAILED = "SUBMIT_IQ_SERIAL_FAILED";

export const MODIFY_FLOW = "MODIFY_FLOW";
export const MODIFY_FLOW_SUCCESS = "MODIFY_FLOW_SUCCESS";
export const MODIFY_FLOW_ERROR = "MODIFY_FLOW_ERROR";
export const LOAD_ALL_USERS = "LOAD_ALL_USERS";
export const LOAD_ALL_USERS_SUCCESS = "LOAD_ALL_USERS_SUCCESS";

export const FETCH_TENTATIVE_ORDERS = "FETCH_TENTATIVE_ORDERS";
export const FETCH_TENTATIVE_ORDERS_SUCCESS = "FETCH_TENTATIVE_ORDERS_SUCCESS";
export const FETCH_SELECTED_TENTATIVE_FLOW = "FETCH_SELECTED_TENTATIVE_FLOW";
export const FETCH_SELECTED_TENTATIVE_FLOW_SUCCESS =
  "FETCH_SELECTED_TENTATIVE_FLOW_SUCCESS";

export const FETCH_REPEATED_ORDERS = "FETCH_REPEATED_ORDERS";
export const LOAD_REPEATED_ORDERS = "LOAD_REPEATED_ORDERS";
export const FETCH_DELAY_REASONS = "FETCH_DELAY_REASONS";
export const FETCH_DELAY_REASONS_SUCCESS = "FETCH_DELAY_REASONS_SUCCESS";

export const QR_SEND = "QR_SEND";
export const QR_SEND_SUCCESS = "QR_SEND_SUCCESS";
export const QR_SEND_ERROR = "QR_SEND_ERROR";
export const QR_ACCEPTANCE = "QR_ACCEPTANCE";
