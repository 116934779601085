import { Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Error, Loading,Title, usePermissions } from "react-admin";
import { connect } from "react-redux";
import { theme } from "../../layout/theme";
import { loadData, setCurrentPlant } from "../../store/actions/transferActions";
import {
  getCurrentPlant,
  getDemandOps,
  getLoading,
  getPlants,
  getWorkstations,
} from "../../store/selectors/transferSelectors";
import AlltransfersTab from "./AllTransfersTab";
import ReceivedTab from "./ReceivedTab";
import TransferredTab from "./TransferredTab";

const Transfers = ({
  onLoad,
  loading,
  currentPlant,
  plants,
  demandOps,
  workstations,
  setPlant,
  match,
}) => {
  const initialTab = match.params.tab ? match.params.tab : "transferred";
  const [tab, setTab] = React.useState(initialTab);
  const title="Transfers"
  const [data, setData] = useState();
  const [filter, setFilter] = useState("all");
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  // if (currentPlant == null) {
  //   setPlant('all')
  // }

  const payload1 = {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "id", order: "ASC" },
    filter: { is_exchange: true },
  };
  const payload2 = {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "id", order: "ASC" },
    filter: {},
  };
  const { permissions } = usePermissions();

  // useEffect(() => {
  //   console.log('5')
  //   onLoad(Number(match.params.plant))
  // },[match.params.plant, onLoad])

  useEffect(() => {
    console.log("start", moment());
    // console.log([currentPlant, match.params.plant, onLoad])
    if (match.params.plant && !currentPlant) {
      onLoad(Number(match.params.plant));
      // console.log('1')
    } else if (!match.params.plant) {
      // console.log('2')
      onLoad(currentPlant);
    }
  }, [currentPlant, match.params.plant, onLoad]);

  useEffect(() => {
    if (currentPlant == "all") {
      // console.log('3')
      setTab("allTransfers");
    } else if (tab == "allTransfers") {
      setTab("transferred");
      // console.log('4')
    }
  }, [currentPlant, tab]);

  // if (loading) return <Loading />;
  if (error) return <Error />;

  if (loading) return <Loading />;
  

  if (!demandOps) return null;


  if (demandOps) {
    const workstationIds = workstations.map((w) => w.id);
    const transferDOs = demandOps.filter(
      (dO) =>
        workstationIds.includes(dO.sent_workstation) ||
        workstationIds.includes(dO.received_workstation) ||
        workstationIds.includes(dO.workstation_id)
    );

    // console.log({ permissions });

    return (
      <>
      <Title title={title} />
        <Paper style={{marginTop: theme.spacing(3)}} square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            
          >
            {" "}
            {currentPlant == "all" ? (
              <Tab
                title="permissions"
                disabled={
                  !permissions?.permissions.includes("allTransfers_view")
                }
                label="All Transfers"
                value="allTransfers"
              />
            ) : (
              [
                <Tab
                  title="permissions"
                  disabled={
                    !permissions?.permissions.includes(
                      "transfers_tranferredTab"
                    )
                  }
                  label="Transferred"
                  value="transferred"
                />,
                <Tab
                  title="permissions"
                  disabled={
                    !permissions?.permissions.includes("transfers_receivedTab")
                  }
                  label="Received"
                  value="received"
                />,
              ]
            )}
            {["ADMIN", "SITE", "SUB"].includes(permissions?.access_level) ? ( //plant admin doesnt need to select
              <Typography style={{padding: 10, fontWeight: 600, marginTop: '0px', color: '#333',}}>
                Select department :{" "}
                <Select
                  style={{ padding: 10 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentPlant}
                  onChange={(e) => setPlant(e.target.value)}
                >
                  <MenuItem value="all">All</MenuItem>
                  {/* <MenuItem value="sub">Sub contract</MenuItem> */}
                  {plants &&
                    plants.map((plant) => (
                      <MenuItem value={plant.id}>{plant.code}</MenuItem>
                    ))}
                </Select>
              </Typography>
            ) : null}
          </Tabs>
        </Paper>
          <p>
            {tab === "allTransfers" &&
            permissions?.permissions.includes("allTransfers_view") ? (
              <AlltransfersTab dOs={transferDOs} filter={"all"} />
            ) : null}
            {tab === "transferred" &&
            permissions?.permissions.includes("transfers_tranferredTab") &&
            filter ? (
              <TransferredTab dOs={transferDOs} />
            ) : null}
            {tab === "received" &&
            permissions?.permissions.includes("transfers_receivedTab") ? (
              <ReceivedTab data={demandOps} />
            ) : null}
          </p>
      </>
    );
  }
};

const mapStateToProps = (state, props) => {
  const loading = getLoading(state);
  const demandOps = getDemandOps(state);
  const currentPlant = getCurrentPlant(state);
  const plants = getPlants(state);
  const workstations = getWorkstations(state);
  return {
    loading,
    demandOps,
    currentPlant,
    plants,
    workstations,
  };
};

const mapDispatchToProps = {
  onLoad: loadData,
  setPlant: setCurrentPlant,
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfers);
