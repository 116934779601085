import _ from "lodash"
import { AllocationIdType, IAllocation, IAllocationPart, IPlan, ILinePart } from "../../../../lib/plan"
import { all_allocations_time_slot_forwart_selector, line_all_allocation_selector, same_config_allocation_selector } from "../../../../lib/plan/allocation_selectors/selectors"
import { allocate_to_line } from "../../../../lib/plan/allocators/line_allocators"
import { add_allocation_to_plan, convert_allocation_parts_to_demand_operation_parts, delete_allocations_from_plan, get_calculated_fields_updated, get_next_allocation, get_previous_allocation, select_first_n_by_quantity } from "../../../../lib/plan/helpers"
import { allocationSelectorMap } from "./shared"

export function moveAllocationTransformer(plan: IPlan, action: any): IPlan {
    // TODO implement
    const deletedAllocationIds = new Set<AllocationIdType>()

    const { mode, quantity, allocation_id, to_line_id, to_time, freezed_time } = action.payload



    const selector = allocationSelectorMap[mode as (keyof typeof allocationSelectorMap)]
    const allocationParts = selector(plan, allocation_id) as IAllocationPart[]
    const firstJobAllocationPart = allocationParts.find(v => v.quantity > 0)
    if(!firstJobAllocationPart){
        return plan;
    }

    const allocation = plan.allocations[firstJobAllocationPart?.allocation_id]
    const rm_ready_date = plan.demand_operations[allocation.demand_operation_id].rm_ready_date
    if (rm_ready_date ? rm_ready_date > to_time :  false) {
        return plan
    }
    const isFromFreezeRange = freezed_time >= allocation.start_time
    const isToFreezeRange = freezed_time >= freezed_time
    const allQuantity = _.sum(allocationParts.map(a => a.quantity))

    const movedAllocationIds = new Set(allocationParts.map(a => a.allocation_id))
    const from_line_id = plan.allocations[allocation_id].plan_board_line_id
    const from_time = plan.allocations[allocation_id].start_time
    const fromPreviousAllocationId = get_previous_allocation(plan, allocation_id) as AllocationIdType
    const toPreviousAllocationId = _.maxBy(line_all_allocation_selector(plan, to_line_id)
        .filter(a => plan.allocations[a.allocation_id].start_time < to_time), a => plan.allocations[a.allocation_id].start_time)?.allocation_id as AllocationIdType


    const hasDifference = allQuantity != quantity && quantity
    const selectedParts = hasDifference ? select_first_n_by_quantity(allocationParts, "quantity", quantity) : allocationParts
    const remaingParts = hasDifference ? select_first_n_by_quantity(allocationParts.slice().reverse(), "quantity", allQuantity - quantity) : []

    const toLineRest = line_all_allocation_selector(plan, to_line_id)
        .filter(a => plan.allocations[a.allocation_id].start_time >= to_time) // From the dropped location onward
        .filter(a => !movedAllocationIds.has(a.allocation_id)) // Ignore dragged parts


    const lastAllocationId = allocationParts[allocationParts.length - 1].allocation_id
    const fromLineRestStartAllocationId = get_next_allocation(plan, lastAllocationId)
    
    const hasFromSectionAllocationsToArrange = fromLineRestStartAllocationId && !isFromFreezeRange

    const fromLineRest = hasFromSectionAllocationsToArrange ?
        all_allocations_time_slot_forwart_selector(plan, fromLineRestStartAllocationId as AllocationIdType)
            .filter(a => !movedAllocationIds.has(a.allocation_id))
        : []

    // const draggedParts = convert_allocation_parts_to_demand_operation_parts(plan, selectedParts)

    let newAllocations: IAllocation[] = []



    // First Process the 'to line' which this is dropped.
    let toLine_allocParts: IAllocationPart[] = []
    let fromLine_allocParts: IAllocationPart[] = []

    const toWriteTime = new Date(_.max([to_time, plan.allocations[toPreviousAllocationId]?.end_time]))
    const toInitailConfig = plan.allocations[toPreviousAllocationId]?.configuration || ""
    const fromInitailConfig = plan.allocations[fromPreviousAllocationId]?.configuration || ""

    if (from_line_id != to_line_id) { // Different lines
        toLine_allocParts = [...selectedParts, ...toLineRest]
        fromLine_allocParts = [...remaingParts, ...fromLineRest]
    } else if (from_time > to_time) {  // Same line, move to a previous location
        toLine_allocParts = [
            ...selectedParts,
            ...toLineRest.filter(a => plan.allocations[a.allocation_id].start_time < from_time),
            ...remaingParts,
            ...fromLineRest]
        fromLine_allocParts = []
    } else { // Different lines move to a future point to_time > from_time
        fromLineRest?.forEach((alloc: IAllocationPart, index: number) => {
            if (toLineRest?.includes(alloc)) {
                fromLineRest.splice(index,1)
            }
        })
        fromLine_allocParts = [
            ...remaingParts,
            ...fromLineRest.filter(a => plan.allocations[a.allocation_id].start_time < to_time),
            ]

        const remaing = [...selectedParts,...toLineRest]
        if(isFromFreezeRange) {
            fromLine_allocParts = [...fromLine_allocParts, ...remaing]
        } else{
            toLine_allocParts = remaing
        }
    }

    let part_from = fromLine_allocParts.length == 0 ? toWriteTime : from_time;
    let part_to = from_time;
    let is_previous_alloc_freezed = false;
    const max_date = new Date(8640000000000000)
    const freezed_allocations: IAllocation[] = []

    const [fromLine_lineParts, toLine_lineParts] = [fromLine_allocParts, toLine_allocParts].map((allocParts: IAllocationPart[], mapIndex: number) => allocParts.reduce((parts: ILinePart[], allocation: IAllocationPart, index: number) => {
        if (allocation.is_freezed) {
            part_to = plan.allocations[allocation.allocation_id].start_time;
            if (!is_previous_alloc_freezed) {
                parts.push({
                    start_time: part_from,
                    end_time: part_to,
                    line_id: plan.allocations[allocation.allocation_id].plan_board_line_id,
                    end_config: plan.allocations[allocation.allocation_id].configuration
                } as ILinePart)
            }
            part_from = plan.allocations[allocation.allocation_id].end_time;
            freezed_allocations.push(plan.allocations[allocation.allocation_id])
        }
        is_previous_alloc_freezed = allocation.is_freezed
        if (index == allocParts.length-1) {
            parts.push({
                start_time: part_from,
                end_time: max_date,
                line_id: plan.allocations[allocation.allocation_id].plan_board_line_id,
                end_config: ""
            } as ILinePart)
            part_from = toWriteTime
        }
        return parts;
    },[]))

    newAllocations = [
        ...allocate_to_line(plan, convert_allocation_parts_to_demand_operation_parts(plan, toLine_allocParts), toLine_lineParts, to_line_id, toWriteTime, toInitailConfig),
        ...allocate_to_line(plan, convert_allocation_parts_to_demand_operation_parts(plan, fromLine_allocParts), fromLine_lineParts, from_line_id, from_time, fromInitailConfig)
    ]


    fromLineRest.forEach(a => deletedAllocationIds.add(a.allocation_id))
    toLineRest.forEach(a => deletedAllocationIds.add(a.allocation_id))
    allocationParts.forEach(a => deletedAllocationIds.add(a.allocation_id))


    // alert(JSON.stringify({ mode, quantity, allocation_id, to_line_id, to_time }))
    const addNewAllocations = (plan: IPlan) => add_allocation_to_plan(plan, newAllocations)
    const deleteOldAllocatiosn = (plan: IPlan) => delete_allocations_from_plan(plan, deletedAllocationIds)
    const addFreezedAllocations = (plan: IPlan) => add_allocation_to_plan(plan, freezed_allocations)

    return _.flow([addNewAllocations, deleteOldAllocatiosn, addFreezedAllocations, get_calculated_fields_updated])(plan)
}