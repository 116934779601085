import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link, usePermissions } from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import DashboardIcon from "@material-ui/icons/Dashboard";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SwitchListSecondary() {
  const { permissions } = usePermissions();

  return (
    <div style={{ width: "100%" }} className="row">
      <div className="col-12">
        <h3>Settings </h3>
      </div>

      <div className="col-md-3 settings_block">
        <Card>
          <CardContent>
            <Typography
              style={{ fontSize: 14 }}
              color="textSecondary"
              gutterBottom
            >
              Plan change reason
            </Typography>
            <DashboardIcon />
          </CardContent>

          <div
            style={{
              // display: "flex",
              textAlign: "center",
            }}
          >
            <Button
              title="Click here to add or view reasons"
              disabled={!permissions?.permissions.includes("changeReasons")}
              // size="small"
              className="btn_ btn_primary  mb-1 ml-0"
              variant="contained"
              style={{width:'95%'}}
              // className="btn_ btn_primary"
              component={Link}
              to={{
                pathname: "/changeReason",
              }}
              // style={{ marginRight: "%50" }}
            >
              Add or View Reason
            </Button>
          </div>
        </Card>
      </div>
      {/* 
      <div className="col-md-3 settings_block">
        <Card>
        <CardContent>
          <Typography></Typography>
          <DashboardIcon/>
        </CardContent>
        <div className="text-center"Lorium ipsum>
            <Button className="btn_ btn_primary w-100 mb-0">Lorium ipsum</Button>
        </div>
        </Card>
      </div>

      <div className="col-md-3 settings_block">
        <Card>
        <CardContent>
          <Typography>Lorium ipsum</Typography>
          <DashboardIcon />
        </CardContent>
        <div className="text-center">
            <Button className="btn_ btn_primary w-100 mb-0">Lorium ipsum</Button>
        </div>
        </Card>
      </div>  

      <div className="col-md-3 settings_block">
        <Card>
        <CardContent>
          <Typography>Lorium ipsum</Typography>
          <DashboardIcon />
        </CardContent>
        <div className="text-center">
            <Button className="btn_ btn_primary w-100 mb-0">Lorium ipsum</Button>
        </div>
        </Card>
      </div>     */}
    </div>
  );
}
