import React, { useState, useEffect } from 'react';
import { usePermissions } from "react-admin";
import { useSelector, useDispatch } from "react-redux"
import { WS_URL } from '../../constants'
import FooterPlanboardLoading from './FooterPlanboardLoading';
import { getIsPlanning, getPlanBoard, getPlanningProgress } from '../../store/selectors';
import { setProgress } from '../../store/actions';
import dataProvider from '../../api/dataProvider';

const getPlanBoardProgress = (params, currentPlanBoardId, isPlanning) =>
  params?.map(b => ({ planboardname: b.code, progress: b.progress, id: b.id })) || []

export default function FooterStripe() {

  const isPlanning = useSelector(getIsPlanning);
  const currentLoadedBoardProgress = useSelector(getPlanningProgress);
  const planBoard = useSelector(getPlanBoard);
  const currentLoadedPlanboardId = planBoard?.id
  const dispatch = useDispatch()
  const [planboards, setPlanboards] = useState([]);
  const [progressData, setProgressData] = useState()

  const permissions = usePermissions().permissions?.access_level;

  useEffect(() => {
    dataProvider
        .GET_PLANBOARDS()
        .then((resp) => {
          const data = getPlanBoardProgress(resp.data.results, currentLoadedPlanboardId, isPlanning)
          setPlanboards(data);
        });
    planboardsSocket();
  }, [isPlanning, currentLoadedPlanboardId, dispatch]);

  const planboardsSocket = () => {
    console.log("Called web socket")
    const socket = new WebSocket(
      `${WS_URL}/ws/planBoards/`
    );

    if (socket.readyState == 1) {
      socket.send({ user_permissions: permissions });
    }

    socket.onmessage = function (event) {
      const data = JSON.parse(event.data)
      const plan_board_id = data.progress_update.plan_board
      const progress = data.progress_update.percentage.toFixed(2)
      const updateProgress = b => b.id == plan_board_id ? ({ ...b, progress: progress }) : b
      setPlanboards(boards => boards.map(updateProgress))
      if (plan_board_id == currentLoadedPlanboardId) {
        dispatch(setProgress(progress))
      }
    };
    socket.addEventListener('close', socketRestart)
  };

  const socketRestart = (event) => {
    setTimeout(() => planboardsSocket(), 5000)
  }

  return (<FooterPlanboardLoading planboards={planboards.filter(b => b.progress && b.progress > 0)} />);
}

