import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import React, { useEffect } from "react";
import { Link, Title, usePermissions } from "react-admin";
import { connect } from "react-redux";
import { loadNotifications } from "../../store/actions/notificationActions";
import { getLoading, getNotificationCount } from "../../store/selectors/notificationSelectors";
import { getTNANotificationCount } from "../../store/selectors/tnaNotificationSelectors";

const styles = {
  root: {
    minWidth: 500
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 30
  },
  pos: {
    marginBottom: 12
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
};

const Notification = ({ loading, count, tnaCount, onLoad }) => {
  const auth = usePermissions();
  //const [load, setLoad] = useState(true);
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <Title title="Notificatons" />

      <div>
        <Grid container spacing={3} style={{ marginTop: 30, marginLeft: 20 }}>
          <div class="col-md-4 notify">
            <Grid item xs variant="outlined" className={styles.row}>
              {count ?
                <Card className={styles.root}>
                  <CardContent>
                    <div style={{ display: "flex" }}>
                      <Badge
                        badgeContent={count > 0 ? count : 0}
                        color="secondary"
                        style={{ color: "#fff", backgroundColor: "red", }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                        style={{ justifyContent: "flexStart" }}
                      >
                        <MailIcon />
                      </Badge>

                      <Typography
                        className={styles.title}
                        color="textSecondary"
                        gutterBottom
                        style={{
                          justifyContent: "flexEnd",
                          paddingLeft: "30px",
                          fontSize: "30px",
                          fontWeight: "700",
                          color: "#00acee"
                        }}
                      >
                        Transfer
                    </Typography>
                    </div>
                  </CardContent>

                  <CardActions style={{ justifyContent: "center", paddingBottom: "0px", }}>
                    <Button
                      size="small"
                      //className="btn-secondry"
                      style={{ fontSize: "16px", width: "100%", height: "50px", borderRadius: "0px", marginTop: "100px", backgroundColor: "#c5edfd", color: "#00acee" }}
                      component={Link}
                      to={{
                        pathname: "/notifyTransfers",
                      }}
                    >
                      View
                  </Button>
                  </CardActions>
                </Card> :
                tnaCount ?

                  <Card className={styles.root}>
                    <CardContent>
                      <div style={{ display: "flex" }}>
                        <Badge
                          badgeContent={tnaCount > 0 ? tnaCount : 0}
                          color="secondary"
                          style={{ color: "#fff", backgroundColor: "red", }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                          style={{ justifyContent: "flexStart" }}
                        >
                          <MailIcon />
                        </Badge>

                        <Typography
                          className={styles.title}
                          color="textSecondary"
                          gutterBottom
                          style={{
                            justifyContent: "flexEnd",
                            paddingLeft: "30px",
                            fontSize: "30px",
                            fontWeight: "700",
                            color: "#00acee"
                          }}
                        >
                          Notifications
                    </Typography>
                      </div>
                    </CardContent>

                    <CardActions style={{ justifyContent: "center", paddingBottom: "0px", }}>
                      <Button
                        size="small"
                        //className="btn-secondry"
                        style={{ fontSize: "16px", width: "100%", height: "50px", borderRadius: "0px", marginTop: "100px", backgroundColor: "#c5edfd", color: "#00acee" }}
                        component={Link}
                        to={{
                          pathname: "/tna_notifications",
                        }}
                      >
                        View
                  </Button>
                    </CardActions>
                  </Card> : null}
            </Grid>
          </div>
        </Grid>
      </div>
    </>
  );
};
const mapStateToProps = (state, props) => {
  const loading = getLoading(state);
  const count = getNotificationCount(state);
  const tnaCount = getTNANotificationCount(state);
  return {
    loading,
    count,
    tnaCount
  };
};

const mapDispatchToProps = {
  onLoad: loadNotifications
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
