import React from "react";
import ReactSpeedometer from "react-d3-speedometer";
import ChartsNew from "./ChartsNew";
import CustomTable from "./CustomTable";
import {useQuery, Error} from 'react-admin'
import SmallLoading from "../loading/SmallLoading";
import { useTable } from "react-table";
import { TableContainer, Table } from "@material-ui/core";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    scrollBehavior: "smooth",
    overflow: "auto",
    marginTop: 30,
    borderRadius: 5,
  },
  tableContainer: {
    backgroundColor: "white",
    padding: 15,
    borderRadius: 5,
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  row: { width: "100%", display: "flex", flexDirection: "row" },
  title: { textAlign: "center", fontSize: 15, marginBottom: 10 },
};

const LineWisePlannedUtil = (props) => {
  const { data, loading, error } = useQuery({
    type: "CUSTOM_LIST",
    resource: "dashboard",
    payload: { action: "get_linewise_planned_utilization", query: props },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(null);

  if (loading) return <SmallLoading />
  if (error) return <Error />
  if (!data) return null;
  return (
    <CustomTable
      maxHeight={230}
      order={order}
      orderBy={orderBy}
      columns={[{id: "line_code", name: "Line Code"}, {id: "total_planned_smv", name:"Planned SMV"}]}
      data={data}
      setOrderBy={setOrderBy}
      setOrder={setOrder}
    />
  );
}


const PlantCapacityLoading = (props) => {
  const { data, loading, error } = useQuery({
    type: "CUSTOM_LIST",
    resource: "dashboard",
    payload: { action: "get_plant_capacity_loading", query: props },
  });
  // console.log(props)
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(null);

  if (loading) return <SmallLoading />
  if (error) return <Error />
  if (!data) return null
  return (
    <CustomTable
      maxHeight={300}
      order={order}
      orderBy={orderBy}
      columns={[{id: "plant_name", name: "Plant"}, {id: "used_capacity", name: "Used Capacity"}, {id: "full_capacity", name: "Full Capacity"}, {id: "capacity_loading", name: "Capacity Loading"}]}
      data={data}
      setOrderBy={setOrderBy}
      setOrder={setOrder}
    />
  );
}

const PlannedToolUtilization = (props) => {
  const { data, loading, error } = useQuery({
    type: "CUSTOM_LIST",
    resource: "dashboard",
    payload: { action: "get_planned_tool_utilization", query: props },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(null);

  if (loading) return <SmallLoading />;
  if (error) return <Error />;
  if (!data) return null;
  
  return (
    <CustomTable
      maxHeight={230}
      order={order}
      orderBy={orderBy}
      columns={[
        { id: "tool_type", name: "Tool Type" },
        { id: "tool_size", name: "Tool Size" },
        { id: "sum", name: "Sum" },
      ]}
      data={data}
      setOrderBy={setOrderBy}
      setOrder={setOrder}
    />
  );
};


const CompletedPercentage = (props) => {
  const { data, loading, error } = useQuery({
    type: "CUSTOM_LIST",
    resource: "dashboard",
    payload: { action: "get_complete_percentage", query: props },
  });

  if (loading) return <SmallLoading />;
  if (error) return <Error />;
  if (!data) return null;
  console.log(data)
  return (
    <ReactSpeedometer
      value={Number(Number(data['complete_percentage']).toFixed(5))}
      maxValue={100}
      height={180}
      segments={250}
      paddingVertical={20}
      maxSegmentLabels={3}
      currentValueText="${value}%"
      valueTextFontSize="30px"
      ringWidth={15}
      needleHeightRatio={0.8}
    />
  );
};

const DelayedOrderGroupCodes = (props) => {
  const innerWidth = window.innerWidth
  const { data, loading, error } = useQuery({
    type: "CUSTOM_LIST",
    resource: "dashboard",
    payload: { action: "get_delayed_order_group_codes", query: props },
  });
  const columns = [{Header: "Date", accessor: "date"}, {Header: "Delayed Group Codes", accessor: "delayed_order_group_code",Cell: ({ row }) => <div style={{whiteSpace: 'normal'}}>{row["delayed_order_group_code"]}</div>}]
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  
  if (loading) return <SmallLoading />;
  if (error) return <Error />;
  if (!data) return null;
  for (let item of data) {
    item["delayed_order_group_code"] = String(item["delayed_order_group_code"]);
  }
  // console.log(data)
  // return <MyTable {...{columns, data}}/>
  
  return (
    <CustomTable
      maxHeight={450}
      style={{
        maxWidth: 35 * innerWidth / 38
      }}
      order={order}
      orderBy={orderBy}
      columns={[
        { id: "date", name: "Date" },
        { id: "delayed_order_group_code", name: "Delayed Group Codes" },
      ]}
      data={data}
      setOrderBy={setOrderBy}
      setOrder={setOrder}
      // useTableHeader={false}
      // colgroups={[12, 85]}
      alignCell="left"
      alignHead="left"
    />
  );
};

const NewCustomDash = (props) => {
  return (
    <div style={styles.container}>
      <ChartsNew {...props} />
      <div style={{ ...styles.tableContainer, marginBlock: 15, width: "100%" }}>
        <h4 style={styles.title}>Plant Capacity Loading Table</h4>
        <PlantCapacityLoading {...props}/>
      </div>
      <div style={styles.row}>
        <div
          style={{ ...styles.tableContainer, marginRight: 15, width: "35%" }}
        >
          <h4 style={styles.title}>Linewise Planned Utilization</h4>
          <LineWisePlannedUtil {...props}/>
        </div>
        <div
          style={{
            ...styles.tableContainer,
            marginRight: 15,
            width: "45%",
          }}
        >
          <h4 style={styles.title}>Planned Tool Utilization</h4>
          <PlannedToolUtilization {...props} />
        </div>
        <div
          style={{
            ...styles.tableContainer,
            width: "20%",
          }}
        >
          <h4 style={styles.title}>Complete Percentage</h4>
          <div style={{ justifySelf: "center", alignSelf: 'center' }}>
            <CompletedPercentage {...props}/>
          </div>
        </div>
      </div>
      <div
        className="col-12"
        style={{
          ...styles.tableContainer,
          marginBlock: 15,
        }}
      >
        <h4 style={styles.title}>Delayed Order Group Codes</h4>
        <DelayedOrderGroupCodes {...props}/>
      </div>
    </div>
  );
};

export default NewCustomDash;
