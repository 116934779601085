import React from "react";
import {
  BooleanField,
  CardActions,
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  SingleFieldList,
  ReferenceInput,
  SelectInput,
  ChipField,
  ArrayField,
  WithPermissions,
  usePermissions,
} from "react-admin";
import Confirmation from "../../components/Confirmation";
import UploadOperationCsvButton from "./UploadOperationCsvButton";
import { ChangeDiv } from "../../components/ChangeDiv";

const OperationFilter = (props) => {

  const { permissions } = usePermissions();
  const isAdmin = permissions?.access_level == "ADMIN"
  return (
    <Filter {...props}>
      <TextInput label="Search by description" source="description" alwaysOn />
      <TextInput label="Search by code" source="code" alwaysOn />
      <TextInput label="Search by group code" source="group_code" alwaysOn />
      {isAdmin && <ReferenceInput
        link=""
        label="Site"
        source="site_id"
        reference="sites"
        alwaysOn
      >
        <SelectInput optionText="name" resettable />
      </ReferenceInput>}
    </Filter>
  );
}

const Actions = () => (
  <CardActions>
    <div className="ml-2">
      <UploadOperationCsvButton />
    </div>

  </CardActions>
);

export const Operations = (props) => {
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      perPage={25}
      filters={<OperationFilter />}
      bulkActionButtons={false}
      actions={<Actions />}
    >
      {/* <div
        // style={{
        //   //width: '100%',
        //   height: "93%",
        //   //overflow: 'auto'
        // }}
        // className="table-width-custom"
        style={{ height: window.innerHeight * 0.7, overflowY: "auto" }} 
      >*/}
      <ChangeDiv>
        {props => {
          return <Datagrid>
            <TextField source="id" />
            <TextField source="code" />
            <TextField source="group_code" />
            <TextField source="description" />
            <BooleanField source="resolve_child_demands" />
            <TextField source="input_lead_time" />
            <TextField source="output_lead_time_min" />
            <TextField source="output_lead_time_max" />
            <TextField source="backward_output_lead_time" />
            <TextField source="configuration_pattern" emptyText="N/A" />
            <ArrayField source="direct_planboards" label="Direct planboard" sortable={false} >
              <SingleFieldList linkType={false}>
                <ChipField source="code" clickable={false} />
              </SingleFieldList>
            </ArrayField>
            <ArrayField source="compatible_planboards" label="Optional planboards" sortable={false} >
              <SingleFieldList linkType={false}>
                <ChipField source="code" clickable={false} />
              </SingleFieldList>
            </ArrayField>


            <EditButton
              {...props}
              className="btn_ btn_edit m-0"
              title="Click here to edit operations"
              disabled={!permissions?.permissions.includes("operations_edit")}
            />
            <Confirmation
              className="m-0"
              title="Click here to Delete operations"
              disabled={!permissions?.permissions.includes("operations_delete")}
              text="operations"
            />
          </Datagrid>
        }}
      </ChangeDiv>
      {/* </div> */}
    </List >
  );
};
