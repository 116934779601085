
import React from "react";
import { Datagrid, ReferenceField, ReferenceManyField, Show, Tab, TabbedShowLayout, TextField } from "react-admin";
import CustomDateField from "../../components/field/CustomDateField";

export const showDemandOperations = (props) => {

    return (
        <Show {...props} actions={null}>

            <TabbedShowLayout>
                <Tab label="Allocations">
                    <ReferenceManyField
                        {...props}
                        label="Allocations"
                        reference="allocations"
                        target="demand_operation"
                        addLabel={false}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <CustomDateField source="start_time" showTime />
                            <CustomDateField source="end_time" showTime />
                            <ReferenceField link="" source="line" source="line_id" reference="boardLine">
                                <TextField source="code" />
                            </ReferenceField>

                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>)
}