import Button from "@material-ui/core/Button";
import { ListController } from "ra-core";
import React from "react";
import ShowButton from "../../../components/CustomShowButton";
import {
  Datagrid,
  EditButton,
  ListView,
  NumberField,
  Pagination,
  Filter,
  ListBase,
  TextInput,
  ListToolbar,
  ReferenceField,
  ReferenceManyField,
  Show,
  DateField,
  SimpleShowLayout,
  TextField,
  usePermissions,
  FunctionField,
  BooleanField,
} from "react-admin";
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const AdminFlowShow = (props) => {
  const { permissions } = usePermissions();

  const Title = ({ record }) => {
    return <span>Task Flow | {record ? `${record.flow_code} ` : ""}</span>;
  };

  const TaskFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Search by Task ID" source="id" alwaysOn />
      <TextInput label="Search by Task Code" source="task_code" alwaysOn />
    </Filter>
  );

  const TaskExpand = ({id, record, resource}) => {
    const classes = useStyles();
    const pathChange = (sub_task_id) => {
      const path = resource;
      console.log(path);
      window.location.href = `#/${resource}/${sub_task_id}/show`;
    }
    return <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {["id", "task code", "task name", "assignee","department","due start datetime","due complete datetime","task status"].map((column) => (
                <TableCell>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {record.childTasks.map((row) => (
              <TableRow key={row.id} style={{ height: 40, alignItems: 'center' }}>
                {["id", "task_code", "task_name", "assignee","task_department","due_start_datetime","due_complete_datetime","task_status"].map((column) => (
                  <TableCell component="th" scope="row">
                    {row[column]}
                  </TableCell>
                ))}
                <Button style={{ backgroundColor: '#aaa', marginTop: 7.5, color: 'white' }} key={row.id} onClick={() => pathChange(row.id)}>show</Button>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  }

  return (
    <Show {...props} title={<Title />}>
      <SimpleShowLayout>
        <ReferenceManyField
          label="Tasks"
          reference="tna_itemTasks"
          sort={{ field: "id", order: "ASC" }}
          filter={{ order: props.id, main_task: 'None' }}
          perPage={1000}
        >
          <ListBase {...props}>
            <ListToolbar filters={<TaskFilter />} />
            <Datagrid expand={<TaskExpand/>}>
              <TextField source="sequence_no" />
              <TextField source="id" label="Task ID" />
              <TextField source="task_code" />
              <TextField source="task_name" />
              <TextField source="tna_department" label="Department" />
              <TextField source="assignee" label="Assigned User" />
              <TextField source="task_status" />
              <FunctionField label="Due Start Datetime" width="15%" render={props => {
                const dueStartDateTime = moment(props.due_start_datetime).format("DD/MM/YYYY")
                return <div
                >{dueStartDateTime}</div>
                }}
              />
              <FunctionField label="Due Complete Datetime" width="15%" render={props => {
                const dueCompleteDateTime = moment(props.due_complete_datetime).format("DD/MM/YYYY")
                return <div
                >{dueCompleteDateTime}</div>
                }}
              />
              <FunctionField
                label="Has Sub Tasks"
                render={(record) => {
                  if (record.childTasks.length > 0) return <span style={{fontWeight: "bold"}}>Yes</span>
                  else return <span>No</span>
                }}
              ></FunctionField>
              <FunctionField
                render={(record) => (
                  //   {
                  //   if (record.id != undefined && record.id != null) {
                  //     if (localStorage.getItem("tna_department") != "undefined") {

                  //       if (record.tna_department == localStorage.getItem("tna_department")) {
                  //         console.log(record)
                  //         return (<ShowButton
                  //           title='permissions' basePath={"/tna_itemTasks"} record={record}
                  //         />)
                  //       } else {
                  //         return null
                  //       }
                  //     } else {
                  //       console.log(record)
                  //       return (<ShowButton
                  //         title='permissions' basePath={"/tna_itemTasks"} record={record}
                  //       />)
                  //     }
                  //   }

                  // }

                  <ShowButton
                    title="permissions"
                    basePath={"/tna_itemTasks"}
                    record={record}
                  />
                )}
              />
              ;
            </Datagrid>
          </ListBase>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

const DumbList = (props) => (
  <ListController {...props}>
    {(controllerProps) => {
      let { data } = props;
      const ids = Object.keys(data || {});
      const total = ids.length;
      return (
        <ListView
          {...props}
          pagination={false}
          // This is important, otherwise bulkActionsToolbar ends up on very top of the page
          classes={{ card: "relative" }}
          {...Object.assign(controllerProps, { data, ids, total })}
        />
      );
    }}
  </ListController>
);
