import React, { createRef, useRef, useState, useEffect } from "react";
import { Query, Error, Title } from 'react-admin'
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Popover,
  TextField,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { NumberInput, Edit, SelectInput, usePermissions, useQuery, useRedirect, Loading, SimpleForm, TextInput, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import { useDispatch, useSelector } from "react-redux";
import { SAVE_HOLIDAYS, DELETE_TNA_HOLIDAY } from "../../store/types";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { showToastError } from "../../lib/toas";
import { SET_INITIAL_STATE } from "../../store/types/tnaHolidayTypes"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    maxWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  deptSelector: {
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: 'none',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '2px',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      cursor: 'pointer'
    }
  }
}));

type Holiday = {
  id?: string,
  start_date: any,
  end_date: any,
  reason: string,
  departments: any[]
}

const convertHolidaysToEvents = (holidays: Holiday[]) => {
  return holidays.map((h) => {
    return {
      'id': `${h.id}`,
      'title': `${h.reason} (${h.departments})`,
      'start': new Date(h.start_date),
      'end': new Date (h.end_date),
    }
  })
}

type HolidayShowProps = {
  showHoliday: boolean
  holiday: Holiday | undefined
  deleteHoliday: () => any
  closeDialog: () => any
}

const HolidayShowDialog = (props: HolidayShowProps) => {
  return (
    <Dialog open={props.showHoliday} maxWidth="md">
      <DialogTitle>{props.holiday?.reason}</DialogTitle>
      <DialogContent>
        <div>
          <span style={{ fontWeight: 'bold' }}>From: <span style={{ fontWeight: 'normal' }}>{moment(props.holiday?.start_date).format("DD-MM-YYYY")}</span></span>
          <span style={{ fontWeight: 'bold', marginLeft: '20px' }}>To: <span style={{ fontWeight: 'normal' }}>{moment(props.holiday?.end_date).format("DD-MM-YYYY")}</span></span>
          <div style={{ marginTop: '10px' }}>
            Delete holiday?
              <span style={{ marginLeft: '20px' }}>
              <Button variant="contained" style={{ backgroundColor: 'red', marginRight: '10px' }} size="small" onClick={() => {
                props.deleteHoliday();
                props.closeDialog()
              }}>Yes</Button>
              <Button variant="contained" color="default" size="small" onClick={props.closeDialog}>No</Button>
            </span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value + 1); // update the state to force render
}

export default function TnaCalendar() {
  const classes = useStyles()
  const { permissions } = usePermissions();
  const [selectedDepts, setSelectedDepts] = useState([])
  const [selectedStart, setSelectedStart] = useState<any>()
  const [selectedEnd, setSelectedEnd] = useState<any>()
  const [holidayReason, setHolidayReason] = useState('')
  const [unsavedHolidays, setUnsavedHolidays] = useState<Holiday[]>([])
  const [savedHolidays, setSavedHolidays] = useState<Holiday[]>([])
  const [showHoliday, setShowHoliday] = useState(false)
  const [selectedHoliday, setSelectedHoliday] = useState<Holiday>()
  const [notifyWrong, setNotifyWrong] = useState(false)
  const refresh = useRefresh()
  const dispatch = useDispatch()
  const [isLoading, setisLoading] = useState(false)
  const successMessage = useSelector((state: any) => state.custom.tnaHoliday.success)

  moment.locale("en");
  const localizer = momentLocalizer(moment)

  const onSelectDepts = (o: any) => {
    setSelectedDepts(o)
  }
  console.log("Selected Depts: ", selectedDepts)
  const handleStartDateChange = (date: any) => {
    setSelectedStart(date);
  };
  const handleEndDateChange = (date: any) => {
    if (selectedStart && date.toDate() >= selectedStart.toDate()) {
      setSelectedEnd(date);
      setNotifyWrong(false)
    } else setNotifyWrong(true)
  };
  const onReasonChange = (e: any) => {
    setHolidayReason(e.target.value)
  }
  const addHoliday = () => {
    if (selectedDepts.length == 0) {
      showToastError("No departments selected")
    } else {
      const holiday = {
        'id': Math.random().toString(),
        'start_date': selectedStart,
        'end_date': selectedEnd,
        'reason': holidayReason,
        'departments': selectedDepts
      } as Holiday
      console.log("Holiday: ", holiday)
      setUnsavedHolidays([...unsavedHolidays, holiday])
    }
    dispatch({ type: SET_INITIAL_STATE })
  }

  const deleteHoliday = () => {
    setUnsavedHolidays(unsavedHolidays.filter(h => h.id != selectedHoliday?.id))
    setSavedHolidays(savedHolidays.filter(h => h.id != selectedHoliday?.id))
    if (selectedHoliday?.id && parseFloat(selectedHoliday?.id) >= 1.0) {
      const holToDel = {
        id: selectedHoliday.id,
        reason: selectedHoliday?.reason,
        departments: selectedHoliday?.departments,
        startDate: moment(selectedHoliday?.start_date).format('DD-MM-YYYY'),
        endDate: moment(selectedHoliday?.end_date).format('DD-MM-YYYY')
      }
      dispatch({ type: DELETE_TNA_HOLIDAY, payload: holToDel })
      refresh()
    }
  }

  const undoAddHoliday = () => {
    setUnsavedHolidays(unsavedHolidays.slice(0, unsavedHolidays.length - 1))
  }

  const onSelectHoliday = (e: any) => {
    setShowHoliday(true)
    console.log("Unsaved holidays: ", unsavedHolidays)
    console.log("Saved holidays: ", savedHolidays)
    console.log("Event: ", e)
    const selHol = [...savedHolidays, ...unsavedHolidays].filter(h => h.id == e.id)[0]
    setSelectedHoliday(selHol)
  }

  const saveHolidays = () => {
    const formattedHolidays = unsavedHolidays.map((h: Holiday) => {
      return {
        ...h,
        startDate: moment(h.start_date).format('DD-MM-YYYY'),
        endDate: moment(h.end_date).format('DD-MM-YYYY')
      }
    })
    dispatch({ type: SAVE_HOLIDAYS, payload: formattedHolidays })
    setisLoading(true)
    setUnsavedHolidays([])
    // setTimeout(() => {
    //   window.location.reload()
    // }, 1500)
  }

  useEffect(() => {
    switch (successMessage) {
      case true:
        setisLoading(false)
        break;
      default:
        break;
    }
  }, [successMessage])

  const payload = {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "id", order: "ASC" },
  }

  return (
    <div>
      {isLoading ?
        <Loading loadingSecondary="Content is loading, just a moment please" /> :
        <>
          <Grid container>
            <Grid item md={7} style={{ height: 700 }}>
              <Title title="TNA Calendar" />
              <Query
                type="TNA_CUSTOM_LIST"
                resource="holidays"
                payload={{ action: 'getHolidays' }}
              >
                {({ data, loading, error }: any) => {
                  if (data) setSavedHolidays(data)
                  if (loading) { return <></> }
                  if (error) { return <Error /> }
                  return <Calendar
                    localizer={localizer}
                    events={convertHolidaysToEvents([...savedHolidays, ...unsavedHolidays])}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ width: '100%', height: '80%', marginTop: 20 }}
                    onView={() => { }}
                    onSelectEvent={onSelectHoliday}
                  />
                }}
              </Query>
            </Grid>
            {permissions?.permissions.includes("tna_calender_add_holiday") == true ?
              <Grid item md={5}>
                <Paper style={{ marginLeft: 20, marginTop: 20, height: 400 }}>
                  <Typography style={{ padding: "10px", textAlign: 'center', fontSize: 20 }}>Add Holidays</Typography>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px' }}>
                    <Query
                      type="getList"
                      resource="tna_departments"
                      payload={payload}
                    >
                      {({ data, loading, error }: any) => {
                        if (loading) { return <></> }
                        if (error) { return <Error /> }
                        return <DeptSelector columns={data.map((c: any) => c.code)} onChangeSelected={onSelectDepts} selected={selectedDepts} />
                      }}
                    </Query>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ padding: "10px", textAlign: 'left' }}>Start date</Typography>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        minDate={new Date()}
                        style={{ marginRight: 10 }}
                        disableToolbar
                        variant="inline"
                        format="DD-MM-YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        label="Holiday start date"
                        value={selectedStart}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ padding: "10px", textAlign: 'left' }}>End date</Typography>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        minDate={selectedStart ? selectedStart.toDate() : new Date()}
                        style={{ marginRight: 10 }}
                        disableToolbar
                        variant="inline"
                        format="DD-MM-YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        label="Holiday end date"
                        value={selectedEnd}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ padding: "10px", textAlign: 'left' }}>Holiday reason</Typography>
                    <TextField style={{ marginRight: 10 }} required label="Reason" onChange={onReasonChange}></TextField>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: 30 }}>
                    <Button size="large" variant="contained" color="primary" onClick={addHoliday}>Add holiday</Button>
                    <Button disabled={unsavedHolidays.length == 0} size="large" variant="contained" color="secondary" onClick={undoAddHoliday}>Undo</Button>
                    <Button disabled={unsavedHolidays.length == 0} size="large" variant="contained" color={unsavedHolidays.length == 0 ? 'secondary' : 'primary'} onClick={saveHolidays}>Save</Button>
                  </div>
                </Paper>
              </Grid> : null
            }
          </Grid>
          <HolidayShowDialog showHoliday={showHoliday} holiday={selectedHoliday} closeDialog={() => setShowHoliday(false)} deleteHoliday={deleteHoliday} />
        </>
      }

    </div>
  );
}

type ColumnSelectorProps = {
  columns: string[],
  selected: string[],
  onChangeSelected: any
}
export const DeptSelector = ({ columns, onChangeSelected, selected }: ColumnSelectorProps) => {
  const classes = useStyles()
  const check_ref = createRef<any>()
  const handleChange = (col: string) => {
    if (selected.some(key => key == col)) {
      onChangeSelected(selected.filter(key => key != col))
    } else {
      onChangeSelected([...selected, col])
    }

  }
  const onSelectAll = () => {
    console.log("Current: ", check_ref.current.checked)
    if (!check_ref.current.checked) {
      onChangeSelected([])
    } else {
      onChangeSelected(columns)
    }
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return <div>
    <div aria-describedby={id} onClick={handleClick} className={classes.deptSelector}
    >
      Select Departments
          <ExpandMoreIcon style={{ paddingLeft: '5px' }} fontSize="large" />
    </div>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div style={{ padding: 20 }}>
        <label style={{ paddingBottom: '10px' }}>
          <input
            ref={check_ref}
            type="checkbox"
            checked={selected.length == columns.length}
            onChange={onSelectAll}
          />
          <span style={{ fontWeight: 'bold' }}> ALL </span>
                &nbsp; &nbsp;
              </label>
        {columns.map(col => {
          return <div>

            <label>
              <input
                type="checkbox"
                checked={selected.some(key => key == col)}
                onChange={() => handleChange(col)}
              />
              <span> {col} </span>
              &nbsp; &nbsp;</label>
          </div>
        })}
      </div>
    </Popover>
  </div>
}