import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import moment from "moment";
const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    container: {
        padding: '10px'
    },
    supply: {
        backgroundColor: 'lightgreen'
    }
}));

export function DemandDetailPopOver({demandDetails }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    var demandsRows = [];
    var forceSupplyRow = [];
    let forceOk = false;
    for (var i = 0; i < demandDetails?.demands.length; i++) {
        let demand = demandDetails.demands[i]
        let demands_force_supply_time = demandDetails.force_supply[i]
        let demand_supply = demandDetails.supply_time[i]
        let demandIsRm = demandDetails.is_rm[i]
        let finalSupplyTime;

        if (demandIsRm) {
            if (!forceOk) {
                forceSupplyRow.push(
                    <p>Force Upload Date : {demands_force_supply_time? moment(demands_force_supply_time).format('DD/MM/YYYY'):"No Force Uploads"}</p>
                )
                forceOk = true
            }

        } else {
            if (demand_supply) {
                finalSupplyTime = moment(demand_supply).format('DD/MM/YYYY')
                demandsRows.push(
                    <p className={classes.supply}>{demand} : {finalSupplyTime}</p>
                )
            }else{
                demandsRows.push(
                    <p className={classes.supply}>{demand}</p>
                )
            }
            if (!forceOk) {
                forceSupplyRow.push(
                    <p>Force Upload Date : {demands_force_supply_time? moment(demands_force_supply_time).format('DD/MM/YYYY'):"No Force Uploads"}</p>
                )
                forceOk = true
            }
        }
    }


    return (
        <div>
            <div onClick={handleClick}>
                {demandDetails.rm_ready}
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.container}>
                    RM Ready : {demandDetails.rm_ready}
                    {demandsRows}
                    {forceSupplyRow}
                </div>


            </Popover>
        </div>
    );
}

export function AutoPlanErrorsPopover({row}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const possible_but_unplanned = row.rm_ready_date && row.planned_quantity == 0
    const planned = row.planned_quantity > 0
    const errors = typeof row.auto_plan_errors === "object" && row.auto_plan_errors !== null ? [] : JSON.parse(row.auto_plan_errors)
    const possible_unplanned_reasons = ["Tools unavailable", "Low priority order"]

    return (
        <div style={{ backgroundColor: possible_but_unplanned || errors?.length > 0 ? '#ff8c68' : '', paddingLeft: 5 }} >
            <div onClick={handleClick}>
                {errors.length == 0 ?
                possible_but_unplanned ? "Multiple possible reasons" : "" : planned ? "" : errors[0].replace(/['"]+/g, '')}
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.container}>
                    {errors.length == 0 ?
                    possible_but_unplanned ? possible_unplanned_reasons.map(r => <p>{r}</p>) : "" : planned ? "" : errors.map(e => <span>{e.replace(/['"]+/g, '')}</span>)}
                </div>


            </Popover>
        </div>
    );
}