import {
  LOAD_TEMPLATE_SUCCESS,
  LOAD_TEMPLATE_FAILURE,
  LOAD_SDN_SUCCESS,
  LOAD_SDN_FAILURE,
  GENERATE_TEMPLATE_SUCCESS,
  TEMPLATE_RESET_UI_STATE,
  GENERATE_TEMPLATE_ERROR,
  DELETE_FLOW_ERROR,
  DELETE_FLOW_SUCCESS,
  FLOW_STATUS_CHANGE_FAIL,
  CONFIRM_TEMPLATE_SUCCESS,
  CONFIRM_TEMPLATE_ERROR,
  LOAD_REPEATED_ORDERS,
  SUBMIT_IQ_SERIAL_SUCCESS,
  SUBMIT_IQ_SERIAL_FAILED,
  MODIFY_FLOW_SUCCESS,
  MODIFY_FLOW_ERROR,
  LOAD_ALL_USERS_SUCCESS,
  FETCH_TENTATIVE_ORDERS_SUCCESS,
  FETCH_SELECTED_TENTATIVE_FLOW_SUCCESS,
  LOAD_RP_SDN_SUCCESS,
} from "../types/tnaTaskTypes";

type Temp = {
  templateIDs: string[];
  sdns: string[];
  sdnsLoading: boolean;
  RPsdns: string[];
  RPsdnsLoading: boolean;
  repeatedOrders: any[];
  success: string;
  error: string;
  selectedOrder: string;
  tasks: any[];
  inqDescription: any;
  tnaUsers: any[];
  tentativeOrders: any[];
};

const initialTempState = {
  templateIDs: [],
  sdns: [],
  sdnsLoading: true,
  RPsdns: [],
  RPsdnsLoading: true,
  repeatedOrders: [],
  success: "",
  error: "",
  selectedOrder: "",
  tasks: [],
  inqDescription: null,
  tnaUsers: [],
  tentativeOrders: [],
} as Temp;

export function templateReducer(state = initialTempState, action: any) {
  switch (action.type) {
    case LOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateIDs: action.payload,
      };
    case LOAD_TEMPLATE_FAILURE:
      return {
        ...state,
      };
    case LOAD_SDN_SUCCESS:
      return {
        ...state,
        sdns: action.payload,
        sdnsLoading: false
      };
    case LOAD_RP_SDN_SUCCESS:
      return {
        ...state,
        RPsdns: action.payload,
        RPsdnsLoading: false
      };
    case LOAD_SDN_FAILURE:
      return {
        ...state,
      };
    case GENERATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateIDs: [],
        sdns: [],
        success: action.payload.successMessage,
        error: "",
        selectedOrder: action.payload.order,
        tasks: action.payload.tasks,
        inqDescription: action.payload.inqDescription,
      };
    case GENERATE_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.payload.error
      };
    case MODIFY_FLOW_SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: "",
      };
    case MODIFY_FLOW_ERROR:
      return {
        ...state,
        error: action.payload,
        success: "",
      };
    case TEMPLATE_RESET_UI_STATE:
      return {
        ...state,
        success: "",
        error: "",
      };

    case CONFIRM_TEMPLATE_SUCCESS:
      return {
        ...state,
        tasks: [],
        success: action.payload,
        error: "",
        selectedOrder: "",
      };

    case CONFIRM_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_FLOW_SUCCESS:
      return {
        ...state,
        tasks: [],
        success: action.payload,
        error: "",
        selectedOrder: "",
        inqDescription: null,
      };

    case FLOW_STATUS_CHANGE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_FLOW_ERROR:
      return {
        ...state,
      };

    case LOAD_REPEATED_ORDERS:
      return {
        ...state,
        repeatedOrders: action.payload,
      };
    case SUBMIT_IQ_SERIAL_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
      };
    case SUBMIT_IQ_SERIAL_FAILED:
      return {
        ...state,
        error: action.payload.message,
      };
    case LOAD_ALL_USERS_SUCCESS:
      return {
        ...state,
        tnaUsers: action.payload,
      };
    case FETCH_TENTATIVE_ORDERS_SUCCESS:
      return {
        ...state,
        tentativeOrders: action.payload,
      };
    case FETCH_SELECTED_TENTATIVE_FLOW_SUCCESS:
      return {
        ...state,
        tasks: action.payload.flow,
        inqDescription: action.payload.inqDescription,
        success: action.payload.successMessage,
      };
    default:
      return state;
  }
}
