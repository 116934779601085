import React from "react";
import { Datagrid, EditButton, Filter, List, ReferenceField, TextField, TextInput } from "react-admin";

const ItemOpFilter = props => (
  <Filter {...props}>
    {/* CustomerReferenceField */}
    <TextInput label="search by operation" source="code" alwaysOn/>
    <TextInput label="Search by Item" source="produced_item" alwaysOn />


    
  </Filter>
);

export const ItemOperations = props => (
<List {...props} filters={<ItemOpFilter /> } bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="">
      <TextField source="id" />
      <TextField source="smv" />
      <TextField source="initial_lead_time" />
      <TextField source="batch_size" />
      <TextField source="priority" />
      <TextField source="produced_item" />
      <ReferenceField link="" label="Operation" source="operation" reference="operations">
        <TextField source="code" />
      </ReferenceField>
      <ReferenceField link="" label="Produced Item" source="produced_item" reference="items">
        <TextField source="code" />
      </ReferenceField>
      <TextField source="workstation" />
      <ReferenceField link="" label="Workstation" source="workstation" reference="workstations">
        <TextField source="code" />
      </ReferenceField>
      <EditButton label="Edit" className='btn-success' />
    </Datagrid>
  </List>
);
