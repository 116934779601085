import React from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  usePermissions,
  WithPermissions,
} from "react-admin";
import {
  TopToolbar,
  // CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
} from "react-admin";
import CreateButton from "../../components/CustomCreateButton";
import { cloneElement, useMemo } from "react";
import Confirmation from "../../components/Confirmation";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../components/ChangeDiv";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const PlantFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search plants" alwaysOn />
    <ReferenceInput label="Plants" source="plant" reference="plants" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const Plants = (props) => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  
  const Actions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
    } = useListContext();

    return (
      <TopToolbar
        className={classes.buttonContainer}
        {...sanitizeListRestProps(rest)}
      >
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
            className: "btn_",
          })}
        <CreateButton
          basePath={basePath}
          title="permissions"
          className="btn-secondry"
          disabled={!permissions?.permissions.includes("plants_create")}

          // title='Click here to create plant' disabled={!permissions?.permissions.includes("plants_create")}
        />
        <ExportButton
          className="btn_ btn_primary"
          title="Click here to export plant"
          disabled={!permissions?.permissions.includes("plants_export")}
        />
      </TopToolbar>
    );
  };
  return (
    <List
      {...props}
      actions={<Actions />}
      bulkActionButtons={false}
      perPage={25}
    >
      <WithPermissions
        render={({ permissions, ...props }) =>
          permissions?.permissions.includes("plants") ? (
            <ChangeDiv>
              {props => {
                return <Datagrid>
                <TextField source="id" />
                <TextField source="code" />
                <TextField source="name" />
                <ReferenceField
                  label="Site"
                  source="site"
                  reference="sites"
                  link={false}
                >
                  <TextField source="code" />
                </ReferenceField>
                <WithPermissions
                  render={({ permissions, ...props }) => (
                    <>
                      {" "}
                      <EditButton
                        {...props}
                        className="btn_ btn_edit"
                        title="Click here to edit plant"
                        disabled={
                          !permissions?.permissions.includes("plants_edit")
                        }
                      />
                    </>
                  )}
                />
              </Datagrid>
              }}
            </ChangeDiv>
            
          ) : null
        }
      />
    </List>
  );
};
