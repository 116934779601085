import React, { useMemo, useState } from "react";
import FormUploadConfirmationDialog from "../FormUploadConfirmationDialog";
import Button from "@material-ui/core/Button";
const TechnicalReviewForm = ({toEdit, prevFormData, prevFormName, formName }) => {
  const [formData, setFormData] = useState( toEdit ? prevFormData : { descriptionTable: [] });
  const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);


  const options = {
    scale: 0.8,
  };

  const onChange = (event, field) => {
    const id = event.target.name;
    const type = event.target.type;

    const newFormData = formData;
    if (type === "checkbox") {
      newFormData[field] = !formData[field];
      setFormData(newFormData);
    } else {
      const value = event.target.value;

      newFormData[field] = value;
      setFormData(newFormData);
    }
  };

  const uiInputProps = (formData, id) => {
    const props = {
      required: true,
      defaultValue: formData[id],
      checked: formData[id],
      onChange: (e) => {
        onChange(e, id);
      },
    };

    if (id.toLowerCase().includes("date")) {
      props["type"] = "date";
    }

    return props;
  };

  const uploadInputProps = (formData, id) => {
    if (formData[id]) {
      return {
        value: formData[id],
        checked: formData[id],
      };
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      setOpenFormUploadDialog(true);
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const tables = {
    sections: {
      1: [
        ["Inq No", "Mat 1 type", "Trim 01"],
        ["Sample No #", "Mat 2 type", "Trim 02"],
        ["Smaple qty", "Mat 3 type", "Trim 03"],
        ["SMV"],
      ],
      2: [
        ["Print type", "Embroidery", "Hand work"],
        ["Finish form/Pnl form", "Finish form/Pnl form", "Finish form/Pnl form"],
        ["Any other"],
      ],
      3: ["Size range", "Lbl Specifications", "Adhesive application"],
      4: ["Chemical compliance", "Mounting operation", "Sole attaching"],
      5: ["Machine", "SMV", "Tgt 100%", "Mat Type", "Trims"],
      6: ["Work aids", "Responsible persons", "Date"],
      7: ["Special Quality Points", "Other Critical Points", "Sole Manipulation Method"],
      8: ["Special Quality Points", "Other Critical Points"],
    },
  };

  const Form = ({ formData, inputProps, isUI=true }) => {
    return (
      <div>
        <table class="form-table" style={{ border: "none" }}>
          <tr style={{ border: "none" }}>
            <td class="form-td" style={{ border: "none" }} rowspan="2">
              <p>
                DATE:
                <input
                  class="form-input"
                  {...inputProps(formData, "DATE")}
                ></input>
              </p>
            </td>
            <td class="form-td" style={{ border: "none" }}>
              <center>
                <h3>D. Samsan Industries (Pvt.) Ltd.</h3>
              </center>
            </td>
          </tr>
          <tr style={{ border: "none" }}>
            
            <center>
              <h4>Technical Review Check List</h4>
            </center>
          </tr>
        </table>

        <table class="form-table" style={{ border: "none" }}>
          <tr style={{ border: "none" }}>
            <th class="form-th" style={{ border: "none" }}>
              Buyer
            </th>
            <td class="form-td">
              <input
                class="form-input"
                {...inputProps(formData, "Buyer")}
              ></input>
            </td>
            <th class="form-th" style={{ border: "none" }} colspan="2">
              Material details:
            </th>
            <th class="form-th" style={{ border: "none" }} colspan="2">
              Trims Details:
            </th>
          </tr>
          {tables.sections[1].map((row) => (
            <tr style={{ border: "none" }}>
              {row.map((column) => (
                <>
                  <th class="form-th" style={{ border: "none" }}>
                    {column}
                  </th>
                  <td class="form-td">
                    <input
                      class="form-input"
                      {...inputProps(formData, column)}
                    ></input>
                  </td>
                </>
              ))}
            </tr>
          ))}
        </table>
        <h5>Embellishment Detials:</h5>
        <table class="form-table" style={{ border: "none" }}>
          {tables.sections[2].map((row) => (
            <tr style={{ border: "none" }}>
              {row.map((column, index) => (
                <>
                  <th class="form-th" style={{ border: "none" }}>
                    {column}
                  </th>
                  <td class="form-td">
                    <input
                      class="form-input"
                      {...inputProps(formData, `${index}${column}`)}
                    ></input>
                  </td>
                </>
              ))}
            </tr>
          ))}
        </table>
        <table>
          {tables.sections[3].map(
            (table) => (
              <tr>
                <th class="form-th">{table}</th>
                <td class="form-td">
                  <input
                    class="form-input"
                    {...inputProps(formData, table)}
                  ></input>
                </td>
              </tr>
            )
          )}

          <tr>
            <th class="form-th">Laboratory specifications</th>
            <td class="form-td">
              { isUI ?
                <textarea
                class="form-textarea"
                {...inputProps(formData, "Laboratory specifications")}
              ></textarea>: 
              <input className="form-input" {...inputProps(formData, "Laboratory specifications")} ></input>
              }
            </td>
          </tr>
          {tables.sections[4].map(
            (table) => (
              <tr>
                <th class="form-th">{table}</th>
                <td class="form-td">
                  <input
                    class="form-input"
                    {...inputProps(formData, table)}
                  ></input>
                </td>
              </tr>
            )
          )}
        </table>
        <h5>Special production details</h5>
        <table>
          <tr>
            <th class="form-th" colspan="2">
              Critical operation analysis
            </th>
            <td class="form-td" colspan="8">
              { isUI ?
                <textarea
                class="form-textarea"
                {...inputProps(formData, "Critical operation analysis")}
              ></textarea>: 
              <input className="form-input" {...inputProps(formData, "Critical operation analysis")} ></input>
              }
            </td>
          </tr>
          <tr>
            <td class="form-td" colspan="2">{`Operation no 01`}</td>
            <td class="form-td" colspan="8">
              <input
                class="form-input"
                {...inputProps(formData, "Operation no 01")}
              ></input>
            </td>
          </tr>

          <tr>
            {tables.sections[5].map(
              (column, index) => (
                <>
                  <th class="form-th">{column}</th>
                  <td class="form-td">
                    <input
                      class="form-input"
                      {...inputProps(formData, `${index}${column}`)}
                    ></input>
                  </td>
                </>
              )
            )}
          </tr>
          <tr>
            <th class="form-th" colspan="2">
              Require attachements
            </th>
            <td class="form-td" colspan="8">
              <input
                class="form-input"
                {...inputProps(formData, "Require attachments")}
              ></input>
            </td>
          </tr>
          <tr>
            <th class="form-th" colspan="2">
              Require work-aids
            </th>
            <td class="form-td" colspan="8">
              <input
                class="form-input"
                {...inputProps(formData, "Require work-aids")}
              ></input>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            {tables.sections[6].map((column) => (
              <th class="form-th" colspan="4">
                {column}
              </th>
            ))}
          </tr>

          {[1, 2, 3, 4].map((row) => (
            <tr>
              {tables.sections[6].map((column) => (
                <td class="form-td" colspan="4">
                  <input
                    class="form-input"
                    {...inputProps(formData, `${row}${column}`)}
                  ></input>
                </td>
              ))}
            </tr>
          ))}
        </table>
        <table>
          <tr>
            <th class="form-th">Machine/ Tool Adjustment Specifications</th>
            <td class="form-td">
              <input
                class="form-input"
                {...inputProps(
                  formData,
                  `Machine/ Tool Adjustment Specifications`
                )}
              ></input>
            </td>
          </tr>
          {[1, 2, 3, 4].map((row) => (
            <tr>
              <td class="form-td" colspan="10">
                <input
                  class="form-input"
                  {...inputProps(
                    formData,
                    `${row}Machine/ Tool Adjustment Specifications`
                  )}
                ></input>
              </td>
            </tr>
          ))}
        </table>
        {tables.sections[7].map((row) => (
          <>
            <p>{row}</p>
            <table>
              <tr>
                <td class="form-td">
                  <input
                    class="form-input"
                    {...inputProps(formData, `${row}`)}
                  ></input>
                </td>
              </tr>
            </table>
          </>
        ))}

        {[2, 3, 4, 5].map((operation) => (
          <>
            
            <h6>Critical operation analysis:</h6>
            <table>
              <tr>
                <td
                  class="form-td"
                  colspan="2"
                >{`Operation no ${operation}`}</td>
                <td class="form-td" colspan="8">
                  <input
                    class="form-input"
                    {...inputProps(formData, `Operation no ${operation}`)}
                  ></input>
                </td>
              </tr>

              <tr>
                {tables.sections[5].map(
                  (column, index) => (
                    <>
                      <th class="form-th">{column}</th>
                      <td class="form-td">
                        <input
                          class="form-input"
                          {...inputProps(
                            formData,
                            `${operation}${index}${column}`
                          )}
                        ></input>
                      </td>
                    </>
                  )
                )}
              </tr>
              <tr>
                <th class="form-th" colspan="2">
                  Require attachements
                </th>
                <td class="form-td" colspan="8">
                  <input
                    class="form-input"
                    {...inputProps(formData, `${operation}Require attachments`)}
                  ></input>
                </td>
              </tr>
              <tr>
                <th class="form-th" colspan="2">
                  Require work-aids
                </th>
                <td class="form-td" colspan="8">
                  <input
                    class="form-input"
                    {...inputProps(formData, `${operation}Require work-aids`)}
                  ></input>
                </td>
              </tr>
            </table>
            <table>
              <tr>
                {tables.sections[6].map((column) => (
                  <th class="form-th" colspan="4">
                    {column}
                  </th>
                ))}
              </tr>

              {[1, 2, 3, 4].map((row) => (
                <tr>
                  {tables.sections[6].map(
                    (column) => (
                      <td class="form-td" colspan="4">
                        <input
                          class="form-input"
                          {...inputProps(
                            formData,
                            `${operation}${row}${column}`
                          )}
                        ></input>
                      </td>
                    )
                  )}
                </tr>
              ))}
            </table>
            <table>
              <tr>
                <th class="form-th">Sewing/ Cutting Method specifications</th>
                <td class="form-td">
                  <input
                    class="form-input"
                    {...inputProps(
                      formData,
                      `${operation}Sewing/ Cutting Method specification`
                    )}
                  ></input>
                </td>
              </tr>
              {[1, 2, 3, 4].map((row) => (
                <tr>
                  <td class="form-td" colspan="10">
                    <input
                      class="form-input"
                      {...inputProps(
                        formData,
                        `${operation}${row}Sewing/ Cutting Method specification`
                      )}
                    ></input>
                  </td>
                </tr>
              ))}
            </table>
            <table>
              <tr>
                <th class="form-th">Machine/ Tool Adjustment Specifications</th>
                <td class="form-td">
                  <input
                    class="form-input"
                    {...inputProps(
                      formData,
                      `${operation}Machine/ Tool Adjustment Specifications`
                    )}
                  ></input>
                </td>
              </tr>
              {[1, 2, 3, 4].map((row) => (
                <tr>
                  <td class="form-td" colspan="10">
                    <input
                      class="form-input"
                      {...inputProps(
                        formData,
                        `${operation}${row}Machine/ Tool Adjustment Specifications`
                      )}
                    ></input>
                  </td>
                </tr>
              ))}
            </table>
            {tables.sections[8].map((row) => (
              <>
                <p>{row}</p>
                <table>
                  <tr>
                    <td class="form-td">
                      <input
                        class="form-input"
                        {...inputProps(formData, `${operation}${row}`)}
                      ></input>
                    </td>
                  </tr>
                </table>
              </>
            ))}
          </>
        ))}
        <h6>Participants</h6>
        <table>
          {[1, 2, 3, 4, 5, 6, 7].map((row) => (
            <tr>
              {[1, 2, 3, 4].map((column) => (
                <td class="form-td">
                  <input
                    class="form-input"
                    {...inputProps(formData, `${column}${row}Participants`)}
                  ></input>
                </td>
              ))}
            </tr>
          ))}
        </table>
        <table>
          <tr>
            <td class="form-td">
              <input
                class="form-input"
                {...inputProps(formData, "Signature - PD Merchant")}
              ></input>
            </td>
            <td class="form-td">
              <input
                class="form-input"
                {...inputProps(formData, "Signature Head of Technical")}
              ></input>
            </td>
          </tr>
          <tr>
            <th class="form-th">Signature - PD Merchant</th>
            <th class="form-th">Signature Head of Technical</th>
          </tr>
        </table>
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Form formData={formData} inputProps={uiInputProps} />
        <Button style={{marginBlock: 40}} variant="contained" type="submit">
          Submit
        </Button>
      </form>
      <FormUploadConfirmationDialog
        isOpen={openFormUploadDialog}
        setIsOpen={setOpenFormUploadDialog}
        Form={Form({ formData, inputProps: uploadInputProps, isUI: false })}
        formData={formData}
        prevFormName={prevFormName}
        formName={formName}
      />
    </div>
  );
};

export default TechnicalReviewForm;
