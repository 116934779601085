import { Button } from "@material-ui/core";
import PlanIcon from "@material-ui/icons/Apps";
import { makeStyles } from "@material-ui/core/styles";
import React, { cloneElement } from "react";
import LinkButton from "../../components/LinkButton";
import CreateButton from "../../components/CustomCreateButton";
import ShowButton from "../../components/CustomShowButton";
import {
  // CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  Filter,
  List,
  Query,
  sanitizeListRestProps,
  SelectInput,
  // ShowButton,
  TextField,
  TopToolbar,
  useListContext,
  usePermissions,
} from "react-admin";
import { Link } from "react-router-dom";
import SmallLoading from "../../components/loading/SmallLoading";
import { ChangeDiv } from "../../components/ChangeDiv";

const payload = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: "plant_id", order: "ASC" },
};

const useStyles = makeStyles((theme) => ({
  // buttonContainer: {
  //   display: "flex",
  //   width: window.innerWidth * 0.1,
  //   flexDirection: "row",
  //   justifyContent: "space-around",
  // },
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const ListActions = (props) => {
  // const { permissions } = usePermissions();
  const { className, exporter, filters, maxResults, permissions, ...rest } =
    props;
  const classes = useStyles();
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar
      className={classes.buttonContainer}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
          className: "btn_",
        })}

      <CreateButton
        title="Click here to create plan board"
        disabled={!permissions?.permissions.includes("planboards_create")}
        basePath={basePath}
        variant="contained"
      />
      <ExportButton
        className="btn_ btn_primary  exportAlign"
        title="Click here to Export plan board"
        disabled={!permissions?.permissions.includes("planboards_export")}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        variant="contained"
      />
    </TopToolbar>
  );
};

const PlanBoardFilter2 = (props) => {
  return (
    <Query type="getList" resource="plants" payload={payload}>
      {({ data, loading, error }) => {
        if (loading) {
          return <SmallLoading />;
        }
        if (error) {
          return "Error";
        }
        const choices = data;
        const allChoice = { id: 0, site: 0, code: "All", name: "All" };
        if (choices.length === 0 || choices[0].id !== 0) {
        }
        return (
          <Filter {...props}>
            <SelectInput
              label="Plant"
              source="plant_id"
              choices={[allChoice, ...choices]}
              default={2}
              alwaysOn
            />
          </Filter>
        );
      }}
    </Query>
  );
};

export const PlanBoardList = (props) => {
  const { history } = props;
  if (history.location.state && history.location.state.plantFilter) {

  } else {
    history.location.state = { plantFilter: 0 };
  }
  const { permissions } = usePermissions();


  return (
    <List
      perPage={25}


      actions={
        <ListActions
          // className={classes.buttonContainer}
          permissions={permissions}
        />
      }
      {...props}
      filters={
        ["ADMIN", "SITE"].includes(permissions?.access_level) ? (
          <PlanBoardFilter2 {...props} />
        ) : null
      }
      filterDefaultValues={
        permissions === "SUB"
          ? null
          : { plant_id: history.location.state.plantFilter }
      }
      bulkActionButtons={false}

      sort={{ field: history.location.search ? 'id' : 'plant', order: 'ASC' }}
    >
      {/* <div style={{ height: window.innerHeight * 0.7, overflowY: "auto" }}> */}
      <ChangeDiv>
        {props => {
          return <Datagrid>
            <TextField source="id"
            // sortBy = "ASC"
            />

            <TextField source="plant_name" sortBy='plant__name' />
            <TextField source="code" />
            <TextField source="algorithm" />
            <PlanBoardButton
              title="Click here to view plan"
              disabled={!permissions?.permissions.includes("planboards_viewplan")}
            />
            <ShowButton
              title="Click here to settings"
              disabled={!permissions?.permissions.includes("planboards_settings")}
              label="Settings"
              className="btn_ btn_secondry"
            />
            <EditButton
              title="Click here to edit plan board"
              disabled={!permissions?.permissions.includes("planboards_edit")}
              label="Edit"
              className="btn_ btn_edit"
            />
          </Datagrid>
        }}
      </ChangeDiv>
      {/* </div> */}
    </List>
  );
};

const PlanBoardButton = (props) => (
  // <Link to={props.disabled ? "#" : `planBoard/${props.record.id}`}>
  <LinkButton
    to={`planBoard/${props.record.id}`}
    className="btn_ btn_primary"
    // color="primary"
    disabled={props.disabled}
    title={props.title}
    startIcon={<PlanIcon />}
  >
    View Plan
  </LinkButton>
  // </Link>
);
