import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { cloneElement, Fragment, useState } from 'react';
import { ForecastDialogContent, RMRequestsContent } from './dialogContent';
import {  BulkRMButtons } from './bulkAction'
import { CardActions, Mutation, useRefresh, sanitizeListRestProps } from "react-admin";
import CSVReader from 'react-csv-reader';
import { showToastSuccess, showToastError } from "../../lib/toas";


const useStyles = makeStyles((theme) => ({
    buttons: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
}));

export const ActionButton = ({title, Content, label, dialogWidth=null, fullWidth=false}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const classes = useStyles()

    const handleClose = () => setIsOpen(false)
    const handleOpen = () => setIsOpen(true)

    return (
        <Fragment>
            <div className={classes.buttons} >
                <Button
                    onClick={handleOpen}
                    variant='contained'
                    color='primary'
                >
                    {label}
                </Button>
            </div>
            <Dialog
                fullWidth={fullWidth} 
                maxWidth={dialogWidth===null ? 'md' : dialogWidth}
                open={isOpen} 
                onClose={handleClose} 
            >
                {title === null ? null : <DialogTitle>{title}</DialogTitle>}
                <DialogContent>
                    {<Content closeAction={handleClose} />}
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

const RMRequestsButton = () => {
    return (
        <ActionButton
            label='RM requests'
            title={null}
            Content={RMRequestsContent}
            dialogWidth='md'
            fullWidth={true}
        />
    )
}

const ForecastButton = () => {
    return (
        <ActionButton
            label='View Forecast'
            title='Forecasts'
            Content={ForecastDialogContent}
            dialogWidth='md'
            fullWidth={true}
        />
    )
}

export const RmActions = ({
    basePath,
    classname,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    maxResults,
    permanentFilter,
    ...rest
}) => {
    const classes = useStyles()
    return (
    <CardActions {...sanitizeListRestProps(rest)} >
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
            className: "btn_"
        })}
        <RMRequestsButton />
        <ForecastButton />
        <UploadRMButton />
    </CardActions>
    )
}

const UploadRMButton = ({ onSuccess }) => {

    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState(false)
  
    const refresh = useRefresh()
    const onUploadSuccess = () => {
      showToastSuccess("Upload success!");
      refresh()
    }
  
    const onUploadError = () => {
      showToastError("Upload Failed");
      refresh()
    }
  
    return <div style={{marginLeft: 7.5, marginTop: 7.5}}>
      <Button variant="contained" color="primary" onClick={() => setIsOpen(true)} > Upload RM</Button>
      <Dialog title="Raw Material CSV Upload" open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <CSVReader onFileLoaded={data => setData(data)} />
          {data && <div>Records {data.length - 1} </div>}
          <DialogActions>
            <Button onClick={() => setIsOpen(false)}>Close</Button>
            {data && <Mutation
              type='CUSTOM'
              resource="materialInventory"
              payload={{ action: 'upload_rm', method: 'POST', body: data }}
              options={{
                onSuccess: onUploadSuccess,
                onFailure: onUploadError
              }}
            >
              {(action) => (
                <Button variant="contained" color="primary" onClick={() => { setIsOpen(false); action() }}> Save</Button>
              )}
            </Mutation>}
  
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  }
