import { DELETE_HOLIDAY, DELETE_HOLIDAY_SUCCESS, DELETE_HOLIDAY_FAILED } from './../types/holidayTypes';
import moment from "moment";
import { format_to_date_for_api } from "../../lib/date_time";
import { CREATE_HOLIDAY, CREATE_HOLIDAY_FAILED, CREATE_HOLIDAY_SUCCESS, LOAD_HOLIDAYS, LOAD_HOLIDAYS_FAILED, LOAD_HOLIDAYS_SUCCESS, SET_DATE_RANGE, UPDATE_SHIFT_PATTERN, UPDATE_SHIFT_PATTERN_FAILED, UPDATE_SHIFT_PATTERN_SUCCESS, SET_SHIFT_PATTERNS } from "../types/holidayTypes";

export const initialState = {
    date_range: {
        start_date: format_to_date_for_api(moment().startOf('month')),
        end_date: format_to_date_for_api(moment().endOf('month'))
    },
    holidays: [],
    shiftPatterns: [],
    loading: false,
    section: null
}

export const holidayReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_DATE_RANGE: {
            const {date_range} = action.payload
            return {...state, date_range}
        }
        case LOAD_HOLIDAYS: {
            const {section} = action.payload
            return {...state, loading: true, section: section}
        }
        case LOAD_HOLIDAYS_FAILED: {
            return {...state, loading: false}
        }
        case LOAD_HOLIDAYS_SUCCESS: {
            const {holidays} = action.payload
            return {...state, holidays, loading: false}
        }
        case SET_SHIFT_PATTERNS: {
            const {shiftPatterns} = action.payload
            return {...state, shiftPatterns}
        }
        case UPDATE_SHIFT_PATTERN: {
            return {...state, loading: true}
        }
        case UPDATE_SHIFT_PATTERN_SUCCESS: {
            return {...state, loading: false}
        }
        case UPDATE_SHIFT_PATTERN_FAILED: {
            return {...state, loading: false}
        }
        case CREATE_HOLIDAY: {
            return {...state, loading: true}
        }
        case CREATE_HOLIDAY_SUCCESS: {
            return {...state, loading: false}
        }
        case CREATE_HOLIDAY_FAILED: {
            return {...state, loading: false}
        }
        case DELETE_HOLIDAY: {
            return {...state, loading: true}
        }
        case DELETE_HOLIDAY_SUCCESS: {
            return {...state, loading: false}
        }
        case DELETE_HOLIDAY_FAILED: {
            return {...state, loading: false}
        }
        default:
            return state
    }
}