import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { forwardRef } from "react";
import { useDataProvider } from "react-admin";
import { connect } from "react-redux";
import { theme } from "../../layout/theme";
import { format_to_date } from "../../lib/date_time";
import {
  getPlants,
  getSentTransfers,
} from "../../store/selectors/transferSelectors";
import TransferDialog from "./TransferDialog";

const treeData = (ts, dOs) => {
  const treeData = [];
  const _ts = ts.map((transfer) => ({
    ...transfer,
    Id: transfer.id,
    id: `t${transfer.id}`,
  }));
  treeData.push(..._ts);
  const ts_ids = ts.map((t) => t.id);
  const _dOs = dOs
    .filter((dO) => ts_ids.includes(dO.transfer_id))
    .map((dO) => ({ ...dO, parentId: `t${dO.transfer_id}` }));
  treeData.push(..._dOs);
  // ts.map(transfer => {
  //   const values = [];
  //   const t = { ...transfer, Id: transfer.id, id: `t${transfer.id}` };
  //   const data = dOs
  //     .filter(dO => transfer.demand_ops.includes(dO.id))
  //     .map(dO => ({ ...dO, parentId: t.id }));
  //   values.push(t);
  //   values.push(...data);
  //   treeData.push(...values);
  // });
  return treeData;
};

const AllTransfersTab = ({ dOs, plants, sentTransfers }) => {
  const filteredData = dOs;

  const pendingDOs = filteredData.filter(
    (dO) => !dO.transfer_status || dO.transfer_status === "reverted"
  );
  const setupDOs = filteredData.filter((dO) => dO.transfer_status);
  const transfers = sentTransfers;
  // .filter(t => t.status !== 'reverted')
  const transferDOs = treeData(transfers, setupDOs);
  return (
    <>
      <AllTransfersTable
        setup={false}
        title="Transfers"
        data={transferDOs}
        // colorMappings={colorMappings} onSelect={onSelect}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const plants = getPlants(state);
  const sentTransfers = getSentTransfers(state);

  return {
    plants,
    sentTransfers,
  };
};

export default connect(mapStateToProps, null)(AllTransfersTab);

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const updateData = (array) => {
  const newArray = array.map((data) => {
    if (data.is_exchange === false) {
      data.is_exchange = true;
      return data;
    }
  });
  return newArray;
};

const getIdArray = (array) => {
  const newArray = array.map((data) => {
    return data.id;
  });
  return newArray;
};

const statusColor = (status) => {
  if (status == "accepted") {
    return "lightgreen";
  } else if (status == "declined") {
    return "#FF7F50";
  } else if (status == "pending") {
    return "#f9d572";
  } else if (status == "reverted") {
    return "#999999";
  }
};

const sortValue = {
  accepted: 3,
  pending: 1,
  reverted: 4,
  declined: 2,
};

function AllTransfersTable({
  data,
  colorMappings,
  onSelect,
  title,
  plants,
  setup,
  onRevert,
}) {
  const dataProvider = useDataProvider();
  return (
    <MaterialTable
      className="table-width-custom"
      style={{ marginBottom: "20px", marginTop: "20px" }}
      disableEnforceFocus
      icons={tableIcons}
      columns={[
        {
          title: "Status",
          field: "transfer_status",
          customSort: (a, b) => sortValue[a.status] - sortValue[b.status],
          defaultSort: "asc",
          render: (rowData, renderType) => {
            if (!setup && rowData.status && renderType == "row") {
              return (
                <span
                  style={{
                    padding: 5,
                    borderRadius: 5,
                    backgroundColor: statusColor(rowData.status),
                  }}
                >
                  {rowData.status}
                </span>
              );
            } else {
              return "";
            }
          },
        },
        {
          title: "ID",
          field: "id",
          cellStyle: { paddingLeft: 10, paddingBottom: 15, paddingTop: 15, height: '40px', },
          headerStyle: {
            paddingLeft: 10,
            paddingTop: 15,
            paddingBottom: 15,
          },
        },
        {
          title: "Transfer Date",
          field: "transfer_status",
          render: (rowData, renderType) => {
            if (!setup && rowData.transfer_date && renderType == "row") {
              return <span>{format_to_date(rowData.transfer_date)}</span>;
            } else {
              return "";
            }
          },
        },
        { title: "Item Code", field: "item_code" },
        {
          title: "From Plant",
          field: "plant_from",
          render: (rowData, renderType) => {
            if (!setup && rowData.plant_from && renderType == "row") {
              return <span>{rowData.plant_from}</span>;
            } else {
              return "";
            }
          },
        },

        { title: "Order", field: "order_code" },
        {
          title: "To Plant",
          field: "plant_to",
          render: (rowData, renderType) => {
            if (!setup && rowData.plant_to && renderType == "row") {
              return <span>{rowData.plant_to}</span>;
            } else {
              return "";
            }
          },
        },
        // {
        //   title: "Possible Start Date",
        //   field: "required_time_min",
        //   type: "date"
        // },
        {
          title: "Need Date",
          field: "required_time_max",
          type: "date",
          render: (rowData, renderType) => {
            if (rowData.required_time_max) {
              return <span>{format_to_date(rowData.required_time_max)}</span>;
            } else {
              return "";
            }
          },
        },
        
        {
          title: "Planned",
          field: "is_planned",
          render: (rowData, renderType) => {
            const is_planned =
              renderType == "row" ? rowData.is_planned : rowData;
            const text =
              renderType == "row"
                ? `${parseFloat(rowData.planned_quantity)}/${parseFloat(
                    rowData.quantity
                  )}`
                : is_planned
                ? "Planned"
                : "Not planned";

            return (
              <>
                {rowData.quantity ? (
                  <span
                    style={{
                      padding: 5,
                      borderRadius: 5,
                      backgroundColor: is_planned ? "lightgreen" : "#f9d572",
                    }}
                  >
                    {text}
                  </span>
                ) : (
                  ""
                )}
              </>
            );
          },
        },

        // {
        //   title: "Actions",
        //   render: (rowData, renderType) => {
        //     if (renderType == "row" && rowData.status && (rowData.status.split("-")[0] === "pending" || rowData.status == 'declined') ) {
        //       return <Button color="primary" variant='contained' onClick={()=> {onRevert({demand_operations: rowData.demand_ops, transfer_id: rowData.Id})}}>Revert</Button>;
        //     } else {
        //       return "";
        //     }
        //   }
        // }
      ]}
      parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
      data={data}
      options={{
        searchFieldStyle:{maxWidth:200},
        sorting: true,
        defaultExpanded: false,
        grouping: false,
        pageSize: 10,
        doubleHorizontalScroll: true,
        maxBodyHeight: 650,
        debounceInterval: 1000,
        padding: "default",
        selection: false,
        selectionProps: (rowData) => ({
          data: rowData,
          color: "primary",
          style: { padding: 0 },
        }),
      }}
      components={{
        Toolbar: (props) => {
          const { selectedRows } = props;
          return (
            <div>
              <MTableToolbar {...props} />
              {setup && selectedRows.length > 0 && (
                <div style={{ padding: "10px" }}>
                  <TransferDialog selectedRows={selectedRows} />
                  {/* <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      //const ids = getIdArray(selectedRows)
                      const data = updateData(selectedRows);
                      // const data = Object.keys(val)

                      dataProvider.CUSTOM_UPDATE_MENY("demandOp", { data });

                    }}
                  >
                    Transfer
                  </Button> */}
                </div>
              )}
            </div>
          );
        },
      }}
      title={title}
    />
  );
}
