import { AnyAction } from "redux"
import { BULK_ACTION_CLEAN, BULK_ACTION_SAVE, BULK_ACTION_SAVE_ERROR, BULK_ACTION_SAVE_SUCCESS, BULK_ACTION_SET_DATA } from "../types"
const initialState = {
    data: [],
    error: null,
    exicuted: false,
    loading: false
}

export const bulkActionReducer = (state = initialState, action: AnyAction) => {

    switch (action.type) {
        case BULK_ACTION_CLEAN: {
            return { ...initialState }
        }
        case BULK_ACTION_SET_DATA: {
            const { data, meta } = action.payload
            return { ...initialState, data, meta, loading: false }
        }
        case BULK_ACTION_SAVE: {
            const { data, meta } = action.payload
            return { ...initialState, data, meta, loading: true }
        }
        case BULK_ACTION_SAVE_SUCCESS: {
            const { response } = action.payload
            const data = state.data.map((row, i) => [...response[i], ...row])
            return { ...state, data, loading: false, exicuted: true }
        }
        case BULK_ACTION_SAVE_ERROR: {
            const { response } = action.payload
            return { ...state, errro: response, loading: false, exicuted: true }
        }
    }
    return state
}
