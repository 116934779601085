import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useDispatch } from "react-redux";
import { format_to_date_time } from "../../lib/date_time";

const useStyles = makeStyles({
  root: {
    width: 270,
    padding: 20,
    marginBottom: 20,
    marginTop:20
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const UnReadNotify = ({ notify }) => {
//{demand_operations: rowData.demand_ops, transfer_id: rowData.Id}
  //  const [notifycations, setnotifycation] = React.useState(notify);
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <div>
      {notify.length ? (
        notify.map((data) => (
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Plant {data.plant_id}
              </Typography>
              {/* <Typography variant="h5" component="h2">
              lent
            </Typography> */}
              <Typography variant="body2" component="p">
                Create date {format_to_date_time(data.time_stamp)}
                <br />
                {data.details.data.received_date &&
                  `Transfer date ${data.details.data.received_date}`}{" "}
              </Typography>
            </CardContent>
            {data.details.message && (
              <CardActions>
                <div style={{ display: "flex" }}>
                  <Button
                    size="small"
                    variant="contained"
                    className="btn-danger px-3 py-2"
                    color="secondary"
                    style={{ backgroundColor: "red", fontSize: "14px", }}
                  >
                    {data.details.message}
                  </Button>
                </div>
              </CardActions>
            )}
          </Card>
        ))
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              No Notifications
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default UnReadNotify;
