import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required
} from "react-admin";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { showToastError} from "../../../lib/toas";

export const DelayaReasonCreate = (props) => {
  const customToast = useCustomToast(props);

  const Title = () => {
    return <span>Create TNA Delay Reasons</span>;
  };

  const onFailure = (error) => {
    if (error) {
      showToastError(
        `This delay reason already exists.`
      );
    }
  };

  return (
    <Create onFailure={onFailure} className="col-md-6" onSuccess={customToast.onSuccess} {...props} title={<Title/>}>
      <SimpleForm
        toolbar={<CustomToolbar need={false} name="tna_delay_reasons" text="Reason" />}
      >
        <TextInput source="delay_reason" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
