import React from "react";
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  TextField,
} from "react-admin";

export const OrderResolveOption = (props) => (
  <List perPage={25} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="order" />
      <TextField source="order_demand" />
      <BooleanField source="force_select" />
      <BooleanField source="selected" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);
