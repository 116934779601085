import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { Loading, usePermissions } from "react-admin";
import { connect } from "react-redux";
import { getLoading, getNotificationCount, getReceved, getRecevedCount, getTransfer, getTransferCount } from "../../store/selectors/notificationSelectors";
import Received from "./Recived";
import Transfer from "./Transfers";
const styles = {
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

const NotifycationsPanal = ({
  loading,
  count,
  transfer,
  received,
  receivedCount,
  transferCount,
}) => {
  const [view, setview] = useState("Basic");
  // const counter = useSelector(
  //   (state) => state.custom.notifications.oderTransfer.transfers.transfer.read
  // );

  const permissions = usePermissions();
  const checkPermitions = () => {
    const userPermissions = permissions.permissions;
    if (["ADMIN", "SITE"].includes(permissions?.access_level)) {
      setview("Admin");
    }
  };

  React.useEffect(() => {
    checkPermitions();
  }, []);
  //const setUserPermissions = checkPermitions()
  // use test5
  return (
    <div>
      {loading && <Loading />}

      <>
        <Grid container style={{ marginTop: 10, marginLeft: 10 }}>
          <div>
            <Received notifycations={received} count={receivedCount} />
          </div>
          <div>
            <Transfer notifycations={transfer} count={transferCount} />
          </div>
        </Grid>
      </>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const loading = getLoading(state);
  const count = getNotificationCount(state);
  const transfer = getTransfer(state);
  const received = getReceved(state);
  const transferCount = getTransferCount(state);
  const receivedCount = getRecevedCount(state);
  return {
    loading,
    count,
    transfer,
    received,
    transferCount,
    receivedCount,
  };
};

export default connect(mapStateToProps, null)(NotifycationsPanal);
