import React from "react";
import { addField } from "react-admin";
import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";


const Form = withTheme(MuiTheme);

// const dataformat = {
//   "pattern": {
//     "weekday": [
//       ["07:20", 11],
//       ["19:00", 11]
//     ],
//     "saturday": [
//       ["07:20", 10],
//       ["19:00", 10]
//     ],
//     "sunday": []
//   },
//   "holidays": [
//     "2019-03-12",
//     "2019-12-25",
//     "2020-01-10",
//     "2020-01-15",
//     "2020-02-04"
//   ]
// };

const JsonSchemaEditor = ({ input, schema }) => {

  // const test = () => {
  //   return (input.value)
  // }

  const onChange = e => {
    input.onChange(e.formData);
  };

return <Form schema={schema} onChange={onChange} formData={input.value} children={<div>{' '}</div>}/>;
};

export default addField(JsonSchemaEditor);

