import React from "react";
import {
  Bar,
  BarChart,
  Area,
  AreaChart,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import SmallLoading from "../loading/SmallLoading";
import { Error, useQuery } from "react-admin";
import { SECONDARY_COLOR } from "../../constants";

const styles = {
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "100vw",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 10,
    paddingRight: 10,
    backgroundColor: "white",
    borderRadius: 5,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    width: "50%",
  },
  title: { textAlign: "center", fontSize: 15 },
  legend: { marginLeft: 20, paddingBottom: 10 },
  table: { top: 10, right: 10, left: 0, bottom: 0 },
  tick: { fontSize: 13 },
};

const tickFormatter = (value, index) =>
  value !== 0 ? `${Number(value) / 1000}K` : "0";

const QuantatiesVsTracking = (props) => {
  const innerHeight = window.innerHeight;
  const { data, loading, error } = useQuery({
    type: "CUSTOM_LIST",
    resource: "dashboard",
    payload: { action: "get_overall_quantities_vs_tracking", query: props },
  });

  if (loading) return <SmallLoading />;
  if (error) return <Error />;
  if (!data) return null;
  return (
    <ResponsiveContainer width="100%" height={innerHeight / 3}>
      <AreaChart data={data} margin={styles.table}>
        <defs>
          <linearGradient id="colorQuantity" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={SECONDARY_COLOR} stopOpacity={0.8} />
            <stop offset="95%" stopColor={SECONDARY_COLOR} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorTracking" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#98DEAD" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#98DEAD" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="date" tick={styles.tick} />
        <YAxis tickFormatter={tickFormatter} tick={styles.tick} />
        <CartesianGrid stroke="#ddd" />
        <Tooltip />
        <Legend align="left" wrapperStyle={styles.legend} />
        <Area
          type="linear"
          name="Total Quantity"
          dataKey="quantity_total"
          stroke={SECONDARY_COLOR}
          fillOpacity={1}
          fill="url(#colorQuantity)"
          dot={true}
          connectNulls={true}
        />
        <Area
          type="linear"
          name="Total Tracking"
          dataKey="tracking_total"
          stroke="#98DEAD"
          fillOpacity={1}
          fill="url(#colorTracking)"
          dot={true}
          connectNulls={true}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const QuantityDifferenceOverall = (props) => {
  const innerHeight = window.innerHeight;
  const { data, loading, error } = useQuery({
    type: "CUSTOM_LIST",
    resource: "dashboard",
    payload: { action: "get_quantity_difference_overall", query: props },
  });

  if (loading) return <SmallLoading />;
  if (error) return <Error />;
  if (!data) return null;
  return (
    <ResponsiveContainer width="100%" height={innerHeight / 3}>
      <BarChart data={data} margin={styles.table}>
        <CartesianGrid stroke="#ddd" />
        <XAxis dataKey="date" tick={styles.tick} name="Date" />
        <YAxis tick={styles.tick} tickFormatter={tickFormatter} />
        <Tooltip />
        <Bar dataKey="difference" fill={SECONDARY_COLOR} name="Difference" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const ChartsNew = (props) => {
  const innerHeight = window.innerHeight;
  return (
    <div style={styles.rowContainer}>
      <div style={{ ...styles.tableContainer, minHeight: innerHeight / 3 }}>
        <h4 style={styles.title}>Overall Quantities vs. Tracking</h4>
        <QuantatiesVsTracking {...props} />
      </div>
      <div style={{ width: 15 }}></div>
      <div style={{ ...styles.tableContainer, minHeight: innerHeight / 3 }}>
        <h4 style={styles.title}>Quantity Difference Overall</h4>
        <QuantityDifferenceOverall {...props} />
      </div>
    </div>
  );
};

export default ChartsNew;
