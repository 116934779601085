export const lineAttributesSchema = {
    type: ["object", "null"],

    properties: {
        supported_groups: {
            type: "string",
            description: "Add groups comma separated. No spaces",
            title: "Supported Groups"
        }
    }
};