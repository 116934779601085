import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { solve } from './algorithm'
import CsvLoader from './CsvLoader'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab'
import {
    SupplyChainState
} from './interfaces'
import { supplyChainCalculatePlan, supplyChainLoadData } from './reducer/actions'
import { getSupplyChainData } from './reducer/selectors'
import Table from './Table'
import TimeSeriesChart from './TimeSeriesChart';
import SimulationView from './SimulationView';
import DataImportView from './DataImportView';
import { AppBar } from '@material-ui/core';

const getTitleFromKey = (s: string) => {
    return s.split("_").map(s => `${s[0].toUpperCase()}${s.slice(1)}`).join(" ")
}

type SectionRecord = {
    key: keyof SupplyChainState,
    headers: string[],
    fromJson: (data: any) => any
}

export default function Board() {

    const dispatch = useDispatch()
    // useEffect(() => { dispatch(supplyChainCalculatePlan()) }, [])
    const [selectedTab, setSelectedTab] = useState(1)
    const tabs = [
        {
            label: "Dashboard",
        },
        {
            label: "Planning",
        },
        {
            label: "Import Data",
        },
        {
            label: "Settings",
        }
    ]
    const handleChangeTab = (event: any, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <div>
            <Tabs variant="scrollable" value={selectedTab} onChange={handleChangeTab} >
                {tabs.map(s => <Tab key={s.label} label={s.label} id={s.label} />)}
            </Tabs>
            <br />
            <div>
                {selectedTab == 0 && <TimeSeriesChart />}
                <SimulationView open={selectedTab == 1} />
                {selectedTab == 2 && <DataImportView />}
            </div>
            <br />
        </div>
    )
}
