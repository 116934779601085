import {
    ItemTimeBucket,
    SupplyChainState
} from "../interfaces";
import { SUPPLY_CHAIN_LOAD_DATA, SUPPLY_CHAIN_PUBLISH_REVISION, SUPPLY_CHAIN_UPDATE_ITEM_TIME_BUCKET, SUPPLY_CHAIN_CLEAR_REVISIONS } from "./types";
import getData from "../sample_data/data"
import moment from "moment";

export type RevisionState = { key: string, value: SupplyChainState }
type SupplyChainParentState = {
    data: SupplyChainState
    revisions: RevisionState[]
}


export const supplyChainReducer = (state: (SupplyChainParentState | undefined), action: any) => {
    if (state == undefined) {
        return {
            data: getData(),
            revisions: []
        }
    }
    if (action.type == SUPPLY_CHAIN_LOAD_DATA) {
        return { ...state, data: { ...state.data, [action.payload.key]: action.payload.data } }
    }
    if (action.type == SUPPLY_CHAIN_UPDATE_ITEM_TIME_BUCKET) {
        const { itemId, timeBucketId, key, value } = action.payload
        const isMatch = (v: ItemTimeBucket) => v.item_id == itemId && v.time_bucket_id == timeBucketId
        if (!state.data.item_time_bucket.some(isMatch)) {
            const newRecord = new ItemTimeBucket(timeBucketId, itemId, 0, 0, 0, 0, 0, 0)
            return { ...state, data: { ...state.data, item_time_bucket: [...state.data.item_time_bucket, { ...newRecord, [key]: value }] } }
        }
        const updatedItemBuckets = state.data.item_time_bucket.map(v => isMatch(v) ? ({ ...v, [key]: value }) : v)
        return { ...state, data: { ...state.data, item_time_bucket: updatedItemBuckets } }
    }
    if (action.type == SUPPLY_CHAIN_PUBLISH_REVISION) {
        const revistionCount = state.revisions.length
        const revisionKey = `Revision ${revistionCount + 1} - ${moment().format('MMMM-DD_HH:mm:ss')}`
        return {
            ...state,
            revisions: [...state.revisions, { key: revisionKey, value: state.data }]
        }
    }
    if (action.type == SUPPLY_CHAIN_CLEAR_REVISIONS) {
        return {...state, revisions: []}
    }
    return state
}