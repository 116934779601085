import React from "react";
import Select from "@material-ui/core/Select";
import { useDataProvider, useMutation, useRefresh, Loading } from "react-admin";
import LevelSelector from "./levelSelector";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CapacityChart from "./chart";
import Input from "@material-ui/core/Input";
import { showToastSuccess, showToastError } from "../../lib/toas";
import DatePicker from "react-datepicker";
import Chip from "@material-ui/core/Chip";
import InputLabel from "@material-ui/core/InputLabel";
import { ChartDataTable } from "./chartDataTable";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  meta: {
    display: "flex",
    justifyContent: "start",
    width: '100%',
    padding: "20px",
  },
  comp: {
    display: "flex",
    justifyContent: "space-between",
    width: "fit-content",
    marginRight: '20px'
  },
  paneContainer: {
    width: "1700px",
  },
  title: {
    padding: "30px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  select: {
    marginLeft: "20px",
    width: "max-content",
  },
  levelSelect: {
  },
  actionContainer: {
    display: 'flex',
    width: 'fit-content',
    justifyContent: 'space-between'
  },
  picker: {
    marginLeft: "50px",
  },
}));

export const CapacityLoadingChart = () => {
  const classes = useStyles();
  const [isChartShow, setIsChartShow] = React.useState(false);
  const [chartData, setChartData] = React.useState();
  const [level, setLevel] = React.useState();
  const [selectPlanboardList, setSelectPlanboardList] = React.useState();
  const [selectPlantsList, setSelectPlantsList] = React.useState();
  const [focusedMonth, setFocusedMonth] = React.useState();
  const [chartTableData, setChartTableData] = React.useState();
  const [componentsList, setComponentsList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState();
  const [yMax, setYMax] = React.useState(false);
  const [mutate, { mutateLoading, onSuccess, onError }] = useMutation();

  const levelOptions = [
    { code: "PLANT", value: "Plant" },
    { code: "PLANBOARD", value: "Plan Board" },
  ];

  const makeComponentSelect = (resource, payload, level) => {
    mutate(
      {
        type: "getList",
        resource: resource,
        payload: payload,
      },
      {
        onFailure: (e) => {
          showToastError("Capacity Loading Data Retrieval Failed:" + e.body);
        },
        onSuccess: (result) => {
          if (level == "PLANBOARD") {
            let planboardList = {};
            result.data.forEach((element) => {
              let code = element?.code;
              let id = element?.id;
              let plantName = element?.plant_name;
              if (Object.keys(planboardList).length > 0) {
                if (Object.keys(planboardList).includes(plantName)) {
                  planboardList[plantName].push({
                    id: id,
                    code: code,
                    plantName: plantName,
                  });
                } else {
                  planboardList[plantName] = [
                    { id: null, code: null, plantName: plantName },
                    { id: id, code: code, plantName: plantName }
                  ];
                }
              } else {
                planboardList[plantName] = [
                  { id: null, code: null, plantName: plantName },
                  { id: id, code: code, plantName: plantName },
                ];
              }
            });
            setSelectPlanboardList(planboardList);
            setSelectPlantsList();
          } else {
            let plantsList = [];
            result.data.forEach((element) => {
              let code = element.code;
              let id = element.id;
              plantsList.push({ id: id, code: code });
            });
            setSelectPlantsList(plantsList);
            setSelectPlanboardList();
          }
        },
      }
    );
  };

  const handleChange = async (event) => {
    const level = event.target.value;
    setComponentsList([])
    if (level != "") {
      console.log(level);
      let resource;
      setLevel(level);
      switch (level) {
        case "PLANT":
          resource = "plants";
          break;
        case "PLANBOARD":
          resource = "planBoards";
          break;
        default:
          break;
      }
      const payload = {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "id", order: "ASC" },
      };

      makeComponentSelect(resource, payload, level);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
  };

  const formatDataForChart = (data) => {
    let formattedData = {
      labels: [],
      switchingHours: [],
      currentOrders: [],
      previousOrders: [],
      advancedOrders: [],
      effectiveOrderse: [],
      currentEffectiveOrders: [],
      delayEffectiveOrders: [],
      monthlyCap: [],
    };

    data.forEach((element) => {
      var month = moment(new Date(element["month"])).format("MMMM YYYY");
      formattedData["labels"].push(month);
      formattedData["switchingHours"].push(element["switchingHours"]);
      formattedData["currentOrders"].push(element["currentOrderHours"]);
      formattedData["previousOrders"].push(element["previousOrderHours"]);
      formattedData["advancedOrders"].push(element["advancedOrderHours"]);
      formattedData["currentEffectiveOrders"].push(element["currentEffectiveOrderHours"]);
      formattedData["delayEffectiveOrders"].push(element["delayEffectiveOrderHours"]);
      formattedData["monthlyCap"].push(element["totalMaxUtilHours"]);
    });
    return formattedData;
  };

  const getRequiredHours = (chartData) => {
    let requiredHours = []

    for (var i = 0; i < chartData['labels'].length; i++) {
      requiredHours.push(chartData["switchingHours"][i] + chartData["currentOrders"][i] + chartData["previousOrders"][i] + chartData["advancedOrders"][i] + chartData["currentEffectiveOrders"][i] + chartData["delayEffectiveOrders"][i]);
    }
    return requiredHours
  }

  const getYMax = (chartData) => {
    const monthlyCapMax = chartData["monthlyCap"].reduce(function (a, b) {
      return Math.max(a, b, 0);
    }, 0);

    const requiredHours = getRequiredHours(chartData)
    const requiredHrMax= requiredHours.reduce(function (a, b) {
      return Math.max(a, b, 0);
    }, 0);

    return Math.max(monthlyCapMax,requiredHrMax)

  }

  const isValidInputs = () => {
    if (!level) {
      showToastError("Please select a Data Level");
      return false;
    }
    if (componentsList == []) {
      showToastError("Please select a Plant or Planboard");
      return false;
    }
    return true;
  };

  const viewChart = () => {
    setIsLoading(true);
    if (!isValidInputs()) {
      setIsLoading(false);
      return;
    }
    mutate(
      {
        type: "CUSTOM_LIST",
        resource: "allocations",
        payload: {
          action: "getCapacityLoadingData",
          method: "POST",
          body: {
            level: level,
            componentId: componentsList,
          },
        },
      },
      {
        onFailure: (e) => {
          setIsLoading(false);
          showToastError("Capacity Loading Data Retrieval Failed:" + e.body);
        },
        onSuccess: (e) => {
          setIsLoading(false);
          console.log(e);
          setChartTableData(e.data);
          const chartDt = formatDataForChart(e.data);
          setChartData(chartDt);
          let yMax = getYMax(chartDt)
          if (Number.isNaN(yMax)) {
            showToastError("No sufficient data to render the report");
            yMax = 10000;
            setIsChartShow(false);
          } else {
            setYMax(yMax);
            setIsChartShow(true);
          }
        },
      }
    );
  };

  const handleComponent = (event) => {
    const component = event.target.value;
    setComponentsList(component);

  };

  return (
    <div className={classes.paneContainer}>
      <h3 className={classes.title}>Capacity Utilization Chart</h3>
      <div className={classes.meta}>
        <div className={classes.actionContainer}>
          <div className={classes.comp}>
            <Select
              native
              className={classes.levelSelect}
              onChange={handleChange}
              value={level}
            >
              <option value="">Select a Level</option>
              {levelOptions.map(({ code, value }) => (
                <option value={code}>{value}</option>
              ))}
            </Select>
            {selectPlantsList ? (
              <Select
                className={classes.select}
                multiple
                value={componentsList}
                onChange={handleComponent}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value, index) => {
                      var selectedValues = selectPlantsList.filter(function (element) {
                        return element.id == value;
                      });
                      return (
                        <Chip key={index} label={selectedValues[0]?.code} className={classes.chip} />
                      );
                    })}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {selectPlantsList.map((element, key) => (
                  <MenuItem key={key + 1} value={element.id}>
                    {element.code}
                  </MenuItem>
                ))}
              </Select>
            ) : null}

            {selectPlanboardList ? (

              <Select
                multiple
                defaultValue=""
                className={classes.select}
                onChange={handleComponent}
                value={componentsList}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value, index) => {
                      var selectedValues = ([].concat.apply([], Object.values(selectPlanboardList))).filter(function (element) {
                        return element.id == value;
                      });
                      return (
                        <Chip key={index} label={selectedValues[0].code} className={classes.chip} />
                      );
                    })}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {Object.keys(selectPlanboardList).map((key, index) => {

                  return (selectPlanboardList[key].map(element => {

                    if (element.id) {
                      return (
                        <MenuItem
                          key={element.id}
                          value={element.id}
                        >
                          {element.code}
                        </MenuItem>)
                    } else {
                      return (
                        <MenuItem
                          key=""
                          value=""
                          disabled={true}
                        >
                          {element.plantName}
                        </MenuItem>
                      )
                    }
                  }
                  ))
                })}
              </Select>
            ) : null}
          </div>

          <Button className="btn_primary" onClick={viewChart}>
            Go
          </Button>
        </div>

      </div>
      {!isLoading ? (
        isChartShow ? (
          <>
            {" "}
            <CapacityChart data={chartData} yMax={yMax} />
          </>
        ) : null
      ) : (
        <Loading />
      )}
      {!isLoading ? (
        isChartShow ? (
          chartData ? (
            <>
              {" "}
              <ChartDataTable data={chartTableData} />
            </>
          ) : null
        ) : null
      ) : null}
    </div>
  );
};
