import {
  FETCH_REPEATED_ORDERS,
  MODIFY_FLOW,
  LOAD_ALL_TASKS,
  LOAD_UNASSIGNED_TASKS,
  COMPLETE_TASK,
  START_TASK,
  UPLOAD_FORM,
  GENERATE_AND_DOWNLOAD_FORM,
  SAVE_HOLIDAYS,
  DELETE_TNA_HOLIDAY,
  GENERATE_TEMPLATE,
  LOAD_ALL_USERS,
  DELETE_FLOW,
  CONFIRM_TEMPLATE,
  LOAD_SDN,
  LOAD_TEMPLATE,
  QR_ACCEPTANCE,
  FETCH_TENTATIVE_ORDERS,
  FETCH_SELECTED_TENTATIVE_FLOW,
  SUBMIT_IQ_SERIAL,
} from "../types/tnaTaskTypes";
import { SAVE_HOLIDAYS_SUCCESS } from "../types/tnaHolidayTypes"
import { put, select, takeEvery } from "redux-saga/effects";
import dataProvider from "../../api/dataProvider";
import {
  setAllTasks,
  setUnassignedTasks,
  setLoadSuccess,
  setTaskComplete,
  setTaskStarted,
  setTaskTransferredToApproval,
  setUploadFormSuccess,
  updateTask,
  generateTemplate,
  loadAllSuccessUsers,
  modifyFlowError,
  loadSDNs,
  loadTemplate,
  setDeleteOrderSuccess,
  setDeleteOrderError,
  setConfirmTemplateSuccess,
  setModifyFlowSuccess,
  setModifyFlowError,
  loadRepeatedOrders,
  setQRSendError,
  loadSDNSuccess,
  loadTemplateSuccess,
  fetchTentativeOrdersSuccess,
  fetchSelectedTentativeFlowSuccess,
  setStatusChangeFail,
  setConfirmTemplateError,
  setSubmitIQSerialSuccess,
  setSubmitIQSerialError,
  loadRPSDNSuccess,
  generateTemplateError,
} from "../actions/tnaTaskActions";
import { showToastSuccess, showToastError } from "../../lib/toas";
import { arrangeResponse } from "../../lib/taskPreview";

export function* tnaTaskSaga() {
  yield takeEvery(LOAD_ALL_TASKS, function* () {
    const resp = yield dataProvider.TNA_CUSTOM_LIST("itemTasks", {
      action: "getAllTasks",
    });

    const allT = resp.data;
    const data = {
      allTasks: resp.data,
    };

    yield put(setAllTasks(data));
    yield put(setLoadSuccess());
  });

  yield takeEvery(LOAD_UNASSIGNED_TASKS, function* () {
    const resp = yield dataProvider.TNA_CUSTOM_LIST("itemTasks", {
      action: "getUnAssignedTasks",
      method: "GET",
    });

    const allT = resp.data;
    const data = {
      allUnassignedTasks: resp.data,
    };

    yield put(setUnassignedTasks(data));
    yield put(setLoadSuccess());
  });

  yield takeEvery(COMPLETE_TASK, function* (action: any) {
    const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
      action: "completeTask",
      body: action.payload.data,
    });

    if (resp != undefined && resp.data != undefined) {
      showToastSuccess("Task Successfully Completed");
      const completedTask = resp.data.completedTask;

      if (
        completedTask.tranferred_time != null ||
        completedTask.transferred_time_for_successor_acceptance != null
      ) {
        yield put(setTaskTransferredToApproval(completedTask));
      } else {
        yield put(setTaskComplete(completedTask));
      }

      yield put(setLoadSuccess());
    }
  });

  yield takeEvery(START_TASK, function* (action: any) {
    const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
      action: "startTask",
      body: action.startingTask,
    });

    showToastSuccess("Task Started Successfully");
    const startedTask = resp.data.startedTask;

    yield put(setTaskStarted(startedTask));
    yield put(setLoadSuccess());
  });

  yield takeEvery(GENERATE_AND_DOWNLOAD_FORM, function* (action: any) {
    const resp = yield dataProvider.BLOB_DOWNLOAD("tna/itemTasks", {
      action: "gen_and_download_forms",
      method: "POST",
      fileName: action.payload.prev_name + "--add-code--" + new Date().toLocaleString(),
      fileExt: ".pdf",
      body: action.payload
    });
    yield put(setUploadFormSuccess());
  });

  yield takeEvery(UPLOAD_FORM, function* (action: any) {
    const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
      action: "upload_forms",
      method: "POST",
      body: action.payload,
    });
    if (resp.data.success) {
      showToastSuccess("Upload success!");
      yield put(updateTask(resp.data.updatedTask));
    } else {
      showToastError("Error uploading pdf!");
    }
    yield put(setUploadFormSuccess());
  });

  yield takeEvery(START_TASK, function* (action: any) {
    const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
      action: "startTask",
      body: action.startingTask,
    });

    showToastSuccess("Task Started Successfully");
    const startedTask = resp.data.startedTask;

    yield put(setTaskStarted(startedTask));
    yield put(setLoadSuccess());
  });

  yield takeEvery(SAVE_HOLIDAYS, function* (action: any) {
    const resp = yield dataProvider.TNA_CUSTOM_ACTION("holidays", {
      action: "createHolidays",
      body: action.payload,
    });
    const allT = resp.data;
    showToastSuccess("Holiday Added Successfully");
    yield put({ type: SAVE_HOLIDAYS_SUCCESS, payload: resp.data.success })
    const completedTask = resp.data.completedTask;
  });

  yield takeEvery(DELETE_TNA_HOLIDAY, function* (action: any) {
    const resp = yield dataProvider.TNA_CUSTOM_ACTION("holidays", {
      action: "deleteHoliday",
      body: action.payload,
    });
    // console.log("response: ", resp);
    if (resp.data.success) {
      showToastSuccess("Holiday Delete Success");
    } else {
      showToastError("Error Deleting Holiday");
    }
  });

  yield takeEvery(GENERATE_TEMPLATE, function* (action: any) {
    console.log("Generate flow...");
    const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
      action: "generateOrderTasks",
      body: action.payload,
    });
    // console.log("response: ", resp.data);
    if(resp.data){
      yield put(generateTemplate(resp.data));
    }else{
      yield put(generateTemplateError())
    }
    
  });

  yield takeEvery(LOAD_ALL_USERS, function* (action: any) {
    console.log("Loading all users");
    const resp = yield dataProvider.CUSTOM_LIST("users", {
      action: "",
      // body: action.payload,
      query: {
        page: action?.payload?.pagination?.page,
        page_size: action?.payload?.pagination?.perPage,
      },
    });
    // console.log("response: ", resp.data);
    if (resp) {
      yield put(loadAllSuccessUsers(resp.data));
    } else {
      yield put(modifyFlowError());
    }
  });

  yield takeEvery(DELETE_FLOW, function* (action: any) {
    console.log("Delete Template Saga Running...");
    const orderId = action.payload.late
      ? action.payload.orderId
      : yield select((s: any) => s.custom.template.selectedOrder.id);
    const deleteDataObject = {
      orderId: orderId.toString(),
    };
    try {
      const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
        method: "DELETE",
        action: "deleteGeneratedFlow",
        body: deleteDataObject,
      });
      // console.log("Delete Template", resp);
      if (resp) {
        yield put(setDeleteOrderSuccess());
        yield put(loadSDNs({"isRP":false}));
        yield put(loadTemplate());
      }
    } catch (err) {
      yield put(setDeleteOrderError());
    }
  });

  yield takeEvery(CONFIRM_TEMPLATE, function* (action: any) {
    console.log("Confirm Template Saga Running...");
    const orderId = action.payload.late
      ? action.payload.orderId
      : yield select((s: any) => s.custom.template.selectedOrder.id);
    const confirmDataObject = {
      orderId: orderId,
      status: action.payload.status.toString(),
    };
    try {
      const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
        action: "recordFlowStatus",
        body: confirmDataObject,
      });
      // console.log("Confirm Template", resp);
      if (resp.data) {
        yield put(setConfirmTemplateSuccess());
        yield put(loadSDNs({"isRP":false}));
        yield put(loadTemplate());
      } else {
        yield put(setStatusChangeFail());
      }
    } catch (err) {
      yield put(setConfirmTemplateError());
    }
  });

  yield takeEvery(MODIFY_FLOW, function* (action: any) {
    console.log("Modifying flow");
    const modifiedTasks = action.payload;
    try {
      const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
        action: "modifyFlow",
        body: modifiedTasks,
      });
      if (resp == "Flow modified successfully") {
        yield put(setModifyFlowSuccess());
      } else {
        yield put(setModifyFlowError());
      }
    } catch (err) {
      console.log("Error modifying flow: ", err);
      yield put(setModifyFlowError());
    }
  });

  yield takeEvery(FETCH_REPEATED_ORDERS, function* (action: any) {
    console.log("Fetching repeated orders");
    try {
      const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
        method: "POST",
        action: "getRepeatedOrders",
        body:action.payload
      });
      if (resp) {
        // console.log(resp);
        yield put(loadRepeatedOrders(resp));
      }
    } catch (err) {
      yield put(setQRSendError());
    }
  });

  yield takeEvery(LOAD_SDN, function* (action:any) {
    console.log("Load SDNs Saga Running...");
    try {
      const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
        method: "POST",
        action: "getFlowCodes",
        body:{
          "isRP":action.payload?.isRP
        }
      });
      if (resp) {
        // console.log(resp);
        const sdnArray = resp.data.map((sdnObject: any) => ({
          id: sdnObject.id,
          code: sdnObject.code,
          price_list_ref: sdnObject.price_list_ref,
          inquiry_serial: sdnObject.inquiry_serial,
        }));
        if(action.payload.isRP){
          yield put(loadRPSDNSuccess(sdnArray));
        }else{
          yield put(loadSDNSuccess(sdnArray));
        }
        
      }
    } catch (err) {
      console.log("Failed to load SDNs: ", err);
    }
  });

  yield takeEvery(LOAD_TEMPLATE, function* () {
    console.log("Load Templates Saga Running...");
    try {
      const resp = yield dataProvider.CUSTOM_LIST("tna/templates", {
        query:{
          status:"Active"
        }
      });
      if (resp) {
        // console.log("loading of tamplates", resp);
        const templateArray = resp.data.results.map((templateObject: any) => ({
          id: templateObject.id,
          code: templateObject.code,
          type: templateObject.type,
        }));
        yield put(loadTemplateSuccess(templateArray));
      }
    } catch (err) {
      console.log("Failed to load templates: ", err);
    }
  });

  yield takeEvery(QR_ACCEPTANCE, function* (action: any) {
    const resp = yield dataProvider.TNA_CUSTOM_ACTION("itemTasks", {
      action: "qrAcceptance",
      body: action.payload,
    });

    if (resp != undefined && resp.data != undefined) {
      if (resp.data) {
        showToastSuccess(resp.data);
      } else {
        showToastError(resp.data);
      }
    }
  });

  yield takeEvery(FETCH_TENTATIVE_ORDERS, function* (action: any) {
    console.log("Fetching all tentative orders");
    try {
      const resp = yield dataProvider.TNA_CUSTOM_ACTION("orders", {
        action: "getTentativeOrders",
        body: { flowType: action.payload },
      });
      if (resp) {
        // console.log(resp);
        yield put(fetchTentativeOrdersSuccess(resp.data));
      }
    } catch (err) {
      console.log("Error fetching tentative orders: ", err);
    }
  });

  yield takeEvery(FETCH_SELECTED_TENTATIVE_FLOW, function* (action: any) {
    console.log("Fetching selected tentative flow");
    try {
      const resp = yield dataProvider.TNA_CUSTOM_ACTION("orders", {
        action: "getSelectedTentativeFlow",
        body: { orderId: action.payload },
      });
      if (resp) {
        // console.log(resp);
        yield put(fetchSelectedTentativeFlowSuccess(resp.data));
      }
    } catch (err) {
      console.log("Error fetching selected tentative flow: ", err);
    }
  });

  yield takeEvery(SUBMIT_IQ_SERIAL, function* (action: any) {
    console.log("Fetching delay reasons");
    try {
      const resp = yield dataProvider.TNA_CUSTOM_ACTION("orders", {
        action: "submitInquirySerials",
        body: {
          IQSerial: action.payload.code,
          description: action.payload.comments,
        },
      });
      if (resp) {
        console.log(resp);
        if (resp.data === "Inquiry Serial Submitted Successfully") {
          yield put(setSubmitIQSerialSuccess(resp.data));
        } else {
          yield put(
            setSubmitIQSerialError(
              "Given inquiry serial number have not received via an email."
            )
          );
        }
      }
    } catch (err) {
      yield put(setSubmitIQSerialError("Network Error"));
    }
  });
}
