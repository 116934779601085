import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import WorkIcon from "@material-ui/icons/Work";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { format_to_date_time, format_to_time } from "../../lib/date_time";
import {
  requestDeleteAllocation,
  requestEditAllocationDuration,
  editAllocationDuration,
  clearSelection,
} from "../../store/actions";
import {
  getAllocations,
  getDemandOperations,
  getEntity,
  getIsEditing,
  getSelectedEntity,
} from "../../store/selectors";
import CreateAllocation from "./CreateAllocation";
import { Popover, Input, Typography, ButtonGroup } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import CloseIcon from "@material-ui/icons/Close";

function BucketDetailed({
  bucket,
  line,
  allocations,
  onDelete,
  onEdit,
  demandOperations,
  isUserEditing,
  onClose,
}) {

  
  line.carder = bucket.carder;
  
  return (
    <Card>
      <CardContent>
        <Tooltip
          style={{
            position: "absolute",
            right: 1,
            top: 1,
          }}
          title="Close"
        >
          {/* <IconButton aria-label="close" onClick={() => onClose()}>
            <CancelPresentationIcon fontSize="large" />
          </IconButton> */}
          <CloseIcon className="mt-1 mr-1 " onClick={() => onClose()} />
        </Tooltip>

        <div>
          <div>
            <font size="3">
              <b>{line.code} </b>
              <span> Total: {_.sumBy(allocations, "quantity")}</span>

              {/* <i>Capacity: {Number(bucket.total_smv).toFixed(2)}</i> <br /> */}
            </font>
          </div>
          <div>
            <font size="2">
              Carder: {bucket.carder} | Efficiency:{bucket.efficiency * 100}%
            </font>
          </div>
          <div style={{ paddingBottom: 5 }}>
            <font size="2">
              {format_to_date_time(bucket.start_time)} -{" "}
              {format_to_time(bucket.end_time)}
            </font>
          </div>
        </div>
        <Card
          variant="outlined"
          style={{ width: "100%", maxHeight: 400, overflowY: "auto" }}
        >
          <List>
            {/* <ListItem divider style={{ paddingLeft: "5px" }} key="main">
              <ListItemText
                primary={line.code}
                secondary={format_to_date_time(bucket.start_time)}
              ></ListItemText>
            </ListItem> */}
            {allocations.map((allocation, i) => {
              // If it is a allocation of a work
              if (allocation.demand_operation_id) {
                return (
                  <ListItem
                    divider
                    style={{
                      paddingLeft: "5px",
                      backgroundColor: allocation.is_freezed
                        ? "lightblue"
                        : undefined,
                    }}
                    key={allocation.id}
                  >
                    <ListItemIcon style={{ minWidth: "35px" }}>
                      <WorkIcon />
                    </ListItemIcon>
                    <ListItemText
                      style={{ fontSize: "11px" }}
                      primary={`${allocation.quantity} of ${allocation.demand_operation.item_code} in ${allocation.demand_operation.order_code} `}
                      secondary={`${format_to_date_time(
                        allocation.start_time
                      )} - ${format_to_time(allocation.end_time)}  ${
                        allocation.configuration
                      } | SMV: ${allocation.demand_operation.smv}`}
                    />
                    {isUserEditing == "user" ? (
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => onDelete(allocation.id)}
                          edge="end"
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    ) : null}
                  </ListItem>
                );
              }
              // If it is a configuration change or operation loss
              else {
                const nextAllocationIsFreezed =
                  allocations[i + 1] && allocations[i + 1].is_freezed;
                  const currentAllocationIsOperationLoss=allocations[i].type && allocations[i].type=="OPERATION_LOSS"
                return (
                  <ListItem
                    divider
                    style={{ backgroundColor: "pink", paddingLeft: "5px" }}
                    key={allocation.id}
                  >
                    <ListItemIcon style={{ minWidth: "35px" }}>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={currentAllocationIsOperationLoss?'Operation Loss':`Change to ${allocation.configuration}`}
                      secondary={format_to_date_time(allocation.start_time)}
                    />
                    {isUserEditing == "user" ? (
                      <ListItemSecondaryAction>
                        <EditDurationButton
                          disabled={nextAllocationIsFreezed|| currentAllocationIsOperationLoss}
                          onEdit={onEdit}
                          allocation={allocation}
                        />
                        <IconButton
                          disabled= {currentAllocationIsOperationLoss}
                          onClick={() => onDelete(allocation.id)}
                          edge="end"
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    ) : null}
                  </ListItem>
                );
              }
            })}
            <ListItem style={{ paddingLeft: "5px" }} key="button">
              <ListItemText style={{ color: "white" }} primary={` .`} />
              <ListItemSecondaryAction>
                {isUserEditing == "user" ? (
                  <CreateAllocationButton
                    bucket={bucket}
                    demandOps={demandOperations}
                  />
                ) : null}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Card>
      </CardContent>
    </Card>
  );
}
const EditDurationButton = ({
  onEdit,
  allocation,
  initialDuration,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [durationHours, setDurationHours] = React.useState(null);
  const HOUR_RATIO = 3600 * 1000;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setDurationHours(
      (allocation.end_time.valueOf() - allocation.start_time.valueOf()) /
        HOUR_RATIO
    );
  }, [allocation]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (e) => {
    setDurationHours(e.target.value);
  };

  const handleOk = () => {
    onEdit(allocation.id, HOUR_RATIO * parseFloat(durationHours));
    handleClose();
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ padding: 10 }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card style={{ padding: 20 }}>
          <Typography>Duration In Hours:</Typography>
          <Input value={durationHours} onChange={handleChange} />
          <br />
          <br />
          <Button
            style={{ marginRight: 10 }}
            onClick={handleClose}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
          <Button
            onClick={handleOk}
            variant="contained"
            color="primary"
            className="btn-success"
          >
            OK
          </Button>
        </Card>
      </Popover>
      <IconButton
        disabled={disabled}
        onClick={handleClick}
        edge="end"
        aria-label="delete"
      >
        <EditIcon />
      </IconButton>
    </>
  );
};

const CreateAllocationButton = ({ bucket, demandOps }) => {
  const [isOpen, setPopupOpen] = useState(false);
  const onClose = () => {
    setPopupOpen(false);
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="xl"
      >
        {/* <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ paddingTop: "10px" }} id="form-dialog-title">
              {"Allocate To Shift"}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={onClose} />
            </div>
          </Grid>
        </Grid> */}

        <DialogTitle id="form-dialog-title">Allocate To Shift </DialogTitle>
        <DialogContent>
          <CreateAllocation
            bucket_id={bucket.id}
            onSubmit={onClose}
            onClose={onClose}
          />
        </DialogContent>
      </Dialog>

      <Button
        className="btn_primary"
        variant="contained"
        onClick={() => setPopupOpen(true)}
      >
        Allocate
      </Button>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const bucket = getSelectedEntity(state);
  const line = getEntity(state, "lines", bucket.line_id);
  const allocations = getAllocations(state);
  const selectedAllocations = allocations
    .filter((a) => a.line_bucket_id === bucket.id)
    .map((a) => {
      const dO = getEntity(state, "demand_operations", a.demand_operation_id);
      return { ...a, demand_operation: dO };
    })
    .sort((a, b) => a.start_time - b.start_time);
  const demandOperations = getDemandOperations(state);
  const isUserEditing = getIsEditing(state);
  return {
    bucket: bucket,
    line,
    allocations: selectedAllocations,
    demandOperations,
    isUserEditing,
  };
};
const mapDispatchToProps = {
  onDelete: requestDeleteAllocation,
  onEdit: editAllocationDuration,
  onClose: clearSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(BucketDetailed);
