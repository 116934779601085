import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { Loading } from "react-admin";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { useDispatch, useSelector } from "react-redux";
import RawJsonSchemaEditor from "../../components/input/RawJsonSchemaEditor";
import { showToastError } from "../../lib/toas";
import { createOperationLoss } from "../../store/actions";
import { getLoadingOperationLosses } from "../../store/selectors/operationLossSelectors";
import { operationLossAttributesSchema } from "./createOperationLossAttributesSchema";
import { PreparedTreeCheckbox } from "./PreparedTreeCheckbox";
import Tooltip from "@material-ui/core/Tooltip";

export const CreateOperationLoss = (props) => {
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [formData, setFormData] = useState({
        pattern: { weekday: [], saturday: [], sunday: [] },
    });

    // variables for sub tree
    const [subChecked, setSubChecked] = useState([]);
    const [subExpanded, setSubExpanded] = useState([]);

    const setSubCheckedWrapper = (data) => {
        setSubChecked(data);
        setChecked([]);
    };
    const setCheckedWrapper = (data) => {
        setChecked(data);
        setSubChecked([]);
    };

    const dispatch = useDispatch();
    const isLoading = useSelector(getLoadingOperationLosses);

    const onChangeForm = (e) => {
        setFormData(e.formData);
    };

    const validateFormData = () => {
        if (checked.length === 0 && subChecked.length === 0) {
            return "Please select at least Plant or Sub Contract";
        }
        if (formData.operationLosses === undefined) {
            return "Please fill all the fields";
        }

        for (let i = 0; i < formData.operationLosses.length; i++) {
            let item = formData.operationLosses[i];
            if (item[0] === undefined) {
                return "Please fill the reason field";
            }
            if (item[1] === undefined || item[2] === undefined) {
                return "Please fill both start date and end date";
            }
            if (item[2] >= item[3]) {
                return "End date should be later than start date"
            }
        }

        return false;
    };

    const submitData = () => {
        // validation
        const error = validateFormData();
        if (error) {
            showToastError(error);
            return false;
        }

        const checkedList = checked[0] ? checked : subChecked;
        dispatch(createOperationLoss(checkedList, formData.operationLosses));
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Grid container>
            <Grid item xs={4}>
                <Box m={3}></Box>
                <Box m={3}>
                    <PreparedTreeCheckbox
                        checked={checked}
                        expanded={expanded}
                        setChecked={setCheckedWrapper}
                        setExpanded={setExpanded}
                    />
                    <PreparedTreeCheckbox
                        checked={subChecked}
                        expanded={subExpanded}
                        setChecked={setSubCheckedWrapper}
                        setExpanded={setSubExpanded}
                        sub={true}
                    />
                </Box>
            </Grid>
            <Grid item xs={8}>
                <RawJsonSchemaEditor
                    source="config"
                    schema={operationLossAttributesSchema}
                    formData={formData}
                    onChangeForm={onChangeForm}
                />
                <Tooltip title="Click here to Create operationLosses">
                    <Button
                        variant="contained"
                        className="btn_ btn_primary"
                        style={{ float: "right" }}
                        onClick={submitData}
                    // className="btn_ btn_primary"
                    >
                        Create
                    </Button>
                </Tooltip>
                <Box mb={5}></Box>
            </Grid>
        </Grid>
    );
};
