import _ from "lodash"
import { IAllocation, IPlan, AllocationIdType } from "../../../../lib/plan"
import { delete_allocations_from_plan, get_active_allocations, get_calculated_fields_updated } from "../../../../lib/plan/helpers"

export function loadPlanTransformer(plan: IPlan, action: any): IPlan {
    return { ...action.payload.plan }
}



export function deleteSectionTransformer(plan: IPlan, action: any): IPlan {
    const { start_time, lines, end_time } = action.payload
    const filter_allocations = (allocation: IAllocation) => {
        return (
            allocation.start_time > start_time &&
            (!lines ||
                lines.length == 0 ||
                lines.indexOf(`${allocation.plan_board_line_id}`) > -1)
        );
    };
    const allocatioByLineId = _.groupBy(get_active_allocations(plan), a => a.plan_board_line_id)
    const relatedLineIds = (lines && lines.length > 0) ?  lines : Object.keys(plan.lines) // Get all lines if lines is [] or null

    const deletedAllocIds = new Set<AllocationIdType>([])

    relatedLineIds.forEach((lineId: number) => {
        const lineAllocations = _.sortBy(allocatioByLineId[lineId] || [], "start_time")
        lineAllocations.forEach((alloc,i) => {
            const prevAlloc = lineAllocations[i-1]
            const nextAlloc = lineAllocations[i+1]
            const isJobALlocation = !!alloc.demand_operation_id
            const isOperationLossAllocation=alloc.type=="OPERATION_LOSS"?true:false;
            if(alloc.start_time < start_time || alloc.start_time > end_time){
                return
            }
            if(isOperationLossAllocation){
                return
            }

            if(isJobALlocation){
                if(!alloc.is_freezed){
                    deletedAllocIds.add(alloc.id)
                }
            } else{
                const hasBothNextAndPreviousAllocs = !!nextAlloc && !!prevAlloc
                const shouldDelete = !hasBothNextAndPreviousAllocs || !nextAlloc.is_freezed || !prevAlloc.is_freezed
                if(shouldDelete){
                    deletedAllocIds.add(alloc.id)
                }
            }
        })
    });

    const deleteAllocations = (plan: IPlan) => delete_allocations_from_plan(plan, deletedAllocIds)
    return _.flow([deleteAllocations, get_calculated_fields_updated])(plan)
}

export function freezeAllocationsTransformer(plan: IPlan, action: any): IPlan {
    const allocation_Ids = new Set<AllocationIdType>(action.payload.ids)
    const update = _.mapValues(plan.allocations, a => {
        if (allocation_Ids.has(a.id)) {
            return { ...a, is_freezed: action.payload.toFreeze }
        }
        return a
    })
    

    return {
        ...plan,
        allocations: {
            ...plan.allocations,
            ...update
        }
    }
}