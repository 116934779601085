import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import React from "react";
import { Loading, Query } from "react-admin";
import { format_to_date_time } from "../../lib/date_time";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // height: 140,
    // width: 100,
    padding: '5px',
    marginRight: '5px'
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export const ShiftPlanTable = ({selectedShift, selectedGroup}) => {
  const classes = useStyles();
  //console.log({selectedShift})
  if (selectedShift) {
    return <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell
              align="center">{`${format_to_date_time(selectedShift.start_time)} - ${moment(selectedShift.end_time).format('hh:mm:ss A')}`}</TableCell>
          </TableRow>
        </TableHead>
        <Allocation selectedGroup={selectedGroup}
                    selectedShift={selectedShift}/>

      </Table>
    </TableContainer>
  } else {
    return null
  }
}

const LineBuckets = ({selectedShift, selectedGroup, allocations}) => {
  const classes = useStyles();
  return <Query type="getList" resource="boardLine" payload={{
    pagination: {page: 1, perPage: 1000},
    sort: {field: 'id', order: 'ASC'},
    filter: {
      plan_board_group: selectedGroup
    }
  }}>
    {({data, loading, error}) => {
      if (loading) {
        return <Loading/>;
      }
      if (error) {
        return "Error"
      }
      //console.log({data})
      return <TableBody>
        {
          data.map(row =>
          {
            const filteredAllocations = allocations.filter(v => v.line_id == row.id)

            //console.log({allocations, filteredAllocations})
            return <TableRow key={row.id}>
              <TableCell align="center">{row.code}</TableCell>
              <TableCell
                align="center">{filteredAllocations.length>0 ?
                filteredAllocations[0].configuration
                : null}</TableCell>

              <br/>
              <Grid container className={classes.root} spacing={2}>
                {filteredAllocations.map(v => {
                  return <Grid item xs={3}>
                    <Paper className={classes.paper}
                           style={{
                             backgroundColor: !v.demand_operation ? '#f47373' : '#e6e6e6',
                             maxWidth: !v.demand_operation ? '150px' : null,
                             minHeight: !v.demand_operation ? '80px' : null,
                           }}>
                      <Grid container justify="center" spacing={2}>
                        {v.demand_operation ?
                          <React.Fragment>
                            {
                              v.order_code ? <Grid item xs={8}>
                                <Typography> {v.order_code}</Typography>
                              </Grid> : null
                            }
                            {
                              v.quantity ? <Grid item xs={4}>
                              <Typography>{`${parseFloat(v.quantity)} prs`}</Typography> 
                              
                              
                                <br/>
                              </Grid> : ""
                            }
                            {v.item_code ? <Grid item xs={8}>
                              <Typography style={{color: 'red'}}>{v.item_code} </Typography>
                            </Grid> : null}
                          </React.Fragment>
                          : null}
                        {v.configuration ? <Grid item xs={4}>
                          {!v.demand_operation ? <br/> : null}
                          <Typography>
                            {v.configuration}
                          </Typography>
                        </Grid> : ""}

                      </Grid>
                    </Paper>
                  </Grid>
                })}
              </Grid>
              <br/>
            </TableRow>}
          )
        }
      </TableBody>
    }}
  </Query>
}

const Allocation = ({selectedShift, selectedGroup}) => {
  const classes = useStyles();
  return <Query type="getList" resource="allocations" payload={{
    pagination: {page: 1, perPage: 1000},
    sort: {field: 'id', order: 'ASC'},
    demand_operation: null,
    filter: {
      start_time: moment(selectedShift.start_time).format('YYYY-MM-DD'),
      end_time: moment(selectedShift.end_time).format('YYYY-MM-DD')
    }
  }}>
    {({data, loading, error}) => {
      if (loading) {
        return <Loading/>;
      }
      if (error) {
        return "Error"
      }
      return <LineBuckets selectedShift={selectedShift}
                          selectedGroup={selectedGroup}
                          allocations={data}/>
    }}
  </Query>
}
