export const calendarAttributesSchema = {
    title: "",
    type: ["object", "null"],

    properties: {
        pattern: {
            type: "object",
            title: "Weekly Shift pattern",

            properties: {

                weekday: {
                    type: "array",
                    title: "Weekday",
                    description: "Click 'ADD ITEM' to add a shift",
                    items: {
                        type: "array",
                        title: "Weekdays shifts",
                        required: ["start_time", "duration"],
                        items: [
                            {
                                type: "string",
                                title: "Start time",
                                description: "Enter shift starting time (format: HH:mm)"
                            },
                            {
                                type: "number",
                                title: "Duration",
                                description: "Enter shift duration"
                            }
                        ]
                    }
                },
                saturday: {
                    type: "array",
                    title: "Saturday",
                    description: "Click 'ADD ITEM' to add a shift",
                    items: {
                        type: "array",
                        title: "Saturday shifts",
                        required: ["start_time", "duration"],
                        items: [
                            {
                                type: "string",
                                title: "Start time",
                                description: "Enter shift starting time (format: HH:mm)"
                            },
                            {
                                type: "number",
                                title: "Duration",
                                description: "Enter shift duration"
                            }
                        ]
                    }
                },
                sunday: {
                    type: "array",
                    title: "Sunday",
                    description: "Click 'ADD ITEM' to add a shift",
                    items: {
                        type: "array",
                        title: "Sunday shifts",
                        required: ["start_time", "duration"],
                        items: [
                            {
                                type: "string",
                                title: "Start time",
                                description: "Enter shift starting time (format: HH:mm)"
                            },
                            {
                                type: "number",
                                title: "Duration",
                                description: "Enter shift duration"
                            }
                        ]
                    }
                },
            }
        },
    },
  }
;

