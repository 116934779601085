import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import _ from "lodash"



export default function LinkField({ source, getLink, record = {} }) {

    return (
        <div>
            <a href={getLink(record)}>{record[source]}</a>
        </div>
    )
}


