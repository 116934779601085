import React, { useState } from "react";
import Button from "@material-ui/core/Button";

import FormUploadConfirmationDialog from "../../FormUploadConfirmationDialog";

export const ANSLForm = (props) => {
  // const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);
  const exportProps = props.exportProps;
  const onHandle2 = props?.onHandle2;

  return (
    <div>
      <table class='form-table' style={{ width: "100%" }} border={1} cellPadding={10}>
        <tbody>
          <tr>
            <td class='form-td'  style={{ width: "33%", height: "20%" }}>
              <image />
              Ansel
            </td>
            <td class='form-td'  style={{ width: "33%", height: "20%" }}>
              CONTROL OF SALES AND MARKETING PROCESS
              <br></br>
              SAMPLE REQUISITIONS FORM FOR LOCAL/ANSEL ITEMS
            </td>
            <td class='form-td'  style={{ width: "33%", height: "20%" }}>
              SEC.NO CR-FORM 06
              <tr>
                <td class='form-td' >
                  
                  <p>REV.NO</p>
                </td>
                <td class='form-td' >
                  <input class='form-input'
                    type="text"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("revNo")}
                    name="revNo"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td class='form-td' >
                  
                  <p>REV. DATE</p>
                </td>
                <td class='form-td' >
                  
                  <input class='form-input'
                    type="date"
                    name="revDate"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("revDate")}
                    required
                  />
                </td>
              </tr>
              <tr>
                
                <td class='form-td' >
                  <p>PAGE NO </p>
                </td>
                <td class='form-td' >
                  
                  <input class='form-input'
                    type="text"
                    name="pNo"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("pNo")}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td class='form-td' >Reference NO</td>
                <td class='form-td' >
                  <input class='form-input'
                    type="text"
                    name="refNo"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("refNo")}
                    required
                  />
                </td>
              </tr>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ margin: 20 }}>
        <p>Please fill the required information in relevant fields</p>
        <p>
          1. Category :
          <input class='form-input'
            type="text"
            name="category"
            onChange={(e) => onHandle2(e)}
            {...exportProps("category")}
            required
          />
        </p>
        <p>
          2. Requested Officer :
          <input class='form-input'
            type="text"
            name="officer"
            onChange={(e) => onHandle2(e)}
            {...exportProps("officer")}
            required
          />
        </p>
        <div style={{ display: "flex" }}>
          <p style={{ paddingRight: 20 }}>
            3. Requested Date :
            <input class='form-input'
              type="date"
              name="RequestedDate"
              onChange={(e) => onHandle2(e)}
              {...exportProps("RequestedDate")}
              required
            />
          </p>
          <p>
            5. Need Date :
            <input class='form-input'
              type="text"
              name="needDate"
              onChange={(e) => onHandle2(e)}
              {...exportProps("needDate")}
              required
            />
          </p>
        </div>
        <p>
          4. Sample Picture submission:
          <label htmlFor="" style={{margin: 10}} >Yes
          <input class='form-input'
            type="checkbox"
            style={{margin: 5}}
            name="yes"
            onChange={(e) => onHandle2(e)}
            {...exportProps("yes")}
          /></label>
          <label htmlFor="" style={{margin: 10}} >No
          <input class='form-input'
            type="checkbox"
            name="no"
            style={{margin: 5}}
            onChange={(e) => onHandle2(e)}
            {...exportProps("no")}
          /></label>
        </p>
        <p>
          5. Brand/sub brand :
          <input class='form-input'
            type="text"
            style={{margin: 5}}
            name="Brand"
            onChange={(e) => onHandle2(e)}
            {...exportProps("Brand")}
            required
          />
        </p>
        <p>
          6. Target Market:<label htmlFor="" style={{margin: 10}}>Low
          <input class='form-input'
            type="checkbox"
            style={{margin: 5}}
            onChange={(e) => onHandle2(e)}
            {...exportProps("low")}
            name="low"
          /></label>
          <label htmlFor="" style={{margin: 10}}>Middle
          <input class='form-input'
            type="checkbox"
            style={{margin: 5}}
            name="middle"
            onChange={(e) => onHandle2(e)}
            {...exportProps("middle")}
          /></label>
          <label htmlFor="" style={{margin: 10}}>High
          <input class='form-input'
            type="checkbox"
            style={{margin: 5}}
            name="high"
            onChange={(e) => onHandle2(e)}
            {...exportProps("high")}
          /></label>
        </p>
        <p>
          7. Demography Life style:<label style={{margin: 10}} htmlFor="">Unisex
          <input class='form-input'
            type="checkbox"
            style={{margin: 5}}
            name="uni"
            onChange={(e) => onHandle2(e)}
            {...exportProps("uni")}
          /></label>
          <label htmlFor="" style={{margin: 10}}>Male
          <input class='form-input'
            type="checkbox"
            name="male"
            style={{margin: 5}}
            onChange={(e) => onHandle2(e)}
            {...exportProps("male")}
          /></label>
          <label style={{margin: 10}} htmlFor="">Female
          <input style={{margin: 5}} class='form-input'
            type="checkbox"
            name="female"
            
            onChange={(e) => onHandle2(e)}
            {...exportProps("female")}
          /></label>
        </p>
        <p>
          8. Session Relevant Requirement :
          <input class='form-input'
            type="text"
            name="RelevantRequirement"
            onChange={(e) => onHandle2(e)}
            {...exportProps("RelevantRequirement")}
            required
          />
        </p>
        <p>
          9. Price Range (whole sale) :
          <input class='form-input'
            type="text"
            name="priceRange"
            onChange={(e) => onHandle2(e)}
            {...exportProps("priceRange")}
            required
          />
        </p>
        <p>
          10. Size Range :
          <input class='form-input'
            type="text"
            name="sizeRange"
            onChange={(e) => onHandle2(e)}
            {...exportProps("sizeRange")}
            required
          />
        </p>
        <p>
          11. Sole Type:<label htmlFor="" style={{margin: 10}}>Rubber
          <input class='form-input'
            type="checkbox"
            name="Rubber"
            style={{margin: 5}}
            onChange={(e) => onHandle2(e)}
            {...exportProps("Rubber")}
          /></label>
          <label htmlFor="" style={{margin: 10}}>EVA
          <input class='form-input'
            type="checkbox"
            style={{margin: 5}}
            onChange={(e) => onHandle2(e)}
            {...exportProps("eva")}
            name="eva"
          /></label>
          <label htmlFor="" style={{margin: 10}}>PVC
          <input class='form-input'
            type="checkbox"
            style={{margin: 5}}
            onChange={(e) => onHandle2(e)}
            {...exportProps("pvc")}
            name="pvc"
          /></label>
        </p>
        <p>
          12. Upper Material :
          <input class='form-input'
            type="text"
            onChange={(e) => onHandle2(e)}
            {...exportProps("UpperMaterial")}
            name="UpperMaterial"
            required
          />
        </p>
        <p>
          13. Sample Item Image :
          <input class='form-input'
            type="text"
            onChange={(e) => onHandle2(e)}
            {...exportProps("sampleImage")}
            name="sampleImage"
            required
          />
        </p>
      </div>
    </div>
  );
};



const ANSL = ({toEdit, prevFormData, prevFormName, formName }) => {
  const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);
  // const [Form, setForm] = useState();

  const [data, setData] = React.useState(toEdit ? prevFormData : {
    revNo: "",
    refNo: "",
    revDate: "",
    pNo: "",
    category: "",
    officer: "",
    RequestedDate: "",
    needDate: "",
    yes: "",
    no: "",
    Brand: "",
    low: "",
    high: "",
    middle: "",
    uni: "",
    male: "",
    female: "",
    RelevantRequirement: "",
    priceRange: "",
    sizeRange: "",
    Rubber: "",
    eva: "",
    pvc: "",
    UpperMaterial: "",
    sampleImage: "",
  });

  const onHandle2 = (e) => {
    const inputType=e.target.type

    if(inputType==="checkbox"){
      const isChecked=e.target.checked
      setData({
        ...data,
        [e.target.name]: isChecked,
      });
    }
    else{
      const value=e.target.value
      setData({
        ...data,
        [e.target.name]: value,
      });
    }
  };
  
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setOpenFormUploadDialog(true);
      event.preventDefault();
      event.stopPropagation();
    }
    setOpenFormUploadDialog(true);
    event.preventDefault();
    event.stopPropagation();
  };

  const exportProps = (field) => {
    if (data[field]===true) {
      return { 'checked': 'true', value: data[field]  };
    }
    return { value: data[field] };

  };

  const uiProps = (field) => {
    if (data[field] === true) {
      return { defaultChecked: true, defaultValue: data[field]};
    }
    return {defaultValue: data[field]}
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        < ANSLForm
          exportProps={uiProps}
          onHandle2={onHandle2}
        />
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </form>
      <FormUploadConfirmationDialog
        isOpen={openFormUploadDialog}
        setIsOpen={setOpenFormUploadDialog}
        Form={ANSLForm({ exportProps, onHandle2})}
        formData={data}
        prevFormName={prevFormName}
        formName={formName}
      />
    </div>
  );
};
export default ANSL;