import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { TopToolbar, ShowButton, usePermissions } from "react-admin";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
  },
});

export const CustomTopToolbar = ({ basePath, data, name, ...props }) => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar {...props} classes={useStyles()}>
      <ShowButton
        basePath={basePath}
        record={data}
        title={"Click here to show " + name}
        // disabled={!permissions?.permissions.includes(name + "_show")}
        className="btn_ btn_secondry"
      />
      {/* Add your custom actions */}
      {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
  );
};
