import React, { useState } from "react";
import Button from "@material-ui/core/Button";


import {
  PDFDownloadLink,
  Document,
  Page,
  Image,
  StyleSheet,
  View,
  Text,
  PDFViewer,
} from "@react-pdf/renderer";
import { format_to_date, format_to_date_time } from "../../../lib/date_time";
import moment from "moment";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    height: 100,
    width: 100,
    alignSelf: 'center'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  text: {
    margin: 12,
    fontSize: 14,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',

  },
});

const MyDoc = ({ task, link, comment }) => {
  const taskFlow = task.order.type

  return (
    <Document>
      <Page size="A4" style={styles.body}>

        <Text style={styles.title}>{`Task name : ${task.task_name}`}</Text>
        <Text style={styles.text}>{''}</Text>
        {taskFlow == "NEW" || taskFlow == "RP" ?
          <>
            <Text style={styles.text}>{`Ref no. : ${task.order.price_list_ref}`}</Text>
            <Text style={styles.text}>{`Computer code : ${task.order.computer_code}`}</Text>
            <Text style={styles.text}>{`Sample design number : ${task.order.code}`}</Text>
          </>
          : taskFlow == "IQ" ? <Text style={styles.text}>{`Inquiry Serial Number : ${task.order.inquiry_serial}`}</Text> : null}
        <Text style={styles.text}>{`Comments : ${comment}`}</Text>
        <Text style={styles.text}>{""}</Text>
        <Image style={styles.image} source={{ uri: link }}></Image>
        <Text style={styles.header}>
          ~ Auto generated by Scheduler ~
        </Text>
        <Text style={styles.header}>
          {`Timestamp: ${format_to_date_time()}`}
        </Text>
      </Page>
    </Document>
  )
};
const PdfGenButton = ({ task, uri, comment }) => {
  // console.log({ task });
  return (
    <div>
      <PDFDownloadLink
        document={<MyDoc link={uri} comment={comment} task={task} />}
        fileName={`QR:${task.task_name}-${format_to_date()}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? <Text style={{ color: 'white' }}>Loading document...</Text> :
            <Button
              variant="contained"
              color="primary"
            >
              {" "}
              Download QR Generated PDF
            </Button>
        }
      </PDFDownloadLink>
    </div>
  );
};

export default PdfGenButton;
