import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';

export  const CompletedQuantity =({ source, record = {},getQuantity })=>
{
   
  return(
    <LinearProgress variant="determinate"  value={getQuantity(record)} />
  
  )
}