import { combineReducers } from "redux";
import { activeRevisionReducer } from "./activeRevisionReducer";
import { changedOrderCodesReducer } from "./changedOrderCodeReducer";
import { editingReducer } from "./editingReducer";
import { loadingReducer } from "./loadingReducer";
import { modelReducer } from "./modelReducer";
import { pendingActionReducer } from "./pendingActionReducer";
import { planningReducer } from "./planningReducer";
import { selectedGroupReducer } from "./selectedGroupReducer";
import { selectionReducer } from "./selectionReducer";
import { timeRangeReducer } from "./timeRangeReducer";


export const planBoardReducer = combineReducers({
    selection: selectionReducer,
    timeRange: timeRangeReducer,
    loading: loadingReducer,
    planning: planningReducer,
    pendingAction: pendingActionReducer,
    model: modelReducer,
    editing: editingReducer,
    selectedGroupId: selectedGroupReducer,
    changedOrderCodes: changedOrderCodesReducer,
    activeRevision: activeRevisionReducer
})

export type PlanBoardStateType = ReturnType<typeof planBoardReducer>

// const initialState = {
//     // entities: null, // normalizePlanBoardTree(sampelData.tree).entities,
//     selection: null, // {type: , id: } //Done
//     time_range: {
//         start_time: Date.now(),
//         end_time: Date.now() + 1000 * 3600 * 24 * 5,
//     }, // Done
//     loading: false, //  Done
//     loadingInBackground: false, // Done Loading Reducer
//     is_planning: false,  // Done PlanningReducer
//     plan_progress: null,  // Done PlanningReducer
//     pending_action: null as any, // Done Pending Action Reducer
//     line_open: false, // model reducer

//     is_user_editing: "no", //  TODO Editing Reducer
//     selected_group_id: false, // TODO  
//     revision_id: null,
// }

// export const planboardReducer = (state = initialState, action: any) => {
//     switch (action.type) {
//         case LOAD_PLANBOARD: {
//             const { id, start_time, end_time, silent } = action.payload
//             return { ...state, loading: silent ? false : true, plan_board_id: id, start_time, end_time, is_planning: false, is_deleting: false }
//         }
//         case LOAD_PLANBOARD_SUCCESS: {
//             return { ...state, selection: null, loading: false, loadingInBackground: false }
//         }
//         case LOAD_PLANBOARD_REVISION: {
//             return { ...state, selection: null, loading: false, loadingInBackground: true }
//         }
//         case LOAD_PLANBOARD_REVISION_SUCCESS: {
//             const { revision_id } = action.payload
//             return { ...state, selection: null, loading: false, revision_id, is_planning: false, loadingInBackground: false }
//         }

//         case CLEAR_SELECTION: {
//             return { ...state, selection: null }
//         }


//         // Actions related to pending and pop ups
//         case CLEAR_PENDING_ACTION: {
//             return { ...state, pending_action: null }
//         }
//         case SET_PENDING_ACTION: {
//             return { ...state, pending_action: action.payload }
//         }

//         // case REQUEST_CREATE_ALLOCATION: {
//         //     const { to_line_id, to_time, demand_operation_id } = action.payload
//         //     return {
//         //         ...state, pending_action: {
//         //             type: CREATE_ALLOCATION,
//         //             payload: { to_time, to_line_id, demand_operation_id }
//         //         }
//         //     }
//         // }
//         // case REQUEST_MOVE_ALLOCATION: {
//         //     const { to_line_id, to_time, allocation_id } = action.payload
//         //     return {
//         //         ...state, pending_action: {
//         //             type: MOVE_ALLOCATION,
//         //             payload: { to_time, to_line_id, allocation_id }
//         //         }
//         //     }
//         // }
//         // case REQUEST_DELETE_ALLOCATION: {
//         //     const { allocation_id } = action.payload
//         //     return {
//         //         ...state, pending_action: {
//         //             type: DELETE_ALLOCATION,
//         //             payload: { allocation_id }
//         //         }
//         //     }
//         // }
//         case PLAN_AUTOMATIC: {
//             return { ...state, is_planning: true }
//         }

//         case SAVE_PLAN_BOARD_CHANGES: {
//             return { ...state, loadingInBackground: true }
//         }

//         case PUBLISH_PLANBOARD_REVISION: {
//             return { ...state, loading: true }
//         }

//         case DELETE_PLAN: {
//             return { ...state }
//         }
//         case MODEL_OPEN: {
//             return { ...state, line_open: true }
//         }
//         case MODEL_CLOSE: {
//             return { ...state, line_open: false }
//         }

//         case SET_SELECTED_GROUP: {
//             return { ...state, selected_group_id: action.payload.id }
//         }



//         // case PLAN_CREATE_ALLOCATION: {
//         //     return planCreateAllocationReducer(state, action)
//         // }
//         // case PLAN_EDIT_ALLOCATION: {
//         //     return planEditAllocationReducer(state, action)
//         // }
//         // case PLAN_DELETE_ALLOCATION: {
//         //     return planDeleteAllocationReducer(state, action)
//         // }
//         // case PLAN_CHANGE_CONFIGURATION: {
//         //     return planChangeConfigurationReducer(state, action)
//         // }
//         // case PLAN_MARK_BUCKET_COMPLETE: {
//         //     return planMarkBucketCompleteReducer(state, action)
//         // }

//         default:
//             // code block
//             return state
//     }
// }
