import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WorkIcon from "@material-ui/icons/Work";
import React from "react";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { format_to_date_time, format_to_time } from "../../lib/date_time";
import {
  requestDeleteAllocation,
  selectEntity,
  clearSelection,
} from "../../store/actions";
import {
  getAllocations,
  getDemandOperations,
  getEntity,
  getIsEditing,
  getSelection,
} from "../../store/selectors";
import Tooltip from "@material-ui/core/Tooltip";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

function DemandOperationDetailed({
  bucket,
  demandOperation,
  allocations,
  demandOperationsOfSameGroup,
  id,
  onDelete,
  onSelect,
  onClose,
  isUserEditing,
}) {
  if (!demandOperation) {
    return null;
  }

  return (
    <>
      <ExpansionPanel defaultExpanded style={{ margin: 0 }}>
        <Tooltip
          style={{
            position: "absolute",
            right: 1,
            top: 1,
          }}
          title="Close"
        >
          <CloseIcon className="mt-1 mr-1" onClick={() => onClose()} />
          {/* <IconButton aria-label="close" onClick={() => onClose()}>
            <CancelPresentationIcon fontSize="large" />
          </IconButton> */}
        </Tooltip>

        <ExpansionPanelSummary
          style={{
            margin: 20,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="demand_colapse-content"
          id="demand_colapse"
        >
          <Typography>
            <b>Order: {demandOperation.order_code}</b>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List
            style={{
              maxHeight: "34.5vh",
              overflow: "auto",
            }}
          >
            <ListItem>
              <Typography color="textSecondary">
                {demandOperation.item_code} | {demandOperation.planned_quantity}{" "}
                of {demandOperation.quantity} planned
              </Typography>
            </ListItem>
            <ListItem>
              <Divider />
            </ListItem>
            <ListItem>
              <small>
                RM Ready Date:{" "}
                {demandOperation.rm_ready_date
                  ? format_to_date_time(demandOperation.rm_ready_date)
                  : "N/A"}
              </small>
            </ListItem>
            <ListItem>
              <small>
                Need Date:{" "}
                {format_to_date_time(demandOperation.required_time_max)}
              </small>
            </ListItem>
            <Divider />
            <ListItem>
              <small>
                Planned Start Date:{" "}
                {demandOperation.planned_start_date
                  ? format_to_date_time(demandOperation.planned_start_date)
                  : ""}{" "}
                <br />
                Planned End Date:{" "}
                {demandOperation.planned_end_date
                  ? format_to_date_time(demandOperation.planned_end_date)
                  : ""}
                <br />
                SMV: {demandOperation.smv}h
              </small>
            </ListItem>
            <Divider />
            <br />
            <ListItem style={{ paddingLeft: "5px" }}>
              <Typography>Allocations</Typography>
            </ListItem>
            {allocations.map((allocation) => {
              // If it is a allocation of a work

              return (
                <ListItem style={{ paddingLeft: "5px" }}>
                  <ListItemIcon style={{ minWidth: "35px" }}>
                    <WorkIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${allocation.quantity} | ${allocation.line.code}`}
                    secondary={`${format_to_date_time(
                      allocation.start_time
                    )} - ${format_to_time(allocation.end_time)}  ${
                      allocation.configuration
                    }`}
                  />

                  <ListItemSecondaryAction>
                    {isUserEditing === "user" ? (
                      <IconButton
                        onClick={() => onDelete(allocation.id)}
                        edge="end"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : null}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
            <Divider />
            <br />
            <ListItem style={{ paddingLeft: "5px" }}>
              <Typography>Orders in the Group</Typography>
            </ListItem>

            {demandOperationsOfSameGroup.map((dOp) => {
              // If it is a allocation of a work

              return (
                <ListItem
                  key={dOp.id}
                  style={{
                    paddingLeft: "5px",
                    backgroundColor:
                      dOp.id === id ? "rgba(0,0,0,0.1)" : undefined,
                  }}
                  dense
                  button
                  onClick={() => onSelect("demand_operations", dOp.id)}
                >
                  <ListItemIcon style={{ minWidth: "35px" }}>
                    <WorkIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${dOp.order_code} | ${dOp.planned_quantity} / ${dOp.quantity}`}
                    secondary={`${dOp.configuration} `}
                  />

                  {/* <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                    >
                      <SelectIcon />
                    </IconButton>
                  </ListItemSecondaryAction> */}
                </ListItem>
              );
            })}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}

const mapStateToProps = (state, props) => {
  const demandOperation = getEntity(state, "demand_operations", props.id);
  const selection = getSelection(state);
  const allocations = getAllocations(state, "allocations")
    .filter((allocation) => allocation.demand_operation_id === props.id)
    .map((alloc) => {
      const line = getEntity(state, "lines", alloc.plan_board_line_id);
      return { ...alloc, line };
    });

  const demandOperationsOfSameGroup = getDemandOperations(state).filter(
    (dOp) => dOp.order_group_code === demandOperation.order_group_code
  );

  // const allocations = bucket.allocations.map(id => select_entity(state, "allocations", id))
  const isUserEditing = getIsEditing(state);
  return {
    demandOperation,
    allocations,
    demandOperationsOfSameGroup,
    isUserEditing,

    // allocations
  };
};
const mapDispatchToProps = {
  onDelete: requestDeleteAllocation,
  onSelect: selectEntity,
  onClose: clearSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemandOperationDetailed);
