import { Input, Typography } from '@material-ui/core'
import NoIcon from "@material-ui/icons/Close"
import YesIcon from "@material-ui/icons/Done"
import EditIcon from "@material-ui/icons/Edit"
import React from 'react'

export default function EditableText({ initialValue, onChange }: { initialValue: string, onChange: (valeu: string) => any }) {
    const [value, setValue] = React.useState(initialValue)
    const [edit, setEdit] = React.useState(false)
    const onYes = () => {
        setEdit(false)
        onChange(value)
    }

    const onEdit = () => {
        setEdit(true)
    }

    const onNo = () => {
        setEdit(false)
        setValue(initialValue)
    }
    const handleChange = (e: any) => {
        setValue(e.target.value)
        onChange(e.target.value)
    }

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return (
        <div style={{ display: 'flex' }}>
            {edit ? <Input value={value} onChange={handleChange}></Input> : <Typography>{value}</Typography>}
            {edit ?
                <>
                    <YesIcon onClick={onYes} />
                    <NoIcon onClick={onNo} />
                </> :
                <EditIcon onClick={onEdit} />}
        </div>
    )
}
