import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import CustomDateField from "../../components/field/CustomDateField";
export const PlanRevisionList = props => {

  const Title = ({ record }) => {
    return <span>Plan Revision</span>;
  };

  return (
    <List {...props}  title={<Title />} perPage={25}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <CustomDateField source="time_stamp" showTime />
        <TextField source="title" />
        <TextField source="plan_board" />
      </Datagrid>
    </List>
  );
};