//notificatons
export const getLoading = (state: any) => state.custom.notifications.loading;

export const getNotificationCount = (state: any) => state.custom.notifications.oderTransfer.count;

export const getAllTransfer = (state: any) => state.custom.notifications.oderTransfer.transfers;

export const getTransfer = (state: any) => state.custom.notifications.oderTransfer.transfers.transfer.all

export const getTransferRead = (state: any) => state.custom.notifications.oderTransfer.transfers.transfer.read;

export const getTransferUnread = (state: any) => state.custom.notifications.oderTransfer.transfers.transfer.unRead;

export const getReceved = (state: any) => state.custom.notifications.oderTransfer.transfers.recived.all;

export const getRecevedRead = (state: any) => state.custom.notifications.oderTransfer.transfers.recived.read;

export const getRecevedUnread = (state: any) => state.custom.notifications.oderTransfer.transfers.recived.unRead;

export const getTransferCount = (state: any) => state.custom.notifications.oderTransfer.transfers.transfer.count;

export const getRecevedCount = (state: any) => state.custom.notifications.oderTransfer.transfers.recived.count;

export const getAllNotifications = (state: any) => state.custom.notifications.allNotifications;


