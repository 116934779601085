import Excel from "exceljs";
import { saveAs } from "file-saver";
import { array } from "prop-types";
import { showToastError } from "../../../../lib/toas";
import { format_reverse_oder_date, format_to_date_time, format_to_date,format_to_custom_needs,format_duration } from "../../../../lib/date_time";
import { DATE_FORMAT_EXCEL, DATE_TIME_FORMAT_EXCEL ,DATE_TIME_FORMAT ,DATE_FORMAT} from "../../../../lib/date_time";
import moment from "moment"

//font sizes
const mainHeaderFontSize = 11;
const timeRangeFontSize = 11;
const fontSize1 = 8;
// const fontSize2 = 9;
const columns = 13;

//map column widths
const columnWidths = {
  "Item Code": 15,
  Mold: 10,
  "Need Date(max)": 15,
  "Order Code": 15,
  "P.S.D.": 17,
  "P.E.D.": 17,
  Quantity: 8,
  "RM Ready Date": 15,
  "Start Time": 23,
  Unit: 6,
  head: 8,
  "End Time": 23,
  "Operation Code": 15,
  'Shifts': 8,
  "Total shift quantity": 15,
  "Release number": 13,
  SMV: 7,
};

//map colors
const colorMappings = {
  B: "FFCCCCCC",
  BE: "FFF5F5DC",
  DB: "FFFFE4B5",
  PI: "FFFFC0CB",
  R: "FFFF7F50",
  W: "FFF8F8FF",
};

const advanced_format_to_date = date => {
    if (date instanceof Date) {
        return format_to_date(date)
    }
    else return ""
}

const advanced_format_to_date_time = date => {
    if (date instanceof Date) {
        return moment(date).format("YYYY-MM-DD hh:mm:ss A")
    }
    else return ""
}

const dataFormator = (data) => {
    const output = data.map((dOp) => {
        console.log({data})
        return {
            "Lines": dOp.line.join(","),
            "Order Group" : dOp.order_group_code,
            "Order": dOp.order_code,
            "Release number": dOp.order_release_number,
            "Item":dOp.item_code,
            "Compounds":dOp.compound_codes,
            "Configuration": dOp.configuration,
            "Month": dOp.order_required_month,
            "Week No": dOp.order_week_number,
            "Confirmation date": dOp.order_confirmation_date,
            "Order need":dOp.order_required_date,
            "Need date": dOp.required_time_max,
            "RM Ready": dOp.rm_ready_date,
            "Plan start": advanced_format_to_date_time(dOp.planned_start_date),
            "Plan end": advanced_format_to_date_time(dOp.planned_end_date),
            "Delay": dOp.delay >= 0 ? `${dOp.delay}` : `${-dOp.delay} early`,
            "Mlt": dOp.quantity_multiplier,
            "Quantity": dOp.quantity,
            "Skipped": dOp.skipped_quantity,
            "Planned": dOp.planned_quantity,
            "Completed": dOp.completed_quantity,
            "Operation": dOp.operation_code,
            "SMV": dOp.smv,
            "Production Time":format_duration( dOp.production_time)

        }
    })
return output
}

export const tableViewExport = async (input) => {

    const data = dataFormator(input)
    const columns = Object.keys(data[0]).length;
  
    var workbook = new Excel.Workbook();
    workbook.creator = "Auto Generated by Stack Schedular";
    var worksheet = workbook.addWorksheet("plan", {
      views: [{ showGridLines: true }],
    });
  
    //main header
    worksheet.getRow(1).height = 42.5;
    worksheet.mergeCells("A1", "O1");
    worksheet.getCell("A1").value = `DEMAND OPERATIONS`;
    worksheet.getCell("A1").font = {
      name: "Verdana",
      size: mainHeaderFontSize,
      bold: true,
    };
    worksheet.getCell("A1").border = {
      top: { style: "thick" },
      left: { style: "thick" },
      bottom: { style: "thick" },
      right: { style: "thick" },
    };
    worksheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFF00" },
    };
  
    const timeRange = ` `;
  
    //time range
    worksheet.mergeCells("A2", "D2");
    worksheet.getRow(2).height = 20;
    worksheet.getCell("A2").value = timeRange;
    worksheet.getCell("A2").font = {
      name: "Verdana",
      size: timeRangeFontSize,
      bold: true,
    };
  
    //table headers
    const headers = Object.keys(data[0]);
    worksheet.getRow(4).values = headers;
    worksheet.lastRow.eachCell({ includeEmpty: true }, function (
      cell,
      cellNumber
    ) {
      if (cellNumber < columns + 1) {
        if (!cell.value) {
          cell.value = "";
        }
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF9999FF" },
        };
        cell.font = {
          name: "Verdana",
          size: fontSize1,
        };
      }
    });
    worksheet.columns = headers.map((i) => ({
      key: i,
      width: columnWidths[i] || 8,
    }));
  
    //table data

    var j = 0;
    var i
    data.map((O, index) => {
      //Allocations
      worksheet.addRow(O);
      worksheet.lastRow.eachCell(function (cell, cellNumber) {
        cell.font = {
          name: "Verdana",
          size: fontSize1,
        };
    }
      )
        if (j % 2 === 0) {
          for (i = 0; i < columns; i++) {
            worksheet.lastRow.getCell(i + 1).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFEEEEFF" },
            };
          }
        }
      j = j + 1;
    })
  
    //add thin borders to all table cells
    worksheet.eachRow(function (row, rowNumber) {
      if (rowNumber > 3) {
        var i;
        for (i = 1; i < columns - 1; i++) {
          row.getCell(i + 1).border = {
            top: { style: "thin" },
            bottom: { style: "thin" },
          };
        }
  
        //first cell in a row
        row.getCell(1).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
        };
  
        //last cell in a row
        row.getCell(columns).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    });
  
    //end text
    worksheet.addRow([""]);
    
    worksheet.addRow([`Timestamp: ${format_to_date_time(new Date())}`]);
    // worksheet.addRow([`Export type: ${planBoard.export_type}`]);
  
    // worksheet.addRow([`Export SMV unit: ${planBoard.export_smvunit}`]);
    worksheet.addRow(["Auto generated by Stack Schedular"]);
  
    //export excel
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";
    const filename = `Plan ${format_to_date_time(new Date())}`;
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, filename + fileExtension);
  };