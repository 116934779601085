import React from "react";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};

  if (!values.demandOperation) {
    errors.demandOperation = ["Demand operation is required"];
  }
  if (!values.demand) {
    errors.demand = ["Demand is required"];
  }
  if (!values.lead_time_min) {
    errors.lead_time_min = ["Lead time min is required"];
  }
  return errors;
};
export const CreateRequiredDemand = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm validates={validate} rowClick="edit">
        <ReferenceInput
          label="demand operation"
          source="demand_operation"
          reference="demandOp"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput label="demand" source="demand" reference="demand">
          <SelectInput optionText="id" />
        </ReferenceInput>
        <TextInput source="lead_time" />
      </SimpleForm>
    </Create>
  );
};
