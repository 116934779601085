import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  Filter,
  useRecordContext,
  useDataProvider,
} from "react-admin";
import Confirmation from "../../components/Confirmation";
import {ChangeDiv} from "../../components/ChangeDiv";

const PermissionFilter = (props) => (
  <div 
  // style={{ marginBottom: 15 }}
  >

    <Filter {...props}>
      <ReferenceInput source="parent" reference="permissions" alwaysOn>
        <TextInput label="Search (parent name)" source="name" alwaysOn />
      </ReferenceInput>
      <TextInput label="Search (code)" source="code" alwaysOn />
      <TextInput label="Search (name)" source="name" alwaysOn />
    </Filter>
  </div>
);

const TextNullableField = ({ record = {}, source, props }) => {
  console.log(record, "re", source, "so");
  console.log(props);
  const dataProvider = useDataProvider();

  // dataProvider.getOne()
  return (
    <ReferenceField
      link=""
      label="Parent"
      source="parent"
      reference="permissions"
    >
      {record.parent ? <TextField source="name" /> : "N/A"}
    </ReferenceField>
  );
};

export const Permissions = (props) => (
  <div 
  >
    <List
      bulkActionButtons={false}
      actions={null}
      perPage={25}
      filters={<PermissionFilter />}
      {...props}
    >
      <ChangeDiv>
        {props => {
          return <Datagrid>
          <TextField source="id" />
          <TextField source="code" />
          <TextField source="name" />
          <ReferenceField
            emptyText="N/A"
            link=""
            label="Parent"
            source="parent"
            reference="permissions"
          >
            <TextField source="name" emptyText="ldd" />
          </ReferenceField>
          <EditButton className="btn_ btn_edit permission-edit-btn " style={{marginRight:"100px", float: "right"}} />
        </Datagrid>
        }}
      </ChangeDiv>
    </List>
  </div>
);
