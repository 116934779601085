import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CalendarToolbar from "../../components/CalendarToolbar";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Loading,
  Query,
  useDataProvider,
  WithPermissions,
  usePermissions,
  Title,
  useMutation
} from "react-admin";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ExpandableCard from "../../components/loading/ExpandableCard";
import {
  format_to_date_for_api,
  format_to_date_time,
  format_to_date,
  format_to_time,
  format_to_date_time_for_api,
} from "../../lib/date_time";
import { showToastError, showToastSuccess } from "../../lib/toas";
import { deleteHoliday, loadHolidays, setDateRange } from "../../store/actions";
import { deleteOperationLoss, loadOperationLosses, setDateRangeOperationLoss } from "../../store/actions";
import {
  getDateRange,
  getHolidays,
  getLoading,
  getShitPatterns,
} from "../../store/selectors/holidaySelectors";
import {
  getDateRangeOperationLosses,
  getOperationLosses,
  getLoadingOperationLosses,
  getShitPatternsOperationLosses,
} from "../../store/selectors/operationLossSelectors";
import ShiftPatternView from "./ShiftPatternView";
import LinkButton from "../../components/LinkButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import { theme as customTheme } from "../../layout/theme";
import { VerticalSpacer } from "../../components/dashboard/Spacer";
import { PRIMARY_COLOR } from "../../constants";
import { ReqInputField } from "../../components/ReqInputField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  text: {
    marginBottom: "5px",
    color: customTheme.palette.primary.main,
  },
  buttonContainer: {
    margin: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
  buttons: {
    margin: theme.spacing(1),
  },
  formfield: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-around",
    width: "200px",
  },
}));

const payload = {
  pagination: { page: 1, perPage: 1000 },
  sort: { field: "id", order: "ASC" },
};

const localizer = momentLocalizer(moment);

export const HolidayCalendar = (props) => {
  const classes = useStyles();
  const [calendarType, setCalendarType] = useState(null);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [selectedOperationLoss, setSelectedOperationLoss] = useState(null);
  const [selectedLines, setSelectedLines] = useState({});
  const [cusView, setCusView] = useState();
  const dispatch = useDispatch();
  const holidays = useSelector(getHolidays);
  const operationLosses = useSelector(getOperationLosses);
  const events = holidays.concat(operationLosses)
  const shiftPatterns = useSelector(getShitPatterns);
  const dates = useSelector(getDateRange);

  useEffect(() => {
    dispatch(loadHolidays(calendarType));
  }, [calendarType, dispatch]);

  const handleChange = (event) => {
    setCalendarType(event.target.value);
  };

  const handleOnClickLineOpLoss = (lineId) => {
    if (lineId == "all") {
      selectedOperationLoss.lines.map((l) => {
        setSelectedLines((s) => ({ ...s, [l.id]: !s[lineId] }));
      });
    }
    setSelectedLines((s) => ({ ...s, [lineId]: !s[lineId] }));
  };

  const handleOnClickLine = (lineId) => {
    if (lineId == "all") {
      selectedHoliday.lines.map((l) => {
        setSelectedLines((s) => ({ ...s, [l.id]: !s[lineId] }));
      });
    }
    setSelectedLines((s) => ({ ...s, [lineId]: !s[lineId] }));
  };

  const loading = useSelector(getLoading)

  const onDelete = () => {
    let allFalse = true;
    _.map(selectedLines, (i) => {
      if (i) {
        allFalse = false;
      }
    });

    if (allFalse) {
      showToastError("Please select lines!");
      return 0;
    }
    setSelectedLines((i) => {
      delete i["all"];
      return i;
    });

    dispatch(deleteHoliday(selectedHoliday.id, selectedLines, calendarType));
    setSelectedHoliday(null);
  };


  const onDeleteOpLoss = () => {
    let allFalse = true;
    _.map(selectedLines, (i) => {
      if (i) {
        allFalse = false;
      }
    });

    if (allFalse) {
      showToastError("Please select lines!");
      return 0;
    }
    setSelectedLines((i) => {
      delete i["all"];
      return i;
    });

    dispatch(deleteOperationLoss(selectedOperationLoss.id, selectedLines, calendarType));
    setSelectedOperationLoss(null);
  };

  const { permissions } = usePermissions();

  if (permissions?.permissions.includes("calendars")) {
    return (
      <WithPermissions
        render={({ permissions }) => (
          <Grid container spacing={3}>
            <Title title="Calendars" />
            <Grid item xs={2}>
              <CalendarTypeSelector
                permissions={permissions}
                calendarType={calendarType}
                setCalendarType={setCalendarType}
                handleChange={handleChange}
                classes={classes}
              />
            </Grid>

            <Grid item xs={6}>
              <Box mt={2}>
                <LinkButton
                  startIcon={<AddIcon />}
                  title="Click here to add holiday"
                  disabled={
                    !permissions?.permissions.includes("holidays_create")
                  }
                  to={`/createHoliday`}
                  // variant="outlined"
                  className="btn_ btn_primary"
                >
                  Add Holiday
                </LinkButton>
                <LinkButton
                  startIcon={<EditIcon />}
                  title="Click here to edit shift pattern"
                  to={`/createShiftPattern`}
                  disabled={
                    !permissions?.permissions.includes("shiftPattern_edit")
                  }
                  className="btn_ btn_primary"
                  onClick={() => { }}
                >
                  Edit Shift Pattern
                </LinkButton>
                <LinkButton
                  startIcon={<AddIcon />}
                  title="Click here to add operation losses"
                  disabled={
                    !permissions?.permissions.includes("operation_loss_create")
                  }
                  to={`/createOperationLoss`}
                  className="btn_ btn_primary"
                >
                  Operation Loss
                </LinkButton>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box mt={2}>
                {calendarType ? (
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 475 }}
                    onSelectEvent={(e) => {
                      setSelectedHoliday({ ...e });
                      setSelectedLines({});
                      setSelectedOperationLoss({ ...e });
                    }}
                    components={{
                      toolbar: CalendarToolbar,
                    }}
                    onRangeChange={(i) => {
                      dispatch(
                        setDateRange({
                          start_date: format_to_date_for_api(i.start),
                          end_date: format_to_date_for_api(i.end),
                        })
                      );
                      dispatch(loadHolidays(calendarType));
                      //set dates here
                    }}
                  />
                ) : null}
              </Box>
            </Grid>
            {selectedHoliday?.eventType == 'Holiday' ?
              (
                <Grid style={{ marginTop: 15 }} item xs={4}>
                  {selectedHoliday ? (
                    <EventItem
                      selectedHoliday={selectedHoliday}
                      classes={classes}
                      selectedLines={selectedLines}
                      onClickLine={handleOnClickLine}
                      onDelete={onDelete}
                    />
                  ) : (
                    <Paper style={{ padding: 2, height: 475 }}>
                      <div>
                        <Typography style={{ padding: "10px" }}>
                          Select a holiday or operation loss to view details!
                        </Typography>
                        {loading && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <VerticalSpacer />
                          <VerticalSpacer />
                          <CircularProgress size={50} style={{ color: PRIMARY_COLOR }} />
                        </div>}
                      </div>
                    </Paper>
                  )}
                </Grid>
              ) : (
                <Grid style={{ marginTop: 15 }} item xs={4}>
                  {selectedHoliday ? (
                    <EventItem
                      selectedHoliday={selectedHoliday}
                      classes={classes}
                      selectedLines={selectedLines}
                      onClickLine={handleOnClickLineOpLoss}
                      onDeleteOperationLoss={onDeleteOpLoss}
                    />
                  ) : (
                    <Paper style={{ padding: 2, height: 475 }}>
                      <div>
                        <Typography style={{ padding: "10px" }}>
                          Select a holiday or operation loss to view details!
                        </Typography>
                        {loading && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <VerticalSpacer />
                          <VerticalSpacer />
                          <CircularProgress size={50} style={{ color: PRIMARY_COLOR }} />
                        </div>}
                      </div>
                    </Paper>
                  )}
                </Grid>
              )}
            <Grid item xs={12} style={{ maxWidth: "100%" }}>
              {shiftPatterns?.length > 0 ? (
                <div
                  style={{
                    overflowY: "auto",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  <ExpandableCard label="Default Shift Patterns">
                    <ShiftPatternView shiftPatterns={shiftPatterns} />
                  </ExpandableCard>
                </div>
              ) : null}
            </Grid>
            {/* </div> */}
          </Grid>
        )}
      ></WithPermissions>
    );
  } else return <div></div>;
};

const EventItem = ({
  selectedHoliday,
  classes,
  selectedLines,
  onClickLine,
  onDelete, // Use for holidays
  onDeleteOperationLoss, // Use for Opereation Losses

}) => {
  const { permissions } = usePermissions();
  const [disableEdit, setDisableEdit] = useState(false);
  const handleClickChecked = () => setDisableEdit(!disableEdit)

  const [checkAll, setCheckAll] = useState(false);
  const handleClickCheckAll = () => setCheckAll(!checkAll)
  console.log("checkAll", checkAll)

  if (selectedHoliday?.eventType == 'Holiday')
    return (
      <Paper style={{ padding: 2, height: 475 }}>
        <Box m={3}>
          <Typography style={{ fontWeight: 600 }} className={classes.text}>
            {selectedHoliday.title}
          </Typography>
          <Typography className={classes.text}>
            {/* Start Date : {format_to_date_time(selectedHoliday.start)} */}
            Start Date : <b>{format_to_date(selectedHoliday.start)}</b>
          </Typography>
          <Typography className={classes.text}>
            {/* End Date : {format_to_date_time(selectedHoliday.end)} */}
            End Date : <b>{format_to_date(selectedHoliday.end)}</b>
          </Typography>
          <Box display={"flex"}>
            <Checkbox
              style={{ padding: "0px", marginRight: "5px", color: "black" }}
              checked={checkAll}
              onChange={(e) => {
                onClickLine("all");
                // setLine(e.target.value, setSelectedLines, selectedHoliday)
              }}
              onClick={handleClickCheckAll}
              // value={selectedLines[i.id]}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <Typography
              style={{ marginTop: 2, color: "black" }}
              className={classes.text}
            >
              Select All
            </Typography>
          </Box>
          <Divider style={{ marginTop: 10 }} />
          <div className="list-group" style={{ height: 260, overflow: "auto" }}>
            {selectedHoliday.lines.map((i) => (
              <Box className="list-group-item" display="flex" key={i.id}>
                <Checkbox
                  className="form-check-input"
                  key={i.id + JSON.stringify(selectedLines[i.id])}
                  style={{ padding: "0px", marginRight: "5px", color: "black" }}
                  checked={selectedLines[i.id] | checkAll}
                  onChange={(e) => {
                    onClickLine(i.id);
                    // setLine(e.target.value, setSelectedLines, selectedHoliday)
                  }}
                  onClick={handleClickChecked}
                  // value={selectedLines[i.id]}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <Typography className={classes.text}>{i["code"]}</Typography>
              </Box>
            ))}
          </div>
          <Divider style={{ marginBottom: 10 }} />
          <div className={classes.buttonContainer}>
            <Button
              title="permissions"
              disabled={!permissions?.permissions.includes("holidays_delete")}
              // className={classes.buttons}
              className="btn_ btn_delete"
              variant="contained"
              startIcon={<DeleteIcon />}
              // color="primary"
              onClick={onDelete}
            >
              Delete
            </Button>
            {selectedLines["all"] ? (
              <EditHolidayButton
                title="permissions"
                setCheckAll={setCheckAll}
                disabled={!permissions?.permissions.includes("holidays_edit")}
                classes={classes}
                selectedHoliday={selectedHoliday}
              />
            ) : (disableEdit) ? (
              <EditHolidayButton
                title="permissions"
                setCheckAll={setCheckAll}
                disabled
                classes={classes}
                selectedHoliday={selectedHoliday}
              />
            ) : (
              <EditHolidayButton
                title="permissions"
                setCheckAll={setCheckAll}
                disabled={!permissions?.permissions.includes("holidays_edit")}
                classes={classes}
                selectedHoliday={selectedHoliday}
              />
            )}
          </div>
        </Box>
      </Paper>
    );
  else
    return (
      <Paper style={{ padding: 2, height: 475 }}>
        <Box m={3}>
          <Typography style={{ fontWeight: 600 }} className={classes.text}>
            {selectedHoliday.title}
          </Typography>
          <Typography className={classes.text}>
            Type: {selectedHoliday.type}
          </Typography>
          <Typography className={classes.text}>
            {/* Start Date : {format_to_date_time(selectedHoliday.start)} */}
            Start Date : <b>{format_to_date(selectedHoliday.start)}</b>,{" "}
            {format_to_time(selectedHoliday.start)}
          </Typography>
          <Typography className={classes.text}>
            {/* End Date : {format_to_date_time(selectedHoliday.end)} */}
            End Date : <b>{format_to_date(selectedHoliday.end)}</b>,{" "}
            {format_to_time(selectedHoliday.end)}
          </Typography>
          <Box display={"flex"}>
            <Checkbox
              style={{ padding: "0px", marginRight: "5px", color: "black" }}
              checked={checkAll}
              onChange={(e) => {
                onClickLine("all");
                // setLine(e.target.value, setSelectedLines, selectedHoliday)
              }}
              onClick={handleClickCheckAll}
              // value={selectedLines[i.id]}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <Typography
              style={{ marginTop: 2, color: "black" }}
              className={classes.text}
            >
              Select All
            </Typography>
          </Box>
          <Divider style={{ marginTop: 10 }} />
          <div className="list-group" style={{ height: 260, overflow: "auto" }}>
            {selectedHoliday.lines.map((i) => (
              <Box className="list-group-item" display="flex" key={i.id}>
                <Checkbox
                  className="form-check-input"
                  key={i.id + JSON.stringify(selectedLines[i.id])}
                  style={{ padding: "0px", marginRight: "5px", color: "black" }}
                  checked={selectedLines[i.id] | checkAll}
                  onChange={(e) => {
                    onClickLine(i.id);
                    // setLine(e.target.value, setSelectedLines, selectedHoliday)
                  }}
                  onClick={handleClickChecked}
                  // value={selectedLines[i.id]}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <Typography className={classes.text}>{i["code"]}</Typography>
              </Box>
            ))}
          </div>
          <Divider style={{ marginBottom: 10 }} />
          <div className={classes.buttonContainer}>
            <Button
              title="permissions"
              disabled={!permissions?.permissions.includes("operation_loss_delete")}
              // className={classes.buttons}
              className="btn_ btn_delete"
              variant="contained"
              startIcon={<DeleteIcon />}
              // color="primary"
              onClick={onDeleteOperationLoss}
            >
              Delete
            </Button>
            {selectedLines["all"] ? (
              <EditHolidayButton
                title="permissions"
                disabled={!permissions?.permissions.includes("operation_loss_edit")}
                classes={classes}
                selectedHoliday={selectedHoliday}
                setCheckAll={setCheckAll}
              />
            ) : (disableEdit || !disableEdit) ? (
              <EditHolidayButton
                title="permissions"
                disabled={disableEdit && (permissions?.permissions.includes("operation_loss_edit"))}
                classes={classes}
                selectedHoliday={selectedHoliday}
                setCheckAll={setCheckAll}
              />
            ) : (
              <EditHolidayButton
                title="permissions"
                disabled={!permissions?.permissions.includes("operation_loss_edit")}
                classes={classes}
                selectedHoliday={selectedHoliday}
                setCheckAll={setCheckAll}
              />
            )}
          </div>
        </Box>
      </Paper>
    );
};

const EditHolidayButton = ({ classes, selectedHoliday, disabled, setCheckAll }) => {
  const [type, setType] = React.useState('');
  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const dataProvider = useDataProvider();
  const [isOpen, setIsOpen] = React.useState(false);
  const [mutate, { mutateLoading, onSuccess, onError }] = useMutation();
  const [formData, setFormData] = React.useState({
    id: selectedHoliday.id,
    reason: selectedHoliday.title,
    start: selectedHoliday.start,
    end: selectedHoliday.end,
    type: selectedHoliday.type,
    lines: selectedHoliday.lines.map((ob) => ob.id)
  });

  const handleOpen = () => {
    setIsOpen(true);
  }
  const handleClose = () => setIsOpen(false);

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const setFormat = (formData) => {
    return {
      id: formData.id,
      reason: formData.reason,
      type: formData.type,
      start: format_to_date_time(formData.start),
      end: format_to_date_time(formData.end),
      lines: formData.lines,
    };
  };

  const onMassageAction = (massage = false) => {
    if (massage) {
      showToastSuccess("Successfully updated");
    } else {
      showToastError("Please try again!");
      return false;
    }

    setTimeout(() => {
      window.location.reload(false);
    }, 2000);
  };

  const validateFormData = () => {
    if (formData.reason === "") {
      return "Please fill the reason field";
    }
    if (formData.start === "" || formData.end === "") {
      return "Please fill both start date and end date";
    }
    if (formData.start > formData.end) {
      return "End date should be later than start date"
    }
    return false;
  };

  const submitData = (formData) => {
    const error = validateFormData();
    if (error) {
      showToastError(error);
      return false;
    } else handleClose();
    if (selectedHoliday.eventType == 'Holiday')
      apiCall(formData)
    else
      apiCallOpLoss(formData)
  };



  const apiCall = async (formData) => {
    mutate({
      type: 'CUSTOM_LIST',
      resource: 'holiday',
      payload: {
        action: "edit_holiday_details",
        method: "POST",
        body: setFormat(formData),
      }
    }, {
      onFailure: (e) => {

        showToastError("Holiday Edit Failed.");
      },
      onSuccess: (e) => {

        onMassageAction(true)
      }
    });
  };

  const apiCallOpLoss = async (formData) => {
    mutate({
      type: 'CUSTOM_LIST',
      resource: 'calendar',
      payload: {
        action: "edit_operation_loss",
        method: "POST",
        body: setFormat(formData),
      }
    }, {
      onFailure: (e) => {

        showToastError("Operation Loss Edit Failed.");
      },
      onSuccess: (e) => {

        onMassageAction(true)
      }
    });
  };

  if (selectedHoliday.eventType == 'Holiday')
    return (
      <div>
        <Button
          disabled={disabled}
          onClick={() => {
            setCheckAll(true)
            handleOpen()
          }}
          variant="contained"
          startIcon={<EditIcon />}
          // color="primary"
          // className={classes.buttons}
          className="btn_ btn_primary"
        >
          Edit holiday details
        </Button>
        <Dialog
          fullWidth="true"
          maxWidth="sm"
          open={isOpen}
          onClose={() => {
            setCheckAll(false)
            handleClose()
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={10}>
              <DialogTitle style={{ paddingTop: "10px" }}>
                Edit Holiday Details
              </DialogTitle>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 10,
                  marginRight: 10,
                }}
              >
                <CloseIcon onClick={() => {
                  setCheckAll(false)
                  handleClose()
                }} />
              </div>
            </Grid>
          </Grid>
          {/* <DialogTitle>Edit Holiday Details</DialogTitle> */}
          <DialogContent>
            <i>Note that this changes holiday details for all lines</i>
            <div className={classes.formfield}>
              <TextField
                label={<ReqInputField name="Reason" />}
                defaultValue={selectedHoliday.title}
                onChange={handleChange("reason")}
                variant="outlined"
              />
            </div>
            <div className={classes.formfield}>
              <TextField
                type="date"
                label={<ReqInputField name="Start Date" />}
                defaultValue={format_to_date_for_api(selectedHoliday.start)}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange("start")}
              />
            </div>
            <div className={classes.formfield}>
              <TextField
                type="date"
                label={<ReqInputField name="End Date" />}
                defaultValue={format_to_date_for_api(selectedHoliday.end)}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange("end")}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                onClick={() => {
                  submitData(formData);
                  handleClose();
                }}
                className="btn_ btn_primary"
              >
                {" "}
                Confirm
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  else
    return (
      <div>
        <Button
          disabled={disabled}
          onClick={() => {
            setCheckAll(true)
            handleOpen()
          }}
          variant="contained"
          startIcon={<EditIcon />}
          // color="primary"
          // className={classes.buttons}
          className="btn_ btn_primary"
        >
          Edit operation loss details
        </Button>
        <Dialog
          fullWidth="true"
          maxWidth="sm"
          open={isOpen}
          onClose={() => {
            setCheckAll(false)
            handleClose()
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={10}>
              <DialogTitle style={{ paddingTop: "10px" }}>
                Edit Operation Loss Details
              </DialogTitle>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 10,
                  marginRight: 10,
                }}
              >
                <CloseIcon onClick={() => {
                  setCheckAll(false)
                  handleClose()
                }} />
              </div>
            </Grid>
          </Grid>
          {/* <DialogTitle>Edit Holiday Details</DialogTitle> */}
          <DialogContent>
            <i>Note that this changes operation loss details for all lines</i>
            <div className={classes.formfield}>
              <TextField
                label={<ReqInputField name="Reason" />}
                defaultValue={selectedHoliday.title}
                onChange={handleChange("reason")}
                variant="outlined"
              />
            </div>
            <div className={classes.formfield}>
              <FormControl sx={{ margin: 4, padding: 4 }}>
                <InputLabel id="demo-simple-select">Type</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={formData.type}
                  onChange={handleChange("type")}
                >
                  <MenuItem key={selectedHoliday.type} value={selectedHoliday.type}>SHEDULED</MenuItem>
                  <MenuItem key={selectedHoliday.type} value={selectedHoliday.type}>OTHER</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.formfield}>
              <TextField
                type="datetime-local"
                label={<ReqInputField name="Start Date" />}
                defaultValue={format_to_date_time_for_api(selectedHoliday.start)}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange("start")}
              />
            </div>
            <div className={classes.formfield}>
              <TextField
                type="datetime-local"
                label={<ReqInputField name="End Date" />}
                defaultValue={format_to_date_time_for_api(selectedHoliday.end)}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange("end")}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                onClick={() => {
                  submitData(formData);
                  handleClose();
                }}
                className="btn_ btn_primary"
              >
                {" "}
                Confirm
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
};

const CalendarTypeSelector = ({
  calendarType,
  setCalendarType,
  handleChange,
  classes,
  permissions,
}) => {
  if (permissions !== "BASIC") {
    return (
      <Query type="getList" resource="plants" payload={payload}>
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return "Error";
          }
          const plants = data;
          return (
            <Query type="getList" resource="planBoards" payload={payload}>
              {({ data, loading, error }) => {
                if (loading) {
                  return <Loading />;
                }
                if (error) {
                  return "Error";
                }
                if (calendarType == null) {
                  setCalendarType(`plant-${plants[0].code}`);
                }
                return (
                  <>
                    <FormControl
                      style={{ padding: 0, margin: 0, marginTop: "10px" }}
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Section
                      </InputLabel>
                      {calendarType ? (
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={calendarType}
                          onChange={handleChange}
                        >
                          {plants.map((v) => (
                            <MenuItem key={v.id} value={`plant-${v.code}`}>
                              {v.code}
                            </MenuItem>
                          ))}
                          {data.map((v) => (
                            <MenuItem key={v.id} value={v.code}>
                              {v.code}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  } else {
    return (
      <Query type="getList" resource="planBoards" payload={payload}>
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return "Error";
          }
          return (
            <>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Section</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={calendarType}
                  onChange={handleChange}
                >
                  {data.map((v) => (
                    <MenuItem key={v.id} value={v.code}>
                      {v.code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          );
        }}
      </Query>
    );
  }
};

