import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import { CapacityLoadingChart } from './capacityLoadingChart';


const useStyles = makeStyles({
  list: {
    width: 1720,
  },
  fullList: {
    width: 'auto',
  },
  chartBtn:{
      padding:10
  }
});

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [isShow, setIsShow] = React.useState(false);

  const toggleDrawer = (show) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsShow(show);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     
    </div>
  );

  return (
    <div>
      
        <React.Fragment key={'right'}>
          <Button className="btn_ btn_primary" onClick={toggleDrawer(true)}>View Chart</Button>
          <SwipeableDrawer
            anchor={'right'}
            open={isShow}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <CapacityLoadingChart/>
          </SwipeableDrawer>
        </React.Fragment>
      
    </div>
  );
}
