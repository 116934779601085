import "../../layout/styles/planBoard.css";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Drawer from "@material-ui/core/Drawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef, useState } from "react";
import { Loading, Title, usePermissions } from "react-admin";
import SplitPane from "react-split-pane";
import { connect, useSelector } from "react-redux";
import _ from "lodash";

import DrawerPanel from "../../components/DrawerPanel";
import OverlayLoading from "../../components/loading/OverlayLoading";
import { loadPlanBoard } from "../../store/actions";
import {
  getIsEditing,
  getIsPlanBackgroundLoading,
  getIsPlanLoading,
  getIsPlanning,
  getPendingAction,
  getPlanBoard,
  getPlanningProgress,
  getTools,
} from "../../store/selectors";
import Actions from "./Actions";
import CalendarView2 from "./CalendarView2";
import DemandOperationGroupTable from "./DemandOperationGroupTable";
import DemandOperationsTable from "./DemandOperationsTable";
import IdleSlotsTable from "./IdleSlotsTable";
import DetailsPane from "./DetailsPane";
import PendingAllocationPopup from "./PendingAllocationPopup";
import ToolUtilization from "./ToolUtilization";
import TableView from "./TableView";
import { Box } from "@material-ui/core";
import Calendar, { CalendarDragDropContext } from "./dnd-calendar/Calendar";
import useWindowSize from "@rehooks/window-size";

import AutoSizer from "react-virtualized-auto-sizer";

import dataProvider from "../../api/dataProvider";

import ProgressLoading from "./planProgress/progressLoading";
import BackgroundImage from "../../components/BackgroundImage";
// import StripeLoading from "./planProgress/StripeLoading";
// import PlanBoardGroup from "./PlanBoardGroup";

// const Title = ({ record }) => {
//   return <span>Planboard | {record ? `${record.code}` : ""}</span>;
// };

function PlanBoard({
  onLoad,
  id,
  loading,
  loadingBackground,
  isPlanning,
  isDeleting,
  progress,
  isUserEditing,
  hasTools,
  planBoardOb,
}) {
  // TODO update to new update algorithm

  useEffect(() => {
    onLoad(id);
  }, [id]);

  // const planBoardOb = useSelector(getPlanBoard);

  const { permissions } = usePermissions();

  const title = `Plan Board ${
    planBoardOb && planBoardOb.id ? planBoardOb.id : null
  } : ${planBoardOb && planBoardOb.code ? planBoardOb.code : null} `;
  // const { innerWidth, innerHeight } = useWindowSize();
  // return <div>{lines.map(l => <div>{l.code}</div>)}</div>
  // const [percentage, setPercentage] = useState();

  const [selectedGroup, setSelectedGroup] = useState();
  
  const [hasDemandOpTableOpened, setHasDemandOpTableOpened] = useState(false);

  const { innerWidth, innerHeight } = useWindowSize();
  const isSideBarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const width = Math.max(innerWidth - 100, 100);

  const [tableVisible, setTableVisible] = useState(false);
  const [groupTableVisible, setGroupTableVisible] = useState(false);
  const boardAreaRef = useRef(null);
  const [splitSize, setSplitSize] = useState(400);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const handleSplitChange = (change) => {
    setSplitSize(change);
  };

  if (loading) {
    return <><Loading /><BackgroundImage imageName="city" /></>;
  }

  if (isPlanning) {
    return (
      <>
      <ProgressLoading data={progress}/>
      <BackgroundImage imageName="mountains" />
      </>
    );
  }

  const getEditingUser = () => {
    if (typeof is_user_editing === "array") {
    }
  };

  return (
    <AutoSizer style={{ width: "100%", height: "100%" }}>
      {(autoProps) => {
        const autoHeight = autoProps.height;
        const autoWidth = autoProps.width;
        return (
          <div style={{ height: "100%", width: autoWidth }}>
            <Title title={title} />
            <OverlayLoading open={loadingBackground} />
            <Box
              display="flex"
              flexDirection="column"
              style={{ height: "88vh", margin: "0px -25px" }}
            >
              <Box
                flexGrow={0}
                flexShrink={1}
                style={{
                  // marginTop: 10,
                  // marginBottom: 5,
                  paddingRight: 5,
                }}
              >
                <Actions
                  selectedGroup={selectedGroup}
                  setSelectedGroup={setSelectedGroup}
                  selectedOrderIds={selectedOrderIds}
                />
                {isUserEditing === "user" ? (
                  <Typography
                    color="secondary"
                    className="alert alert-warning color-warning py-1 mb-1"
                  >
                    *You are editing this planboard. Others will not be able to
                    edit.
                  </Typography>
                ) : null}
                {typeof isUserEditing === "array" ? (
                  <Typography color="secondary">{`*${getEditingUser} is editing this planboard. You cannot make changes.`}</Typography>
                ) : null}
                {/* {JSON.stringify(splitSize)} {JSON.stringify(boardAreaRef?.current?.offsetHeight)} */}
              </Box>

              <Box
                display="flex"
                flexShrink={1}
                flexGrow={1}
                flexBasis="auto"
                style={{ overflowY: "hidden" }}
              >
                <CalendarDragDropContext>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                  >
                    <SplitPane
                      ref={boardAreaRef}
                      onChange={_.debounce(handleSplitChange, 300)}
                      split="horizontal"
                      style={{ position: "relative", width }}
                      minSize={150}
                      defaultSize={splitSize}
                    >
                      {/* <CalendarView2 key={planBoardKey} height={splitSize}  selectedGroup={selectedGroup} /> */}

                      <Card>
                        <Calendar
                          height={splitSize}
                          width={width + 26}
                          setSplitSize={setSplitSize}
                        />
                      </Card>

                      <div
                        style={{
                          backgroundColor: "white",
                          boxShadow: "0 -3px 5px #888",
                        }}
                      >
                        {/* <StripeLoading data={percentage}/> */}
                        {permissions?.permissions.includes("plan_tableView") ? (
                          <TableView
                            setSelectedOrderIds={setSelectedOrderIds}
                            selectedOrderIds={selectedOrderIds}
                            w_width={width + 29}
                            w_height={autoHeight - splitSize}
                          />
                        ) : null}
                      </div>
                    </SplitPane>
                  </Box>
                </CalendarDragDropContext>
              </Box>
            </Box>
            <div
              style={{
                position: "fixed",
                display: "block",
                top: "137px",
                zIndex: 10000,
                right: 10,
                margin: "0 0 0 0",
                height: "200px",
                maxWidth: "300px",
              }}
            >
              <DetailsPane />
            </div>
            <div
              style={{
                position: "fixed",
                display: "flex",
                flexDirection: "row",
                right: "25px",
                bottom: "1%",
                zIndex: 10000,
                margin: "0 0 0 0",
                height: "28px",
                maxWidth: "700px",
              }}
            >
              <DrawerPanel
                disabled={!permissions?.permissions.includes("plan_orderTable")}
                title="Order Table"
                onOpen={() => setHasDemandOpTableOpened(true)}
              >
                {hasDemandOpTableOpened && <DemandOperationsTable />}
              </DrawerPanel>
              <DrawerPanel
                disabled={
                  !permissions?.permissions.includes("plan_orderGroupTable")
                }
                title="Order Group Table"
              >
                {(open) => <DemandOperationGroupTable open={open} />}
              </DrawerPanel>
              {hasTools && (
                <DrawerPanel
                  disabled={
                    !permissions?.permissions.includes(
                      "plan_toolUtilizationTable"
                    )
                  }
                  title="Tool Utilization"
                >
                  {(open) => open && <ToolUtilization />}
                </DrawerPanel>
              )}
              <DrawerPanel
                title="Idle Slots"
                disabled={
                  !permissions?.permissions.includes("plan_idleSlotsTable")
                }
              >
                {(open) => <IdleSlotsTable open={open} />}
              </DrawerPanel>
            </div>
            <Drawer
              anchor="bottom"
              open={tableVisible}
              onClose={() => setTableVisible(false)}
            ></Drawer>
            <PendingAllocationPopup />
            {/* <CardContent> */}
            {/* <Grid container spacing="3" style={{ flexGrow: 1 }}> */}
            {/* <Grid item md={9} style={{ width: innerWidth * 0.9 }}> */}
            {/* <div style={{ height: '100%', backgroundColor: 'pink' }}> */}
            {/* <SplitPane split="horizontal" style={{ position: 'relative' }} minSize={150} defaultSize={200}  >
          <div style={{ overflow: 'hidden' }}>
              <CalendarView2 selectedGroup={selectedGroup} />
          </div>
          <div style={{ overflow: 'hidden', height: '100%', backgroundColor: 'pink' }}>
            <TableView />
          </div>
        </SplitPane> */}
            {/* </div> */}
            {/* </Grid> */}
            {/* <Grid item md={3}>
            <DetailsPane />
          </Grid>
          <Grid item md={12}>
            <DemandOperationsTable />
          </Grid> */}
            {/* </Grid> */}
            {/* </CardContent> */}
            {/* </Card> */}
          </div>
        );
      }}

    </AutoSizer>
  );
}

const mapStateToProps = (state, props) => {
  const loading = getIsPlanLoading(state);
  const loadingBackground = getIsPlanBackgroundLoading(state);
  const isPlanning = getIsPlanning(state);
  const progress = getPlanningProgress(state);
  const isUserEditing = getIsEditing(state);
  const tools = getTools(state, {});
  const hasTools = tools.length > 0;
  const planBoardOb = getPlanBoard(state);

  return {
    loading,
    loadingBackground,
    isPlanning,
    progress,
    isUserEditing,
    hasTools,
    planBoardOb,
  };
};

const mapDispatchToProps = {
  onLoad: loadPlanBoard,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanBoard);
