import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-data-grid/dist/react-data-grid.css";
import "react-toastify/dist/ReactToastify.css";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import React from "react";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";
import { toast } from "react-toastify";
import DataProvider from "./api/dataProvider";
import authprovider from "./auth/authProvider";
// import { ShowCharts } from "./components/charts";
import { About } from "./components/Doc/About";
import LoginPage from "./components/Login/Login";
import { ProductTree } from "./components/productsStructure/ProductStructureEditor";
import { ActivityLog } from "./containers/activityLog/ActivityLog";
import { Allocation } from "./containers/allocation";
import { CreateAllocation } from "./containers/allocation/Create";
import { EditAllocation } from "./containers/allocation/Edit";
import { ShowAllocation } from "./containers/allocation/Show";
import { Calendar } from "./containers/calendar";
import { CreateCalendar } from "./containers/calendar/Create";
import { EditCalendar } from "./containers/calendar/Edit";
import { ChangeLog } from "./containers/changeLog";
import { Demand } from "./containers/demand";
import { CreateDemand } from "./containers/demand/Create";
import { EditDemand } from "./containers/demand/Edit";
import { DemandOperation } from "./containers/demandOperation";
import { CreateDemandOperation } from "./containers/demandOperation/Create";
import { EditDemandOperation } from "./containers/demandOperation/Edit";
import { showDemandOperations } from "./containers/demandOperation/Show";
import Transfers from "./containers/demandOperation/Transfers";
import { DrilDownView } from "./containers/drilDownview";
import { HolidayCalendar } from "./containers/holidayCalendar";
import { CreateHoliday } from "./containers/holidayCalendar/CreateHoliday";
import { CreateShiftPattern } from "./containers/holidayCalendar/CreateShiftPattern";
import { CreateOperationLoss } from "./containers/holidayCalendar/CreateOperationLoss";
import { IntegrationRecord } from "./containers/integrationRecord";
import { Item } from "./containers/item";
import { CreateItems } from "./containers/item/Create";
import { ItemEdit } from "./containers/item/Edit";
import { ItemOperations } from "./containers/itemOperations";
import { ItemOperationsCreate } from "./containers/itemOperations/Create";
import { EditOperations } from "./containers/itemOperations/Edit";
import { LineBucket } from "./containers/LineBucket/";
import { CreateLineBucket } from "./containers/LineBucket/Create";
import { EditLineBucket } from "./containers/LineBucket/Edit";
import { MaterialInventory } from "./containers/materialRequirements";
import { MaterialRequests } from "./containers/materialRequirements";
import NotificationPage from "./containers/Notification";
import Notyfi from "./containers/Notification/NotyfyPannel";
import RecivedNotifyPanal from "./containers/Notification/RecivedNotifyPanal";
import TransferNotifyPanal from "./containers/Notification/TransferNotifyPanal";
import { Operations } from "./containers/operations";
import { OperationEdit } from "./containers/operations/Edit";
import { OperationWorkstations } from "./containers/operationWorkstations";
import { OperationWorkstationsCreate } from "./containers/operationWorkstations/Create";
import { OperationWorkstationsEdit } from "./containers/operationWorkstations/Edit";
import { Order } from "./containers/order";
import { CreateOrder } from "./containers/order/Create";
import { orderEdit } from "./containers/order/Edit";
import { OrderGroup } from "./containers/orderGroup";
import { CreateOrderGroup } from "./containers/orderGroup/Create";
import { OrderResolveOption } from "./containers/orderResolveOption/";
import { CreateOrderResolveOption } from "./containers/orderResolveOption/Create";
import { EditOrderResolveOption } from "./containers/orderResolveOption/Edit";
import { PlanBoardList } from "./containers/planBoard";
import { CreatePlantBoard } from "./containers/planBoard/Create";
import { EditPlanBoard } from "./containers/planBoard/Edit";
import PlanBoardPageContainer from "./containers/planBoard/PlanBoardPageContainer";
import { showPlanBoard } from "./containers/planBoard/View";
import { PlanBoardLineList } from "./containers/planBoardLine";
import { CreatePlanBoardLine } from "./containers/planBoardLine/Create";
import { EditPlanBoardLine } from "./containers/planBoardLine/Edit";
import { PlanBoardLineCompatibility } from "./containers/planBoardLineCompatibility";
import { CreatePlanBoardLineCompatibility } from "./containers/planBoardLineCompatibility/Create";
import { EditPlanBoardLineCompatibility } from "./containers/planBoardLineCompatibility/Edit";
import { PlanBoardLineGroup } from "./containers/planBoardLineGroup";
import { CreatePlanBoardLineGroup } from "./containers/planBoardLineGroup/Create";
import { EditPlanBoardLineGroup } from "./containers/planBoardLineGroup/Edit";
import { PlanBoardVersion } from "./containers/planBoardVersion";
import { CreatePlanBoardVersion } from "./containers/planBoardVersion/Create";
import { EditPlanBoardVersion } from "./containers/planBoardVersion/Edit";
import { PlanRevisionList } from "./containers/planRevision";
import { Plants } from "./containers/plants";
import { CreatePlant } from "./containers/plants/Create";
import { PlantEdit } from "./containers/plants/Edit";
import PlanView from "./containers/planView/PlanView";
import { RequiredDemand } from "./containers/requiredDemand";
import { CreateRequiredDemand } from "./containers/requiredDemand/Create";
import { EditRequiredDemand } from "./containers/requiredDemand/Edit";
import { TimeStamp } from "./containers/timeStamps";
import { MainTransferView } from "./containers/timeStamps/transfer/MainTransferView";
import Settings from "./containers/settings";
import { CreateReason } from "./containers/settings/Reason/Create";
import { EditReason } from "./containers/settings/Reason/Edit";
import { Reason } from "./containers/settings/Reason/Reason";
import { Sites } from "./containers/sites";
import { CreateSite } from "./containers/sites/Create";
import { SiteEdit } from "./containers/sites/Edit";
import { SubContract } from "./containers/subContract";
import { CreateSubContract } from "./containers/subContract/Create";
import { SubContractEdit } from "./containers/subContract/Edit";
import { ShowSubContract } from "./containers/subContract/Show";
import { SwitchingDetailList } from "./containers/switchingDetail";
import { Tool } from "./containers/Tool";
import { CreateTool } from "./containers/Tool/Create";
import { EditTool } from "./containers/Tool/Edit";
import { CreateTrackingRecord } from "./containers/trackingRecord/Create";
import { EditTrackingRecord } from "./containers/trackingRecord/Edit";
import { TrackingRecord } from "./containers/trackingRecord/Index";
import { TrackingStep } from "./containers/trackingStep";
import { CreateTrackingStep } from "./containers/trackingStep/Create";
import { EditTrackingStep } from "./containers/trackingStep/Edit";
import { Users } from "./containers/users";
import { CreateUser } from "./containers/users/Create";
import { UserEdit } from "./containers/users/Edit";
import { Workstations } from "./containers/workstations";
import { WorkstationsCreate } from "./containers/workstations/Create";
import { WorkstationsEdit } from "./containers/workstations/Edit";
import { WorkstationShow } from "./containers/workstations/Show";
import { allSagas } from "./store/allSagas";
import { rootReducer } from "./store/reducers/rootReducer.ts";
import { Profile } from "./containers/profile/Profile";
import NotFound from "./layout/NotFound";
import { theme } from "./layout/theme";
import CustomLayout from "./layout/Layout";
import { CloseOrders } from "./containers/closeOrders";
import Grafana from "./components/dashboard/GrafanaDash";
import GrafanaDashboard from "./components/dashboard/GrafanaDash";
import { PopOver } from "./containers/timeStamps/transfer/components/PopOver";
import ErrorPage from "./layout/ErrorPage";
import { Roles } from "./containers/role";
import { CreateRole } from "./containers/role/Create";
import { EditRole } from "./containers/role/Edit";
import { Permissions } from "./containers/permission";
import { EditPermission } from "./containers/permission/Edit";
import { CreatePermission } from "./containers/permission/Create";
import { ItemStructureUpload } from "./containers/item/ItemStructureUpload";

import { DepartmentsCreate } from "./containers/TNA/departments/create";
import { DepartmentsEdit } from "./containers/TNA/departments/edit";
import { DepartmentsShow } from "./containers/TNA/departments/show";
import { Departments } from "./containers/TNA/departments/index";
import { DelayaReasonCreate } from "./containers/TNA/delayReasons/create";
import { DelayReasonEdit } from "./containers/TNA/delayReasons/edit";
import { DelayReasons } from "./containers/TNA/delayReasons/index";
import { AdminFlow } from "./containers/TNA/flows/index";
import { AdminFlowShow } from "./containers/TNA/flows/show";
import { TaskShow } from "./containers/TNA/flows/taskShow";
import { Templates } from "./containers/TNA/templates/index";
import TnaDashboard from "./containers/TNA/dashboard";
import TaskList from "./containers/TNA/taskList";
import UnassignedTaskList from "./containers/TNA/unassignedTaskList";
import TaskPerform from "./containers/TNA/taskPerform";
import { TemplatesCreate } from "./containers/TNA/templates/create";
import { TemplatesEdit } from "./containers/TNA/templates/edit";
import { TemplatesShow } from "./containers/TNA/templates/show";

// import { showPlanBoard } from './containers/TestPlanBoard/View';
import { DelayQuantities } from "./containers/orderGroup/DelayQuanties";
import PriceListUpload from "./containers/TNA/PerformTasks/PriceListUpload";
import TaskApproval from "./containers/TNA/tasksApproval";
import PriceListImageUpload from "./containers/TNA/itemImagesUpload/ItemImageUpload";
import ItemImageList from "./containers/TNA/itemImagesUpload/imageList";
import Board from "./containers/supply_chain/Board";
import { TemplateItemsCreate } from "./containers/TNA/templateItems/Create.jsx";
import { TemplateItemsEdit } from "./containers/TNA/templateItems/Edit.jsx";
import TNACalendar from "./containers/TNA/TnaCalendar";
import TNANotification from "./containers/TNA/notifications/receivedNotifications";

import Forms from "./containers/TNA/forms/Forms";
import ANSL from "./containers/TNA/forms/DSS/ANSL";
import EXP from "./containers/TNA/forms/DSS/EXP";
import SampleRequisition from "./containers/TNA/forms/DSS/Sample-Requisition";
import OTB from "./containers/TNA/forms/OTB";
import { CapacityLoading } from "./containers/capacityLoading";
import { FlowGeneration } from "./containers/TNA/flowGeneration";
import CreateSubTask from "./containers/TNA/createSubtasks";
import { DropdownCreate } from "./containers/TNA/forms/dropDownForm/DropdowncreateUI";
import Sole from "./containers/TNA/forms/SoleSampleRequestForm";
import Ready from "./containers/TNA/forms/ReadyMadeUppersSampleRequestForm";
import PVCPUSampleRequestForm from "./containers/TNA/forms/PVCPUSampleRequestForm";
import { TentativeFlows } from "./containers/TNA/TentativeFlow";
import { InquirySerialVerifier } from "./containers/TNA/InquirySerialVerifier";
import { InquirySerial } from "./containers/TNA/forms/inquirySerial/create";

const i18nProvider = polyglotI18nProvider(
  (locale) => englishMessages,
  "en", // Default locale
  {
    allowMissing: true,
  }
);

// init tost
toast.configure();

const App = () => {
  return (
    <>
      <Admin
        dashboard={GrafanaDashboard}
        catchAll={NotFound}
        layout={CustomLayout}
        loginPage={LoginPage}
        authProvider={authprovider}
        customReducers={{ custom: rootReducer }}
        customSagas={allSagas}
        theme={theme}
        dataProvider={DataProvider}
        i18nProvider={i18nProvider}
        customRoutes={[
          // <Route path="/Quantity" component={ShowDelayQuantityCharts} />,
          // <Route path="/Charts" component={ChartsPage} />,
          <Route path="/supply_chain" component={Board} />,
          <Route path="/about" component={About} />,
          <Route path="/demandOps" component={DrilDownView} />,
          <Route path="/about" component={About} />,

          <Route path="/userProfile" component={Profile} />,
          <Route path="/floorView" component={PlanView} />,
          <Route path="/holidays" component={HolidayCalendar} />,
          <Route path="/createHoliday" component={CreateHoliday} />,
          <Route path="/createOperationLoss" component={CreateOperationLoss} />,
          <Route path="/createShiftPattern" component={CreateShiftPattern} />,
          <Route path="/planBoard/:id" component={PlanBoardPageContainer} />,
          <Route path="/notification" component={NotificationPage} />,
          <Route path="/notifyTransfers" component={Notyfi} />,
          <Route path="/notifyList" component={RecivedNotifyPanal} />,

          <Route path="/notifyRecived" component={TransferNotifyPanal} />,
          <Route path="/transfers/:tab?/:plant?" component={Transfers} />,
          <Route path="/TimeStampTransfers" component={MainTransferView} />,
          <Route path="/settings" component={Settings} />,
          <Route path="/oderCodes" component={PopOver} />,
          <Route path="/error" component={ErrorPage} />,
          <Route path="/ItemStructureUpload" component={ItemStructureUpload} />,
          <Route path="/TnaDashboard" component={TnaDashboard} />,
          <Route path="/todo" component={TaskList} />,
          <Route path="/processing" component={TaskList} />,
          <Route path="/completed" component={TaskList} />,
          <Route path="/unassigned/:searchText?" component={UnassignedTaskList} />,
          <Route path="/allForms" component={Forms} />,
          <Route path="/itemImageUpload" component={PriceListImageUpload} />,
          <Route path="/itemImageList" component={ItemImageList} />,
          <Route path="/taskPerform/:taskMode/:id" component={TaskPerform} />,
          <Route path="/price_list_upload" component={PriceListUpload} />,
          <Route path="/approve_tasks" component={TaskApproval} />,
          <Route path="/flow_generation" component={FlowGeneration} />,
          <Route path="/tna_calendar" component={TNACalendar} />,
          <Route path="/tna_notifications" component={TNANotification} />,
          <Route path="/sam" component={SampleRequisition} />,
          <Route path="/ansl" component={ANSL} />,
          <Route path="/exp" component={EXP} />,
          <Route path="/CapacityReports" component={CapacityLoading} />,
          <Route path="/drop" component={DropdownCreate} />,
          <Route path="/sole" component={Sole} />,
          <Route path="/red" component={Ready} />,
          <Route path="/pvc" component={PVCPUSampleRequestForm} />,
          <Route path="/tentativeOrders" component={TentativeFlows} />,
          <Route
            path="/inquirySerialSubmit"
            component={InquirySerialVerifier}
          />,
          <Route  path="/inquiry_serial" component={InquirySerial} />,
        ]}
      >
        {(permissions) => [
          <Resource name="closedOrderGroup" list={CloseOrders} />,
          <Resource
            name="changeLog"
            list={permissions?.permissions.includes("changeLog") && ChangeLog}
          />,

          <Resource
            name="workstations"
            list={
              permissions?.permissions.includes("workstations") && Workstations
            }
            edit={
              permissions?.permissions.includes("workstations_edit") &&
              WorkstationsEdit
            }
            create={
              permissions?.permissions.includes("workstations_create") &&
              WorkstationsCreate
            }
            show={
              permissions?.permissions.includes("workstations_show") &&
              WorkstationShow
            }
          />,
          <Resource
            name="opWorkstations"
            options={{ label: "Operation workstations" }}
            list={OperationWorkstations}
            create={
              permissions?.permissions.includes("link_operation") &&
              OperationWorkstationsCreate
            }
            edit={OperationWorkstationsEdit}
          />,
          <Resource
            name="itemOp"
            options={{ label: "Item operations" }}
            list={ItemOperations}
            create={ItemOperationsCreate}
            edit={EditOperations}
          />,
          <Resource
            name="orders"
            list={permissions?.permissions.includes("orders") && Order}
            create={
              permissions?.permissions.includes("orders_create") && CreateOrder
            }
            edit={permissions?.permissions.includes("orders_edit") && orderEdit}
          />,
          <Resource
            name="orderGroups"
            options={{ label: "Order groups" }}
            list={
              permissions?.permissions.includes("orderGroups") && OrderGroup
            }
            create={
              permissions?.permissions.includes("orderGroups_create") &&
              CreateOrderGroup
            }
          />,
          <Resource
            name="demand"
            options={{ label: "Demand" }}
            list={permissions?.permissions.includes("demands") && Demand}
            create={
              permissions?.permissions.includes("demands_create") &&
              CreateDemand
            }
            edit={
              permissions?.permissions.includes("demands_edit") && EditDemand
            }
          />,
          <Resource
            name="demandOp"
            options={{ label: "Demand operation" }}
            list={DemandOperation}
            create={CreateDemandOperation}
            edit={EditDemandOperation}
            show={showDemandOperations}
          />,
          <Resource
            name="reqDemand"
            options={{ label: "Required demand" }}
            list={RequiredDemand}
            create={CreateRequiredDemand}
            edit={EditRequiredDemand}
          />,
          <Resource
            name="orderResolve"
            options={{ label: "Order resolve option" }}
            list={OrderResolveOption}
            create={CreateOrderResolveOption}
            edit={EditOrderResolveOption}
          />,
          <Resource
            name="planBoards"
            options={{ label: "Plan board" }}
            list={
              permissions?.permissions.includes("planboards") && PlanBoardList
            }
            create={
              permissions?.permissions.includes("planboards_create") &&
              CreatePlantBoard
            }
            edit={
              permissions?.permissions.includes("planboards_edit") &&
              EditPlanBoard
            }
            show={
              permissions?.permissions.includes("planboards_settings") &&
              showPlanBoard
            }
          />,
          <Resource
            name="calendar"
            list={permissions?.permissions.includes("calendars") && Calendar}
            create={CreateCalendar}
            edit={EditCalendar}
          />,
          <Resource
            name="boardVersion"
            options={{ label: "Plan board version" }}
            list={PlanBoardVersion}
            create={CreatePlanBoardVersion}
            edit={EditPlanBoardVersion}
          />,
          <Resource
            name="boardGroup"
            options={{ label: "Plan Board Line Group" }}
            list={
              permissions?.permissions.includes("planBoardGroups") &&
              PlanBoardLineGroup
            }
            create={
              permissions?.permissions.includes("planBoardGroups_create") &&
              CreatePlanBoardLineGroup
            }
            edit={
              permissions?.permissions.includes("planBoardGroups_edit") &&
              EditPlanBoardLineGroup
            }
          />,
          <Resource
            name="lineCompatibility"
            options={{ label: "Plan Board Line Compatibility" }}
            list={PlanBoardLineCompatibility}
            create={CreatePlanBoardLineCompatibility}
            edit={EditPlanBoardLineCompatibility}
          />,
          <Resource
            name="boardLine"
            options={{ label: "Plan Board Line" }}
            list={
              permissions?.permissions.includes("linesHeads") &&
              PlanBoardLineList
            }
            create={
              (permissions?.permissions.includes("linesHeads_create") ||
                permissions?.permissions.includes("create_efficiency_line") ||
                permissions?.permissions.includes(
                  "create_dailyquantity_line"
                )) &&
              CreatePlanBoardLine
            }
            edit={EditPlanBoardLine} //used by planboard and subcontract
          />,
          <Resource
            name="lineBucket"
            options={{ label: "Line Bucket" }}
            list={LineBucket}
            create={CreateLineBucket}
            edit={EditLineBucket}
          />,
          <Resource
            name="trackStep"
            options={{ label: "Tracking step" }}
            list={TrackingStep}
            create={CreateTrackingStep}
            edit={EditTrackingStep}
          />,
          <Resource
            name="trackRecord"
            options={{ label: "Tracking Record" }}
            list={TrackingRecord}
            create={CreateTrackingRecord}
            edit={EditTrackingRecord}
          />,
          <Resource name="mobileApp" />,
          <Resource name="switchingDetail" list={SwitchingDetailList} />,
          <Resource
            name="tools"
            list={permissions?.permissions.includes("tools") && Tool}
            create={
              permissions?.permissions.includes("tools_create") && CreateTool
            }
            edit={permissions?.permissions.includes("tools_edit") && EditTool}
          />,
          <Resource
            name="plants"
            list={permissions?.permissions.includes("plants") && Plants}
            edit={permissions?.permissions.includes("plants_edit") && PlantEdit}
            create={
              permissions?.permissions.includes("plants_delete") && CreatePlant
            }
          />,
          <Resource
            name="items"
            list={permissions?.permissions.includes("items") && Item}
            show={
              permissions?.permissions.includes("items_show") && ProductTree
            }
            edit={permissions?.permissions.includes("items_edit") && ItemEdit}
            create={
              permissions?.permissions.includes("items_create") && CreateItems
            }
          />,
          <Resource
            name="operations"
            list={permissions?.permissions.includes("operations") && Operations}
            edit={
              permissions?.permissions.includes("operations_edit") &&
              OperationEdit
            }
          />,
          <Resource
            name="sites"
            list={permissions?.permissions.includes("sites") && Sites}
            edit={permissions?.permissions.includes("sites_edit") && SiteEdit}
            create={
              permissions?.permissions.includes("sites_create") && CreateSite
            }
          />,
          <Resource
            name="users"
            list={permissions?.permissions.includes("users") ? Users : null}
            create={
              permissions?.permissions.includes("users_create") || true
                ? CreateUser
                : null
            }
            edit={
              permissions?.permissions.includes("users_edit") ? UserEdit : null
            }
          />,
          <Resource
            name="roles"
            list={permissions?.permissions.includes("roles") && Roles}
            create={
              permissions?.permissions.includes("roles_create")
                ? CreateRole
                : null
            }
            edit={
              permissions?.permissions.includes("roles_edit") ? EditRole : null
            }
          />,
          <Resource
            name="permissions"
            list={
              permissions?.permissions.includes("permissions") && Permissions
            }
            create={
              permissions?.permissions.includes("permissions")
                ? CreatePermission
                : null
            }
            edit={
              permissions?.permissions.includes("permissions")
                ? EditPermission
                : null
            }
          />,

          <Resource
            name="subContracts"
            list={
              permissions?.permissions.includes("subcontracts") && SubContract
            }
            edit={
              permissions?.permissions.includes("subcontracts_edit") &&
              SubContractEdit
            }
            create={
              permissions?.permissions.includes("subcontracts_create") &&
              CreateSubContract
            }
            show={ShowSubContract}
          />,
          <Resource name="integrationRecord" list={IntegrationRecord} />,
          <Resource
            name="allocations"
            list={Allocation}
            create={CreateAllocation}
            edit={EditAllocation}
            show={ShowAllocation}
          />,
          <Resource
            name="activityLog"
            options={{ label: "Activity Log" }}
            list={ActivityLog}
            edit={PlantEdit}
            create={CreatePlant}
          />,
          <Resource
            name="materialInventory"
            list={
              permissions?.permissions.includes("materialInventory") &&
              MaterialInventory
            }
          />,
          <Resource
            name="planRevision"
            list={
              permissions?.permissions.includes("plan_viewHistory") &&
              PlanRevisionList
            }
          />,
          <Resource name="materialStore" />,
          <Resource name="rmRequest" list={MaterialRequests} />,
          <Resource
            name="rmTimeStamp"
            list={permissions?.permissions.includes("rmTimestamp") && TimeStamp}
          />,
          <Resource
            create={
              permissions?.permissions.includes("changeReasons_create") &&
              CreateReason
            }
            edit={
              permissions?.permissions.includes("changeReasons_edit") &&
              EditReason
            }
            name="changeReason"
            list={permissions?.permissions.includes("changeReasons") && Reason}
          />,
          <Resource name="compatibleWorkstations" />,
          <Resource name="dashboard" />,
          <Resource name="delayQuantities" list={DelayQuantities} />,
          <Resource name="tna_tasks" />,
          <Resource
            name="tna_departments"
            create={
              permissions?.permissions.includes("tna_departments_create") &&
              DepartmentsCreate
            }
            edit={
              permissions?.permissions.includes("tna_departments_edit") &&
              DepartmentsEdit
            }
            list={
              permissions?.permissions.includes("tna_departments") &&
              Departments
            }
            show={
              permissions?.permissions.includes("tna_departments_edit") &&
              DepartmentsShow
            }
          />,
          <Resource
            name="tna_orders"
            list={permissions?.permissions.includes("tna_flow") && AdminFlow}
            show={
              permissions?.permissions.includes("tna_flow") && AdminFlowShow
            }
          />,
          <Resource
            name="tna_createSubTasks"
            list={
              permissions?.permissions.includes("tna_tasks_assign") && CreateSubTask
            }
          />,

          <Resource
            name="tna_itemTasks"
            show={permissions?.permissions.includes("tna_flow") && TaskShow}
          />,
          <Resource
            name="tna_templates"
            list={
              permissions?.permissions.includes("tna_templates") && Templates
            }
            create={
              permissions?.permissions.includes("tna_templates_create") &&
              TemplatesCreate
            }
            edit={
              permissions?.permissions.includes("tna_templates_create") &&
              TemplatesEdit
            } //template edit is used as clone
            show={
              permissions?.permissions.includes("tna_templates_show") &&
              TemplatesShow
            }
          />,
          <Resource
            name="tna_templateItems"
            // create={TemplateItemsCreate}
            edit={
              permissions?.permissions.includes("tna_templates_edit") &&
              TemplateItemsEdit
            }
          />,
          <Resource
            name="tna_delayreasons"
            create={
              permissions?.permissions.includes("tna_delay_reasons_create") &&
              DelayaReasonCreate
            }
            edit={
              permissions?.permissions.includes("tna_delay_reasons_edit") &&
              DelayReasonEdit
            }
            list={
              permissions?.permissions.includes("tna_delay_reasons") &&
              DelayReasons
            }
          />,
          //   <Resource
          //   name="dropdown"
          //   create={
          //     CreateDropdown
          //   }
          //   // edit={
          //   //   permissions?.permissions.includes("tna_delay_reasons_edit") &&
          //   //   DelayReasonEdit
          //   // }
          //   list={
          //     DropDownData
          //   }
          // />,
        ]}
      </Admin>
    </>
  );
};
export default App;