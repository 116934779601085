import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { Link } from "react-admin";
import { format_to_date_time } from "../../../../lib/date_time";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const AdminView = ({ payload }) => {
  const classes = useStyles();
  const rows = payload;
  return (
    <>
      <h3 style={{ marginTop: 20 }}>Transfer Time stamps for Admin users </h3>
      <TableContainer component={Paper} style={{ marginTop: 40 }}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Transfer id</StyledTableCell>
              <StyledTableCell align="right">Oder code</StyledTableCell>
              <StyledTableCell align="right"> Sent user </StyledTableCell>
              <StyledTableCell align="right"> Sent to </StyledTableCell>
              <StyledTableCell align="right"> Received from </StyledTableCell>
              <StyledTableCell align="right"> Sent on </StyledTableCell>
              <StyledTableCell align="right"> Received on </StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
              <StyledTableCell align="right"> Action made on </StyledTableCell>
              {/* action made on date is wen user actept or dicline this date shoud change  */}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.transfer_id}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.order_code.length > 1 ? (
                    <Link
                      to={{
                        pathname: `/oderCodes`,
                        state: { row },
                      }}
                    >
                      Oder Codes
                    </Link>
                  ) : (
                    row.order_code
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {" "}
                  {row.user_name}{" "}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {" "}
                  {row.transfer_sent}{" "}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {" "}
                  {row.received_plant}{" "}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {" "}
                  {format_to_date_time(row.transfer_date)}{" "}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {" "}
                  {format_to_date_time(row.received_date)}{" "}
                </StyledTableCell>
                <StyledTableCell align="right">{row.action}</StyledTableCell>
                <StyledTableCell align="right">
                  {" "}
                  {format_to_date_time(row.action_time)}{" "}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
