import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const BorderLinearProgress = withStyles({
  root: {
    marginLeft:10,
    height: 5,
    backgroundColor: "#f2f7f4",
    width: "90%",
  },
  bar: {
    backgroundColor: "#14e34b",
  },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft:10,
    width: "90%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      backgroundColor: "#14e34b",
    },

    bar: {
      backgroundColor: "#14e34b",
    },
  },
}));

const ProgressBar = React.memo(({ value, completed }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip title={`progress ${value}`}>
        {value === 100 ? (
          <BorderLinearProgress
            variant="determinate"
            value={value}
            style={{ width: "100%" }}
          />
        ) : (
          <LinearProgress
            variant="determinate"
            value={value}
            color="secondary"
          />
        )}
      </Tooltip>
    </div>
  );
});
export default ProgressBar;
