import { LOAD_PLANBOARD_REVISION_SUCCESS } from "../../types"

const initialState = {
    id: null,
}

export const activeRevisionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOAD_PLANBOARD_REVISION_SUCCESS: {
            const { revision_id } = action.payload
            return { ...state, id: revision_id }
        }

        default:
            return state
    }
}