import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import React from "react";
import { Link } from "react-admin";

const styles = {
  root: {
    minWidth: 500
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

const Received = ({ notifycations, count }) => {
  //const counter = useSelector(state => state.custom.notifications.notification)

  return (
    <div>
      <Grid container spacing={3} style={{ marginTop: 30, marginLeft: 20, width: "400px" }}>
        <div class="col-md-12 notify">
          <Grid item xs variant="outlined">
            <Card className={styles.root}>
              <CardContent>
                <div style={{ display: "flex" }}>
                  <Badge
                    badgeContent={count}
                    color="secondary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    style={{ justifyContent: "flexStart" }}
                  >
                    <MailIcon />
                  </Badge>

                  <Typography
                    className={styles.title}
                    color="textSecondary"
                    gutterBottom
                    style={{
                      justifyContent: "flexEnd",
                      paddingLeft: "30px",
                      fontSize: "30px",
                      fontWeight: "700",
                      color:"#00acee"
                    }}
                  >
                    Received
                  </Typography>
                </div>
              </CardContent>

              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  component={Link}
                  style={{fontSize:"16px", width: "100%", height: "50px", borderRadius: "0px", marginTop: "100px", backgroundColor: "#c5edfd", color: "#00acee"}}
                  to={{
                    ///notifyRecived
                    pathname: "/notifyList",
                    state: { notify: notifycations }
                  }}
                >
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

// const mapStateToProps = (state, props) => {
//   const loading = getLoading(state);
//   const notifycations = getNotification(state);
//   return {
//     loading,
//     notifycations
//   };
// };

export default Received;
//connect(mapStateToProps, null)(notifycationsPanal);
