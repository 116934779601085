import React from "react";
import {
  Datagrid,
  List,
  CardActions,
  ExportButton,
  TextField,
  TextInput,
  Filter,
  DateField,
  FunctionField,
  usePermissions,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ChangeDiv } from "../../components/ChangeDiv";
import moment from "moment"

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const Title = () => {
  return <span> Delay Quantities</span>;
};

const OrderGroupFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by Group Code" source="code" alwaysOn />
  </Filter>
);
const DateTimeField = ({ record = {} }) => {
  return (
    <Typography variant="body1" gutterBottom>
      {record.required_date && format_to_date_for_api(record.required_date)}
    </Typography>
  );
};

const format_to_date_for_api = datetime => {
  if (datetime) {
    return moment(datetime).format("YYYY-MM-DD")
  }
  else {
    return "N/A"
  }
}
export const DelayQuantities = (props) => {
  const classes = useStyles();
  const { permissions } = usePermissions();

  const Actions = () => (
    <div className={classes.buttonContainer}>
      <CardActions>
        <ExportButton
          title="Click here to Export"
          disabled={!permissions?.permissions.includes("activityLog_export")}
          className="btn_ btn_primary "
        />
      </CardActions>
    </div>
  );

  return (
    <List {...props} perPage={25} filters={<OrderGroupFilter />} bulkActionButtons={false} title={<Title />} actions={<Actions />}>
      <Datagrid expand={<DelayQuantitiesShow />}>
        <TextField source="id" />
        <TextField source="code" />
        <FunctionField label="Planned End Date" className="col-sm-6 col-xl-6  col-sm-12 nopadding text_inline" render={record => {
          const order_panned_end_date = record.order_groups_palanned_end_date ? record.order_groups_palanned_end_date.max_order_date__max : "NA"
          return <div
          >{`${format_to_date_for_api(order_panned_end_date)}`}</div>
        }}
        />
        <DateTimeField source="required_date" />
      </Datagrid>
    </List>
  );
};

const DelayQuantitiesShow = (props) => {
  const { record } = props;

  return (
    <div>
      {record.orders &&
        record.orders.map((el) => (
          <div >

            <Chip
              variant="outlined"
              size="small"
              color="primary"
              label={`${el.code}`}
              className='delayQtyChip'
            />

            <Chip
              variant="outlined"
              size="small"
              color="primary"
              label={`${format_to_date_for_api(el.order_planned_end_date)}`}
              className='delayQtyChipEndDate'
            />

            <Chip
              variant="outlined"
              size="small"
              color="primary"
              label={`${format_to_date_for_api(el.required_date)}`}
              className='delayQtyChip'
            />
          </div>
        ))}
    </div>
  );
};
