import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { useDataProvider, usePermissions } from "react-admin";
import { AdminView } from "./components/AdminView";
import { Received } from "./components/Recived";
import { Transfer } from "./Transfer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const MainTransferView = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();

  const [value, setValue] = React.useState(0);
  const [transfer, setTransferData] = React.useState([]);
  const [recived, setRecivedData] = React.useState([]);

  //lord data
  const loadData = async (rec) => {
    const data = await dataProvider.TIMESTAMP_CUSTOM_LIST("transferTimeStamp", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      actions: `1/${rec ? "get_recived_timestamp" : "get_transfer_timestamp"}`,
    });
    return data;
  };

  const resolveData = async () => {
    const data = await loadData();
    const recived_data = await loadData(true);

    if (data) {
      setTransferData(data.data);
    }
    if (recived_data) {
      setRecivedData(recived_data.data);
    }
  };

  React.useEffect(() => {
    resolveData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dataSourceManager = (source, type) => {
    return source.filter(
      (data) =>
        data.order_code[0] !== null &&
        !(type === "recived" && data.action === "pending")
    );
  };
  const transferData = dataSourceManager(transfer);
  const recivedData = dataSourceManager(recived, "recived");
console.log(transferData,recivedData)
  return (
    <>
      {["ADMIN", "SITE"].includes(permissions?.access_level) ? (
        <AdminView
          payload={transferData}
          recivedData={recivedData}
          permissions={permissions}
        />
      ) : (
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              <LinkTab label="Transfers" href="/drafts" {...a11yProps(0)} />
              <LinkTab label=" Received" href="/trash" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Transfer data={transferData} permissions={permissions} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Received data={recivedData} />
          </TabPanel>
        </div>
      )}
    </>
  );
};
