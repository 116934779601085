import React from 'react'
// import redClose from '../../../style/img/close-red.jpg'
import redClose from '../../../layout/img/close-red.png'

export const CloseButton = React.memo(() => {
    return (
        <div>
            <img src={redClose} width='30px' hight='30px' />
        </div>
    )
})
