import _, { isEmpty } from "lodash";
import { evaluateExpression } from "../../evaluator";
import { getUuid } from "../../plan_board/utils";
import { IAllocation, IBucket, IDemandOperation, IDemandOperationPart, ILinePart, IPlan } from "../interfaces";

export function create_config_change(plan: IPlan, bucket: IBucket,nextBucket:IBucket | undefined, start_time: Date, linePart: ILinePart, from_configuration: string, to_configuration: string):[IAllocation[],boolean] {

    const durationHours = getSwitchingTime(plan, bucket.line_id, from_configuration, to_configuration);

    const end_time = new Date(start_time.valueOf() + durationHours * 3600 * 1000)
    if (end_time > bucket.end_time || end_time > linePart.end_time) {
        if(end_time > bucket.end_time && nextBucket && bucket.end_time.getTime()== nextBucket.start_time.getTime()){
            const secondAllocEndTime=new Date(nextBucket.start_time.getTime()+ (durationHours * 3600 * 1000 - (bucket.end_time.getTime() - start_time.getTime())))
            const firstConfigSwitchAlloc={
                demand_operation_id: 0,
                plan_board_line_id: bucket.line_id,
                deleted: false,
                end_time:bucket.end_time,
                start_time,
                completed_quantity: 0,
                configuration: to_configuration,
                id: getUuid(),
                is_fixed: false,
                is_freezed: false,
                is_tools_availabile: true,
                quantity: 0,
                total_smv: 0,
                line_bucket_id: bucket.id
            }
            const secondConfigSwitchAlloc={
                demand_operation_id: 0,
                plan_board_line_id: bucket.line_id,
                deleted: false,
                end_time: secondAllocEndTime,
                start_time:nextBucket.start_time,
                completed_quantity: 0,
                configuration: to_configuration,
                id: getUuid(),
                is_fixed: false,
                is_freezed: false,
                is_tools_availabile: true,
                quantity: 0,
                total_smv: 0,
                line_bucket_id: nextBucket.id
            }
            return [[firstConfigSwitchAlloc,secondConfigSwitchAlloc],true]

        }else{
            return [[],false]
        }
        
    }
    return [[{
        demand_operation_id: 0,
        plan_board_line_id: bucket.line_id,
        deleted: false,
        end_time,
        start_time,
        completed_quantity: 0,
        configuration: to_configuration,
        id: getUuid(),
        is_fixed: false,
        is_freezed: false,
        is_tools_availabile: true,
        quantity: 0,
        total_smv: 0,
        line_bucket_id: bucket.id
    }],false]
}

function getSwitchingTime(plan: IPlan, line_id: number, from_configuration: string, to_configuration: string) {
    const groupId = plan.lines[line_id].plan_board_group_id;
    const fromConfigList = from_configuration.split(":");
    const toConfigList = to_configuration.split(":");
    const group = plan.groups[groupId]
    if (group.is_switching_time_script) {
        const durationHours = evaluateExpression(`${plan.groups[groupId].switching_time_script}`, { from: fromConfigList, to: toConfigList });
        return durationHours
    }

    return group.switching_time;
}

export function smv_based_forward_allocator(plan: IPlan, bucket: IBucket, nextBucket: IBucket | undefined, linePart: ILinePart, start_time: Date, initial_configuration: string, part: IDemandOperationPart): IAllocation[] {
    // TODO Implement
    if (bucket.skipped) {
        return []
    }
    const demandOperation = plan.demand_operations[part.demand_operation_id]
    let time = _.max([bucket.start_time, start_time, linePart.start_time]) as Date
    let allocations: IAllocation[] = []

    const shouldConfigChange = shouldChangeConfiguration(plan, bucket.line_id, initial_configuration, demandOperation);

    const freezedConfigChangeDurationInMillis = shouldChangeConfiguration(plan, bucket.line_id, linePart.end_config, demandOperation) ?
                                                getSwitchingTime(plan, bucket.line_id, demandOperation.configuration, linePart.end_config)*3600*1000 : 0;

    const hasEnoughSwitchingTimeInNextBucket = nextBucket ? linePart.end_time.valueOf() - nextBucket.start_time.valueOf() >= freezedConfigChangeDurationInMillis : false 
    if (!hasEnoughSwitchingTimeInNextBucket && typeof nextBucket !== 'undefined') {
        nextBucket.skipped = true
    }                                                

    let isMultipleConfigSwitch=false
    if (shouldConfigChange) {
        const configSwitchAllocs=create_config_change(plan, bucket,nextBucket, time, linePart, initial_configuration, demandOperation.configuration)
        isMultipleConfigSwitch=configSwitchAllocs[1]
        allocations = [...allocations, ...configSwitchAllocs[0]]
        time = allocations[allocations.length - 1]?.end_time || time
        // If no allocaito is created that meand the this bucket remaining time is not enough for config change. So can't allocate anything here.
        if (allocations.length === 0) {
            return []
        }
    }
    time = _.max([demandOperation.rm_ready_date, time]) as Date

    if (time >= (_.min([bucket.end_time, linePart.end_time]) as Date)) {
        if(isMultipleConfigSwitch){
            return allocations
        }else{
            return []
        }
    }

    let quantity_possible = bucket.end_time > linePart.end_time ? 
                            (bucket.carder * bucket.efficiency * (linePart.end_time.valueOf() - freezedConfigChangeDurationInMillis - time.valueOf())) / (3600 * 1000 * demandOperation.smv) :
                            hasEnoughSwitchingTimeInNextBucket ? 
                            (bucket.carder * bucket.efficiency * (bucket.end_time.valueOf() - time.valueOf())) / (3600 * 1000 * demandOperation.smv) :
                            (bucket.carder * bucket.efficiency * (bucket.end_time.valueOf() - freezedConfigChangeDurationInMillis - time.valueOf())) / (3600 * 1000 * demandOperation.smv)

    const allocatable_quantity = Math.min(parseInt(quantity_possible + ""), part.quantity)
    const duration = get_duration(allocatable_quantity, demandOperation.smv, bucket.efficiency, bucket.carder)
    const end_time = new Date(time.valueOf() + duration)

    const alloc = {
        demand_operation_id: demandOperation.id,
        plan_board_line_id: bucket.line_id,
        deleted: false,
        end_time,
        start_time: time,
        completed_quantity: 0,
        configuration: demandOperation.configuration,
        id: getUuid(),
        is_fixed: false,
        is_freezed: part.is_freezed || false,
        is_tools_availabile: true,
        quantity: allocatable_quantity,
        total_smv: 0,
        line_bucket_id: bucket.id
    }
    allocations.push(alloc)

    if (!hasEnoughSwitchingTimeInNextBucket) {
        const configSwitches=create_config_change(plan, bucket,nextBucket, end_time, linePart, demandOperation.configuration, linePart.end_config)
        allocations.push(...configSwitches[0])
    }
    return allocations
}

export const get_duration = (quantity: number, smv: number, efficiency: number, carder: number) => {
    return 3600 * 1000 * quantity * smv / (efficiency * carder)
}

function shouldChangeConfiguration(plan: IPlan, line_id: number, initial_configuration: string, demandOperation: IDemandOperation) {
    const groupId = plan.lines[line_id].plan_board_group_id
    const group = plan.groups[groupId];
    if(initial_configuration === demandOperation.configuration) {
        return false
    }
    if(group.switching_time === 0 && !group.is_switching_time_script){
        return false
    }
    if(group.is_switching_time_script && getSwitchingTime(plan, line_id, initial_configuration, demandOperation.configuration) === 0){
        return false
    }
    return true
}

export function quantity_based_forward_allocator(plan: IPlan, bucket: IBucket, nextBucket: IBucket | undefined, linePart: ILinePart, start_time: Date, initial_configuration: string, part: IDemandOperationPart): IAllocation[] {
    // TODO Implement
    const demandOperation = plan.demand_operations[part.demand_operation_id]
    let time = _.max([bucket.start_time, start_time]) as Date
    let allocations: IAllocation[] = []
    time = _.max([demandOperation.rm_ready_date, time]) as Date
    const line = plan.lines[bucket.line_id]
    const per_day_quantity = line.daily_quantity // TODO Set properly
    const time_per_unit = ((bucket.end_time.valueOf() - bucket.start_time.valueOf()) / per_day_quantity) * demandOperation.quantity_multiplier
    const remain_ratio = (bucket.end_time.valueOf() - time.valueOf())/(bucket.end_time.valueOf() - bucket.start_time.valueOf())

    const remain_ratio_actual = Math.max(0, remain_ratio)
    const quantity_possible = per_day_quantity * remain_ratio_actual / demandOperation.quantity_multiplier
    console.log(quantity_possible, remain_ratio, remain_ratio_actual, time_per_unit)
    const allocatable_quantity = Math.min(parseInt(quantity_possible + ""), part.quantity)
    if(allocatable_quantity == 0){
        return []
    }
    const duration = allocatable_quantity * time_per_unit
    const end_time = new Date(time.valueOf() + duration)
    const alloc = {
        demand_operation_id: demandOperation.id,
        plan_board_line_id: bucket.line_id,
        deleted: false,
        end_time,
        start_time: time,
        completed_quantity: 0,
        configuration: demandOperation.configuration,
        id: getUuid(),
        is_fixed: false,
        is_freezed: false,
        is_tools_availabile: true,
        quantity: allocatable_quantity,
        total_smv: 0,
        line_bucket_id: bucket.id
    }
    allocations.push(alloc)
    return allocations
} 