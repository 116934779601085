import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import { useDataProvider } from "react-admin";
import Popup from "./poupupComponent";

export const BulKOrderResolver = ({ selectedIds, disabled }) => {
  const dataProvider = useDataProvider();

  const [open, setOpen] = useState(false);
  const [ordersData, setOrdersData] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button
          title="Click here to resolve selected orders"
          onClick={() => setOpen(true)}
          variant="contained"
          className="btn_ btn_primary"
          // color="primary"
          disabled={disabled}
        >
          Resolve selected Orders
        </Button>
      </div>
      <div>
        {open && (
          <Popup isOpen={open} onClose={onClose} selectedIds={selectedIds} />
        )}
      </div>
    </>
  );
};
