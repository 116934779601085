import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Typography } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import Table from './Table';
import { useSelector } from 'react-redux';
import { getSupplyChainData } from './reducer/selectors';
import _ from "lodash"
import { TimeBucket } from './interfaces';
import { getConsumptionForTimeBucket } from './lib';

type ExportMaterialProps = {
    selectedItems: string[]
}

export default function ExportMaterial({ selectedItems }: ExportMaterialProps) {


    const [isOpen, setOpen] = useState(false)
    const onClose = () => setOpen(false)
    const onOpen = () => setOpen(true)

    // const [selectedItems, setSelectedItems] = useState<string[]>([])
    // const data = useSelector(getSupplyChainData)

    // const relatedItems = useMemo(() => _.uniq(data.routings.filter(r => r.operation_id == operationId).map(r => r.item_id)), [operationId, data])
    // useEffect(() => {
    //     setSelectedItems(relatedItems)
    // }, [relatedItems])

    // const handleChange = (item: string) => {
    //     if (selectedItems.indexOf(item) >= 0) {
    //         setSelectedItems(selectedItems.filter(v => v != item))
    //     } else {
    //         setSelectedItems([...selectedItems, item])
    //     }
    // }



    return <>
        <Button disabled={selectedItems.length == 0} onClick={onOpen} size="small" variant="contained" color="primary" >Material Requirement </Button>
        <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
            <DialogActions>
                <Button onClick={onClose} style={{ float: 'right' }} variant="text"><CloseIcon /></Button>
            </DialogActions>
            <DialogContent style={{ overflowX: 'scroll' }}>
                Selected Items:
                    {selectedItems.join(", ")}
                <br />
                {/* {relatedItems.map(item => {
                    return <div key={item}><label>
                        <input
                            type="checkbox"
                            checked={selectedItems.indexOf(item) >= 0}
                            onChange={() => handleChange(item)}
                        />
                        <span> {item} </span></label><br /></div>
                })} */}
                {isOpen && <MaterialTable selectedItems={selectedItems} /> }
            </DialogContent>
        </Dialog>
    </>
}


const MaterialTable = ({ selectedItems }: ExportMaterialProps) => {

    const headers = ["time_slot", "item", "requirement"]
    const data = useSelector(getSupplyChainData)

    const rows = useMemo(() => {
        const cartesian =
            (...a: any[]) => a.reduce((a, b) => a.flatMap((d: any) => b.map((e: any) => [d, e].flat())));

        const prepareRecord = (timeBucket: TimeBucket, itemId: string) => ({ time_slot: timeBucket.label, item: itemId, requirement: _.round(getConsumptionForTimeBucket(data, timeBucket.id, itemId), 2) })

        return cartesian(selectedItems, data.time_buckets)
            .map((row: any) => prepareRecord(row[1], row[0]))
    }, [data, selectedItems])

    return <Table headers={headers} data={rows} />

}