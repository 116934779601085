
import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useFilters, usePagination } from 'react-table';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select"
import ReactToExcel from "react-html-table-to-excel";
import { Button } from "@material-ui/core";
import { excelExport } from "./ExcelExport";
import { 
    ColumnFilter,
    ColumnLineHeadFilter,
    ColumnInefficiencyFilter,
    ColumnInefficiencyReasonFilter,
    DateColumnFilter
} from "./ColumnFilter";
import './table.css';
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import BTable from 'react-bootstrap/Table';
// import FormControl from '@mui/material/FormControl';


const useStyles = makeStyles((theme) => ({
    content: {
       maxWidth:'1800px',
       overflow:'scroll',
       paddingTop:10
    },
}));


export default function TableView({
    data,
    selectors,
    allPlants,
    selectedPlant,
    setSelectedPlant,
    selectPlants
  }){

    const ColumnWorkstationFilter = ({ column }) => {
        const { filterValue, setFilter } = column
        return (
            <select className="form-select form-select-lg mb-3"
                onChange={(e) => {
                    const val = e.target.value
                    if (val == 'ALL') {
                        setFilter(null)
                    } else {
                        setFilter(val)
                    }
                }}
                style={{ width: "100%" }}
                value={filterValue}
            >
            <option value="ALL">Show All</option>
            {
                selectors.selectWorkstation.map( k => {
                return <option key={k.toString()} value={k}>{k}</option>
                })
            }
            
          </select>
        )
    }




    const GROUPED_COLUMNS = [
      {
        Header: " ",
        Footer: " ",
        columns: [
          {
            Header: "EPF No",
            Footer: "EPF No",
            accessor: "epf_no",
            
            Filter: ColumnFilter,
          
            // border:' 1px solid #555',
          },
          {
            Header: "User",
            Footer: "User",
            accessor: "user",
            Filter: ColumnFilter,
            disableFilters: true,
          },
          {
            Header: "Workstation",
            Footer: "Workstation",
            accessor: "workstation",
            Cell: ({ value }) => {
              return value;
            },
            Filter: ColumnWorkstationFilter,
            maxWidth: 400,
            minWidth: 120,
            width: 100
          },
          {
            Header: "Line/Head",
            Footer: "Line/Head",
            accessor: "line_or_head",
            Cell: ({ value }) => {
              return value;
            },
            Filter: ColumnLineHeadFilter,
            disableFilters: true,
          },
          {
            Header: "Shift Date",
            Footer: "Shift Date",
            accessor: "shift_date",
            Filter: DateColumnFilter,
            maxWidth: 400,
            minWidth: 140,
            width: 100
          },
          {
            Header: "Shift",
            Footer: "Shift",
            accessor: "shift",
            Filter: ColumnFilter,
            disableFilters: true,
          },
          {
            Header: "Input",
            Footer: "Input",
            accessor: "input",
            Filter: ColumnFilter,
            disableFilters: true,
          },
        ],
      },
      {
        Header: "Output",
        Footer: "Output",
        columns: [
          {
            Header: "Marked",
            Footer: "Marked",
            accessor: "marked",
            Filter: ColumnFilter,
            disableFilters: true,
          },
          {
            Header: "B-Grade Qty",
            Footer: "B-Grade Qty",
            accessor: "b_qty",
            Filter: ColumnFilter,
            disableFilters: true,
          },
          {
            Header: "B-Grade Reasons",
            Footer: "B-Grade Reasons",
            accessor: "b_reason",
            Filter: ColumnFilter,
            disableFilters: true,
          },
          {
            Header: "D-Grade Qty",
            Footer: "D-Grade Qty",
            accessor: "d_qty",
            Filter: ColumnFilter,
            disableFilters: true,
          },
          {
            Header: "D-Grade Reasons",
            Footer: "D-Grade Reasons",
            accessor: "d_reason",
            Filter: ColumnFilter,
            disableFilters: true,
          },
          {
            Header: "NC",
            Footer: "NC",
            accessor: "nc",
            Filter: ColumnFilter,
            disableFilters: true,
          },
        ],
      },
      {
        Header: " ",
        Footer: " ",
        columns: [
          {
            Header: "Efficiency (%)",
            Footer: "Efficiency (%)",
            accessor: "efficiency",
            Filter: ColumnFilter,
            disableFilters: true,
          },
          {
            Header: "Inefficiency Reasons",
            Footer: "Inefficiency Reasons",
            accessor: "inefficiency_reason",
            Cell: ({ value }) => {
              return value
                ? value.map((v) => {
                    return <p>{v}</p>;
                  })
                : "";
            },
            Filter: ColumnInefficiencyReasonFilter,
            disableFilters: true,
          },
          {
            Header: "Occurred Between",
            Footer: "Occurred Between",
            accessor: "occurred_between",
            Cell: ({ value }) => {
              return value
                ? value.map((v) => {
                    return (
                      <p>
                        {v.from} - {v.to}
                      </p>
                    );
                  })
                : "";
            },
            Filter: ColumnFilter,
            disableFilters: true,
          },
          {
            Header: "Demand IDs",
            Footer: "Demand IDs",
            accessor: "demands",
            Filter: ColumnFilter,
            disableFilters: true,
          },
        ],
      },
    ];
    
    const columns = useMemo(() => GROUPED_COLUMNS, [])

    const { 
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        prepareRow 
    } = useTable(
        {
            columns: columns,
            data: data
        },
        useFilters,
        usePagination
    )
    const { pageIndex, pageSize } = state

    
    const tablePallet = {
      margin: "20px",
      marginTop: "10px",
        overflow: "auto",
        width: "98%",
        height: "85vh"
    }
    //console.log("all plants: ", allPlants)
    const excelColHeaders = [];
    columns.map((item) => {
      Array.prototype.push.apply(excelColHeaders, item.columns);
    });

    return (
        <>
            {selectPlants ? <div style={{float: 'left', marginLeft: 25, marginTop: 15, marginBottom:15}}>
               <div className="font"> Select Plant :
               <Select
                    labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    value={selectedPlant}
                    label="Age"
                    onChange={(e) => setSelectedPlant(e.target.value)}
                    style={{marginLeft: 5}}
                >
                    <MenuItem value='ALL'>All Plants</MenuItem>
                    {allPlants?.map((p) => <MenuItem value={p.code}>{p.code}</MenuItem>)}
                </Select>
               
               </div>
               
                
            </div> : <></>}
            {/* <ReactToExcel
                className="export-button"
                table="table-export"
                filename="Efficiency Report"
                sheet="Sheet 1"
                buttonText="EXPORT"
        /> */}
        <div style={{}}>
          <Button
            className="btn_ btn_primary float-right mt-2 mr-3"
            onClick={() => excelExport(excelColHeaders, data)}
            disabled={data?.length===0}
          >
            Export
          </Button>
        </div>

            <br />
            <br />
            <div >

               
            <BTable striped bordered hover size="sm" {...getTableProps()}>
                    <thead>
                        
                        {
                            headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                                    {
                                        headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps({
                                              style: { minWidth:column.minWidth, width: column.width },
                                          })} className="th"> 
                                            
                                                {column.render('Header')}
                                                
                                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                                            </th>
                                        ))
                                    } 
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}  className="body">
                        {
                            page.map((row) =>{
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}  className="tr">
                                        {
                                            row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                                            })
                                            
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                
                </BTable>
                {/* <div id='bottom-panel'>
                    <span className='bottom-element'>
                        Page{' '}
                        <strong>
                            {pageIndex +1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span className='bottom-element'>
                        | Go to page: {' '}
                        <input className='bottom-element' type='number' defaultValue={pageIndex + 1}
                        onChange={e =>{
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(pageNumber)
                        }}
                        style={{ width: '50px' }}
                        />
                    </span>
                    <select className='bottom-element' value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                        {
                            [10, 25, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))
                        }
                    </select>
                    <button className='button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                    <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
                    <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
                    <button className='button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                </div> */}
            </div>
            
        </>
        

    )    
}

