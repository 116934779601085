import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import DetailsView from "./DetailsView";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3.5),
  },
  papersmall: {
    height: theme.spacing(12),
    width: "23%",
    marginInline: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: theme.spacing(2.5),
  },
  headingsmall: {
    fontSize: "small",
    fontWeight: "bold",
    color: "#454545",
  },
  headingsmallgrey: {
    fontSize: "small",
    fontWeight: "bold",
    color: "#C0C0C0",
    marginBottom: theme.spacing(1),
  },
  paperlarge: {
    width: "100%",
    borderRadius: theme.spacing(2.5),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  papermedium: {
    width: "48%",
    marginRight: "10px",
    borderRadius: theme.spacing(2.5),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export default function PaperComponent({ payloard }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {payloard.length > 0 &&
          payloard.map((data, i) => (
            <Paper className={classes.papermedium} key={i}>
              <DetailsView data={data} />
            </Paper>
          ))}
      </div>
    </div>
  );
}
