export const arrangeResponse = (arr: any[]) => {
  const task: any[] = [];
  arr.forEach((t) => {
    if (t.task_code == "O-FG" || t.task_code == "I-FG") {
      t.assignee = localStorage.getItem("username");
    }
    task.push({
      taskId: t.id,
      taskName: t.task_name,
      dueStartDatetime: t.due_start_datetime,
      dueCompleteDatetime: t.due_complete_datetime,
      assignee: t.assignee,
      dept: t.tna_department,
    });
  });
  return task;
};
