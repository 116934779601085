import { stringify } from "query-string";
import { fetchUtils } from "react-admin";
import { API_URL } from "../constants";
import { saveAs } from "file-saver";
import { overrideDefaultErrorMsg } from "../lib/overrideDefaultErrorMsg";

const fetchJson = (url, options = {}) => {
  const fullUrl = `${API_URL}/${url}`;
  if (options.body) {
    if (typeof options.body === "object") {
      options.body = JSON.stringify(options.body);
    }
  }

  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `JWT ${token}`);
  options.headers.set("Accept", "application/json, */*");

  //this function responsibale for throw custom error msg

  return fetchUtils
    .fetchJson(fullUrl, options)
    .catch((e) => overrideDefaultErrorMsg(e));
};

const getResourceIfTNA = (resource) => {
  if (resource?.includes("tna_")) {
    let [prefix, action] = resource.split("_");
    resource = `${prefix}/${action}`;
    return resource;
  }
  return resource;
};

const getListFromResponse = ({ headers = "", json = "" }) => {
  if ("count" in json) {
    // return { data: camelCaseKeys(json.results, { deep: true }), total: json.count }
    return { data: json.results, total: json.count };
  }
  if (headers.has("content-range")) {
    return {
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    };
  }
  if ("detail" in json && json.detail === "Invalid page.") {
    return { data: [], total: 0 };
  }
  throw new Error(
    'The total number of results is unknown. The DRF data provider expects responses for lists of resources to contain this information to build the pagination. If you\'re not using the default PageNumberPagination class, please include this information using the Content-Range header OR a "count" key inside the response.'
  );
};

/**
 * Maps react-admin queries to the default format of Django REST Framework
 */
const httpClient = fetchJson;
export default {
  getList: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const options = {};
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { filter } = params;
    const query = {
      page,
      page_size: perPage,
      ordering: `${order === "ASC" ? "" : "-"}${field}`,
      ...filter,
    };
    const url = `${resource}/?${stringify(query)}`;
    return fetchJson(url, options).then((response) =>
      getListFromResponse(response)
    );
  },
  getOne: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const options = {};
    const url = `${resource}/${params.id}/`;
    return fetchJson(url, options).then((response) => {
      if (response && response.json) {
        return { data: response.json };
      }
      return { data: response };
    });
  },

  getMany: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const options = { method: "GET" };
    return Promise.all(
      params.ids.map((id) => fetchJson(`${resource}/${id}/`, options))
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  },
  getManyReference: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { filter, target, id } = params;
    const query = {
      page,
      page_size: perPage,
      ordering: `${order === "ASC" ? "" : "-"}${field}`,
      ...filter,
      [target]: id,
    };
    const url = `${resource}/?${stringify(query)}`;
    const options = {};
    return fetchJson(url, options).then((response) =>
      getListFromResponse(response)
    );
  },
  create: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const url = `${resource}/`;
    const options = {
      method: "POST",
      body: params.data,
    };

    return fetchJson(url, options).then((response) => {
      // TODO review whether we need to update all data or just the ID
      //  return { data: camelCaseKeys(response.json, { deep: true }) }
      return { data: { ...params.data, id: response.json.id } };
    });
  },
  update: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const url = `${resource}/${params.id}/`;
    const options = {
      method: "PUT",
      body: params.data,
    };
    return fetchJson(url, options).then((response) => {
      return { data: response.json };
    });
  },

  updateMany: (resource, params) => {
    resource = getResourceIfTNA(resource);
    return Promise.all(
      params.ids.map((id) =>
        fetchJson(`${resource}/${id}`, {
          method: "PUT",
          body: params.data,
        })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  },

  delete: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const url = `${resource}/${params.id}/`;
    const options = {
      method: "DELETE",
    };
    return fetchJson(url, options)
      .then(() => {
        // todo should this really be like this? or like the default
        //  return { data: response.json }
        return { data: params.previousData };
      })
      .catch((response) => console.log(response));
  },
  deleteMany: (resource, params) => {
    resource = getResourceIfTNA(resource);
    // TODO can we check whether the viewsets need this customisation?
    //  Perhaps we can make a single query like the example in
    //  https://github.com/marmelab/react-admin/blob/v3.0.0-beta.0/docs/DataProviders.md
    const options = {};
    return Promise.all(
      params.ids.map((id) =>
        fetchJson(`${resource}/${id}/`, { method: "DELETE" })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  },

  //////////////////////////////////////////////////////////////////////////////////////
  // CUSTOM METHODS

  GET_USER: (params) => {
    const { method = "GET" } = params;
    const options = {
      method,
      body: JSON.stringify(),
    };
    const url = `userProfile`;
    return fetchJson(`${url}/`, options).then((response) => {
      return { data: response && response.json };
    });
  },

  GET_PLANBOARDS: () => {
    const method = "GET";
    const options = {
      method,
      body: JSON.stringify(),
    };
    const url = `planBoards/`;
    return fetchJson(url, options).then((response) => {
      return { data: response && response.json };
    });
  },

  GET_PLANBOARD: (params) => {
    const { method = "GET" } = params;
    const options = {
      method,
      body: JSON.stringify(),
    };
    const url = `planBoards`;
    return fetchJson(`${url}/${params}`, options).then((response) => {
      return { data: response && response.json };
    });
  },

  CUSTOM: (resource, params) => {
    resource = getResourceIfTNA(resource);

    const { id, action, method = "GET", body, query } = params;
    const queryString = query ? `?${stringify(query)}` : "";

    return fetchJson(`${resource}/${id}/${action}/${queryString}`, {
      method,
      body: JSON.stringify(body),
    }).then((resp) => {
      return { data: resp && resp.json };
    });
  },
  AGGREGATE: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const { action, method = "GET" } = params;
    //endpoint.agg/?aggregate[Count]=(field to count)
    return httpClient(`${resource}.agg/?${action}`, {
      method,
      body: JSON.stringify(),
    }).then((resp) => resp.json);
  },

  UPDATE_PASSWORD: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const options = {
      method: "PUT",
      body: params.data,
    };
    const url = `${resource}/`;
    return fetchJson(url, options).then((response) => {
      return { data: response.json };
    });
  },

  RESOLVE_ALL_ORDERS: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const { method = "PUT" } = params;
    return httpClient(`${resource}/resolve_all/`, {
      method,
    }).then((resp) => {
      return { data: resp.json };
    });
  },

  GET_DEMAND: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const { action, method = "GET", body } = params;
    return httpClient(`${resource}/?${action}`, {
      method,
      body: JSON.stringify(body),
    }).then((resp) => {
      return { data: resp.json };
    });
  },
  CUSTOM_LIST: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const { action, method = "GET", body, query } = params;
    const queryString = query ? `?${stringify(query)}` : "";
    let url = "";
    if (action) {
      url = `${resource}/${action}/${queryString}`;
    } else {
      url = `${resource}/${queryString}`;
    }
    return httpClient(url, {
      method,
      body: JSON.stringify(body),
    }).then((resp) => {
      return { data: resp.json };
    });
  },
  CUSTOM_UPDATE: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const { action, method = "POST", body } = params;
    return httpClient(`${resource}/${action}`, {
      method,
      body: JSON.stringify(body),
    }).then((resp) => {
      return { data: resp.json };
    });
  },

  TRANSFER_DEMAND_OP: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const { method = "POST", body } = params;
    return httpClient(`${resource}/`, {
      method,
      body: { demand_operations: body },
    }).then((resp) => {
      return { data: resp.json };
    });
  },

  CUSTOM_ACTION: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const { method = "POST", body, action } = params;
    return httpClient(`${resource}/1/${action}/`, {
      method,
      body: { data: body },
    }).then((resp) => {
      return { data: resp.json };
    });
  },
  BLOB_DOWNLOAD: (resource, params) => {
    const { action, body, fileName, fileExt } = params;

    const fullUrl = `${API_URL}/${resource}/${action}/`;
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open("POST", fullUrl, true);
      xhr.setRequestHeader("Content-Type",  "application/json");

      const token = localStorage.getItem("token");
      xhr.setRequestHeader("Authorization", `JWT ${token}`);
      xhr.setRequestHeader("Accept", "application/json, */*");

      xhr.responseType = "blob";
      xhr.onload = function (e) {
        if (this.status >= 200 && this.status < 300) {
          var blob = e.currentTarget.response;
          saveAs(blob, fileExt ? fileName + fileExt : fileName);
          resolve({ data: this.status });
        } else {
          reject(this.status);
        }
      };
      xhr.onerror = function () {
        reject(this.status);
      };
      xhr.send(JSON.stringify(body));
    });
  },

  TNA_CUSTOM_LIST: (resource, params) => {
    const { action, method = "GET", body, query, actions } = params;
    const queryString = query ? `?${stringify(query)}` : "";
    let url = "";
    if (action) {
      url = `tna/${resource}/${action}/${queryString}`;
    } else if (actions) {
      url = `tna/${resource}/${actions}/${queryString}`;
    } else {
      url = `tna/${resource}/${queryString}`;
    }

    return httpClient(url).then((resp) => {
      return { data: resp.json };
    });
  },

  TNA_CUSTOM_ACTION: (resource, params) => {
    const { method = "POST", body, action } = params;
    return httpClient(`tna/${resource}${action ? `/` + action : ''}/`, {
      method,
      body: body,
    }).then((resp) => {
      if (resp !== undefined) {
        return { data: resp.json };
      }
    });
  },

  TNA_FORM_ACTION: (resource, params) => {
    const { method = "POST", body, action } = params;
    const url = `tna/${resource}/${action}/`;
    const fullUrl = `${API_URL}/${url}`;
    const options = { body: body, method: method };
    // options.body=body

    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }

    const token = localStorage.getItem("token");
    options.headers.set("Authorization", `JWT ${token}`);
    options.headers.set("Accept", "application/json, */*");

    return fetchUtils.fetchJson(fullUrl, options).then((resp) => {
      return { data: resp };
    });
  },
  //  for partial update use this instead using update
  put: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const url = `${resource}/${params.id}/`;
    const options = {
      method: "PATCH",
      body: params.data,
    };
    return fetchJson(url, options).then((response) => {
      return { data: response.json };
    });
  },

  GET_EFFICIENCY: (params) => {
    const { method = "GET" } = params;
    const options = {
      method,
      body: JSON.stringify(),
    };
    const url = `efficiency`;
    return fetchJson(`${url}/`, options).then((response) => {
      return { data: response && response.json };
    });
  },
  // CUSTOM ACTION for when can't use default CUSTOM LIST

  TIMESTAMP_CUSTOM_LIST: (resource, params) => {
    resource = getResourceIfTNA(resource);
    const { actions, method = "GET", body, query } = params;
    const queryString = query ? `?${stringify(query)}` : "";
    return httpClient(`${resource}/${actions}/${queryString}`, {
      method,
      body: JSON.stringify(body),
    }).then((resp) => {
      return { data: resp.json };
    });
  },

  CUSTOM_ACTION_TNA: (resource, params) => {
    console.log("run");
    const { method = "POST", body, actions } = params;
    console.log(params);
    return httpClient(`tna/${resource}/1/${actions}/`, {
      method,
      body: body,
    }).then((resp) => {
      console.log(resp);
      if (resp != undefined) {
        return { data: resp.json };
      }
    });
  },

  CUSTOM_ACTION_TNA_SP: (resource, params) => {
    // console.log("run");
    const { method = "POST", body, actions } = params;
    console.log(params);
    return httpClient(`tna/${resource}/${actions}/`, {
      method,
      body: body,
    }).then((resp) => {
      console.log(resp);
      if (resp != undefined) {
        return { data: resp.json };
      }
    });
  },
};
