import { combineReducers } from "redux";
import { bulkActionReducer } from "./bulkActionReducer";
import { holidayReducer } from "./holidayReducer";
import { notificationReducer } from "./notifycationReducer";
import { tnaNotificationReducer } from "./tnaNotificationReducer";
import { planBoardReducer } from "./planBoardReducer";
import { planReducer } from "./planReducer";
import { transferReducer } from "./transferReducer";
import { tnaTaskReducer } from "./tnaTaskReducer";
import { supplyChainReducer } from "../../containers/supply_chain/reducer/reducer";
import { templateReducer } from "./templateReducer";
import { operationLossReducer } from "./operationLossReducer";
import { tnaHolidayReducer } from "./tnaHolidayReducer"

export const rootReducer = combineReducers({
  planBoard: planBoardReducer,
  plan: planReducer,
  holidays: holidayReducer,
  operationLosses: operationLossReducer,
  transfers: transferReducer,
  notifications: notificationReducer,
  bulkActions: bulkActionReducer,
  tnaTasks: tnaTaskReducer,
  tnaNotifications: tnaNotificationReducer,
  supplyChain: supplyChainReducer,
  template: templateReducer,
  tnaHoliday: tnaHolidayReducer
});

export type RootReducerType = ReturnType<typeof rootReducer>;
