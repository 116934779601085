import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";
import { redirect } from "../../lib/redirect";

const validate = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = "Code is required";
  }
  return errors;
};

export const CreatePermission = (props) => {
  const customToast = useCustomToast({redirect: redirect});
  return (
    <Create
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      {...props}
      class={"col-md-6 mt-5"}
    >
      <SimpleForm
        validate={validate}
        toolbar={
          <CustomToolbar need={false} name="permissions" text="Permission" />
        }
      >
        {/* <TextInput source="name" /> */}
        <TextInput source="code" />
        <ReferenceInput
          label="parent permissions"
          source="parent"
          reference="permissions"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
