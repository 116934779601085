import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  Datagrid, EditButton,
  // CreateButton,
  ExportButton, List, sanitizeListRestProps, TextField, TopToolbar,


  useListContext,

  usePermissions
} from "react-admin";
import CreateButton from "../../components/CustomCreateButton";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const Actions = (props) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar
      className={classes.buttonContainer}
      {...sanitizeListRestProps(rest)}
    >
      <CreateButton
        basePath={basePath}
        title="permissions"
        className="btn-secondry"
        disabled={!permissions?.permissions.includes("workstations_create")}

        // title='Click here to create plant' disabled={!permissions?.permissions.includes("plants_create")}
      />
      <ExportButton
        className="btn_ btn_primary"
        title="Click here to export plant"
        disabled={!permissions?.permissions.includes("workstations_export")}
      />
    </TopToolbar>
  );
};

export const OperationWorkstations = (props) => (
  <List {...props} actions={<Actions />} bulkActionButton={false} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="operation" />
      <TextField source="workstation" />
      <EditButton label="Edit" className="btn_ btn_edit" />
    </Datagrid>
  </List>
);
