import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import React from "react";
import { Link } from "react-admin";
import { format_to_date_for_api } from "../../../lib/date_time";
import Upload from "./Upload";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    minWidth: 275,
    marginTop: 70,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  conatiner: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
});


const rows = [];

const ForcastButtonView = () => {
  const classes = useStyles();
  const [date, setDates] = React.useState([
    format_to_date_for_api(),
    format_to_date_for_api(moment().add(7, "days")),
  ]);
  return (
    <>
      <div className={classes.conatiner}>
        <Upload title="Upload" style={{ marginLeft: "100px" }} />
        <DateRangePicker
          onChange={(date) => {
            setDates([
              date
                ? format_to_date_for_api(date[0])
                : format_to_date_for_api(),
              date
                ? format_to_date_for_api(date[1])
                : format_to_date_for_api(moment().add(7, "days")),
            ]);
          }}
          value={[new Date(date[0]), new Date(date[1])]}
        // style={{ marginTop: "10px" }}
        />

        <Button
          size="small"
          // style={{ justifyContent: "flexEnd" }}
          component={Link}
          to={{
            pathname: "/addNewRm",
          }}
          variant="contained"
          color="primary"
        >
          Add Reason
        </Button>
      </div>

      <Card className={classes.root} variant="outlined">
        <CardContent>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell align="right">Item</TableCell>
                  <TableCell align="right">QTY</TableCell>
                  <TableCell align="right">RM Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.calories}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 100 }}
            size="small"
          >
            Save changes
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default ForcastButtonView;
