import React from "react";
import img from "../../layout/img/ap.svg";

export const ActtionPoupBottemConerImg = () => {
  return (
    <div>
      <img src={img} alt="action popup img" height="250px" width="250px" />
    </div>
  );
};
