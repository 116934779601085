import { AppBar, Box, Button, MenuItem, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import React, { useState } from 'react';
import { Error, Loading, Mutation, Query, useRefresh } from 'react-admin';
import { theme as customTheme } from '../../layout/theme';
import ForcastButtonView from './forcastButtonView/index';
import { CustomEnhancedTable } from './table';

const useStyles = makeStyles((theme) => ({
    formfield: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    },
    formHorizontalRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        '& .MuiTextField-root': {
            margin: theme.spacing(1)
        },
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    },
    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    invalidTextTitle: {
        color: customTheme.palette.warning.main,
        fontSize: 36,
        textAlign: 'center'
    },
    resubmitText: {
        color: customTheme.palette.primary.main,
        fontSize: 12,
        textAlign: 'center'
    }
}));


export const AddRMForm = ({ closeAction, plant = null, selectedIds }) => {
    const [formData, setFormData] = React.useState([{ item: '', quantity: '', date: '', location: '' }])
    const [confirmState, setConfirmState] = React.useState([[0, 0, 0, 0]])
    const [selectedItems, setSelectedItems] = React.useState()
    const [itemsFetched, setItemsFetched] = React.useState(false)
    const handleChange = (props) => (event) => {
        let tempFormData = formData;
        tempFormData[props.id][props.key] = event.target.value
        setFormData(tempFormData)
        setConfirmState((state) => {
            const ind = ['rm_item', 'location', 'date', 'quantity'].indexOf(props.key)
            if (state[props.id][ind] === 0) {
                state[props.id][ind] += 1
            }
            return state
        })
    }
    const payload = {
        pagination: { page: 1, perPage: 100000 },
        sort: { field: 'username', order: 'ASC' }
    }
    const classes = useStyles()
    const queriedData = []
    const refresh = useRefresh()

    React.useMemo(() => {
        if (selectedItems) {
            const itemData = []
            const tempConfirmState = []
            selectedItems.map((item) => {
                itemData.push({
                    rm_item: item.id,
                    location: item.location,
                    quantity: 0,
                    date: ''
                })
                tempConfirmState.push([1,1,0,0])
            })
            setFormData(itemData)
            setConfirmState(tempConfirmState)
        }
    },[selectedItems])

    React.useEffect(() => {
        if (queriedData['item_data']?.length > 0 && itemsFetched && selectedIds) {
            let data = queriedData['item_data']
            setSelectedItems(data.filter((item) => selectedIds.includes(item.id)))
        }
    },[itemsFetched])

    return (
        <Query
            type='getList'
            resource='materialInventory'
            payload={plant === null ? payload : { ...payload, filter: { plant: plant } }}
        >
            {({ data, total, loading, error }) => {
                if (loading) { return <Loading />; }
                if (error) { return <Error />; }

                queriedData['item_data'] = data
                setItemsFetched(true)
                return (
                    <Query
                        type="getList"
                        resource="materialStore"
                        payload={payload}
                    >
                        {({ data, total, loading, error }) => {
                            if (loading) { return <Loading /> }
                            if (error) { return <Error /> }

                            queriedData['location_data'] = data
                            return (
                                <div>
                                    {selectedItems?.length > 0 ? 
                                    selectedItems?.map((item, id) => (
                                            <div style={{color: customTheme.palette.primary.main, fontSize: 14}}>
                                                <div className={classes.formHorizontalRow}>
                                                    <TextField
                                                        label={`Item ` + parseInt(id+1)}
                                                        value={item.item_code}
                                                        contentEditable={false}
                                                        variant='outlined'
                                                        style={{width: 100}}
                                                    />
                                                    <TextField
                                                        label='Quantity'
                                                        type='number'
                                                        value={item.quantity}
                                                        onChange={handleChange({key: 'quantity', id: id})}
                                                        variant='outlined'
                                                        style={{width: 100}}
                                                    />
                                                </div>
                                                <div className={classes.formHorizontalRow}>
                                                    <TextField
                                                        label='Location'
                                                        value={item.location}
                                                        contentEditable={false}
                                                        variant='outlined'
                                                        style={{width: 60}}
                                                    />
                                                    <TextField
                                                        type='datetime-local'
                                                        label='Date'
                                                        value={item.need_date}
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={handleChange({key: 'date', id: id})}
                                                        variant='outlined'
                                                        style={{width: 140}}
                                                    />
                                                </div>
                                                <Divider />
                                            </div>
                                        )
                                    ) :
                                    <div>
                                        <div className={classes.formfield}>
                                            <TextField
                                                select
                                                label='Item'
                                                value={formData.rm_item}
                                                onChange={handleChange({key: 'rm_item', id: 0})}
                                                variant='outlined'
                                            >
                                                {queriedData['item_data'].map((obj, ind) => (
                                                    <MenuItem value={obj.item_code}>{obj.item_code}</MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className={classes.formfield}>
                                            <TextField
                                                select
                                                label='Location'
                                                value={formData.location}
                                                onChange={handleChange({key: 'location', id: 0})}
                                                variant='outlined'
                                            >
                                                {queriedData['location_data'].map((obj, ind) => (
                                                    <MenuItem value={obj.id}>{obj.code}</MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className={classes.formfield} >
                                            <TextField
                                                type='datetime-local'
                                                label='Date'
                                                value={formData.need_date}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleChange({key: 'date', id: 0})}
                                            />
                                        </div>
                                        <div className={classes.formfield} >
                                            <TextField
                                                label='Quantity'
                                                type='number'
                                                value={formData.quantity}
                                                onChange={handleChange({key: 'quantity', id: 0})}
                                            />
                                        </div>
                                    </div>
                                    }
                                    <div className={classes.buttons} >
                                        <Mutation
                                            type="CUSTOM_LIST"
                                            resource="materialInventory"
                                            payload={{
                                                action: "add_rm",
                                                method: "POST",
                                                body: { rm_data: formData }
                                            }}
                                            options={{}}
                                        >
                                            {action => (
                                                <Button
                                                    // disabled={confirmState.includes(0) ? true : false}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        closeAction()
                                                        action();
                                                        refresh();
                                                    }}
                                                >
                                                    {" "}
                                                Confirm
                                                </Button>
                                            )}
                                        </Mutation>
                                        <Button
                                            onClick={closeAction}
                                            variant='contained'
                                            color='primary'
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            )
                        }}
                    </Query>
                )
            }}
        </Query>
    )
}

const InvalidSelection = () => {
    const classes = useStyles();
    return (
        <div className={classes.invalidText}>
            Invalid selection!
            <div className={classes.resubmitText}>
                Please select one item and re-submit
            </div>
        </div>
    )
}


export const RequestRMForm = ({ closeAction, plant = null, selectedIds }) => {
    const [formData, setFormData] = React.useState(
        [{ rm_item: '', location: '', need_date: '', quantity: '' }]
    )
    const [confirmState, setConfirmState] = React.useState([[0, 0, 0, 0]])
    const [selectedItems, setSelectedItems] = React.useState()
    const [itemsFetched, setItemsFetched] = React.useState(false)
    const handleChange = (props) => (event) => {
        let tempFormData = formData;
        tempFormData[props.id][props.key] = event.target.value
        setFormData(tempFormData)
        setConfirmState((state) => {
            const ind = ['rm_item', 'location', 'need_date', 'quantity'].indexOf(props.key)
            if (state[props.id][ind] === 0) {
                state[props.id][ind] += 1
            }
            return state
        })
    }

    const payload = {
        pagination: { page: 1, perPage: 100000 },
        sort: { field: 'username', order: 'ASC' }
    }
    const classes = useStyles()
    const queriedData = []
    const refresh = useRefresh()

    React.useMemo(() => {
        if (selectedItems) {
            const itemData = []
            const tempConfirmState = []
            selectedItems.map((item) => {
                itemData.push({
                    rm_item: item.item_code,
                    location: item.location,
                    quantity: 0,
                    need_date: ''
                })
                tempConfirmState.push([1,1,0,0])
            })
            setFormData(itemData)
            setConfirmState(tempConfirmState)
        }
    },[selectedItems])

    React.useEffect(() => {
        if (queriedData['item_data']?.length > 0 && itemsFetched && selectedIds) {
            let data = queriedData['item_data']
            setSelectedItems(data.filter((item) => selectedIds.includes(item.id)))
        }
    },[itemsFetched])


    return (
        <Query
            type='getList'
            resource='materialInventory'
            payload={plant === null ? payload : { ...payload, filter: { plant: plant } }}
        >
            {({ data, total, loading, error }) => {
                if (loading) { return <Loading /> }
                if (error) { return <Error /> }

                queriedData['item_data'] = data
                setItemsFetched(true)
                return (
                    <Query
                        type="getList"
                        resource="materialStore"
                        payload={payload}
                    >
                        {({ data, total, loading, error }) => {
                            if (loading) { return <Loading /> }
                            if (error) { return <Error /> }

                            queriedData['location_data'] = data
                            return (
                                <div>
                                    {selectedItems?.length > 0 ? 
                                    selectedItems?.map((item, id) => (
                                            <div style={{color: customTheme.palette.primary.main, fontSize: 14}}>
                                                <div className={classes.formHorizontalRow}>
                                                    <TextField
                                                        label={`Item ` + parseInt(id+1)}
                                                        value={item.item_code}
                                                        contentEditable={false}
                                                        variant='outlined'
                                                        style={{width: 100}}
                                                    />
                                                    <TextField
                                                        label='Quantity'
                                                        type='number'
                                                        value={item.quantity}
                                                        onChange={handleChange({key: 'quantity', id: id})}
                                                        variant='outlined'
                                                        style={{width: 100}}
                                                    />
                                                </div>
                                                <div className={classes.formHorizontalRow}>
                                                    <TextField
                                                        label='Location'
                                                        value={item.location}
                                                        contentEditable={false}
                                                        variant='outlined'
                                                        style={{width: 60}}
                                                    />
                                                    <TextField
                                                        type='datetime-local'
                                                        label='Date'
                                                        value={item.need_date}
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={handleChange({key: 'need_date', id: id})}
                                                        variant='outlined'
                                                        style={{width: 140}}
                                                    />
                                                </div>
                                                <Divider />
                                            </div>
                                        )
                                    ) :
                                    <div>
                                        <div className={classes.formfield}>
                                            <TextField
                                                select
                                                label='Item'
                                                value={formData.rm_item}
                                                onChange={handleChange({key: 'rm_item', id: 0})}
                                                variant='outlined'
                                            >
                                                {queriedData['item_data'].map((obj, ind) => (
                                                    <MenuItem value={obj.item_code}>{obj.item_code}</MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className={classes.formfield}>
                                            <TextField
                                                select
                                                label='Location'
                                                value={formData.location}
                                                onChange={handleChange({key: 'location', id: 0})}
                                                variant='outlined'
                                            >
                                                {queriedData['location_data'].map((obj, ind) => (
                                                    <MenuItem value={obj.id}>{obj.code}</MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className={classes.formfield} >
                                            <TextField
                                                type='datetime-local'
                                                label='Date'
                                                value={formData.need_date}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleChange({key: 'need_date', id: 0})}
                                            />
                                        </div>
                                        <div className={classes.formfield} >
                                            <TextField
                                                label='Quantity'
                                                type='number'
                                                value={formData.quantity}
                                                onChange={handleChange({key: 'quantity', id: 0})}
                                            />
                                        </div>
                                    </div>
                                    }
                                    <div className={classes.buttons} >
                                        <Mutation
                                            type="CUSTOM_LIST"
                                            resource="rmRequest"
                                            payload={{
                                                action: "new_rm_request",
                                                method: "POST",
                                                body: { request_data: formData, request_type: 'SUPPLY_REQ' }
                                            }}
                                            options={{}}
                                        >
                                            {action => (
                                                <Button
                                                    // disabled={confirmState.flat().includes(1) ? true : false}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        closeAction()
                                                        action();
                                                    }}
                                                >
                                                    {" "}
                                                Confirm
                                                </Button>
                                            )}
                                        </Mutation>
                                        <Button
                                            onClick={closeAction}
                                            variant='contained'
                                            color='primary'
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            )
                        }}
                    </Query>
                )
            }}
        </Query>
    )
}


export const RMRequestsContent = ({ closeAction }) => {
    const TabPanel = ({ children, value, index, ...other }) => {
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={3}>{children}</Box>}
            </Typography>
        );
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Query
            type="CUSTOM_LIST"
            resource="rmRequest"
            payload={{
                action: "get_requests_of_type",
                method: "GET"
            }}
        >
            {({ data, loading, error }) => {
                if (loading) { return <Loading /> }
                if (error) { return <Error /> }

                const { planner_requests, supply_requests, supply_recieved } = data

                return (
                    <div className={classes.root} >
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant='fullWidth' >
                                <Tab label="Requests recieved" {...a11yProps(0)} />
                                <Tab label="Requests sent" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <CustomEnhancedTable
                                recordList={planner_requests}
                                closeAction={closeAction}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <CustomEnhancedTable
                                recordList={supply_requests}
                                closeAction={closeAction}
                            />
                        </TabPanel>
                    </div>
                );
            }}
        </Query>
    )
}


export const ForecastDialogContent = ({ closeAction }) => {

    return (
        <div>
            <ForcastButtonView /> 
        </div>
    )
}
