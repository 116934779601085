import { addBaseUrlIfNotExist } from "./lib/urls";
import { LightenDarkenColor } from "./lib/shadeColors";

const dynamic_env = window._env_ || {}; // This comes from the the includes js file in the index.html. Tutorial: https://www.freecodecamp.org/news/how-to-implement-runtime-environment-variables-with-create-react-app-docker-and-nginx-7f9d42a91d70/
const configuredUrl =
  dynamic_env.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
const configuredGrafanaUrl =
  dynamic_env.REACT_APP_GRAFANA_API_URL ||
  process.env.REACT_APP_GRAFANA_API_URL;
export const API_URL =
  addBaseUrlIfNotExist(configuredUrl) || "http://127.0.0.1:8000";
export const APP_VERSION = process.env.REACT_APP_VERSION || "unknown";
export const PRIMARY_COLOR =
  dynamic_env.REACT_APP_PRIMARY_COLOR || process.env.REACT_APP_PRIMARY_COLOR;
export const SECONDARY_COLOR = dynamic_env.REACT_APP_SECONDARY_COLOR || process.env.REACT_APP_SECONDARY_COLOR;
export const WS_URL = API_URL.replace("http://", "ws://").replace(
  "https://",
  "wss://"
);
export const ADMINS = ["ADMIN", "SITE"];
export const BASIC = ["SITE", "PLANT", "PLANT"];
export const ALL_AUTH_LEVELS = ["ADMIN", "SITE", "SITE", "PLANT", "PLANT"];
export const IS_DEV =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";
export const GRAFANA_URL = configuredGrafanaUrl;

export const MENU_SECTIONS = {
  Dashboard: ["dashboard"],
  Company: [
    "sites",
    "plants",
    "workstations",
    "users",
    "roles",
    "permissions",
    "calendars",
    "subcontracts",
    "settings",
    "tna_calender",
    "operation_loss"
  ],
  "Items and Routings": ["items", "operations","itemStructureUpload"],
  Orders: ["orders", "transfers", "orderGroups", "drillDownView", "demands"],
  Inventory: ["materialInventory"],
  Plan: ["planboards"],
  Execution: ["progress", "floorView"],
  Reports: ["activityLog", "changeLog", "rmTimestamp"],
  Integration: ["integrationRecord"],
  Documentation: ["about"],
  Profile: ["profile"],

  "TNA Admin Panel": [
    "tna_departments",
    "tna_templates",
    "tna_dashboard",
    "tna_tasks",
    "tna_delay_reasons",
  ],
};

export const TNA_MENU_SECTIONS = {  
  TNAAdmin: [
    "tna_departments",
    "tna_templates",
    "tna_dashboard",
    "tna_delay_reasons",
    "tna_calender",
  ],
};

export const PLAN_EXPORT_COLUMNS = {
  SCOM_DATEWISE: [
    "Head",
    "Order No.",
    "Part No.",
    "Compound",
    "Description",
    "Order Qty",
    "Time",
    "dateWiseQty",
    "moldTable",
    "Order Required Date",
  ],
  SCOM: [
    "HEAD",
    "ITEM CODE",
    "BTCHS",
    "LOT BATCH NO.",
    "ALLOC RANGE",
    "TIME/BTCH",
    "TOTAL TIME",
    "DELIVERY",
    "DELAYED QTY.",
    "Order Required Date",
  ],
  DSI: [
    "head",
    "Mold",
    "Order Code",
    "Release number",
    "Item Code",
    "Quantity",
    "Unit",
    "Week No",
    "Start Time",
    "P.S.D.",
    "RM Ready Date",
    "Order Required Date",
    "Need Date(max)",
    "Operation Code",
    "SMV",
    "Total shift quantity",
    "Shifts",
    "Planned O. G. S. D.",
    "Planned O. G. E. D.",
    "P. O. G. S. D. - 1",
    "P. O. G. E. D. + 1",
    "Efficiency",
    "Carder",
    "Shift Time (H)",
  ],
};
