import { demand_operation_allocation_selector, demand_operation_group_allocation_selector, same_config_allocation_selector, single_allocation_selector } from "../../../../lib/plan/allocation_selectors/selectors"
import { demand_operation_group_selector, demand_operation_selector } from "../../../../lib/plan/demand_operation_selectors/selectors"

export const allocationSelectorMap = {
    "allocation": single_allocation_selector,
    "demand_operation": demand_operation_allocation_selector,
    "order_group": demand_operation_group_allocation_selector,
    "same_config": same_config_allocation_selector
}

export const demandOperationSelectorMap = {
    "demand_operation": demand_operation_selector,
    "order_group": demand_operation_group_selector
}