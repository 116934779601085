
import { CREATE_OPERATION_LOSS, LOAD_OPERATION_LOSSES,DELETE_OPERATION_LOSS, LOAD_OPERATION_LOSS_FAILED, LOAD_OPERATION_LOSS_SUCCESS, UPDATE_SHIFT_PATTERN, SET_SHIFT_PATTERNS, CREATE_OPERATION_LOSS_FAILED, CREATE_OPERATION_LOSS_SUCCESS, SET_DATE_RANGE } from "../types/operationLossTypes";


export const loadOperationLosses = (section: any) => {
    return { type: LOAD_OPERATION_LOSSES, payload: { section } }
}

export const setOperationLoss = (operationLoss: any) => ({ type: LOAD_OPERATION_LOSS_SUCCESS, payload: { operationLoss } })


export const deleteOperationLoss = (operationLossId: number, lines: any[], section: any) => ({
    type: DELETE_OPERATION_LOSS, payload: { operationLossId, lines, section }
})

export const setDateRangeOperationLoss = (date_range: any) => ({ type: SET_DATE_RANGE, payload: { date_range } })

export const createOperationLoss = (lines: any, operationLoss: any) => ({
    type: CREATE_OPERATION_LOSS,
    payload: { lines, operationLoss }
})
