import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";
import { redirect } from "../../lib/redirect";

const validate = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = ["Code is required"];
  }
  if (!values.required_date) {
    errors.required_date = ["Date is required"];
  }
  if (!values.quantity) {
    errors.quantity = ["Date is required"];
  }
  return errors;
};
export const orderEdit = (props) => {
  const Title = ({ record }) => {
    return <span>Edit Order | {record ? `${record.code} ` : ""}</span>;
  };
  /* eslint-disable */
  const customToast = useCustomToast({ redirect: redirect });
  /* eslint-enable */
  return (
    <Edit
      className="col-md-6"
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
      {...props}
      title={<Title />}
    >
      <SimpleForm
        validations={validate}
        toolbar={<CustomToolbar need={false} name="orders" text="Order" />}
        redirect={redirect}
      >
        <TextInput source="code" label={<ReqInputField name="Code" />} />
        <TextInput
          source="quantity"
          label={<ReqInputField name="Quantity" />}
        />
        <BooleanInput source="skip_planning" />
        <BooleanInput source="is_dummy" />
        <TextInput source="resolve_failed_reason" />
        <BooleanInput source="resolve_failed" />
        <DateTimeInput
          source="required_date"
          label={<ReqInputField name="Required Date" />}
        />
        <ReferenceInput
          source="final_item"
          reference="items"
          label={<ReqInputField name="Item" />}
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
