import React from 'react'
import imge from './img/AppBarLogo.png'


export const AppBarLogo = React.memo(() => {
    return (
        <div style={{ marginLeft: window.innerWidth*0.1, paddingBottom: 4}}>
            <img src={imge} height='25px' />
        </div>
    )
})