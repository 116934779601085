import React from "react";

import {
  Create,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";
import { calendarAttributesSchema } from "./attributesSchema";

const validate = (values) => {
  const errors = {};
  if (!values.config) {
    errors.config = ["config is required"];
  }

  return errors;
};
export const CreateCalendar = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm validat={validate}>
        <TextInput source="name" />
        <TextInput source="config" schema={calendarAttributesSchema} />
      </SimpleForm>
    </Create>
  );
};
