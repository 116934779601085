import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import ShowButton from "../../components/CustomShowButton";
import CreateButton from "../../components/CustomCreateButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateTimeInput,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  // ShowButton,
  showNotification,
  TextField,
  TextInput,
  useDataProvider,
  usePermissions,
  DateInput,
  CardActions
} from "react-admin";
import {
  TopToolbar,
  // CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
} from "react-admin";
import { cloneElement, useMemo } from "react";
import { connect } from "react-redux";
import Confirmation from "../../components/Confirmation";
import CustomDateField from "../../components/field/CustomDateField";
import { CompletedQuantity } from "../../components/field/ProgressBar";
import "../../layout/styles/input.css";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../components/ChangeDiv";

import EfficiencyDialog from "../Efficiency/EfficiencyDialog";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: 0,
  },
  buttonContainer: {
    display: "flex",
    // width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

// filter that filter plan board
const AllocationFilter = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Filter title="Click here to add filter" {...props}>
        <ReferenceInput source="plan_board_line" reference="boardLine" alwaysOn>
          <TextInput source="code" />
        </ReferenceInput>
        <TextInput label="Search (order code)" source="order_code" alwaysOn />
        <TextInput label="Search (item code)" source="item_code" alwaysOn />
        <TextInput
          label="search (configuration)"
          source="configuration"
          alwaysOn
        />
        <DateInput
          source="start_time"
          label="start date"
          // alwaysOn
        />
        <DateInput
          source="end_time"
          label="end date"
          // alwaysOn
        />

        <TextInput
          label="seach by demand operation"
          source="demand_operation"
        />
        <BooleanInput label="Is fixed" source="is_fixed" />
        <BooleanInput label="Is manual" source="is_manual" />
      </Filter>
    </div>
  );
};
// prop that use change value in   progressbar
const dataProps = (data) => {
  return (data.completed_quantity * 100) / data.quantity;
};
// options that used for mutation
const options = {
  undoable: true,
  onSuccess: {
    notification: { body: "Quantity saved", level: "info" },
  },
  onFailure: {
    notification: { body: "Error: Quantity  not saved", level: "warning" },
  },
};
// custom component
const MakeButtonComponent = ({
  source,
  record = {},
  showNotification,
  disabled,
  title,
}) => {
  const [open, setOpen] = React.useState(false);
  const [quantity, setQuantity] = React.useState(record.completed_quantity);
  const dataProvider = useDataProvider();

  const apicall = async (id) => {
    const payload = {
      id,
      data: {
        completed_quantity: quantity,
        plan_board_line: record.plan_board_line,
        quantity: record.quantity,
        start_time: record.start_time,
        end_time: record.end_time,
        total_smv: record.total_smv,
        line_bucket: record.line_bucket,
      },
    };
    await dataProvider.update("allocations", payload);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        className="btn_ btn_primary"
        variant="contained"
        // size="small"
        onClick={handleClickOpen}
        disabled={disabled}
        title="Click here to Mark"
      >
        Mark
      </Button>

      <Dialog
        fullWidth="true"
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ paddingTop: "10px" }} id="alert-dialog-title">
              {"Completed quantity"}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        <DialogContent>
          <input
            className="password"
            type="text"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
          />
          <DialogContentText>Total : {record.quantity}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              apicall(record.id);
              handleClose();
            }}
            onHeaderClick={handleClose}
            className="btn_ btn_primary"
          >
            Set Completed Quantity
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// custom component for Complete button
const MakeFinishButton = ({
  source,
  record = {},
  showNotification,
  disabled,
  title,
}) => {
  const dataProvider = useDataProvider();

  const apicall = async (id) => {
    const payload = {
      id,
      data: {
        completed_quantity: record.quantity,
        plan_board_line: record.plan_board_line,
        quantity: record.quantity,
        start_time: record.start_time,
        end_time: record.end_time,
        total_smv: record.total_smv,
        line_bucket: record.line_bucket,
      },
    };
    await dataProvider.update("allocations", payload);
  };
  return (
    <>
      <Button
        className="btn_ btn_primary"
        variant="contained"
        onClick={() => apicall(record.id)}
        disabled={disabled}
        title="Click here to complete"
      >
        Complete
      </Button>
    </>
  );
};

export const Allocation = (props) => {
  const Actions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;

    const classes = useStyles();
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
    } = useListContext();

    return (
      <div className={classes.buttonContainer} {...sanitizeListRestProps(rest)}>
        <CardActions>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
            className: "btn_",
          })}


        <EfficiencyDialog data={props} />

        <CreateButton
          basePath={basePath}
          title="Click here to create progress"
          disabled={!permissions?.permissions.includes("allocations_create")}
        />
        <ExportButton
          className="btn_ btn_primary"
          title="Click here to Export Progress"
          disabled={!permissions?.permissions.includes("allocations_export")}
        />
        </CardActions>
      </div>
    );
  };

  const Title = ({ record }) => {
    return <span>Progress | Allocations</span>;
  };

  const { permissions } = usePermissions();
  
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <List
        {...props}
        filters={<AllocationFilter />}
        title={<Title />}
        bulkActionButtons={false}
        actions={<Actions />}
        perPage={25}
        // class="custom-list"
        // className={classes.customList}
      >
        <ChangeDiv>
          {(props) => {
            return (
              <Datagrid {...props}>
                <TextField source="id" />
                <TextField source="demand_operation" />
                <ReferenceField
                  link=""
                  label="Plan Board Line"
                  source="plan_board_line"
                  reference="boardLine"
                >
                  <TextField source="code" />
                </ReferenceField>
                <TextField source="line_bucket" />
                <TextField source="configuration" />
                <TextField source="quantity" />

                <TextField source="b_count" />
                <TextField source="d_count" />
                <TextField source="nc_count" />

                <TextField source="completed_quantity" />
                <CompletedQuantity
                  getQuantity={dataProps}
                  source="completed_quantity"
                />
                <CustomDateField source="start_time" />
                <CustomDateField source="end_time" />
                <TextField source="total_smv" />
                <TextField
                  source="item_code"
                  sortBy="demand_operation__item_operation__produced_item__code"
                />
                <TextField
                  source="order_code"
                  sortBy="demand_operation__produced_demand__order__code"
                />
                <TextField source="plan_board_version" />
                <MakeButton
                  label="Mark"
                  title="permissions"
                  disabled={
                    !permissions?.permissions.includes("allocations_mark")
                  }
                />
                <MakeFinishButton
                  label="Mark Finished"
                  title="permissions"
                  disabled={
                    !permissions?.permissions.includes(
                      "allocations_markComplete"
                    )
                  }
                />
                <EditButton
                  title="Click here to Edit progress"
                  className="btn_ btn_edit"
                  disabled={
                    !permissions?.permissions.includes("allocations_edit")
                  }
                />
                <ShowButton
                  title="Click here to show progress"
                  className="btn_ btn_secondry"
                  //   title="Click here to Edit progress"
                  //   disabled={!permissions?.permissions.includes("allocations_edit")}
                  // />
                  // <ShowButton
                  //   title="Click here to Show progress"
                  disabled={
                    !permissions?.permissions.includes("allocations_show")
                  }
                />
                <Confirmation
                  text="allocation"
                  title="Click here to Delete progress"
                  disabled={
                    !permissions?.permissions.includes("allocations_edit")
                  }
                />
              </Datagrid>
            );
          }}
        </ChangeDiv>
      </List>
    </div>
  );
};
const MakeButton = connect(null, {
  showNotification,
})(MakeButtonComponent);