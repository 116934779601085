import React from "react";
import img from "../../../layout/img/undraw_Profile_data_re_v81r.svg";

// profile page bottom woman image
export const BottomImage = () => {
  return (
    <div>
      <img src={img} alt="leaf" width="90%" height="340px" />
    </div>
  );
};
