import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import {
  Loading,
  Query,
  Title,
  useDataProvider,
  usePermissions,
} from "react-admin";
import { Link } from "react-router-dom";
import "../../layout/styles/grid.css";
import { OperationCard } from "../operations/OperationCard";
import { OderCard } from "./containers/OderCard";
import Pagination from "./helpers/Pagination";
import PlanBoardList from "./helpers/PlanBordListView";
import { CardStyle } from "./widgets/DemandOperationCard";
import NewPagination from "./helpers/newPagnition";

export const DrilDownView = () => {
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();

  let operationIdArray = 0;
  let orderIds = 0;

  // get plant id from local strage
  const getPlant = () => {
    const plant = localStorage.getItem("plant");

    if (!isNaN(plant)) {
      return plant;
    }
    return "";
  };

  const [inputData, setInputData] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [pageCount, setPageCount] = useState(0);
  const [plantId, setPlantId] = useState(() => getPlant());

  // ------- serch bar functionality

  const searchPayload = {
    id: inputData,
    pagination: { page: page, perPage: perPage },
    sort: { field: "id", order: "ASC" },
    filter: inputData ? { order_code: inputData } : "",
  };

  // ------ basic api call and pagination functionlity

  const filterData = async () => {
    return await dataProvider.getList("demandOp", searchPayload);
  };
  const setdata = async () => {
    const data = await filterData(inputData);
    const { total } = data;
    if (total > 0) {
      setPageCount(total);
    }
    setSearchResult(data);
  };

  useEffect(() => {
    setdata();
  }, [inputData]);

  //---- set pagination state
  const handlePaginationState = (p, r) => {
    setPage(p);
    setPerPage(r);
  };
  //----- set plant number
  const onChangeList = (data) => {
    if (isNaN(data)) {
      return false;
    }
    setPlantId(data);
  };

  const payload = {
    id: "",
    pagination: { page: page + 1, perPage: perPage },
    sort: { field: "id", order: "ASC" },
    filter: inputData ? { order_code: inputData } : "",
    filter: plantId ? { plant_id: plantId } : "",
  };
  return (
    <Query type="getList" resource="demandOp" payload={payload}>
      {({ data, loading, error }) => {
        if (data) {
          // //filter remove useless data

          const filterOperationId = (data) => {
            const operationIdArray = data.map(
              ({ operation_id }) => operation_id
            );
            const filterOperationId = operationIdArray.filter(
              (res, ind) => operationIdArray.indexOf(res) === ind
            );
            return filterOperationId;
          };
          operationIdArray = filterOperationId(data);

          orderIds = Array.from(new Set(data.map((item) => item.order_id)));
        }
        // ---- order search only triger when user search somthing

        if (searchResult.data) {
          data = searchResult.data;
          if (!data) {
            console.log("ok");
          }
          const filterOperationId = (data) => {
            const operationIdArray = data.map(
              ({ operation_id }) => operation_id
            );
            const filterOperationId = operationIdArray.filter(
              (res, ind) => operationIdArray.indexOf(res) === ind
            );
            return filterOperationId;
          };
          operationIdArray = filterOperationId(data);

          orderIds = Array.from(new Set(data.map((item) => item.order_id)));
        }

        return (
          <>
            <Title title="Drill Down View" />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginLeft: 10 }}>
                  {["ADMIN", "SITE"].includes(permissions?.access_level) ? (
                    <PlanBoardList
                      onChangeList={onChangeList}
                      listVal={plantId}
                    />
                  ) : null}
                </div>
                <div style={{ marginBottom: -25 }}>
                  <input
                    style={{ width: window.innerWidth * 0.15 }}
                    className="search"
                    type="text"
                    placeholder="Search"
                    name="search"
                    onChange={(e) => setInputData(e.target.value)}
                  />
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Pagination
                  count={pageCount}
                  Cpage={page}
                  RperPage={perPage}
                  handlePaginationState={handlePaginationState}
                />
              </div>
            </div>

            {loading ? (
              <Loading />
            ) : error ? (
              <p className="alert alert-danger text-center text-danger">NO DATA FOUND</p>
            ) : (
              <div
                style={{
                  // maxWidth: window.innerWidth * 0.84,
                  maxHeight: window.innerHeight * 0.82,
                  overflow: "auto",
                }}
                className="table-width-custom"
              >
                <table className="zui-table "  style={
                      {
                         height: '100%'
                      }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          position: "sticky",
                          left: "0px",
                          zIndex: 11000,
                          top: "0px",
                        }}
                      >
                        <span style={{ fontSize: 14 }}>
                          <center> Order</center>
                        </span>
                      </th>

                      {operationIdArray.map((id, i) => (
                        <th
                          key={i}
                          style={{
                            position: "sticky",
                            top: "0px",
                            zIndex: 9000,
                          }}
                        >
                          <span style={{ fontSize: 14 }}>
                            <center> Order</center>
                          </span>{" "}
                          <OperationCard prop={id} />{" "}
                        </th>
                      ))}
                    </tr>
                    {/* </thead>
                  <tbody style={{ overflowY: "auto", height: "100px" }}>
                    {orderIds.map((orderId, i) => (
                      <tr key={i}>
                        <td
                          style={{
                            position: "sticky",
                            left: "0px",
                            zIndex: 10000,
                            backgroundColor: "#bde9ba",
                          }}
                        >
                          <OderCard
                            key={i}
                            prop={orderId}
                            dataProp={data}
                            opArrayProp={operationIdArray}
                          />
                        </td>
                        {operationIdArray.map((id, i) => {
                          return (
                            <td key={i}>
                              {data
                                .filter(
                                  (val) =>
                                    id === val.operation_id &&
                                    val.order_id === orderId
                                )
                                .map((dmnd, i) => {
                                  return (
                                    <>
                                      {dmnd.plan_board_id ? (
                                        <Link
                                          to={`/planBoard/${dmnd.plan_board_id}/`}
                                        >
                                          <Button>
                                            <CardStyle data={dmnd} key={i} />
                                          </Button>
                                        </Link>
                                      ) : (
                                        <CardStyle data={dmnd} key={i} />
                                      )}
                                    </>
                                  );
                                })}
                            </td>
                          );
                        })}
                      </tr> */}
                  </thead>
                  <tbody style={{ overflowY: "auto", height: "100vh" }}>
                    {orderIds.map((orderId, i) => (
                      <tr key={i}>
                        <td
                          style={{
                            position: "sticky",
                            left: "0px",
                            zIndex: 10000,
                            backgroundColor: "#effaff",
                            color: "#00acee",
                            fontSize: "16px",
                          }}
                        >
                          <OderCard
                            key={i}
                            prop={orderId}
                            dataProp={data}
                            opArrayProp={operationIdArray}
                          />
                        </td>
                        {operationIdArray.map((id, i) => {
                          return (
                            <td key={i}>
                              {data
                                .filter(
                                  (val) =>
                                    id === val.operation_id &&
                                    val.order_id === orderId
                                )
                                .map((dmnd, i) => {
                                  return (
                                    <>
                                      {dmnd.plan_board_id ? (
                                        <Link
                                          to={`/planBoard/${dmnd.plan_board_id}/`}
                                        >
                                          <Button style={{ width: "100%" }}>
                                            <CardStyle data={dmnd} key={i} />
                                          </Button>
                                        </Link>
                                      ) : (
                                        <CardStyle data={dmnd} key={i} />
                                      )}
                                    </>
                                  );
                                })}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              //       ))}
              //     </tbody>
              //   </table>
              // </div>
            )}
          </>
        );
      }}
    </Query>
  );
};
