import { SUPPLY_CHAIN_CALCULATE_PLAN, SUPPLY_CHAIN_LOAD_DATA, SUPPLY_CHAIN_UPDATE_ITEM_TIME_BUCKET, SUPPLY_CHAIN_MANUAL_PLAN, SUPPLY_CHAIN_MANUAL_PLAN_FIND_INFEASIBLE } from "./types";
import { put, takeLatest, select } from "redux-saga/effects";
import { getSupplyChainData } from "./selectors";
import { findInfeasibleItems, solve } from "../algorithm";
import { supplyChainCalculatePlan, supplyChainLoadData } from "./actions";
import { showToastError, showToastSuccess, showToastWarning } from "../../../lib/toas";

export function* supplyChainSaga() {
    yield takeLatest(SUPPLY_CHAIN_MANUAL_PLAN, handleCalculatePlan);
    yield takeLatest(SUPPLY_CHAIN_CALCULATE_PLAN, handleCalculatePlan);
    yield takeLatest(SUPPLY_CHAIN_MANUAL_PLAN_FIND_INFEASIBLE, handleFindInfeasibleItemsAndCalculatePlan);
}

export function* handleLoadData(action: any) {
    const { key } = action.payload
    if (key != "planned") {
        yield put(supplyChainCalculatePlan())
    }
};

export function* handleUpdate(action: any) {
    // yield put(supplyChainCalculatePlan())
};

const delay = (duration: number) => new Promise(resolve => setTimeout(resolve, duration))

export function* handleCalculatePlan(action: any): any {
    showToastWarning("Starting")
    yield delay(500)
    const data = yield select(getSupplyChainData)

    const result = solve(data)
    const { planned, feasible, success, sales_loss } = result
    const hasSalesLoss = sales_loss.length > 0
    if (feasible) {
        console.log(planned, data, result)
        yield put(supplyChainLoadData("infeasible_items", []))
        yield put(supplyChainLoadData("planned", planned))
        yield put(supplyChainLoadData("sales_loss", sales_loss))
        if (!hasSalesLoss) {
            showToastSuccess("Simulation completed.")
        } else {
            showToastError("Simulation completed with sales loss")
        }
    } else {
        showToastError("No feasible solution")
    }
}


export function* handleFindInfeasibleItemsAndCalculatePlan(action: any): any {
    showToastWarning("Finding Infeasible Items")
    yield delay(500)
    const data = yield select(getSupplyChainData)

    const result = findInfeasibleItems(data)
    console.log(result.ignoredItems)
    const { planned, feasible, success, sales_loss, ignoredItems } = result

    showToastSuccess("The plan is calculated. ")
    console.log(planned, data, result)
    yield put(supplyChainLoadData("infeasible_items", Array.from(ignoredItems)))
    yield put(supplyChainLoadData("planned", planned))
    yield put(supplyChainLoadData("sales_loss", sales_loss))
    // } else {
    //     showToastError("No feasible solution")
    // }
}