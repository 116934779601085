import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  TextInput,
  Filter,
} from "react-admin";

const ToolFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by type" source="type" alwaysOn />
  </Filter>
);

export const Tool = (props) => (
  <List {...props} filters={<ToolFilter />} perPage={25}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="plan_board" />
      <TextField source="type" />
      <TextField source="size" />
      <TextField source="count" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);
