import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { SaveButton, DeleteButton, Toolbar, usePermissions } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../components/Confirmation";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";

export const SiteEdit = (props) => {
  const { permissions } = usePermissions();
  // const useStyles = makeStyles({
  //   toolbar: {
  //     display: "flex",
  //     justifyContent: "space-between",
  //   },
  // });
  // const CustomToolbar = (props) => (
  //   <Toolbar {...props} classes={useStyles()}>
  //     <SaveButton />
  //     <Confirmation
  //       title='permissions' disabled={!permissions?.permissions.includes('sites_delete')}
  //       text="sites"
  //     />{" "}
  //   </Toolbar>
  // );

  const validateSite = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.code) {
      errors.code = "Code is required";
    }
    return errors;
  };

  const Title = ({ record }) => {
    return <span>Edit Site | {record ? `${record.code} ` : ""}</span>;
  };

  const customToast = useCustomToast(props);
  return (
    <Edit
      title={<Title />}
      {...props}
      class={"col-md-6 mt-5"}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      {/* {console.log(props)} */}
      <SimpleForm
        validate={validateSite}
        toolbar={<CustomToolbar name="sites" text="Site" />}
        //  class={'px-4 py-4'}
      >
        <TextInput disabled source="id" />
        <TextInput
          source="name"
          label={<ReqInputField name="Name" />}
          title="This field is mandatory"
        />
        <TextInput
          source="code"
          label={<ReqInputField name="Code" />}
          title="This field is mandatory"
        />
      </SimpleForm>
    </Edit>
  );
};
