import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";


export const PlanBoardLineGroup = props => (
  <List  {...props} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="plan_board" />
      <TextField source="calendar" />
      <TextField source="code" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);
