import { LOAD_TNA_NOTIFICATIONS, SET_TNA_NOTIFICATIONS, LOAD_TNA_NOTIFICATIONS_SUCCESS, SET_NOTIFICATION_READ, SET_NOTIFICATION_TO_LAST } from "../types/tnaNotificationTypes";

const initialState = {
    loading: null,
    tnaNotifications: {
        unRead: Array(),
        read: Array(),
        all: Array(),
        unReadCount: 0,
    },
};

export const tnaNotificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOAD_TNA_NOTIFICATIONS: {
            return { ...state, loading: true };
        }
        case SET_TNA_NOTIFICATIONS: {
            const { data } = action.payload;
            return {
                ...state,
                tnaNotifications: {
                    ...state.tnaNotifications,
                    unRead: data?.unRead,
                    read: data?.read,
                    all: data?.allTNANotifications,
                    unReadCount: data.unReadCount,
                    readCount: data.readCount,
                    allCount: data.allCount
                },
                loading: true
            };
        }

        case LOAD_TNA_NOTIFICATIONS_SUCCESS: {
            return { ...state, loading: false };
        }
        case SET_NOTIFICATION_READ: {
            const notification = action.payload
            state.tnaNotifications.unRead = state.tnaNotifications.unRead.filter(value => value.id != notification.id);
            state.tnaNotifications.read.push(notification);

            return { ...state };

        }
        case SET_NOTIFICATION_TO_LAST: {
            const notificationId = action.notificationId
            const viewLaterNotification = state.tnaNotifications.unRead.filter(value => value.id == notificationId);
            state.tnaNotifications.unRead = state.tnaNotifications.unRead.filter(value => value.id != notificationId);
            state.tnaNotifications.unRead.push(viewLaterNotification[0]);
            return { ...state };

        }

        default:
            return state;
    }
};
