import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment";
import React from "react";
import { Loading, Query } from "react-admin";
import { format_to_date_time } from '../../lib/date_time';

const useStyles = makeStyles(theme => ({}));

export default function MoldChangeTable({selectedShift, selectedGroup}) {
  const classes = useStyles();
  if (selectedShift) {
    return <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center">Time</TableCell>
            <TableCell align="center">Mold Changes</TableCell>
          </TableRow>
        </TableHead>

        <Query key={`${selectedShift.start_time}-${selectedShift.end_time}`}
               type="getList" resource="allocations"
               payload={{
                 pagination: {page: 1, perPage: 1000},
                 sort: {field: 'id', order: 'ASC'},
                 filter: {
                   start_time: moment(selectedShift.start_time).format('YYYY-MM-DD'),
                   end_time: moment(selectedShift.end_time).format('YYYY-MM-DD'),
                   plan_board_group: selectedGroup
                 }
               }}>
          {({data, loading, error}) => {
            if (loading) {
              return <Loading/>;
            }
            if (error) {
              return "Error"
            }
            return <TableBody>
              {
                data.filter(v => v.demand_operation == null).map(row =>
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.line_code}</TableCell>

                    <TableCell align="center" scope="row">
                      {format_to_date_time(row.start_time)}
                    </TableCell>
                    <TableCell align="center">{row.configuration}</TableCell>
                  </TableRow>)
              }
            </TableBody>
          }}
        </Query>

      </Table>
    </TableContainer>
  } else {
    return null
  }
}
