import React from "react";
import {
  BooleanInput,
  Create,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { redirect } from "../../lib/redirect";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.order) {
    errors.order = "Order is required";
  }
  if (!values.item) {
    errors.item = "Item is required";
  }
  if (!values.quantity) {
    errors.quantity = "quantity is required";
  }
  if (!values.initial_required) {
    errors.initial_required = "initial is required";
  }
  if (!values.initial_required_time) {
    errors.initial_required_time = "initial_required_time is required";
  }
  if (!values.is_quality_ok) {
    errors.is_quality_ok = "is quality ok is required";
  }
  if (isNaN(values.is_quality_ok)) {
    errors.is_quality_ok = "is quality ok should be a number";
  }

  return errors;
};

export const CreateDemand = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      class={"col-md-6 mt-3"}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm
        toolbar={<CustomToolbar need={false} text="Demand" name="demands" />}
        validate={validate}
      >
        <ReferenceInput
          source="order"
          label={<ReqInputField name="Order" />}
          reference="orders"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput
          source="item"
          label={<ReqInputField name="Item" />}
          reference="items"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <TextInput
          source="quantity"
          label={<ReqInputField name="Quantity" />}
        />
        <TextInput
          source="initial_required"
          label={<ReqInputField name="Initial Required" />}
        />
        <TextInput source="is_quality_ok" label="Is Quality Ok" />
        <BooleanInput source="is_final" label="Is Final" />
        <DateTimeInput
          source="initial_required_time"
          label={<ReqInputField name="Initial Required Time" />}
        />
        <DateTimeInput source="supply_time" label="Supply Time" />
        <DateTimeInput source="required_time" label="Required Time" />
        <TextInput source="completed_quantity" label="Completed Quantity" />
      </SimpleForm>
    </Create>
  );
};
