import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";

export const ColumnSelectorDialog = ({
  isOpen,
  setOpenSelectorPopup,
  allColumns,
  setSelectColumns,
  selectColumns,
}) => {
  const handleClose = () => {
    setOpenSelectorPopup(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Column Selector</DialogTitle>
        <DialogContent>
          <Grid container item xs={12} spacing={0}>
            {allColumns
              .filter((column) => column.id !== "selection" && column.Header !== " ")
              .map((column) => {
                const id = column.Header;
                return (
                  <Grid item xs={4}>
                    <Checkbox
                      onChange={(e) =>
                        setSelectColumns({
                          ...selectColumns,
                          [id]: e.target.checked,
                        })
                      }
                      checked={selectColumns[column.Header]}
                    />{" "}
                    {column.Header}
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button className='btn_ btn_primary' onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
