import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { Fragment } from "react";
import { translate } from "react-admin";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { theme as customTheme } from "./theme";

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: "1rem",
    marginTop: 5,
    marginBottom: 5,
    // color: "#00acee",
    color: customTheme.palette.primary.main,
    borderBottom: "1px solid #eee",
  },
  listItemText: {
    paddingLeft: 0,
    // color: "#888",
    // fontSize: theme.typography.fontSize,
    fontFamily: "Montserrat",
    fontSize: "14px",
    paddingTop: "10px",
    paddingBottom: "10px",
    // color: "#00acee",
    color: customTheme.palette.primary.main,
    fontWeight: "500",
  },
  sidebarIsOpen: {
    paddingLeft: 10,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
}));

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  translate,
  hidden,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div onMouseEnter={handleToggle} onMouseLeave={handleToggle}>
        <ListItem
          hidden={hidden}
          dense
          button
          // onClick={handleToggle}
          classes={{ root: classes.listItem }}
        >
          <ListItemIcon>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
          <ListItemText
            style={{ whiteSpace: "nowrap", overflow: "hidden" }}
            primary={isOpen ? translate(name) : ""}
            secondary={isOpen ? "" : translate(name)}
            classes={
              isOpen
                ? { primary: classes.listItemText }
                : { secondary: classes.listItemText }
            }
          />
        </ListItem>
        <Collapse hidden={hidden} in={isOpen} timeout="auto" unmountOnExit>
          <List
            dense
            component="div"
            disablePadding
            className={
              sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
            }
          >
            {!hidden ? children : null}
          </List>
          <Divider />
        </Collapse>
      </div>
    </Fragment>
  );
};

const enhance = compose(translate);

export default enhance(SubMenu);
