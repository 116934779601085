import React from "react";
import {
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";
import { redirect } from "../../lib/redirect";

const validate = (values) => {
  const errors = {};
  if (!values.plan_board) {
    errors.plan_board = "plan board is required";
  }
  if (!values.type) {
    errors.type = "type time is required";
  }
  if (!values.size) {
    errors.size = "size is required";
  }
  if (!values.count) {
    errors.count = "count time is required";
  }
  return errors;
};


export const EditTool = (props) => {
  const Title = ({ record }) => {
    return (
      <span>Edit Tool | {record ? `${record.type}${record.size} ` : ""}</span>
    );
  };

  const customToast = useCustomToast({redirect: redirect});

  return (
    <Edit
      className="col-md-6"
      {...props}
      title={<Title />}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm
        toolbar={<CustomToolbar name="tools" text="Tool" />}
        validate={validate}
      >
        <ReferenceInput
          label="plan board"
          source="plan_board"
          reference="planBoards"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <TextInput source="type" />
        <TextInput source="size" />
        <NumberInput source="count" />
        <NumberInput source="compatibility_group" />
      </SimpleForm>
    </Edit>
  );
};
