import React from "react";
import { BooleanInput, Create, SimpleForm } from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

export const CreatePlanBoardVersion = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm rowClick="edit" redirect="list">
        <BooleanInput source="is_production" />
      </SimpleForm>
    </Create>
  );
};
