import { showToastError } from "../lib/toas";
import dsiPlanExport from "./dsiPlanExport";
import scomPlanExport from "./scomPlanExport";
import dateWiseScomPlanExport from "./dateWiseScomExport";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const formatShiftData = (data) => {
  var total_quantity = 0;
  var shift_count = 0;
  const output = data.map((record, index) => {
    const bucketDateLine = record.bucketDateLine;
    const bucket = record.bucket;
    if (data[index + 1] && data[index + 1].bucket !== bucket) {
      shift_count++;
    }
    if (data[index + 1] && data[index + 1].bucketDateLine === bucketDateLine) {
      total_quantity += record.Quantity;
      return { ...record, "Total shift quantity": " ", Shifts: " " };
    } else {
      const _total_quantity = total_quantity;
      const _shift_count = shift_count;
      shift_count = 0;
      total_quantity = 0;
      const column = {
        ...record,
        "Total shift quantity": _total_quantity + record.Quantity,
        Shifts: _shift_count,
      };

      return column;
    }
  });
  return output;
};

const basic = (inputData) => {
  const data = formatShiftData(inputData);

  const outputData = data.map((a) => {
    delete a.bucket;
    delete a.bucketDateLine;
    return a;
  });
  return outputData; //outputData
};

const combineSizes = (inputData) => {
  const data2 = inputData.reduce((acc, value) => {
    // compare the current value with the last item in the collected array
    const hasElements = acc.length > 0;
    const lastList = hasElements ? acc[acc.length - 1] : undefined;
    const lastElement = hasElements ? lastList[0] : undefined;

    const key = `${(value["Order Code"] || "").slice(0, 8)}---${
      value["Operation Code"]
    }///${value["bucket"]}`;
    const lastKey = hasElements
      ? `${(lastElement["Order Code"] || "").slice(0, 8)}---${
          lastElement["Operation Code"]
        }///${lastElement["bucket"]}`
      : "";
    if (acc.length && key === lastKey) {
      // append the current value to it if it is matching
      acc[acc.length - 1].push(value);
    } else {
      // append the new value at the end of the collected array
      acc.push([value]);
    }

    return acc;
  }, []);


  const data3 = data2.map((a) => {
    const record = a[0];
    var Quantity = 0;
    record["Order Code"] = record["Order Code"]
      ? record["Order Code"].slice(0, 8)
      : "";

    a.map((b) => {
      Quantity = Quantity + Number(b["Quantity"]);
      record["Quantity"] = Quantity;
    });
    return record;
  });

  const data4 = formatShiftData(data3);
  const outputData = data4.map((a) => {
    delete a.bucket;
    delete a.bucketDateLine;
    return a;
  });

  // console.log(data3);

  return outputData;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const exportExcel = (data, time, planboard, name) => {

  if (data[0] && name === "plan") {
    if (planboard.export_type === "BASIC") {
      //basic export
      console.log("BASIC PLAN EXPORT");
      const formatData = basic(data);
      dsiPlanExport(formatData, time, planboard);
    } else if (planboard.export_type === "COMBINE_SIZES") {
      console.log("COMBINE SIZES PLAN EXPORT");
      const formatData = combineSizes(data);
      dsiPlanExport(formatData, time, planboard);
    } else if (planboard.export_type === "SCOM") {
      console.log("SCOM PLAN EXPORT");
      scomPlanExport(data, time, planboard);
    } else if (planboard.export_type === "SCOM_DATEWISE") {
      console.log("SCOM_DATEWISE PLAN EXPORT");
      dateWiseScomPlanExport(data, time, planboard);
    } else {
      console.log("Error: No export type detected");
      showToastError("Export type for planboard not defined or invalid");
    }
  } else {
    showToastError("No allocations in this time range");
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////