import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { format_to_date_time } from "../../../../lib/date_time";
import { Link } from "react-admin";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
export const TableComponent = ({ payload, name }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} style={{ marginTop: 40 }}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Transfer id</TableCell>
            <TableCell align="right">Oder code</TableCell>
            <TableCell align="right">
              {" "}
              {name === "T" ? "Sent user" : "Action taken by user"}{" "}
            </TableCell>
            <TableCell align="right">
              {" "}
              {name === "T" ? "Sent to " : "Received from"}{" "}
            </TableCell>
            <TableCell align="right">
              {" "}
              {name === "T" ? "Sent on" : "Received on"}{" "}
            </TableCell>
            <TableCell align="right">Action</TableCell>
            <TableCell align="right"> Action made on </TableCell>
            {/* action made on date is wen user actept or dicline this date shoud change  */}
          </TableRow>
        </TableHead>
        <TableBody>
          {payload.map((row, i) => (
            <TableRow key={i}>
              <TableCell component="th" scope="row">
                {row.transfer_id}
              </TableCell>
              <TableCell align="right">
                {row.order_code.length > 1 ? (
                  <Link
                    to={{
                      pathname: `/oderCodes`,
                      state: { row },
                    }}
                  >
                    Oder Codes
                  </Link>
                ) : (
                  row.order_code
                )}
              </TableCell>
              <TableCell align="right">
                {" "}
                {name === "T" ? row.user_name : row.action_user}{" "}
              </TableCell>
              <TableCell align="right">
                {" "}
                {name === "T" ? row.received_plant : row.transfer_sent}{" "}
              </TableCell>
              <TableCell align="right">
                {" "}
                {name === "T"
                  ? format_to_date_time(row.transfer_date)
                  : format_to_date_time(row.received_date)}{" "}
              </TableCell>
              <TableCell align="right">{row.action}</TableCell>
              {name === "R" ? (
                <TableCell align="right">
                  {" "}
                  {format_to_date_time(row.action_time)}{" "}
                </TableCell>
              ) : (
                <TableCell align="right">
                  {format_to_date_time(row.time_stamp)}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
