import React from "react";
import { BooleanField, Datagrid, EditButton, List, TextField } from "react-admin";


export const PlanBoardVersion = props => (
  <List  {...props} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <BooleanField source="is_production" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);
