import { Button } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { forwardRef } from "react";
import { useDataProvider, usePermissions } from "react-admin";
import { format_to_date } from "../../lib/date_time";
import TransferDialog from "./TransferDialog";


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const updateData = (array) => {
  const newArray = array.map((data) => {
    if (data.is_exchange === true) {
      return data.id;
    }
  });
  return newArray;
};

const getIdArray = (array) => {
  const newArray = array.map((data) => {
    return data.id;
  });
  return newArray;
};

export default function SetupTransfersTable({
  data,
  colorMappings,
  onSelect,
  title,
  plants,
  setup,
}) {

  const { permissions } = usePermissions()
  const dataProvider = useDataProvider();
  const callApi = (values) => {
    const idArray = updateData(values);
    dataProvider.TRANSFER_DEMAND_OP(`planBoards/1/transfer_demandop_revert`, {
      body: idArray,
    });
  };

  return (
    <MaterialTable
      disableEnforceFocus
      icons={tableIcons}
      columns={[
        {
          title: "ID",
          field: "id",
          cellStyle: { paddingLeft: 10 },
          headerStyle: {
            paddingLeft: 10,
          },
        },
        { title: "Item Code", field: "item_code" },
        { title: "Order", field: "order_code" },
        // { title: "RM Ready", field: "rm_ready_date", type: "date" },

        { title: "Order Group", field: "order_group_code" },
        // {
        //   title: "Possible Start Date",
        //   field: "required_time_min",
        //   type: "date",
        //   render: (rowData, renderType) => {
        //     if (rowData.received_date) {
        //       return <span>{format_to_date(rowData.received_date)}</span>;
        //     } else {
        //       return "";
        //     }
        //   },
        // },
        {
          title: "Need Date",
          field: "required_time_max",
          defaultSort: "desc",
          type: "date",
          render: (rowData, renderType) => {
            if (rowData.required_time_max) {
              return <span>{format_to_date(rowData.required_time_max)}</span>;
            } else {
              return "";
            }
          },
        },
        { title: "Workstation", field: "workstation_code" },
        { title: "Quantity", field: "quantity", type: "number" },

        // { title: "Planned Between", field: "required_date_max", type: 'date' },

        // {
        //   title: "Configuration",
        //   field: "configuration",
        //   render: (rowData, renderType) => {
        //     const config =
        //       renderType == "row" ? rowData.configuration : rowData;

        //     return (
        //       <span
        //         style={{
        //           padding: 5,
        //           borderRadius: 5,
        //           // backgroundColor: getColorFromConfiguration(
        //           //   colorMappings,
        //           //   rowData.configuration
        //           // )
        //         }}
        //       >
        //         {config}{" "}
        //       </span>
        //     );
        //   },
        // },
        // {
        //   title: "Skipped",
        //   field: "skipped_quantity",
        //   render: (rowData, renderType) => {
        //     if (renderType == "row") {
        //       return rowData.skipped_quantity > 0
        //         ? parseFloat(rowData.skipped_quantity)
        //         : "";
        //     } else {
        //       return "";
        //     }
        //   }
        // },
        // {
        //   title: "Planned",
        //   field: "is_planned",
        //   render: (rowData, renderType) => {
        //     const is_planned =
        //       renderType == "row" ? rowData.is_planned : rowData;
        //     const text =
        //       renderType == "row"
        //         ? `${parseFloat(rowData.planned_quantity)}/${parseFloat(
        //             rowData.quantity
        //           )}`
        //         : is_planned
        //         ? "Planned"
        //         : "Not planned";

        //     return (
        //       <span
        //         style={{
        //           padding: 5,
        //           borderRadius: 5,
        //           backgroundColor: is_planned ? "lightgreen" : "yellow",
        //         }}
        //       >
        //         {text}
        //       </span>
        //     );
        //   },
        // },
        // {
        //   title: "status",
        //   field: "transfer_status",
        //   render: (rowData, renderType) => {
        //     if (!setup && rowData.transfer_status && renderType == "row") {
        //       return rowData.transfer_status.split('-')[0]
        //     } else {
        //       return "";
        //     }
        //   }
        // },
      ]}
      data={data}
      options={{
        searchFieldStyle:{maxWidth:200},
        grouping: true,
        pageSize: 10,
        doubleHorizontalScroll: true,
        maxBodyHeight: 500,
        debounceInterval: 1000,
        padding: "default",
        selection: setup,
        selectionProps: (rowData) => ({
          data: rowData,
          color: "primary",
          style: { padding: 0 },
        }),
      }}
      components={{
        Toolbar: (props) => {
          const { selectedRows } = props;
          return (
            <div>
              <MTableToolbar {...props} />
              {setup && selectedRows.length > 0 && (
                <div style={{ padding: "10px" }}>
                  <TransferDialog title='permissions' disabled={!permissions?.permissions.includes('pendingSetupTable_setup')} selectedRows={selectedRows} />

                  <Button
                    style={{ margin: "10px" }}
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      callApi(selectedRows);
                      window.location.reload(false);
                    }}
                    title='permissions' disabled={!permissions?.permissions.includes('pendingSetupTable_revert')}
                  >
                    Revert
                  </Button>
                </div>
              )}
            </div>
          );
        },
      }}
      title={title}
    />
  );
}
