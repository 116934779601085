import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { format_to_date_for_api } from "../../lib/date_time";
import { exportPlanBoardData } from "../../store/actions";
import { ExportHelper } from "./helperComponents/export/ExportHelper";
import { usePermissions } from "react-admin";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  select: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const ExportPopup = React.memo((props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const today = moment().startOf("day").valueOf();

  const [open, setOpen] = useState(false);

  const [startDate, setStartDate] = React.useState(today);
  const [endDate, setEndDate] = React.useState(today);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const convertToTimeStamp = (date) => new Date(date).getTime();
  const submitData = () => {
    const start = convertToTimeStamp(startDate);
    const end = convertToTimeStamp(endDate);

    dispatch(exportPlanBoardData(start, end));
    handleClose();
  };

  const { permissions } = usePermissions();
  return (
    <div>
      <Button
        className="btn_ btn_primary"
        // variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        title="Click here to Export"
        disabled={!permissions?.permissions.includes("plan_export")}
      >
        Export
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minHeight: "80vh", maxHeight: "80vh" }}
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle id="alert-dialog-title">
              {"Select export time range"}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        <DialogContent>
          <Card style={{ padding: 5 }}>
            <br />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                label="Start date"
                value={startDate}
                onChange={(e) => setStartDate(e)}
                format="DD/MM/YYYY" // hh:mm A"
                // disabled={disabled}
                variant="inline"
                emptyLabel="Choose date"
              />
              <KeyboardDatePicker
                autoOk
                label="End date"
                value={endDate}
                onChange={(e) => setEndDate(e)}
                format="DD/MM/YYYY" // hh:mm A"
                // disabled={disabled}
                variant="inline"
                emptyLabel="Choose date"
              />
            </MuiPickersUtilsProvider>
          </Card>
          <ExportHelper
            startDate={startDate}
            endDate={endDate}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
});
ExportPopup.type.displayName = "ExportPopup";
export default ExportPopup;
