import React from "react";
import {
  BooleanInput,
  Create,
  EditButton,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.order) {
    errors.order = ["Order is required"];
  }
  if (!values.demand) {
    errors.demand = ["Order demand is required"];
  }

  return errors;
};

export const CreateOrderResolveOption = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm validate={validate} rowClick="edit">
        <ReferenceInput label="order" source="order" reference="orders">
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput
          label="order demand"
          source="order_demand"
          reference="demand"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <BooleanInput source="force_select" />
        <BooleanInput source="selected" />
        <EditButton label="Edit" />
      </SimpleForm>
    </Create>
  );
};
