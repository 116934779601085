import React, { useState, useMemo, ChangeEvent, useEffect, useContext, useCallback } from 'react'
import DownIcon from "@material-ui/icons/ArrowDownward"
import UpIcon from "@material-ui/icons/ArrowUpward"
import { useDebouncedState, Html5Table, useFilter } from 'window-table';
import _ from "lodash"
import { Tooltip, Typography, Checkbox } from '@material-ui/core';
import { format_to_date } from "../lib/date_time";
import moment from "moment";

export type DefaultDateRangeFilter = (row: any, columnKey: string, filterValue: { start_date: any, end_date: any }) => boolean

export type Column = {
    key: string,
    title: string,
    filterComponent: React.Component,
    width: number,
    useDateRangeFilter?: boolean,
    onClickCell: (row: any) => any,
    groupByFunction: (row: any) => any,
    sortFunction: (a: any, b: any) => any,
    getCellStyleFunction: (row: any) => any,
    CellRenderer?: (props: any) => JSX.Element,
    HeaderRenderer?: (props: any) => JSX.Element,
    formatFunction?: typeof defaultFormatFunction,
    filterFunction?: typeof defaultFilterFunction | DefaultDateRangeFilter,
    FilterInput?: typeof DefaultFilterInput
}

const useGroupBy = (rows: any[], f: (row: any) => any) => {
    return useMemo(() => {
        const groupsObject = _.groupBy(rows, f)
        const groups = Object.keys(groupsObject).sort().map(k => groupsObject[k])
        return groups.reduce((all, value) => [...all, ...value], [])
    }, [rows, f])
}

const defaultFormatFunction = (value: any) => `${value}`

const defaultFilterFunction = (row: any, columnKey: string, filterValue: string, formatFunction: typeof defaultFormatFunction) =>
    !filterValue || `${formatFunction(row[columnKey])}`.toLowerCase().startsWith(filterValue.toLowerCase())

const useFilters = (filters: any, rows: any[], columns: Column[]) => useMemo(() => {
    const isValidRow = (row: any) => {
        return columns.every(column => {
            const formatFunction = column.formatFunction || identityFunction
            const filterFunction = column.filterFunction || defaultFilterFunction
            return filterFunction(row, column.key, filters[column.key], formatFunction)
        })
    }
    return rows.filter(isValidRow)
}, [filters, rows])

const useSorted = (rows: any[], sortColumn: string, sortDirection: number) => useMemo(() => {
    if (!sortColumn) {
        return rows;
    }
    /**
     * Note that we are using the mapSort library to do the sorting,
     * But that's completely optional.
     * Use whatever cool library you prefer
     *
     * However, it is important to use a sort function which
     * does not sort in place.
     */
    return rows.sort((a: any, b: any) => {
        const vA = a[sortColumn]
        const vB = b[sortColumn]
        if (vA < vB) return -sortDirection;
        if (vA > vB) return sortDirection;
        return 0;
    }
    );
}, [rows, sortColumn, sortDirection]);


const createCellRenderer = (getStyles: (row: any) => any, onClick: (row: any) => any, formatFunction: (row: any) => any) => {
    return (props: any) => {
        const { row, column } = props;
        const styles = getStyles ? getStyles(row) : {}
        const value = formatFunction(row[column.key])
        const handleClick = () => onClick && onClick(row)
        return <Tooltip title={value}><Typography variant="body2" style={{ overflow: 'hidden', paddingLeft: 5, paddingRight: 5, ...styles }} onClick={handleClick}>
            {value}
        </Typography>
        </Tooltip>
    }
}

const SortIcon = ({ up }: { up: boolean }) => up ? <UpIcon style={{ position: 'absolute' }} fontSize="small" /> : <DownIcon style={{ position: 'absolute' }} fontSize="small" />

const identityFunction = (v: any) => v


const QueryContext = React.createContext({
    query: {
        sortColumn: undefined as any,
        sortDirection: 1,
        groupByColumn: undefined as any,
        filters: {} as any,
        changedFilterKey: ""
    },
    sortedRows: [] as any[],
    setQuery: (a: any) => { },
})


const TextFilterInput = ({ onChange, value, isFocused }: { onChange: (value: any) => void, value: any, isFocused: boolean }) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)
    return <input
        ref={input => isFocused && input && input.focus()}
        onChange={handleOnChange}
        value={value}
        style={{ width: '100%' }}>
    </input>
}

const DefaultFilterInput = TextFilterInput


const HeaderCell = (props: any) => {
    const { setQuery, query } = React.useContext(QueryContext)
    const columnKey = props.column.key
    const isSortThis = columnKey === query.sortColumn
    const onClickSort = () => {
        setQuery((q: any) => ({ ...q, sortColumn: columnKey, sortDirection: q.sortDirection * -1, changedFilterKey: columnKey }))
    }

    const handleOnChangeQuery = !props.column.useDateRange ? (value: string) => {
        setQuery((q: any) => ({ ...q, filters: { ...query.filters, [columnKey]: value }, changedFilterKey: columnKey }))
    } : (range: Date[]) => {
        if (!range) {
            range = [new Date(), moment().add(4, "days").toDate()];
        }
        const formatted = range.map((v: Date) => v.valueOf());
        setQuery((q: any) => ({ ...q, filters: { ...query.filters, [columnKey]: { start_date: formatted[0], end_date: formatted[1] } }, changedFilterKey: columnKey }))
    }
    const filterValue = query.filters[columnKey]
    const FilterInput = (props.column.FilterInput || DefaultFilterInput) as (typeof DefaultFilterInput)

    return (
        // columnKey === 'isFreezed' ?
        //     <th {...props} >
        //         <div style={{ display: 'flex', justifyContent: "center", paddingLeft: 3, paddingTop: 5 }}>
        //             <Checkbox
        //                 color="default"
        //                 checked={checkAll}
        //                 onChange={() => {
        //                     setFilteredRows(sortedRows)
        //                     setCheckAll(!checkAll)
        //                 }}
        //             />
        //         </div>
        //     </th> :
        <th {...props}>
            <Typography
                style={{ cursor: 'pointer', textAlign: 'center', fontSize: 12 }}
                variant="body1"
                onClick={onClickSort}
            >

                {props.children}
                {isSortThis && <SortIcon up={query.sortDirection < 0} />}
            </Typography>
            <FilterInput onChange={handleOnChangeQuery} value={filterValue} isFocused={columnKey === query.changedFilterKey} />

        </th >

    );
}

const CustomCell = (props: any) => <td {...props} style={{ ...props.style, overflow: 'hidden', }} />

const SelectedIdsContext = React.createContext([{} as any, () => null])

const HeaderSelectAllBox = () => {
    const [selectedIds, setSelectedIds, isAllSelected, selectAll] = useContext(SelectedIdsContext)

    return <div style={{ display: 'flex', justifyContent: "center", height: 20, marginTop: 2 }}>
        <Checkbox
            color="default"
            // style={{color: row.isFreezed ? blue[600] : grey[600]}}
            checked={isAllSelected}
            onChange={selectAll}
        />
    </div>
}
const SelectBox = ({ row }: any) => {
    // const [] = useContext(SelectedIdsContext)

    return <div style={{ display: 'flex', justifyContent: "center", height: 20, marginTop: 2 }}>
        <SelectedIdsContext.Consumer>
            {
                ([selectedIds, setSelectedIds]) => {

                    const handleClick = () => {
                        setSelectedIds((v: any) => ({ ...v, [row.id]: !selectedIds[row.id] }))
                        console.log("Row", row)
                    }
                    return <Checkbox
                        color="default"
                        key={row.id}
                        // style={{color: row.isFreezed ? blue[600] : grey[600]}}
                        checked={selectedIds[row.id]}
                        onChange={handleClick}
                    />
                }
            }
        </SelectedIdsContext.Consumer>
    </div>
}

const selectColumn = {
    key: "select",
    title: "Select",
    width: 10,
    FilterInput: HeaderSelectAllBox,
    Component: SelectBox
}

export default function AdvancedTable({ columns, rows, defaultSortColumn = "", defaultGroupByColum = "", rowClassName, onChangeSelection, useSelectColumn, height, open=true }:
    { columns: Column[], rows: any[], defaultSortColumn?: string, defaultGroupByColum?: string, rowClassName?: Function, onChangeSelection?: (ids: any[]) => any, useSelectColumn?: boolean, height?: number, open?: boolean }) {

    const [realQuery, query, setQuery] = useDebouncedState({ sortColumn: defaultSortColumn, sortDirection: 1, groupByColumn: defaultGroupByColum, filters: {} }, 200)
    const filteredRows = useFilters(query.filters, rows, columns)
    const sortedRows = useSorted(filteredRows, query.sortColumn, query.sortDirection)
    const groupByFunction = columns.filter(column => column.key === query.groupByColumn)[0]?.groupByFunction || ((row: any) => row[query.groupByColumn])
    // const groupedRows = useGroupBy(sortedRows, groupByFunction)

    const [selectedIds, setSelectedIds] = useState({} as any)
    const [selectAllCounter, setSelectAllCounter] = useState(0)


    const mapColumns = (column: Column) => ({
        key: column.key,
        title: column.title,
        width: column.width,
        useDateRange: column.useDateRangeFilter,
        FilterInput: column.FilterInput,
        Component: column.CellRenderer || createCellRenderer(
            column.getCellStyleFunction, column.onClickCell,
            column.formatFunction || identityFunction)
    })

    const tableColumns = useMemo(() => columns.map(mapColumns), [columns, query])
    const tableColumnsWithSelect = useMemo(() => [selectColumn, ...tableColumns], [tableColumns, selectAllCounter])

    const finalRows = sortedRows
    const rowClassNameFunction = useCallback((index: number) => rowClassName && rowClassName(finalRows[index]), [rowClassName, finalRows])

    useEffect(() => {
        if (onChangeSelection) {
            const ids = Object.keys(selectedIds).filter(k => selectedIds[k])
            onChangeSelection(ids)
        }
    }, [selectedIds])

    const isAllSelected = useMemo(() => finalRows.length > 0 && finalRows.every(row => !!selectedIds[row.id]), [finalRows, selectedIds])
    const onAllSelectClick = () => {
        if (isAllSelected) {
            setSelectedIds({})
        } else {
            const update = _.mapValues(_.mapKeys(finalRows, 'id'), () => true)
            setSelectedIds((v: any) => ({ ...update }))
        }
        setSelectAllCounter(v => v + 1)
    }

    return (
        <QueryContext.Provider value={{ query: realQuery, sortedRows: sortedRows, setQuery }}>
            <SelectedIdsContext.Provider value={[selectedIds, setSelectedIds, isAllSelected, onAllSelectClick]}>
                {open && <Html5Table
                    data={finalRows}
                    columns={useSelectColumn ? tableColumnsWithSelect : tableColumns}
                    style={{ height: height || '500px' }}
                    className="table-sm table-hover"
                    headerClassName="thead-light"
                    HeaderCell={HeaderCell}
                    Cell={CustomCell}
                    rowClassName={rowClassNameFunction}
                /> }
            </SelectedIdsContext.Provider>
        </QueryContext.Provider>
    )
}
