import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";

export const RequiredDemand = props => (
  <List {...props} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="demand_operation" />
      <TextField source="demand" />
      <TextField source="lead_time" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);
