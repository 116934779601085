import React, { useState } from "react";
import { showToastSuccess, showToastError } from "../../../lib/toas";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  NumberField,
  Datagrid,
  BooleanField,
  DateField,
  ReferenceField,
  EditButton,
  ReferenceManyField,
  Pagination,
  usePermissions,
  ReferenceArrayField,
  useMutation,
  SingleFieldList,
  ChipField,
  useRefresh,
  useRecordContext
} from "react-admin";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormControl, Typography, Grid,Switch } from "@material-ui/core";




const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    paddingLeft: 5,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75)
  },
}));

export const TemplatesShow = (props) => {
  const { permissions } = usePermissions();

  
  const [mutate, { loading, onSuccess, onError }] = useMutation();
  const classes = useStyles();


  const Title = ({ record }) => {
    return (
      <span>TNA Template | {record ? `${record.id ?? ""} ` : ""}</span>
    );
  };

  const ChangeStatusField = (props) => {
    const { record } = useRecordContext(props);
    const { permissions } = usePermissions();
    const refresh = useRefresh();
    const [isActive, setIsActive] = useState(record.isActive);

    const changeStatus = event => {
      mutate({
        type: 'TNA_CUSTOM_ACTION',
        resource: 'templates',
        payload: {
          action: "change_template_status",
          method: "POST",
          body: {
            "isActive": !record.isActive,
            "templateId": record.id
          },
        }
      }, {
        onFailure: (e) => {
          showToastError("Template status change failed" + e.body);
          setIsActive(record.isActive)
        },
        onSuccess: ({ data }) => {
          if (data) {
            if(data.isActive){
              showToastSuccess(`Template Successfully Activated `);
            }else{
              showToastSuccess(`Template Successfully De-Activated `);
            }
            
          }
          setIsActive(!record.isActive)
          setTimeout(() => {
            refresh();
          }, 10);
          
        }
      });
    }

    return <div class="ra-field ra-field-status">
      <FormControl>
        <Typography>Status</Typography>
        <Grid container alignItems="center">
          <Grid item md={1} >
            <Typography>Active</Typography>
          </Grid>
          <Grid item md={1}>
            <Switch
            checked={record.isActive}
            onChange={changeStatus}
            name="templateStatus"
            color="primary"
            disabled={!permissions?.permissions.includes("tna_template_status_change")}
          />
          </Grid>


        </Grid>

      </FormControl>
    </div>;
  }

  return (
    <Show {...props} actions={false} title={<Title />}>
      <SimpleShowLayout>
        <TextField source="id" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
        <TextField source="created_user" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
        <TextField source="modified_user" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
        <DateField source="created_timestamp" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
        <DateField source="modified_timestamp" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
        <TextField source="type" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
        <TextField source="code" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
        <TextField source="description" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
        <TextField source="plant" emptyText="-" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
        <ChangeStatusField />
        {/* <NumberField source="created_by" />
      <NumberField source="modified_by" /> */}
        <ReferenceManyField
          reference="tna_templateItems"
          label="Template Items"
          target="template"
          sort={{ field: 'sequence_no', order: "ASC" }}
          pagination={<Pagination perPage={25} />}
        >
          <Datagrid>
            <NumberField source="sequence_no" />
            <ReferenceField
              link=""
              label="Task"
              reference="tna_tasks"
              source="task"
            >
              <TextField source="title" />
            </ReferenceField>
            {/* <NumberField source="task" /> */}
            {/* <NumberField source="assigned_department" /> */}
            <ReferenceField
              link=""
              label="Department"
              reference="tna_departments"
              source="assigned_department"
            >
              <TextField source="code" />
            </ReferenceField>
            {/* <NumberField source="assigned_user" /> */}
            <ReferenceField
              link=""
              label="Assigned user"
              reference="users"
              source="assigned_user"
            >
              <TextField source="username" />
            </ReferenceField>
            <NumberField source="start_offset_days" />
            <NumberField source="duration" />
            <ReferenceArrayField
              label="Dependent items"
              reference="tna_templateItems"
              source="dependent_item"
            >
              <SingleFieldList>
                <ChipField source="sequence_no" />
              </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceField
              link=""
              label="Transferred user"
              reference="users"
              source="transferred_to"
            >
              <TextField source="username" />
            </ReferenceField>
            <BooleanField source="need_approval" />
            <EditButton
              disabled={
                !permissions?.permissions.includes("tna_templates_edit")
              }
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
