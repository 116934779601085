
import _ from "lodash"
import { IDemandOperation, IDemandOperationPart, IDemandOperationSelection, IPlan } from "../interfaces"

export const demand_operation_selector = (plan: IPlan, demand_operation_id: number) => {
    const demand_operation = plan.demand_operations[demand_operation_id]
    const quantity = _.max([demand_operation.quantity - demand_operation.planned_quantity, 0])
    if (quantity) {
        return [{ demand_operation_id: demand_operation.id, quantity }] as IDemandOperationSelection
    }
    return [] as IDemandOperationSelection
}

export const demand_operation_group_selector = (plan: IPlan, demand_operation_id: number) => {
    const demand_operation = plan.demand_operations[demand_operation_id]
    const f = (d: IDemandOperation) => (d.quantity - d.planned_quantity) > 0 && d.order_group_code === demand_operation.order_group_code
    const to_demand_operation = (d: IDemandOperation) => ({ demand_operation_id: d.id, quantity: d.quantity - d.planned_quantity }) as IDemandOperationPart
    return Object.values(plan.demand_operations).filter(f).map(to_demand_operation) as IDemandOperationSelection
}

export const demand_operation_quantity_selector = (plan: IPlan, demand_operation_id: number, quantity: number) => {
    return [{ demand_operation_id, quantity }] as IDemandOperationSelection
}