import { showToastError } from "./toas";


/*
this function has tow main functionality 
show custom error msg
when server faller occur  dispatch the error
*/


export const serverErrorsArray = [
  "The fields operation, workstation must make a unique set.",
  "This field is required.",
];

export const customResponseMsg = {
  duplicateOp: "Operation already linked",
  formDefaultError: "fill all the fields",
  defaultErrorMsg: "Action failed",
};

export const overrideDefaultErrorMsg = (e) => {
  if (e && typeof e === 'object') {
    if (e.TypeError) {
      showToastError(customResponseMsg.defaultErrorMsg)
    } else {
      if (e.body != undefined && e.body != null) {
        if (typeof e.body === "object") {
          try {
            if (e.body?.code) {
              showToastError(e.body.code[0])
            } else if (e.body?.username) {
              showToastError(e.body.username[0])
            }
          } catch (error) {
            console.log(error)
          }


          showToastError(e.body?.message)
        } else {
          showToastError(e.body)
        }
      }
    }
  }
  else if (typeof e === 'string') {
    showToastError(e);
    console.log(e.TypeError)
    // return false
  }

  if (e?.message != "Failed to fetch") {
    return false
  }
  else {
    return false
  }





  // showToastError(customResponseMsg.defaultErrorMsg);
}






// if (e && typeof e === 'object') {
//   // ---- server response with JSON
//   // if (e.body) {
//   //   // server handle the error 
//   //   const errorStack = Object.values(e.body);
//   //   const errorArray = errorStack;       // note! this array maybe contain one or more errors
//   //   if (errorArray && errorArray.length > 0) {
//   //     handelErrorMsg(errorArray[0]);
//   //   } else {
//   //     showToastError(customResponseMsg.defaultErrorMsg);
//   //   }
//   // }

//   // if (e.TypeError) {
//   //   // ---- server response without JSON
//   //   showToastError(customResponseMsg.defaultErrorMsg)
//   // } else {
//   //   return false
//   // }

//   // if(e.body){
//   //   if(typeof e.body==="object"){
//   //     showToastError(e.body["message"])
//   //   } else {
//   //     showToastError(e.body)
//   //   }

//   // } else {
//   //   showToastError(e.message)
//   // }
// }



// };

const handelErrorMsg = (e) => {
  if (typeof e !== "object") {

    return false
  }
  e.forEach((error) => {
    if (error) {
      showErrorMsg(error);
    }
  });
};

const showErrorMsg = (e) => {
  // custom errors
  switch (e) {
    case serverErrorsArray[0]:
      showToastError(customResponseMsg.duplicateOp);
      break;
    case serverErrorsArray[1]:
      showToastError(customResponseMsg.formDefaultError);
      break;
    // in default show the server error
    default:
      showToastError(e);
      break;
  }
};
