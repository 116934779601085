//loading
export const LOAD_DATA = "LOAD_DATA"
export const LOAD_DATA_SUCCESS = "LOAD_DATA_SUCCESS"

export const SET_DATA = "SET_DATA"

export const SETUP_TRANSFER = " SETUP_TRANSFER"

export const REVERT_TRANSFER = " REVERT_TRANSFER"

export const SETUP_RECEIVED = "SETUP_RECEIVED"

export const SET_CURRENT_PLANT = "SET_CURRENT_PLANT"
