import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { BulkDeleteButton, usePermissions } from "react-admin";
import { BulKOrderResolver } from "../bulkoderResolver";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    paddingBottom: theme.spacing(2),
  },
}));

export const OrderBulkActionButtons = (props) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BulkDeleteButton
        className="btn_ btn_delete"
        {...props}
        title="Click here to delete orders"
        disabled={!permissions?.permissions.includes("orders_bulkdelete")}
      />
      <BulKOrderResolver
        {...props}
        title="Click here to delete orders"
        disabled={!permissions?.permissions.includes("orders_resolveSelected")}
      />
    </div>
  );
};
