import { CLEAR_SELECTION, LOAD_PLAN, LOAD_PLANBOARD_SUCCESS, SELECT_ENTITY } from "../../types"

const initialState = null as { type: string, id: any } | null
export const selectionReducer = (state = initialState, action: any) => {
    if (action.type === SELECT_ENTITY) {
        const { type, id } = action.payload
        return { ...state, type, id }
    }

    if (action.type == LOAD_PLAN){
        return null
    }

    const needToClearIndex = [CLEAR_SELECTION, LOAD_PLANBOARD_SUCCESS].indexOf(action.type) !== -1
    if (needToClearIndex) {
        return null
    }
    return state
}