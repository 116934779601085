import React, { useState } from "react";
import { Filter, Loading, Query, SelectInput } from "react-admin";

const payload = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: "code", order: "ASC" },
};

const PlanboardList = ({ onChangeList }) => {
  const [list, setList] = useState("");
  React.useEffect(() => {
    onChangeList(list);
  }, [list]);

  const handelList = (e)=>
  {
    setList(e.target.value)
  }
  return (
    <Query type="getList" resource="plants" payload={payload}>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <p>ERRORS FOUND </p>;
        }
        const choices = data;
        const allChoice = { id: 0, site: 0, code: "", name: "" };
        if (choices.length == 0 || choices[0].id !== 0) {
          choices.unshift(allChoice);
        }
        return (
          <Filter>
            <SelectInput
              label="Plant"
              source="plant_id"
              choices={choices}
              // optionValue={listVal}
              default={1}
              alwaysOn
              onClick={(e) => handelList(e)}
            />
          </Filter>
        );
      }}
    </Query>
  );
};

export default PlanboardList;
