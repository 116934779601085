import { IPlan } from "../../../lib/plan";
import { createUndoableReducer } from "../../../lib/undoable_reducer";
import { CREATE_ALLOCATIONS, DELETE_ALLOCATIONS, DELETE_SECTION, LOAD_PLAN, MOVE_ALLOCATIONS, EDIT_ALLOCATION_DURATION, FREEZE_ALLOCATIONS } from "../../types";
import { createAllocationTransformer, deleteAllocationTransformer, deleteSectionTransformer, loadPlanTransformer, moveAllocationTransformer, editAllocationDuraitionTransformer, freezeAllocationsTransformer } from "./transformers";

const transformer_map = {
    [CREATE_ALLOCATIONS]: createAllocationTransformer,
    [DELETE_ALLOCATIONS]: deleteAllocationTransformer,
    [MOVE_ALLOCATIONS]: moveAllocationTransformer,
    [LOAD_PLAN]: loadPlanTransformer,
    [DELETE_SECTION]: deleteSectionTransformer,
    [EDIT_ALLOCATION_DURATION]: editAllocationDuraitionTransformer,
    [FREEZE_ALLOCATIONS]: freezeAllocationsTransformer,
    default: (s: any = null, action: any) => s
} as any



const planReducerOriginal = (state: IPlan | undefined, action: any) => {
    const type = action ? action.type : "default"
    const tansformer = transformer_map[type] || transformer_map.default
    return tansformer(state, action) as IPlan
}

export const planReducer = createUndoableReducer(planReducerOriginal, "PLAN_", LOAD_PLAN)