import React from "react";
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify
} from "react-admin";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";
import { redirect } from "../../lib/redirect";

const validate = (values) => {
  const errors = {};
  if (!values.plan_board) {
    errors.planBoard = "Plan board is required";
  }
  if (!values.code) {
    errors.code = "Unique Code is required";
  }

  return errors;
};

export const CreatePlanBoardLineGroup = (props) => {
  const customToast = useCustomToast({ redirect: redirect });
  return (
    <Create
      {...props}
      className="col-xl-6 col-sm-12"
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar
            need={false}
            name="planBoardGroups"
            text="planBoardGroups"
          />
        }
        validate={validate}
      >
        <ReferenceInput
          source="plan_board"
          reference="planBoards"
          label={<ReqInputField name="plan board" />}
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <TextInput source="code" label={<ReqInputField name="code" />} />
        <BooleanInput source="consider_group_smv" label="Consider Group SMV" />
        <BooleanInput
          source="is_switching_time_script"
          label="Enable script for switching time"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.is_switching_time_script ? (
              <TextInput
                source="switching_time_script"
                {...rest}
                label="Switching Script"
              />
            ) : (
              <NumberInput
                source="switching_time"
                {...rest}
                label="Switching Time (Hours)"
              />
            )
          }
        </FormDataConsumer>
        <TextInput
          source="supported_colors"
          label="Supported Colors (Comma Separated)"
        />
        <NumberInput
          source="supported_color_count"
          label="Supported Color Count"
        />
      </SimpleForm>
    </Create>
  );
};
