import { Button } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getDemandOperations } from "../../store/selectors";

import _ from 'lodash'

const demandOpIdArray = (array) => {
  const newArray = array.map((data) => {
    if (data.is_exchange === false) {
      return data.id;
    }
  });
  return newArray;
};

export const DemandOpDialog = ({ isOpen, values, forceSetOpen }) => {
  const dataProvider = useDataProvider();
  const location = useLocation();
  const demands = useSelector(getDemandOperations);

  const url = location.pathname.substr(1).split("/")[1];
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    forceSetOpen();
  };

  useEffect(() => {
    if (isOpen) {
      handleClickOpen();
    }
  });

  const checkOderGroups = () => {
    const oderGroupArray = values.map((data) => data.order_group_code);
    // for (let i = 0; i < oderGroupArray.length; i++) {
    //   const element = oderGroupArray[i];
    //   if (oderGroupArray[0] !== element) {
    //     return false;
    //   }
    // }
    // return {
    //   status: true,
    //   oderCode: oderGroupArray[0],
    // };
    return {
      status: true,
      oderCodes: _.uniq(oderGroupArray)
    }
  };

  const obj = checkOderGroups();
  const is_equel = obj.status;

  const getDemandOpId = (codes) => {
    const list = demands.filter((data) => {
      if (codes.includes(data.order_group_code)) {
        return data;
      }
    });
    return list;
  };
  const callApi = (values) => {
    const idArray = demandOpIdArray(values);
    dataProvider.TRANSFER_DEMAND_OP(`planBoards/${url}/transfer_demandop`, {
      body: idArray,
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Button onClick={handleClose}>Close</Button>
        <DialogTitle id="alert-dialog-title">
          {is_equel
            ? " Do you want to transfer?"
            : " Plese select befour transfer"}
        </DialogTitle>
        {!is_equel && (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description"></DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  callApi(values);
                  handleClose();
                  window.location.reload(false);
                }}
                variant="outlined"
                color="secondary"
                className="btn-success"
              >
                Ok
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  window.location.reload(false);
                }}
                variant="outlined"
                className="btn-delete"
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
        {is_equel && (
          <>
            <DialogContent>
              <ButtonGroup>
                <Button
                  variant="contained"
                  className='btn_ btn_secondry'
                  onClick={() => {
                    callApi(values);
                    handleClose();
                  }}
                >
                  Selected Order
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className='btn_ btn_primary'
                  onClick={() => {
                    const data = getDemandOpId(obj.oderCodes);
                    const idArray = demandOpIdArray(Object.values(data));
                    dataProvider.TRANSFER_DEMAND_OP(
                      `planBoards/${url}/transfer_demandop`,
                      {
                        body: idArray,
                      }
                    );
                    handleClose();
                  }}
                >
                  Order Group
                </Button>
              </ButtonGroup>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};
