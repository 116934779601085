export const calendarAttributesSchema = {
    title: "Edit calendar configuration",
    type: ["object", "null"],

    properties: {
        pattern: {
            type: "object",
            title: "Weekly pattern",

            properties: {

                weekday: {
                    type: "array",
                    title: "Weekday",
                    description: "Click 'ADD ITEM' to add a shift",
                    items: {
                        type: "array",
                        title: "Weekdays shifts",
                        required: ["start_time", "duration"],
                        items: [
                            {
                                type: "string",
                                title: "Start time",
                                description: "Enter shift starting time (format: HH:mm)"
                            },
                            {
                                type: "number",
                                title: "Duration",
                                description: "Enter shift duration"
                            }
                        ]
                    }
                },
                saturday: {
                    type: "array",
                    title: "Saturday",
                    description: "Click 'ADD ITEM' to add a shift",
                    items: {
                        type: "array",
                        title: "Saturday shifts",
                        required: ["start_time", "duration"],
                        items: [
                            {
                                type: "string",
                                title: "Start time",
                                description: "Enter shift starting time (format: HH:mm)"
                            },
                            {
                                type: "number",
                                title: "Duration",
                                description: "Enter shift duration"
                            }
                        ]
                    }
                },
                sunday: {
                    type: "array",
                    title: "Sunday",
                    description: "Click 'ADD ITEM' to add a shift",
                    items: {
                        type: "array",
                        title: "Sunday shifts",
                        required: ["start_time", "duration"],
                        items: [
                            {
                                type: "string",
                                title: "Start time",
                                description: "Enter shift starting time (format: HH:mm)"
                            },
                            {
                                type: "number",
                                title: "Duration",
                                description: "Enter shift duration"
                            }
                        ]
                    }
                },
            }
        },

        conditional: {
            "title": "Holiday or Workingday",
            "$ref": "#/definitions/holiday"
        },
    },

    definitions: {
        holiday: {
            title: "Holiday or Set Workingday",
            type: "object",
            properties: {
                "Set Holiday or Workingday?": {
                    type: "string",
                    enum: [
                        "Holiday",
                        "Workingday"
                    ],
                    default:"Holiday"
                }
            },
            dependencies:{
                "Set Holiday or Workingday?": {
                    oneOf:[
                        {
                            properties:{
                                "Set Holiday or Workingday?":{
                                    enum:[
                                      "Holiday"
                                    ]
                                },
                                holidays: {
                                    type: "array",
                                    title: "Holidays",
                                    description: "click 'ADD ITEM' to add holidays",
                                    items: {
                                        type: "array",
                                        title: "Holiday",
                                        items: [
                                            {
                                                type: "string",
                                                title: "Reason",
                                                description: "Enter reason"
                                            },
                                            {
                                                type: "string",
                                                required: [""],
                                                format: "datetime",
                                                description: "Start Date Time",
                                                title: "Date"
                                            },
                                            {
                                                type: "string",
                                                required: [""],
                                                format: "datetime",
                                                description: "End Date Time",
                                                title: "Date"
                                            }
                                        ]

                                    }
                                },
                            }
                        },
                        {
                            properties:{
                                "Set Holiday or Workingday?":{
                                    enum:[
                                        "Workingday"
                                    ]
                                },
                                workingdays: {
                                    type: "array",
                                    title: "Workingdays",
                                    description: "click 'ADD ITEM' to add workingday",
                                    items: {
                                        type: "array",
                                        title: "Workingday",
                                        items: [
                                            {
                                                type: "string",
                                                required: [""],
                                                format: "datetime",
                                                description: "Start DateTime",
                                                title: "Date"
                                            },
                                            {
                                                type: "string",
                                                required: [""],
                                                format: "datetime",
                                                description: "End DateTime",
                                                title: "Date"
                                            }
                                        ]

                                    }
                                },
                            }
                        }
                    ]
                }
            }
        }
    }
  }
;

