import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { useDispatch } from "react-redux";
import { showToastError } from "../../../../lib/toas";
import { exportPlanBoardData } from "../../../../store/actions";

export default function AlertDialog({
  handelOpen,
  startDate,
  endDate,
  selectedLines,
  groupId,
  selectedGroup,
  handleClose,
}) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const getLines = () => {
    // TODO  insted using filter use arr.slice to ommit
    // first element
    if (selectedLines.length === 1) {
      return false;
    }
    return selectedLines.filter((ids) => ids > 0);
  };

  // determine what  lines needed
  const manageLines = () => {
    const lineIds = getLines();
    if (lineIds) {
      return lineIds;
    }
    return selectedGroup.map(({ id }) => id);
  };
  const lines = manageLines();

  const convertToTimeStamp = (date) => new Date(date).getTime();

  const submitData = () => {
    const start = convertToTimeStamp(startDate)
    const end = convertToTimeStamp(endDate) + 24*60*60*1000 - 1; //to include the ending date
    if (start === end) {
      showToastError("You must select at least  end date!");
      return false;
    }

    try {
      dispatch(exportPlanBoardData(start, end, lines));
    } catch (error) {
      return false;
    }
    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlePopup = () => {
    setOpen(false);
    handelOpen(false);
  };
  React.useEffect(() => {
    const val = handelOpen();
    setOpen(val);
  }, []);


  return (
    <div>
      <Dialog
        open={open}
        onClose={handlePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Export csv"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lines.length > 0
              ? `Export csv for ${lines.length} lines`
              : "Export csv for all lines"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopup} className="btn_ btn_secondry">
            Close
          </Button>
          <Button onClick={submitData} className="btn_ btn_primary" autoFocus>
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
