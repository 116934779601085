import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Select from "@material-ui/core/Select";
import DatePicker from "react-datepicker";
import { blue } from '@material-ui/core/colors';
import { saveAs } from 'file-saver';
import { useDataProvider, useMutation, useRefresh, Loading, useQuery } from "react-admin";
import { showToastSuccess, showToastError } from "../../lib/toas"

const useStyles = makeStyles({
    dialogContainer: {
        margin: '20px',
        minHeight: '60vh'
    },
    inputs: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column'

    },
    selectInput: {
        margin: '10px'
    },
    btn: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

function SimpleDialog(props) {
    const classes = useStyles();
    const [selectedPlant, setSelectedPlant] = React.useState();
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [isLoading, setIsLoading] = React.useState();
    const dataProvider = useDataProvider();
    const [mutate, { mutateLoading, onSuccess, onError }] = useMutation();
    const { onClose, open, plants } = props;


    const handleChange = (event) => {
        const plantId = event.target.value
        setSelectedPlant(plantId)
    }

    const isValidInputs = () => {
        if (!(selectedPlant)) {
            showToastError("Please select a Plant")
            return false;
        }
        if (!(startDate)) {
            showToastError("Please select a Start Date")
            return false;
        }
        if (!(endDate)) {
            showToastError("Please select a End Date")
            return false;
        }
        return true
    }
    const viewTable = async () => {
        setIsLoading(true)
        if (!(isValidInputs())) {
            setIsLoading(false)
            return;
        }
        const downloadTimestamp = new Date()
        const dateTimeStr = downloadTimestamp.toLocaleString();

        mutate({
            type: 'BLOB_DOWNLOAD',
            resource: 'allocations',
            payload: {
                action: "exportCapacityLoading",
                method: "POST",
                body: {
                    plants: selectedPlant,
                    startDate: startDate.toLocaleDateString(),
                    endDate: endDate.toLocaleDateString()
                },
                fileName: dateTimeStr.concat("-").concat("Capacity_Utilization.xlsx")
            }
        }, {
            onFailure: (e) => {
                setIsLoading(false)
                showToastError("Capacity Loading Data Retrieval Failed:" + e.body);
            },
            onSuccess: (e) => {
                setIsLoading(false)
                showToastSuccess("Capacity Utilization Report Export Successfull");
                onClose()
            }
        })
    }

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} maxWidth="lg" aria-labelledby="simple-dialog-title" open={open}>
            <div className={classes.dialogContainer}>
                <DialogTitle id="simple-dialog-title">Export Capacity Utilization</DialogTitle>
                <div className={classes.inputs}>
                    <Select native className={classes.selectInput}
                        onChange={handleChange}
                        value={selectedPlant}

                    >
                        <option value="">Select a Plant</option>
                        {plants?.map(({ id, code }) => (
                            <option value={id}>{code}</option>
                        ))}
                    </Select>


                    <DatePicker className={classes.selectInput}
                        dateFormat="MMMM yyyy dd"
                        isClearable

                        placeholderText="Choose a Start Date"
                        selected={startDate} onChange={(date) => setStartDate(date)}
                    />
                    <DatePicker className={classes.selectInput}
                        dateFormat="MMMM yyyy dd"
                        isClearable

                        placeholderText="Choose a End Date"
                        selected={endDate} onChange={(date) => setEndDate(date)}
                    />
                </div>
                <div className={classes.btn}>
                    <Button className="btn_ btn_primary" onClick={viewTable}>Export</Button>
                </div>
                {isLoading ?
                    <Loading /> : null}
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export function ExportModal({ plants }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <div>
            <Button className="btn_ btn_primary" onClick={handleClickOpen}>
                Export
            </Button>
            <SimpleDialog open={open} plants={plants} onClose={handleClose} />
        </div>
    );
}