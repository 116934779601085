import React from "react";
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";
const validate = (values) => {
  const errors = {};

  if (!values.demand) {
    errors.demand = ["produced demand is required"];
  }
  if (!values.itemOperation) {
    errors.itemOperation = ["ItemOperation is required"];
  }
  if (!values.planned_start_date) {
    errors.planned_start_date = ["planned start date is required"];
  }
  if (!values.planned_end_date) {
    errors.planned_end_date = ["planned end date is required"];
  }
  return errors;
};

export const EditDemandOperation = (props) => {
  const Title = ({ record }) => {
    return (
      <span>Edit Demand Operation | {record ? `${record.code} ` : ""}</span>
    );
  };

  const customToast = useCustomToast(props);
  return (
    <Edit
      {...props}
      title={<Title />}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm validate={validate}>
        <ReferenceInput
          label="produced demand"
          source="produced_demand"
          reference="demand"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput
          label="item operation"
          source="item_operation"
          reference="itemOp"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <DateTimeInput source="planned_start_date" />
        <DateTimeInput source="planned_end_date" />
        <DateTimeInput source="rm_ready_date" />
        <TextInput source="duration" />
        <TextInput source="quantity" />
        <TextInput source="planned_quantity" />
        <BooleanInput source="is_planned" />
        <DateTimeInput source="required_time_min" />
        <DateTimeInput source="required_time_max" />
        <TextInput source="attributes.color" />
        <TextInput source="attributes.color" />
        <TextInput source="attributes.mold_type" />
        <TextInput source="configuration" />
      </SimpleForm>
    </Edit>
  );
};
