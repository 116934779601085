import { FormControl, InputLabel, makeStyles, MenuItem, Select, SvgIcon, TablePagination, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';



const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
    table: {
      minHeight: 300
    }
}));

export const MySelect = ({menuItems = [], value, onChange, label}) => {
    const classes = useStyles()

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">{label ? label : 'Data Not Available'}</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={value}
                onChange={onChange}
                label={label ? label : 'Data not available'}
            >
                {menuItems.length === 0 ? 
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem> : null
                }
                {menuItems.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export const MySvgIcon = (props) => {
    return (
        <SvgIcon viewBox='0 0 949.434 949.434' style={{color: 'cyan'}} >
            <path d="M949.434 224.27l-587.341 0.225l8.312-93.217H200.27v65h49.888h49.192l-2.699 30.287l-14.007 157.11H0v65h276.85l-4.283 48.044H143.529v65h123.243l-2.67 29.946h553.891L949.434 224.27z M335.155 526.666l6.953-77.99h53.195v-65h-47.4l8.396-94.179l500.834-0.191l-84.919 237.36H335.155z" />
            <rect x="78.951" y="274.902" width="174.728" height="65"/>
            <path d="M626.999,786.956c16.736,19.827,42.046,31.199,69.442,31.199c53.688,0,102.879-41.885,111.985-95.355
			c4.705-27.622-2.319-54.983-19.272-75.068c-16.736-19.827-42.046-31.199-69.442-31.199h-374.45
			c-53.688,0-102.879,41.885-111.986,95.354c-4.705,27.623,2.32,54.985,19.273,75.07c16.736,19.827,42.046,31.199,69.441,31.199
			c53.688,0,102.878-41.885,111.985-95.355c2.408-14.139,1.736-28.207-1.761-41.269h185.457c-4.72,9.498-8.132,19.695-9.948,30.354
			C603.021,739.509,610.045,766.87,626.999,786.956z M365.033,689.656c4.643,5.501,6.372,13.396,4.867,22.229
			c-3.745,21.984-26.133,41.271-47.909,41.271c-8.447,0-15.284-2.811-19.771-8.125c-4.644-5.501-6.372-13.396-4.867-22.23
			c3.745-21.983,26.133-41.269,47.909-41.269C353.709,681.531,360.546,684.341,365.033,689.656z M739.483,689.656
			c4.643,5.501,6.372,13.396,4.866,22.229c-3.744,21.984-26.132,41.271-47.908,41.271c-8.448,0-15.284-2.811-19.771-8.125
			c-4.644-5.501-6.372-13.396-4.867-22.23c3.745-21.983,26.132-41.269,47.909-41.269
			C728.159,681.531,734.996,684.341,739.483,689.656z"/>
        </SvgIcon>
    )
}



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 10,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);


export function CustomizedTables({rows}) {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(8)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <>
    <TableContainer className={classes.table} >
      <Table aria-label="customized table"  >
        <TableHead>
          <TableRow>
            <StyledTableCell>Order Code</StyledTableCell>
            <StyledTableCell align="center">Product</StyledTableCell>
            <StyledTableCell align="center">Completed qty</StyledTableCell>
            <StyledTableCell align="center">Total qty</StyledTableCell>
            <StyledTableCell align="center">Delay</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <StyledTableRow key={row.order_code}>
              <StyledTableCell component="th" scope="row">
                {row.order_code}
              </StyledTableCell>
              <StyledTableCell align="center">{row.product}</StyledTableCell>
              <StyledTableCell align="center">{row.completed_qty}</StyledTableCell>
              <StyledTableCell align="center">{row.quantity}</StyledTableCell>
              <StyledTableCell align="center">{Math.floor(row.delay)} days</StyledTableCell>
            </StyledTableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[8, 12]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </>
  );
}