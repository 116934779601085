import React from "react";
import { TableComponent } from "./components/TableComponent";

export const Transfer = ({ data, permissions }) => {
  return (
    <>
      <div>
        <h3>
          Transfer Time stamps{" "}
          {permissions === "ADMIN" ? "for Admin  users" : ""}{" "}
        </h3>
        <TableComponent payload={data} name={"T"} />
      </div>
    </>
  );
};
