import React, { useState } from "react";
import {usePermissions} from "react-admin"
import { API_URL } from "../../constants";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { showToastSuccess } from "../../lib/toas";
import ReadyMadeUppersSampleRequestForm from "./forms/ReadyMadeUppersSampleRequestForm";
import NewDevelopmentInquirySheetForFlipFlops from "./forms/NewDevelopmentInquirySheetForFlipFlops";
import SoleSampleRequestForm from "./forms/SoleSampleRequestForm";
import TrimsSampleRequestForm from "./forms/TrimsSampleRequestForm";
import FabricSampleRequestForm from "./forms/FabricSampleRequestForm";
import InkSampleRequestForm from "./forms/InkSampleRequestForm";
import LeatherSampleRequestForm from "./forms/LeatherSampleRequestForm";
import CompoundSampleRequestForm from "./forms/CompoundSampleRequestForm";
import EXP from "./forms/DSS/EXP";
import ANSL from "./forms/DSS/ANSL";
import OTB from "./forms/OTB";
import SampleRequisition from "./forms/DSS/Sample-Requisition";
import TechnicalReviewForm from "./forms/TechnicalReviewForm";
import PVCPUSampleRequestForm from "./forms/PVCPUSampleRequestForm";
import RiskAnalysisForm from "./forms/RiskAnalysisForm";

const useStyles = makeStyles((theme) => ({
  copyBtn: {
    margin: "0px 10px",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));

export const getPdfUrl = (fileName) => {
  const fileType = "taskFormUpload";
  const endpointUrl = `tna/itemTasks/fileDownload/?`;
  const formsDownloadUrl = `${API_URL}/${endpointUrl}type=${fileType}&file_name=`;
  return formsDownloadUrl.concat(fileName);
};

const FormDownload = ({ task, isParent = false }) => {
  const uploadedForms = task?.task_data?.form_data;
  const rejectReasons = task?.task_data?.rejected_reasons || {};
  const addedComments = task?.task_data?.comments || {};
  const submittedFormData = task?.submitted_form_data;

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    showToastSuccess("Url Copied");
  };

  return (
    <div style={{ margin: "20px 0px" }}>
      Submitted Forms:
      <ul>
        {uploadedForms
          ? uploadedForms.map((fileOb) => (
              <FormEntry
                task={task}
                fileOb={fileOb}
                copyUrl={copyUrl}
                rejectReasons={rejectReasons}
                addedComments={addedComments}
                submittedFormData={submittedFormData}
                isParent={isParent}
              />
            ))
          : null}
      </ul>
    </div>
  );
};

const FormEntry = ({
  task,
  fileOb,
  copyUrl,
  rejectReasons,
  addedComments,
  submittedFormData,
  isParent,
}) => {
  const classes = useStyles();
  const [showEditForm, setshowEditForm] = useState(false);
  const [showNewIQParentTaskForm, setShowNewIQParentTaskForm] = useState(false);
  const { permissions } = usePermissions();
  const rejectMsg = rejectReasons[fileOb];
  const approveMsg = addedComments[fileOb];
  const formName = fileOb.split("--")[1];
  return (
    <li key={fileOb}>
      <span className={classes.linkContainer}>
        <p>
          {formName}
          <a
            style={{marginLeft: "1em"}}
            href={getPdfUrl(fileOb)}
            rel="noopener noreferrer"
            target="_blank"
            download="true"
          >
            {fileOb}
          </a>
          <Button
            className={classes.copyBtn}
            variant="contained"
            color="primary"
            onClick={() => {
              copyUrl(getPdfUrl(fileOb));
            }}
          >
            Copy Url
          </Button>

          {rejectMsg && (
            <>
              <span style={{ color: "red" }}>{`  Rejected ${
                rejectMsg === true ? "" : ": " + rejectMsg
              }`}</span>
            </>
          )}
          
          {isParent && !rejectMsg && (
            <>
              <span style={{ color: "#08c40b"}}>{`  Approved `}</span>
            </>
          )}

          {isParent && approveMsg && (
            <>
              <span style={{ color: "#08c40b"}}>{`: ${ approveMsg === true ? " " : approveMsg}`}</span>
            </>
          )}

          {!isParent && rejectMsg && permissions?.permissions.includes("tna_edit_forms") && 
              <Button
              variant="contained"
                className={classes.copyBtn}
                style={{ color: showEditForm ? "#FB5B73" : null }}
                color="primary"
                onClick={() => {
                  setshowEditForm(!showEditForm);
                }}
              >
                {showEditForm ? "Dismiss" : "Edit Form"}
              </Button>
              }

          {isParent && !rejectMsg && submittedFormData[fileOb] && formName === "NewDevelopmentInquirySheetForFlipFlops" &&permissions?.permissions.includes("tna_edit_pdf") &&  (
            <Button
            variant="contained"
            className={classes.copyBtn}
            style={{ color: showNewIQParentTaskForm ? "#FB5B73" : null }}
            color="primary"
            onClick={() => {
              setShowNewIQParentTaskForm(!showNewIQParentTaskForm);
            }}
          >
            {showNewIQParentTaskForm ? "Dismiss" : "Edit PDF"}
          </Button>
          )}
        </p>
      </span>
      {showNewIQParentTaskForm && (
        <NewDevelopmentInquirySheetForFlipFlops toEdit={true} task={task} prevFormData={submittedFormData[fileOb]} appendParentCode={true} prevFormName={fileOb.split(".pdf")[0]} formName={formName}/>
      )}
      {showEditForm && (
        <GetRightForm
          task={task}
          prevFormData={submittedFormData[fileOb]}
          formName={formName}
          prevFormName={fileOb.split(".pdf")[0]}
        />
      )}
    </li>
  );
};

const GetRightForm = (props) => {
  switch (props.formName) {
    case "RiskAnalysisForm":
      return <RiskAnalysisForm {...props} toEdit={true} />;
    case "ReadyMadeUppersSampleRequestForm":
      return <ReadyMadeUppersSampleRequestForm {...props} toEdit={true} />;
    case "NewDevelopmentInquirySheetForFlipFlops":
      return <NewDevelopmentInquirySheetForFlipFlops {...props} toEdit={true} />;
    case "SoleSampleRequestForm":
      return <SoleSampleRequestForm {...props} toEdit={true} />;
    case "TrimsSampleRequestForm":
      return <TrimsSampleRequestForm {...props} toEdit={true} />;
    case "FabricSampleRequestForm":
      return <FabricSampleRequestForm {...props} toEdit={true} />;
    case "InkSampleRequestForm":
      return <InkSampleRequestForm {...props} toEdit={true} />;
    case "LeatherSampleRequestForm":
      return <LeatherSampleRequestForm {...props} toEdit={true} />;
    case "CompoundSampleRequestForm":
      return <CompoundSampleRequestForm {...props} toEdit={true} />;
    case "EXP":
      return <EXP {...props} toEdit={true} />;
    case "Ansel":
      return <ANSL {...props} toEdit={true} />;
    case "OTB":
      return <OTB {...props} toEdit={true} />;
    case "DSS":
      return <SampleRequisition {...props} toEdit={true} />;
    case "TechnicalReviewForm":
      return <TechnicalReviewForm {...props} toEdit={true} />;
    case "PVCPUSampleRequestForm":
      return <PVCPUSampleRequestForm {...props} toEdit={true} />;
    default:
      return null;
  }
};

export default FormDownload;
