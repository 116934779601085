import { BULK_ACTION_CLEAN, BULK_ACTION_SAVE, BULK_ACTION_SAVE_ERROR, BULK_ACTION_SAVE_SUCCESS, BULK_ACTION_SET_DATA } from "../types"

export const bulkActionSetData = (key: string, data: any, meta: any) => {
    return { type: BULK_ACTION_SET_DATA, payload: { key, data, meta } }
}

export const bulkActionClean = () => {
    return { type: BULK_ACTION_CLEAN }
}


export const bulkActionSave = (key: string, data: any, meta: any) => {
    return { type: BULK_ACTION_SAVE, payload: { key, data, meta } }
}

export const bulkActionSaveSuccess = (key: string, response: any) => {
    return { type: BULK_ACTION_SAVE_SUCCESS, payload: { key, response } }
}

export const bulkActionSaveError = (key: string, response: any) => {
    return { type: BULK_ACTION_SAVE_ERROR, payload: { key, response } }
}