import React from "react";
import {
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { SaveButton, DeleteButton, Toolbar, usePermissions } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../components/Confirmation";
import { CustomToolbar } from "../../components/CustomToolbar";
import { CustomTopToolbar } from "../../components/CustomTopToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";

//const redirect = (basePath, id, data) => `/planBoards/${data.plan_board}/show/tool`

export const WorkstationsEdit = (props) => {
  const { permissions } = usePermissions();

  const validateWorkstation = (values) => {
    const errors = {};
    if (!values.plant) {
      errors.plant = "Plant is required";
    }
    if (!values.plan_board) {
      errors.plan_board = "Plan board is required";
    }
    if (!values.code) {
      errors.code = "Code is required";
    }
    if (!values.compatible_workstations) {
      errors.compatible_workstations = "Compatible Workstations is required";
    }
    return errors;
  };

  const Title = ({ record }) => {
    return <span>Edit Workstation | {record ? `${record.code} ` : ""}</span>;
  };
  const customToast = useCustomToast(props);
  return (
    <Edit
      actions={<CustomTopToolbar name="workstation" />}
      validate={validateWorkstation}
      {...props}
      title={<Title />}
      className={"col-md-6 "} //mt-5
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      {/* <Edit {...props} title={<Title /> }> */}

      <SimpleForm
        redirect="list"
        toolbar={
          <CustomToolbar need={true} text="workstations" name="workstations" />
        }
      >
        <ReferenceInput
          source="plant"
          reference="plants"
          label={<ReqInputField name="Plants" />}
        >
          <SelectInput optionText="name" translateChoice={false} />
        </ReferenceInput>
        <ReferenceInput
          source="plan_board"
          reference="planBoards"
          label={<ReqInputField name="Plan Board" />}
        >
          <SelectInput optionText="code" translateChoice={false} />
        </ReferenceInput>
        <TextInput source="code" label={<ReqInputField name="Code" />} />
        <ReferenceArrayInput
          source="compatible_workstations"
          reference="workstations"
          label="Compatible Workstations"
        >
          <SelectArrayInput optionText="code" translateChoice={false} />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};
