import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-admin";
import { useDispatch } from "react-redux";
import { format_to_date_time } from "../../lib/date_time";
import { markNotificationRead } from "../../store/actions/notificationActions";
import { revertTransfer } from "../../store/actions/transferActions";
const useStyles = makeStyles({
  root: {
    width: 270,
    padding: 20,
    marginBottom: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
//{demand_operations: rowData.demand_ops, transfer_id: rowData.Id}
const ReadNotify = ({ notify }) => {
  //  const [notifycations, setnotifycation] = React.useState(notify);
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <div>
      {notify.length ? (
        notify.map((data) => (
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Plant {data.plant_id}
              
              </Typography>
              {/* <Typography variant="h5" component="h2">
              lent
            </Typography> */}
              <Typography variant="body2" component="p">
                Create date {format_to_date_time(data.time_stamp)}
                <br />
                Transfer date {data.details.data.received_date}
              </Typography>
            </CardContent>
            <CardActions>
              <div style={{ display: "flex" }}>
                <Button
                  size="small"
                  variant="contained"
                  class="btn-success py-2 px-4 mb-3 mr-3 ml-3"
                  color="secondary"
                  onClick={() => dispatch(markNotificationRead(data.id))}
                  style={{ justifyContent: "flexStart", marginRight: 20 }}
                  component={Link}
                  to={{
                    pathname: "/transfers",
                  }}
                >
                  Aprove
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 15 }}
                  onClick={() =>
                    dispatch(
                      revertTransfer({
                        demand_operations: null,
                        transfer_id: data.details.data.transfer_id,
                      })
                    )
                  }
                >
                  decline
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ justifyContent: "flexEnd" }}
                  onClick={() => dispatch(markNotificationRead(data.id))}
                >
                  View later
                </Button>
              </div>
            </CardActions>
          </Card>
        ))
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              No Notifications
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ReadNotify;
