import React, { useMemo, useState } from 'react'
import { Line, } from 'react-chartjs-2';
import _ from "lodash"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { SupplyChainState, TimeBucket } from './interfaces';
import { getCapacityForTimeBucket, getDemandAndConsumptionForTimeBucket, getEndingStockForTimeBucket, getMinCapacityForTimeBucket, getOpenningStockForTimeBucket, getPlannedQtyForTimeBucket, getSalseLossPerItemForTimeBucket, getSupplyForTimeBucket } from './lib';
import { useSelector } from 'react-redux';
import { getSupplyChainData } from './reducer/selectors';



type Props = {
    data: SupplyChainState
}

// 'rgba(54, 162, 235, 1)',
// 'rgba(255, 206, 86, 1)',
// 'rgba(75, 192, 192, 1)',
// 'rgba(153, 102, 255, 1)',
// 'rgba(255, 159, 64, 1)'

const colors = [
    {
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
    }, 
    {

        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
    },
    {

        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
    },
    {
        backgroundColor: 'rgba(72, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
    },
    {
        backgroundColor: 'rgba(255,165, 0, 0.2)',
        borderColor: 'orange',
    }
]

export const getColor = (i: number) => {
    return colors[i % colors.length]
}

export const getChartData = (items: string[], data: SupplyChainState, buckets: (TimeBucket[] | null) = null) => {

    // const getTimeRecord = (b: TimeBucket) => data.item_time_bucket.find(d => d.time_bucket_id == b.id && d.item_id == item)
    const timeBuckets = buckets || data.time_buckets
    const mapForBuckets = (func: any) => timeBuckets.map(b => _.sumBy(items, item => func(data, b.id, item)))

    const demandData = mapForBuckets(getDemandAndConsumptionForTimeBucket),
        supplyData = mapForBuckets(getSupplyForTimeBucket),
        plannedData = mapForBuckets(getPlannedQtyForTimeBucket),
        openningData = mapForBuckets(getOpenningStockForTimeBucket),
        salesLossData = mapForBuckets(getSalseLossPerItemForTimeBucket),
        endingData = mapForBuckets(getEndingStockForTimeBucket);
    const capacityData = timeBuckets.map(b => getCapacityForTimeBucket(data, b.id, items)),
        minCapacityData = timeBuckets.map(b => getMinCapacityForTimeBucket(data, b.id, items));

    const datasets = [
        {
            label: "Openning Stock",
            data: openningData,
            backgroundColor: 'rgba(255,165, 0, 0.2)',
            borderColor: 'orange',
        },
        {
            label: "Demand / Consumption",
            data: demandData,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
        },
        {
            label: "Salse Loss",
            data: salesLossData,
            backgroundColor: 'rgba(255, 0, 0, 0.2)',
            borderColor: 'rgba(255, 0, 0, 1)',
        },
        {
            label: "Planned",
            data: plannedData,
            backgroundColor: 'rgba(72, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
        },
        {
            label: "Supply",
            hidden: true,
            data: supplyData,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
        },
        {
            label: "Capacity",
            hidden: true,
            data: capacityData,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'purple',
        },
        {
            label: "Min. Operational Capacity",
            hidden: true,
            data: minCapacityData,
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            borderColor: 'darkgreen',
        },
        {
            label: "Ending Stock",
            hidden: true,
            data: endingData,
            backgroundColor: 'rgba(101, 67, 33, 0.2)',
            borderColor: 'brown',
        },
    ]

    const result = {
        labels: timeBuckets.map(b => b.label),
        datasets: datasets.map(entry => ({ ...entry, borderWidth: 1, tension: 0.1, pointHitRadius: 6, pointRadius: 2, pointHoverRadius: 4 }))
    }
    return result
}

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }],
        xAxes: [
            {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 20
                }
            }
        ]

    },
}


export default function TimeSeriesChart() {
    const data = useSelector(getSupplyChainData)
    const [item, setItem] = useState("A" as string)
    const allItems = useMemo(() => data.items.map(item => item.id), [data])

    return (
        <div>
            <ItemSelector allItems={allItems} value={item} onChange={setItem} />
            <div style={{ height: 400 }}>
                <Line options={options} data={getChartData([item], data)} />
            </div>
        </div>
    )
}

export const Chart = ({ items, buckets }: { items: string[], buckets?: (TimeBucket[]) }) => {

    const data = useSelector(getSupplyChainData)
    return <Line options={options} data={getChartData(items, data, buckets)} />
}


const ItemSelector = ({ value, onChange, allItems }: any) => {
    const handleChange = (e: any) => onChange(e.target.value)
    return <FormControl component="fieldset">
        <FormLabel component="legend">Item</FormLabel>
        <div style={{ height: 100, overflowY: 'scroll' }}>
            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                {allItems.map((item: string) => <FormControlLabel value={item} control={<Radio />} label={item} />)}
            </RadioGroup>
        </div>
    </FormControl>
}