import React, { useEffect } from 'react';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import './fileUpload.css'
import { useMutation, useRefresh, Mutation, FileInput, Loading, ImageField, FileField, SimpleForm } from 'react-admin';
import { showToastSuccess, showToastError } from "../../../lib/toas"
import TextField from '@material-ui/core/TextField';
import { TextareaAutosize, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    body: {
        width: '199%'
    },
    fileTagContainer: {
        display: 'flex',
    },
    fileTag: {
        padding: theme.spacing(2)
    },
    close: {
        cursor: 'pointer',
        color: 'red',
        fontWeight: 'bold'

    }

}));



const EmailSend = ({ id = null, }) => {
    const classes = useStyles();
    const refresh = useRefresh();
    const [to, setTo] = React.useState([]);
    const [cc, setCC] = React.useState([]);
    const [subject, setSubject] = React.useState("");
    const [body, setBody] = React.useState("");

    const [mutate, { loading, onSuccess, onError }] = useMutation();
    const params = useParams()

    const [uploadedFile, setUploadedFile] = React.useState([]);
    const [isImage, setIsImage] = React.useState(false)
    // const [imageCode, setImageCode] = React.useState("")

    const [formData, setFormData] = React.useState(new FormData())

    let history = useHistory();
    if (loading) return <Loading />;

    const fileHandler = (event) => {
        event.persist();
        if (event.target?.files[0]) {
            if (uploadedFile != undefined) {
                setUploadedFile(uploadedFile => [...uploadedFile, event.target.files[0]]);
            }
        } else {
            return false
        }


        const uploaded = event.target.files[0]
        let uploadedFileType = uploaded?.type
        if (uploadedFileType != undefined) {
            uploadedFileType = uploadedFileType.split("/")[0]
        }

        console.log(uploadedFile)
        if (uploadedFileType == "image") {
            setIsImage(true)
        } else if ((uploadedFileType == "application")) {
            setIsImage(false)
        }

    };
    const submit = event => {
        if (to == "" || to == null || subject == "" || subject == null) {
            showToastError("Please add recipient and a subject");
            return false
        }
        formData.append("templateItemTaskId", id)
        formData.append("to", to)
        formData.append("cc", cc)
        formData.append("body", body)
        formData.append("subject", subject)
        uploadedFile.forEach(file => formData.append('attachment', file))

        mutate({
            type: 'TNA_FORM_ACTION',
            resource: 'itemTasks',
            payload: {
                action: "sendEmails",
                method: "POST",
                body: formData,
            }
        }, {
            onFailure: (e) => {
                showToastError("Email Sending Failed:" + e?.body);
            },
            onSuccess: (e) => {
                showToastSuccess("Email Sent Successfully");
                setFormData(new FormData())
                setTo([])
                setCC([])
                setSubject("")
                setUploadedFile([])
            }
        });
    }

    const ImageThumb = ({ image }) => {
        return <img src={image ? URL.createObjectURL(image) : null} alt={image?.name} />;
    };

    const UploadedAttachmentFile = ({ file }) => {

        const removeFile = (fileName) => {
            var copyUploadedFile = [...uploadedFile]
            var filteredFiles = copyUploadedFile.filter(x => x?.name != fileName)
            setUploadedFile(filteredFiles)
        }

        return (
            <div className={classes.fileTagContainer}>
                <div className={classes.fileTag}>{file?.name}</div>
                <div className={classes.close} data-file={file?.name} onClick={() => removeFile(file?.name)}>x</div>
            </div>
        )
    }

    return (
        <div>
            <Grid container direction="column" spacing={3}>
                <Grid xs={6}>
                    <TextField label="To" onChange={(e) => setTo(e.target.value)}></TextField>
                </Grid>
                <Grid xs={6}>
                    <TextField label="CC" onChange={(e) => setCC(e.target.value)}></TextField>
                </Grid>
                <Grid xs={6}>
                    <TextField label="Subject" onChange={(e) => setSubject(e.target.value)}></TextField>
                </Grid>
                <Grid xs={6}>
                    <TextareaAutosize label="Body" aria-label="minimum height" rowsMin={5} placeholder="Body" className={classes.body} onChange={(e) => setBody(e.target.value)}></TextareaAutosize>
                </Grid>
                <hr></hr>
                <div className="files">
                    <label>Upload Attachments</label>
                    <input type="file" onChange={fileHandler} />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submit}
                    >
                        {" "}
                      Send
                        </Button>

                    {uploadedFile != null ?
                        <Grid item xs={12}>
                            {uploadedFile.map(function (file) {
                                return (<UploadedAttachmentFile file={file}></UploadedAttachmentFile>)
                            })}
                        </Grid> : null}
                </div>
            </Grid>
        </div>
    );
}



export default EmailSend;;