import React from "react";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Create,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
  AutocompleteArrayInput,
} from "react-admin";
import { redirect } from "../../lib/redirect";
import { Typography } from "@material-ui/core";
import PermissionSelector from "./PermissionSelector";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";


const validateRole = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = "Code is required";
  }
  if (!values.access_level) {
    errors.access_level = "access level is required";
  }
  if (values.access_level!=="ADMIN" && !values.site) {
    errors.site = "site is required";
  }
  if (["PLANT","BASIC"].includes(values.access_level) && !values.plant) {
    errors.plant = "plant is required";
  }
  try {
    if (values.access_level==="BASIC" && values.plan_board_group.length===0) {
      errors.plan_board_group = "plan board group  is required";
    }
  } catch (err) {
    errors.plan_board_group = "plan board group  is required";
  }
  try {
    if (values.access_level==="SUB" && values.plan_boards.length===0){
      errors.plan_boards = "plan board is required";
    }
  } catch (err) {
    errors.plan_boards = "plan board is required";
  }
  return errors;
};

export const CreateRole = (props) => {
  const { permissions } = usePermissions();
  const usersSite = localStorage.getItem("site");
  const usersPlant = localStorage.getItem("plant");

  const accessLevelChoicesArray = [
    permissions?.access_level === "ADMIN"
      ? [
          { id: "ADMIN", name: "ADMIN" },
          { id: "SITE", name: "SITE" },
          { id: "PLANT", name: "PLANT" },
          { id: "SUB", name: "SUB" },
          { id: "BASIC", name: "BASIC" },
          { id: "TNA_ADMIN", name: "TNA ADMIN" },
          { id: "TNA_CLUSTER_MANAGER", name: "TNA CLUSTER MANAGER" },
          { id: "TNA_DEP_ADMIN", name: "TNA DEPARTMENT ADMIN" },
          { id: "TNA_MANAGER", name: "TNA MANAGER" },
          { id: "TNA_PLANNER", name: "TNA PLANNER" },
          { id: "TNA_USER", name: "TNA USER" },
        ]
      :
    ["SITE"].includes(permissions?.access_level)
      ? [
          { id: "PLANT", name: "PLANT" },
          { id: "SUB", name: "SUB" },
        ]
      :
    permissions?.access_level === "TNA_ADMIN"
      ? [
          { id: "TNA_CLUSTER_MANAGER", name: "TNA CLUSTER MANAGER" },
          { id: "TNA_DEP_ADMIN", name: "TNA DEPARTMENT ADMIN" },
          { id: "TNA_MANAGER", name: "TNA MANAGER" },
          { id: "TNA_PLANNER", name: "TNA PLANNER" },
          { id: "TNA_USER", name: "TNA USER" },
        ]
      :
      permissions?.access_level === "TNA_CLUSTER_MANAGER"
      ? [
          { id: "TNA_DEP_ADMIN", name: "TNA DEPARTMENT ADMIN" },
          { id: "TNA_MANAGER", name: "TNA MANAGER" },
          { id: "TNA_PLANNER", name: "TNA PLANNER" },
          { id: "TNA_USER", name: "TNA USER" },
        ]:
    permissions?.access_level === "TNA_DEP_ADMIN"
      ? [
          { id: "TNA_MANAGER", name: "TNA MANAGER" },
          { id: "TNA_PLANNER", name: "TNA PLANNER" },
          { id: "TNA_USER", name: "TNA USER" },
        ]
      : null,
  ];
  const accessLevelChoices = accessLevelChoicesArray.flat();
  //console.log("choices array after processing: ", accessLevelChoices);

  const customToast = useCustomToast({ ...props, redirect: redirect });
  const Title = ({ record }) => {
    return (
      <span>Create New Role</span>
    );
  };

  return (
    <Create
      className={"col-md-8 mt-2"}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      {...props}
      title={<Title/>}
    >
      <SimpleForm
        toolbar={<CustomToolbar need={false} name="roles" text="Role" />}
        validate={validateRole}
      >
        <TextInput
          source="code"
          label={<ReqInputField name="Code" />}
          title="This field is mandatory"
        />
        <SelectInput
          label="Access level"
          source="access_level"
          choices={accessLevelChoices}
          translateChoice={false}
        />

        {permissions?.access_level === "ADMIN" ? (
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.access_level &&
              formData.access_level !== "ADMIN" && (
                <ReferenceInput 
                  source="site" 
                  reference="sites" 
                  label={<ReqInputField name="Site" />}
                  >
                  <SelectInput
                    optionText="code"
                    label="site"
                    translateChoice={false}
                  />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
        ) : <ReferenceInput
        source="site"
        reference="sites"
        label={<ReqInputField name="Site" />}
      >
        <SelectInput optionText="code" label="Site" defaultValue={usersSite} disabled/>
      </ReferenceInput>}

        {["ADMIN", "SITE"].includes(permissions?.access_level) === true ? (
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.access_level &&
              ["PLANT", "BASIC"].includes(formData.access_level) === true && (
                <ReferenceInput
                  source="plant"
                  reference="plants"
                  label={<ReqInputField name="Plant" />}
                  filter={{ site: formData.site }}
                >
                  <SelectInput optionText="name" label="plant" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
        ) : null}
        {["ADMIN", "SITE", "PLANT"].includes(permissions?.access_level) && (
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData &&
              formData.access_level &&
              formData.access_level === "SUB" && (
                <ReferenceArrayInput
                  source="plan_boards"
                  reference="planBoards"
                  filter={{ site: usersSite, plant: usersPlant }}
                  //label="plan boards"
                  className={"col-md-4"}
                >
                  <AutocompleteArrayInput
                    optionText="code"
                    //label="Planboards"
                    label={<ReqInputField name="Plan Boards" />}
                    
                  />
                </ReferenceArrayInput>
              )
            }
          </FormDataConsumer>
        )}

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.access_level &&
            [
              "TNA_DEP_ADMIN",
              "TNA_MANAGER",
              "TNA_PLANNER",
              "TNA_USER",
            ].includes(formData.access_level) && (
              <ReferenceInput
                source="tna_department"
                reference="tna_departments"
                label="Department"
              >
                <SelectInput optionText="code" label="Department" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.access_level &&
            formData.access_level === "BASIC" && (
              <ReferenceArrayInput
                source="plan_board_group"
                reference="boardGroup"
                label="Planboard group"
              >
                <SelectArrayInput 
                optionText="code" 
                label={<ReqInputField name="Planboard Group" />}
                />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>
        <ReferenceArrayInput
          source="permissions"
          reference="permissions"
          label="Permissions"
          perPage={300}
        >
          {/* <SelectArrayInput optionText="code" /> */}
          <PermissionSelector translateChoice={false} optionText="code" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
