import React from 'react'
import { API_URL, APP_VERSION, PRIMARY_COLOR } from "../constants"

const process_env = process.env

export default function AppInfo() {
    const text = JSON.stringify({
        APP_VERSION, API_URL, PRIMARY_COLOR,
        env: { dynamic_env: window._env_, process_env: process_env }
    }, undefined, 2)
    return (
        <div>
            <pre id="q1" style={{ margin: 0 }}>
                {text}
            </pre>
        </div>
    )
}
