import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";

const DateInput = ({ disabled, value, onChange, label }) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        value={value}
        onChange={onChange}
        format="DD/MM/YYYY"
        disabled={disabled}
        variant="inline"
        emptyLabel="Choose date"
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateInput;
