import React, { useState } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
// import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';
// // import { getProcessingTasks } from '../../store/selectors/tnaTaskSelectors';
import { connect } from "react-redux";
import { getTodoTasks, getProcessingTasks, getCompletedTasks, getTNATaskLoading } from "../../store/selectors/tnaTaskSelectors";
import { forwardRef } from 'react';
import { Error, Loading, usePermissions } from "react-admin";
import AddBox from '@material-ui/icons/AddBox';
import FormControlLabel from "@material-ui/core/FormControlLabel"
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { loadALLTasks, startTask } from "../../store/actions/tnaTaskActions";
// import ViewColumn from '@material-ui/icons/View';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import noTasks from './layout/images/noData2.png';
import _ from 'lodash'
import { styled } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch"
import { theme as Theme } from "../../layout/theme";
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography'
import FormDownload from './formDownload'
import {
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  PlayCircleFilledWhiteIcon: forwardRef((props, ref) => <PlayCircleFilledWhiteIcon {...props} ref={ref} />),
  TransferWithinAStationIcon: forwardRef((props, ref) => <TransferWithinAStationIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />)
  // ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: '4%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  timeline: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '26px',
    flexDirection: 'column',
    marginTop: '10px'
  },
  status: {
    marginLeft: '3%',
    borderColor: '#00ACEE',
    borderStyle: 'solid',
    borderRadius: '12%',
    padding: '0.2%'
  },
  toggle: {
    margin: '20px 0px 5px'
  }
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: Theme.palette.primary.main,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: Theme.palette.primary.main,
  },
}));

function TaskList({ toDo, processing, completed, onLoad, loading }) {

  const location = useLocation()
  const classes = useStyles();
  const dispatch = useDispatch()
  const [taskMode, setTaskMode] = React.useState([]);
  const [tasksSet, setTasksSet] = React.useState([]);
  const [flowType, setFlowType] = React.useState("IQ");
  const [startedTask, setStartedTask] = React.useState();

  const [flowTypeSet, setFlowTypeSet] = React.useState(new Set());
  React.useEffect(() => {
    onLoad()
  }, [])

  let history = useHistory();
  const redirect = (id, taskMode) => {
    history.push('/taskPerform/' + taskMode + '/' + id)
  }

  const isTaskActionHidden = (rowData) => {
    if (taskMode == "todo" | taskMode == "completed") {
      return true
    } else {
      const performingTask = processing.get(rowData["id"].toString())
      const isPermissionUnmatched = ["I-FG","O-FG"].includes(performingTask.task_code)
      return (isPermissionUnmatched)
    }
  }

  const isTasksTransferred = (rowData) => {
    const performingTask = processing.get(rowData["id"].toString())
    var isTaskTransferredForApproval = false
    var isTaskTransferredToSuccessorForAccept = false
    if (performingTask != undefined) {
      isTaskTransferredForApproval = performingTask['tranferred_time'] == null ? false : true
      isTaskTransferredToSuccessorForAccept = performingTask['transferred_time_for_successor_acceptance'] == null ? false : true
    }

    return (isTaskTransferredForApproval || isTaskTransferredToSuccessorForAccept)
  }

  const isTaskStarted = (rowData) => {
    if (taskMode == "todo" | taskMode == "completed") {
      return false
    } else {
      const performingTask = processing.get(rowData["id"].toString())
      if (performingTask['actual_start_time'] != null) {
        return true
      } else {
        return false
      }
    }

  }

  const actions = []
  actions.push((rowData) => isRowHidden(rowData))
  const isRowHidden = (rowData) => {
    var isHidden = isTaskActionHidden(rowData);
    var isTasksStarted = isTaskStarted(rowData);
    var isTaskTransferred = isTasksTransferred(rowData);

    if (isTasksStarted) {
      if (isTaskTransferred) {
        return ({
          hidden: false,
          icon: () => <TransferWithinAStationIcon fontSize='large' />,
          tooltip: 'Task Done and Waiting For Acceptance',
          iconProps: { color: "#B0BF1A" }
        })
      } else {
        return ({
          hidden: isHidden,
          icon: () => <Edit fontSize='large' />,
          tooltip: 'Perform the Task',
          onClick: (event, rowData) => {
            redirect(rowData.id, taskMode)
          }
        })
      }

    } else {
      return ({
        hidden: isHidden,
        icon: () => <PlayCircleFilledWhiteIcon fontSize='large' />,
        tooltip: 'Start the Task',
        onClick: (event, rowData) => {
          const data = {
            "id": rowData["id"]
          }
          dispatch(startTask(data))
          setStartedTask(data)
          // redirect(rowData.id, taskMode)
        }
      })
    }
  }

  const Timeline = (rowData) => {
    return (
      <div className={classes.timeline}>
        {rowData.rowData["flowStatus"] ? <div className={classes.status}>
          {rowData.rowData["flowStatus"]}
        </div> : null}

        <div>
          {rowData.rowData["received_time"] ?
            <div>
              <span>Remaining Time </span>: {rowData.rowData['remaining_time']}H
            </div> : null}
          <div>
            Regular Timeline : {rowData.rowData["due_start_datetime"]?.replaceAll("-", "/")} - {rowData.rowData["due_complete_datetime"]?.replaceAll("-", "/")}
          </div>
          {rowData.rowData["received_time"] ? taskMode == "processing" ?
            <div>
              Current Timeline : {rowData.rowData["received_time"]?.split("T")[0]?.replaceAll("-", "/")} - {rowData.rowData["expected_complete_time"]?.split("T")[0]?.replaceAll("-", "/")}
            </div> :
            taskMode == "completed" ? <div>
              Current Timeline : {rowData.rowData["received_time"]?.split("T")[0]?.replaceAll("-", "/")} - {rowData.rowData["actual_complete_time"]?.split("T")[0]?.replaceAll("-", "/")}
            </div> : null : null}
        </div>

      </div>
    )
  }

  const Forms = (rowData) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpen = () => {
      setDialogOpen(true);
    };
    const handleClose = () => {
      setDialogOpen(false);
    };

    console.log("rowdata: ", rowData)
    return (
      <div className={classes.timeline}>
        <Button className="btn_ btn_primary mb-2" onClick={handleOpen}>
          {" "}
          View
          {" "}
        </Button>
        {/* <Typography onClick={handleOpen}>View</Typography> */}
        <Dialog fullWidth maxWidth="lg" open={dialogOpen} onClose={handleClose}>
          <Grid container>
            <Grid item xs={12} sm={10}>
              <DialogTitle style={{ alignSelf: "center" }}>
                {" "}
                Forms from parent tasks
              </DialogTitle>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 10,
                  marginRight: 10,
                  cursor: "pointer",
                }}
              >
                <CloseIcon onClick={handleClose} />
              </div>
            </Grid>
          </Grid>
          <DialogContent>
            {rowData.rowData["forms_from_parent_tasks"]?.length > 0 && <Grid item xs={12}>
              <Paper>
                {(rowData.rowData["forms_from_parent_tasks"] || []).map(t =>
                  <Paper>
                    <Typography>Parent Task Id: {t?.id}</Typography>
                    <FormDownload task={t}  isParent={true} />
                  </Paper>)}
              </Paper>
            </Grid>}
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  const getFlowTypes = (tasksSet) => {
    var flowTypes = new Set()
    var tasksArray = Array.from(tasksSet.values())
    tasksArray.forEach(task => {
      if (task.order.type == "RP") {
        flowTypes.add("NEW")
      }
      else {
        flowTypes.add(task.order.type)
      }
    })
    setFlowTypeSet(flowTypes)
    return flowTypes
  }

  const tasksAccordingToFlow = (tasks) => {
    var flowTypes = getFlowTypes(tasks)
    var tasksArray = Array.from(tasks.values())
    var filteredTasks = []
    if (flowTypes.size > 1) {
      if (flowTypes.has("IQ")) {
        setFlowType("IQ")
        filteredTasks = tasksArray.filter(task => task.order.type == "IQ")
      } else {
        setFlowType("NEW")
        filteredTasks = tasksArray.filter(task => task.order.type == "NEW")
      }
    } else {
      var flowTypesArray = Array.from(flowTypes.values())
      setFlowType(flowTypesArray[0])
      filteredTasks = tasksArray.filter(task => task.order.type == flowTypesArray[0])
    }
    return filteredTasks
  }

  const loadReleventTasksSet = (location) => {
    if (location.pathname == '/todo') {
      setTaskMode(["todo"])
      const filteredTaks = tasksAccordingToFlow(toDo)
      return filteredTaks;

    }
    else if (location.pathname == '/processing') {
      setTaskMode(["processing"])
      const filteredTaks = tasksAccordingToFlow(processing)
      return filteredTaks;
    }
    else if (location.pathname == '/completed') {
      setTaskMode(["completed"])
      const filteredTaks = tasksAccordingToFlow(completed)
      return filteredTaks;
    }
  }

  React.useEffect(() => {
    const tasks = loadReleventTasksSet(location)
    var incomplete_tasks;
    var sorted_tasks;
    const completed_tasks = tasks.filter(t => t.actual_complete_time || t.transferred_time_for_successor_acceptance)

    if (startedTask) {
      const incompleted = tasks.filter(t => !t.actual_complete_time && !t.transferred_time_for_successor_acceptance && t.id != startedTask.id)
      const sorted = [..._.orderBy(incompleted, ["actual_start_time", "received_time"], ["asc", "asc"]), ..._.orderBy(completed_tasks, ["transferred_time_for_successor_acceptance", "actual_complete_time"], ["asc", "desc"])]
      sorted_tasks = tasks.filter(t => t.id == startedTask.id).concat(sorted)
    } else {
      incomplete_tasks = tasks.filter(t => !t.actual_complete_time && !t.transferred_time_for_successor_acceptance)
      sorted_tasks = [..._.orderBy(incomplete_tasks, ["actual_start_time", "received_time"], ["asc", "asc"]), ..._.orderBy(completed_tasks, ["transferred_time_for_successor_acceptance", "actual_complete_time"], ["asc", "desc"])]
    }

    setTasksSet(sorted_tasks)
  }, [toDo, startedTask])
  if (loading) return <Loading />;

  const createRow = (value) => {

    const row = {
      'id': value?.id,
      'task_code': value?.task_code,
      'task_name': value?.task_name,
      'sample_design_number': value?.order?.code,
      'inquiry_serial': value?.order?.inquiry_serial,
      'actual_start_time': value?.actual_start_time,
      'due_start_datetime': value?.due_start_datetime,
      'due_complete_datetime': value?.due_complete_datetime,
      'actual_complete_time': value?.actual_complete_time,
      'expected_complete_time': value?.expected_complete_time,
      'forms_from_parent_tasks': value?.parent_tasks,
      'received_time': value?.received_time,
      'duration': value?.duration,
      'flowStatus': value?.order?.flow_status,
      'remaining_time': value?.remaining_time
    }
    return row
  }


  const rows = []
  tasksSet.forEach(element => {
    rows.push(createRow(element))
  });



  // const rows=createRows(tasksSet,rows)
  var scope = {
    splitterStyle: {
      width: 10
    }
  };

  const changeTasksSet = (location, type) => {
    var newTaksSet = []
    if (location.pathname == '/todo') {
      var tasksArray = Array.from(toDo.values())
    }
    else if (location.pathname == '/processing') {
      var tasksArray = Array.from(processing.values())
    }
    else if (location.pathname == '/completed') {
      var tasksArray = Array.from(completed.values())
    }
    newTaksSet = tasksArray.filter(task => task.order.type == type)
    setTasksSet(newTaksSet)
  }

  const handleToggle = (event, type) => {
    setFlowType(type ? "NEW" : "IQ") ;
    changeTasksSet(location, type ? "NEW" : "IQ")
    // setFlowType(type);
    // changeTasksSet(location, type)
  };

  const ModifiedTitle = ({ text, variant }) => (
  <Typography
    variant={variant}
    style={{
    paddingRight: "50px",
    }}
  >
    {text}
  </Typography>
);

  return (
    <div className={classes.root}>
      {flowTypeSet.size != 0 ? <div className={classes.toggle}>
        <FormControlLabel
          control={
            <CustomSwitch
              onChange={handleToggle}
              inputProps={{ "aria-label": "controlled" }}
              disabled={flowTypeSet.size === 1}
            />
          }
          label={flowType === "IQ" ? "Inquiry Flow" : flowType === "NEW" ? "Order Flow" : null}
        />

        {/* <ToggleButtonGroup
          value={flowType}
          exclusive
          onChange={handleToggle}
          aria-label="text alignment"
        >
          {flowTypeSet.has("IQ") ? <ToggleButton value="IQ" aria-label="left aligned" disabled={flowTypeSet.size == 1}>
            Inquiry Flow
          </ToggleButton> : null}
          {flowTypeSet.has("NEW") ? <ToggleButton value="NEW" aria-label="centered" disabled={flowTypeSet.size == 1}>
            Order Flow
          </ToggleButton> : null}

        </ToggleButtonGroup> */}
      </div> : null}


      {flowType == "IQ" && taskMode == "todo" || taskMode == "completed" ?
        <MaterialTable
          icons={tableIcons}
          title={<ModifiedTitle variant="h6" text={taskMode == "todo" ? "ToDo Tasks" : taskMode == "processing" ? "Processing Tasks" : taskMode == "completed" ? "Completed Tasks" : null} />}
          columns={[
            {
              title: 'Task',
              field: 'id',
              type: 'int'
            },
            { title: 'Task Code', field: 'task_code', type: 'string' },
            { title: 'Task Name', field: 'task_name', type: 'string' },
            { title: 'Inquiry Serial Number', field: 'inquiry_serial', type: 'string' },
            { title: 'Scheduled Start Date', field: 'due_start_datetime', type: 'date' },
            { title: 'Scheduled Complete Date', field: 'due_complete_datetime', type: 'date' },
            { title: 'Actual Start Date', field: 'actual_start_time', type: 'date' },
            { title: 'Expected Complete Date', field: 'expected_complete_time', type: 'date' },
            { title: 'Actual Complete Date', field: 'actual_complete_time', type: 'date' },
            { title: 'Timeline', render: rowData => <Timeline rowData={rowData}></Timeline> }
          ]}
          data={rows}
          actions={(taskMode == "processing") ? actions : null}
          options={{
            sorting: true,
            actionsColumnIndex: -1,
            maxBodyHeight: 700,
            search: true,
            searchFieldAlignment: 'left',
            searchFieldStyle: {width: "500px"},
            pageSize: 25,
            pageSizeOptions: [25,50,100]
          }}
          components={{
          Toolbar: (props) => (
            <div
              style={{
                whiteSpace:"nowrap",
              }}
            >
              <MTableToolbar {...props} />
            </div>
          )
        }}
        /> : flowType == "IQ" && taskMode == "processing" ?
        <MaterialTable
        icons={tableIcons}
        title={<ModifiedTitle variant="h6" text={taskMode == "todo" ? "ToDo Tasks" : taskMode == "processing" ? "Processing Tasks" : taskMode == "completed" ? "Completed Tasks" : null}/>}
        columns={[
          {
            title: 'Task',
            field: 'id',
            type: 'int'
          },
          { title: 'Task Code', field: 'task_code', type: 'string' },
          { title: 'Task Name', field: 'task_name', type: 'string' },
          { title: 'Inquiry Serial Number', field: 'inquiry_serial', type: 'string' },
          { title: 'Scheduled Start Date', field: 'due_start_datetime', type: 'date' },
          { title: 'Scheduled Complete Date', field: 'due_complete_datetime', type: 'date' },
          { title: 'Actual Start Date', field: 'actual_start_time', type: 'date' },
          { title: 'Expected Complete Date', field: 'expected_complete_time', type: 'date' },
          { title: 'Actual Complete Date', field: 'actual_complete_time', type: 'date' },
          { title: 'Forms from parent tasks', render: rowData => <Forms rowData={rowData}></Forms> },
          { title: 'Timeline', render: rowData => <Timeline rowData={rowData}></Timeline> }
        ]}
        data={rows}
        actions={(taskMode == "processing") ? actions : null}
        options={{
          sorting: true,
          actionsColumnIndex: -1,
          maxBodyHeight: 700,
          search: true,
          searchFieldAlignment: 'left',
          searchFieldStyle: {width: "500px"},
          pageSize: 25,
          pageSizeOptions: [25,50,100]
        }}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                whiteSpace:"nowrap",
              }}
            >
              <MTableToolbar {...props} />
            </div>
          )
        }}
      />: flowType == "NEW" && taskMode == "todo" || taskMode == "completed" ?
          <MaterialTable
            icons={tableIcons}
            title={<ModifiedTitle variant="h6" text={taskMode == "todo" ? "ToDo Tasks" : taskMode == "processing" ? "Processing Tasks" : taskMode == "completed" ? "Completed Tasks" : null} />}
            columns={[
              {
                title: 'Task',
                field: 'id',
                type: 'int'
              },
              { title: 'Task Code', field: 'task_code', type: 'string' },
              { title: 'Task Name', field: 'task_name', type: 'string' },
              { title: 'Sample Design Number', field: 'sample_design_number', type: 'string' },
              { title: 'Scheduled Start Date', field: 'due_start_datetime', type: 'date' },
              { title: 'Scheduled Complete Date', field: 'due_complete_datetime', type: 'date' },
              { title: 'Actual Start Date', field: 'actual_start_time', type: 'date' },
              { title: 'Received Date', field: 'received_time', type: 'date' },
              { title: 'Actual Complete Date', field: 'actual_complete_time', type: 'date' },
              { title: 'Timeline', render: rowData => <Timeline rowData={rowData}></Timeline> }
            ]}
            data={rows}
            actions={(taskMode == "processing") ? actions : null}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              maxBodyHeight: 700,
              search: true,
              searchFieldAlignment: 'left',
              searchFieldStyle: {width: "500px"},
              pageSize: 25,
              pageSizeOptions: [25, 50, 100]
            }}
            components={{
          Toolbar: (props) => (
            <div
              style={{
                whiteSpace:"nowrap",
              }}
            >
              <MTableToolbar {...props} />
            </div>
          )
        }}
          /> :flowType == "NEW" && taskMode == "processing" ?
          <MaterialTable
          icons={tableIcons}
          title={<ModifiedTitle variant="h6" text={taskMode == "todo" ? "ToDo Tasks" : taskMode == "processing" ? "Processing Tasks" : taskMode == "completed" ? "Completed Tasks" : null}/>}
          columns={[
            {
              title: 'Task',
              field: 'id',
              type: 'int'
            },
            { title: 'Task Code', field: 'task_code', type: 'string' },
            { title: 'Task Name', field: 'task_name', type: 'string' },
            { title: 'Sample Design Number', field: 'sample_design_number', type: 'string' },
            { title: 'Scheduled Start Date', field: 'due_start_datetime', type: 'date' },
            { title: 'Scheduled Complete Date', field: 'due_complete_datetime', type: 'date' },
            { title: 'Actual Start Date', field: 'actual_start_time', type: 'date' },
            { title: 'Received Date', field: 'received_time', type: 'date' },
            { title: 'Actual Complete Date', field: 'actual_complete_time', type: 'date' },
            { title: 'Forms from parent tasks', render: rowData => <Forms rowData={rowData}></Forms> },
            { title: 'Timeline', render: rowData => <Timeline rowData={rowData}></Timeline> }
          ]}
          data={rows}
          actions={(taskMode == "processing") ? actions : null}
          options={{
            sorting: true,
            actionsColumnIndex: -1,
            maxBodyHeight: 700,
            search: true,
            searchFieldAlignment: 'left',
            searchFieldStyle: {width: "500px"},
            pageSize: 25,
            pageSizeOptions: [25, 50, 100]
          }}
          components={{
          Toolbar: (props) => (
            <div
              style={{
                whiteSpace:"nowrap",
              }}
            >
              <MTableToolbar {...props} />
            </div>
          )
        }}
        />:
          <div>
            <img src={noTasks} width="100%" height="auto" />
          </div>}


    </div>
  )
}


const mapStateToProps = (state) => {
  const toDo = getTodoTasks(state)
  const processing = getProcessingTasks(state)
  const completed = getCompletedTasks(state)
  const loading = getTNATaskLoading(state)

  return {
    toDo, processing, completed, loading
  }
}

const mapDispatchToProps = {
  onLoad: loadALLTasks
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);