import React from "react";
import { TableComponent } from "./TableComponent";

export const Received = ({ data }) => {
  return (
    <>
      <div>
        <h3> Received Time stamps</h3>
        <TableComponent payload={data} name={"R"} />
      </div>
    </>
  );
};
