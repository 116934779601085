import React from "react";
import { useState } from "react";
import {
  Create,
  SimpleForm,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteArrayInput,
  ReferenceInput,
  required,
  useMutation,
  useRefresh,
  AutocompleteInput,
  Toolbar,
  SaveButton,
} from "react-admin";
import { showToastError, showToastSuccess } from "../../../lib/toas";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Label } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
// import BackspaceIcon from 'material-ui/svg-icons/content/backspace';

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
  },
});

const CreateSubTask = (props) => {
  const [taskData, setTaskData] = useState(props.location?.state?.rowData);
  const [search, setSearch] = useState(props.location?.state?.search);
  // const taskData = props.location?.state?.rowData;
  const [flow, setFlow] = useState(taskData ? taskData.order_id : 1);
  const [mode, setMode] = useState(!taskData ? "create" : "assign");
  const [assignee, setAssignee] = useState();
  const [subTaskAssignees, setSubTaskAssignees] = useState([]);
  const [isRadioEnabled, setIsRadioEnabled] = useState(true);
  const [notifyMessage, setNotifyMessage] = useState();
  const [taskType, setTaskType] = useState();
  const [usersMsg, setUsersMsg] = useState();
  const history = useHistory();

  const Title = (record) => {
    return <span>Create TNA Subtask</span>;
  };

  const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton className="btn_ btn_edit m-2" />
    </Toolbar>
  );

  const handleSetFlow = (flowId) => {
    setFlow(flowId);
  };

  const getTaskData = (id) => {
    mutate(
      {
        type: "getOne",
        resource: "tna_itemTasks",
        payload: {
          id: id,
        },
      },
      {
        onFailure: (e) => {
          // console.log(respErrorMessage);
        },
        onSuccess: (e) => {
          if (e.data) {
            var assignees = e.data.taskAssignees;
            if (e.data.task_type == "SubTask") {
              setMode("assign");
              setIsRadioEnabled(false);
              setTaskType("SubTask")
              setAssignee(assignees);
              if (assignees != null) {
                setNotifyMessage(
                  `This is a Sub task and it is already assigned to ${assignees.username}`
                );
              }
            } else if (e.data.task_type == "MainTask") {
              setMode("create");
              setIsRadioEnabled(false);
              setTaskType("MainTask")
              if (assignees != null) {
                let msg=`This task is already divided into ${assignees.length} sub tasks. `
                let assignedUsersMsg=`Assigned users - `
                for (let i = 0; i < assignees.length; i++) {
                  assignedUsersMsg=assignedUsersMsg.concat(assignees[i].username)
                  if(i!=assignees.length-1){
                    assignedUsersMsg=assignedUsersMsg.concat(`, `)
                  }
                }
                setNotifyMessage(msg);
                setUsersMsg(assignedUsersMsg)
              }
            } else if (e.data.task_type == "Undivided") {
              setIsRadioEnabled(true);
              setMode("assign");
              setTaskType("Undivided")
              if (assignees != null) {
                setNotifyMessage(
                  `This Task is already assigned to ${assignees.username}`
                );
              } else {
                setNotifyMessage("This Task is unassigned");
              }
            }
          }
        },
      }
    );
  };

  const handleTask = (id) => {
    getTaskData(id);
  };

  const [mutate, { loading, onSuccess, onError }] = useMutation();
  const refresh = useRefresh();

  const SendData = (event) => {
    var endPoint = "";
    var data = "";
    var respSuccessMessage = "";
    var respErrorMessage = "";
    if (mode === "create") {
      endPoint = "createSubTasks";
      data = event;
      respSuccessMessage = "Subtask Creation Successful";
      respErrorMessage = "Subtask Creation Failed";
    } else if (mode === "assign") {
      endPoint = "modifyFlow";
      data = [
        {
          id: event.mainTaskId,
          assigneeId: event.assignedUser,
        },
      ];
      respSuccessMessage = "Task Assignment Successful";
      respErrorMessage = "Task Assignment Failed";
    }

    mutate(
      {
        type: "TNA_CUSTOM_ACTION",
        resource: "itemTasks",
        payload: {
          action: endPoint,
          body: data,
        },
      },
      {
        onFailure: (e) => {
          console.log(respErrorMessage);
        },
        onSuccess: (e) => {
          if (e.data) {
            showToastSuccess(respSuccessMessage);
            taskData ? history.push(`/unassigned?q=${search}`) : refresh();
            setTaskData(null);
          }
          refresh();
        },
      }
    );
  };

  return (
    <>
      <Create
        {...props}
        title={<Title />}
        className="col-lg-6 col-md-9 col-sm-12"
      >
        <SimpleForm save={SendData} toolbar={<CustomToolbar />}>
          <ReferenceInput
            defaultValue={taskData?.order_id}
            source="flow_id"
            reference="tna_orders"
            perPage={Number.MAX_SAFE_INTEGER}
            sort={{ field: "id", order: "ASC" }}
            filter={{ status: "Processing" }}
            onChange={handleSetFlow}
          >
            <AutocompleteInput validate={required()} optionText="flow_code" />
          </ReferenceInput>
          <ReferenceInput
            defaultValue={taskData?.id}
            source="mainTaskId"
            reference="tna_itemTasks"
            filter={
              localStorage.tna_department !== "undefined"
                ? {
                    order: Number(flow),
                    status: "Received_Not_Received",
                    tna_department_code: localStorage.tna_department,
                  }
                : { order: Number(flow), status: "Received_Not_Received" }
            }

            sort={{ field: "id", order: "ASC" }}
            perPage={100}
            onChange={(props) => handleTask(props)}
          >
            <AutocompleteInput
              validate={required()}
              optionText={(record) => {
                return record["task_code"].concat([
                  `  |  ${record["task_name"]}`,
                ]);
              }}
            />
          </ReferenceInput>
          <h6 >{notifyMessage}</h6>
          {taskType=="MainTask"?<h6 >{usersMsg}</h6>:null}

          <FormControl component="fieldset" disabled={!isRadioEnabled}>
            <RadioGroup
              value={mode}
              onChange={(event) => setMode(event.target.value)}
            >
              <FormControlLabel
                value={!taskData ? "create" : "assign"}
                control={<Radio />}
                label={!taskData ? "Create Subtasks" : "Assign Users"}
              />
              <FormControlLabel
                value={taskData ? "create" : "assign"}
                control={<Radio />}
                label={taskData ? "Create Subtasks" : "Assign Users"}
              />
            </RadioGroup>
          </FormControl>

          {mode === "assign" ? (
            <ReferenceInput
              source="assignedUser"
              reference="users"
              filterToQuery={() => ({ level: "mng" })}
              perPage={Number.MAX_SAFE_INTEGER}
              filter={
                localStorage.tna_department !== "undefined"
                  ? {
                      tna_department_code: localStorage.tna_department,
                    }
                  : false
              }
            >
              <AutocompleteInput optionText="username" validate={required()} />
            </ReferenceInput>
          ) : (
            <ReferenceArrayInput
              source="assignedUsers"
              reference="users"
              filterToQuery={() => ({ level: "mng" })}
              perPage={Number.MAX_SAFE_INTEGER}
              filter={
                localStorage.tna_department !== "undefined"
                  ? {
                      tna_department_code: localStorage.tna_department,
                    }
                  : false
              }
            >
              <AutocompleteArrayInput
                optionText="username"
                validate={required()}
              />
            </ReferenceArrayInput>
          )}
        </SimpleForm>
      </Create>
    </>
  );
};

export default CreateSubTask;
