import React, {useMemo} from "react"
import {
  Datagrid,
  DatagridBody,
  DatagridCell,
  TextField,
  BooleanField
 } from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const CustomDatagridRow = ({ record, resource, id, onToggleItem, children: childrenOriginal, selected, basePath }) => {
    const children = useMemo(() => childrenOriginal.filter(v => !!v), [childrenOriginal])
    return (
    <TableRow key={id}>
        {React.Children.map(children, field => (
            <TableCell style={{width: field.props.width}} key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
);
            }

const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow />} />;
export const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody />} />;
