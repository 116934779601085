import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
// import {Button} from 'react-admin'
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
const ShowButton = (props) => {
  const {
    history,
    location,
    match,
    staticContext,
    onClick,
    basePath,
    variant,
    color,
    label,
    record,

    ...rest
  } = props;
  return (
    <Button
      // fontSize="small"
      startIcon={<VisibilityIcon />}
      // size="medium"
      variant={variant}
      className="btn_ btn_secondry"
      {...rest}
      onClick={(event) => {
        onClick && onClick(event);
        history.push(`${basePath}/${record?.id}/show`);
      }}
    >
      {label ? label : "show"}
    </Button>
  );
};

export default withRouter(ShowButton);
