import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { format_to_date } from "../../../lib/date_time";
import ProgressBar from "./ProgressBar";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const DetailsView = React.memo(({ data }) => {
  const classes = useStyles();

  const progress = (p, q) => `${Math.floor(q)} of  ${Math.floor(p)} Completed`;

  const serilizeQuantity = (p, q) => {
    if (!(q && p)) return false;

    if (p > q || p === q) {
      //serilize over planning

      return progress(q, q);
    }
    return progress(p, q);
  };

  const oderProgressManager = (q, p) => {
    if (!q) return 0;

    if (q > p) {
      return -1;
    }
    if (q === p || p > q) {
      return 1;
    }
  };

  const oderProgress = (p, q) => {
    if (!(q && p)) return false;
    if (p > q) {
      //serilize over planning
      return (q / q) * 100;
    }
    return (p / q) * 100;
  };

  return (
    <>
      <CardContent style={{ width: "100%" }}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          <h5 style={{ color: "blue" }}>{data.code}</h5>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {data.planned_start_date && `Start date :`}{" "}
            {format_to_date(data.planned_start_date)}
          </Typography>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {data.planned_end_date && `End date :`}{" "}
            {format_to_date(data.planned_end_date)}
          </Typography>
          <Typography className={classes.title} gutterBottom>
            {serilizeQuantity(data.planned_quantity, data.quantity)}

            <ProgressBar
              value={oderProgress(data.planned_quantity, data.quantity)}
              style={{ width: "80%" }}
            />
          </Typography>

          <Typography className={classes.title} gutterBottom>
            Status :{" "}
            {oderProgressManager(data.quantity, data.planned_quantity) === 1 ? (
              <span style={{ color: "green" }}>
                Processed <span role="img" aria-label="tick">✔️</span>
              </span>
            ) : (
              <span style={{ color: "red" }}> Not yet planned <span role="img" aria-label="red dot">🔴</span> </span>
            )}
          </Typography>
        </Typography>
      </CardContent>
    </>
  );
});

export default DetailsView;
