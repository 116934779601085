import React from 'react'
import img from '../../../layout/img/stack-tech-logo2.png';

export const Logo = () => {
    return (
        <div>
            <img src={img} alt='leaf'  width="100%" height="50%" style={{marginBottom:'10px'}}  />
        </div>
    )
}
