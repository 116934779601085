import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_REPEATED_ORDERS,
  LOAD_ALL_USERS,
  LOAD_SDN,
} from "../../../store/types/tnaTaskTypes";
import { fetchRepeatedOrders } from "../../../store/actions/tnaTaskActions"
import {
  useMutation
} from "react-admin";
import { showToastError, showToastSuccess } from "../../../lib/toas";
import _ from "lodash";
import { Paper } from "@material-ui/core";
import SmallLoading from "../../../components/loading/SmallLoading"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "50%",
  },
  btn: {
    backgroundColor: "primary",
    minWidth: "120px",
    margin: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  listPaper:{
    maxHeight:300,
    height:'auto',
    width:300,
    marginTop:50
  }
  //   formWrapper:{
  //       width:
  //   }
}));

export const Template = ({
  data,
  createHandler,
  dispatchFormData,
  sdnArray,
  sdnArrayLoading,
  rpSDNArray,
  rpSDNArrayLoading,
  templateIDArray,
  repeatedOrders,
  tabId,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [SDNforRP, setSDNForRP] = React.useState();
  const [shopOrders, setShopOrders] = React.useState();
  const [shopOrderRes, setShopOrderRes] = React.useState();
  const [mutate, { mutateLoading, onSuccess, onError }] = useMutation();
  const codeOptions =
    tabId !== 2 ? (
      tabId === 0 ? (
        sdnArray.filter(
          (code) =>
            code.price_list_ref && code.price_list_ref === data.priceListRef
        ).length > 0 ? (
          sdnArray
            .filter(
              (code) =>
                code.price_list_ref && code.price_list_ref === data.priceListRef
            )
            .map((code) => (
              <MenuItem key={code.code} value={code.code}>
                {code.code}
              </MenuItem>
            ))
        ) : (
          <MenuItem>Select a Price List No.</MenuItem>
        )
      ) : sdnArrayLoading ? (<MenuItem> <SmallLoading />  </MenuItem>) : sdnArray.filter((code) => code.inquiry_serial).length > 0 ? (
        sdnArray
          .filter((code) => code.inquiry_serial)
          .map((code) => (
            <MenuItem key={code.code} value={code.inquiry_serial}>
              {code.inquiry_serial}
            </MenuItem>
          ))
      ) : (
        <MenuItem>Data not available</MenuItem>
      )
    ) : rpSDNArray.filter((code) => code.code).length > 0 ? (
      rpSDNArray
        .filter((code) => code.code)
        .map((code) => (
          <MenuItem key={code.code} value={code.code}>
            {code.code}
          </MenuItem>
        ))
    ) : (
      <MenuItem>Data not available</MenuItem>
    )

  const templateOptions =
    tabId !== 1 ? (
      templateIDArray.filter((t) => t.type === "ORDER_FLOW").length > 0 ? (
        templateIDArray
          .filter((t) => t.type === "ORDER_FLOW")
          .map((tmp) => (
            <MenuItem key={tmp.id} value={tmp.id}>
              {tmp.code}
            </MenuItem>
          ))
      ) : (
        <MenuItem disabled value="Data not available">
          Data not available
        </MenuItem>
      )
    ) : templateIDArray.filter((t) => t.type === "INQUIRY_FLOW").length > 0 ? (
      templateIDArray
        .filter((t) => t.type === "INQUIRY_FLOW")
        .map((tmp) => (
          <MenuItem key={tmp.id} value={tmp.id}>
            {tmp.code}
          </MenuItem>
        ))
    ) : (
      <MenuItem disabled value="Data not available">
        Data not available
      </MenuItem>
    );

  const priceListRefs =
    sdnArrayLoading ? (<MenuItem> <SmallLoading />  </MenuItem>) :
    _.uniqBy(sdnArray, "price_list_ref").length > 0 ? (
      _.uniqBy(sdnArray, "price_list_ref").map(
        (v) =>
          v.price_list_ref && (
            <MenuItem key={v.id} value={v.price_list_ref}>
              {v.price_list_ref}
            </MenuItem>
          )
      )
    ) : (
      <MenuItem disabled value="Data not available">
        Data not available
      </MenuItem>
    );

  useEffect(() => {
    dispatch({
      type: LOAD_ALL_USERS,
      payload: { pagination: { page: 1, perPage: 1000 } },
    });
  }, []);

  useEffect(() => {
    dispatchFormData({ type: "setPriceListRef", payload: "" });
    dispatchFormData({ type: "setCode", payload: "" });
    dispatchFormData({ type: "setType", payload: "" });
  }, [tabId]);

  const sdns = sdnArray.filter((code) => code.code)
  const RPsdns = rpSDNArray.filter((code) => code.code)

  const getRepeatedOrders = () => {
    mutate({
        type: 'TNA_CUSTOM_ACTION',
        resource: 'itemTasks',
        payload: {
            action: "getRepeatedOrders",
            method: "POST",
            body: {
              sdn: SDNforRP.code
          },
        }
    }, {
        onFailure: (e) => {
            showToastError("Repeated Orders Retrieval Failed:" + e.body);
        },
        onSuccess: (resp) => {
          setShopOrderRes(resp.data)
          const shopOrdersOptions=Object.keys(resp.data).length > 0 ? (
            Object.keys(resp.data)
              .map((code) => (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              ))
          ) : (
            <MenuItem>Data not available</MenuItem>
          )
          setShopOrders(shopOrdersOptions)
        }
    });
};


  function renderRow(props) {
    const { index, style } = props;
    const item=shopOrderRes[data.shopOrderGroupCode]?.itemCodes[index]
  
    return (
      <ListItem style={style} key={index}>
        <ListItemText primary={item} />
      </ListItem>
    );
  }

  return (
    <Grid container>
      <Grid item sm={12} md={6}>
        {tabId == 0 && (
          <FormControl className={classes.formControl}>
            <InputLabel>Price List Ref. No.</InputLabel>
            <Select
              value={data.priceListRef}
              onChange={(e) => {
                dispatchFormData({
                  type: "setPriceListRef",
                  payload: e.target.value,
                });
              }}
            >
              {priceListRefs}
            </Select>
          </FormControl>
        )}
        {tabId == 2 && (
          <>
            <FormControl className={classes.formControl}>

              <Autocomplete
                loading={rpSDNArrayLoading}
                loadingText={<SmallLoading />} 
                id="combo-box-demo"
                options={RPsdns}
                value={data}
                getOptionLabel={(option) => option.code}
                onChange={(event,newValue) => {
                  setSDNForRP(newValue);
                  dispatchFormData({ type: "setCode", payload: newValue ? newValue.code : "" });
                  dispatchFormData({
                    type: "setType",
                    payload: "RP",
                  });
                }}
                renderInput={(params) => <TextField {...params} label="Sample Design Number" />}
              />
            </FormControl>
            {shopOrders?<FormControl className={classes.formControl}>
              <InputLabel>
                Shop Order No:
              </InputLabel>
              <Select
                value={data.shopOrderGroupCode}
                onChange={(e) => {
                  dispatchFormData({ type: "setShopOrderGroupCode", payload: e.target.value });
                  dispatchFormData({
                    type: "setShopOrderItems",
                    payload: shopOrderRes[e.target.value]['itemCodes'],
                  });
                }}
              >
                {shopOrders}
              </Select>
            </FormControl> : null}
            {data.shopOrderGroupCode ?
              <FormControl className={classes.formControl} style={{width:300}}>
                <div style={{width:300}}>
                <InputLabel>
                  Item Codes :
                </InputLabel>
                <Paper className={classes.listPaper}>
                  <FixedSizeList height={200} width={300} itemSize={46} itemCount={shopOrderRes[data.shopOrderGroupCode]?.itemCodes.length }>
                    {renderRow}
                  </FixedSizeList>
                </Paper>
                </div>
                
              </FormControl>
              : null}

          </>

        )}
        {tabId !== 2 ? <FormControl className={classes.formControl}>
          <InputLabel>
            {tabId == 0 ? "Sample Design Number" : "Inquiry Serial Number"}
          </InputLabel>
          <Select
            value={data.code}
            onChange={(e) => {
              dispatchFormData({ type: "setCode", payload: e.target.value });
              dispatchFormData({
                type: "setType",
                payload: `${tabId === 0 ? "NEW" : tabId === 2 ? "RP" : "IQ"}`,
              });
            }}
          >
            {codeOptions}
          </Select>
        </FormControl> : null}
        {tabId !== 2 || shopOrders ? <FormControl className={classes.formControl}>
          <InputLabel>Template ID</InputLabel>
          <Select
            value={data.templateId}
            okText="Okay"
            cancelText="Dismiss"
            onChange={(e) =>
              dispatchFormData({ type: "setTemplateId", payload: e.target.value })
            }
          >
            {templateOptions}
          </Select>
        </FormControl> : null
        }
        

        <Grid container justify="flex-end">
          <Button
            className="btn_ btn_primary mt-3 mr-2"
            size="large"
            onClick={() => {
              createHandler(tabId==2?true:false)
              setSDNForRP(null)
              setShopOrders(null)
            }}
          >
            Generate Flow
          </Button>
        </Grid>
      </Grid>
      <Grid item sm={12} md={6}>
        {tabId == 2 && (<FormControl className={classes.formControl} style={{ maxWidth: '50px' }}>
          <Button
            className="btn_ btn_primary mt-3 mr-2"
            size="medium"
            onClick={(value) => getRepeatedOrders()}
          >
            Check
          </Button>

        </FormControl>)}

      </Grid>
    </Grid>
  );
};
