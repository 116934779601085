import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState } from "react";
import {
  BooleanField,
  CardActions,
  Datagrid,
  EditButton,
  Filter,
  List,
  Mutation,
  ReferenceInput,
  SelectInput,
  // ShowButton,
  TextField,
  TextInput,
  WithPermissions,
  useRefresh,
  usePermissions,
} from "react-admin";
import ShowButton from "../../components/CustomShowButton";
import CSVReader from "react-csv-reader";
import Confirmation from "../../components/Confirmation";
import KeyValueField from "../../components/field/KeyValueField";
import { useAutoScroll } from "../../hooks/useScroll";
import { showToastSuccess, showToastError } from "../../lib/toas";
import { makeStyles } from "@material-ui/core/styles";
import { ChangeDiv } from "../../components/ChangeDiv"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
  },
}));

const ItemFilter = (props) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions?.access_level == "ADMIN"

  return (
    <Filter {...props}>
      <TextInput label="Search by Item Code" source="code" alwaysOn />

      <TextInput
        label="Search by item attributes"
        source="attributes"
        alwaysOn
        style={{ minWidth: "230px" }}
      />

      {isAdmin && <ReferenceInput
        link=""
        label="Site"
        source="site_id"
        reference="sites"
        alwaysOn
      >
        <SelectInput optionText="name" resettable />
      </ReferenceInput>}
    </Filter>
  );
}

export const Item = (props) => {
  const autoScroll = useAutoScroll(props);
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      filters={<ItemFilter />}
      actions={<Actions />}
      perPage={25}
      bulkActionButtons={false}
    >

      <ChangeDiv  >
        {props => {
          return <Datagrid>
            <TextField source="code" />
            <TextField source="unit" />
            <BooleanField label="Is RM" source="is_rm" />
            <KeyValueField source="attributes" />
            {!permissions?.permissions.includes("items_show") ? (
              <ShowButton
                title="Click here to Show items"
                className="btn_ btn_secondry m-0"
                disabled={true}
              />
            ) : (
                <ShowButton
                  title="Click here to Show items"
                  className="btn_ btn_secondry m-0"
                  disabled={false}
                />
              )}
            <EditButton
              {...props}
              title="Click here to Edit items"
              disabled={!permissions?.permissions.includes("items_edit")}
              className="btn_ btn_edit m-0"
            />
            <Confirmation
              text="item"
              title="Click here to delete item"
              className="btn_ btn_delete m-0"
              // variant="contained"
              disabled={!permissions?.permissions.includes("items_delete")}
            />
          </Datagrid>
        }}
      </ChangeDiv>
    </List>
  );
};

const Actions = () => (
  <CardActions>
    <UploadAttributesButton />
  </CardActions>
);

const UploadAttributesButton = ({ onSuccess }) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(false);
  const ItemFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Search item by code" source="code" alwaysOn />
    </Filter>
  );

  const refresh = useRefresh();
  const onUploadSuccess = () => {
    showToastSuccess("Upload success!");
    refresh();
  };

  const onUploadError = () => {
    showToastError("Upload Failed");
    refresh();
  };

  return (
    <div className={classes.root}>
      <Button
        title="Click here to Upload items"
        disabled={!permissions?.permissions.includes("items_upload")}
        variant="contained"
        // color="primary"
        className="btn_ btn_primary mt-0"
        onClick={() => setIsOpen(true)}
      >
        {" "}
        Upload
      </Button>

      <Dialog
        fullWidth="true"
        maxWidth="sm"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle className='mb-3 mt-3' style={{ alignSelf: "center" }}>
              {" "}
              Upload Items{" "}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={() => setIsOpen(false)} />
            </div>
          </Grid>
        </Grid>
        <DialogContent>
          {/* <div style={{ minHeight: "150px", paddingTop: "50px" }}> */}
          <CSVReader onFileLoaded={(data) => setData(data)} />
          {data && <div>Records {data.length - 1} </div>}
          {/* </div> */}
          <DialogActions className='mb-5'>
            {/* <Button
              className="btn_ btn_primary"
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button> */}

            {data && (
              <Mutation
                type="CUSTOM"
                resource="items"
                payload={{
                  action: "save_attributes",
                  method: "POST",
                  body: data,
                }}
                options={{
                  onSuccess: onUploadSuccess,
                  onFailure: onUploadError,
                }}
              >
                {(action) => (
                  <Button
                    variant="contained"
                    // color="primary"
                    className="btn_ btn_primary"

                    onClick={() => {
                      setIsOpen(false);
                      action();
                    }}
                  >
                    {" "}
                    Save
                  </Button>
                )}
              </Mutation>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
