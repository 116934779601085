import React, { useState, useMemo, ChangeEvent, useEffect, useContext, useCallback } from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from '@material-ui/core/AppBar';
// import Box from "@material-ui/core/Box";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Toolbar from '@material-ui/core/Toolbar';
import { Paper } from '@material-ui/core'
import Container from '@material-ui/core/Container';
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { ThemeProvider } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { useDataProvider, Title } from "react-admin";
// import AddressForm from './AddressForm';
// import PaymentForm from './PaymentForm';
// import Review from './Review';
import { createMuiTheme } from '@material-ui/core/styles';
import _ from "lodash"
import { showToastError, showToastSuccess } from '../../../../lib/toas';
// import { ThemeProvider } from '@material-ui/styles';
import { NumberInput, Edit, SelectInput, usePermissions, useQuery, useRedirect, Loading, SimpleForm, TextInput } from "react-admin";
import List from './List'


const theme = createMuiTheme();


const payload = {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "id", order: "ASC" },
    // filter: {  },
};

const formsSchema = []
export const InquirySerial = () => {

    const FieldValues = [
        'Cluster Code',
        'Year',
        'Season Code',
        'Category Code',
        'Type',

    ]

    const dataProvider = useDataProvider();

    const initValues = {
        field: '',
        key: '',
        value: '',
        inputs: [],

    }

    const initData = {
        cluster: [],
        year: [],
        season: [],
        category: [],
        type: []
    }

    const tempArr = ['cluster',
        'year',
        'season',
        'category',
        'type'
    ]

    const [state, setstate] = React.useState(initValues)
    const [data, setData] = React.useState(initData)
    const [cluster, setcluster] = React.useState([{}])
    const [year, setyear] = React.useState([{}])
    const [season, setseason] = React.useState([{}])
    const [category, setcategory] = React.useState([{}])
    const [type, settype] = React.useState([{}])
    const [validate, setvalidate] = React.useState(false)
    const [listData, setListData] = React.useState('')


    const schemaMaker = (val, inputs) => {
        switch (val) {
            case 'Cluster Code':
                return setcluster([Object.assign(cluster[0], inputs)])
            case 'Year':
                return setyear([Object.assign(year[0], inputs)])
            case 'Season Code':
                return setseason([Object.assign(season[0], inputs)])
            case 'Category Code':
                return setcategory([Object.assign(category[0], inputs)])
            case 'Type':
                return settype([Object.assign(type[0], inputs)])

        }

    }

    const apiCall = async (res) => {



        try {
            const ids = await dataProvider.CUSTOM_ACTION_TNA_SP("dropdown", {
                actions: "update_inquiry_serial_data",
                body: res,
                method: "POST"
            });
            showToastSuccess('saved ')
            // window.location.reload();
            setTimeout(() => {
                window.location.reload();
            }, 1000);


        } catch (error) {
            showToastError('fill all fields')
        }

    }






    const handleSave = async () => {



        if (state.field && validate) {

            const schema = {
                'cluster': cluster,
                'year': year,
                'season': season,
                'category': category,
                'type': type

            }
            apiCall(schema)

            // console.log(schema)
        } else {
            showToastError('please fill all fields')
        }




    }
    const handleChange = () => {


        if (state.field && state.key && state.value) {

            const key = state.key
            const value = state.value
            if (key && value) {
                setvalidate(true)
            }
            const temp = { [key]: value }

            // setstate({ ...state, inputs: state.inputs.push(temp) })

            const inputArray = schemaMaker(state.field, temp)
            // console.log(inputArray)


            setstate({ ...state, key: '', value: '' })
            showToastSuccess('input added ')
        }

        else {
            showToastError('fill all fields')
        }
    }


    const { Loading, Error } = useQuery(
        {
            type: "TNA_CUSTOM_ACTION",
            resource: "dropdown",
            payload: {
                action: "fetch_inquiry_serial_data",
                method: "GET",
            },
        },
        {
            onFailure: (e) => { },
            onSuccess: (response) => {
                //  console.log("something", response.data.data);
                setListData(response?.data?.data);
            },
        }
    );


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Title title="Inquiry Serial Creator" />

            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm"  >
                        <Typography
                            component="h4"
                            variant="h4"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Inquiry Serial Creator
              </Typography>

                    </Container>
                </Box>
                <Container sx={{ py: 8 }} style={{ paddingTop: 10, height: '100%', marginTop: 50 }} maxWidth="lg">
                    {/* End hero unit */}
                    <Grid container spacing={4}>

                        <Grid item xs={12} sm={8} md={12}>
                            <Card
                                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                            >

                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Create InquirySerial
                      </Typography>
                                    <div style={{ display: 'flex' }}>
                                        <Box sx={{ minWidth: 80 }} md={{ minWidth: 80 }} style={{ width: 200, marginRight: 50 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Field</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    label="Age"
                                                    onChange={(e) => setstate({ ...state, field: e.target.value })}
                                                >
                                                    {FieldValues.map((t, id) => <MenuItem value={t}>{`${t}`}</MenuItem>)}


                                                </Select>
                                            </FormControl>
                                        </Box>



                                        <Box sx={{ minWidth: 80 }} md={{ minWidth: 80 }} style={{ marginLeft: 80 }}>
                                            <FormControl fullWidth>
                                                <TextField id="filled-basic" value={state.key} onChange={(e) => setstate({ ...state, key: e.target.value })} label=" Inquiry Serial code" variant="outlined" />


                                            </FormControl>
                                        </Box>

                                        <Box sx={{ minWidth: 80 }} md={{ minWidth: 80 }} style={{ marginLeft: 80 }}>
                                            <FormControl fullWidth>
                                                <TextField id="filled-basic" value={state.value} onChange={(e) => setstate({ ...state, value: e.target.value })} label="Inquiry Serial Code Description" variant="outlined" />


                                            </FormControl>
                                        </Box>
                                        <Button onClick={handleChange} style={{ width: 180, marginLeft: 50 }} className="btn_secondry" size="small" variant="outlined">Add input</Button>

                                    </div>
                                </CardContent>
                                {/* <Chips/> */}
                                <Typography variant="body1" component="body1" style={{ padding: 10, marginTop: 30 }}>
                                    <span style={{ color: "red" }}>*</span> Please click Save before changing the field
                                </Typography>
                                <CardActions>

                                    <Button
                                        onClick={handleSave}
                                        style={{ width: 180, height: 50, marginBottom: 20, marginTop: 30 }}
                                        className="btn_edit" variant="outlined">Save</Button>
                                    <div className="" style={{ marginLeft: 40, marginTop: 30 }}>
                                        <List state={state} list={listData} />
                                    </div>


                                </CardActions>
                            </Card>
                        </Grid>

                    </Grid>
                </Container>
            </main>

        </ThemeProvider>
    );
}