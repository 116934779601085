import { useRedirect, useRefresh } from "react-admin"
import { showToastError, showToastSuccess } from "../lib/toas";

export const useCustomToast = ({ basePath, successMessage, redirect }) => {
    const redirectTo = useRedirect();
    const refresh = useRefresh();

    const onSuccess = (data) => {

        showToastSuccess(successMessage ?? "Changes Saved");
        redirect ? redirectTo(redirect) : redirectTo("list", basePath);
        refresh();
    }
    
    const onFaliure = (error) => {
        showToastError(`Save Failed: ${error}`);
        redirect ? redirectTo(redirect) : redirectTo("list", basePath);
        refresh();
    }
    return {onSuccess, onFaliure}
}

