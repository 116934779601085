export const CLUSTER_CODES = {
  D: "DSS",
  E: "Export",
  A: "Ansel",
  O: "Other Brand",
  S: "Shadow",
  W: "Own Brands",
};
export const SEASON_CODES = {
  S1: "Season-01",
  S2: "Season-02",
  S3: "Season-03",
  "00": "Other",
};
export const CATEGORY_CODES = {
  A: "(A) Gents Slippers",
  B: "(B) Ladies Slippers",
  C: "(C) Boys Shoes and Sandles",
  D: "(D) Girls Shoes and Sandals",
  E: "(E) Boys School Shoes",
  F: "(F) Girls School Shoes",
  G: "(G) Sport Shoes",
  H: "(H) Gents Shoes",
  I: "(I) Flip Flop",
  J: "(J) Industrial Shoes",
  O: "(O) Other",
};
export const SERIAL_TYPES = {
  P: "Proto",
  D: "Development",
  C: "Confirmation",
  S: "Sales",
  F: "Fit Ton",
  T: "Testing Sample",
};
export const getYears = () => {
  const years = [new Date().getFullYear(), new Date().getFullYear() + 1];
  const returnYears = {} as any;
  returnYears[years[0]] = years[0];
  returnYears[years[1]] = years[1];
  return returnYears;
};
