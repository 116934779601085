import React from "react";
import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";
//import { ToolTipForDeleteButton } from "../../components/ToolTipForDelete";
import { CustomTopToolbar } from "../../components/CustomTopToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";
import { CustomToolbar } from "../../components/CustomToolbar";

export const ItemEdit = (props) => {
  const Title = ({ record }) => {
    return <span>Edit Item | {record ? `${record.code} ` : ""}</span>;
  };
  const customToast = useCustomToast(props);
  return (
    <Edit
      undoable={false}
      onFailure={customToast.onFaliure}
      actions={<CustomTopToolbar name="item" />}
      title={<Title />}
      {...props}
      className={"col-md-6 "}
      onSuccess={customToast.onSuccess}
    >
      <SimpleForm toolbar={<CustomToolbar name="items" text="Item" />}>
        <TextInput source="code" label={<ReqInputField name="Code" />} />
        <TextInput source="unit" label={<ReqInputField name="Unit" />} />
        <BooleanInput source="is_rm" />
      </SimpleForm>
    </Edit>
  );
};
