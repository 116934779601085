export const urlChanger = (path) => {
    let url = ''
    const subUrl = '#/'
    if (path) {
        url = window.location.href.split('#')[0] + subUrl + path
    } else {
        // if path is not given go to dashboard
        url = window.location.href.split('#')[0]
    }
    //return window.location = url
    return window.location.replace(url)

}