import { SET_SELECTED_GROUP } from "../../types";

const initialState = false as any;

export const selectedGroupReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SELECTED_GROUP: {
      return action.payload.id;
    }
    default:
      return state;
  }
};
