import React, { useEffect } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import JsonSchemaEditor from "../../components/input/JsonSchemaEditor";
import { lineAttributesSchema } from "./lineAttributesSchema";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { redirect } from "../../lib/redirect";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.plan_board_group) {
    errors.plan_board_group = "plan board group is required";
  }
  if (!values.code) {
    errors.code = "code is required";
  }

  return errors;
};

export const CreatePlanBoardLine = (props) => {
  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.record &&
      props.location.state.record.plan_board
    ) {
      window.localStorage.setItem(
        "plan_board",
        props.location.state.record.plan_board
      );
    }
  }, []);

  const plan_board_id = window.localStorage.getItem("plan_board");
  const Title = ({ record }) => {
    return <span>Add Head/Line</span>;
  };
  const customToast = useCustomToast({ redirect: redirect });

  return (
    <Create
      title={<Title />}
      {...props}
      className="col-xl-6 col-sm-12"
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar need={false} name="linesHeads" text="PlanBoardLine" />
        }
      >
        {props.location.state && props.location.state.record.sub_contract ? (
          <>
            <ReferenceInput
              label="Sub Contractor"
              source="sub_contract"
              reference="subContracts"
            >
              <SelectInput optionText="code" />
            </ReferenceInput>
            <p> </p>
            <ReferenceArrayInput
              label="Plan Board"
              source="plan_board_ids"
              reference="planBoards"
            >
              <SelectArrayInput optionText="code" />
            </ReferenceArrayInput>
          </>
        ) : (
          <ReferenceInput
            label={<ReqInputField name="Plan Board Group" />}
            source="plan_board_group"
            reference="boardGroup"
            filter={plan_board_id ? { plan_board: plan_board_id } : {}}
          >
            <SelectInput optionText="code" />
          </ReferenceInput>
        )}
        <TextInput source="code" label={<ReqInputField name="Code" />} />
        <TextInput source="initial_configuration" label={<ReqInputField name="Initial Configuration" />} defaultValue="-" />
        {!(props.location.state && props.location.state.record.sub_contract) ||
        !(
          props.location.state && props.location.state.record.withDailyQuantity
        ) ? (
          <div display="inline">
            <TextInput source="efficiency" />
            <TextInput source="carder" />
          </div>
        ) : (
          <div display="inline">
            <TextInput source="daily_quantity" label="Daily Quantity" />
            <TextInput source="daily_op_line_smv" label="Line SMV" />
          </div>
        )}
        <BooleanInput source="skip_planning" label="Skip Planning" />
        <TextInput source="sequence_number" label="Sequence Number" />
        <NumberInput source="tool_rotation_time" label="Tool Rotation Time" />
        <TextInput
          source="compatible_tool_groups"
          label="Compatible Tool Groups"
        />
        <TextInput
          source="supported_operation_groups"
          label="Supported Operation Groups"
        />
        <DateTimeInput source="start_date" label="Start Date" />
        <BooleanInput source="support_item_groups_by_default" />
        <JsonSchemaEditor schema={lineAttributesSchema} source="attributes" />
        <BooleanInput source="duplicate_calendar" label="Duplicate Calendar" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.duplicate_calendar &&
            formData.duplicate_calendar === true && (
              <ReferenceInput
                label="Calendar to duplicate"
                source="dup_calendar"
                reference="boardLine"
                filter={{ plan_board: plan_board_id }}
                perPage={200}
              >
                <AutocompleteInput optionText="code" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.duplicate_calendar &&
            formData.duplicate_calendar === true && (
              <BooleanInput
                source="initial_shifts"
                label="Change default initial shift period?"
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.initial_shifts &&
            formData.initial_shifts === true && (
              <NumberInput
                source="initial_shift_period"
                label="Period to generate shifts (days)"
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
