import _ from "lodash";
import * as uuid from "uuid";
import { IAllocation, IDemandOperation, IPlan } from "../plan/interfaces";
import { Dictionary, EntitiesType } from "./types";

export const getUuid = () => {
    return `${Math.floor(Date.now() / 1000)}-${uuid.v4()}`
}

/**
 * Calculate planned_start data, planned_end_date, planned_quantity, is_planned
 * @param entities 
 * @param demandOperationIds 
 * 
 */
export const getDemandOperationsUpdated = (entities: EntitiesType, demandOperationIds: any[]) => {

    // const demandOperationIds = _.uniq(Object.values(change).map((alloc: Allocation) => alloc.demand_operation_id)).filter(v => !!v)

    // Allocations of each demand operation
    const allocsByDemandOperationId = {} as any
    demandOperationIds.forEach(id => { allocsByDemandOperationId[id] = [] })

    // Go therou each allocation and add them to the dictionary
    Object.values(entities.allocations).forEach((a: IAllocation) => {
        if (a.demand_operation_id in allocsByDemandOperationId) {
            allocsByDemandOperationId[a.demand_operation_id].push(a)
        }
    })

    const updatedDemandsOps = {} as Dictionary<IDemandOperation>
    demandOperationIds.forEach(id => {

        const current = entities.demand_operations[id]
        if (!current.is_exchange) {
            const activeAllocs = allocsByDemandOperationId[id].filter((a: IAllocation) => !a.deleted) as IAllocation[]
            const planned_end_date = _.max(activeAllocs.map(a => a.end_time))
            const planned_start_date = _.min(activeAllocs.map(a => a.start_time))
            const planned_quantity = _.sumBy(activeAllocs, 'quantity')
            const is_planned = current.quantity <= planned_quantity
            updatedDemandsOps[id] = { ...current, planned_end_date, planned_start_date, planned_quantity, is_planned } as IDemandOperation
        }
    })
    return { ...entities, demand_operations: { ...entities.demand_operations, ...updatedDemandsOps } } as EntitiesType
}



/**
 * Get tools availability updated
 * @param entities 
 * @param demandOperationIds 
 * 
 */
export const getToolsAvailabilityUpdated = (entities: IPlan) => {
    // Active Allocations 

    return entities
}


export function getMaximumOverlapCount(times: { start: number, end: number }[]) {
    const events = _.sortBy([...times.map(t => [t.start, 1]), ...times.map(t => [t.end, -1])],["0", "1"] )
    let value = 0
    let max = 0
    events.forEach((event) => {
        value += event[1]
        max = _.max([value, max]) as number
    })
    return max
}

export const getAllocationStatusColor = (planned_quantity: Number, completed_quantity: Number) => {
    if (planned_quantity > 0 && planned_quantity === completed_quantity) {
        return "green"
    }
    else if (completed_quantity > 0)  {
        return "yellow"
    }
    else if (!completed_quantity) {
        return "#0074D9"
    }
    else {
        console.log("allocation color error,", planned_quantity, completed_quantity )
        return ""
    }
}

export const getAllocationStatusColorLight = (planned_quantity: Number, completed_quantity: Number) => {
    const darkColors = ['green', "blue"]
    const color =  getAllocationStatusColor(planned_quantity, completed_quantity)
    if (darkColors.includes(color)) {
        return `light${color}`
    }
    else return color
}

export const getDemandOperationStatusColor = (quantity: Number, planned_quantity: Number, completed_quantity: Number) => {
    if (planned_quantity > 0 && planned_quantity < quantity) {
        return "yellow"
    }
    else if (planned_quantity > 0 && planned_quantity === completed_quantity) {
        return "lightgreen"
    }
    else if (planned_quantity > 0 && planned_quantity === quantity) {
        return "lightblue"
    }
    else if (planned_quantity > 0 && planned_quantity < quantity) {
        return "orange"
    }
    else return ""
}