import React, { cloneElement } from "react";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import {
  // CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  Filter,
  List,
  sanitizeListRestProps,
  // ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  usePermissions,
  WithPermissions,
  downloadCSV,
} from "react-admin";
import CreateButton from "../../components/CustomCreateButton";
import ShowButton from "../../components/CustomShowButton";
import Confirmation from "../../components/Confirmation";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../components/ChangeDiv";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));
const custom_exporter = (workstations) => {
  console.log("Workstations: ", workstations);
  const workstationsForExport = workstations.map((workstation) => {
    workstation.workstation_id = workstation.id;
    workstation.workstation_code = workstation.code;
    workstation.plant_id = workstation.plant;
    workstation.plan_board_id = workstation.plan_board;
    workstation.compatible_workstation_ids =
      workstation.compatible_workstations;
    workstation.plant_name = workstation.plant_name;
    workstation.planboard_code = workstation.planboard_code;
    return workstation;
  });
  const csv = convertToCSV({
    data: workstationsForExport,
    fields: [
      "workstation_id",
      "workstation_code",
      "compatible_workstation_ids",
      "plan_board_id",
      "planboard_code",
      "plant_id",
      "plant_name",
    ],
  });
  downloadCSV(csv, "workstations");
};

const ListActions = (props) => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();

  const createPermissions = () =>
    !permissions?.permissions.includes("workstations_create");
  console.log({ test: permissions?.permissions, test2: createPermissions });

  return (
    <TopToolbar
      className={classes.buttonContainer}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
          className: "btn_",
        })}
      <CreateButton
        basePath={basePath}
        title="Click here to create Workstation"
        disabled={createPermissions()}
        variant="contained"
      />
      <ExportButton
        className="btn_ btn_primary"
        title="Click here to Export Workstations"
        disabled={!permissions?.permissions.includes("workstations_export")}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        variant="contained"
        // color="primary"
      />
    </TopToolbar>
  );
};

const WorkstationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by code" source="code" alwaysOn />
  </Filter>
);

export const Workstations = (props) => {
  const plant = localStorage.getItem("plant");
  const site = localStorage.getItem("site");
  const { permissions } = usePermissions();

  return (
    <List
      {...props}
      exporter={custom_exporter}
      actions={<ListActions />}
      filters={<WorkstationFilter />}
      bulkActionButtons={false}
      filter={
        ["PLANT", "BASIC"].includes(permissions?.access_level)
          ? { plant: plant?plant:-1 }  : ["SITE"].includes(permissions?.access_level)?{ site: site?site:-1 }:null
      }
      perPage={25}
    >
      <ChangeDiv>
        {props => {
          return <Datagrid>
          <TextField source="id" />
          <TextField source="code" />
          <TextField source="plant_name" label="Plant" sortBy="plant__name"/>
          <TextField source="plan_board_code" label="Plan Board " sortBy="plan_board__code"/>
          <EditButton
            {...props}
            title="Click here to Edit Workstation"
            className="btn_ btn_edit"
            disabled={!permissions?.permissions.includes("workstations_edit")}
          />
          <Confirmation
            title="Click here to Delete Workstation"
            disabled={!permissions?.permissions.includes("workstations_delete")}
            text="workstations"
          />
          <ShowButton
            title="Click here to Show Workstation"
            className="btn_ btn_secondry"
            disabled={!permissions?.permissions.includes("workstations_show")}
          />
        </Datagrid>
        }}
      </ChangeDiv>
        
      
    </List>
  );
};
