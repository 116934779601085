import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import SmallLoading from "../../../components/loading/SmallLoading";
import {
  Create,
  Query,
  Error,
  AutocompleteInput,
  SimpleForm,
  Loading,
} from "react-admin";
import dataProvider from "../../../api/dataProvider";
import { Button, Checkbox } from "@material-ui/core";
import { showToastSuccess, showToastError } from "../../../lib/toas";
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const TemplatesCreate = (props) => {
  const [queryData, setQueryData] = useState({
    tna_tasks: [],
    tna_departments: [],
    users: [],
  });

  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const urlParams = new URLSearchParams(props.location?.search);
  const entries = urlParams.entries();
  const source = paramsToObject(entries)["source"];
  const params = source ? JSON.parse(source) : null;

  const templateItems = params?.template_items;

  const initialRowData =
    templateItems &&
    templateItems.map((item) => ({
      ...item,
      dependent_item: item.dependent_item
        .map((di) => templateItems.find((i) => i.id === di).sequence_no)
        .toString(),
    }));
  const initialType = params?.type;
  const initialDescription = params?.description;

  const [rowdata, setRowData] = useState(initialRowData ? initialRowData : []);
  const [flow, setFlow] = useState(initialType ? initialType : "");
  const [templateCode, setTemplateCode] = useState();
  const [templateDesc, setTemplateDesc] = useState(
    initialDescription ? initialDescription : ""
  );

  const onAddRowClick = () => {
    setRowData(
      rowdata.concat({
        sequence_no: "",
        task: "",
        assigned_department: "",
        assigned_user: "",
        start_offset_days: "",
        duration: "",
        need_approval: false,
      })
    );
  };
  let history = useHistory();
  const redirect = (id) => {
    history.push("/tna_templates");
  };

  function clearDependents(field, dependentField, val, updatingRow) {
    if (field == "need_approval" && val == false) {
      updatingRow["transferred_to"] = "";
    }
  }

  function validateDependentTasks(field,value,updatingRow) {
    if(field=="dependent_item"){
      const dependentTasks=value.split(",")
      if(dependentTasks.includes(updatingRow["sequence_no"].toString())){
        return false
      }else{
        return true
      }
    }else{
      return true
    }
  }
  const updateData = (row, col, value) => {
    let row_to_update = rowdata[row];
    const prevValue= row_to_update[col]
    row_to_update[col] = value;
    const isValidDependents=validateDependentTasks(col,value,row_to_update)
    if (!isValidDependents){
      showToastError("Template task is in its dependent tasks")
      row_to_update[col] = prevValue;
    }
    clearDependents(col, "transferred_to", value, row_to_update);
    setRowData([
      ...rowdata.slice(0, row),
      row_to_update,
      ...rowdata.slice(row + 1),
    ]);
  };
  const removeRow = (row) => {
    console.log("removing row");
    setRowData([...rowdata.slice(0, row), ...rowdata.slice(row + 1)]);
  };
  const HeaderCell = ({ value }) => (
    <div
      style={{
        padding: 5,
        backgroundColor: "#c2e8ff",
        textAlign: "center",
        width: window.innerWidth * 0.08,
        minHeight: 35,
        fontSize: "14px",
      }}
    >
      {value}
    </div>
  );

  const saveInvalid = () => {
    return (
      rowdata.length == 0 ||
      rowdata?.filter((d) => d.start_offset_days == null || d.duration == null)
        .length > 0 ||
      rowdata?.filter((d) => d.sequence_no != 1 && d.dependent_item == null)
        .length > 0
    );
  };
  const saveTemplate = () => {
    if (saveInvalid()) {
      showToastError(
        "Please add rows and fill all required fields before saving template"
      );
    } else if (rowdata && flow) {
      const template_data = {
        type: flow,
        template_items: rowdata,
        code: templateCode,
        description: templateDesc,
      };
      dataProvider
        .TNA_CUSTOM_ACTION("templates", {
          body: template_data,
          action: "create_template",
        })
        .then((e) => {
          if (e.data) {
            if (e.data.success) {
              showToastSuccess("Template Creation Success")
              redirect();
            }
          }
        })
    } else if (!flow) {
      showToastError("Please select template type");
    } else {
      showToastError("Error creating template");
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: <HeaderCell value="Sequence No" />,
        accessor: "sequence_no",
        Cell: (props) => <EditableCell {...props} updateData={updateData} />,
      },
      {
        Header: <HeaderCell value="Task" />,
        accessor: "task",
        Cell: (props) => (
          <EditableCell
            {...props}
            isQuery={{ resource: "tna_tasks" }}
            queryData={queryData}
            updateData={updateData}
            flow={flow}
          />
        ),
      },
      {
        Header: <HeaderCell value="Department" />,
        accessor: "assigned_department",
        Cell: (props) => (
          <EditableCell
            {...props}
            isQuery={{ resource: "tna_departments" }}
            queryData={queryData}
            updateData={updateData}
          />
        ),
      },
      {
        Header: <HeaderCell value="Assignee" />,
        accessor: "assigned_user",
        Cell: (props) => (
          <EditableCell
            {...props}
            isQuery={{ resource: "users" }}
            queryData={queryData}
            updateData={updateData}
            rowData={rowdata}
          />
        ),
      },
      {
        Header: <HeaderCell value="Start day" />,
        accessor: "start_offset_days",
        Cell: (props) => <EditableCell {...props} updateData={updateData} />,
      },
      {
        Header: <HeaderCell value="Duration" />,
        accessor: "duration",
        Cell: (props) => <EditableCell {...props} updateData={updateData} />,
      },
      {
        Header: <HeaderCell value="Dependent tasks" />,
        accessor: "dependent_item",
        Cell: (props) => <EditableCell {...props} updateData={updateData} />,
      },
      {
        Header: <HeaderCell value="Need approval" />,
        accessor: "need_approval",
        Cell: (props) => (
          <EditableCell
            {...props}
            isQuery={{ bool: true }}
            updateData={updateData}
          />
        ),
      },

      {
        Header: <HeaderCell value="Transfer to" />,
        accessor: "transferred_to",
        Cell: (props) => (
          <EditableCell
            {...props}
            disabled={!props["row"]["values"]["need_approval"]}
            isQuery={{ resource: "users" }}
            queryData={queryData}
            updateData={updateData}
          />
        ),
      },
      {
        Header: <HeaderCell value="" />,
        accessor: "close",
        Cell: (props) => (
          <Button
            variant="contained"
            style={{
              maxWidth: 30,
              backgroundColor: "red",
              color: "white",
              marginLeft: window.innerWidth * 0.025,
            }}
            onClick={() => {
              removeRow(parseInt(props.row.id));
            }}
          >
            X
          </Button>
        ),
      },
    ],
    [rowdata]
  );

  const View = () => {
    return (
      <div>
        <button
          disabled={flow.length == 0}
          onClick={onAddRowClick}
          style={{ borderRadius: 5, borderColor: "transparent", margin: 15 }}
        >
          Add Row
        </button>
        Select type
        <select
          disabled={rowdata.length > 0}
          value={flow}
          onChange={(e) => setFlow(e.target.value)}
          style={{ marginLeft: 5, marginRight: 20 }}
        >
          <option value=""></option>
          <option value="ORDER_FLOW">Order Flow</option>
          <option value="INQUIRY_FLOW">Inquiry Flow</option>
        </select>
        Template Code
        <input
          style={{ marginLeft: 5, marginRight: 20 }}
          type="text"
          value={templateCode}
          onChange={(e) => setTemplateCode(e.target.value)}
        ></input>
        Template Description
        <input
          style={{ marginLeft: 5, marginRight: 20 }}
          type="text"
          value={templateDesc}
          onChange={(e) => setTemplateDesc(e.target.value)}
        ></input>
        <button
          disabled={!templateCode}
          style={{ borderRadius: 5, borderColor: "transparent" }}
          onClick={saveTemplate}
        >
          Save
        </button>
        <div className="">
          <CustomTable columns={columns} data={rowdata} />
        </div>
      </div>
    );
  };

  const payload = {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: "id", order: "ASC" },
  };

  return (
    <Query type="getList" resource={"users"} payload={payload}>
      {({ data, loading, error }) => {
        if (data) {
          data = data.filter((d) => d.app_user.access_level.includes("TNA_"));
          queryData["users"] = data;
          return (
            <Query
              type="getList"
              resource={"tna_departments"}
              payload={payload}
            >
              {({ data, loading, error }) => {
                if (data) {
                  queryData["tna_departments"] = data;
                  return (
                    <Query
                      type="getList"
                      resource={"tna_tasks"}
                      payload={payload}
                    >
                      {({ data, loading, error }) => {
                        if (data) {
                          queryData["tna_tasks"] = data;
                          return View();
                        }
                        if (loading) {
                          return <Loading />;
                        }
                        if (error) {
                          return <Error />;
                        }
                        return null;
                      }}
                    </Query>
                  );
                }
                if (loading) {
                  return <Loading />;
                }
                if (error) {
                  return <Error />;
                }
                return null;
              }}
            </Query>
          );
        }
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Error />;
        }
        return null;
      }}
    </Query>
  );
};

const CustomTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable({
    columns,
    data,
  });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th className="" {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="" {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td className="" {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const EditableCell = ({
  isQuery,
  queryData,
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  flow,
  rowData,
  disabled = false,
}) => {
  const [value, setValue] = useState(initialValue);
  const [boolVal, setBoolVal] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
    if (isQuery?.resource) {
      updateData(index, id, e.target.value);
    }
  };
  const onChangeUser = (event, newValue) => {
    if (isQuery?.resource) {
      updateData(index, id, newValue.id);
    }
  };

  const onBlur = () => {
    updateData(index, id, value);
  };
  const onToggle = (val) => {
    setBoolVal(val);
    updateData(index, id, val);
  };

  const filterTasks = (d) => {
    if (isQuery?.resource == "tna_tasks") {
      if (flow == "ORDER_FLOW") return !d.is_inquiry_task;
      else return d.is_inquiry_task;
    } else if (isQuery?.resource == "users" && rowData) {
      return (
        rowData[index]["assigned_department"] == d.app_user?.tna_department?.id
      );
    } else return true;
  };
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return isQuery?.resource ? (
    isQuery?.resource === "users" ? (
      <Autocomplete
        disabled={disabled}
        disableClearable
        style={{
          border: "none",
          backgroundColor: "transparent",
          width: window.innerWidth * 0.08,
        }}
        id="combo-box-demo"
        getOptionLabel={(user) => user.username?user.username: "" }
        options={ 
          [{id:"", username:"", tna_department:"" }, ...queryData["users"].filter(filterTasks)]
        }
        defaultValue={
          queryData["users"]
          .find((v) => v.id === initialValue)
        }
        value={queryData["users"].find((v) => v.id === initialValue)}
        onChange={(event, newValue) => onChangeUser(event, newValue)}
        renderInput={
          (params) => <TextField 
            {...params} 
            fullWidth 
            InputProps={{ ...params.InputProps, disableUnderline: true }} 
          />}
      />
    ) : (
      <select
        disabled={disabled}
        style={{
          border: "none",
          backgroundColor: "transparent",
          width: window.innerWidth * 0.08,
        }}
        value={value}
        onChange={onChange}
      >
        {[{}]
          .concat(queryData[isQuery?.resource].filter(filterTasks))
          .map((d, id) => {
            return (
              <option key={id} value={d.id}>
                {d.title || d.name || d.username}
              </option>
            );
          })}
      </select>
    ) 
  ) : isQuery?.bool ? (
    <Checkbox
      style={{ marginLeft: window.innerWidth * 0.03 }}
      value={boolVal}
      checked={boolVal}
      onChange={() => onToggle(!boolVal)}
    />
  ) : (
    <input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={{ maxWidth: window.innerWidth * 0.08 }}
      type="text"
    />
  );
};
