import React from "react";
import {
  Edit,
  useNotify,
  useRefresh,
  useRedirect,
  SimpleForm,
  TextInput,
} from "react-admin";

import { SaveButton, DeleteButton, Toolbar, usePermissions } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../../components/Confirmation";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { showToastError } from "../../../lib/toas";

//const redirect = (basePath, id, data) => `/planBoards/${data.plan_board}/show/tool`

export const DelayReasonEdit = (props) => {
  const { permissions } = usePermissions();

  const Title = ({ record }) => {
    return <span>Edit Delay Reasons | {record ? `${record.code} ` : ""}</span>;
  };

  const onFailure = (e) => {
    if (e) {
      showToastError(
        `Save Failed`
      );
    }
  };

  const customToast = useCustomToast(props);

  return (
    <Edit  onFailure={onFailure} className="col-md-6" onSuccess={customToast.onSuccess} undoable={false} {...props} title="Edit Delay Reason">
      <SimpleForm
        toolbar={<CustomToolbar name="tna_delay_reasons" text="Reason" />}
      >
        <TextInput disabled source="id" />
        <TextInput source="delay_reason" />
      </SimpleForm>
    </Edit>
  );
};
