import { REFRESH_VIEW } from "react-admin";
import { put, select, takeEvery } from "redux-saga/effects";
import dataProvider from "../../api/dataProvider";
import { loadTNANotifications, setTNANotifications, loadTNANotificationsSuccess, setNotificationRead } from "../actions/tnaNotificationActions";
import { LOAD_TNA_NOTIFICATIONS, MARK_NOTIFICATION_READ, SET_TNA_NOTIFICATIONS } from "../types/tnaNotificationTypes";
import { showToast } from "../../lib/toas";

//toast.configure();

export function* tnaNotificationSaga() {
    yield takeEvery(LOAD_TNA_NOTIFICATIONS, function* () {
        const resp = yield dataProvider.TNA_CUSTOM_LIST("notifications", {
            action: "getTNANotifications"
        });
        const allTNANotifications = resp.data;
        if (allTNANotifications) {
            const sortNotificationBasedOnRead = (array: Array<object>) => {
                const readArray: Array<object> = [];
                const unReadArray: Array<object> = [];

                array.map((data: any) => {
                    if (data.is_read) {
                        readArray.push(data);
                    } else {
                        unReadArray.push(data);
                    }
                });
                return { readArray, unReadArray };
            };
            const allSortedArray = sortNotificationBasedOnRead(
                allTNANotifications
            );

            const sortByDate = (array: Array<object>) => {
                return array.sort((a: any, b: any) => {
                    let dateA: any = new Date(a.time_stamp);
                    let dateB: any = new Date(b.time_stamp);
                    return dateB - dateA;
                });
            };

            const readArray = sortByDate(allSortedArray.readArray);
            const unReadArray = sortByDate(allSortedArray.unReadArray);
            const unReadCount = unReadArray.length
            const readCount = readArray.length

            // set notification object

            const data = {
                unReadCount: unReadCount,
                readCount: readCount,
                allCount: unReadCount + readCount,
                allTNANotifications: resp.data,
                read: readArray,
                unRead: unReadArray,
            };

            yield put(setTNANotifications(data));
        }
        yield put(loadTNANotificationsSuccess());
    });

    yield takeEvery(MARK_NOTIFICATION_READ, function* (action: any) {
        const notification_id = action.payload
        const resp = yield dataProvider.TNA_CUSTOM_ACTION("notifications", {
            body: {
                "notificationId": notification_id,
            },
            action: "mark_read",
        });

        if (resp.data.success) {
            yield put(setNotificationRead(resp.data.readNotification))
        }
    })


}
