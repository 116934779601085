import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { useDataProvider } from "react-admin";

const BorderLinearProgress = withStyles({
  root: {
    height: 5,
    backgroundColor: "#f2f7f4",
    width: "100%",
  },
  bar: {
    backgroundColor: "#14e34b",
  },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const ProgressBars = () => {
  const dataProvider = useDataProvider();

  const [cutting, setCutting] = React.useState(100);
  const [molding, setMolding] = React.useState(100);
  const [sewing, setSewing] = React.useState(0);
  const classes = useStyles();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Tooltip title={cutting === 0 ? "20/1/2020 " : ""}>
        <BorderLinearProgress
          // className={classes.margin}
          variant="determinate"
          color="secondary"
          value={cutting}
        />
      </Tooltip>
      <Tooltip title={molding === 0 ? "20/1/2020 " : ""}>
        <BorderLinearProgress
          variant="determinate"
          color="secondary"
          value={molding}
        />
      </Tooltip>
      <Tooltip title={sewing === 0 ? "20/1/2020 " : ""}>
        <BorderLinearProgress
          variant="determinate"
          color="secondary"
          value={sewing}
        />
      </Tooltip>
    </div>
  );
};
export default ProgressBars;
