import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useEffect } from 'react';
import moment from 'moment';
import { PRIMARY_COLOR } from '../../constants';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        maxWidth: '99%'
    },
    box: {
        display: 'flex',
        justifyContent: 'start',
        paddingLeft: 40
    },
    tableBorder:{
        borderWidth:'thin',
        borderStyle:'solid',
        borderColor:PRIMARY_COLOR,
        padding:'4px'
    }
}); 

function createData(month, totalCapacity, capacityUtilPercent, requiredHours,operationLoss, switching, current, previous, advanced, effective,idealCapacity,currentEffective,delayEffective) {
    return { month, totalCapacity, capacityUtilPercent, requiredHours,operationLoss, switching, current, previous, advanced, effective,idealCapacity,currentEffective,delayEffective };
}

export function ChartDataTable({ data }) {
    const classes = useStyles();
    const [rows, setRows] = React.useState();

    useEffect(() => {
        function formatData(data) {
            var details = []
            data.forEach(element => {
                var month = moment(new Date(element["month"])).format("MMMM YYYY");
                var totalCapacity = element['totalMaxUtilHours'] ? element['totalMaxUtilHours'] : 0
                var operationLoss=element["operationLossHours"]?element["operationLossHours"]:0
                var switching = element['switchingHours'] ? element['switchingHours'] : 0
                var current = element['currentOrderHours'] ? element['currentOrderHours'] : 0
                var previous = element['previousOrderHours'] ? element['previousOrderHours'] : 0
                var advanced = element['advancedOrderHours'] ? element['advancedOrderHours'] : 0
                var currentEffective = element['currentEffectiveOrderHours'] ? element['currentEffectiveOrderHours'] : 0
                var delayEffective = element['delayEffectiveOrderHours'] ? element['delayEffectiveOrderHours'] : 0
                var effective = (currentEffective+delayEffective).toFixed(2)
                
                var requiredHours = (switching + current + previous + advanced + currentEffective+delayEffective).toFixed(2)
                var capacityUtilPercent = ((requiredHours / totalCapacity) * 100).toFixed(2)
                var idealCapacity=(totalCapacity-(switching + current + previous + advanced)).toFixed(2)
                details.push(createData(month, totalCapacity, capacityUtilPercent, requiredHours,operationLoss, switching, current, previous, advanced, effective,idealCapacity,currentEffective,delayEffective))
            });
            setRows(details)
        }
        if (data) {
            formatData(data)
        }
    }, [data])

    return (
        <div className={classes.box}>
            <TableContainer className={classes.container} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableBorder} rowSpan={2} align="center">Month</TableCell>
                            <TableCell className={classes.tableBorder} rowSpan={2} align="center">Total Capacity</TableCell>
                            <TableCell className={classes.tableBorder} rowSpan={2} align="center">Capacity Utilization (Min%)&nbsp;</TableCell>
                            <TableCell className={classes.tableBorder} rowSpan={2} align="center">Required Hours(Min)&nbsp;(H)</TableCell>
                            <TableCell className={classes.tableBorder} rowSpan={2} align="center">Lost Operational Capacity&nbsp;(H)</TableCell>
                            <TableCell className={classes.tableBorder} rowSpan={2} align="center">Switching&nbsp;(H)</TableCell>
                            <TableCell className={classes.tableBorder} colSpan={4} align="center">Planned Utilization&nbsp;(H)</TableCell>
                            <TableCell className={classes.tableBorder} rowSpan={2} align="center">Idle Capacity&nbsp;(H)</TableCell>
                            <TableCell className={classes.tableBorder} colSpan={2} align="center"> Effective Order Utilization&nbsp;(H)</TableCell>
                            
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">Current&nbsp;</TableCell>
                            <TableCell align="right">Previous&nbsp;</TableCell>
                            <TableCell align="right">Advanced&nbsp;</TableCell>
                            <TableCell align="right">Effective&nbsp;</TableCell>
                            <TableCell align="right">Current Effective&nbsp;</TableCell>
                            <TableCell align="right">Delay Effective&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row) => (
                            <TableRow key={row.month}>
                                <TableCell component="th" scope="row">
                                    {row.month}
                                </TableCell>
                                <TableCell align="right">{row.totalCapacity}</TableCell>
                                <TableCell align="right">{row.capacityUtilPercent}</TableCell>
                                <TableCell align="right">{row.requiredHours}</TableCell>
                                <TableCell align="right">{row.operationLoss}</TableCell>
                                <TableCell align="right">{row.switching}</TableCell>
                                <TableCell align="right">{row.current}</TableCell>
                                <TableCell align="right">{row.previous}</TableCell>
                                <TableCell align="right">{row.advanced}</TableCell>
                                <TableCell align="right">{row.effective}</TableCell>
                                <TableCell align="right">{row.idealCapacity}</TableCell>
                                <TableCell align="right">{row.currentEffective}</TableCell>
                                <TableCell align="right">{row.delayEffective}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>    
                </Table>
            </TableContainer>
        </div>

    );
}
