import "../../layout/styles/View.css";

import { Grid, Typography, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MTextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  Mutation,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  useNotify,
  useRefresh,
  Tab,
  TabbedShowLayout,
  TextField,
  FormDataConsumer,
  usePermissions,
  useListContext,
} from "react-admin";
import CSVReader from "react-csv-reader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import CustomDateField from "../../components/field/CustomDateField";
import { getChangedOrders } from "../../store/selectors";
import LineActions from "./LineActions";
import { BulkDeleteButton } from "react-admin";

import LineExpand from "./LineExpand";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { queryUrlLastString } from "../../lib/common";
import { ButtonGroup } from "@material-ui/core";
// const data = [
//   { text: "PROJECT 1", id: 1, color: "#cb6bb2" },
//   { text: "PROJECT 2", id: 2, color: "#56ca85" },
//   { text: "PROJECT 3", id: 3, color: "#df5286" },
// ];
// const categoryData = [
//   { text: "Development", id: 1, color: "#1aaa55" },
//   { text: "Testing", id: 2, color: "#7fa900" },
// ];
const useStyles = makeStyles({
  fullWidth: {
    width: "50%",
  },
});

export const ShowPlanBoardComponent = (props) => {
  const [lineFilter, setLineFilter] = useState("");
  const [toolFilter, setToolFilter] = useState("");

  const { permissions } = usePermissions();

  const { id, code, resource } = props;
  // const { dataProvider } = props;

  const {
    location: { search },
  } = props;
  // const searchObject = parse_query_string(search);

  const AddNewToolButton = ({ record }) => {
    return (
      <Button
        title="Click here to add new tool"
        disabled={!permissions?.permissions.includes("tools_create")}
        className="btn_ btn_primary mb-0"
        variant="contained"
        component={Link}
        to={{
          pathname: "/tools/create",
          state: { record: { plan_board: record.id } },
        }}
      >
        Add new tool
      </Button>
    );
  };

  const AddNewGroupButton = ({ record }) => {
    // console.log(props)
    // console.log({ record: { plan_board: record.id, code: record.code } })
    // console.log(record)
    return (
      <Button
        title="Click here to add new group"
        disabled={
          !permissions?.permissions.includes("planBoardGroups_create")
        }
        className="btn_ btn_primary mb-0 ml-0"
        variant="contained"
        component={Link}
        to={{
          pathname: "/boardGroup/create",
          state: { record: { plan_board: record.id } },
        }}
      >
        Add new group
      </Button>
    );
  };

  const UploadToolsButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(false);

    // const ItemFilter = (props) => (
    //   <Filter {...props}>
    //     <TextInput label="search item by code" source="code" alwaysOn />
    //   </Filter>
    // );
    const notify = useNotify();
    const refresh = useRefresh();

    return (
      <>
        <Button
          title="Click here to upload tools"
          disabled={!permissions?.permissions.includes("tools_upload")}
          variant="contained"
          className="btn_ btn_primary mb-2 ml-0"
          onClick={() => setIsOpen(true)}
        >
          {" "}
          Upload
        </Button>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={isOpen}
          onClose={() => setIsOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <Grid container>
            <Grid item xs={12} sm={10}>
              <DialogTitle>Upload Tools</DialogTitle>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 10,
                  marginRight: 10,
                }}
              >
                <CloseIcon onClick={() => setIsOpen(false)} />
              </div>
            </Grid>
          </Grid>
          <DialogContent>
            <CSVReader onFileLoaded={(data) => setData(data)} />
            {data && <div>Records {data.length - 1}</div>}
            <DialogActions>
              {/* <Button onClick={() => setIsOpen(false)}>Close</Button> */}
              {data && (
                <Mutation
                  type="CUSTOM"
                  resource={resource}
                  payload={{
                    action: "save_tool_csv_records",
                    method: "POST",
                    id: id,
                    body: data,
                  }}
                  options={{
                    mutationMode: "undoable",
                    onSuccess: ({ data }) => {
                      notify(" approved CSV", "info", {}, true);
                      refresh();
                    },
                    onFailure: (error) =>
                      notify("Error: csv not approved", "warning"),
                  }}
                >
                  {(action) => (
                    <Button
                      variant="contained"
                      className="btn_ btn_primary"
                      onClick={() => {
                        setIsOpen(false);
                        action();
                      }}
                    >
                      {" "}
                      Save
                    </Button>
                  )}
                </Mutation>
              )}
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  // const DeleteBulkActionButtons = (props) => (
  //   <>
  //     {/* default bulk delete action */}
  //     <BulkDeleteButton {...props} />
  //   </>
  // );

  // const ToolFilter = (props) => (
  //   <Filter {...props}>
  //     <TextInput label="Search type" source="type" alwaysOn />
  //   </Filter>
  // );

  // const LineFilter = (props) => (
  //   <Filter {...props}>
  //     <TextInput label="Search code" source="code" alwaysOn />
  //   </Filter>
  // );

  // const redirect = (basePath, id, data) =>
  //   `/planBoards/${data.plan_board}/show/tool`;
  //const [perPageHistory, setPerPageHistory] = useState();
  //console.log(perPageHistory ? perPageHistory : "null");

  const CustomPagination = (props) => {
    const { perPage, setPage } = useListContext();
    const [pp, setPp] = useState(perPage);
    React.useEffect(() => {
      if (pp !== perPage) {
        setPage(1);
        localStorage.setItem(`${props.perPageHistory}`, perPage);
        setPp(perPage);
      }
    }, [perPage]);
    return (
      <Pagination rowsPerPageOptions={props.rowsPerPageOption} {...props} />
    );
  };

  const CustomEditButton = (props) => {
    return (
      <Button
        title="Click here to edit lines/head"
        disabled={!permissions?.permissions.includes("linesHeads_edit")}
        variant="contained"
        className="btn_ btn_edit"
        component={Link}
        // query={{plan_board_id: props.record.plan_board}}
        to={{
          pathname: `/boardLine/${props.record?.id}`, //?plan_board_id=${props.record.plan_board}`,
          state: { record: { plan_board: props.record?.plan_board } },
        }}
      >
        Edit
      </Button>
    );
  };

  // const ToolsPagination = (props) => {

  //   props.setPage(1)
  //   return(
  // <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />)}
  // ;

  const [selectedLineIds, setSelectedLineIds] = useState([]);

  const BulkSelect = ({ record }) => {
    return (
      <input
        type="checkbox"
        value={
          selectedLineIds && selectedLineIds.includes(record?.id) ? true : false
        }
        onClick={() => {
          console.log(selectedLineIds);
          if (selectedLineIds.includes(record?.id)) {
            console.log("false");
            const arr = selectedLineIds;
            for (var i = 0; i < arr.length; i++) {
              if (arr[i] === record?.id) {
                arr.splice(i, 1);
              }
            } //=> [1, 2, 3, 4, 6, 7, 8, 9, 0]
            setSelectedLineIds(arr);
          } else {
            console.log(record?.id, "true");

            const arr = selectedLineIds;
            arr.push(record?.id);
            setSelectedLineIds(arr);
          }
        }}
      />
    );
  };

  const LineBulkActions = (ids) => {
    return (
      <Card style={{ padding: 10 }}>
        <Button color="secondary">Test</Button>
      </Card>
    );
  };

  const classes = useStyles();

  const CustomDiv = (props) => {
    const { children, ...rest } = props;
    return (
      <Card class="button-card" style={{ padding: 10 }}>
        <ButtonGroup className="mb-0">{children(rest)}</ButtonGroup>
      </Card>
    );
  };

  return (
    <Show
      {...props}
      title={<Title />}
      actions={null}
      // this class control width based on url
      className={queryUrlLastString("show") ? classes.fullWidth : ""}
    >
      <TabbedShowLayout>
        {permissions?.permissions.includes("planboards_summaryTab") && (
          <Tab label="summary" style={{ width: "50%" }}>
            <TextField
              label="Id"
              source="id"
              className="col-xl-4 col-sm-12 sub_con_lab mb-3"
            />
            <TextField
              source="code"
              className="col-xl-4 col-sm-12 sub_con_lab mb-3"
            />
            <TextField
              source="algorithm"
              className="col-xl-4 col-sm-12 sub_con_lab mb-3"
            />
            <TextField
              source="export_type"
              className="col-xl-4 col-sm-12 sub_con_lab mb-3"
            />
            <TextField
              source="export_smvunit"
              className="col-xl-4 col-sm-12 sub_con_lab mb-3"
            />
          </Tab>
        )}
        {permissions?.permissions.includes("tools") && (
          <Tab label="Tools" path="tool">
            <CustomDiv>
              {(props) => {
                return (
                  <>
                    <UploadToolsButton />
                    <AddNewToolButton record={props.record} />
                  </>
                );
              }}
            </CustomDiv>
            <MTextField
              className="mt-0"
              type="text"
              placeholder="Type"
              name="search"
              variant="filled"
              alwaysOn
              onChange={(e) => setToolFilter(e.target.value)}
            />
            <ReferenceManyField
              label="Tools"
              reference="tools"
              target="plan_board"
              addLabel={false}
              perPage={localStorage.perPageHistoryTools || 25}
              pagination={
                <CustomPagination
                  perPageHistory="perPageHistoryTools"
                  rowsPerPageOption={[25, 50, 100]}
                />
              }
              bulkActions={true}
              filter={{ type: toolFilter }}
            >
              {/* <DumbList
          // bulkActionButtons={<DeleteBulkActionButtons />}
          // filters={<ToolFilter />}
          // perPage={500}
          // pagination={false}
          //   filter={{ plan_board: props.id }}
          > */}
              <Datagrid>
                <TextField source="id" />
                <TextField source="type" />
                <TextField source="size" />
                <TextField source="compatibility_group" />
                <TextField source="count" />
                <EditButton
                  title="Click here to Edit tools"
                  className="btn_ btn_edit"
                  disabled={!permissions?.permissions.includes("tools_edit")}
                />
                <DeleteButton
                  className="btn_ btn_delete mt-0"
                  title="Click here to delete tools"
                  disabled={!permissions?.permissions.includes("tools_delete")}
                  basePath={"tool"}
                />
              </Datagrid>
              {/* </DumbList> */}
            </ReferenceManyField>
          </Tab>
        )}
        {permissions?.permissions.includes("planBoardGroups") && (
          <Tab label="Groups" path="boardGroup">
            <CustomDiv>
              {(props) => {
                return (
                  <AddNewGroupButton record={props.record} /> 
                );
              }}
            </CustomDiv>
            {/* <AddNewButton /> */}
            {/* permissions added /> */}
            {/* <List {...props} bulkActionButtons={<DeleteBulkActionButtons />}> */}
            <ReferenceManyField
              label="Planboard Groups"
              reference="boardGroup"
              target="plan_board"
              addLabel={false}
              pagination={<Pagination perPage={25} />}
              bulkActions={true}
            >
              <Datagrid>
                <TextField source="id" />
                <TextField source="plan_board" />

                <TextField source="code" label="Plan Board group code" />
                <TextField
                  source="switching_time"
                  label="Switching time (hours)"
                />
                <TextField source="supported_colors" />
                <TextField source="supported_color_count" />
                {/* <ReferenceField
                link=""
                label="Calendar name"
                source="calendar"
                reference="calendar"
              >
                <TextField source="name" />
              </ReferenceField> */}
                <EditButton
                  className="btn_ btn_edit"
                  title="permissions"
                  disabled={
                    !permissions?.permissions.includes("planBoardGroups_edit")
                  }
                />
                <DeleteButton
                  className="btn_ btn_delete mt-0"
                  title="permissions"
                  disabled={
                    !permissions?.permissions.includes("planBoardGroups_delete")
                  }
                  basePath={"boardGroup"}
                />
              </Datagrid>
            </ReferenceManyField>
            {/* </List> */}
          </Tab>
        )}
        {permissions?.permissions.includes("linesHeads") && (
          <Tab label="Lines/Heads" path="boardLine">
            {/* <AddNewButton /> */}

            <LineActions
              props={{ ...props, selectedLineIds, setSelectedLineIds }}
            />

            <MTextField
              type="text"
              placeholder="Code"
              name="search"
              variant="filled"
              alwaysOn
              onChange={(e) => setLineFilter(e.target.value)}
            />
            <ReferenceManyField
              label="Planboard Lines"
              reference="boardLine"
              target="plan_board_group__plan_board_id"
              addLabel={false}
              pagination={
                <CustomPagination
                  perPageHistory="perPageHistoryLH"
                  rowsPerPageOption={[5, 10, 25]}
                />
              }
              perPage={localStorage.perPageHistoryLH || 25}
              filter={{ code: lineFilter }}
              // filters={<ToolFilter />}
              bulkActions={
                <BulkDeleteButton
                  title="permissions"
                  disabled={
                    !permissions?.permissions.includes("linesHeads_bulkDelete")
                  }
                />
              }
            >
              <Datagrid expand={<LineExpand />}>
                <BulkSelect />
                <TextField source="id" />
                <TextField source="plan_board_group" />
                <TextField source="code" />
                <TextField emptyText="N/A" source="initial_configuration" />
                {/* <TextField source="calendar" /> */}
                <NumberField source="tool_rotation_time" />
                <TextField
                  emptyText="N/A"
                  source="supported_operation_groups"
                />
                <TextField source="compatible_tool_groups" />
                <CustomDateField emptyText="N/A" source="start_date" />
                <TextField source="efficiency" />
                <TextField source="carder" />
                {/* <TextField source="attributes" /> */}
                <TextField source="plan_board" />
                <CustomEditButton /> {/* permissions added /> */}
                {/* <EditButton /> */}
                <DeleteButton
                  className="btn_ btn_delete mt-0"
                  title="Click here to delete Lines/Heads"
                  disabled={
                    !permissions?.permissions.includes("linesHeads_delete")
                  }
                  basePath={"boardLine"}
                />
              </Datagrid>

              {/* </DumbList> */}
            </ReferenceManyField>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

//upload button
const mapStateToProps = (state) => {
  return {
    changedOrders: Object.keys(getChangedOrders(state)),
  };
};
const Title = ({ record }) => {
  return <span>Planboard | {record ? `${record.code}` : ""}</span>;
};
export const showPlanBoard = connect(
  mapStateToProps,
  {}
)(ShowPlanBoardComponent);

// const DumbList = (props) => (
//   <ListController {...props}>
//     {(controllerProps) => {
//       let { data } = props;
//       const ids = Object.keys(data || {});
//       const total = ids.length;
//       return (
//         <ListView
//           {...props}
//           pagination={false}
//           // This is important, otherwise bulkActionsToolbar ends up on very top of the page
//           // classes={{ card: "relative" }}
//           {...Object.assign(controllerProps, { data, ids, total })}
//         />
//       );
//     }}
//   </ListController>
// );
