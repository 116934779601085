import React from "react";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.Item) {
    errors.Item = ["Item is required"];
  }
  if (!values.operation) {
    errors.operation = ["Operation is required"];
  }
  if (!values.smv) {
    errors.smv = ["SMV is required"];
  }
  if (!values.initialLeadTime) {
    errors.initialLeadTime = ["InitialLeadTime is required"];
  }
  if (!values.attributes) {
    errors.attributes = ["Attributes is required"];
  }
  if (!values.priority) {
    errors.priority = ["Priority is required"];
  }
  return errors;
};

export const EditOperations = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Edit
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm validate={validate}>
        <ReferenceInput
          label="producedItem id"
          source="producedItem"
          reference="workstations"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput
          label="operation id"
          source="operation"
          reference="operations"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <TextInput source="smv" />
        <TextInput source="initialLeadTime" />
        <TextInput source="attributes" />
        <TextInput source="priority" />
      </SimpleForm>
    </Edit>
  );
};
