import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { format_to_date } from "../../lib/date_time";
import { getBucketsNoDateRange, getAllocations, getLines } from "../../store/selectors";
import AdvancedTable, { Column, DefaultDateRangeFilter } from '../../components/AdvancedTable'
import _ from 'lodash'

const tableIcons:any = {
  Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref:any) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref:any) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />)
};

const defaultDateFilterFunction: DefaultDateRangeFilter = (row: any, columnKey: string, filterValue: {start_date: any, end_date: any}) => 
    !filterValue || filterValue.start_date <= row[columnKey] && row[columnKey] <= filterValue.end_date

function Table( {data, open} : { data:any[], open: boolean } ) {

  const DateRangeFilterInput = ({ onChange, value, isFocused }: { onChange: (value: any) => void, value: any, isFocused: boolean }) => {
    const handleOnChange = (range: any) => onChange(range)
    const end = value ? value.end_date : new Date()
    const start = value ? value.start_date : new Date(end.valueOf() - 365*24*3600*1000)
    return <div style={{ zIndex: 100000, backgroundColor: '#fff', borderStyle: 'solid', borderColor: '#777', borderWidth: '1px', display: 'flex', justifyContent: 'center'}}>
      <DateRangePicker
      format="dd/MM/yyyy"
      onChange={handleOnChange}
      value={[
        new Date(start),
        new Date(end),
      ]}
      noBorder={true}
      />
    </div>
  }

  return (
    <div>
      <div style={{width: "100%", backgroundColor: "#c5edfd", height: "50px", display: "flex", justifyContent: "left", alignItems: "center", color: "#00acee", padding:"15px 15px"}}>
        <span>Available Idle Shifts (minimum 10 minutes idle time)</span>
      </div>
      <AdvancedTable
        columns={[
          {
            key: "line_id",
            title: "Line",
            width: 9,
            color: "#111",
            getCellStyleFunction: () => ({
              textAlign: "center",
              fontSize: 15
            })
          },
          {
            key: "date",
            title: "Date",
            width: 9,
            useDateRangeFilter: true,
            formatFunction: format_to_date,
            filterFunction: defaultDateFilterFunction,
            FilterInput: DateRangeFilterInput,
            getCellStyleFunction: () => ({
              textAlign: "center",
              fontSize: 15
            })
          },
          {
            key: "shift",
            title: "Shift",
            width: 9,
            getCellStyleFunction: () => ({
              textAlign: "center",
              fontSize: 15
            })
          },
          {
            key: "idle_capacity",
            title: "Idle Capacity % (percentage)",
            width: 9,
            getCellStyleFunction: (row) => ({
              textAlign: "center",
              fontSize: 15
            })
          }
        ] as Column[]}
        rows={data}
        open={open}
      ></AdvancedTable>
    </div>
  );
}

function IdleSlotsTable( {data, open} :{data: any, open: boolean} ) {
  return (
    <Table data={data} open={open} />
  );
}

const mapStateToProps = (state:any) => {
  type IdleBucket = {
    date: Date,
    line_id: string,
    idle_capacity: number,
    shift: string,
    id: number
  }
  const allocations = getAllocations(state);
  const buckets = getBucketsNoDateRange(state);
  const lines = getLines(state);
  const data = [] as IdleBucket[]
  const minIdleTimeInMillis = 1000*60*10

  lines.forEach(l => {
      const bucketsInLine = buckets.filter(b => b.line_id == l.id)
      bucketsInLine.forEach(b => {
          const bucketDurationInMillis = b.end_time.valueOf() - b.start_time.valueOf();
          const allocatedDurationInMillis = _.sum(allocations.filter(a => a.line_bucket_id == b.id).map(a => a.end_time.valueOf() - a.start_time.valueOf()))
          if (bucketDurationInMillis - allocatedDurationInMillis >= minIdleTimeInMillis) {
              data.push({
                  date: b.start_time,
                  idle_capacity: parseInt(((bucketDurationInMillis - allocatedDurationInMillis)*100/bucketDurationInMillis).toFixed(0)),
                  line_id: lines.filter(l => l.id == b.line_id)[0].code,
                  shift: b.start_time.getHours() > 12 ? 'Night': 'Day',
                  id: b.id
              } as IdleBucket)
          }
      })
  })
  
  return {
    data
  }
};

export default connect(
  mapStateToProps
)(IdleSlotsTable);
