import { toast } from "react-toastify";

export function showToast(text: string) {
  toast.info(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
}

export function showToastSuccess(text: string) {
  toast.info(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    type: "success",
  });
}

export function showToastError(text: string) {
  toast.info(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    type: "error",
  });
}
export function showToastWarning(text: string) {
  toast.info(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    type: "warning",
  });
}
