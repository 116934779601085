import Button from "@material-ui/core/Button";
import { ListController } from "ra-core";
import React from "react";
import { Labeled } from "react-admin";
import {
  Datagrid,
  EditButton,
  ListView,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  DeleteButton,
} from "react-admin";
import { Link } from "react-router-dom";
import { useRecordContext } from "react-admin";

export const ShowAllocation = (props) => {
  const Title = ({ record }) => {
    return <span>Allocation | {record?.id ?? ""}</span>;
  };

  // const CustomTextField = (props) => {
  //   const { source } = props;
  //   const record = useRecordContext(props);
  //   console.log(record.record[source]);
  //   CustomTextField.defaultProps = {
  //     label: `${source}`,
  //     addLabel: true,
  //   };
  //   return <span>{record.record[source]}</span>;
  // };

  return (
    <Show title={<Title />} actions={null} {...props} class="col-md-12">
      <SimpleShowLayout>
        <TextField
          source="id"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="demand_operation"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <ReferenceField
          link=""
          label="Plan Board Line"
          source="plan_board_line"
          reference="boardLine"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        >
          <TextField
            source="code"
            className="col-xl-4 col-sm-12 sub_con_lab mb-3"
          />
        </ReferenceField>
        <TextField
          source="line_bucket"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="configuration"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="quantity"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="completed_quantity"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="total_smv"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="item_code"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="order_code"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="plan_board_version"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <ReferenceManyField
          label="Tracking Records"
          reference="mobileApp"
          pagination={<Pagination perPage={25} />}
          target="allocation"
        >
          <Datagrid>
            <TextField source="id" />
            <TextField source="quantity" />
            <TextField source="type" />
            <DeleteButton className="btn_ btn_delete m-0" />
            <EditButton
              className="btn_ btn_edit m-0"
              // class=" px-3 py-2"
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
