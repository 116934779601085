import _ from "lodash"
import { BucketAllocator, IAllocation, IAllocationPart, IBucket, IDemandOperationPart, ILinePart, IPlan } from "../interfaces"

export const forward_sequence_allocator = (plan: IPlan, buckets: IBucket[], start_time: Date, initial_configuration: string, parts: IDemandOperationPart[], lineParts: ILinePart[], bucket_allocator: BucketAllocator) => {
    // TODO Implement

    // Allocate all demand operations

    const allocations = parts.reduce((allocs: IAllocation[], part) => {
        if (part.is_freezed) {
            return allocs
        }
        const demandOperation = plan.demand_operations[part.demand_operation_id]
        const lastAllocation = allocs[allocs.length - 1]?.demand_operation_id ? allocs[allocs.length - 1] : allocs[allocs.length - 2]
        const lastAllocEndTime = lastAllocation?.end_time || start_time
        const initialConfig = lastAllocation ? plan.demand_operations[lastAllocation.demand_operation_id].configuration : initial_configuration
        let time = _.max([demandOperation.rm_ready_date, lastAllocEndTime]) as Date
        const filteredLineParts = lineParts.filter(p => p.end_time > time).sort(p => p.start_time.valueOf());
        const allocationsForThisDemandOp = filteredLineParts.reduce( (allocs: IAllocation[], linePart: ILinePart, index: number) => {
            const remainingQuantityAfterPreviousLinePart = part.quantity - _.sum(allocs.map(a => a.quantity))
            if (remainingQuantityAfterPreviousLinePart <= 0) {
                return allocs
            }
            const initialConfigThisLinePart = index == 0 ? initialConfig : filteredLineParts[index-1].end_config
            const possibleBuckets = buckets.filter(b => b.end_time > linePart.start_time && b.start_time < linePart.end_time)
            const allocationsForThisLinePart = possibleBuckets?.reduce((allocs: IAllocation[], bucket, index: number) => {
                const remaingQuantity = remainingQuantityAfterPreviousLinePart - _.sum(allocs.map(a => a.quantity))
                if (remaingQuantity <= 0 || bucket.start_time >= linePart.end_time) {
                    return allocs
                }
                const remainingPart = { ...part, quantity: remaingQuantity } as IDemandOperationPart
                const lastAlloc = allocs[allocs.length - 1]
                const nextBucket = index < possibleBuckets.length -1 ? possibleBuckets[index+1] : undefined
                const initialConfigThisSection = lastAlloc ? plan.demand_operations[lastAlloc.demand_operation_id]?plan.demand_operations[lastAlloc.demand_operation_id].configuration:lastAlloc.configuration : initialConfigThisLinePart
                time = lastAlloc? plan.demand_operations[lastAlloc.demand_operation_id]?time : _.max([lastAlloc.end_time, time]) as Date : time
                const newAlloc = bucket_allocator(plan, bucket, nextBucket, linePart, time, initialConfigThisSection, remainingPart)
                return [...allocs, ...newAlloc]
            }, [])
            return [...allocs, ...allocationsForThisLinePart]
        }, [])
        return [...allocs, ...allocationsForThisDemandOp]
    }, [])
    return allocations
} 
