import React from "react";
import {
  Create,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { redirect } from "../../lib/redirect";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.demandOperation) {
    errors.demandOperation = "plan board group is required";
  }
  if (!values.planBoardLine) {
    errors.planBoardLine = "PlanBoardLine is required";
  }
  if (!values.lineBucket) {
    errors.lineBucket = "Plan board line is required";
  }
  if (!values.planBoardVersion) {
    errors.planBoardVersion = "Plan Board Version board line is required";
  }
  if (!values.configuration) {
    errors.configuration = "Configuration is required";
  }
  if (!values.start_time) {
    errors.start_time = "start time is required";
  }

  if (!values.quantity) {
    errors.quantity = "Quantity is required";
  }
  return errors;
};

export const CreateAllocation = (props) => {
  const customToast = useCustomToast({redirect: redirect});
  return (
    <Create
      {...props}
      class="col-md-8"
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}>
      <SimpleForm
        toolbar={
          <CustomToolbar need={false} name="allocations" text="Allocation" />
        }
        validate={validate}
      >
        <ReferenceInput
          source="demandOperation"
          reference="demandOp"
          label={<ReqInputField name="Plan Board Group" />}
          title="This field is mandatory"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput
          source="lineBucket"
          reference="lineBucket"
          label={<ReqInputField name="Line Bucket" />}
          title="This field is mandatory"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        {/* since plan board version not require for create allocation */}
        {/* <ReferenceInput
           label="Plan Board Version"
          source="planBoardVersion"
          reference="boardVersion"
        >
          <SelectInput optionText="id" />
        </ReferenceInput> */}
        <TextInput
          source="configuration"
          label={<ReqInputField name="Configuration" />}
          title="This field is mandatory"
        />
        <TextInput
          source="total_smv"
          label={<ReqInputField name="Total SMV" />}
          title="This field is mandatory"
        />
        <TextInput
          source="completed_quantity"
          label={<ReqInputField name="Completed Quantity" />}
          title="This field is mandatory"
        />
        <TextInput
          source="quantity"
          label={<ReqInputField name="Quantity" />}
          title="This field is mandatory"
        />
        <TextInput
          source="demand_operation"
          label={<ReqInputField name="Demand Operation" />}
          title="This field is mandatory"
        />
        <DateTimeInput
          source="start_time"
          label={<ReqInputField name="Start Time" />}
          title="This field is mandatory"
        />
        <DateTimeInput
          source="end_time"
          label={<ReqInputField name="End Time" />}
          title="This field is mandatory"
        />
        <TextInput
          source="item_code"
          label={<ReqInputField name="Item Code" />}
          title="This field is mandatory"
        />
        <TextInput
          source="order_code"
          label={<ReqInputField name="Order Code" />}
          title="This field is mandatory"
        />
      </SimpleForm>
    </Create>
  );
};
