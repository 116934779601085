import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React, { useState } from "react";
import { Filter, Mutation, TextInput } from "react-admin";
import CSVReader from 'react-csv-reader';

const UploadCsvButton = ({ planBoardId, resource, action, title, description }) => {

  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState(false)

  const ItemFilter = (props) => (
    <Filter {...props}>
      <TextInput label="search item by code" source="code" alwaysOn />

    </Filter>
  );

  const onFileLoad = (csvdata) => {
    const data = { plan_board_id: planBoardId, data: csvdata }
    setData(data)
  }

  const options = {
    undoable: false,
    onSuccess: {
      notification: { body: "uploaded", level: "info" }
    },
    onFailure: {
      notification: { body: "Error: not uploaded", level: "warning" }
    }
  };

  return <div>
    <Button variant="contained" color="primary" onClick={() => setIsOpen(true)} >{title}</Button>
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="form-dialog-title">
      <DialogContent>
        <div>
          {description}
        </div>
        <br />
        <br />
        <CSVReader onFileLoaded={onFileLoad} />

        {data && <div><br />Records {data.data.length - 1}</div>} <br/>
        Rm data :
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setIsOpen(false)}>Close</Button>
          {data && <Mutation
            type="CUSTOM"
            resource={resource}
            payload={{ action, method: 'POST', body: data, id:planBoardId }}
            options={options}
          >
            {(action) => (
              <Button variant="contained" color="primary" onClick={() => { setIsOpen(false); action() }}> Save</Button>
            )}
          </Mutation>}

        </DialogActions>
      </DialogContent>
    </Dialog>
  </div>
}

export default UploadCsvButton