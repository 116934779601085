import React, { cloneElement } from "react";
import {
  // CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  Filter,
  List,
  sanitizeListRestProps,
  SelectInput,
  // ShowButton,
  TextField,
  FunctionField,
  TextInput,
  TopToolbar,
  useListContext,
  usePermissions,
  DateField,
  WithPermissions,
  ReferenceField,
} from "react-admin";
import CreateButton from "../../../components/CustomCreateButton";
import ShowButton from "../../../components/CustomShowButton";
import Confirmation from "../../../components/Confirmation";
import { makeStyles } from "@material-ui/core/styles";
import { ChangeDiv } from "../../../components/ChangeDiv";
import { CustomDatagrid } from "../../../components/CustomDatagrid";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

const ListActions = (props) => {
  const classes = useStyles();

  const exportPermissions = () =>
    !props.permissions?.permissions.includes("tna_flow");

  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar
      className={classes.buttonContainer}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
          className: "btn_",
        })}
      <ExportButton
        title="permisswwions"
        disabled={exportPermissions()}
        resource={resource}
        sort={currentSort}
        maxResults={maxResults}
        // variant="contained"
        // color="primary"
        className="btn_ btn_primary"
      />
    </TopToolbar>
  );
};

const TaskFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by Flow Code" source="flow_code" alwaysOn />
    <SelectInput
      source="type"
      choices={[
        { id: "NEW", name: "NEW" },
        { id: "IQ", name: "IQ" },
      ]}
      alwaysOn
    />
    <SelectInput source="status"  choices={[
        { id: 'Completed', name: 'Completed' },
        { id: 'Not Started', name: 'Not Started' },
        { id: 'Processing', name: 'Processing' }
    ]} alwaysOn />
  </Filter>
);

export const AdminFlow = (props) => {
  const { permissions } = usePermissions();
  const Title = () => {
    return <span>Admin Task Browser</span>;
  };

  return (
    <List
      {...props}
      actions={<ListActions permissions={permissions} />}
      filter={{submitted_status:"submitted" }}
      filters={<TaskFilter />}
      bulkActionButtons={false}
      perPage={25}
      title={<Title />}
    >
      <ChangeDiv>
        {props => {
          return <CustomDatagrid resource={props.resource}>
            <TextField width="15%" source="id" />
            <TextField width="15%" source="flow_code" sortable={false} />
            <TextField width="15%" source="type" />
            <TextField width="15%" source="template_code" sortBy="template__code" />
            <FunctionField label = "Started Date" width="15%" sortBy="received_time" render={props => {
            const startedDate = moment(props.started_date).format("DD/MM/YYYY")
            return <div
            >{startedDate}</div>
            }}
            />
            <TextField width="15%" source="flow_status" label="TNA Status" />
            <TextField width="15%" source="status" sortable={false} />
            <ShowButton width="15%" title="permissions" disabled={false} />
          </CustomDatagrid>
        }}
      </ChangeDiv>
    </List>
  );
};
