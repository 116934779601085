import React from "react";
import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";
import { SaveButton, DeleteButton, Toolbar, usePermissions, ReferenceInput, SelectInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";

export const OperationEdit = (props) => {
  const Title = ({ record }) => {
    return (
      <span>Edit Operation | {record ? `${record.description}` : ""}</span>
    );
  };

  const useStyles = makeStyles({
    toolbar: {
      display: "flex",
      justifyContent: "flex-end",
    },
  });
  const { permissions } = usePermissions();
  const isAdmin = permissions?.access_level == "ADMIN"

  const customToast = useCustomToast(props);

  return (
    <Edit
      title={<Title />}
      {...props}
      className={"col-md-6 "}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar need={false} name="operations" text="Operation" />
        }
      >
        <TextInput source="id" disabled />
        <TextInput source="code" disabled />
        <TextInput
          source="group_code"
          label={<ReqInputField name="Group Code" />}
        />
        <TextInput source="description" disabled />
        <TextInput
          source="input_lead_time"
          label={<ReqInputField name="Input Lead Time" />}
        />
        <TextInput
          source="output_lead_time_min"
          label={<ReqInputField name="Output Lead Time Min" />}
        />
        <TextInput
          source="output_lead_time_max"
          label={<ReqInputField name="Output Lead Time Max" />}
        />
        <TextInput
          source="backward_output_lead_time"
          label={<ReqInputField name="Backward Lead Time" />}
        />
        <TextInput source="configuration_pattern" />
        <BooleanInput source="resolve_child_demands" />
        {isAdmin && <ReferenceInput
          link=""
          label="Site"
          source="site"
          reference="sites"
          alwaysOn
        >
          <SelectInput optionText="name" resettable />
        </ReferenceInput>}
      </SimpleForm>
    </Edit>
  );
};
