import { LOAD_PLANBOARD, LOAD_PLANBOARD_REVISION_SUCCESS, PLAN_AUTOMATIC, SET_PROGRESS } from "../../types"

const initialState = {
    isPlanning: false,
    progress: 0
}

export const planningReducer = (state = initialState, action: any) => {
    if (action.type === PLAN_AUTOMATIC) {
        return { ...state, isPlanning: true, progress: 0 }
    }

    if (action.type === SET_PROGRESS) {
        const { progress } = action.payload
        return { ...state, progress }
    }

    const shouldClear = [LOAD_PLANBOARD, LOAD_PLANBOARD_REVISION_SUCCESS].indexOf(action.type) != -1
    if (shouldClear) {
        return initialState
    }
    return state
}