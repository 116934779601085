import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Loading, Query, Title, useDataProvider, useLogout } from "react-admin";
import "../../layout/styles/input.css";
import { showToastError, showToastSuccess } from "../../lib/toas";
import { BottomImage } from "./components/BottomConnerImg";
import { Leaf } from "./components/BottomImage";
import { Logo } from "./components/NewLogo";
import Tooltip from "@material-ui/core/Tooltip";

const payload = {
  pagination: { page: 1, perPage: 1 },
  sort: { field: "username", order: "ASC" },
};
export function NameForm() {
  const useStyles = makeStyles((theme) => ({
   
    paper: {
      height: 140,
      width: 100,
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    input: {
      display: "block",
      marginBottom: "40px",
      height: 35,
      width: "50%",
      borderRadius: "5px",
      fontSize: "14px",

      "&:focus": {
        border: "2px solid #2391da",
        // fontFamily: "Nunito Sans",' sans-serif',
        // fontSize: "15px",
        color: " #3d3d3c",
      },

      "&::placeholder": {
        // textOverflow: "ellipsis !important",
        color: "red",
      },
    },
    bold: {
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();
  const logout = useLogout();

  const dataProvider = useDataProvider();

  const [Password, setPassword] = useState("");
  const [RePassword, setRePassword] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const apiCall = async () => {
    try {
      await dataProvider.UPDATE_PASSWORD("userProfile", {
        data: { password: Password },
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const handelValidation = () => {
    if (!Password || !RePassword) {
      showToastError("please fill both fields! ");
      return false;
    }
    if (Password !== RePassword) {
      showToastError("password should match");
      return false;
    } else {
      if (apiCall()) {
        showToastSuccess("password change success! ");
        setTimeout(() => {
          logout();
        }, 2000);
      }
    }
  };

  return (
    <>
      <Grid container  spacing={1}>
        <b style={{ margin: "0.5%", paddingTop:'0.5%' }}>Change Password</b>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6}>
              <TextField
                required
                id="standard-name"
                label="New Password"
                type="password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                title="This field is mandatory"
              />
              <TextField
                required
                id="standard-name"
                label="Confirm New Password"
                type="password"
                variant="outlined"
                onChange={(e) => setRePassword(e.target.value)}
                title="This field is mandatory"
              />
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button
                // variant="contained"
                // color="primary"
                className="btn_ btn_primary ml-0"
                onClick={handelValidation}
                style={{
                  marginRight: 40,
                  width: 120,
                  height: 40,
                  fontSize: 15,
                  marginBottom:'3%'
                }}
              >
                {" "}
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export const Profile = ({ record }) => (
  <Query type="GET_USER" resource="userProfile" payload={payload}>
    {({ data, loading, error }) => {
      if (loading) {
        return <Loading />;
      }
      if (error) {
        return <p>ERROR</p>;
      }
      return (
        <>
         

          <Grid container style={{ display: "flex" }} spacing={3}>
          <Title title="Profile" />
            <Grid item xs={11}></Grid>
            <Grid item xs={1}>
              <div>
                <Logo />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={6}>
              <TextField
                required
                id="standard-name"
                label="User Name"
                defaultValue={data.username}
                variant="outlined"
                title="This field is mandatory"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="standard-name"
                label="Email"
                defaultValue={data.email}
                variant="outlined"
                title="This field is mandatory"
              />
            </Grid>
          </Grid>

          <NameForm />

          <div className="row"></div>

          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
            spacing={10}
          >
            <Grid item xs={5}>
              <BottomImage />
            </Grid>
            <Grid item>
              <Leaf />
            </Grid>
          </Grid>
        </>
      );
    }}
  </Query>
);
