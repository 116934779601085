import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { format_to_date } from "../../../lib/date_time";
import { OperationProgress } from "../helpers/ProgressView";
import { PlanProgress } from "../helpers/ProgressView";
import { CompletedQuantity } from "./CompletedQuantityBar";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  popover: {
    pointerEvents: "none",
    padding: theme.spacing(1),
  },
  text:{
    textAlign:'left'
  }
  
}));

export const CardStyle = React.memo(({ data }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  console.log("Workstation: ", data.workstation)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // this function round the value and and show given desimal poins
  const roundToGivenDecimal = (value, p = 1) => {
    var multiplier = Math.pow(10, p || 0);
    try {
      const withDecimal = (Math.round(value * multiplier) / multiplier).toFixed(
        p
      );
      return withDecimal;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const quantityLable = `${
    data.alloc_completed_quantity
  } out of  ${roundToGivenDecimal(data.quantity)}`;

  const getPresentage = (quantity, completed) => {
    const presentage = (100 * completed) / quantity;
    // console.log(data);

    if (!presentage) {
      return 0;
    }
    return presentage;
  };

  return (
    <div style={{ margin: 10 }}>
      <Card
        className="card"
        style={{ backgroundColor: data.is_planned ? "#93edb1" : "" }}
        variant="outlined"
      >
        <CardContent style={{ padding: 20 }}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column">
              <Grid item xs>
                <Typography className={classes.text} color="textSecondary">{data.item_code}</Typography>
                <Typography className={classes.text} color="textSecondary">{data.order_code}</Typography>
                {data.workstation && (
                    <Tooltip
                    placement="top"
                    title="Workstation"
                    style={{
                      padding: 3,
                    }}
                  >
                  <Typography className={classes.text} color="textSecondary" style={{wordBreak: 'break-all'}} >
                    {data.workstation?.code || ""}
                  </Typography>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.text} color="textSecondary">{data.quantity} / <span style={{marginLeft:2}}
              >{data.configuration} </span> </Typography>
             
            </Grid>
            <Grid item>
              {data.required_time_min ? (
                <Typography className={classes.text} color="textSecondary">
                  {format_to_date(data.required_time_min)} -{" "}
                  {format_to_date(data.required_time_max)}
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          <Tooltip
            placement="top"
            title="operation progress"
            style={{
              padding: 3,
            }}
          >
            <div>
              <Typography
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <OperationProgress
                  value={getPresentage(data.quantity, data.completed)}
                />
              </Typography>

              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>
                  {getPresentage(data.quantity, data.completed)}
                </Typography>
              </Popover>
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            title={`plan progress %${getPresentage(
              data.quantity,
              data.planned_quantity
            )}`}
            style={{
              padding: 3,
            }}
          >
            <div>
              <PlanProgress
                value={getPresentage(data.quantity, data.planned_quantity)}
              />
            </div>
          </Tooltip>

          <Tooltip
            placement="top"
            title="Completed Quantity"
            style={{
              padding: 7,
            }}
          >
            <div>
              <CompletedQuantity
                quantity={data.quantity}
                completed={data.alloc_completed_quantity}
              />
            </div>
          </Tooltip>
          <Chip label={quantityLable} className={classes.chip} />
        </CardContent>
      </Card>
    </div>
  );
});
