import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import { redirect } from "../../../lib/redirect";
import { ReqInputField } from "../../../components/ReqInputField";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { useCustomToast } from "../../../hooks/useCustomToast";

const validateReason = (values) => {
  const errors = {};
  if (!values.reason) {
    errors.reason = "Reason is required";
  }
  if (!values.text) {
    errors.text = "Text is required";
  }
  return errors;
};
const Title = ({ record }) => {
  return (
    <span>Create Change Reason</span>
  );
};

export const CreateReason = (props) => {
  const customToast = useCustomToast({...props, redirect: redirect});
  return (
  <div className="col-md-6">
      <Create {...props}
        title={<Title/>}
        onSuccess={customToast.onSuccess}
        onFaliure={customToast.onFaliure}>
      <SimpleForm
        validate={validateReason}
        toolbar={<CustomToolbar name="changeReasons" text="change reason" />}
      >
        <TextInput source="reason" label={<ReqInputField name="Reason" />} />
        <TextInput source="text" label={<ReqInputField name="Text" />} />
      </SimpleForm>
    </Create>
  </div>
)};
