import { get_bucket_sequence } from "../helpers";
import { IAllocation, IDemandOperationSelection, IPlan, ILinePart } from "../interfaces";
import { smv_based_forward_allocator, quantity_based_forward_allocator } from "./bucket_allocators";
import { forward_sequence_allocator } from "./sequence_allocators";


function get_bucket_allocator(plan: IPlan, line_id: number){
    const line = plan.lines[line_id]
    if(line.daily_quantity) {
       return quantity_based_forward_allocator 
    }
    return smv_based_forward_allocator
}

export function allocate_to_line(plan: IPlan, demand_operation_parts: IDemandOperationSelection, line_parts: ILinePart[], to_line_id: number, to_time: Date, initialConfiguration: string): IAllocation[] {
    const buckets = get_bucket_sequence(plan, to_line_id, to_time)
    const bucket_allocator = get_bucket_allocator(plan, to_line_id)
    return forward_sequence_allocator(plan, buckets, to_time, initialConfiguration, demand_operation_parts, line_parts, bucket_allocator)
}