import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from "react-admin";
import "react-datepicker/dist/react-datepicker.css";
import CustomDateField from "../../components/field/CustomDateField";
import { CustomClearableDateField } from "../../components/input/CustomClearableDateField";
import { BulkExportResolver } from "./components";
import { Expand } from "./expand";

const Title = () => {
  return <span>Close Orders</span>;
};

const OrderGroupFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search Order code" source="code" alwaysOn />
      <TextInput label="Search Item  code" source="item_code" alwaysOn />
      <CustomClearableDateField
        className="mb-2"
        filterName="planned_start_date"
        alwaysOn
        props={props}
        text="Start date"
        sortOrder="ASC"
        sortField="planned_start_date"
      />
      <CustomClearableDateField
        className="mb-2"
        filterName="planned_end_date"
        alwaysOn
        props={props}
        text="End date"
        sortOrder="ASC"
        sortField="planned_end_date"
      />
    </Filter>
  );
};

export const CloseOrders = (props) => {
  const [open, setOpen] = React.useState(false);

  const getOpen = (e) => {
    setOpen(e);
  };

  return (
    <List
      {...props}
      perPage={25}
      actions={null}
      title={<Title />}
      filters={<OrderGroupFilter />}
      bulkActionButtons={<BulkExportResolver />}
      className="table-width-custom"
    >
      <Datagrid expand={<Expand action={open} />}>
        <TextField source="id" />
        <TextField source="code" />
        <Smv source="smv" />
        <TextField source="quantity" />
        <CustomDateField source="rm_ready_date" />
        <TextField source="completed_quantity" />
        <ItemCode source="item_code" />
        <CustomDateField source="planned_start_date" />
        <CustomDateField source="planned_end_date" />
        <TextField source="skipped_quantity" />
        <CustomDateField source="required_time_max" label="Need date" />
        <EditButton className="btn_ btn_edit" />
      </Datagrid>
    </List>
  );
};

const Smv = (props) => {
  const { smv } = props.record;
  const max_smv = _.max(smv);

  return (
    <Typography
      color="textSecondary"
      variant="body2"
      display="block"
      gutterBottom
    >
      {max_smv}
    </Typography>
  );
};

const ItemCode = (props) => {
  const { item_code } = props.record;
  const uniqItemCode = _.uniq(item_code);
  return (
    <Typography
      color="textSecondary"
      variant="body2"
      display="block"
      gutterBottom
    >
      {uniqItemCode}
    </Typography>
  );
};
