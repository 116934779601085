import React from 'react';
import { format_to_date, format_to_date_for_api } from "../../lib/date_time";
import { makeStyles } from '@material-ui/core/styles';

import moment from "moment";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Grid, IconButton } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
    customTextField: {
      "& input::placeholder": {
        fontSize: "10px"
      }
    }
  });

  

export const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <span>
            
            {/* Search: {' '} */}
            <input className='table-element' value={filterValue || ''} onChange={(e) => setFilter(e.target.value)} />
        </span>
    )
}

export const ColumnWorkstationFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <select className='table-element'
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: "100%" }}
            value={column ? column.value : "all"}
        >
        <option value="all">Show All</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    )
}

export const ColumnLineHeadFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <select className='table-element'
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: "100%" }}
            value={column ? column.value : "all"}
        >
        <option value="all">Show All</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    )
}

export const ColumnInefficiencyFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <select className='table-element'
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: "100%" }}
            value={column ? column.value : "all"}
        >
        <option value="all">Show All</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    )
}

export const ColumnInefficiencyReasonFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <select className='table-element'
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: "100%" }}
            value={column ? column.value : "all"}
        >
        <option value="all">Show All</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    )
} 


export function DateColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const onChange = (e) => {
      if (!e || e == null) {
        setFilter(null)
      } else {
        const value = format_to_date_for_api(e);
        setFilter(value);
      }
    };

    function handleClr(e) {
      e.stopPropagation();
      onChange(null);
    }
  
    const classes = useStyles();
  
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container justify="center" alignItems="flex-end">
          <DatePicker
            variant="inline"
            format="YYYY-MM-DD"
            size="small"
            value={filterValue ? moment(filterValue, "YYYY-MM-DD") : null}
            onChange={onChange}
            placeholder="Date"
            InputProps={{
              endAdornment: (
                <IconButton onClick={(e) => handleClr(e)}>
                  <ClearIcon />
                </IconButton>
              )
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }