import { ButtonGroup } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EditableText from '../../components/EditableText';
import { applyPendingAction, clearPendingAction, updatePendingAction } from '../../store/actions';
import { getPendingAction, getPendingActionOptions, getPlan } from '../../store/selectors';


const PendingAllocationPopup = ({ isOpen, onClose, onApply,
  pendingAction, onUpdatePendingAction, description,
  selectedOption,
  title, options, selectedMode, configChange, configChangeMessage }: {
    isOpen: boolean
    onClose: () => any
    onApply: () => any
    pendingAction: any
    onUpdatePendingAction: (pendingAction: any, change: any) => any
    selectedOption: any
    description?: string
    title?: string,
    selectedMode?: string
    options: any[],
    configChange?: boolean,
    configChangeMessage?: string
  }) => {

  const [acceptedConfigChange, setAcceptedConfigChange] = useState(false)
  useEffect(() => {
    setAcceptedConfigChange(false)
  }, [isOpen])

  // If nothing is selected auto select the first option
  useEffect(() => {
    if(isOpen && !options?.some(option => option.mode === selectedMode)){
      onChange({mode: options[0]?.mode})
    }
  }, [isOpen, selectedMode])

  const onChange = (change: any) => {
    onUpdatePendingAction(pendingAction, change)
  }

  return <Dialog open={isOpen} onClose={onClose}
    maxWidth="xl"
    aria-labelledby="form-dialog-title">
    <DialogContent>
      <h3>{title}</h3>

      <div>
        {configChange ? <div>
          {configChangeMessage} <br /> <br />
          {!acceptedConfigChange &&
            <ButtonGroup>
              <Button variant="contained" color="primary" onClick={() => setAcceptedConfigChange(true)}>Yes</Button>
              <Button variant="contained" onClick={onClose}>No</Button>
            </ButtonGroup>}
        </div> : null}
      </div>

      <div>
        <p>Please choose an option?</p>
      </div>


      <Grid container spacing={4}>
        {options?.map(op =>
          <Grid item xs key={op.mode} style={{
            backgroundColor: op.mode === selectedMode ? 'lightgreen' : 'lightgray',
            cursor: 'pointer',
            textAlign: 'center'
          }}
            onClick={() => onChange({ mode: op.mode })}
          >
            <b>{op.title} <br />{op.code}</b> <br />

            <span>{op.description}</span> <br />
            <i> Qty: {op.quantity}</i>
          </Grid>
        )}
      </Grid>
      <br />
      <div>
        Change quantity: <EditableText initialValue={selectedOption?.quantity} onChange={(quantity) => onChange({ quantity })} />
        {/* <input type="number" max={selectedOption?.quantity} onChange={(e) => onChange({ quantity: e.target.value })} value={pendingAction?.payload?.quantity}></input> */}
      </div>
    </DialogContent>
    {<DialogActions>
      <Button variant="contained" className="btn_ btn_primary" onClick={onClose}>Close</Button>
      <Button disabled={(configChange && !acceptedConfigChange) || !selectedMode} variant="contained" className="btn_ btn_primary" onClick={onApply}>Apply</Button>
    </DialogActions>}
  </Dialog>
}

const titles = { MOVE_ALLOCATION: "Move allocations", CREATE_ALLOCATION: "Create allocations", DELETE_ALLOCATION: "Delete allocations" } as any



const mapStateToProps = (state: any) => {
  const pending_action = getPendingAction(state)
  const plan = getPlan(state)

  const isOpen = !!pending_action
  if (!isOpen) {
    return { isOpen, options: [] }
  }

  // const entities = getEntities(state)
  // const planManager = new PlanBoardManager(entities)

  let configChange = false
  let configChangeMessage = ""
  const options = getPendingActionOptions(state)

  const selectedOption = options.find(option => option.mode === pending_action?.payload?.mode)


  return {
    isOpen,
    title: isOpen ? titles[pending_action.type] : null,
    options,
    pendingAction: pending_action,
    selectedOption,
    selectedMode: pending_action.payload.mode,
    configChange,
    configChangeMessage
  }
}

const mapDispatchToProps = {
  onClose: clearPendingAction,
  onApply: applyPendingAction,
  onUpdatePendingAction: updatePendingAction
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingAllocationPopup)
