// Select entity or objec on plan board
export const SELECT_ENTITY = "PLAN_BOARD_SELECT_ENTITY"
export const CLEAR_SELECTION = "PLAN_BOARD_CLEAR_SELECTION"
export const SET_TIME_RANGE = "SET_TIME_RANGE"

// Loading 
export const LOAD_PLANBOARD = "LOAD_PLANBOARD"
export const LOAD_PLANBOARD_SUCCESS = "LOAD_PLANBOARD_SUCCESS"
export const LOAD_PLANBOARD_FAILED = "LOAD_PLANBOARD_FAILED"

export const LOAD_PLANBOARD_REVISION = "LOAD_PLANBOARD_REVISION"
export const LOAD_PLANBOARD_REVISION_SUCCESS = "LOAD_PLANBOARD_REVISION_SUCCESS"
export const LOAD_PLANBOARD_REVISION_FAILED = "LOAD_PLANBOARD_REVISION_FAILED"
export const PUBLISH_PLANBOARD_REVISION = "PUBLISH_PLANBOARD_REVISION"


// Save change in the server
export const SAVE_PLAN_BOARD_CHANGES = "SAVE_PLAN_BOARD_CHANGES"
export const SAVE_PLAN_BOARD_CHANGES_SUCCESS = "SAVE_PLAN_BOARD_CHANGES_SUCCESS"
export const SAVE_PLAN_BOARD_CHANGES_FAILED = "SAVE_PLAN_BOARD_CHANGES_FAILED"

// Undo Redo
export const PLAN_UNDO = "PLAN_UNDO"
export const PLAN_REDO = "PLAN_REDO"

export const SET_PENDING_ACTION = "SET_PENDING_ACTION"
export const CLEAR_PENDING_ACTION = "CLEAR_PENDING_ACTION"
export const APPLY_PENDING_ACTION = "APPLY_PENDING_ACTION"

// Export Data
export const EXPORT_PLAN_BOARD_DATA = "EXPORT_PLAN_BOARD_DATA"

//automatic planner
export const PLAN_AUTOMATIC = "PLAN_AUTOMATIC"
export const DELETE_AND_PLAN_AUTOMATIC = "DELETE_AND_PLAN_AUTOMATIC"
export const DELETE_PLAN = "DELETE_PLAN"

//Progress of planning
export const SET_PROGRESS = "SET_PROGRESS"

//To set whether the current planboard is being edited
export const SET_IS_EDITING = "SET_IS_EDITING"

export const TOGGLE_EDIT_MODE = "TOGGLE_EDIT_MODE"
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP"
// model 
export const MODEL_OPEN = 'MODEL_OPEN'
export const MODEL_CLOSE = 'MODEL_CLOSE'
//shift time range forward and backward
export const SHIFT_TIME_RANGE_FORWARD = "SHIFT_TIME_RANGE_FORWARD"
export const SHIFT_TIME_RANGE_BACKWARD = "SHIFT_TIME_RANGE_BACKWARD"

// To keep track of the changed orders codes
export const ADD_ORDER_CODE_TO_CHANGED_ORDERS = "ADD_ORDER_CODE_TO_CHANGED_ORDERS"