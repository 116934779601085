import Icon from "@material-ui/core/Icon";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RemoveIcon from "@material-ui/icons/Remove";
import React from "react";
import CheckboxTree from "react-checkbox-tree";

export const TreeCheckbox = ({
  nodes,
  checked,
  expanded,
  setChecked,
  setExpanded,
}) => {
  return (
    <CheckboxTree
      nodes={nodes}
      checked={checked}
      expanded={expanded}
      onCheck={setChecked}
      onExpand={setExpanded}
      icons={{
        check: <CheckBoxIcon></CheckBoxIcon>,
        uncheck: <CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>,
        halfCheck: <CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>,
        expandClose: <ArrowRightIcon></ArrowRightIcon>,
        expandOpen: <ArrowDropDownIcon></ArrowDropDownIcon>,
        expandAll: <AddIcon></AddIcon>,
        collapseAll: <RemoveIcon></RemoveIcon>,
        parentClose: <Icon></Icon>,
        parentOpen: <Icon></Icon>,
        leaf: <Icon></Icon>,
      }}
    />
  );
};

export const formatNodes = (inputTree = {}, sub = false) => {
  const planData = Object.keys(inputTree).map((k) => {
    return {
      value: k,
      label: k,
      children: inputTree[k].map((group) => {
        group.all_lines.sort();
        return {
          value: `${k}-${group.id}`,
          label: group.code,
          children: group.all_lines.map((l, i) => {
            return { value: `${k}---${String(l.id)}`, label: l.code };
          }),
        };
      }),
    };
  });

  return [
    {
      value: sub ? "sub_contracts" : "Plant",
      label: sub ? "Sub Contracts" : "Plant",
      children: planData,
    },
  ];
};
