import React from "react";
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";
import { redirect } from "../../lib/redirect";

const validate = (values) => {
  const errors = {};
  if (!values.plan_board) {
    errors.plan_board = "plan board is required";
  }
  if (!values.type) {
    errors.type = "type time is required";
  }
  if (!values.size) {
    errors.size = "size is required";
  }
  if (!values.count) {
    errors.count = "count time is required";
  }
  return errors;
};

export const CreateTool = (props) => {
  const customToast = useCustomToast({ redirect: redirect });

  return (
    <Create
      className="col-md-6"
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm
        toolbar={<CustomToolbar need={false} name="tools" text="Tool" />}
        validate={validate}
      >
        <ReferenceInput
          label={<ReqInputField name="Plan Board" />}
          source="plan_board"
          reference="planBoards"
        >
          <AutocompleteInput optionText="algorithm" />
          {/* TODO change to planboard code */}
        </ReferenceInput>
        <TextInput source="type" label={<ReqInputField name="Type" />} />
        <TextInput source="size" label={<ReqInputField name="Size" />} />
        <NumberInput source="count" label={<ReqInputField name="Count" />} />
        <NumberInput
          source="compatibility_group"
          initialValue={1}
          label="Compatibility Group"
        />
      </SimpleForm>
    </Create>
  );
};
