import React, { useState } from "react";
import {
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  Paper,
  TableBody,
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  TableHead
} from "@material-ui/core";
import { List, Error, Loading, Query, downloadCSV, Title } from "react-admin";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";

import UploadButton from "../../components/BulkActionButton";
import { exportCsv } from "../../lib/csvExport";

const tables = [
  { table: "item", label: "Items" },
  { table: "operation", label: "Operations" },
  { table: "itemoperation", label: "Item Operations" },
  { table: "itemoperationrequireditem", label: "Item Operation Required Items" },
  { table: "operationworkstation", label: "Operation Workstation Link" },
  { table: "ordergroup", label: "Order Groups" },
  { table: "order", label: "Orders" }
];

export const ItemStructureUpload = (props) => {

  return (
    <Grid container spacing={2}>
      <Grid item sm="12" md="10" lg="6">
        <Title title="Item structure upload" />
        <TableContainer component={Paper}>
          <div style={{ margin: 20 }}>UPLOAD ITEM STRUCTURE CSVS</div>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Table</TableCell>
                <TableCell align="right">Template</TableCell>
                <TableCell align="right">Upload</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tables.map(({ table, label }) => (
                <TableRow
                  key={table}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {label}
                  </TableCell>
                  <TableCell align="right">
                    <GetTemplateButton
                      selectedTable={table}
                      class="MuiButton-textPrimary"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <UploadButton
                      title={`Upload CSV`}
                      meta={{ model: table }}
                      action={`upload_csv_table`}
                      description={`upload CSV for ${label}`}
                      maxWidth="xl"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const getDescriptors = (f) => {
  const required = f.required ? "required" : "";
  const choices = (f.choices || []).join(" or ")
  return [f.label, required, choices, f.type, f.help_text].filter(v => !!v).join(", ")
};

const GetTemplateButton = ({ selectedTable }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleExport = (sample) => (e) => {
    downloadCSV(sample, `${selectedTable}-template`);
  };

  return (
    <div>
      <Button className="btn_ btn_primary mb-2" onClick={handleOpen}>
        {" "}
        Get Template{" "}
      </Button>
      <Dialog fullWidth maxWidth="md" open={dialogOpen} onClose={handleClose}>
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ alignSelf: "center" }}>
              {" "}
              Template for {selectedTable}{" "}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
                cursor: "pointer",
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        <Query
          type="CUSTOM"
          resource="bulkAction"
          payload={{
            body: { data: { model_name: selectedTable } },
            id: "upload_csv_table",
            action: "get_template",
            method: "POST",
          }}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Error />;
            }

            return (
              <DialogContent>
                <DialogContentText style={{ fontSize: 15, fontWeight: "bold" }}>
                  {" "}
                  Fields overview{" "}
                </DialogContentText>
                {data.template.map((f) => {
                  return (
                    <DialogContentText>
                      <b>{f.name}</b> :{" "}
                      <i style={{ marginLeft: "1%" }}>{getDescriptors(f)}</i>
                    </DialogContentText>
                  );
                })}
                <DialogContentText>
                  {" "}
                  Click export to download a csv of template{" "}
                </DialogContentText>
                <DialogActions>
                  <Button
                    className="btn_ btn_primary"
                    disabled={loading}
                    onClick={handleExport(data.basic_template[0])}
                  >
                    {" "}
                    Export{" "}
                  </Button>
                  {/* <Button
                    className="btn_ btn_primary"
                    disabled={loading}
                    onClick={handleClose}
                  >
                    {" "}
                    Close{" "}
                  </Button> */}
                </DialogActions>
              </DialogContent>
            );
          }}
        </Query>
      </Dialog>
    </div>
  );
};
