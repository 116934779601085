import Button from "@material-ui/core/Button";
import { ListController } from "ra-core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { API_URL } from "../../../constants";
import { showToastSuccess, showToastError } from "../../../lib/toas"
import {
  Datagrid,
  EditButton,
  ListView,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  DateField,
  FileField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  usePermissions,
  useMutation,
  useQuery,
  Loading,
  useRedirect,
  FunctionField

} from "react-admin";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { TextareaAutosize } from "@material-ui/core";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));
export const TaskShow = (props) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const [skipComment, setSkipComment] = React.useState("");
  const [reopenComment, setReopenComment] = React.useState("");
  const [isCompleted, setIsCompleted] = React.useState(true);
  const history = useHistory();

  const [mutate, { loading, onSuccess, onError }] = useMutation();
  const getFileTypeToDownload = (attr) => {
    var type;
    switch (attr) {
      case "uploadedFiles":
        type = "taskFileUpload"
        break;

      case "sentEmails":
        type = "taskEmail"
        break;

      case "form_data":
        type = "taskFormUpload"
        break;

      default:
        break;
    }
    return type;
  }


  const CustomFileField = (props) => {
    const { source, label, attr } = props;
    const record = useRecordContext(props);
    const files = record["record"][source][attr]
    const rejectReasons = record["record"][source]['rejected_reasons'] || {};
    const addedComments = record["record"][source]['comments'] || {};
    const baseUrl = `${API_URL}`;
    const fileType = getFileTypeToDownload(attr)
    const endpointUrl = `tna/itemTasks/fileDownload/?`

    const fullUrl = `${baseUrl}/${endpointUrl}type=${fileType}&file_name=`


    return (
      <>
        {files?.length > 0 ? <div class="ra-field ra-field-uploaded_files">
          <div class="MuiFormControl-root MuiFormControl-marginDense">
            <label class="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-54 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense" data-shrink="true">
              <span>{label}</span>
            </label>
            <hr></hr>
            <div class="RaLabeled-value-55">
              <ul class="RaFileField-root-56">
                {(files != null && files != undefined) ? files.map(function (fileOb) {
                  const rejectMsg = rejectReasons[fileOb]
                  const approveMsg = addedComments[fileOb]
                  return (
                    <li>
                      
                        <a href={fullUrl.concat(fileOb)} target="_blank" download="true">{fileOb}</a>

                      
                      {rejectMsg && (
                        <>
                          <span style={{ color: "red",marginLeft:'20px' }}>{`  Rejected: ${rejectMsg === true ? "No reason given" : rejectMsg
                            }`}</span>
                        </>
                      )}
                      {approveMsg && (
                        <>
                          <span style={{ color: "#08c40b",marginLeft:'20px' }}>{`  Approved with comment: ${approveMsg === true ? " " : approveMsg
                            }`}</span>
                        </>
                      )}

                    </li>);
                }) : null}
              </ul>
            </div>
          </div>
        </div> : null}

      </>
    )
  };

  const { data, queryLoading, error } = useQuery({
    type: "TNA_CUSTOM_ACTION",
    resource: "itemTasks",
    payload: {
      action: "isTaskCompleted",
      method: "POST",
      body: { "templateItemTaskId": props.id },
    },
  }, {
    onFailure: (e) => {
      showToastError("Task Retrieval Failed:" + e.body);
    },
    onSuccess: (response) => {
      setIsCompleted(response?.data?.isCompleted)
    }
  });

  if (queryLoading) return <Loading />;

  const submit = event => {
    mutate({
      type: 'TNA_CUSTOM_ACTION',
      resource: 'itemTasks',
      payload: {
        action: "completeTask",
        method: "POST",
        body: {
          "templateItemTaskId": props.id,
          "skipReason": skipComment,
          "forced": true
        },
      }
    }, {
      onFailure: (e) => {
        showToastError("Task Force Completion Failed:" + e.body);
      },
      onSuccess: ({ data }) => {
        if (data) {
          showToastSuccess("Task Force Completion Success");
        }
        history.goBack()
      }
    });
  }

  const reopen = event => {
    mutate({
      type: 'TNA_CUSTOM_ACTION',
      resource: 'itemTasks',
      payload: {
        action: "reopenTaskAndDependents",
        method: "POST",
        body: {
          "taskId": props.id,
          "reason": reopenComment
        },
      }
    }, {
      onFailure: (e) => {
        showToastError("Task Reopen Failed:" + e.body);
      },
      onSuccess: (e) => {
        showToastSuccess("Task Reopen Success");
        history.goBack();
      }
    });
  }



  TextField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
  };


  const Title = ({ record }) => {
    return <span>TNA Flow Task | {record ? `${record.id ?? ""}` : ""}</span>;
  };

  return (
    <>
      <Show {...props} actions={null} title={<Title />}>
        <SimpleShowLayout>
          <TextField
            source="id" label="ID :"
            className="col-xl-4 col-sm-12 sub_con_lab mb-3"
          />
          <TextField
            source="task_name" label="Task Name : "
            className="col-xl-4 col-sm-12 sub_con_lab mb-3"
          />
          <TextField
            source="tna_department" label="Assigned Department :"
            className="col-xl-4 col-sm-12 sub_con_lab mb-3"
          />
          <TextField
            source="assignee" label="Assigned User :"
            className="col-xl-4 col-sm-12 sub_con_lab mb-3"
          />
          <TextField
            source="transferring_user" label="Approving User :" className="col-xl-4 col-sm-12 sub_con_lab mb-3"
          />
          <br></br>
          <br></br>
          <FunctionField
            render={record => {
            const dueStartdate = moment(record.due_start_datetime).format("DD/MM/YYYY")
            return <div
            >{dueStartdate}</div>
          }}
            label="Due Start Date/Time  :"
            className="col-sm-2 col-xl-2  col-sm-12 nopadding text_inline"
          />
          <FunctionField label="Actual Start Date/Time :" className="col-sm-10 col-xl-10  col-sm-12 nopadding text_inline" render={record => {
            const actualStartTime = record.actual_start_time ? record.actual_start_time : "NA"
            return <div
            >{actualStartTime}</div>
          }}
          />
          <FunctionField
            render={record => {
              const dueCompleteDate = moment(record.due_complete_datetime).format("DD/MM/YYYY")
              return <div
              >{dueCompleteDate}</div>
            }}
            label="Due Completed Date/Time"
            className="col-sm-2 col-xl-2  col-sm-12 nopadding text_inline"
          />
          <FunctionField label="Actual completed Date/Time :" className="col-sm-10 col-xl-10  col-sm-12 nopadding text_inline" render={record => {
            const actualCompletedTime = record.actual_complete_time ? record.actual_complete_time : "NA"
            return <div
            >{actualCompletedTime}</div>
          }}
          />
          <TextField
            source="duration" label="Allocated Duration (HH MM)"
            className="col-sm-2 col-xl-2  col-sm-12 nopadding text_inline"
          />
          <TextField
            source="used_duration" label="Used Duration (HH MM)"
            className="col-sm-10 col-xl-10  col-sm-12 nopadding text_inline"
          />
          <FunctionField
            render={record => {
              const receivedDate = moment(record.received_time).format("DD/MM/YYYY")
              const receivedDateOutput = receivedDate ? receivedDate : "NA"
              return <div
              >{receivedDateOutput}</div>
            }}
            label="Received Date"
            className="col-sm-6 col-xl-6  col-sm-12 nopadding text_inline" 
          />
          <br></br>
          <TextField
            source="delay_reason" label="Delay Reason :"
            className="col-xl-4 col-sm-12 sub_con_lab mb-3"
          />
          <TextField
            source="comment" label="Comment :"
            className="col-xl-4 col-sm-12 sub_con_lab mb-3"
          />
          <CustomFileField source="task_data" attr="uploadedFiles" src="url" title="title" label="Uploaded files" target="_blank" download="true" />
          <CustomFileField source="task_data" attr="sentEmails" src="url" title="title" label="Sent Emails" target="_blank" download="true" />
          <CustomFileField source="task_data" attr="form_data" src="url" title="title" label="Uploaded Forms" target="_blank" download="true" />


          <FunctionField
            label=" "
            render={record => {
              if (isCompleted === false
                && permissions?.permissions.includes("tna_force_complete")
                && (localStorage.getItem("tna_department") === record.tna_department
                  || localStorage.getItem("tna_department") === "undefined")
              ) {
                return (
                  <Paper className={classes.paper}>
                    <TextareaAutosize
                      className="ml-1"
                      aria-label="minimum height"
                      rowsMin={3}
                      onChange={(e) => setSkipComment(e.target.value)}
                      placeholder="Comment or skip reason for task force completion"
                    />
                    <br></br>
                    <Button variant="contained" className="btn_ btn_primary" onClick={submit}>
                      {" "}
                      Force Complete Task
                    </Button>
                  </Paper>
                )
              } else if (permissions?.permissions.includes("tna_reopen_tasks")) {
                return (
                  <Paper className={classes.paper}>
                    <TextareaAutosize
                      className="ml-1"
                      aria-label="minimum height"
                      rowsMin={3}
                      onChange={(e) => setReopenComment(e.target.value)}
                      placeholder="Comment or skip reason for task reopen"
                    />
                    <br></br>
                    <Button variant="contained" className="btn_ btn_primary" onClick={reopen} >
                      {" "}
                      Reopen Task
                    </Button>
                  </Paper>
                )
              }

            }} />


        </SimpleShowLayout>

      </Show>
    </>
  );
};
