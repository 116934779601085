import React, { useState } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Grid from '@material-ui/core/Grid';
import QRCode from 'qrcode.react';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import { ReactPDF, Document, Image, PDFDownloadLink, Page } from '@react-pdf/renderer';
import PdfGenButton from './PDFGenButton';
import Typography from '@material-ui/core/Typography'


const QRTask = ({ task }) => {
    const [isQROK, setIsQROK] = useState(true);
    const [comment, setComment] = useState("");
    const [uri, setUri] = useState("");
    const [qrDisplay, seQrDisplay] = useState(false);


    const generatePdfLink = setTimeout(() => {
        const qrCodeCanvas = document.querySelector('canvas');
        try {
            const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
            setUri(qrCodeDataUri)
        } catch (e) {
            console.log(e)
        }
    }, 50);
    const handleChange = (event) => {
        setComment(event.target.value)
    }


    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>

                    <label>Details for QR</label>

                    <Grid>
                        <TextareaAutosize aria-label="minimum height" className="form-group border p-2" rowsMin={3} onChange={handleChange} placeholder="Give details to include in QR" />
                    </Grid>
                    <Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => seQrDisplay(true)}
                        >
                            {" "}
                            {uri ? <PdfGenButton task={task} comment={comment} uri={uri} /> : null}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={6} style={{ display: qrDisplay ? 'block' : 'none' }}>
                    {isQROK ?
                        <Grid item xs={6}>
                            <Typography variant='h5'>Code: {task?.id}</Typography>
                            {" "}
                            <QRCode value={task.id + ': ' + comment} size={200} />
                        </Grid> : null}
                </Grid>
            </Grid>




        </div>
    )
}

export default QRTask;
