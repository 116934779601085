import React, { useState } from "react";
import {
  Create,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
} from "react-admin";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

export const WorkstationsCreate = (props) => {
  const [plant, setPlant] = useState();

  const validateWorkstation = (values) => {
    const errors = {};
    if (!values.plant) {
      errors.plant = "Plant is required";
    }
    if (!values.plan_board) {
      errors.plan_board = "Plan board is required";
    }
    if (!values.code) {
      errors.code = "Code is required";
    }
    return errors;
  };

  const permissions = usePermissions();
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      class={"col-md-6 mt-5"}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm
        redirect="list"
        toolbar={
          <CustomToolbar text="workstations" name="workstations" need={false} />
        }
        validate={validateWorkstation}
      >
        <ReferenceInput
          source="plant"
          reference="plants"
          onChange={(e) => setPlant(e.target.value)}
          label={<ReqInputField name="Plants" />}
        >
          <SelectInput optionText="name" title="This field is mandatory" />
        </ReferenceInput>
        {(permissions.permissions === "ADMIN") | "SITE" ? (
          <ReferenceInput
            source="plan_board"
            reference="planBoards"
            filter={{ plant_id: plant }}
            title="This field is mandatory"
            label={<ReqInputField name="Plan board" />}
          >
            <SelectInput
              optionText="code"
              label={<ReqInputField name="code" />}
            />
          </ReferenceInput>
        ) : (
          <ReferenceInput
            source="plan_board"
            reference="planBoards"
            filter={{ plant_id: plant }}
            label={<ReqInputField name="Plan board" />}
          >
            <SelectInput optionText="code" title="This field is mandatory" />
          </ReferenceInput>
        )}
        <TextInput
          source="code"
          label={<ReqInputField name="Code" />}
          title="This field is mandatory"
        />
        <ReferenceArrayInput
          source="compatible_workstations"
          reference="workstations"
        >
          <SelectArrayInput
            optionText="code"
            title="This field is not mandatory"
            label="Compatible Workstations"
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
