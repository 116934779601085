import React,{useState} from 'react';
import NotFound from '../layout/img/404-image.png';
 import './styles/NotFound.css';
import {Button,Typography} from '@material-ui/core'
import { PRIMARY_COLOR } from "../constants";
import Grid from "@material-ui/core/Grid";

const ErrorPage404 = () => {

return(
    <div>

    <Grid container spacing={2}>
    <Grid item xs={6}>
        <img src={NotFound}  width="100%" height="auto" />
    </Grid>
    <Grid item xs={6} className="NotFoundContent">
    <h3>Oops.... Page Not Found</h3>
    <p> Uh oh, We can't seem to find the page you're looking for. Try refreshing the page or contact support to resolve the issue.</p>
    <a href="mailto:support@stacktech.io">
        <Button style={{backgroundColor:"PRIMARY_COLOR"}} title="support@stacktech.io"  className="btn_ btn_primary ml-0">
        Contact Support
        </Button>
    </a>
    </Grid>
    </Grid>

    </div>

)
}


export default ErrorPage404