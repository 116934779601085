import React, { useState } from 'react';
// import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';
// // import { getProcessingTasks } from '../../store/selectors/tnaTaskSelectors';
// import { connect } from "react-redux";
import { forwardRef } from 'react';
import { Error, Loading, useQuery, useMutation, useRefresh, usePermissions,Title } from "react-admin";
import MaterialTable, { MTableToolbar, MTableBody, MTableHeader } from "material-table";
import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
// import ViewColumn from '@material-ui/icons/View';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { showToastSuccess, showToastError } from "../../../lib/toas"
import TransitionsModal from './imageModal';

const tableIcons = {
    AddBoxIcon: forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    VisibilityIcon: forwardRef((props, ref) => <VisibilityIcon {...props} ref={ref} />),
    DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    PlayCircleFilledWhiteIcon: forwardRef((props, ref) => <PlayCircleFilledWhiteIcon {...props} ref={ref} />),
    TransferWithinAStationIcon: forwardRef((props, ref) => <TransferWithinAStationIcon {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />)
    // ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '4%',
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    wrapper: {
        display: 'flex',
        // justifyContent: 'center',
        alignContent: "center",
        paddingTop: "5%"
    },
    delete: {
        color: 'red'
    },
    visibility: {
        color: 'blue'
    }
}));

function ItemImageList() {
    const classes = useStyles();
    const history = useHistory();
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const [resultImage, setResultImage] = React.useState("");
    const [mutate, { delLoading, onSuccess, onError }] = useMutation();
    const createRow = (value) => {
        var imageCode = value.imageCode.split("_")[0]
        var imageCode = imageCode.replaceAll("|", "/")
        var imageFormat = value.imageCode.split(".")[1]
        const row = {
            'code': imageCode,
            'image': value.image,
            'format': imageFormat
        }
        return row
    }

    const { data, loading, error } = useQuery({
        type: 'TNA_CUSTOM_ACTION',
        resource: 'itemTasks',
        payload: {
            action: "getUploadedItemImages",
            method: "GET"
        }
    });
    if (loading) return <Loading />;


    const rows = []
    if (data != null && data != undefined) {
        data.forEach(element => {
            rows.push(createRow(element))
        });
    }


    function deleteImage(rowData) {
        var originalCode = (rowData.code).concat(".").concat(rowData.format)

        mutate({
            type: 'TNA_CUSTOM_ACTION',
            resource: 'itemTasks',
            payload: {
                action: "deleteItemImage",
                method: "POST",
                body: {
                    "imageCode": originalCode
                },
            }
        }, {
            onFailure: (e) => {
                showToastError("Delete Failed:" + e.body);
            },
            onSuccess: (e) => {
                showToastSuccess("Delete Success");
                refresh()
            }
        });
    }

    function showOriginal(rowData) {
        var code = (rowData.code).replaceAll("/", "|")
        var originalCode = code.concat(".").concat(rowData.format)
        mutate({
            type: 'TNA_CUSTOM_ACTION',
            resource: 'itemTasks',
            payload: {
                action: "getOriginalItemImage",
                method: "POST",
                body: {
                    "imageCode": originalCode
                },
            }
        }, {
            onFailure: (e) => {
                showToastError("Image Retrieval Failed:" + e.body);
            },
            onSuccess: (e) => {
                // showToastSuccess("Delete Success");
                // refresh()
                setResultImage(e.data)
                setOpen(true)

            }
        });
    }
    if (delLoading) return <Loading />;

    function redirectToUpload() {
        history.push('/itemImageUpload')
    }
    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div className={classes.wrapper}>
            <div className={classes.paper}>
                <img src={'data:image/jpeg;base64,' + resultImage.image} alt={resultImage.code} width="300" height="400" />
            </div>
        </div>

    );

    return (
        <div className={classes.root}>
            <Title title="Item Image Uploader" />
            <TransitionsModal openModel={open} image={resultImage} handleCloseModel={handleClose}></TransitionsModal>
            <MaterialTable
                icons={tableIcons}
                title="Item Images"
                columns={[
                    {
                        title: 'Image Code',
                        field: 'code',
                        type: 'string'
                    },
                    { title: 'Image', field: 'image', render: item => <img src={'data:image/jpeg;base64,' + item.image} alt={item.code} /> },

                ]}
                data={rows}
                actions={[{
                    icon: () => (
                        <DeleteIcon
                            className={classes.delete}
                        />
                    ),
                    tooltip: 'Delete Image',
                    onClick: (event, rowData) => {
                        deleteImage(rowData)
                    }

                }, {
                    icon: () => (
                        <AddBoxIcon fontSize="Large"/>
                    ),
                    tooltip: 'Upload Image',
                    isFreeAction: true,
                    onClick: (event, rowData) => {
                        redirectToUpload()
                    }
                },
                {
                    icon: () => ( 
                        <VisibilityIcon
                            className={classes.visibility}
                        />
                    ),
                    tooltip: 'View Image',
                    onClick: (event, rowData) => {
                        showOriginal(rowData)
                    }
                }
                ]}

                options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    search: true,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {width: "400px"},
                }}

                components={{
                    Toolbar: props => {
                        const {titleCopy, ...propsCopy} = { ...props };
                        propsCopy.showTitle = false;
                            return (
                                <div style={{ padding: "0px 10px", textAlign: "left" }}>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={1} alignItems="right">
                                            <h5>Item Images</h5>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <MTableToolbar {...propsCopy} />
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                    },
                }}
            />
        </div>
    )
}


export default ItemImageList;