import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import React, { useState } from "react";
import { useDataProvider } from "react-admin";
import { useDispatch } from "react-redux";
import { exportPlanBoardData } from "../../../../store/actions";
import { usePermissions } from "react-admin";
import moment from "moment";
import { ListHelper } from './ListHelper'
import { showToastError, showToastSuccess } from '../../../../lib/toas';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  select: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Popup = React.memo(({ list, state, setList }) => {
  const classes = useStyles();


  const [open, setOpen] = useState(false);
  const [listData, setListData] = React.useState([])
  const [selectListData, setSelectListData] = React.useState([])
  const dataProvider = useDataProvider();


  const handleClickOpen = () => {

    if (state.taskId && state.formId && state.fieldId) {
      if(typeof list == "undefined"){
        showToastError('No current values in this field')
      }
      else if(list.length){
        setSelectListData({tid: state.taskId, fid: state.formId, feid: state.fieldId});
        return  setOpen(true);
      }
      else{
        showToastError('No current values in this field')
      }
    }
    else{
      showToastError('Select Task, Form and Field')
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFilteredList =  async (tid, fid, feid) => {
    try {
      const newList = Object.assign({}, list);

      const result = Object.keys(newList).map(function (key, i) {
          return [newList[key]];
        });

      setListData(result);

    } catch (error) {
      return [];
    }
  }

  React.useEffect(() => {
    if (open) {
      getFilteredList(state.taskId, state.formId, state.fieldId)
    }

  }, [open])

  

  const { permissions } = usePermissions();
  return (
    <div>
      <Button
        className="btn_secondry"
        style={{ width: 280, height: 50, marginBottom: 20 }}
        variant="outlined"
        onClick={handleClickOpen}
      >
         View existing item
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minHeight: "80vh", maxHeight: "80vh" }}
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle id="alert-dialog-title">
              {"Select Values to Delete"}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        <DialogContent>
          <ListHelper
            data={listData}
            selectListData={selectListData}
            handleClose={handleClose}
            setList={setList}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
});
export default Popup;
