import { Grid, Typography } from "@material-ui/core";

import Chip from "@material-ui/core/Chip";

import React, { useState } from "react";



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { format_to_date_time } from '../../lib/date_time'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function SimpleTable({ rows, columns }) {
  const classes = useStyles();


  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              {columns.map((column) => (
                <TableCell component="th" scope="row">
                  {row[column]}
                </TableCell>

              ))}


            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const LineExpand = ({ id, record, resource }) => {
  const groups_array = record?.attributes["supported_groups"]
    ? record.attributes["supported_groups"].split(",")
    : null;

  console.log(groups_array);

  const changed_efficiencies = record?.changed_efficiencies ? record.changed_efficiencies.map(change => ({ ...change, 'start_date': format_to_date_time(change.start_date), 'end_date': format_to_date_time(change.end_date) })) : []
  return (
    <div>

      {record?.attributes["supported_groups"] && (
        <>
          <Grid style={{ padding: "2px" }}>
            <Typography>Supported groups</Typography>
          </Grid>
          {groups_array.map((group) => (
            <Chip label={group} />
          ))}
          <p>{' '}</p>
        </>
      )}
      {record?.changed_efficiencies && changed_efficiencies[0] && (
        <>
          <Grid style={{ padding: "2px" }}>
            <Typography>Changes in efficiency and carder </Typography>
          </Grid>
          {/* {record.changed_efficiencies.map((change) => (
              <Typography>{JSON.stringify(change)}</Typography>
            ))} */}
          <SimpleTable columns={Object.keys(changed_efficiencies[0])} rows={changed_efficiencies} />
        </>
      )}
      <p>{' '}</p>
    </div>
  );
};





export default LineExpand