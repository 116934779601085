import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function SmallLoading({ open = true }: { open: boolean }) {
    const classes = useStyles();

    return open ? (<CircularProgress size={20} color="secondary" />) : null
}

export function SmallLoadingBox({ open = true }: { open: boolean }) {
    return open ? <div style={{ padding: 5, textAlign: 'center' }}>
        <SmallLoading open={open}></SmallLoading>
    </div> : null
}