import React, { Fragment } from "react";
import { cloneElement, useMemo } from "react";
import CreateButton from "../../components/CustomCreateButton";
import ShowButton from "../../components/CustomShowButton";
import {
  Datagrid,
  EditButton,
  List,
  // ShowButton,
  TextField,
  useListContext,
  TopToolbar,
  // CreateButton,
  ExportButton,
  sanitizeListRestProps,
  refreshView,
  TextInput,
  Filter,
  WithPermissions,
  usePermissions,
  BulkDeleteButton,
} from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import Confirmation from "../../components/Confirmation";
import UploadButton from "../../components/BulkActionButton";
import { makeStyles } from "@material-ui/core/styles";
import { DriveEta } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    paddingBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    // width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const SubContractFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Search code" source="code" alwaysOn />
  </Filter>
);

const Actions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const classes = useStyles();
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();

  const dispatch = useDispatch();
  const { permissions } = usePermissions();

  return (
    <TopToolbar
      className={classes.buttonContainer}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
          className: "btn_",
        })}
      <UploadButton
        // meta={}
        title="Upload Subcontractors"
        disabled={!permissions?.permissions.includes("subcontracts_upload")}
        action="upload_subcontractors"
        onSuccess={() => {
          dispatch(refreshView());
        }}
        description="Upload subcontractors in a csv file, in following order: code, name, address, phone_number, plant"
      />
      <CreateButton
        basePath={basePath}
        title="Click here to Create sub contract"
        disabled={!permissions?.permissions.includes("subcontracts_create")}
      />
      <ExportButton
        title="Click here to Export sub contract"
        disabled={
          total === 0 ||
          !permissions?.permissions.includes("subcontracts_export")
        }
        resource={resource}
        className="btn_ btn_primary"
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      {/* Add your custom actions */}
    </TopToolbar>
  );
};

export const SubContract = (props) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const BulkActionButtons = (props) => (
    <div className={classes.root}>
      <BulkDeleteButton
        className="btn_ btn_delete "
        title="Click here to bulk delete sub contract"
        disabled={!permissions?.permissions.includes("subcontracts_bulkdelete")}
        {...props}
      />
    </div>
  );

  return (
    <List
      {...props}
      bulkActionButtons={<BulkActionButtons />}
      actions={<Actions />}
      filters={<SubContractFilters />}
      perPage={25}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="code" />
        <TextField source="name" />
        <TextField source="address" />
        <TextField source="phone_number" />
        <EditButton
          title="Click here to edit sub contract"
          {...props}
          disabled={!permissions?.permissions.includes("subcontracts_edit")}
          className="btn_ btn_edit"
        />{" "}
        <Confirmation
          title="Click here to Delete sub contract"
          disabled={!permissions?.permissions.includes("subcontracts_delete")}
          text="subcontract"
        />{" "}
        <ShowButton
          title="Click here to Show"
          disabled={!permissions?.permissions.includes("subcontracts_show")}
          className="btn_ btn_secondry"
        />
      </Datagrid>
    </List>
  );
};
