import React, { useMemo, useState } from "react";
import FormUploadConfirmationDialog from "../FormUploadConfirmationDialog";
import Button from "@material-ui/core/Button";
const RiskAnalysisForm = ({formName, toEdit, prevFormData, prevFormName }) => {
  const [formData, setFormData] = useState(toEdit ? prevFormData : {});
  const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);

  const options = {
    scale: 0.8,
  };


  const onChange = (event, field) => {
    const id = event.target.name;
    const type = event.target.type;
    //console.log(id, type, event);

    const newFormData = formData;
    if (type === "checkbox") {
      newFormData[field] = !formData[field];
      setFormData(newFormData);
    } else {
      const value = event.target.value;

      newFormData[field] = value;
      setFormData(newFormData);
    }
  };

  const uiInputProps = (formData, id) => {
    const props = {
      required: true,
      defaultValue: formData[id],
      defaultChecked: formData[id],
      onChange: (e) => {
        onChange(e, id);
      },
      // key: formData[id],
      // id: id,
      // name: id,
    }

    if (id.toLowerCase().includes("date")) {
      props['type'] = 'date'
    }

    return props
  }

  const uploadInputProps = (formData, id) => {
    if (formData[id]) {
      return {
        value: formData[id],
        checked: formData[id],
        // key: formData[id],
        // id: id,
        // name: id,
      };
    }
  }


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true && validateCheckBoxes()) {
      setOpenFormUploadDialog(true);
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const tables = {
    "": [
      "Design Sketch",
      "Basic Construction Details",
      "Physical Shoe",
      "Compliance Details",
      "Trim Card With Available Trims",
      "Any Risk",
    ],
    "Upper Features": [
      "Upper Construction",
      "Last Type",
      "Upper Material",
      "Any Risk",
    ],
    "Bottom Part": [
      "Sole Material",
      "Sole Type",
      "Bottom Part Construction",
      "Sole Stitching",
      "Any Risk",
    ],
    "Fabric/ Material Specification": [
      "Shrinkage",
      "Fabric Stretch",
      "Fabric Way",
      "Mold Ability",
      "Skewing",
      "Bowing",
      "Fraying",
      "Yellowing",
      "Laddering",
      "Rolling",
      "Width",
      "Weight",
      "Composition",
      "Pealing",
      "Any Prints/ Stripes/ Placements",
      "Color bleeding risk",
      "Any risk in wash stability",
      "Any risk in fabric/ material",
      "Any risk in welding",
      "Any risk in printing",
      "Any risk heating at chamber",
      "Any risk other normal lab",
    ],
    "Size Spec": ["Size ratio", "Size Range"],
    "Trim Specifications": [
      "Lace details",
      "Eyelet details",
      "Special decorative trim details",
      "Trims & elastic attaching method",
      "Binding width",
      "Binding cut direction",
      "Any risk",
    ],
    Bonding: ["Adhesive", "Bonding construction method", "Any risk"],
    Embellishments: [
      "Type of embellishments",
      "Heat seal",
      "Embroidery",
      "Place print",
      "Hand stitch",
      "Digital print",
      "Applied to cut panels or component",
      "Allowances required?",
      "Any risk",
    ],
    Machinery: [
      "Thread type",
      "Needle Type",
      "Special folders/ attachments required?",
      "Special needle gauges/ conversion kits required?",
      "Any special equipment required?",
      "Special lasts required",
      "Special moulds required?",
      "Any risk",
    ],
    "Construction & Fit": [
      "Any hand stitching operations?",
      "Ironing needed?",
      "Any risk achieving fit for larger sizes?",
      "Any special areas requiring attention?",
    ],
  };

  const validateCheckBoxes = () => {
    var isValid = true;
    const values = Object.keys(tables).map((table) => {
      const tableValues = tables[table].map((row) => {
        // //console.log(isValid);
        if (!isValid) {
          return false;
        } else if (
          !formData[`${table}${row}Yes`] &&
          !formData[`${table}${row}No`]
        ) {
          isValid = false;
          alert(
            `You must at least select 1 item in the text box field ${table} - ${row}`
          );
        } else if (
          formData[`${table}${row}Yes`] &&
          formData[`${table}${row}No`]
        ) {
          isValid = false;
          alert(
            `You should select only 1 item in the text box field ${table} - ${row}`
          );
        } else return true;
      });
    });
    if (!isValid) {
      return false;
    } else if (
      !formData.StyleProductionViableNo &&
      !formData.StyleProductionViableYes
    ) {
      isValid = false;
      alert("You must at least select 1 item in the field.");
    } else if (
      formData.StyleProductionViableNo &&
      formData.StyleProductionViableYes
    ) {
      isValid = false;
      alert("You should select only 1 item in the field.");
    }
    return isValid;
  };
  const Form = ({ formData, inputProps }) => {
    //console.log({formData, inputProps})
    return (
      <div>
        <h2>D Samson Industries (pvt) ltd</h2>
        <h3>Style Risk Analysis</h3>
        <table>
          <tr>
            <th class='form-th' colspan="2">Customer:</th>
            <th class='form-th' colspan="2">Style:</th>
            <th class='form-th' colspan="2">Size range:</th>
            <th class='form-th' colspan="2">Color:</th>
            <th class='form-th' colspan="2">Date:</th>
          </tr>
          <tr>
            <td class='form-td'  colspan="2">
              <input class='form-input' {...inputProps(formData, "Customer")}></input>
            </td>
            <td class='form-td'  colspan="2">
              <input class='form-input' {...inputProps(formData, "Style")}></input>
            </td>
            <td class='form-td'  colspan="2">
              <input class='form-input' {...inputProps(formData, "SizeRange")}></input>
            </td>
            <td class='form-td'  colspan="2">
              <input class='form-input' {...inputProps(formData, "Color")}></input>
            </td>
            <td class='form-td'  colspan="2">
              <input class='form-input' {...inputProps(formData, "Date")}></input>
            </td>
          </tr>
        </table>
        <h4>Attendees</h4>
        <table>
          <tr>
            <th class='form-th' colSpan="2">R&D:</th>
            <td class='form-td'  style={{ width: "40%" }}>
              <input class='form-input' {...inputProps(formData, "r&d")}></input>
            </td>
            <th class='form-th' colSpan="2">Production:</th>
            <td class='form-td'  style={{ width: "40%" }}>
              <input class='form-input' {...inputProps(formData, "production")}></input>
            </td>
          </tr>
          <tr>
            <th class='form-th' colSpan="2">QA:</th>
            <td class='form-td'  style={{ width: "40%" }}>
              <input class='form-input' {...inputProps(formData, "qa")}></input>
            </td>
            <th class='form-th' colSpan="2">IE:</th>
            <td class='form-td'  style={{ width: "40%" }}>
              <input class='form-input' {...inputProps(formData, "ie")}></input>
            </td>
          </tr>
          <tr>
            <th class='form-th' colSpan="2">Design:</th>
            <td class='form-td'  style={{ width: "40%" }}>
              <input class='form-input' {...inputProps(formData, "design")}></input>
            </td>
            <th class='form-th' colSpan="2">Marketing:</th>
            <td class='form-td'  style={{ width: "40%" }}>
              <input class='form-input' {...inputProps(formData, "marketing")}></input>
            </td>
          </tr>
        </table>
        {Object.keys(tables).map((table) => (
          <div>
            <div></div>
            <table>
              <tr>
                <th class='form-th' rowspan="1" style={{ width: "40%" }}>                  
                </th>
                <th class='form-th' rowspan="1" colspan="2">
                  Availability of data
                </th>
                <th class='form-th' rowspan="1" style={{ width: "40%" }}>                  
                </th>
              </tr>

              <tr>
                <th class='form-th' rowspan="1" style={{ width: "40%" }}>
                  <u>{table}</u>
                </th>
                <th class='form-th' rowspan="1" colspan="1">
                  Yes
                </th>
                <th class='form-th' rowspan="1" colspan="1">
                  No
                </th>
                <th class='form-th' style={{ width: "40%" }}>Remarks/Details</th>
              </tr>

              {tables[table].map((row) => {
                return (
                  <tr>
                    <th class='form-th' rowspan="1" style={{ width: "40%" }}>
                      {row}
                    </th>
                    <td class='form-td'  rowspan="1" colspan="1">
                      <input class='form-input'
                        type="checkbox"
                        {...inputProps(formData, `${table}${row}Yes`)}
                        required={false}
                      ></input>
                    </td>
                    <td class='form-td'  rowspan="1" colspan="1">
                      <input class='form-input'
                        type="checkbox"
                        {...inputProps(formData, `${table}${row}No`)}
                        required={false}
                      ></input>
                    </td>

                    <td class='form-td'  style={{ width: "40%" }}>
                      <input class='form-input'
                        {...inputProps(formData, `${table}${row}Remarks`)}
                      ></input>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        ))}
        <table>
          <tr>
            <th class='form-th' rowspan="1" style={{ width: "40%" }}>
              <u></u>
            </th>
            <th class='form-th' rowspan="1" colspan="1">
              Yes
            </th>
            <th class='form-th' rowspan="1" colspan="1">
              no
            </th>
          </tr>
          <th class='form-th' rowspan="1" style={{ width: "40%" }}>
            Is the style production viable?
          </th>
          <td class='form-td'  rowspan="1" colspan="1">
            <input class='form-input'
              type="checkbox"
              {...inputProps(formData, `StyleProductionViableYes`)}
              required={false}
            ></input>
          </td>
          <td class='form-td'  rowspan="1" colspan="1">
            <input class='form-input'
              type="checkbox"
              {...inputProps(formData, `StyleProductionViableNo`)}
              required={false}
            ></input>
          </td>
        </table>
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Form formData={formData} inputProps={uiInputProps} />
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </form>
      <FormUploadConfirmationDialog
        isOpen={openFormUploadDialog}
        setIsOpen={setOpenFormUploadDialog}
        Form={Form({ formData, inputProps: uploadInputProps })}
        formData={formData}
        prevFormName={prevFormName}
        formName={formName}
      />
    </div>
  );
};

export default RiskAnalysisForm;
