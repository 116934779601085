import { REFRESH_VIEW } from "react-admin";
import { put, select, takeEvery } from "redux-saga/effects";
import dataProvider from "../../api/dataProvider";
import { loadNotifications, loadNotificationsSuccess, setNotifications } from "../actions/notificationActions";
import { CHECK_NOTIFICATIONS, LOAD_NOTIFICATIONS, READ_NOTIFICATION, READ_NOTIFICATION_OLD } from "../types/notificationTypes";
import { showToast } from "../../lib/toas";

//toast.configure();

export function* notificationSaga() {
  yield takeEvery(LOAD_NOTIFICATIONS, function* () {
    const resp = yield dataProvider.CUSTOM("notification", {
      id: 1,
      action: "get_notifications",
    });
    const allNotifications = resp.data;
    if(allNotifications!=undefined){

    
    const transferNotifications = allNotifications?.filter(
      (N: any) => N.notify_type.type && N.notify_type.type === "transfer"
    );
    const pendingTransferNotifications = transferNotifications.filter(
      (N: any) =>
        N.notify_type.status && N.notify_type.status.search("pending") !== -1
    );
    const respondedNotifications = transferNotifications.filter(
      (N: any) =>
        N.notify_type.status &&
        (N.notify_type.status === "accepted" ||
          N.notify_type.status === "declined")
    );
    //sort read or unread notifications

    const sortNotificationBasedOnRead = (array: Array<object>) => {
      const readArray: Array<object> = [];
      const unReadArray: Array<object> = [];

      array.map((data: any) => {
        if (data.is_read) {
          readArray.push(data);
        } else {
          unReadArray.push(data);
        }
      });
      return { readArray, unReadArray };
    };
    const allPendingArray = sortNotificationBasedOnRead(
      pendingTransferNotifications
    );
    const allRecivedArray = sortNotificationBasedOnRead(respondedNotifications);

    const sortByDate = (array: Array<object>) => {
      return array.sort((a: any, b: any) => {
        let dateA: any = new Date(a.time_stamp);
        let dateB: any = new Date(b.time_stamp);
        return  dateB - dateA  ;
      });
    };

    const pendingRead = sortByDate(allPendingArray.readArray);
    const PendingUnRead = sortByDate(allPendingArray.unReadArray);
    const reciveRead = sortByDate(allRecivedArray.readArray);
    const reciveUnRead = sortByDate(allRecivedArray.unReadArray);
    const count =  reciveUnRead.length + PendingUnRead.length

    // set notification object

    const data = {
      allNotifications: resp.data,
      transfers: {
        count: count,
        pendingTransfers: {
          count: pendingTransferNotifications.length,
          read: pendingRead,
          unRead: PendingUnRead,
          all: pendingTransferNotifications,
        },
        respondedNotifications: {
          count: respondedNotifications.length,
          read: reciveRead,
          unRead: reciveUnRead,
          all: respondedNotifications,
        },
      },
    };

    yield put(setNotifications(data));
  }
    yield put(loadNotificationsSuccess());
  });

  // mark read notification
  yield takeEvery(READ_NOTIFICATION, function* (action: any) {
    const { notification_id } = action.payload
    const resp = yield dataProvider.CUSTOM("notification", {
      id: notification_id,
      action: "mark_read",
    });
    
  })

  yield takeEvery(READ_NOTIFICATION_OLD, function* (action: any) {
    const { notification_id } = action.payload;
    const resp = yield dataProvider.CUSTOM("notification", {
      id: notification_id,
      action: "mark_read_old",
    });

    yield put({ type: LOAD_NOTIFICATIONS });

    //yield put(loadNotificationsSuccess());
  });

  // check notification
  yield takeEvery(REFRESH_VIEW, function*() {
    const path = yield select((s: any) => s.router.location.pathname)
    if (path.search(/(notification|notifyTransfers|notifyList|notifyRecived)/) !== -1) {
      yield put(loadNotifications())
    }
  })

  yield takeEvery(CHECK_NOTIFICATIONS, function* () {
    const resp = yield dataProvider.CUSTOM("notification", {
      id: 1,
      action: "get_notifications",
    });

    const allNotificationsCount = resp.data.length;

    const notificationsCount = yield select(
      (state: any) => state.custom.notifications.oderTransfer.count
    );

    const allNotifications = yield select(
      (state: any) => state.custom.notifications.oderTransfer.all
    );


    if (allNotificationsCount !== notificationsCount) {

      //yield checkNotifications();

      // toast(" You Have New Notification !", {
      //   className: css({
      //     background: "#34A853",
      //   }),
      //   bodyClassName: css({
      //     fontSize: "30px",
      //   }),
      //   progressClassName: css({
      //     background:
      //       "repeating-radial-gradient(circle at center, red 0, blue, green 30px)",
      //     height: "2px",
      //   }),
      // });
      showToast("You Have New Transfer Notification !");
    }
    // while (true) {
    // //  yield call(delay, 50000);

    // }

    
  });
  // yield takeEvery(LOAD_NOTIFICATIONS, function* () {
  //   const chatSocket = new WebSocket(
  //     `ws://127.0.0.1:8000/ws/notification/`
  //   );
  //   chatSocket.send("test");

  //   chatSocket.onmessage = function (event: any) {
  //   };
  // });
}
