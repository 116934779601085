import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import { cloneElement, Fragment } from "react";
import {
  Datagrid,
  Filter,
  List,
  Mutation,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useMutation,
} from "react-admin";
import {
  TopToolbar,
  // CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
  usePermissions,
  BulkDeleteButton,
} from "react-admin";
import CreateButton from "../../components/CustomCreateButton";
import CustomDateField from "../../components/field/CustomDateField";
import { useAutoScroll } from "../../hooks/useScroll";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    paddingBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

export const OrderGroup = (props) => {
  const autoScroll = useAutoScroll(props);

  const { permissions } = usePermissions();

  const Actions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const classes = useStyles();
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
    } = useListContext();

    return (
      <TopToolbar
        className={classes.buttonContainer}
        {...sanitizeListRestProps(rest)}
      >
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
            className: "btn_",
          })}
        <CreateButton
          basePath={basePath}
          title="Click here to create order groups"
          disabled={!permissions?.permissions.includes("orderGroups_create")}
        />
        <ExportButton
          className="btn_ btn_primary"
          title="Click here to Export order groups"
          disabled={!permissions?.permissions.includes("orderGroups_export")}
        />
      </TopToolbar>
    );
  };

  const ToggleSkipPlanning = ({ record }) => {
    const [action] = useMutation({
      type: "update",
      resource: "orderGroups",
      payload: {
        id: record.id,
        data: {
          skip_planning: !record.skip_planning,
          code: record.code,
        },
      },
    });
    return (
      <Checkbox
        title="permissions"
        disabled={
          !permissions?.permissions.includes("orderGroups_skipPlanning")
        }
        onClick={action}
        checked={record.skip_planning}
      />
    );
  };

  const OrderGroupFilter = (props) => {
    const { permissions } = usePermissions();
    const isAdmin = permissions?.access_level == "ADMIN"
    return (
      <Filter {...props}>
        <TextInput label="Search code" source="code" alwaysOn />
        {isAdmin && <ReferenceInput
          alwaysOn
          link=""
          label="Site"
          source="site_id"
          reference="sites"
        >
          <SelectInput optionText="name" resettable />
        </ReferenceInput>}
        <ReferenceInput
          alwaysOn
          label="Plant"
          source="plant"
          reference="plants"
          filter={{ site_id: props.filterValues.site_id }}
        >
          <SelectInput optionText="name" resettable />
        </ReferenceInput>
      </Filter>
    );
  }

  const BulkActionButtons = (props) => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        {/* default bulk delete action */}
        <BulkDeleteButton
          className="btn_ btn_delete"
          title="Click here to delete order groups"
          disabled={
            !permissions?.permissions.includes("orderGroups_bulkDelete")
          }
          {...props}
        />
      </div>
    );
  };

  const Title = ({ record }) => {
    return <span>Order Groups</span>;
  };

  return (
    <List
      {...props}
      undoable={false}
      filters={<OrderGroupFilter />}
      title={<Title />}
      actions={<Actions />}
      perPage={25}
      bulkActionButtons={<BulkActionButtons />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="code" />
        <CustomDateField source="required_date" showTime />

        <ReferenceField
          link=""
          label="Plant"
          source="plant"
          reference="plants"
          sortBy="plant__name"
        >
          <TextField source="name" />
        </ReferenceField>
        <ToggleSkipPlanning source="skip_planning" />
      </Datagrid>
    </List>
  );
};
