import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import SmallLoading from "../../../components/loading/SmallLoading";
import {
  Create,
  Query,
  Error,
  AutocompleteInput,
  SimpleForm,
  Edit,
  useDataProvider,
  FormDataConsumer,
  Loading,
  Mutation,
  Title,
} from "react-admin";
import dataProvider from "../../../api/dataProvider";
import { Button, Checkbox } from "@material-ui/core";
import { showToastSuccess, showToastError } from "../../../lib/toas";
import { useHistory } from "react-router-dom";

export const TemplatesEdit = (props) => {
  const [rowdata, setRowData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  // const queryData = { tna_tasks: [], tna_departments: [], 'users': [] };
  const queries = ["tna_tasks", "tna_departments"];
  const [queryData, setQueryData] = useState({
    tna_tasks: [],
    tna_departments: [],
    users: [],
  });
  const [flow, setFlow] = useState();
  const [templateCode, setTemplateCode] = useState();
  const [queryLoading, setQueryLoading] = useState(true);
  const [queryDataLoading, setQueryDataLoading] = useState(true);
  const [queryTaskLoading, setQueryTaskLoading] = useState(true);

  // console.log(queryData);

  const dataProvider = useDataProvider();

  const loadQuery = async () => {
    await queries.map((query) => {
      dataProvider
        .getList(query, {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "id", order: "ASC" },
        })
        .then(({ data }) => {
          const newQueryData = queryData;
          newQueryData[query] = data;
          setQueryData(newQueryData);
        });
    });
    setQueryTaskLoading(false)
    await dataProvider
      .getList("users", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        filter: { is_tna_user: true },
      })
      .then(({ data }) => {
        const newQueryData = queryData;
        newQueryData["users"] = data;
        setQueryData(newQueryData);
      });
    setQueryDataLoading(false)

  };

  const loadData = async () => {
    await dataProvider
      .getList("tna_templateItems", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        filter: { template: props.id },
      })
      .then(({ data }) => {
        function getSequenceNumbers(items){
          var seqNos=[]
          items.forEach(item=>{
            seqNos.push(item?.sequence_no)
          })
          return seqNos
        }

        setRowData(
          data.map((item) => {
            var dependents=data.filter(x=>item.dependent_item?item.dependent_item.includes(x.id):null)
            var dependentTasks=getSequenceNumbers(dependents)

            return({
            id: item.id,
            sequence_no: item.sequence_no,
            task: item.task,
            assigned_department: item.assigned_department,
            assigned_user: item.assigned_user,
            start_offset_days: item.start_offset_days,
            duration: item.duration,
            need_approval: item.need_approval,
            transferred_to:item.transferred_to,
            dependent_item:dependentTasks,
            isInitialData: true,
          })
        }
        ))
        setInitialData(
          data.map((item) =>{
            var dependents=data.filter(x=>item.dependent_item?item.dependent_item.includes(x.id):null)
            var dependentTasks=getSequenceNumbers(dependents)

            return({
            id: item.id,
            sequence_no: item.sequence_no,
            task: item.task,
            assigned_department: item.assigned_department,
            assigned_user: item.assigned_user,
            start_offset_days: item.start_offset_days,
            duration: item.duration,
            need_approval: item.need_approval,
            transferred_to:item.transferred_to,
            dependent_item:dependentTasks,
            isInitialData: true,
          })
        }))
      })
      .catch((error) => {
        console.log(error);
      });
    console.log({ rowdata });
    await setQueryLoading(false);
  };

  React.useEffect(() => {
    loadQuery();
    loadData();
  }, []);

  const onAddRowClick = () => {
    setRowData(
      rowdata.concat({
        sequence_no: "",
        task: "",
        assigned_department: "",
        assigned_user: "",
        start_offset_days: "",
        duration: "",
        need_approval: false,
      })
    );
  };
  let history = useHistory();
  const redirect = (id) => {
    history.push("/tna_templates");
  };

  function clearDependents(field,dependentField,val,updatingRow){
    console.log(updatingRow)
    if(field=='need_approval' && val==false){
      updatingRow['transferred_to']=""
    }
  }

  function validateDependentTasks(field,value,updatingRow) {
    if(field=="dependent_item"){
      const dependentTasks=value.split(",")
      if(dependentTasks.includes(updatingRow["sequence_no"].toString())){
        return false
      }else{
        return true
      }
    }else{
      return true
    }
  }

  const updateData = (row, col, value) => {
    let row_to_update = rowdata[row];
    const prevValue= row_to_update[col]
    row_to_update[col] = value;
    const isValidDependents=validateDependentTasks(col,value,row_to_update)
    if (!isValidDependents){
      showToastError("Template task is in its dependent tasks")
      row_to_update[col] = prevValue;
    }
    clearDependents(col,'transferred_to',value,row_to_update)
    setRowData([
      ...rowdata.slice(0, row),
      row_to_update,
      ...rowdata.slice(row + 1),
    ]);
  };
  const removeRow = (row) => {
    setRowData([...rowdata.slice(0, row), ...rowdata.slice(row + 1)]);
  };
  const HeaderCell = ({ value }) => (
    <div
      style={{
        padding: 5,
        backgroundColor: "#c2e8ff",
        textAlign: "center",
        width: window.innerWidth * 0.08,
        minHeight: 35,
        fontSize: "14px",
      }}
    >
      {value}
    </div>
  );

  // const saveTemplate = () => {
  //   if (rowdata && flow) {
  //     const template_data = {
  //       type: flow,
  //       template_items: rowdata,
  //       code: templateCode,
  //     };

  //     const options = {
  //       body: template_data,
  //       action: "create_template",
  //       method: "POST",
  //     };
  //     console.log({ options });
  //     dataProvider.TNA_CUSTOM_ACTION("templates", options).then(() => {
  //       showToastSuccess("Template Creation Success");
  //       redirect();
  //     });
  //   } else if (!flow) {
  //     showToastError("Please select template type");
  //   } else {
  //     showToastError("Error creating template");
  //   }
  // };

  const SaveTemplate = () => {
    const template_data = {
      type: flow,
      template_items: rowdata,
      code: templateCode,
    };

    const options = {
      body: template_data,
      action: "create_template",
      method: "POST",
    };
    const saveInvalid = () => {
      return (rowdata.length == 0 || rowdata?.filter(d => d.start_offset_days == null || d.duration == null).length > 0 || rowdata?.filter(d=>(d.sequence_no!=1 && (d.dependent_item == null || d.dependent_item==[] || d.dependent_item==""))).length >0)
    }

    return (
      <Mutation type="TNA_CUSTOM_ACTION" resource="templates" payload={options}>
        {(action) => {
          return (
            <Button
              variant="contained"
              class="btn-success px-5 py-2 border-0"
              color="Secondary"
              onClick={() => {
                if (saveInvalid()) {
                  showToastError("Please add rows and fill all required fields before saving template")
                }
                else{
                  if (rowdata && flow) {
                    action();
                    redirect();
                  } else if (!flow) {
                    showToastError("Please select template type");
                  } else {
                    showToastError("Error creating template");
                  }
                }
              }}
            >
              Save
            </Button>
          );
        }}
      </Mutation>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: <HeaderCell value="Sequence No" />,
        accessor: "sequence_no",
        Cell: (props) => <EditableCell {...props} updateData={updateData} />,
      },
      {
        Header: <HeaderCell value="Task" />,
        accessor: "task",
        Cell: (props) => (
          <EditableCell
            {...props}
            isQuery={{ resource: "tna_tasks" }}
            queryData={queryData}
            updateData={updateData}
          />
        ),
      },
      {
        Header: <HeaderCell value="Department" />,
        accessor: "assigned_department",
        Cell: (props) => (
          <EditableCell
            {...props}
            isQuery={{ resource: "tna_departments" }}
            queryData={queryData}
            updateData={updateData}
          />
        ),
      },
      {
        Header: <HeaderCell value="Assignee" />,
        accessor: "assigned_user",
        Cell: (props) => (
          <EditableCell
            {...props}
            isQuery={{ resource: "users" }}
            queryData={queryData}
            updateData={updateData}
          />
        ),
      },
      {
        Header: <HeaderCell value="Start day" />,
        accessor: "start_offset_days",
        Cell: (props) => <EditableCell {...props} updateData={updateData} />,
      },
      {
        Header: <HeaderCell value="Duration" />,
        accessor: "duration",
        Cell: (props) => <EditableCell {...props} updateData={updateData} />,
      },
      {
        Header: <HeaderCell value="Dependent tasks" />,
        accessor: "dependent_item",
        Cell: (props) => <EditableCell {...props} updateData={updateData} />,
      },
      {
        Header: <HeaderCell value="Need approval" />,
        accessor: "need_approval",
        Cell: (props) => (
          <EditableCell
            {...props}
            isQuery={{ bool: true }}
            updateData={updateData}
          />
        ),
      },
      {
        Header: <HeaderCell value="Transfer to" />,
        accessor: "transferred_to",
        Cell: (props) => (
          <EditableCell
            {...props}
            disabled={!props['row']['values']['need_approval']}
            isQuery={{ resource: "users" }}
            queryData={queryData}
            updateData={updateData}
          />
        ),
      },

      {
        Header: <HeaderCell value="" />,
        accessor: "close",
        Cell: (props) => (
          <Button
            variant="contained"
            style={{
              maxWidth: 30,
              backgroundColor: "red",
              color: "white",
              marginLeft: window.innerWidth * 0.025,
            }}
            onClick={() => {
              removeRow(parseInt(props.row.id));
            }}
          >
            X
          </Button>
        ),
      },
    ],
    [queryData, queryLoading, initialData,queryDataLoading,queryTaskLoading]
  );

  return React.useMemo(
    () => (
      <Edit {...props} title={<span>Clone template</span>}>
        <Query
          type="getOne"
          resource="tna_template"
          payload={{
            id: props.id,
          }}
        >
          {({ data, loading, error }) => {
            console.log(loading, queryLoading);

            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Error />;
            }

            const formData = data;
            // setTemplateCode(formData.code);
            setFlow(formData.type);

            return (
              <div>
                <button
                  // disabled
                  onClick={onAddRowClick}
                  style={{
                    borderRadius: 5,
                    borderColor: "transparent",
                    margin: 15,
                  }}
                >
                  Add Row
                </button>
                Select type
                <select
                  onChange={(e) => setFlow(e.target.value)}
                  value={formData.type}
                  // value={flow}

                  style={{ marginLeft: 5, marginRight: 20 }}
                >
                  <option value={{}}></option>
                  <option value="ORDER_FLOW">Order Flow</option>
                  <option value="INQUIRY_FLOW">Inquiry Flow</option>
                </select>
                Template Code
                <input
                  onChange={(e) => setTemplateCode(e.target.value)}
                  style={{ marginLeft: 5, marginRight: 20 }}
                  type="text"
                  // defaultValue={formData.code}
                  // value={templateCode}
                ></input>
                <SaveTemplate />
                {/* <button
                  style={{ borderRadius: 5, borderColor: "transparent" }}
                  onClick={saveTemplate}
                >
                  Save
                </button> */}
                <div className="">
                  {rowdata && (
                    <CustomTable
                      columns={columns}
                      data={rowdata}
                      queryData={queryData}
                    />
                  )}
                </div>
              </div>
            );
          }}
        </Query>
      </Edit>
    ),
    [queryData, rowdata, columns, flow, templateCode, queryData["tna_tasks"]]
  );
};

const CustomTable = ({ columns, data, queryData }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable({
    columns,
    data,
  });

  console.log({ data });
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th className="" {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="" {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td className="" {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const EditableCell = ({
  isQuery,
  queryData,
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  disabled = false,
}) => {
  const [value, setValue] = useState(initialValue);
  const [boolVal, setBoolVal] = useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onEdit = (e) => {
    onChange(e);
    updateData(index, id, e.target.value);
  };
  const onToggle = (val) => {
    setBoolVal(val);
    updateData(index, id, val);
  };
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return isQuery?.resource ? (
    <select
      disabled={disabled}
      style={{
        border: "none",
        backgroundColor: "transparent",
        width: window.innerWidth * 0.08,
      }}
      value={value}
      onChange={onEdit}
    >
      {[{}].concat(queryData[isQuery?.resource]).map((d) => {
        // console.log({ query: queryData[isQuery?.resource], d });
        return <option value={d.id}>{d.title || d.name || d.username}</option>;
      })}
    </select>
  ) : isQuery?.bool ? (
    <Checkbox
      disabled={disabled}
      style={{ marginLeft: window.innerWidth * 0.035 }}
      value={boolVal}
      checked={boolVal}
      onChange={() => onToggle(!boolVal)}
    />
  ) : (
    <input
      disabled={disabled}
      value={value}
      onChange={onEdit}
      style={{ maxWidth: window.innerWidth * 0.08 }}
      type="text"
    />
  );
};

const payload = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: "id", order: "ASC" },
};

// const LoadData = ({ query, queryData }) => {
//   console.log("query running");
//   return (
//     <Query type="getList" resource={query} payload={payload}>
//       {({ data, loading, error }) => {
//         if (data) {
//           queryData[query] = data;
//         }
//         if (loading) {
//           return <></>;
//         }
//         if (error) {
//           return <Error />;
//         }
//         return null;
//       }}
//     </Query>
//   );
// };
