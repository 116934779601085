import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import jsonExport from "jsonexport/dist";
import moment from "moment";
import React, { useState } from "react";
import {
  downloadCSV,
  Loading,
  Query,
  Title,
  usePermissions,
} from "react-admin";
import dataProvider from "../../api/dataProvider";
import DateInput from "../../components/input/DateInput";
import { format_to_date_time } from "../../lib/date_time";
import { showToastError } from "../../lib/toas";
import MoldChangeTable from "./MoldChangeTable";
import { ShiftPlanTable } from "./ShiftPlanTable";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  spaced: {
    margin: "10px",
  },
}));

export default function PlanView() {
  const classes = useStyles();
  const payload = {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: "id", order: "ASC" },
  };

  //debug data
  // const [selectedPlan, selectPlan] = useState(1);
  // const [selectedGroup, selectGroup] = useState(1);
  // const [selectedShift, selectShift] = useState({
  //   id: 37038,
  //   start_time: "2019-11-11T19:00:00",
  //   end_time: "2019-11-12T05:45:00"
  // });
  // const [selectedDate, setSelectedDate] = useState(moment("2019-11-11").format('YYYY-MM-DD'));

  const [selectedPlan, selectPlan] = useState();
  const [selectedGroup, selectGroup] = useState();
  const [selectedShift, selectShift] = useState();
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const handleDateChange = (e) => {
    setSelectedDate(moment(e).format("YYYY-MM-DD"));
  };

  const exportData = async () => {
    if (!selectedShift) {
      showToastError("Please select data!");
      return;
    }

    let allocations = await getAllocationsForExport();
    let lines = await getLinesForExport();

    lines = lines.data.map((l) => {
      let allocs = allocations.data
        .filter((a) => a.line_id == l.id)
        .map((a) => ({
          order_code: a.order_code ? a.order_code : " ",
          item_code: a.item_code
            ? a.item_code
            : format_to_date_time(a.start_time),
          quantity: a.quantity != 0 ? a.quantity : "mould change",
          unit: a.item_unit ? a.item_unit : " ",
          mould: a.configuration,
        }));

      return {
        head: l.code,
        initial_mould: allocs.length > 0 ? allocs[0].mould : "",
        "": allocs,
      };
    });

    let config = {
      plan_board_group: selectedGroup,
      date: moment(selectedShift.start_time).format("MM/DD/YYYY"),
      shift: {
        start: moment(selectedShift.start_time).format("hh:mm:ss A"),
        end: moment(selectedShift.end_time).format("hh:mm:ss A"),
      },
    };

    jsonExport(config, {}, (err, configcsv) => {
      //prepare shift plan table
      jsonExport(lines, {}, (err, csv) => {
        downloadCSV(configcsv + "\n\n" + csv, "plan");
      });
    });
  };

  const getLinesForExport = () =>
    dataProvider.getList("boardLine", {
      pagination: { page: 1, perPage: 1000000 },
      sort: { field: "id", order: "ASC" },
      filter: {
        plan_board_group: selectedGroup,
      },
    });

  const getAllocationsForExport = () =>
    dataProvider.getList("allocations", {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: "id", order: "ASC" },
      filter: {
        start_time: moment(selectedShift.start_time).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        end_time: moment(selectedShift.end_time).format("YYYY-MM-DD HH:mm:ss"),
        plan_board_group: selectedGroup,
      },
    });

  const { permissions } = usePermissions();

  return (
    <div className={classes.root}>
      <Title title="Floor View" />
      <Grid
        container
        // spacing={1}
      >
        <Grid
          container
          justify="space-between"
          alignItems="center"
          item
          xs={12}
        >
          <Typography className="mt-3 mb-2" component={"div"}>
            Plan Board
          </Typography>
          <Tooltip title="Click here to Export">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                exportData();
              }}
              style={{ float: "left" }}
              className="btn_ btn_primary"
              title="permissions"
              disabled={!permissions?.permissions.includes("floorView_export")}
            >
              Export
            </Button>
          </Tooltip>
          <MoldChangeTable
            selectedShift={selectedShift}
            selectedGroup={selectedGroup}
          />
        </Grid>

        <Grid item xs={12}>
          {/* <planBoardSelector /> */}
          <div className={classes.spaced}></div>
          <PlanBoardSelector
            payload={payload}
            selectPlan={selectPlan}
            selectedPlan={selectedPlan}
          />
          {selectedPlan ? <LastPlanDate selectedPlan={selectedPlan} /> : null}

          {selectedPlan ? (
            <PlanBoardGroupSelector
              payload={payload}
              selectedPlan={selectedPlan}
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
            />
          ) : null}
          <br />
          {selectedGroup ? (
            <div   style={{maxWidth:"25%",}}> <DateInput
            id="date"
            label="Date"
            type="date"
            value={selectedDate}
            // className={classes.textField}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            onChange={(e) => handleDateChange(e)}
          /></div>
           
          ) : null}
          {selectedDate && selectedGroup ? (
            <ShiftSelector
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
              selectShift={selectShift}
              date={selectedDate}
              selectedShift={selectedShift}
            />
          ) : null}
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={12}>
          <ShiftPlanTable
            selectedShift={selectedShift}
            selectedGroup={selectedGroup}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const ShiftSelector = ({
  payload,
  selectGroup,
  selectedGroup,
  date,
  selectShift,
  selectedShift,
}) => (
  <React.Fragment>
    <Typography component={"div"}>Shift</Typography>
    <Query
      type="CUSTOM"
      resource="boardGroup"
      payload={{ action: "shifts", id: selectedGroup, query: { date } }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return "Error";
        }
        return (
          <ButtonGroup
            variant="outlined"
            color="primary"
            aria-label="outlined primary button group"
          >
            {data.success.map((v) => (
              <Button
                variant={
                  selectedShift && selectedShift.id == v.id
                    ? "contained"
                    : "outlined"
                }
                onClick={() => {
                  selectShift({
                    id: v.id,
                    start_time: v.start_time,
                    end_time: v.end_time,
                  });
                }}
              >{`${format_to_date_time(v.start_time)}(${v.total_smv})`}</Button>
            ))}
          </ButtonGroup>
        );
      }}
    </Query>
  </React.Fragment>
);

const PlanBoardSelector = ({ payload, selectPlan, selectedPlan }) => (
  <React.Fragment>
    {/* <Typography className="mt-3 mb-2" component={"div"}>
      Plan Board
    </Typography> */}
    <Query type="getList" resource="planBoards" payload={payload}>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return "Error";
        }
        return (
          <ButtonGroup
            variant="outlined"
            color="primary"
            className="btn-group-justified d-inline"
            aria-label="outlined primary button group"
          >
            {data.map((v) => (
              <Button
                variant={selectedPlan == v.id ? "contained" : "outlined"}
                className="mb-2 mr-3 py-2 border-right"
                onClick={() => {
                  selectPlan(v.id);
                }}
              >
                {v.code}
              </Button>
            ))}
          </ButtonGroup>
        );
      }}
    </Query>
  </React.Fragment>
);

const LastPlanDate = ({ selectedPlan }) => {
  const [revisionDate, setRevisionDate] = useState();
  dataProvider
    .CUSTOM("planRevision", {
      id: selectedPlan,
      action: "get_last_revision",
    })
    .then(({ data }) => {
      setRevisionDate(data);
    });

  if (revisionDate)
    return (
      <Typography>
        Last Published Plan Revision: {format_to_date_time(revisionDate)}
      </Typography>
    );
  else return <div></div>;
};

const PlanBoardGroupSelector = ({
  payload,
  selectedPlan,
  selectGroup,
  selectedGroup,
}) => (
  <React.Fragment>
    <Typography className="mt-3 mb-2" component={"div"}>
      Plan Board Group
    </Typography>
    <Query type="getList" resource="boardGroup" payload={payload}>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return "Error";
        }
        return (
          <ButtonGroup
            variant="outlined"
            color="primary"
            aria-label="outlined primary button group"
          >
            {data
              .filter((v) => v.plan_board == selectedPlan)
              .map((v) => (
                <Button
                  variant={selectedGroup == v.id ? "contained" : "outlined"}
                  className="mb-2 mr-3 py-2 border-right"
                  onClick={() => {
                    selectGroup(v.id);
                  }}
                >
                  {v.code}
                </Button>
              ))}
          </ButtonGroup>
        );
      }}
    </Query>
  </React.Fragment>
);
