import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";


export const PlanBoardLineCompatibility = props => (
  <List  {...props} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="item_operation" />
      <TextField source="plan_board_line" />
      <TextField source="performance_multiplier" />
      <TextField source="priority" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);
