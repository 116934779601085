import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { connect } from "react-redux";
import { format_to_date_time, format_to_date_for_api } from "../../lib/date_time";
import { modelClose } from "../../store/actions";
import {
  getAllocations,
  getEntity,
  getIsPlanBoardModelOpen,
  getSelection,
} from "../../store/selectors";

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 800,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function LineAllocationDetailed({
  modelOpen,
  selection,
  closeModel,
  allocationList,
  allAllocations3,
}) {
  const classes = useStyles();
  //init date
  const date = new Date();
  const startDate = format_to_date_for_api(date); // format time stamp
  //convert date to timestamp
  const convertTimeToTimeStamp = (date) => {
    const date1 = new Date(date).getTime();
    return date1;
  };

  //state

  const [timeStart, setTimeStart] = useState(startDate);
  const [allocation, setAllocation] = useState([]);
  const [input, setInput] = useState("");

  const allocationsFilterByDate = (date) => {
    const timeStampOfSelectedDate = convertTimeToTimeStamp(date);
    const filteredArray = allAllocations3.filter(
      (allocation) =>
        (convertTimeToTimeStamp(allocation.end_time) >= timeStampOfSelectedDate)
    );
    return filteredArray.sort((a, b) => a.id - b.id);
  };

  //when user  search
  const searchFilter = (text) => {
    const searchArray = allocationList.filter(({ demandOperation }) => {
      if (demandOperation) {
        return demandOperation.order_code.includes(text);
      }
    });
    return searchArray;
  };

  // some ids too long and massy this function
  //simplify id

  const idPrityer = (int) => {
    int = `${int}`;
    if (int.split("").includes("-")) {
      let toArr = int.split("-")[0];
      return toArr;
    }
    return int;
  };

  // when selection id change
  React.useEffect(() => {
    setAllocation(allocationList);
  }, [allocationList, selection.id]);

  // when user change the time
  // React.useEffect(() => {
  //   const alo = allocationsFilterByDate(timeStart);
  //   setAllocation(alo);
  // }, [timeStart]);

  //when user search somthing this effect fire
  // React.useEffect(() => {
  //   const result = searchFilter(input);
  //   setAllocation(result);
  // }, [input]);

  React.useEffect(() => {
    //filter allocations accoirding to order code and start date
    const filterAllocations = (text, date) => {
      const timeStampOfSelectedDate = convertTimeToTimeStamp(date)
      const filteredAllocations = allAllocations3.filter((allocation) => (convertTimeToTimeStamp(allocation.end_time) >= timeStampOfSelectedDate)
        && (text !== "" ? allocation.demandOperation.order_code.includes(text) : true)
      )
      return filteredAllocations.sort((a, b) => a.id - b.id)
    }
    setAllocation(filterAllocations(input, timeStart))
  }, [input, timeStart, allAllocations3])

  // when model close
  const handleClose = () => {
    closeModel();
  };

  return (
    <>
      <Dialog
        open={modelOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle id="alert-dialog-title">{selection.code}</DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        <div style={{ display: "flex" }}>
          <DialogContent>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="standard-basic"
                label="Order code"
                onChange={(e) => setInput(e.target.value)}
              />
            </form>
          </DialogContent>
          <div style={{ justifyContent: "flex-end" }}>
            <DialogContent>
              <TextField
                variant="filled"
                label="Start Date"
                id="date"
                type="date"
                defaultValue={timeStart}
                className={classes.textField}
                onChange={(e) => setTimeStart(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </DialogContent>
          </div>
        </div>
        {allocation.length === 0 ? (
          <DialogContent>
            <DialogContentText>No Allocations</DialogContentText>
          </DialogContent>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Id</TableCell>
                    <TableCell align="center">Order code</TableCell>
                    <TableCell align="center">Item code</TableCell>
                    <TableCell align="center">RM ready</TableCell>
                    <TableCell align="center">need date</TableCell>
                    <TableCell align="center">Configuration</TableCell>
                    <TableCell align="center">Allocation Start</TableCell>
                    <TableCell align="center">Allocation End</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allocation.map((data, i) => (
                    <TableRow key={i}>
                      <TableCell align="center">{idPrityer(data.id)}</TableCell>
                      <TableCell align="center">
                        {data.demandOperation &&
                          data.demandOperation.order_code}
                      </TableCell>
                      <TableCell align="center">{data.demandOperation &&
                          data.demandOperation.item_code}</TableCell>
                      <TableCell align="center">{format_to_date_time(data.demandOperation.rm_ready_date)}</TableCell>
                      <TableCell align="center">{format_to_date_time(data.demandOperation.required_time_max)}</TableCell>
                      <TableCell align="center">{data.configuration}</TableCell>
                      <TableCell align="center">
                        {format_to_date_time(data.start_time)}
                      </TableCell>
                      <TableCell align="center">
                        {format_to_date_time(data.end_time)}
                      </TableCell>

                      <TableCell align="center">{data.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <DialogActions>
          {/* <Button onClick={handleClose} variant="contained" color="primary">
            close
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
const mapStateToProps = (state, props) => {
  const today = new Date();
  const datetoday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  ).getTime();

  const convertTimeToTimeStamp = (date) => {
    const date1 = new Date(date).getTime();
    return date1;
  };

  const allAllocations = getAllocations(state);
  const modelOpen = getIsPlanBoardModelOpen(state);
  const selectionState = getSelection(state);
  const selection = getEntity(state, "lines", selectionState.id);

  const allAllocations2 = allAllocations
    .map((data) => data)
    .filter((a) => a.plan_board_line_id === selection.id)
    .filter((data) => {
      return convertTimeToTimeStamp(data.end_time) >= datetoday;
    });
  const allocationList = allAllocations2.map((a) => {
    const demandOperation = getEntity(
      state,
      "demand_operations",
      a.demand_operation_id
    );
    return { ...a, demandOperation };
  }).filter(allocation => allocation.demandOperation);
  const all = allAllocations
    .map((data) => data)
    .filter(({ plan_board_line_id }) => plan_board_line_id === selection.id);

  const allAllocations3 = all.map((a) => {
    const demandOperation = getEntity(
      state,
      "demand_operations",
      a.demand_operation_id
    );
    return { ...a, demandOperation };
  }).filter(allocation => allocation.demandOperation);
  return {
    allAllocations: all,
    modelOpen,
    selection,
    allocationList,
    allAllocations3,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModel: () => dispatch(modelClose()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineAllocationDetailed);
