import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";
import { redirect } from "../../lib/redirect";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";

// const validatePermission = (values) => {
//   const errors = {};

//   if (!values.code) {
//     errors.code = "Code is required";
//   }
//   return errors;
// };

export const EditPermission = (props) => {
  const useStyles = makeStyles({
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
  });
  const Title = ({ record }) => {
    return <span>Permission | {record ? `${record.code ?? ""} ` : ""}</span>;
  };
  const customToast = useCustomToast(props);
  return (
    <>
      <p className="mt-5 ml-3">Edit the name you prefer</p>
      <Edit
        {...props}
        class={"col-md-6"}
        onSuccess={customToast.onSuccess}
        onFaliure={customToast.onFaliure}
        undoable={false}
        title={<Title/>}
      >
        <SimpleForm
          // validate={validatePermission}
          toolbar={
            <CustomToolbar need={false} name="permissions" text="Permission" />
          }
        >
          <TextInput disabled source="code"  />
          <TextInput source="name" className="mt-4"  label={<ReqInputField name="Name" />}/>
        </SimpleForm>
      </Edit>
    </>
  );
};
