import { REFRESH_VIEW } from "react-admin";
import { LOAD_TNA_NOTIFICATIONS, SET_TNA_NOTIFICATIONS, LOAD_TNA_NOTIFICATIONS_SUCCESS, MARK_NOTIFICATION_READ, SET_NOTIFICATION_READ, SET_NOTIFICATION_TO_LAST } from "../types/tnaNotificationTypes";

//load
export const loadTNANotifications = () => {
    return { type: LOAD_TNA_NOTIFICATIONS, payload: {} };
};

export const setTNANotifications = (data: any) => {
    return { type: SET_TNA_NOTIFICATIONS, payload: { data } };
};

export const loadTNANotificationsSuccess = () => {
    return { type: LOAD_TNA_NOTIFICATIONS_SUCCESS };
};

export const markNotificationRead = (data: any) => {
    return { type: MARK_NOTIFICATION_READ, payload: data };
};

export const setNotificationRead = (data: any) => {
    return { type: SET_NOTIFICATION_READ, payload: data };
};

export const setNotificationToLast = (data: any) => {
    return { type: SET_NOTIFICATION_TO_LAST, notificationId: data };
};