import React, { useState } from "react";

import FormUploadConfirmationDialog from "../../FormUploadConfirmationDialog";
import Button from "@material-ui/core/Button";


export const UploadForm = (props) => {
  const exportProps = props.exportProps
  const onHandle2 = props?.onHandle2
 

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>ODER INQUIRY FORM</h1>
      <h3>Section A</h3>
      <h3>From Im to DE (DSI) / S&M Ex SCOM</h3>
      <p>Please tick as appropriate </p>
      <table class='form-table' style={{ width: "100%" }} border={1} cellPadding={10}>
        <tbody>
          <tr>
            <td class='form-td'  colSpan="4">
              
              <h3 style={{ textAlign: "center" }}>DETAILS OF ENQUIRY</h3>
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: "25%" }}>1. Inquiry Serial No.</td>
            <td class='form-td'  style={{ width: "25%" }}>
              
              <input class='form-input'
                type="text"
                // defaultValue={Form.Inquiry}
                name="Inquiry"
                onChange={(e) => onHandle2(e)}
                required={false}
                {...exportProps('Inquiry')}
                // onBlur={onBlur}
              />
            </td>
            <td class='form-td'  style={{ width: "25%" }}>2. Date</td>
            <td class='form-td'  style={{ width: "25%" }}>
              <input class='form-input'
                type="date"
                // value={Form.Inquiry}
                name='date'
                onChange={(e) => onHandle2(e)}
                required={false}
                {...exportProps('date')}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: "30%" }}>3. Customer Name & Country</td>
            <td class='form-td'  colSpan="3" style={{ width: "70%" }}>
              <input class='form-input'
                type="text"
                // value={Form.nameCountry}
                style={{ width: "100%" }}
                name="nameCountry"
                onChange={(e) => onHandle2(e)}
                {...exportProps('nameCountry')}
                required={false}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: "30%" }}>4. Customer Reference/date</td>
            <td class='form-td'  colSpan="3" style={{ width: "70%" }}>
              <input class='form-input'
                type="text"
                // value={Form.refernce}
                style={{ width: "100%" }}
                name="reference"
                onChange={(e) => onHandle2(e)}
                {...exportProps('reference')}
                required={false}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  colSpan="4">
              
              <p>5. Product Details</p>
              <input class='form-input'
                
                name="pDetails"
                onChange={(e) => onHandle2(e)}
                {...exportProps('pDetails')}
                style={{ width: "100%" }}
                // rows="4"
                // cols="100"
                required={false}
              ></input>
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: "25%" }}>
              6. Availability of Original Sample
            </td>
            <td class='form-td'  style={{ width: "25%" }}>
              
              <input class='form-input'
                type="checkbox"
                // value={Form.avalabilityOriginal}
                name="avalabilityOriginal"
                onChange={(e) => onHandle2(e)}
                {...exportProps('avalabilityOriginal')}
              />
            </td>
            <td class='form-td'  style={{ width: "25%" }}>
              7. Availability of Packing Instruction
            </td>
            <td class='form-td'  style={{ width: "25%" }}>
              <input class='form-input'
                type="checkbox"
                name="avalabilityPactind"
                onChange={(e) => onHandle2(e)}
                {...exportProps('avalabilityPactind')}              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  rowSpan="2">Quantity standard</td>
            <td class='form-td' >Bayer provided</td>
            <td class='form-td'  colSpan="2">
              <input class='form-input'
                type="checkbox"
                // value={Form.bayerProvided1}
                name="bayerProvided1"
                onChange={(e) => onHandle2(e)}
                {...exportProps('bayerProvided1')}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >DSI standard</td>
            <td class='form-td'  colSpan="2">
              <input class='form-input'
                type="checkbox"
                // value={Form.dsiStandart}
                name="dsiStandart"
                onChange={(e) => onHandle2(e)}
                {...exportProps('dsiStandart')}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  rowSpan="2">Product Specification</td>
            <td class='form-td' >Bayer provided</td>
            <td class='form-td'  colSpan="2">
              <input class='form-input'
                type="checkbox"
                // value={Form.bayerProvided2}
                name="bayerProvided2"
                onChange={(e) => onHandle2(e)}
                {...exportProps('bayerProvided2')}
              />
            </td>
          </tr>

          <tr>
            <td class='form-td' >DSI standard</td>
            <td class='form-td'  colSpan="2">
              <input class='form-input'
                type="checkbox"
                // value={Form.dsiStandart2}
                name="dsiStandart2"
                onChange={(e) => onHandle2(e)}
                {...exportProps('date')}
              />
            </td>
          </tr>
          {/*  */}
          <tr>
            <td class='form-td'  rowSpan="5">Compliance Requirements</td>
            <td class='form-td' >10a. REACH Compliance</td>
            <td class='form-td'  colSpan="2">
              <input class='form-input'
                type="checkbox"
                // value={Form.reach}
                name="reach"
                onChange={(e) => onHandle2(e)}
                {...exportProps('reach')}
              />
            </td>
          </tr>

          <tr>
            <td class='form-td' >10b. Patellate Free</td>
            <td class='form-td'  colSpan="2">
              <input class='form-input'
                type="checkbox"
                // value={Form.Patellate}
                name="Patellate"
                onChange={(e) => onHandle2(e)}
                {...exportProps('Patellate')}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >10c.Path Recruit Require</td>
            <td class='form-td'  colSpan="2">
              <input class='form-input'
                type="checkbox"
                // value={Form.path}
                name="path"
                onChange={(e) => onHandle2(e)}
                {...exportProps('path')}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >10d. Bayer Complice</td>
            <td class='form-td'  colSpan="2">
              <input class='form-input'
                type="checkbox"
                // value={Form.BayerComplice}
                name="BayerComplice"
                onChange={(e) => onHandle2(e)}
                {...exportProps('BayerComplice')}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >10e. Other</td>
            <td class='form-td'  colSpan="2">
              <input class='form-input'
                type="checkbox"
               
                // value={Form.other}
                name="other"
                onChange={(e) => onHandle2(e)}
                {...exportProps('other')}
              />
            </td>
         
          </tr>
          <tr>
            <td class='form-td'  style={{ width: "30%" }}>Remark Comments</td>
            <td class='form-td'  colSpan="3" style={{ width: "70%" }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%" }}
                // value={Form.Remark}
                name="Remark"
                // onChange={(e) => onHandle(e)}
                required={false}
                onChange={(e) => onHandle2(e)}
                {...exportProps('Remark')}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: "25%" }}>Customer Need Date</td>
            <td class='form-td'  style={{ width: "25%" }}>
              
              <input class='form-input'
                type="date"
                // value={Form.NeedDate}
                name="NeedDate"
                onChange={(e) => onHandle2(e)}
                {...exportProps('NeedDate')}
                required={false}
              />
            </td>
            <td class='form-td'  style={{ width: "25%" }}>Sample Size</td>
            <td class='form-td'  style={{ width: "25%" }}>
              <input class='form-input'
                type="text"
                // value={Form.sampleSize}
                name="sampleSize"
                onChange={(e) => onHandle2(e)}
                {...exportProps('sampleSize')}
                required={false}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: "25%" }}>Target Price</td>
            <td class='form-td'  style={{ width: "25%" }}>
              
              <input class='form-input'
                type="text"
                // value={Form.targetPrise}
                name="targetPrise"
                onChange={(e) => onHandle2(e)}
                {...exportProps('targetPrise')}
                required={false}
              />
            </td>
            <td class='form-td'  style={{ width: "25%" }}>Sample Quantity</td>
            <td class='form-td'  style={{ width: "25%" }}>
              <input class='form-input'
                type="text"
                // value={Form.sampleQuntity}
                name="sampleQuntity"
                onChange={(e) => onHandle2(e)}
                {...exportProps('sampleQuntity')}
                required={false}
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: "30%" }}>1M Coordinator</td>
            <td class='form-td'  colSpan="3" style={{ width: "70%" }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%" }}
                // value={Form.MCoordinator}
                onChange={(e) => onHandle2(e)}
                {...exportProps('MCoordinator')}
                name="MCoordinator"
                
                required={false}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div>
      <h6>SECTION B</h6>
      <table>
        <tbody>
          <tr>
            <td class='form-td' >
              
              <h6>Inquiry Serial No</h6>
            </td>
            <td class='form-td' >
              
              <input class='form-input' 
               name="Inquiry2"
               onChange={(e) => onHandle2(e)}
               {...exportProps('Inquiry2')}
              
              type="text" />
            </td>
          </tr>
        </tbody>
      </table>

      <h3>From Im to DE (DSI) / S&M Ex (SCOM) to IM</h3>
      <table class='form-table' style={{ width: "100%" }} border={1} cellPadding={10}>
        <tbody>
          <tr>
            <td class='form-td'  colSpan="5">
              
              <h6 style={{ textAlign: "center" }}>
                Sample complete date ( Please send the confirmation within 02
                working days up on receipt of the enquiry )
              </h6>
            </td>
          </tr>
          <tr>
            <td class='form-td' >
              <p>17. Im section request date</p>
            </td>
            <td class='form-td' >
              <p>OK</p>
            </td>
            <td class='form-td' >
              <input class='form-input'
                type="checkbox"
                name="ok"
                onChange={(e) => onHandle2(e)}
                {...exportProps('ok')} 
              />
            </td>
            <td class='form-td' >
              <p>Not OK</p>
            </td>
            <td class='form-td' >
              <input class='form-input'
                type="checkbox"
                name="notOk"
                onChange={(e) => onHandle2(e)}
                {...exportProps('notOk')} 
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  colSpan="5">
              
              <label htmlFor="">
                18 if answer to 17 is Not OK, please indicate the posible completion
                date below
              </label>
              <br />
              <input class='form-input'
                type="date"
                name="officer"
                onChange={(e) => onHandle2(e)}
                {...exportProps('officer')} 
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  colSpan="5">
              
              <h4 style={{ textAlign: "center" }}>Sample Delivery Details</h4>
            </td>
          </tr>
          <tr>
            <td class='form-td'  colSpan="5">
              
              <label htmlFor="">
                19. Please find the enclose sample (Please specify sample
                numbers , size & qty)
              </label>
              <br />
              <input class='form-input'
                type="text"
                style={{ width: "100%" }}
                name="sampleNumbers"
                onChange={(e) => onHandle2(e)}
                {...exportProps('sampleNumbers')} 
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >20. Sample Completion Date</td>
            <td class='form-td' >
              
              <input class='form-input'
                type="date"
                name="SampleCompletionDate"
                onChange={(e) => onHandle2(e)}
                {...exportProps('SampleCompletionDate')} 
                required
              />
            </td>
            <td class='form-td' >Authorized signature</td>
            <td class='form-td' >
              
              <input class='form-input'
                type="text"
                name="signature"
                onChange={(e) => onHandle2(e)}
                {...exportProps('signature')} 
                required
              />
            </td>
          </tr>
        </tbody>
      </table>
      <h4 style={{ marginBottom: 20, marginTop: 20 }}>
        **** After completion of samples pleas send the costing details to
        costing executive withing 01 day
      </h4>

      <table class='form-table' style={{ width: "100%" }} border={1} cellPadding={10}>
        <tbody>
          <tr>
            <td class='form-td'  colSpan="5">
              
              <h4 style={{ textAlign: "center" }}>IM SECTION RECODES</h4>
            </td>
          </tr>
          <tr>
            <td class='form-td'  colSpan="5">
              
              <label htmlFor="">21 . Sample delivery dates</label>
              <br />
              <input class='form-input'
                type="text"
                style={{ width: "100%" }}
                name="SampleDeliveryDates"
                onChange={(e) => onHandle2(e)}
                {...exportProps('SampleDeliveryDates')} 
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >21a. Dispatch date</td>
            <td class='form-td' >
              
              <input class='form-input'
                type="date"
                name="DispatchDate"
                id=""
                onChange={(e) => onHandle2(e)}
                {...exportProps('DispatchDate')} 
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >21b. Airway bill NO</td>
            <td class='form-td' >
              
          
              <input class='form-input'
                // style={{ width: "100%" }}
                type="text"
                name="airwayBill"
                onChange={(e) => onHandle2(e)}
                {...exportProps('airwayBill')} 
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >22 IM Section comment</td>
            <td class='form-td' >
              
          
              <input class='form-input'
                style={{ width: "100%" }}
                type="text"
                name="IMcomment"
                onChange={(e) => onHandle2(e)}
                {...exportProps('IMcomment')} 
                required
              />
            </td>
          </tr>
        
        </tbody>
      </table>

      <table class='form-table' style={{ width: "100%" }} border={1} cellPadding={10}>
        <tbody>
          <tr>
            <td class='form-td'  colSpan="5">
              
              <h4 style={{ textAlign: "center" }}>PROGRESS OF THE INQUIRY</h4>
            </td>
          </tr>
         
          <tr>
            <td class='form-td' >23. Order Received</td>
            <td class='form-td' >
              
              <input class='form-input'
                type="text"
                name="OrderReceived"
                id=""
                onChange={(e) => onHandle2(e)}
                {...exportProps('OrderReceived')} 
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >24. Order Reference No</td>
            <td class='form-td' >
              
          
              <input class='form-input'
                // style={{ width: "100%" }}
                type="text"
                name="orderRef"
                onChange={(e) => onHandle2(e)}
                {...exportProps('orderRef')} 
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >25 Reason for No Order</td>
            <td class='form-td' >
              
          
              <input class='form-input'
                style={{ width: "100%" }}
                type="text"
                name="ReasonForNoOrder"
                onChange={(e) => onHandle2(e)}
                {...exportProps('ReasonForNoOrder')} 
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >Signature (Manager international Marketing)</td>
            <td class='form-td' >
              
          
              <input class='form-input'
                style={{ width: "100%" }}
                type="text"
                name="MarketingSignature"
                onChange={(e) => onHandle2(e)}
                {...exportProps('MarketingSignature')} 
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td' >Inquiry closed date</td>
            <td class='form-td' >
              
          
              <input class='form-input'
                style={{ width: "100%" }}
                type="date"
             name="InquiryClosedDate"
                onChange={(e) => onHandle2(e)}
                {...exportProps('InquiryClosedDate')} 
                required
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    </div>
  );
};



 const EXP = ({toEdit, prevFormData, prevFormName, formName }) => {
  const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);
  // const [Form, setForm] = useState();

  const [data, setData] = useState(toEdit ? prevFormData : {
    nameCountry: "",
    Inquiry: "",
    date: "",
    reference: "",
    pDetails: "",
    avalabilityPactind: "",
    avalabilityOriginal: "",
    bayerProvided1: "",
    dsiStandart: "",
    bayerProvided2: "",
    dsiStandart2: "",
    reach: "",
    Patellate: "",
    path: "",
    BayerComplice: "",
    other: "",
    Remark: "",
    NeedDate: "",
    sampleSize: "",
    targetPrise: "",
    sampleQuntity: "",
    MCoordinator: "",
    Inquiry2: "",
    ok: "",
    notOk: "",
    sampleNumbers: "",
    SampleCompletionDate: "",
    signature: "",
    DispatchDate: "",
    airwayBill: "",
    orderRef: "",
    OrderReceived:"",
    ReasonForNoOrder: "",
    MarketingSignature: "",
    SampleDeliveryDates: "",
    IMcomment:'',
    InquiryClosedDate:''
  })


  const onHandle2 = (e) => {
    const inputType=e.target.type

    if(inputType==="checkbox"){
      const isChecked=e.target.checked
      setData({
        ...data,
        [e.target.name]: isChecked,
      });
    }
    else{
      const value=e.target.value
      setData({
        ...data,
        [e.target.name]: value,
      });
    }
     
  };


  const validateCheckBoxes = () => {
    if (data['ok'] && data['notOk']) {
      alert(
        `You should select only 1 item in the 'Im section request date' field`
      );
      return false
    } 
    if (!data['ok'] && !data['notOk']) {
      alert(
        `You must at least select 1 item in the 'Im section request date' field`
      );
      return false
    }
    if (data['notOk'] && !data['officer']) {
      alert(
        "You must indicate the posible completion date if 'Im section request date' is set to Not OK"
      )
      return false
    }
    return true
  }


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() && validateCheckBoxes()) {
      setOpenFormUploadDialog(true);
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    event.stopPropagation();


  };


  const exportProps = (field) => {
    if (data[field]===true) {
      return { 'checked': 'true', value: data[field]  };
    }
    return { value: data[field] };

  };

  const uiProps = (field) => {
    if (data[field] === true) {
      return {defaultChecked: true, defaultValue: data[field]};
    }
    return {defaultValue: data[field]}
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <UploadForm exportProps={uiProps} onHandle2={onHandle2}/>
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </form>
      <FormUploadConfirmationDialog
        isOpen={openFormUploadDialog}
        setIsOpen={setOpenFormUploadDialog}
        Form={UploadForm({exportProps, onHandle2})}
        formData={data}
        prevFormName={prevFormName}
        formName={formName}
      />
    </div>
  );
};
export default EXP;

