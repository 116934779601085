import { CHECK_NOTIFICATIONS, LOAD_NOTIFICATIONS, LOAD_NOTIFICATIONS_SUCCESS, SET_NOTIFICATIONS } from "../types/notificationTypes";

const initialState = {
  loading: null,
  oderTransfer: {
    transfers: {
      recived: [],
      transfer: [],
    },
    all: [],
    count: "",
    isNewNotification: false,
  },
};

export const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOAD_NOTIFICATIONS: {
      return { ...state, loading: true };
    }
    case SET_NOTIFICATIONS: {
      const { data } = action.payload;
      return {
        ...state,
        allNotifications: data.allNotifications,
        oderTransfer: {
          transfers: {
            ...state.oderTransfer.transfers,
            recived: data.transfers.pendingTransfers,
            transfer: data.transfers.respondedNotifications,
          },
          all: data.transfers.notifications,
          count: data.transfers.count,
          loading: true,
        },
      };
    }

    case LOAD_NOTIFICATIONS_SUCCESS: {
      return { ...state, loading: false };
    }
    // case READ_NOTIFICATION: {
    //   return { ...state };
    // }

    default:
      return state;

    case CHECK_NOTIFICATIONS: {
      return {
        ...state,
        isNewNotification: true,
      };
    }
  }
};
