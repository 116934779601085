import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import React from "react";
import { Link } from "react-admin";
import { useDispatch } from "react-redux";

const styles = {
  root: {
    minWidth: 400,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

const Transfer = ({ notifycations, count }) => {
  const disPatch = useDispatch();
  //  const [open, setopen] = React.useState(false);

  //  const allArrays = sortNotifyBasedOnState();
  return (
    <div>
      <Grid container spacing={3} style={{ marginTop: 30, marginLeft: 20, width: '400px' }}>
        <div class="col-md-12 notify">
          <Grid item xs variant="outlined">
            <Card className={styles.root}>
              <CardContent>
                <div style={{ display: "flex" }}>
                  <Badge
                    badgeContent={count}
                    color="secondary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    style={{ justifyContent: "flexStart" }}
                  >
                    <MailIcon />
                  </Badge>

                  <Typography
                    className={styles.title}
                    color="textSecondary"
                    gutterBottom
                    style={{
                      justifyContent: "flexEnd",
                      paddingLeft: "30px",
                      fontSize: "30px",
                      fontWeight: "700",
                      color: "#00acee"
                    }}
                  >
                    Declined
                  </Typography>
                </div>
              </CardContent>

              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  style={{ fontSize: "16px", width: "100%", height: "50px", borderRadius: "0px", marginTop: "100px", backgroundColor: "#c5edfd", color: "#00acee" }}
                  component={Link}
                  to={{
                    pathname: "/notifyRecived",
                  }}
                //onClick={() => disPatch(loadStatusNotifications())}
                >
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default Transfer;
