import { makeStyles, MenuItem, Paper, Select } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { setDate } from 'date-fns'
import _ from "lodash"
import React, { useState } from 'react'
import ReactDataGrid from 'react-data-grid'
import { connect } from 'react-redux'
import { Bar, BarChart, Brush, CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts"
import { createSelector } from 'reselect'
import { format_to_date, format_to_date_time } from '../../lib/date_time'
import { ITool } from '../../lib/plan'
import { getMaximumOverlapCount } from '../../lib/plan_board/utils'
import { Allocation } from '../../models'
import { getAllocations, getSelectedTimeRange, getTools } from '../../store/selectors'

interface ToolUsage {
    start_time: Date;
    end_time: Date;
    tool: string;
    attributes: any;
}

interface ToolUtilizationProps {
    tools: ITool[]
    primaryAttribute: string;
    secondaryAttribute: string;
    toolUsages: any;
    selected_start_time: Date
    selected_end_time: Date
}

const getToolKey = (tool: ITool) => {
    return `${tool.type}:${tool.size}`
}


function formatData(usages: ToolUsage[]): any[] {
    const startTimes = usages.map(usage => [usage.start_time, 1])
    const endTimes = usages.map(usage => [usage.end_time, -1])
    const allEvents = _.sortBy([...endTimes, ...startTimes,], "0")

    let value = 0
    const events = [] as any[]
    allEvents.forEach(e => {
        const time = e[0].valueOf()
        events.push({ Uses: value, time })
        value += e[1] as number
        const timeNew = time + 1
        events.push({ Uses: value, time: timeNew.valueOf() })
    })
    return events
}

function ToolUtilization({ tools, toolUsages, selected_end_time, selected_start_time }: ToolUtilizationProps) {
    // Selector to select the mold
    // A Table Parallel
    // A Graph
    const [selectedTool, setSelectedTool] = useState(tools[0]?.id)
    const [noData, setNoData] = useState(false)
    const handleChange = (event: any) => {
        setSelectedTool(event.target.value)
    }
   

    const selectedToolObject = tools.find(tool => tool.id === selectedTool) as ITool
    const selectedToolUsages = selectedToolObject ? ( toolUsages[getToolKey(selectedToolObject)] || [] ) : []
     const checkData = ()=>  selectedToolUsages?.length > 0?setNoData(false):setNoData(true)
     const data = formatData(selectedToolUsages)

     React.useEffect(() => {
        checkData ()
    }, [selectedToolUsages])

    if (tools.length == 0) {
        return null
    }
   
    
  

   

   

    const startI = _.max([data.filter(entry => entry.time < selected_start_time).length - 1, 0])
    const endI = data.length - data.filter(entry => entry.time > selected_end_time).length - 1

    if (tools.length === 0) {
        return null
    }

    // console.log(noData)
   

    return (
        <div style={{ display: 'flex', paddingTop: '50px', paddingBottom:"30px" }}>
            <Paper style={{ margin: 15, width: '30%', padding: '30px' }}>
                <Select
                    style={{ marginRight: 10, marginBottom:'2.5%' }}
                    onChange={handleChange}
                    value={selectedTool}
                >
                    {tools.map(tool => <MenuItem
                        key={getToolKey(tool)}
                        value={tool.id}
                    >{getToolKey(tool)}</MenuItem>)}
                </Select>


                <ToolSummary tool={selectedToolObject} usages={selectedToolUsages} status={noData}></ToolSummary>

            </Paper>
            <Paper style={{ margin: 15, width: '30%', padding: '50px 30px', fontSize:'14px', color:'#00acee' }}>
     { noData?<p style={ {marginTop:'2.8%',  fontSize:'16px'} } className="alert alert-danger text-center text-danger">NO DATA FOUND</p>:(
                <LineChart
                    key={selectedTool}
                    height={300}
                    width={850}
                    data={data}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis
                        dataKey='time'
                        domain={['auto', 'auto']}
                        name='Time'
                        tickFormatter={format_to_date}
                        type='number'
                    />
                    <YAxis allowDecimals={false} />
                    <Tooltip
                        labelFormatter={format_to_date_time}
                    />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Line dot={false} type="monotone" dataKey="Uses" stroke="#ff7300" yAxisId={0} />
                    <Brush
                        dataKey='time'
                        height={40}
                        stroke="#000000"
                        startIndex={startI}
                        endIndex={endI}
                        tickFormatter={format_to_date}
                    >
                        <BarChart>
                            <Bar dataKey="Uses" fill="#8884d8" stackId="a" />
                            {/* <XAxis
                            dataKey='time'
                            domain={['auto', 'auto']}
                            name='Time'
                            tickFormatter={format_to_date_time}
                            type='number'
                        /> */}
                        </BarChart>

                    </Brush>
                </LineChart>)}
            </Paper>
        </div>
    )
}



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const ToolSummary = ({ tool, usages,status }: { tool: ITool, usages: ToolUsage[], status:boolean }) => {
    // Tool Available Count, Tool Type, Tool Size
    console.log(status)
    const classes = useStyles()
    const configDistribution = _.mapValues(_.groupBy(usages, usage => usage.attributes.configuration.split(":")[0]),
        groupUses => {
            const dateGroups = _.groupBy(groupUses, use => use.start_time.toDateString())
            const dailyUseCounts = _.mapValues(dateGroups, uses => getMaximumOverlapCount(uses.map(v => ({ start: v.start_time.valueOf(), end: v.end_time.valueOf() }))))
            const counts = _.countBy(Object.values(dailyUseCounts))
            return counts
        }
    )

    const countMax = _.max(Object.values(configDistribution).map(dist => _.max(Object.keys(dist).map(v => parseInt(v)))))
    const columns = [{ key: "config", name: "Tool Count", editable: false }, ...[...Array(countMax)].map((v, i) => ({ key: i + 1, name: i + 1 }))] as any[]
    const rows = Object.keys(configDistribution).map(k => ({ config: k, ...configDistribution[k] }))

    // const columns = [
    //     { key: 'id', name: 'ID' },
    //     { key: 'title', name: 'Title' },
    //     { key: 'count', name: 'Count' }];

    // const rows = [{ id: 0, title: 'row1', count: 20 }, { id: 1, title: 'row1', count: 40 }, { id: 2, title: 'row1', count: 60 }];

    if (rows.length === 0) {
       return <p mt-3 className="alert alert-danger text-center text-danger">NO DATA FOUND</p>
        // return null
    }

    return <div>
        <div className={classes.root} >
            <Chip label={`Type: ${tool.type}`} />
            <Chip label={`Size: ${tool.size}`} />
            <Chip label={`Available Count: ${tool.count}`} />

            {/* <div>Max: {JSON.stringify(columns)}</div>
            {JSON.stringify(rows)} */}
        </div>
        
        
        <div style={{ height: 150 }}>
     
            
            <div>Number of days</div>
            <ReactDataGrid
                columns={columns}
                rowGetter={i => rows[i]}
                rowsCount={rows.length}
                minHeight={150} />
        </div>
    </div>
}

const getToolUsages = createSelector([getAllocations], allocations => {
    const allUsages = allocations.map((allocation) => ({
        tool: (new Allocation(allocation)).getTool(),
        start_time: allocation.start_time,
        end_time: allocation.end_time,
        attributes: { configuration: `${allocation.configuration}` }
    }) as ToolUsage)

    const toolUsages = _.groupBy(allUsages, "tool")
    return toolUsages
})


const mapStateToProps = (state: any) => {
    const selectedTimeRange = getSelectedTimeRange(state)
    const tools = getTools(state)
    const toolUsages = getToolUsages(state)
    return {
        toolUsages: toolUsages, tools: tools,
        selected_start_time: selectedTimeRange.start_time, selected_end_time: selectedTimeRange.end_time
    } as ToolUtilizationProps
}

export default connect(mapStateToProps)(ToolUtilization)
