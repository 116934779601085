import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import {
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useLogin, useNotify, Notification, defaultTheme } from "react-admin";
import { showToastError } from "../../lib/toas";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyrights © "}
      <Link color="inherit" href="https://www.stack.lk" target="_blank">
        Stack Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: "50px",
    fontSize: "18px",
    textTransform: "uppercase",
  },
  formInputs: {
    height: "50px",
    border: "none",
    boxShadow: "0px 0px 5px #eee",
  },
  formLabels: {
    lineHeight: "26px",
    fontSize: "16px",
  },
}));

export default function CustomSignIn() {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [requireUsername, setRequireUsername] = useState(false);
  const [requirePassword, setRequirePassword] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const onSubmit = (e) => {
    e.preventDefault();
    if (username.length === 0) {
      setRequireUsername(true);
    }
    if (password.length === 0) {
      setRequirePassword(true);
    } else {
      login({ username, password }).catch(() => {
        // notify('Invalid credentials')
        showToastError("Incorrect Username or Password");
      });
    }
  };

  const onFocus = () => {
    setRequireUsername(false);
    setRequirePassword(false);
  };
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel
              htmlFor="outlined-adornment-username"
              className={classes.formLabels}
            >
              User Name{" "}
              {requireUsername && (
                <span style={{ color: "red", fontSize: "0.75em" }}>
                  required*
                </span>
              )}
              {/* <InputLabel htmlFor="outlined-adornment-username">
              Username{" "} */}
            </InputLabel>
            <OutlinedInput
              margin="dense"
              id="outlined-adornment-password"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onFocus={onFocus}
              labelWidth={70}
              className={classes.formInputs}
            />
          </FormControl>
          {requireUsername && (
            <span style={{ color: "red", fontSize: "0.75em" }}>
              Please enter Username*
            </span>
          )}

          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel
              htmlFor="outlined-adornment-password"
              className={classes.formLabels}
            >
              Password{" "}
            </InputLabel>
            <OutlinedInput
              margin="dense"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              className={classes.formInputs}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={onFocus}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>

          {requirePassword && (
            <span style={{ color: "red", fontSize: "0.75em" }}>
              Please enter Password*
            </span>
          )}

          <br></br>

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            // className={classes.submit}
            className="btn_ btn_edit"
            onClick={onSubmit}
          >
            Sign In
          </Button>
        </form>
      </div>
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
}
