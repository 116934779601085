import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { format_to_date_for_api } from "../../lib/date_time";
import { exportPlanBoardData } from "../../../../store/actions";
// import { ExportHelper } from "./helperComponents/export/ExportHelper";
import { usePermissions } from "react-admin";
import moment from "moment";
import { ListHelper } from './ListHelper'
import { showToastError, showToastSuccess } from '../../../../lib/toas';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  select: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Popup = React.memo(({ list, state }) => {
  const classes = useStyles();


  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [listData, setListData] = React.useState([])
  const [selectListData, setSelectListData] = React.useState([])

  const handleClickOpen = () => {
    if (state.field) {
      return setOpen(true);
    }
    showToastError('select field first!')

  };

  const handleClose = () => {
    setOpen(false);
  };
  const tempArr = ['cluster',
    'year',
    'season',
    'category',
    'type'
  ]

  const normalieFields = (val) => {
    switch (val) {
      case 'Cluster Code':
        return tempArr[0]
      case 'Year':
        return tempArr[1]
      case 'Season Code':
        return tempArr[2]
      case 'Category Code':
        return tempArr[3]
      case 'Type':
        return tempArr[4]

    }
  }

  const getFilteredList = () => {
    // console.log(state.field)
    if (state.field) {
      const field = normalieFields(state.field)
      // console.log(field)
      const newList = list[field]
      // console.log(newList)
     

      if (newList && newList.length > 0) {

        const result = Object.keys(newList[0]).map(function (key, i) {
          return [newList[0][key]];
        });
        //  console.log(result)
         setSelectListData({ field, newList,result })
        setListData(result)
        if (result.length===0) {
          setActive(true)
        }else{
          setActive(false)
        }
      }

     

    }

    // const newList = list?.state.field
    // console.log(newList)
  }
  // console.log(open)

  React.useEffect(() => {
    if (open || state) {
      getFilteredList()
    }

  }, [open,state])

  
// console.log(list, state)

  const { permissions } = usePermissions();
  return (
    <div>
      <Button
        className="btn_secondry"
        style={{ width: 280, height: 50, marginBottom: 20 }}
        variant="outlined"
        onClick={handleClickOpen}
         disabled={active}
      >
        View Existing Items
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minHeight: "80vh", maxHeight: "80vh" }}
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle id="alert-dialog-title">
              <Typography variant="body1" component="body1">
                Select an item code to delete
              </Typography>
            </DialogTitle>

          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        <DialogContent>
          {/* <Card style={{ padding: 5 }}>
           
           
          </Card> */}
          <ListHelper
            data={listData}
            selectListData={selectListData}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
});
export default Popup;
