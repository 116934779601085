import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  usePermissions,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
// import SelectInput from "@material-ui/core/Select/SelectInput";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

const validateSite = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.code) {
    errors.code = "Code is required";
  }
  if (!values.address) {
    errors.address = "Address is required";
  }
  if (!values.phone_number) {
    errors.phone_number = "Phone Number is required";
  }
  if (!values.plant) {
    errors.plant = "Plant is required";
  }

  return errors;
};

export const CreateSubContract = (props) => {
  const plant = window.localStorage.getItem("plant");
  const { permissions } = usePermissions();
  const customToast = useCustomToast({
    ...props,
    successMessage: "Sub-contractor added. Complete setup in the next view",
  });
  return (
    <Create
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      {...props}
      class={"col-md-6 mt-5"}
    >
      <SimpleForm
        validate={validateSite}
        toolbar={
          <CustomToolbar
            name="subcontracts"
            text="Sub Contracts"
            need={false}
          />
        }
        redirect="show"
      >
        <TextInput
          source="name"
          label={<ReqInputField name="Name" />}
          title="This field is mandatory"
        />
        <TextInput
          source="code"
          label={<ReqInputField name="Code" />}
          title="This field is mandatory"
        />
        <TextInput
          source="address"
          label={
            <ReqInputField name="Address" title="This field is mandatory" />
          }
        />
        <TextInput
          source="phone_number"
          helperText=" use this format 077xxxxxx"
          label={<ReqInputField name="Phone Number" />}
          title="This field is mandatory"
        />
        {["ADMIN", "SITE"].includes(permissions?.access_level) && (
          <ReferenceInput
            labe="plant"
            source="plant"
            reference="plants"
            label={<ReqInputField name="Plant" />}
          >
            <AutocompleteInput
              optionText="code"
              translateChoise={false}
              title="This field is mandatory"
            />
          </ReferenceInput>
        )}
        {["PLANT"].includes(permissions?.access_level) && (
          <ReferenceInput
            source="plant"
            reference="plants"
            label={<ReqInputField name="Plant" />}
            title="This field is mandatory"
          >
            <SelectInput
              value={plant}
              optionText="code"
              translateChoise={false}
              title="This field is mandatory"
            />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  );
};
