import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import { redirect } from "../../lib/redirect";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

const validateSite = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.code) {
    errors.code = "Code is required";
  }
  return errors;
};

export const CreateSite = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      class={"col-md-6 "}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm
        validate={validateSite}
        redirect={redirect}
        toolbar={<CustomToolbar name="sites" text="Site" need={false} />}
      >
        <TextInput
          source="name"
          label={<ReqInputField name="Name" />}
          title="This field is mandatory"
        />
        <TextInput
          source="code"
          label={<ReqInputField name="Code" />}
          title="This field is mandatory"
        />
      </SimpleForm>
    </Create>
  );
};
