import { takeEvery, takeLatest } from "redux-saga/effects";
import { APPLY_PENDING_ACTION, DELETE_PLAN, EXPORT_PLAN_BOARD_DATA, LOAD_PLANBOARD, LOAD_PLANBOARD_REVISION, PLAN_AUTOMATIC, PUBLISH_PLANBOARD_REVISION, SAVE_PLAN_BOARD_CHANGES, SELECT_ENTITY, TOGGLE_EDIT_MODE } from "../../types";
import { applyPendingActionSaga, deletePlanSaga, exportPlanBoardSage, loadPlanSaga, loadRevisionSaga, planAutomaticSaga, publishPlanBoarRevisionSaga, savePlanBoardSaga, selectEntitySaga, toggleEditSage } from "./subSagas";


export function* planSaga() {
  yield takeLatest(LOAD_PLANBOARD, loadPlanSaga);
  yield takeLatest(LOAD_PLANBOARD_REVISION, loadRevisionSaga);
  yield takeEvery(SELECT_ENTITY, selectEntitySaga);
  yield takeEvery(APPLY_PENDING_ACTION, applyPendingActionSaga);

  // Save Plan Board
  yield takeEvery(SAVE_PLAN_BOARD_CHANGES, savePlanBoardSaga);

  // Export Plan Board Data
  yield takeEvery(EXPORT_PLAN_BOARD_DATA, exportPlanBoardSage);

  // Plan Automatic
  yield takeEvery(PLAN_AUTOMATIC, planAutomaticSaga);

  // Publish
  yield takeEvery(PUBLISH_PLANBOARD_REVISION, publishPlanBoarRevisionSaga);

  // Delete Plan
  yield takeEvery(DELETE_PLAN, deletePlanSaga);



  yield takeEvery(TOGGLE_EDIT_MODE, toggleEditSage);
}
