import { CREATE_HOLIDAY, DELETE_HOLIDAY, LOAD_HOLIDAYS, LOAD_HOLIDAYS_SUCCESS, SET_DATE_RANGE, UPDATE_SHIFT_PATTERN, SET_SHIFT_PATTERNS } from "../types/holidayTypes";

export const loadHolidays = (section: any) => {
    return {type: LOAD_HOLIDAYS, payload: {section}}
}

export const setHolidays = (holidays: any) => ({type: LOAD_HOLIDAYS_SUCCESS, payload: {holidays}})

export const setShiftPatterns = (shiftPatterns: {codes: string[], config: any}[]) => ({type: SET_SHIFT_PATTERNS, payload: {shiftPatterns}})

export const deleteHoliday = (holiday_id: number, lines: any [], section: any) => ({
    type: DELETE_HOLIDAY, payload: {holiday_id, lines, section}
})

export const setDateRange = (date_range: any) => ({type: SET_DATE_RANGE, payload: {date_range}})

export const updateShiftPattern = (dates: any, lines: any, holidays: any, shifts: any) => ({
    type: UPDATE_SHIFT_PATTERN,
    payload: {dates, lines, holidays, shifts}
})

export const createHoliday = (lines: any, holidays: any) => ({
    type: CREATE_HOLIDAY,
    payload: {lines, holidays}
})