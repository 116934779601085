import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";


export const PlanBoardLineList = props => (
  <List perPage={25}  {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="plan_board_group" />
      <TextField source="code" />
      <TextField source="initial_configuration" />
      <TextField source="calendar" />
      <TextField source="efficiency" />
      <TextField source="carder" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);
