import Excel from "exceljs";
import { saveAs } from "file-saver";
import { format_reverse_oder_date, format_to_date_time } from "./date_time";
import _ from "lodash";
import { PLAN_EXPORT_COLUMNS as plan_export_columns } from "../constants";
import moment from "moment";
import { DateColumnFilter } from "../components/ReactTableFilters";

//font sizes
const mainHeaderFontSize = 11;
const timeRangeFontSize = 11;
const fontSize1 = 8;
// const fontSize2 = 9;

const alphabet = (num) => {
  let letters = "";
  while (num >= 0) {
    letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
};
//column order

//map column widths
const columnWidths = {
  "Order No.": 15,
  "Part No.": 13,
};

const formatMoldData = (data) => {
  const groupedData = _.groupBy(data, "mold");
  const moldingData = Object.keys(groupedData).map((a) => {
    const item = {};
    item["Part No."] = a;
    groupedData[a].map((b) => {
      const shiftTime =
        moment(b.bucket_start_time).hour() < 12 ? "DAY" : "NIGHT";
      const date = `${moment(b.bucket_start_time)
        .startOf("day")
        .format("DD/MM/YY")}-${shiftTime}`;
      item[date] = item[date] ? item[date] + 1 : 1;
    });

    return item;
  });
  return moldingData;
};

const formatData = (data, planBoard) => {
  const hasHeadColumn = planBoard.plan_export_columns.includes("Head");

  const sortedData = hasHeadColumn
    ? data
    : _.sortBy(data, ["bucket_start_time", "Order No.", "Part No."]);

  const data2 = sortedData.reduce((acc, value) => {
    // compare the current value with the last item in the collected array
    const hasElements = acc.length > 0;
    const lastList = hasElements ? acc[acc.length - 1] : undefined;
    const lastElement = hasElements ? lastList[0] : undefined;
    const head = hasHeadColumn ? value["Head"] : "-";

    const key = `${value["Order No."] || ""}---${value["Part No."]}---${head}`;
    const lastKey = hasElements
      ? `${lastElement["Order No."] || ""}---${
          lastElement["Part No."]
        }---${head}`
      : "";
    if (acc.length && key === lastKey) {
      // append the current value to it if it is matching
      acc[acc.length - 1].push(value);
    } else {
      // append the new value at the end of the collected array
      acc.push([value]);
    }

    return acc;
  }, []);

  const data3 = data2.map((a) => {
    const record = a[0];
    var Quantity = 0;
    var Time = 0;
    record["Order No."] = record["Order No."] ? record["Order No."] : "";

    a.map((b) => {
      const shiftTime =
        moment(b.bucket_start_time).hour() < 12 ? "DAY" : "NIGHT";
      Quantity = Quantity + Number(b["Order Qty"]);
      record["Order Qty"] = Quantity;
      Time = Time + Number(b["Time"]);
      record["Time"] = Time;
      record[
        `${moment(b.bucket_start_time)
          .startOf("day")
          .format("DD/MM/YY")}-${shiftTime}`
      ] = record[
        `${moment(b.bucket_start_time)
          .startOf("day")
          .format("DD/MM/YY")}-${shiftTime}`
      ]
        ? record[
            `${moment(b.bucket_start_time)
              .startOf("day")
              .format("DD/MM/YY")}-${shiftTime}`
          ] + Number(b["Order Qty"])
        : Number(b["Order Qty"]);
    });
    return record;
  });

  return data3;
};

const dateScomPlanExport = async (data, time, planBoard) => {
  const dates = _.uniq(
    data.map((a) =>
      moment(a.bucket_start_time).startOf("day").format("DD/MM/YY")
    )
  );

  const formattedData = formatData(data, planBoard);

  const dateColumns = [];

  dates.map((date) =>
    dateColumns.push(
      ...[
        { header: `${date}-DAY`, key: `${date}-DAY`, width: 12 },
        { header: `${date}-ACT`, key: `${date}-D-ACT`, width: 12 },
        { header: `${date}-NIGHT`, key: `${date}-NIGHT`, width: 12 },
        { header: `${date}-ACT`, key: `${date}-N-ACT`, width: 12 },
      ]
    )
  );
  const dateColumnsArray = dateColumns.map((a) => a["key"]);

  const includeDateWiseColumns = () => {
    if (planBoard.plan_export_columns.includes("dateWiseQty")) {
      return dateColumns;
    } else return [];
  };

  const exportColumns = [
    ...planBoard.plan_export_columns
      .filter((a) => plan_export_columns.SCOM_DATEWISE.includes(a))
      .map((i) => ({
        totalsRowFunction: "sum",
        key: i,
        header: i,
        width: columnWidths[i] || 12,
      })),

    ...includeDateWiseColumns(),
  ].filter((c) => !["dateWiseQty", "moldTable"].includes(c["key"]));

  const columnsCount = exportColumns.length;

  var workbook = new Excel.Workbook();
  workbook.creator = "Auto Generated by Stack Schedular";
  var worksheet = workbook.addWorksheet("plan", {
    views: [{ showGridLines: true }],
  });

  //main header
  worksheet.getRow(1).height = 42.5;
  worksheet.mergeCells("A1", `${alphabet(columnsCount - 1)}1`);
  worksheet.getCell("A1").value = `SCOM PLAN - ${planBoard.code}`;
  worksheet.getCell("A1").font = {
    name: "Verdana",
    size: mainHeaderFontSize,
    bold: true,
  };
  worksheet.getCell("A1").border = {
    top: { style: "thick" },
    left: { style: "thick" },
    bottom: { style: "thick" },
    right: { style: "thick" },
  };
  worksheet.getCell("A1").alignment = {
    vertical: "middle",
    horizontal: "left",
  };
  worksheet.getCell("A1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFFFFF00" },
  };

  //time range
  const timeRange = `from : ${format_reverse_oder_date(
    time.start
  )} to : ${format_reverse_oder_date(time.end)}`;

  worksheet.mergeCells("A2", "D2");
  worksheet.getRow(2).height = 20;
  worksheet.getCell("A2").value = timeRange;
  worksheet.getCell("A2").font = {
    name: "Verdana",
    size: timeRangeFontSize,
    bold: true,
  };

  worksheet.addRow([""]);

  //scom headers

  //week numbers
  const getWeekNumbers = (time) => {
    const weekNumbers = [];
    var date = moment(time.start);
    while (date <= moment(time.end)) {
      const weekNumber = moment(date).week();
      weekNumbers.push(weekNumber);
      date = date.add(7, "days");
    }
    return weekNumbers;
  };

  worksheet.addRow(["Week number(s):", getWeekNumbers(time).toString()]);
  worksheet.lastRow.eachCell(
    { includeEmpty: true },
    function (cell, cellNumber) {
      if (cellNumber < columnsCount + 1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF9999FF" },
        };
        cell.font = {
          name: "Verdana",
          size: fontSize1 + 1,
        };
      }
    }
  );

  //shift times
  worksheet.addRow(["DAY SHIFT:"]);
  worksheet.addRow(["NIGHT SHIFT:"]);
  worksheet.addRow(["SATURDAY DAY SHIFT:"]);
  worksheet.addRow(["SATURDAY NIGHT SHIFT:"]);

  //table headers
  const headers = exportColumns.map((a) => a["key"]);
  const headersObjectArray = exportColumns;

  worksheet.addRow([" "]);

  //date headers
  // worksheet.addRow(headersObjectArray.map(a => " "))
  // worksheet.lastRow.eachCell((cell, i) => {
  // if (i > headers.length - 1) {

  //   cell.value =dates[i-headers.length + 1]
  // }
  // })

  worksheet.addRow(headersObjectArray.map((a) => a["header"]));
  const tableStartRow = worksheet.rowCount + 1;

  worksheet.lastRow.eachCell(
    { includeEmpty: true },
    function (cell, cellNumber) {
      if (cellNumber < columnsCount + 1) {
        if (!cell.value) {
          cell.value = "";
        }
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF9999FF" },
        };
        cell.font = {
          name: "Verdana",
          size: fontSize1,
        };
      }
    }
  );

  worksheet.columns = headersObjectArray;
  worksheet.addRow(["PENDING"]);
  // var shift_count = 0;
  formattedData.map((record, index) => {
    const item = record["ITEM CODE"];
    const bucketDateLine = record.bucketDateLine;
    const bucket = record.bucket;

    if (record["Part No."]) {
      worksheet.addRow(record);
    } else {
      worksheet.addRow({
        "Part No.": `CLEANING`,
        Time: record["Time"],
      });
    }
  });

  const tableEndRow = worksheet.rowCount;

  worksheet.addRow(["TOTAL"]);

  ///Quantity column sum
  const batchesIndex = headers.indexOf("Order Qty");
  worksheet.lastRow.getCell(batchesIndex + 1).value = {
    formula: `sum(${alphabet(batchesIndex)}${tableStartRow}:${alphabet(
      batchesIndex
    )}${tableEndRow})`,
    result: _.sumBy(formattedData, "Order Qty"),
  };

  ///date quantity column sums
  dateColumnsArray.map((date) => {
    const dateIndex = headers.indexOf(date);
    worksheet.lastRow.getCell(dateIndex + 1).value = {
      formula: `sum(${alphabet(dateIndex)}${tableStartRow}:${alphabet(
        dateIndex
      )}${tableEndRow})`,
      result: _.sumBy(formattedData, date),
    };
    return date;
  });

  worksheet.addRow(["TOTAL TIME"]);

  // const totalTimeIndex = headers.indexOf("TOTAL TIME");
  // worksheet.lastRow.getCell(totalTimeIndex + 1).value = {
  //   formula: `sum(${alphabet[totalTimeIndex]}5:${alphabet[totalTimeIndex]}${rowCount})`,
  //   result: _.sumBy(formattedData, "TOTAL TIME"),
  // };

  //add thin borders to all table cells
  worksheet.eachRow(function (row, rowNumber) {
    if (rowNumber > 9) {
      var i;
      for (i = 1; i < columnsCount - 1; i++) {
        row.getCell(i + 1).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
        };
      }

      //first cell in a row
      row.getCell(1).border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
      };

      //last cell in a row
      row.getCell(columnsCount).border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    }
  });
  worksheet.addRow([""]);

  //molding data table
  if (planBoard.plan_export_columns.includes("moldTable")) {
    const moldingTableData = formatMoldData(data);

    //molding table headers
    const moldingTableHeaders = headers.map((a) => {
      if (dateColumnsArray.includes(a)) return a;
      else if (a === "Part No.") {
        return "Mold";
      } else return null;
    });

    worksheet.addRow(moldingTableHeaders);
    const moldingTableStartRow = worksheet.rowCount + 1;
    worksheet.lastRow.eachCell(
      { includeEmpty: true },
      function (cell, cellNumber) {
        if (cellNumber < columnsCount + 1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF9999FF" },
          };
          cell.font = {
            name: "Verdana",
            size: fontSize1 + 1,
          };
          cell.border = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            left: { style: "thin" },
          };
        }
      }
    );

    //molding table data
    moldingTableData.map((row) => {
      worksheet.addRow(row);
      var i;
      for (i = 0; i < columnsCount; i++) {
        worksheet.lastRow.getCell(i + 1).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          left: { style: "thin" },
        };
      }

      return true;
    });

    const moldingTableEndRow = worksheet.rowCount;
    //molding table totals column
    worksheet.addRow(["TOTAL:"]);

    dateColumnsArray.map((date) => {
      const dateIndex = headers.indexOf(date);
      const cell = worksheet.lastRow.getCell(dateIndex + 1);
      cell.value = {
        formula: `sum(${alphabet(dateIndex)}${moldingTableStartRow}:${alphabet(
          dateIndex
        )}${moldingTableEndRow})`,
        result: _.sumBy(moldingTableData, date) || 0,
      };
      cell.border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      };
      return date;
    });
  }

  //TODO plan export header gets replaced by a value. This is a temporary fix for that
  worksheet.getCell("A1").value = `SCOM PLAN - ${planBoard.code}`;

  //end text
  worksheet.addRow([""]);
  worksheet.addRow([`Timestamp: ${format_to_date_time(new Date())}`]);
  worksheet.addRow([`Export type: ${planBoard.export_type}`]);

  worksheet.addRow([`Export SMV unit: ${planBoard.export_smvunit}`]);
  worksheet.addRow(["Auto generated by Stack Schedular"]);

  //export excel
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";
  const filename = `plan ${format_reverse_oder_date(
    time.start
  )}-${format_reverse_oder_date(time.end)}`;
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, filename + fileExtension);
};

export default dateScomPlanExport;
