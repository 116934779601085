import { ItemTimeBucket, SupplyChainState } from "../interfaces"
import { SUPPLY_CHAIN_CALCULATE_PLAN, SUPPLY_CHAIN_CLEAR_REVISIONS, SUPPLY_CHAIN_LOAD_DATA, SUPPLY_CHAIN_MANUAL_PLAN, SUPPLY_CHAIN_MANUAL_PLAN_FIND_INFEASIBLE, SUPPLY_CHAIN_PUBLISH_REVISION, SUPPLY_CHAIN_UPDATE_ITEM_TIME_BUCKET } from "./types"
export const supplyChainLoadData = (key: keyof SupplyChainState, data: any[]) => {
    return { type: SUPPLY_CHAIN_LOAD_DATA, payload: { key, data } }
}

export const supplyChainCalculatePlan = () => {
    return { type: SUPPLY_CHAIN_CALCULATE_PLAN }
}

export const supplyChainManualPlan = () => {
    return { type: SUPPLY_CHAIN_MANUAL_PLAN }
}
export const supplyChainManualPlanFindInfeasible = () => {
    return { type: SUPPLY_CHAIN_MANUAL_PLAN_FIND_INFEASIBLE }
}
export const supplyChainUpdateItemTimeBucket = (itemId: string, timeBucketId: string, key: keyof ItemTimeBucket, value: any) => {
    console.log(itemId, timeBucketId)
    return { type: SUPPLY_CHAIN_UPDATE_ITEM_TIME_BUCKET, payload: { itemId, timeBucketId, key, value } }
}
export const supplyChainPublishRevision = () => {
    return { type: SUPPLY_CHAIN_PUBLISH_REVISION }
}
export const supplyChainClearRevisions = () => {
    return { type: SUPPLY_CHAIN_CLEAR_REVISIONS }
}