import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import Popup from "./popup";

export const BulkExportResolver = (props) => {
  // console.log(props)
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          className="btn_ btn_primary mb-3"
          id="t1"
        >
          Export selected Orders
        </Button>
      </div>
      <div>
        {open && (
          <Popup
            isOpen={open}
            onClose={onClose}
            selectedIds={props.selectedIds}
          />
        )}
      </div>
    </>
  );
};
