import React from "react";
import { Datagrid, List, TextField } from "react-admin";



export const DemandOperation = props => (
  <List perPage={25} {...props}>
    <Datagrid >
      <TextField source="id" />
    </Datagrid>
  </List>
);
