import { AllocationSelectMode, DemandOperationSelectMode, IPlan } from "../../lib/plan";
import { CREATE_ALLOCATIONS, DELETE_ALLOCATIONS, DELETE_SECTION, LOAD_PLAN, MOVE_ALLOCATIONS, EDIT_ALLOCATION_DURATION, FREEZE_ALLOCATIONS } from "../types";



export const moveAllocations = (
    allocation_id: any,
    to_line_id: any,
    to_time: Date,
    mode: AllocationSelectMode,
    quantity: number = 0
) => {
    return {
        type: MOVE_ALLOCATIONS,
        payload: { allocation_id, to_line_id, to_time, mode, quantity, freezed_time: new Date() }
    };
};

export const deleteAllocations = (allocation_id: any,
    mode: AllocationSelectMode,
    quantity: number = 0
) => {
    return {
        type: DELETE_ALLOCATIONS,
        payload: { allocation_id, mode, quantity, freezed_time: new Date()  }
    };
};

export const editAllocationDuration = (allocation_id: any,
    duration: number
) => {
    return {
        type: EDIT_ALLOCATION_DURATION,
        payload: { allocation_id, duration }
    };
};

export const createAllocations = (
    demand_operation_id: any,
    to_line_id: any,
    to_time: Date,
    mode: DemandOperationSelectMode,
    quantity: number = 0
) => {
    return {
        type: CREATE_ALLOCATIONS,
        payload: { demand_operation_id, to_line_id, to_time, mode, quantity, freezed_time: new Date()  }
    };
};

export const loadPlan = (plan: IPlan) => {
    return {
        type: LOAD_PLAN,
        payload: { plan }
    }
}

export const deleteSection = (start_time: Date, lines: number[] | undefined) => {
    return {
        type: DELETE_SECTION,
        payload: { start_time, lines }
    }
}

// Freeze allocations
export const freezeAllocations = (allocation_ids: any[], toFreeze: boolean | undefined) => {
    return { type: FREEZE_ALLOCATIONS, payload: { ids: allocation_ids, toFreeze: toFreeze }}
  }