import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { SaveButton, Toolbar, usePermissions } from "react-admin";
import Confirmation from "./Confirmation";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
  },
});

export const CustomToolbar = ({ need = true, name, text, ...props }) => {
  const { permissions } = usePermissions();
  return (
    <Toolbar {...props} classes={useStyles()}>
      <div className="m-2">
        {need && (
          <Confirmation
            title={text ? text + " delete" : name + " delete"}
            disabled={!permissions?.permissions.includes(name + "_delete")}
            text={text ? text : name}
            // className=" mb-0"
            {...props}
          />
        )}{" "}
        <SaveButton
          {...props}
          // className="btn_ btn_edit mb-0"
          className="btn_ btn_edit"
          disabled={
            Object.keys(props.record).length!==0
              ? !permissions?.permissions.includes(name + "_edit")
              : !permissions?.permissions.includes(name + "_create")
          }
        />
      </div>
    </Toolbar>
  );
};
