import React from "react";
import CapacityLoadingTable from "./capacityLoadingTable";


export const CapacityLoading = () => {
    return (
        <div>
            <h3>Capacity Utilization Reports</h3>
            <CapacityLoadingTable/>    

        </div>
    );
}