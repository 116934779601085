import { Card, CardContent, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import React from 'react';


const useStyles = makeStyles((theme) => ({
    title: {
      fontSize: 14,
      fontWeight: 'bold'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(1)
    },
    card: {
        margin: theme.spacing(1),
        width: theme.spacing(40),
        // backgroundImage: `linear-gradient(60deg, rgb(255, 255, 255), rgb(216, 216, 226), rgb(235, 238, 230), rgb(255, 255, 255), rgb(236, 219, 219))`
    }
}));

const PercentageBarField = ({source}) => {
    const value = source*100 >= 100 ? 100 : source*100
    return <LinearProgress variant='determinate' value={value} style={{marginRight: 10, marginTop: 10}} />
}

export const RMInventoryRecordView = ({record}) => {
    const classes = useStyles()

    return (
            <div className={classes.container} >
                <Card className={classes.card} >
                    <CardContent >
                        <Typography className={classes.title} > Item Attributes </Typography>
                        <Typography> Item: {record.item_code} </Typography>
                        <Typography> Lead time: {record.lead_time} </Typography>
                        <Typography> Safety stock: {record.safety_stock} </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card} >
                    <CardContent >
                        <Typography className={classes.title} > Inventory status </Typography>
                        <PercentageBarField source={record.inventory_status} />
                    </CardContent>
                </Card>
                <Card className={classes.card} >
                    <CardContent >
                        <Typography className={classes.title} > Location </Typography>
                        <Typography> Name: {record.location_code} </Typography>
                    </CardContent>
                </Card>
            </div>
    )
}