import _ from "lodash"
import { SupplyChainState } from "./interfaces";



export const getSupplyForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    return _.chain(data.item_time_bucket)
        .filter(row => itemId == row.item_id && row.time_bucket_id == timeBucketId)
        .sumBy(v => v.supply).value() || 0
}

export const getMinBufferForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    return _.chain(data.item_time_bucket)
        .filter(row => itemId == row.item_id && row.time_bucket_id == timeBucketId)
        .sumBy(v => v.minimum_buffer_stock).value() || 0
}

export const getCapacityForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemIds: string[]) => {
    const opIds = new Set(data.routings
        .filter(op => itemIds.indexOf(op.item_id) >= 0)
        .map(op => op.operation_id))

    return _.chain(data.capacity)
        .filter(row => opIds.has(row.operation_id) && row.time_bucket_id == timeBucketId)
        .sumBy(v => v.quantity).value() || 0
}

export const getMinCapacityForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemIds: string[]) => {
    const opIds = new Set(data.routings
        .filter(op => itemIds.indexOf(op.item_id) >= 0)
        .map(op => op.operation_id))

    return _.chain(data.capacity)
        .filter(row => opIds.has(row.operation_id) && row.time_bucket_id == timeBucketId)
        .sumBy(v => v.minimum_quantity).value() || 0
}


export const getPlannedQtyForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    return _.chain(data.planned)
        .filter(row => itemId == row.item_id && row.time_bucket_id == timeBucketId)
        .sumBy(v => v.quantity).value()
}

export const getEndingStockForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    const bucketsOrder = {} as any;
    data.time_buckets.forEach((b, i) => bucketsOrder[b.id] = i)
    const openning = _.chain(data.items)
        .filter(item => itemId == item.id)
        .sumBy(item => item.openning_stock).value()
    const routesByKey = _.chain(data.routings).groupBy(r => `${r.operation_id}__${r.item_id}`).mapValues(data => data[0]).value()

    const consumer_route_qty_requitement = _.chain(data.material_structures)
        .filter(m => m.rm_item_id == itemId)
        .groupBy(m => `${m.operation_id}__${m.item_id}__${m.rm_item_id}`)
        .mapValues(m => m[0].qty_per_assembly).value()

    const external_change = _.chain(data.item_time_bucket)
        .filter(item => itemId == item.item_id && bucketsOrder[item.time_bucket_id] <= bucketsOrder[timeBucketId])
        .sumBy(row => row.supply - row.demand).value()

    const prev_seles_loss = _.chain(data.sales_loss)
        .filter(item => itemId == item.item_id && bucketsOrder[item.time_bucket_id] <= bucketsOrder[timeBucketId])
        .sumBy(row => row.quantity).value()

    const produced_qty = _.chain(data.planned)
        .filter(row => itemId == row.item_id && bucketsOrder[row.time_bucket_id] <= bucketsOrder[timeBucketId])
        .sumBy(v => v.quantity).value()

    const consumed_qty = _.chain(data.planned)
        .filter(row => bucketsOrder[row.time_bucket_id] <= bucketsOrder[timeBucketId])
        .sumBy(r => {
            const packSize = routesByKey[`${r.operation_id}__${r.item_id}`]?.pack_size || 1
            return (consumer_route_qty_requitement[`${r.operation_id}__${r.item_id}__${itemId}`] || 0) * r.quantity / packSize
        }).value()

    const ending = openning + external_change + prev_seles_loss + produced_qty - consumed_qty
    return Math.max(ending, 0)
}


export const getOpenningStockForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    const bucketsOrder = {} as any;
    data.time_buckets.forEach((b, i) => bucketsOrder[b.id] = i)
    const previousBuckets = _.chain(data.time_buckets)
        .filter(v => bucketsOrder[v.id] < bucketsOrder[timeBucketId])
        .value()
    if (previousBuckets.length == 0) {
        const openning = _.chain(data.items)
            .filter(item => itemId == item.id)
            .sumBy(item => item.openning_stock).value()
        return openning
    }
    const lastBucket = previousBuckets[previousBuckets.length - 1]
    return getEndingStockForTimeBucket(data, lastBucket.id, itemId)
}

export const getConsumptionForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    const bucketsOrder = {} as any;
    data.time_buckets.forEach((b, i) => bucketsOrder[b.id] = i)
    const routesByKey = _.chain(data.routings).groupBy(r => `${r.operation_id}__${r.item_id}`).mapValues(data => data[0]).value()
    const consumer_route_qty_requitement = _.chain(data.material_structures)
        .filter(m => m.rm_item_id == itemId)
        .groupBy(m => `${m.operation_id}__${m.item_id}__${m.rm_item_id}`)
        .mapValues(m => m[0].qty_per_assembly).value()

    const consumed_qty = _.chain(data.planned)
        .filter(row => row.time_bucket_id == timeBucketId)
        .sumBy(r => {
            const pack_size = routesByKey[`${r.operation_id}__${r.item_id}`]?.pack_size || 1
            return (consumer_route_qty_requitement[`${r.operation_id}__${r.item_id}__${itemId}`] || 0) * r.quantity / pack_size
        }).value()

    return consumed_qty
}

export const getDemandForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    return _.chain(data.item_time_bucket)
        .filter(row => itemId == row.item_id && row.time_bucket_id == timeBucketId)
        .sumBy(v => v.demand).value() || 0
}

export const getDemandAndConsumptionForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    return getConsumptionForTimeBucket(data, timeBucketId, itemId) + getDemandForTimeBucket(data, timeBucketId, itemId)
}

export const getBufferPercetageForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    const bucktI = data.time_buckets.findIndex(v => v.id == timeBucketId)
    const nextBucket = data.time_buckets[bucktI + 1]
    if (!nextBucket) {
        return 0
    }
    const demandNextBucket = getDemandAndConsumptionForTimeBucket(data, nextBucket.id, itemId)
    if (demandNextBucket == 0) {
        return 0
    }
    const endStock = getEndingStockForTimeBucket(data, timeBucketId, itemId)
    return endStock / demandNextBucket
}

export const getCostPerItemForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    const routes = data.routings.filter(r => r.item_id == itemId)
    const routesGroupByOpId = _.chain(routes).groupBy(r => r.operation_id).mapValues(rows => rows[0]).value()

    return _.chain(data.planned)
        .filter(row => itemId == row.item_id && row.time_bucket_id == timeBucketId)
        .sumBy(v => v.quantity * routesGroupByOpId[v.operation_id].production_cost_per_unit).value()

    // const planned = getPlannedQtyForTimeBucket(data, timeBucketId, itemId)
    // return planned
}

export const getSalseLossPerItemForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    return _.chain(data.sales_loss)
        .filter(row => itemId == row.item_id && row.time_bucket_id == timeBucketId)
        .sumBy(v => v.quantity).value()
}

export const getSalseLossCostPerItemForTimeBucket = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    const salesLossPrice = data.item_time_bucket.find(v => v.item_id == itemId && v.time_bucket_id == timeBucketId)?.selling_price || 0
    return _.chain(data.sales_loss)
        .filter(row => itemId == row.item_id && row.time_bucket_id == timeBucketId)
        .sumBy(v => v.quantity).value() * salesLossPrice
}

export const getProductionFixedCost = (data: SupplyChainState, timeBucketId: string, operationId: string) => {
    const capacityRecord = data.capacity.filter(r => r.time_bucket_id == timeBucketId && operationId == r.operation_id)[0]
    return capacityRecord?.fixed_cost || 0
}

export const getProductionVariableCost = (data: SupplyChainState, timeBucketId: string, operationId: string) => {
    const capacityRecord = data.capacity.filter(r => r.time_bucket_id == timeBucketId && operationId == r.operation_id)[0]
    const plannedRecords = data.planned.filter(row => row.operation_id == operationId && row.time_bucket_id == timeBucketId)
    return (_.sumBy(plannedRecords, v => v.quantity) || 0) * (capacityRecord?.variable_cost || 0)
}

export const getWareHouseCost = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    const itemTimeBucket = data.item_time_bucket.filter(row => row.item_id == itemId && row.time_bucket_id == timeBucketId)[0]

    const wareHouseCostPerItem = itemTimeBucket?.warehouse_cost || 0
    const openning = getOpenningStockForTimeBucket(data, timeBucketId, itemId)
    const ending = getEndingStockForTimeBucket(data, timeBucketId, itemId)

    return (openning + ending) * wareHouseCostPerItem / 2
}

export const getInterestCost = (data: SupplyChainState, timeBucketId: string, itemId: string) => {
    const item = data.items.find(item => item.id == itemId)
    const timeBucket = data.time_buckets.find(bucket => bucket.id == timeBucketId)
    const interestCostPerItem = (timeBucket?.interest || 0) * (item?.openning_price || 0)

    const openning = getOpenningStockForTimeBucket(data, timeBucketId, itemId)
    const ending = getEndingStockForTimeBucket(data, timeBucketId, itemId)

    return (openning + ending) * interestCostPerItem / 2
}

export const getProductionCostPerItem = (data: SupplyChainState, timeBucketId: string, operationId: string) => {
    const capacityRecord = data.capacity.filter(r => r.time_bucket_id == timeBucketId && operationId == r.operation_id)[0]
    const plannedRecords = data.planned.filter(row => row.operation_id == operationId && row.time_bucket_id == timeBucketId)
    const plannedQty = _.sumBy(plannedRecords, v => v.quantity)
    if (plannedQty > 0) {
        const variableCost = (_.sumBy(plannedRecords, v => v.quantity) || 0) * (capacityRecord?.variable_cost || 0)
        const fixedCost = capacityRecord?.fixed_cost || 0
        return (fixedCost + variableCost) / plannedQty
    }
    return 0
}