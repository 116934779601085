import React, { useState } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
// import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';
import { connect } from "react-redux";
import { getUnassignedTasks, getTNATaskLoading } from "../../store/selectors/tnaTaskSelectors";
import { forwardRef } from 'react';
import { Error, Loading, usePermissions } from "react-admin";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { loadUnassignedTasks, startTask } from "../../store/actions/tnaTaskActions";
// import ViewColumn from '@material-ui/icons/View';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory,useParams } from 'react-router-dom';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import noTasks from './layout/images/noData2.png';
import _ from 'lodash'
import Button from "@material-ui/core/Button";
import { Link } from "react-admin";



const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  PlayCircleFilledWhiteIcon: forwardRef((props, ref) => <PlayCircleFilledWhiteIcon {...props} ref={ref} />),
  TransferWithinAStationIcon: forwardRef((props, ref) => <TransferWithinAStationIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />)
  // ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '4%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  timeline: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '26px',
    flexDirection: 'column',
    marginTop: '10px'
  },
  status: {
    marginLeft: '3%',
    borderColor: '#00ACEE',
    borderStyle: 'solid',
    borderRadius: '12%',
    padding: '0.2%'
  },
  toggle: {
    margin: '30px 0px'
  }
}));

function UnassignedTaskList({ unassigned, loading, onLoadUnassignTask }) {

  const location = useLocation()
  const searchQuery=location?.search.includes("=")?location?.search.split("=")[1]:""
  const dispatch = useDispatch()
  const classes = useStyles();
  const [tasksSet, setTasksSet] = React.useState([]);
  const [flowType, setFlowType] = React.useState("IQ");
  const [flowTypeSet, setFlowTypeSet] = React.useState(new Set());
  const [startedTask, setStartedTask] = React.useState();
  const [searchedWord, setSearchedWord] = React.useState(searchQuery); 

  React.useEffect(() => {
    onLoadUnassignTask()
  }, [])


  let history = useHistory();

  const redirect = () => {
    history.push('/tna_createSubTasks')
  }

  const actions = []
  actions.push((rowData) => isRowHidden(rowData))


  const isRowHidden = (rowData) => {
    return ({

      icon: () => <Link to={{pathname:`/tna_createSubTasks`,state:{rowData:rowData,search:searchedWord}}} style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          // color="primary"
          className="btn_ btn_edit"
          style={{ margin: '1em', textDecoration: "none" }}
        >
          Take Action
        </Button>
      </Link>,
      onClick: () => {
        redirect()
      }
    })
  }


  const getFlowTypes = (tasksSet) => {
    var flowTypes = new Set()
    var tasksArray = Array.from(tasksSet.values())
    tasksArray.forEach(task => {
      if (task.order.type == "RP") {
        flowTypes.add("NEW")
      }
      else {
        flowTypes.add(task.order.type)
      }
    })
    setFlowTypeSet(flowTypes)
    return flowTypes
  }

  const tasksAccordingToFlow = (tasks) => {
    var flowTypes = getFlowTypes(tasks)
    var tasksArray = Array.from(tasks.values())
    var filteredTasks = []
    if (flowTypes.size > 1) {
      if (flowTypes.has("IQ")) {
        setFlowType("IQ")
        filteredTasks = tasksArray.filter(task => task.order.type == "IQ")
      } else {
        setFlowType("NEW")
        filteredTasks = tasksArray.filter(task => task.order.type == "NEW")
      }
    } else {
      var flowTypesArray = Array.from(flowTypes.values())
      setFlowType(flowTypesArray[0])
      filteredTasks = tasksArray.filter(task => task.order.type == flowTypesArray[0])
    }
    return filteredTasks
  }

  const loadReleventTasksSet = (location) => {
    if (location.pathname.includes('/unassigned')) {
      const filteredTaks = tasksAccordingToFlow(unassigned)
      return filteredTaks;
    }
  }

  React.useEffect(() => {
    const tasks = loadReleventTasksSet(location)
    console.log("tasks: ", tasks)
    var incomplete_tasks;
    var sorted_tasks;
    const completed_tasks = tasks.filter(t => t.actual_complete_time || t.transferred_time_for_successor_acceptance)

    if (startedTask) {
      const incompleted = tasks.filter(t => !t.actual_complete_time && !t.transferred_time_for_successor_acceptance && t.id != startedTask.id)
      const sorted = [..._.orderBy(incompleted, ["actual_start_time", "received_time"], ["asc", "asc"]), ..._.orderBy(completed_tasks, ["transferred_time_for_successor_acceptance", "actual_complete_time"], ["asc", "desc"])]
      sorted_tasks = tasks.filter(t => t.id == startedTask.id).concat(sorted)
    } else {
      incomplete_tasks = tasks.filter(t => !t.actual_complete_time && !t.transferred_time_for_successor_acceptance)
      sorted_tasks = [..._.orderBy(incomplete_tasks, ["actual_start_time", "received_time"], ["asc", "asc"]), ..._.orderBy(completed_tasks, ["transferred_time_for_successor_acceptance", "actual_complete_time"], ["asc", "desc"])]
    }

    setTasksSet(sorted_tasks)
  }, [unassigned, startedTask])
  if (loading) return <Loading />;




  const createRow = (value) => {

    const row = {
      'id': value?.id,
      'task_code': value?.task_code,
      'task_name': value?.task_name,
      'sample_design_number': value?.order?.code,
      'inquiry_serial': value?.order?.inquiry_serial,
      'actual_start_time': value?.actual_start_time,
      'due_start_datetime': value?.due_start_datetime,
      'due_complete_datetime': value?.due_complete_datetime,
      'actual_complete_time': value?.actual_complete_time,
      'expected_complete_time': value?.expected_complete_time,
      'received_time': value?.received_time,
      'duration': value?.duration,
      'flowStatus': value?.order?.flow_status,
      'remaining_time': value?.remaining_time,
      'tna_department': value?.tna_department,
      'order_id': value?.order?.id,
    }
    return row
  }

  const rows = []
  tasksSet.forEach(element => {
    rows.push(createRow(element))
  });

  // const rows = createRow(tasksSet, rows)


  const changeTasksSet = (type) => {
    var newTasksSet = []
    var tasksArray = Array.from(unassigned.values())

    newTasksSet = tasksArray.filter(task => task.order.type == type)
    setTasksSet(newTasksSet)
  }

  const handleToggle = (event, type) => {
    setFlowType(type);
    changeTasksSet(type)
  };


  return (
    <div className={classes.root}>
      {flowTypeSet.size != 0 ? <div className={classes.toggle}>
        <ToggleButtonGroup
          value={flowType}
          exclusive
          onChange={handleToggle}
          aria-label="text alignment"
        >
          {flowTypeSet.has("IQ") ? <ToggleButton value="IQ" aria-label="left aligned" disabled={flowTypeSet.size == 1}>
            Inquiry Flow
          </ToggleButton> : null}
          {flowTypeSet.has("NEW") ? <ToggleButton value="NEW" aria-label="centered" disabled={flowTypeSet.size == 1}>
            Order Flow
          </ToggleButton> : null}

        </ToggleButtonGroup>
      </div> : null}


      {flowType == "IQ" ?
        <MaterialTable
          icons={tableIcons}
          title="Unassigned Tasks"
          onSearchChange={setSearchedWord}
          columns={[
            {
              title: 'Task',
              field: 'id',
              type: 'int'
            },
            { title: 'Task Code', field: 'task_code', type: 'string' },
            { title: 'Task Name', field: 'task_name', type: 'string' },
            { title: 'Inquiry Serial Number', field: 'inquiry_serial', type: 'string' },
            { title: 'Scheduled Start Date', field: 'due_start_datetime', type: 'date' },
            { title: 'Scheduled Complete Date', field: 'due_complete_datetime', type: 'date' },
            { title: 'Expected Complete Date', field: 'expected_complete_time', type: 'date' },
          ]}
          data={rows}
          actions={actions}
          options={{
            sorting: true,
            actionsColumnIndex: -1,
            maxBodyHeight: 500,
            searchText:`${searchQuery?searchQuery:""}`

          }}
        /> : flowType == "NEW" ?
          <MaterialTable
            icons={tableIcons}
            title="Unassigned Tasks"
            onSearchChange={setSearchedWord}
            columns={[
              {
                title: 'Task',
                field: 'id',
                type: 'int'
              },
              { title: 'Task Code', field: 'task_code', type: 'string' },
              { title: 'Task Name', field: 'task_name', type: 'string' },
              { title: 'Order Number', field: 'order_code', type: 'string' },
              { title: 'Sample Design Number', field: 'sample_design_number', type: 'string' },
              { title: 'Scheduled Start Date', field: 'due_start_datetime', type: 'date' },
              { title: 'Scheduled Complete Date', field: 'due_complete_datetime', type: 'date' },
              { title: 'Expected Complete Date', field: 'expected_complete_time', type: 'date' },
            ]}
            data={rows}
            actions={actions}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              maxBodyHeight: 500,
              searchText:`${searchQuery?searchQuery:""}`
            }}
          /> :
          <div>
            <img src={noTasks} width="100%" height="auto" />
          </div>}


    </div>
  )
}


const mapStateToProps = (state) => {
  const unassigned = getUnassignedTasks(state)
  const loading = getTNATaskLoading(state)


  return {
    unassigned, loading
  }
}

const mapDispatchToProps = {
  onLoadUnassignTask: loadUnassignedTasks
};
export default connect(mapStateToProps, mapDispatchToProps)(UnassignedTaskList);