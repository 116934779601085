import { REFRESH_VIEW } from "react-admin";
import { CHECK_NOTIFICATIONS, LOAD_NOTIFICATIONS, LOAD_NOTIFICATIONS_SUCCESS, READ_NOTIFICATION, READ_NOTIFICATION_OLD, SET_NOTIFICATIONS, SET_STATUS_NOTIFICATIONS } from "../types/notificationTypes";

//load
export const loadNotifications = () => {
  return { type: LOAD_NOTIFICATIONS, payload: {} };
};

export const loadNotificationsSuccess = () => {
  return { type: LOAD_NOTIFICATIONS_SUCCESS };
};

// export const loadStatusNotifications = () => {
//   return { type: LOAD_STATUS_NOTIFICATIONS };
// };

export const lordReadNotifications = (data: any) => {
  return { type: SET_STATUS_NOTIFICATIONS, payload: { data } };
};

//set
export const setNotifications = (data: any) => {
  return { type: SET_NOTIFICATIONS, payload: { data } };
};
// export const setStatusNotifications = (data: any) => {
//   return { type: SET_STATUS_NOTIFICATIONS, payload: { data } };
// };

export const markNotificationRead = (notification_id: any) => {
  return { type: READ_NOTIFICATION_OLD, payload: { notification_id } };
};

export const readOneNotification = (notification_id: any) => {
  return { type: READ_NOTIFICATION, payload: { notification_id } };
};
// check
export const checkNotifications = () => {
  return { type: CHECK_NOTIFICATIONS };
};
export const refreshView = () => {
  return { type: REFRESH_VIEW }
}
