import React, { useState, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Box, Typography, Tabs, Tab, AppBar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Template } from "./Template";
import {Title } from 'react-admin'
import {
  getSDNArray,
  getTemplateArray,
  getSuccessGenerate,
  getErrorGenerate,
  getRepeatedOrders,
  getAllUsers,
  getRPSDNArray,
  getRPSDNArrayLoading,
  getSDNArrayLoading
} from "../../../store/selectors/tnaTaskSelectors";
import {
  GENERATE_TEMPLATE,
  LOAD_SDN,
  LOAD_TEMPLATE,
  TEMPLATE_RESET_UI_STATE,
  CONFIRM_TEMPLATE,
  DELETE_FLOW,
} from "../../../store/types/tnaTaskTypes";
import { PreviewModal } from "./PreviewModal";
import { showToastSuccess } from "../../../lib/toas";

const initialTemplateState = {
  code: "",
  templateId: "",
  startDate: "",
  type: "",
  priceListRef: "",
  shopOrderGroupCode:"",
  shopOrderItems:[]
};

function templateReducer(state, action) {
  switch (action.type) {
    case "setCode":
      return { ...state, code: action.payload };
    case "setTemplateId":
      return { ...state, templateId: action.payload };
    case "setStartDate":
      return { ...state, startDate: action.payload };
    case "setType":
      return { ...state, type: action.payload };
    case "setPriceListRef":
      return { ...state, priceListRef: action.payload };
    case "setShopOrderGroupCode":
      return { ...state, shopOrderGroupCode: action.payload };
    case "setShopOrderItems":
      return { ...state, shopOrderItems: action.payload }
    case "clear":
      return {
        code: "",
        templateId: "",
        startDate: "",
        type: "",
        priceListRef: "",
        shopOrderGroupCode: "",
        shopOrderItems:[]
      };

    default:
      throw new Error("Something went wrong!");
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "80%",
  },
  tabii: {
    fontSize: "0.875rem",
    wordWrap: "break-word",
  },
  form: {
    padding: "25px",
    paddingTop: "5px",
  },
}));

export const FlowGeneration = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [formState, dispatchFormData] = useReducer(
    templateReducer,
    initialTemplateState
  );
  const dispatch = useDispatch();

  //Modal State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  let modalError = "";

  //SDNs and Templates from the Store
  const sdnArray = useSelector(getSDNArray);
  const sdnArrayLoading = useSelector(getSDNArrayLoading)
  const RPsdnArray = useSelector(getRPSDNArray);
  const RPSDNArrayLoading = useSelector(getRPSDNArrayLoading)
  const templateIDArray = useSelector(getTemplateArray);
  const successMessage = useSelector(getSuccessGenerate);
  const errorMessage = useSelector(getErrorGenerate);
  const repeatedOrders = useSelector(getRepeatedOrders);
  const tna_users = useSelector(getAllUsers);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const clearFields = function () {
    dispatchFormData({ type: "clear" }); //Related to local state reducer. Not redux
  };

  const onCreateHandler = (isRPFlow=false) => {
    setIsOpenModal(true);
    setLoading(true);
    dispatch({
      type: GENERATE_TEMPLATE,
      payload: {
        requestCode: formState.code,
        templateId: formState.templateId,
        processStartDate: formState.startDate,
        requestType: formState.type,
        ...(isRPFlow) && {shopOrderGroupCode: formState.shopOrderGroupCode},
        ...(isRPFlow) && {items: formState.shopOrderItems}
      },
    });
    clearFields();
  };

  const onTentativeHandler = () => {
    //Tentative Call
    setLoading(true);
    dispatch({
      type: CONFIRM_TEMPLATE,
      payload: { status: "tentative", late: false },
    });
  };

  const onConfirmHandler = () => {
    //Confirm Call
    setLoading(true);
    dispatch({
      type: CONFIRM_TEMPLATE,
      payload: { status: "fixed", late: false },
    });
  };

  const onCancelHandler = () => {
    //Cancel Call
    setLoading(true);
    dispatch({ type: DELETE_FLOW, payload: { late: false } });
    setIsOpenModal(false);
  };

  //Load Templates
  useEffect(() => {
    dispatch({ type: LOAD_TEMPLATE });
  }, []);
  //Load SDNs
  useEffect(() => {
    dispatch({ type: LOAD_SDN, payload: { "isRP": false } });
    dispatch({ type: LOAD_SDN, payload: { "isRP": true } })
  }, []);

  //Reset UI State
  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: TEMPLATE_RESET_UI_STATE });
    }, 2000);
  }, [errorMessage, successMessage]);

  //Open Modal if GenerateTemplate is successful
  useEffect(() => {
    switch (successMessage) {
      case "Flow Generated Successfully":
        if (tna_users.length !== 0) {
          setLoading(false);
        }
        showToastSuccess(successMessage);
        // setIsOpenModal(true);
        break;
      case "Flow Status Updated":
        setLoading(false);
        setIsOpenModal(false);
        showToastSuccess(successMessage);
        break;
      case "Template Deleted Successfully!":
        setLoading(false);
        setIsOpenModal(false);
        showToastSuccess(successMessage);
        break;
      case "Child tasks not assigned to user":
        setLoading(false);
        setIsOpenModal(true);
        break;
      default:
        if (tna_users.length !== 0) {
          setLoading(false);
        }
        break
    }
  }, [successMessage,tna_users.length !== 0]);

  useEffect(() => {
    switch (errorMessage) {
      case "Flow Generation Failed":
        setIsOpenModal(false);
        break;
      case "Error Occured Updating Status. Try again!":
        setIsOpenModal(true);
        modalError = "Error Occured Updating Status. Try again!";
        showToastSuccess(errorMessage);
        break;
      default:
    }
  }, [errorMessage]);

  return (
    <Grid className={classes.root}>
      <Title title="Flow Generation" />
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          aria-label="full width tabs example"
          centered
          width="50%"
        >
          <Tab label="Order Flow" className={classes.tabii} {...a11yProps(0)} />
          <Tab
            label="Inquiry Flow"
            className={classes.tabii}
            {...a11yProps(1)}
          />
          <Tab
            label="Repeated Order Flow"
            className={classes.tabii}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <div className={classes.form}>
        <Template
          createHandler={onCreateHandler}
          data={formState}
          dispatchFormData={dispatchFormData}
          sdnArray={sdnArray}
          sdnArrayLoading={sdnArrayLoading}
          rpSDNArray={RPsdnArray}
          rpSDNArrayLoading={RPSDNArrayLoading}
          templateIDArray={templateIDArray}
          repeatedOrders={repeatedOrders}
          tabId={value}
        />
      </div>
      <PreviewModal
        showModal={isOpenModal}
        onSetShowModal={setIsOpenModal}
        onConfirm={onConfirmHandler}
        onCancel={onCancelHandler}
        onTentative={onTentativeHandler}
        isLoading={isLoading}
        // error={modalError}
        // status={status}
        flowType={formState.type}
        parent="flowGeneration"
      />
    </Grid>
  );
};
