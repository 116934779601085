import React from "react";
import { BooleanField, Datagrid, EditButton, List, TextField } from "react-admin";



export const LineBucket = props => (
  <List {...props} bulkActionButtons={false} perPage={25}>
    <Datagrid >
      <TextField source="id" />
      <TextField source="line" />
      <TextField source="start_time" />
      <TextField source="end_time" />
      <TextField source="efficiency" />
      <TextField source="total_smv" />
      <TextField source="unallocated_smv" />
      <TextField source="carder" />
      <TextField source="configuration" />
      <BooleanField source="allocation_completed" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);

