import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import _ from "lodash"


export const stringJoinWIthStars = (strings=[]) => {
    if(!strings || strings.length == 0 ){
        return ""
    }
    const charArrays = strings.filter(v => !!v).map((a) => a.split(""));
    return _.zip(...charArrays)
        .map( list => list.reduce((a, b) =>  (a === b ? a : "*"), list[0]))
        .join("");
};

export default function ListStringJoinField({ source, record = {} }) {
    const list = _.get(record, source)
    const text = stringJoinWIthStars(list)
    return (
        <div>
            {text  && <Tooltip title={list.join(", ")}>
                <Chip style={{ margin: 2 }} label={text} /></Tooltip>}
        </div>
    )
}


