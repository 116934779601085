import "../../layout/styles/input.css";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useMutation } from "react-admin";
import { connect } from "react-redux";
import DateInput from "../../components/input/DateInput";
import { setupTransfer } from "../../store/actions";
import { getPlants } from "../../store/selectors/transferSelectors";

const TransferDialog = ({
  selectedRows,
  plants,
  onTransfer,
  title,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const TransferButton = ({ disabled }) => {
    const [approve] = useMutation({
      type: "CUSTOM",
      resource: "demandOp",
      payload: { id: 1, body: data, action: "transfer", method: "POST" },
    });
    return (
      <Button
        disabled={disabled}
        variant="contained"
        onClick={() => {
          onTransfer(data);
          handleClose();
          setData(null);
        }}
        className="btn_ btn_secondry"
      >
        Transfer
      </Button>
    );
  };

  const handleClose = () => {
    setOpen(false);
    setData(null);
  };

  const selectedDoIds = selectedRows.map((dO) => dO.id);

  if (data == null || selectedDoIds > data.demand_operations) {
    setData({
      transfer_date: new Date(), //moment().format("DD/MM/YYYY"),
      received_date: new Date(), //moment().format("DD/MM/YYYY"),
      // transfer_date: moment().format("YYYY-MM-DD"),
      // received_date: moment().format("YYYY-MM-DD"),
      demand_operations: selectedDoIds,
      sub: null,
    });
  }

  // const selected = selectedRows[0];

  const onChange = (key, input) => {
    const value = input;
    setData((data) => ({ ...data, [key]: value }));
  };

  return (
    <>
      <Button
        title={title}
        disabled={disabled}
        variant="contained"
        onClick={handleClickOpen}
        // color="secondary"
        className="btn_ btn_secondry"
      >
        Setup
      </Button>
      {data ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">{`Transfer Setup`}</DialogTitle>
          <DialogContent>
            <form>
              <Grid item md={12}>
                <Typography>Fill details for the transfer</Typography>
              </Grid>
              <Box display="flex">
                <Grid item md={12}>
                  <Card
                    variant="outlined"
                    style={{ minHeight: "250px", padding: "10px" }}
                  >
                    <Box display="flex">
                      <Grid item md={3}>
                        <Typography style={{ padding: "5px" }}>
                          Transfer date
                        </Typography>
                        <Typography style={{ padding: "5px" }}>
                          Transfer Need Date
                        </Typography>
                        <Typography style={{ padding: "5px" }}>
                          plant
                        </Typography>
                      </Grid>
                      <Grid item md={9}>
                        <Typography>
                          :{" "}
                          <DateInput
                            disabled={true}
                            type="date"
                            value={data.transfer_date}
                            onChange={(e) => onChange("transfer_date", e)}
                          />
                        </Typography>
                        <Typography>
                          :{" "}
                          <DateInput
                            type="date"
                            value={data.received_date}
                            onChange={(e) => onChange("received_date", e)}
                          />{" "}
                        </Typography>
                        <Typography>
                          :{" "}
                          <Select
                            style={{ paddingTop: "10px" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.plant}
                            onChange={(e) => onChange("plant", e.target.value)}
                          >
                            {plants.map((plant) => (
                              <MenuItem value={plant.id}>{plant.code}</MenuItem>
                            ))}
                          </Select>
                        </Typography>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              // color="primary"
              className="btn_ btn_primary"
              variant="contained"
              style={{ padding: "10px" }}
            >
              close
            </Button>
            <TransferButton disabled={!(data.received_date && data.plant)} />
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => {
  const plants = getPlants(state);

  return {
    plants,
  };
};
const mapDispatchToProps = {
  onTransfer: setupTransfer,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferDialog);
