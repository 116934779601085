import React from "react";
import { Title } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { SideLogo } from "./components/sideLogo";
import { BottomImg } from "./components/bottomImg";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  item1: {
    justifyContent: "flex-start",
  },
  item2: {
    justifyContent: "flex-end",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mb: {
    marginBottom: "10px",
  },
  parent: {
    overflow: "hidden",
  },
}));

export const About = () => {
  const classes = useStyles();
  return (
    <div className={classes.parent}>
      <Title title="About" />

      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={3}>
          <h3 style={{ marginTop: 5, fontWeight: 700, fontSize: 44 }}>About</h3>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={3}>
          <div style={{ marginLeft: 200, marginTop: 0, marginBottom: 0 }}>
            <SideLogo />
          </div>
        </Grid>
      </Grid>

      <div className="row" style={{maxHeight:'40vh', width: '80%'}}>
        <div className="col-md-10 mt-3">
          <Typography variant="body1" className={classes.mb}>
            Stack Technologies is a software company that specializes in
            developing customized software solutions and is based in Colombo,
            Cambridge and Suffolk.
          </Typography>
          <Typography variant="body1" className={classes.mb}>
            {" "}
            Comprised with a team spread across Sri Lanka, the UK and Singapore,
            we employ a set of individuals proficient in their own domains from
            Engineers, Consultants to Analysts and Interface designers with
            experience in multiple industries ranging from Manufacturing to
            Supply chain, Telecommunication, E commerce, Fintech, Stock markets
            , Transportation, Logistics and much more.
          </Typography>
          <Typography variant="body1" className={classes.mb}>
            {" "}
            We provides a variety of software services from E-commerce
            platforms, Transport and Logistics platforms, System Integrations ,
            AI backed Production Scheduling Systems to Virtual Reality Rooms for
            businesses and Government owned entities. ​ With clients and
            partners in over 10 industries around the globe, We utilize our
            accumulated experience of over 20 years in software development and
            Integration to provide the best solutions for our clients.
          </Typography>
          <Typography variant="body1" gutterBottom style={{}}>
            {" "}
            We always work with our clients to help them reach the moon, the
            stars and to guide their way towards the unknown....
          </Typography>
        </div>
        {/* <div className="col-md-2">
          <div style={{ marginLeft: 0, marginTop: 20, marginBottom: 10 }}>
            <SideLogo />
          </div>
      </div> */}
      </div>

      <Grid container spacing={1} justify="flex-start">
        <Grid item xs={5}>
          <div style={{ marginLeft: 0, marginTop: 50, marginRight: 0 }}>
            <BottomImg />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
