import React, { useState } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
// import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Item from '@material-ui/core/Item';
import { useLocation } from 'react-router';
// // import { getProcessingTasks } from '../../store/selectors/tnaTaskSelectors';
import { connect } from "react-redux";
import { getTodoTasks, getProcessingTasks, getCompletedTasks, getTNATaskLoading } from "../../store/selectors/tnaTaskSelectors";
import { forwardRef } from 'react';
import { Error, Loading, useQuery, useMutation, useRefresh, usePermissions,Title } from "react-admin";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core"
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import { showToastSuccess, showToastError } from "../../lib/toas"
import { loadALLTasks, startTask } from "../../store/actions/tnaTaskActions";
// import ViewColumn from '@material-ui/icons/View';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import ViewFormsIcon from '@material-ui/icons/ViewAgenda';
import BlockSharp from '@material-ui/icons/BlockSharp'
import FormDownload from './formDownload';
import ViewSubmittedFormsAndApprove from './ViewSubmittedFormsAndApprove';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import noTasks from './layout/images/noData2.png';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    PlayCircleFilledWhiteIcon: forwardRef((props, ref) => <PlayCircleFilledWhiteIcon {...props} ref={ref} />),
    ViewFormsIcon: forwardRef((props, ref) => <ViewFormsIcon {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />)
    // ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '4%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));



function TaskApproval() {

    const location = useLocation()
    const classes = useStyles();
    const [mutate, { mutateLoading, onSuccess, onError }] = useMutation();
    const refresh = useRefresh();
    const [notApprovedTasksSet, setNotApprovedTasksSet] = React.useState([])
    const [taskAssigneeList, setTaskAssigneeList] = React.useState([])
    const [approvedTasksSet, setApprovedTasksSet] = React.useState([])
    const [notApprovedTasksSetAccordingFlow, setNotApprovedTasksSetAccordingFlow] = React.useState([])
    const [approvedTasksSetAccordingFlow, setApprovedTasksSetAccordingFlow] = React.useState([])
    const [flowType, setFlowType] = React.useState("IQ");
    const [flowTypeSet, setFlowTypeSet] = React.useState(new Set());

    const approvalPendingRows = []
    const approvedRows = []

    const createRow = (value) => {
        const row = {
            'id': value.id,
            'sample_design_number': value.order_code,
            'task_name': value.task_name,
            'comment': value.task_data.comment,
            'performed_user': value.performed_user,
            'original_record': value
        }
        return row
    }

    const getFlowTypes = (tasksSet) => {
        var flowTypes = new Set()
        var tasksArray = Array.from(tasksSet.values())
        tasksArray.forEach(task => {
            if (task?.order.type == "RP") {
                flowTypes.add("NEW")
            }
            else {
                flowTypes.add(task?.order.type)
            }
        })
        
        return flowTypes
    }

    const tasksAccordingToFlow = (tasks,flowType) => {
        var tasksArray = Array.from(tasks.values())
        var filteredTasks = []
        if (flowType=="IQ") {
                filteredTasks = tasksArray.filter(task => task.original_record?.order.type == "IQ")
        } else if(flowType=="NEW") {
                filteredTasks = tasksArray.filter(task => task.original_record?.order.type == "NEW")
        }
        return filteredTasks
    }

    const loadReleventTasksSet = (tasks,flowTypesSet) => {
        var flowType;
        if(flowTypesSet.size>1){
            if(flowTypesSet.has("IQ")){
                flowType="IQ"
                setFlowType("IQ")
            }else if(flowTypesSet.has("NEW")){
                flowType="NEW"
                setFlowType("IQ")
            }
        }else {
            var flowTypesArray = Array.from(flowTypesSet.values())
            setFlowType(flowTypesArray[0])
            flowType=flowTypesArray[0]
        }

        const filteredTaks = tasksAccordingToFlow(tasks,flowType)
        return filteredTaks;
    }


    const { data, loading, error } = useQuery({
        type: 'TNA_CUSTOM_LIST',
        resource: 'itemTasks',
        payload: {
            action: "getTasksForApproval",
            method: "GET"
        }
    }, {
        onFailure: (e) => {
            showToastError("Tasks Retrieval Failed:" + e.body);
        },
        onSuccess: (response) => {
            response.data.approval_pending.forEach(element => {
                approvalPendingRows.push(createRow(element))
            });
            setNotApprovedTasksSet(approvalPendingRows)
            response.data.approved.forEach(element => {
                approvedRows.push(createRow(element))
            })
            setApprovedTasksSet(approvedRows)
            const flowTypes = getFlowTypes(response.data.approval_pending.concat(...response.data.approved))
            setFlowTypeSet(flowTypes)
            const approvalPendingTasks = loadReleventTasksSet(approvalPendingRows,flowTypes)
            const approvedTasks = loadReleventTasksSet(approvedRows,flowTypes)
            setNotApprovedTasksSetAccordingFlow(approvalPendingTasks)
            setApprovedTasksSetAccordingFlow(approvedTasks)
            
        }
    });

    const approve = (taskid, rejectedReasons, addedComments) => {
        mutate({
            type: 'TNA_CUSTOM_ACTION',
            resource: 'itemTasks',
            payload: {
                action: "taskApprove",
                method: "POST",
                body: {
                    taskId: taskid,
                    rejectedReasons,
                    addedComments
                },
            }
        }, {
            onFailure: (e) => {
                showToastError("Approval Failed:" + e.body);
            },
            onSuccess: (e) => {
                showToastSuccess("Task Approved");
                refresh()

            }
        });
    };

    const reject = (taskid, rejectedReasons, addedComments) => {
        mutate({
            type: 'TNA_CUSTOM_ACTION',
            resource: 'itemTasks',
            payload: {
                action: "taskReject",
                method: "POST",
                body: {
                    taskId: taskid,
                    rejectedReasons,
                    addedComments
                },
            }
        }, {
            onFailure: (e) => {
                showToastError("Task Rejection Failed:" + e.body);
            },
            onSuccess: (e) => {
                showToastSuccess("Task Rejected and Reopened Successfully");
                refresh()

            }
        });
    }


    const [selectedTask, setSelectedTask] = useState()

    const setTaskAssignees=(task)=>{
        if(task.task_type=="MainTask"){
            return (
            <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color='textPrimary'>Main Task Assigned User :</Typography>{task.assignee}
                </Grid>
                <Grid item xs={4}>
                <Typography variant="h6" color='textPrimary'>Sub Tasks Assigned Users:</Typography>
                </Grid>
                {task.taskAssignees.map((assignee)=><Grid item xs={12}>
                {assignee.username}
                </Grid>)}
  
            </Grid>
            </>
            )
        }else{
            return (
                <>
                <div>
                <Typography variant="h6" color='textPrimary'> Task Assigned User:</Typography>{task.assignee}
                </div>
                </>
                )
        }
    }
    const handleTaskClick = (e, task) => {
        setSelectedTask(task)
        setTaskAssigneeList(setTaskAssignees(task.original_record))
    }

    if (loading) return <Loading />;
    if (mutateLoading) return <Loading />;

    const changeTasksSet = (type) => {
       
        const newNotApprovedTasks = tasksAccordingToFlow(notApprovedTasksSet,type)
        const newApprovedTasks = tasksAccordingToFlow(approvedTasksSet,type)
        setNotApprovedTasksSetAccordingFlow(newNotApprovedTasks)
        setApprovedTasksSetAccordingFlow(newApprovedTasks)
    }

    const handleToggle = (event, flowType) => {
        setFlowType(flowType);
        changeTasksSet(flowType);
    };

    return (
        <>
            <div className={classes.root}>
            <Title title="Task Approval" />
                <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={!!selectedTask}
                    onClose={() => setSelectedTask(null)}
                >
                    <DialogTitle>Review Uploaded forms</DialogTitle>
                    <DialogContent>
                        {taskAssigneeList}
                        {selectedTask &&
                            <ViewSubmittedFormsAndApprove task={selectedTask.original_record} 
                                onReject={(rejectedReasons, addedComments) => {
                                    reject(selectedTask.id, rejectedReasons, addedComments)
                                    setSelectedTask(null)
                                }}
                                onApprove={(rejectedReasons, addedComments) => {
                                    approve(selectedTask.id, rejectedReasons, addedComments)
                                    setSelectedTask(null)
                                }}

                            />
                        }
                    </DialogContent>
                </Dialog>

                {flowTypeSet.size != 0 ?
                    <div className={classes.toggle}>
                        <ToggleButtonGroup
                            value={flowType}
                            exclusive
                            onChange={handleToggle}
                            aria-label="text alignment"
                        >
                            {flowTypeSet.has("IQ") ? <ToggleButton value="IQ" aria-label="left aligned" disabled={flowTypeSet.size == 1}>
                                Inquiry Flow
                            </ToggleButton> : null}
                            {flowTypeSet.has("NEW") ? <ToggleButton value="NEW" aria-label="centered" disabled={flowTypeSet.size == 1}>
                                Order Flow
                            </ToggleButton> : null}

                        </ToggleButtonGroup>
                    </div> : null}

                {flowType == "IQ" ?
                    <div className={classes.root}>
                        <div className={classes.root}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Tasks for Approval"
                                columns={[
                                    {
                                        title: 'Task',
                                        field: 'id',
                                        type: 'int'
                                    },
                                    { title: 'Inquiry Serial Number', field: 'sample_design_number', type: 'string' },
                                    { title: 'Task Name', field: 'task_name', type: 'string' },
                                    { title: 'Comment', field: 'comment', type: 'string' },
                                    { title: 'Performed User', field: 'performed_user', type: 'string' },
                                ]}
                                data={notApprovedTasksSetAccordingFlow}
                                actions={[
                                    {
                                        icon: PlayCircleFilledWhiteIcon,
                                        tooltip: 'Review',
                                        onClick: handleTaskClick
                                    },
                                    // {
                                    //     icon: PlayCircleFilledWhiteIcon,
                                    //     tooltip: 'Approve the Task',
                                    //     onClick: (event, rowData) => {
                                    //         approve(rowData.id)
                                    //     }
                                    // },
                                    // {
                                    //     icon: BlockSharp,
                                    //     tooltip: 'Reject/Decline the Task',
                                    //     onClick: (event, rowData) => {
                                    //         reject(rowData.id)
                                    //     }
                                    // }
                                ]}
                                options={{
                                    sorting: true,
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                        
                        <div className={classes.root}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Approved Tasks"
                                columns={[
                                    {
                                        title: 'Task',
                                        field: 'id',
                                        type: 'int'
                                    },
                                    { title: 'Inquiry Serial Number', field: 'sample_design_number', type: 'string' },
                                    { title: 'Task Name', field: 'task_name', type: 'string' },
                                    { title: 'Comment', field: 'comment', type: 'string' },
                                    { title: 'Performed User', field: 'performed_user', type: 'string' },
                                ]}
                                data={approvedTasksSetAccordingFlow}
                                options={{
                                    sorting: true,
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>

                    </div>
                    : flowType == "NEW" ?
                        <div className={classes.root}>
                            <div className={classes.root}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title="Tasks for Approval"
                                    columns={[
                                        {
                                            title: 'Task',
                                            field: 'id',
                                            type: 'int'
                                        },
                                        { title: 'Sample Design Number', field: 'sample_design_number', type: 'string' },
                                        { title: 'Task Name', field: 'task_name', type: 'string' },
                                        { title: 'Comment', field: 'comment', type: 'string' },
                                        { title: 'Performed User', field: 'performed_user', type: 'string' },
                                    ]}
                                    data={notApprovedTasksSetAccordingFlow}
                                    actions={[
                                        {
                                            icon: PlayCircleFilledWhiteIcon,
                                            tooltip: 'Review',
                                            onClick: handleTaskClick
                                        },
                                        // {
                                        //     icon: PlayCircleFilledWhiteIcon,
                                        //     tooltip: 'Approve the Task',
                                        //     onClick: (event, rowData) => {
                                        //         approve(rowData.id)
                                        //     }
                                        // },
                                        // {
                                        //     icon: BlockSharp,
                                        //     tooltip: 'Reject/Decline the Task',
                                        //     onClick: (event, rowData) => {
                                        //         reject(rowData.id)
                                        //     }
                                        // }
                                    ]}
                                    options={{
                                        sorting: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </div>
                            <div className={classes.root}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title="Approved Tasks"
                                    columns={[
                                        {
                                            title: 'Task',
                                            field: 'id',
                                            type: 'int'
                                        },
                                        { title: 'Sample Design Number', field: 'sample_design_number', type: 'string' },
                                        { title: 'Task Name', field: 'task_name', type: 'string' },
                                        { title: 'Comment', field: 'comment', type: 'string' },
                                        { title: 'Performed User', field: 'performed_user', type: 'string' },
                                    ]}
                                    data={approvedTasksSetAccordingFlow}
                                    options={{
                                        sorting: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </div>
                        </div>
                        :
                        <div>
                            <img src={noTasks} width="100%" height="auto" />
                        </div>}
            </div>
        </>
    )
}

export default TaskApproval;