import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from '@material-ui/core/Grid';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useState } from "react";
import readXlsxFile from 'read-excel-file'
import needUpload from '../layout/images/Upload1.1.png';
import {
  BooleanField,
  CardActions,
  Datagrid,
  EditButton,
  Filter,
  List,
  Mutation,
  // ShowButton,
  TextField,
  TextInput,
  WithPermissions,
  useRefresh,
  useMutation,
  usePermissions,
  Loading,
  Title
} from "react-admin";

import CSVReader from "react-csv-reader";
import { showToastSuccess, showToastError } from "../../../lib/toas"
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CSVDataViewer from "./CSVDataViewer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    marginTop: theme.spacing(4),
  },
  chooseFile: {
    display: 'flex',
    justifyContent: 'space-between'

  }
}));

const PriceListUpload = ({ }) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(false);
  const [mutate, { mutateLoading, onSuccess, onError }] = useMutation();
  const [itemImages, setItemImages] = useState([]);
  // const [indexofFirstCol, setIndexofFirstCol] = useState(0);
  var indexofFirstCol = 0

  function formatCode(code) {
    var formattedCode = code.replaceAll("/", "|")
    var fileName = formattedCode.concat(".png")

    return fileName
  }


  const getItemImages = (sdns) => {
    var imageNames = sdns.map(x => formatCode(x))
    mutate({
      type: 'TNA_CUSTOM_ACTION',
      resource: 'itemTasks',
      payload: {
        action: "getOriginalImages",
        method: "POST",
        body: {
          imageCodeArray: imageNames
        },
      }
    }, {
      onFailure: (e) => {
        showToastError("Approval Failed:" + e.body);
      },
      onSuccess: (e) => {
        setItemImages(e.data)

      }
    });
  }




  function getSDNs(data) {
    console.log("lkk", data)
    const columnData = data[4]
    indexofFirstCol = columnData.indexOf("Computer Code")
    const indexofSDN = columnData.indexOf("Sample Design Number")
    console.log("cc", indexofFirstCol)
    const dataRows = data.slice(10).map(refineRows)
    const refinedRows = dataRows.filter(x => x !== undefined)
    refinedRows.pop()
    const filteredRows = refinedRows.filter(x => x[indexofSDN] != null)
    const sdns = filteredRows.map(x => x[indexofSDN])
    return sdns

  }

  function refineRows(value) {
    if (value[indexofFirstCol] != null) {
      return value
    }
  }

  const handleUpload = (event) => {
    readXlsxFile(event.target.files[0]).then((rows) => {
      setData(rows)
      const sdns = getSDNs(rows)
      getItemImages(sdns)

      console.log(rows)
    })
  }


  const refresh = useRefresh();
  const onUploadSuccess = (res) => {
    showToastSuccess("Upload success!");
    setData(false)
    refresh();
  };

  const onUploadError = (res) => {
    // showToastError("Upload Failed");
    setData(false)
    refresh();
  };

  return (
    <div >
      <Grid item xs={5}>
      <Title title="Price List Uploader" />
        <label>Upload Price List</label>

      </Grid>

      <Button
        title="permissions"
        disabled={!permissions?.permissions.includes("tna_csv_extract")}
        variant="contained"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        {" "}
          Upload
        </Button>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogActions className={classes.chooseFile}>
            <div>
              <input type="file" id="input" onChange={handleUpload} />
              {data && <div>Records {data.length - 1} </div>}
            </div>
            <div>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
              {data && (
                <Mutation
                  type="TNA_CUSTOM_ACTION"
                  resource="itemTasks"
                  payload={{
                    action: "priceListDataExtract",
                    method: "POST",
                    body: data,
                  }}
                  options={{
                    onSuccess: (res) => onUploadSuccess(res),
                    onFailure: (res) => onUploadError(res),
                  }}
                >
                  {(action) => (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setIsOpen(false);
                        action();
                      }}
                    >
                      {" "}
                      Save
                    </Button>
                  )}
                </Mutation>
              )}
            </div>
          </DialogActions>
          {data ? <CSVDataViewer data={data} itemImages={itemImages} /> : <div>
            <img src={needUpload} width="100%" height="auto" />
          </div>}
        </DialogContent>
      </Dialog>
    </div>
  );
};


export default PriceListUpload;