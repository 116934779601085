import React from "react";
import { Loading, Query } from "react-admin";

export const OperationCard = ({ prop }) => (
  <Query type="getOne" resource="operations" payload={{ id: prop }}>
    {({ data, loading, error }) => {
      if (loading) {
        return <Loading />;
      }
      if (error) {
        return <p>ERROR</p>;
      }
      return (
        <>
          <p style={{ textAlign: "center", fontSize: 14 }}>
            {data.description}
            <span style={{ fontSize: 12 }}> ({data.code})</span>
          </p>
        </>
      );
    }}
  </Query>
);
