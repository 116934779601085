import _ from "lodash";
import { get_duration } from "./allocators/bucket_allocators";
import { AllocationIdType, IAllocation, IAllocationPart, IAllocationSelection, IBucket, IDemandOperation, IDemandOperationPart, IDemandOperationSelection, IPlan } from "./interfaces";

export const get_bucket_sequence = (plan: IPlan, line_id: number, start_time: Date) => {
    const filter = (bucket: IBucket) => bucket.end_time > start_time && line_id === bucket.line_id
    return _.sortBy(Object.values(plan.buckets).filter(filter), "start_time")
}

export const get_last_allocation = (plan: IPlan, line_id: number, time: Date) => {
    const filter = (a: IAllocation) => a.start_time < time && line_id === a.plan_board_line_id
    return _.sortBy(get_active_allocations(plan).filter(filter), "start_time")
}

export const group_demand_operation_selection = (parts: IDemandOperationSelection) => {

    return parts.reduce((groups: IDemandOperationPart[], part) => {
        const [first, ...rest] = groups
        if (first?.demand_operation_id === part.demand_operation_id) {
            return [{ ...first, quantity: first.quantity + part.quantity }, ...rest]
        }
        return [part, ...groups]
    }, []).reverse()
}

export const convert_allocation_parts_to_demand_operation_parts = (plan: IPlan, allocation_parts: IAllocationSelection) => {
    const converted = allocation_parts.map(a => {
        return {
            demand_operation_id: plan.allocations[a.allocation_id]?.demand_operation_id,
            quantity: a.quantity,
            is_freezed: a.is_freezed
        } as IDemandOperationPart
    }).filter(a => a.quantity > 0 && a.demand_operation_id)

    return group_demand_operation_selection(converted)
}

export const convert_allocations_to_demand_operation_parts = (plan: IPlan, allocations: IAllocation[]) => {
    const converted = allocations.map(a => {
        return {
            demand_operation_id: plan.allocations[a.id]?.demand_operation_id,
            quantity: a.quantity
        } as IDemandOperationPart
    }).filter(a => a.quantity > 0 && a.demand_operation_id)
    return group_demand_operation_selection(converted)
}


export const get_next_allocation = (plan: IPlan, allocation_id: AllocationIdType) => {
    const alloc = plan.allocations[allocation_id]
    return _.minBy(get_active_allocations(plan).filter(a => a.plan_board_line_id === alloc.plan_board_line_id && a.start_time > alloc.start_time), a => a.start_time)?.id
}


export const get_previous_allocation = (plan: IPlan, allocation_id: AllocationIdType) => {
    const alloc = plan.allocations[allocation_id]
    return _.maxBy(get_active_allocations(plan).filter(a => a.plan_board_line_id === alloc.plan_board_line_id && a.start_time < alloc.start_time), a => a.start_time)?.id
}

export function select_first_n_by_quantity<T>(units: T[], key: keyof T, limit: number): T[] {
    const result = [] as T[]
    let total: number = 0
    units.forEach(v => {
        const count = v[key]
        if (total < limit) {
            const possibleCount = _.min([count, limit - total]) as number
            result.push({ ...v, [key]: possibleCount })
            total += possibleCount
        }
    })
    return result
}

export const add_allocation_to_plan = (plan: IPlan, allocations: IAllocation[]) => {
    const update = _.keyBy(allocations, "id")
    return { ...plan, allocations: { ...plan.allocations, ...update } } as IPlan
}

export const delete_allocations_from_plan = (plan: IPlan, allocationIds: Set<AllocationIdType>) => {
    const update = _.mapValues(plan.allocations, a => {
        if (allocationIds.has(a.id)) {
            return { ...a, deleted: true }
        }
        return a
    })

    return { ...plan, allocations: { ...plan.allocations, ...update } } as IPlan
}

export const update_allocations_in_plan_inplace = (plan: IPlan, allocation_parts: IAllocationPart[]) => {
    const allocationChange = _.chain(allocation_parts).map(part => {
        const existingAllocation = plan.allocations[part.allocation_id]
        const demandOperation = plan.demand_operations[existingAllocation.demand_operation_id]
        const bucket = plan.buckets[existingAllocation.line_bucket_id]
        const end_time = new Date(existingAllocation.start_time.valueOf() + get_duration(part.quantity, demandOperation.smv, bucket.carder, bucket.efficiency))
        return ({ ...existingAllocation, end_time, quantity: part.quantity })
    }).keyBy("id").value()

    return { ...plan, allocations: { ...plan.allocations, ...allocationChange } } as IPlan
}

export const get_active_allocations = (plan: IPlan) => {
    return Object.values(plan.allocations).filter(a => !a.deleted)
}

function get_updated_if_changed<T>(obj: T, change: Partial<T>) {

    const hasChanged = Object.keys(change).some((k: string) => obj[k as keyof T] !== change[k as keyof T])
    if (hasChanged) {
        return { ...obj, ...change }
    }
    return obj
}



export const update_planned_state = (plan: IPlan) => {
    const counts = _.chain(get_active_allocations(plan))
        .groupBy(a => a.demand_operation_id)
        .mapValues(allocs => _.sum(allocs.map(a => a.quantity)))
        .value()

    const plannedStartDates = _.chain(get_active_allocations(plan))
        .groupBy(a => a.demand_operation_id)
        .mapValues(allocs => _.min(allocs.map(a => a.start_time)))
        .value()

    const plannedEndDates = _.chain(get_active_allocations(plan))
        .groupBy(a => a.demand_operation_id)
        .mapValues(allocs => _.max(allocs.map(a => a.end_time)))
        .value()

    const dOpChange = _.mapValues(plan.demand_operations, d => {
        const change = { planned_end_date: plannedEndDates[d.id], planned_start_date: plannedStartDates[d.id], planned_quantity: counts[d.id] || 0 } as IDemandOperation
        return get_updated_if_changed(d, change)
    })

    return { ...plan, demand_operations: { ...plan.demand_operations, ...dOpChange } } as IPlan
}

// Assume we don't need them seperately. Needs to do after calculate planned state
export const update_need_dates = (plan: IPlan) => {
    const dOpChange = {} as any
    _.mapValues(plan.demand_operations,
        d => d.rm_demand_operations.forEach(rmDemandOpId => {
            if (plan.demand_operations[rmDemandOpId]) {
                const existing = plan.demand_operations[rmDemandOpId]
                dOpChange[rmDemandOpId] = get_updated_if_changed(existing, { required_time_max: d.planned_start_date || existing.required_time_max } as IDemandOperation)
            }
        })
    )
    return { ...plan, demand_operations: { ...plan.demand_operations, ...dOpChange } } as IPlan
}

// Needs to do after planned state
export const update_rm_dates = (plan: IPlan) => {
    const dOpChange = {} as any
    _.mapValues(plan.demand_operations, d => {
        const rm_dates = d.rm_demand_operations.map(rmDemandOpId => plan.demand_operations[rmDemandOpId]?.planned_end_date).filter(v => !!v)
        if (rm_dates.length > 0) {
            const plan_end = _.max(rm_dates) as Date
            const rm_ready_date = new Date(plan_end.valueOf() + d.input_lead_time * 3600 * 1000)
            dOpChange[d.id] = get_updated_if_changed(d, { rm_ready_date } as IDemandOperation)
        }
    })
    return { ...plan, demand_operations: { ...plan.demand_operations, ...dOpChange } } as IPlan
}

export const getToolKey = (configuration: string) => {
    const parts = configuration.split(":")
    return parts.slice(Math.max(parts.length - 2)).join(":")
}

const getAllocationIdsWithToolIssues = (plan: IPlan) => {
    if (Object.values(plan.tools).length === 0) {
        return []
    }
    const groupByTools = _.groupBy(get_active_allocations(plan), a => getToolKey(a.configuration))
    const getOverUsedIds = (items: { start_time: Date, end_time: Date, id: any }[], limit: number) => {
        const events = _.sortBy([...items.map(a => [a.start_time, 1, a.id]), ...items.map(a => [a.end_time, -1, a.id])], ["0", "1"])

        const result = [] as any[]
        events.reduce((count: number, event: any[]) => {
            if (event[1] === 1 && count >= limit) {
                result.push(event[2])
            }
            return count + event[1]
        }, 0)
        return result
    }

    const toolIssueAllocIdsList = Object.values(groupByTools).reduce((result: AllocationIdType[], allocs: IAllocation[]) => {
        const tool = Object.values(plan.tools).find(tool => `${tool.type}:${tool.size}` === getToolKey(allocs[0].configuration))
        return [...result, ...getOverUsedIds(allocs, tool?.count || 1)]
    }, [])

    return toolIssueAllocIdsList
}
// Needs to do after planned state
export const calculate_tool_availability = (plan: IPlan) => {
    // Get tools count
    // Reduce all and get the availability update reduced

    const toolIssueAllocIdsList = getAllocationIdsWithToolIssues(plan)
    const toolIssueAllocIds = new Set(toolIssueAllocIdsList)

    const getToolAvailabilityUpdated = (a: IAllocation) => get_updated_if_changed(a, { is_tools_availabile: !toolIssueAllocIds.has(a.id) })
    return { ...plan, allocations: _.mapValues(plan.allocations, getToolAvailabilityUpdated) } as IPlan
}
export const get_calculated_fields_updated = _.flow([update_planned_state, update_need_dates, update_rm_dates, calculate_tool_availability])