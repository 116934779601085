import React from "react";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  SelectArrayInput,
  FormDataConsumer,
} from "react-admin";
import { redirect } from "../../lib/redirect";
import { algorithmConfigSchema } from "./AlgorithmConfigSchema";
import PlanExportColumnSelector from "./helperComponents/planExportColumnSelector";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.plant) {
    errors.plant = "Plant is required";
  }
  if (!values.algorithm) {
    errors.algorithm = "algorithm is required";
  }
  return errors;
};

const export_type_choices = [
  { id: "BASIC", name: "BASIC" },
  { id: "COMBINE_SIZES", name: "COMBINE_SIZES" },
  { id: "SCOM", name: "SCOM" },
];

const smv_units = [
  { id: "HOURS", name: "HOURS" },
  { id: "MINUTES", name: "MINUTES" },
];

export const tableview_fields = [
  { name: "Line", id: "line" },
  { id: "order_group_code", name: "Order Group" },
  { name: "Order", id: "order_code" },
  { id: "order_release_number", name: "Release No." },
  { id: "item_code", name: "Item" },
  { id: "compound_codes", name: "Compounds" },
  { id: "configuration", name: "Configuration" },
  { id: "order_required_month", name: "Month" },
  { id: "order_week_number", name: "Week No." },
  { name: "Confirmation", id: "order_confirmation_date" },
  { name: "Order Need", id: "order_required_date" },
  { name: "Need Date", id: "required_time_max" },
  { name: "Rm Ready", id: "rm_ready_date" },
  { name: "Plan Start", id: "planned_start_date" },
  { name: "Plan End", id: "planned_end_date" },
  { name: "Delay(days)", id: "days" },
  { name: "Production Time", id: "production_time" },
  { id: "quantity_multiplier", name: "Mlt" },
  { id: "quantity", name: "Quantity" },
  { id: "skipped_quantity", name: "Skipped" },
  { id: "planned_quantity", name: "Planned" },
  { id: "completed_quantity", name: "Completed" },
  { id: "smv", name: "SMV" },
  { id: "operation", name: "Operation" },
  { id: "auto_plan_errors", name: "Plan Errors" },
];

export const CreatePlantBoard = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      class="col-md-8"
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar need={false} name="planboards" text="Planboard" />
        }
        validate={validate}
        rowClick="edit"
        redirect={redirect}
      >
        <ReferenceInput
          label={<ReqInputField name="plant" />}
          source="plant"
          reference="plants"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="algorithm"
          label={<ReqInputField name="algorithm" />}
        />
        <TextInput source="code" label={<ReqInputField name="code" />} />
        <SelectInput
          defaultValue="HOURS"
          label="export smv unit"
          source="export_smvunit"
          choices={smv_units}
          translateChoice={false}
        />
        <SelectArrayInput
          label="Table view default fields"
          source="tableview_attributes"
          choices={tableview_fields}
          translateChoice={false}
        />
        <TextInput source="tool_type" label="tool type" />
        <TextInput source="tool_size" label="tool size" />
        <TextInput
          source="supported_group_selection_attribute"
          default="style"
        />
        <TextInput source="export_item_code_selection_attribute" default="" />
        <TextInput source="export_mold_selection_attribute" default="" />
        <SelectInput
          label="export type"
          source="export_type"
          choices={export_type_choices}
          translateChoice={false}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <PlanExportColumnSelector
              formData={formData}
              source="plan_export_columns"
              translateChoice={false}
              optionText="plan export columns"
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
