import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import jsonExport from "jsonexport/dist";
import React from "react";
import {
  BooleanField,
  BooleanInput,
  CardActions,
  // CreateButton,
  Datagrid,
  DateInput,
  downloadCSV,
  EditButton,
  ExportButton,
  Filter,
  List,
  Mutation,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  showNotification,
  TextField,
  TextInput,
  useDataProvider,
  usePermissions,
  NumberField,
  BulkDeleteButton,
} from "react-admin";
import { connect } from "react-redux";
import Confirmation from "../../components/Confirmation";
import CreateButton from "../../components/CustomCreateButton";
import CustomDateField from "../../components/field/CustomDateField";
import { useAutoScroll } from "../../hooks/useScroll";
import { showToastWarning, showToastSuccess, showToastError } from "../../lib/toas";
import { OrderBulkActionButtons } from "./helpers/action/bulkAction";
import ProgressBars from "./helpers/components/ProgressView";
import { ChangeDiv } from "../../components/ChangeDiv"
import { format_to_date, format_to_date_time } from "../../lib/date_time";
import _ from "lodash"
import { vi } from "date-fns/locale";
import { Popover } from "@material-ui/core";
import KeyValueField from "../../components/field/KeyValueField";
import ListStringJoinField, { stringJoinWIthStars } from "../../components/field/ListStringJoinFiedl";
import LinkField from "../../components/field/LinkField";
import {
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
} from "react-admin";
import { cloneElement, Fragment } from "react";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
  },
  order_actions_root: {
    display: "inline-flex",
    marginTop: theme.spacing(3),
    paddingBottom: "15px",
  },
  datagrid: {
    row: {
      height: "35px",
    },
  },
}));

const typeFormatters = {
  "text": v => v,
  "link": v => v,
  "stringlist": stringJoinWIthStars,
  "number": parseInt,
  "date": format_to_date,
  "bool": v => v ? "Yes" : "No"
}



const ResolveAnOrderDialog = (props) => {
  const { permissions } = usePermissions();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ResolveOneButton = () => {
    const options = {
      undoable: false,
      onSuccess: () => showToastSuccess('Resolved success'),
      onError: () => showToastError('Resolved fail')
    };

    return (
      <Mutation
        type="CUSTOM"
        resource="orders"
        payload={{
          id: `${props.record.id}`,
          action: "resolve",
          method: "POST",
        }}
        options={options}
      >
        {(approve) => (
          <Button
            // color="secondary"
            // class="btn-success px-5 py-2 mb-3"
            className="btn_ btn_edit"
            // style={{
            //   fontSize: "14px",
            //   marginBottom: "10px",
            //   marginRight: "15px",
            //   border: "unset",
            // }}
            variant="contained"
            onClick={() => {
              showToastWarning('Resolved start');
              approve();
              handleClose();
            }}
          >
            Yes
          </Button>
        )}
      </Mutation>
    );
  };

  return (
    <div>
      <Button
        title="click hear to resolve order"
        disabled={!permissions?.permissions.includes("orders_resolveOne")}
        variant="contained"
        // color="primary"
        onClick={handleClickOpen}
        className="btn_ btn_primary"
      >
        Resolve
      </Button>
      <Dialog
        fullWidth="true"
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ paddingTop: "10px" }} id="alert-dialog-title">
              {"Resolve the order "}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        <DialogActions>
          {/* <Button onClick={handleClose} color="primary" className="btn-delete">
            cancel
          </Button> */}
          <ResolveOneButton />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ResolveAllOrdersDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const { permissions } = usePermissions();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ResolveAllButton = () => {
    const options = {
      undoable: false,
      onSuccess:() => showToastSuccess('Resolved success'),
      onError:() => showToastError('Resolved fail')      
    };

    return (
      <Mutation
        type="RESOLVE_ALL_ORDERS"
        resource="orders"
        payload={{ action: "resolve_all", method: "POST" }}
        options={options}
      >
        {(onClick) => (
          <Button
            color="secondary"
            variant="contained"
            // className="btn-success px-3 py-2"
            // style={{ minWidth: "100px" }}
            className="btn_ btn_edit"
            onClick={() => {
              showToastWarning('Resolved start');
              onClick();
              setOpen(false);
            }}
          >
            Yes
          </Button>
        )}
      </Mutation>
    );
  };

  return (
    <div>
      <Button
        title="Click here to resolve all orders"
        disabled={!permissions?.permissions.includes("orders_resolveAll")}
        variant="contained"
        className="btn_ btn_edit"
        onClick={handleClickOpen}
      >
        Resolve All Orders
      </Button>
      <Dialog
        fullWidth="true"
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ paddingTop: "10px" }} id="alert-dialog-title">
              {"Resolve all orders?"}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
                cursor: "pointer",
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>


        <DialogActions>

          <ResolveAllButton
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

//skip plan toggle component
const ToggleSkipPlanning = ({ source, record = {} }) => {
  console.log(record)
  const dataProvider = useDataProvider();
  const [click, setClick] = React.useState("");
  const [rid, setRid] = React.useState("");
  const classes = useStyles();

  const options = {
    undoable: true,
    onSuccess: {
      notification: { body: "value changed", level: "info" },
    },
    onFailure: {
      notification: { body: "Error: Value not changed", level: "warning" },
    },
  };


  const setDataProviderVal = async () => {
    const payload = {
      id: rid,
      data: {
        skip_planning: click,
        code: record.code,
        required_date: record.required_date,
        quantity: record.quantity,
        // order_group:record.order_group
      },
    };
    try {
      if (record.id === rid) {
        // use put request because backed expect put request for partial update

        const data = await dataProvider.put("orders", payload);
        if (data.data) {
          // in success
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    if (rid) {
      setDataProviderVal();
    }
  }, [click]);



  return (
    <input
      type="checkbox"
      defaultChecked={record.id === rid ? true : record.skip_planning}
      onClick={() => {
        const val = !record.skip_planning;
        setClick(val);
        setRid(record.id);
        showToastWarning(`plan skip ${val ? "add" : "remove"}`);
      }}
    />
  );
};



const CustomPlant = ({ source, record = {} }) => {
  return (


    <Typography variant="body1" gutterBottom>{record.plant_name} </Typography>
  );

}
const CustomFinalItem = ({ source, getLink, record = {} }) => {

  return (
    <div>
      <a href={getLink(record)}>{record[source]}</a>
    </div>
  )

}


const renderColumn = (column) => {
  const { type, label, source, visible, sort, link } = column
  const props = {
    label: label || sourceToTitle(source),
    source,
    sortable: !!sort,
  }
  if (!visible) {
    return null;
  }
  else if (type == "text") {
    return <TextField {...props} emptyText="N/A" />
  }
  else if (type == "number") {
    return <NumberField {...props} options={{ maximumFractionDigits: 1 }} />
  } else if (type == "date") {
    return <CustomDateField {...props} emptyText="N/A" />
  } else if (type == "bool") {
    return <BooleanField {...props} />
  } else if (type == "keyvalue") {
    return <KeyValueField {...props} />
  } else if (type == "stringlist") {
    return <ListStringJoinField {...props} />
  } else if (type == "link") {
    return <LinkField {...props} getLink={link} />
  } else if (type == "progress") {
    return <ProgressBars {...props} />
  } else if (type == "skipPlanning") {
    return <ToggleSkipPlanning {...props} />
  } else if (type == "customPlant") {
    return <CustomPlant {...props} sortBy="order_group__plant__name" />
  } else if (type == "customFinalItem") {
    return <CustomFinalItem {...props} sortBy="final_item__code" getLink={link} />
  }

  return null

}

const ColumnSelector = ({ columns, setColumns }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeColumnState = (source) => {
    const newColumns = columns.map(col => col.source == source ? ({ ...col, visible: !col.visible }) : col)
    setColumns(newColumns)
  }

  return <div>
    <Button variant="contained"
      style={{ whiteSpace: 'nowrap' }}
      className="btn_ btn_primary"
      color="primary" onClick={handleClick}>Select Columns</Button>
    <Popover
      id="rvsn"
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div style={{ padding: 10 }}>
        {columns.map(r => {
          return <div key={r.source} style={{ width: '100%' }}>
            <div >
              <input
                type="checkbox"
                checked={r.visible}
                onChange={() => changeColumnState(r.source)}
              />
              <span> {r.label || sourceToTitle(r.source)} </span> <br />
            </div>
          </div>
        })}
      </div>
    </Popover>
  </div>
}



const Actions = (props) => {
  const { permissions } = usePermissions();
  const { className, exporter, filters, maxResults, columnData, ...rest } = props;
  const { columns, setColumns } = columnData
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer} {...sanitizeListRestProps(rest)}>
      <CardActions>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
            className: "btn_",
          })}
        <CreateButton
          basePath={basePath}
          className="btn_ btn_primary"
          title="Click here to create orders"
          disabled={!permissions?.permissions.includes("orders_create")}
        />
        <ExportButton
          title="Click here to Export orders"
          disabled={
            total === 0 && !permissions?.permissions.includes("orders_export")
          }
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
          className="btn_ btn_primary"
        />
        <ResolveAllOrdersDialog />

        <ColumnSelector columns={columns} setColumns={setColumns} />
        {/* <ExportButton
          className="btn_ btn_primary"
          title="Click here to Export Demands "
          disabled={!permissions?.permissions.includes("demands_export")}
        /> */}
      </CardActions>
    </div>

  );
};



const sourceToTitle = (txt = "") => txt.replaceAll(".", " ➡️ ").split(/[\s_]+/).map(txt => txt[0].toUpperCase() + txt.slice(1)).join(" ")


const OrderActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
      className={classes.root}
    >
      <CardActions>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
            className: "btn_ ",
          })}
        <CreateButton
          basePath={basePath}
          className="btn_ btn_primary"
          title="Click here to create orders"
          disabled={!permissions?.permissions.includes("orders_create")}
        />
        <ExportButton
          title="Click here to Export orders"
          disabled={
            total === 0 && !permissions?.permissions.includes("orders_export")
          }
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
          className="btn_ btn_primary"
        />
        <ResolveAllOrdersDialog />
      </CardActions>
    </div>
  );
};

const OrderFilter = (props) => {

  const { permissions } = usePermissions();
  const isAdmin = permissions?.access_level == "ADMIN"
  return (
    <Filter title="Click here to add filter" {...props}>
      <TextInput label="Search code" source="code" alwaysOn />
      <TextInput label="Search item code" source="final_item_code" alwaysOn />
      <BooleanInput label="Is Allocated" source="skip_planning  " />
      <BooleanInput label="Is Dummy" source="is_dummy" />
      <BooleanInput label="Resolve failed" source="resolve_failed" alwaysOn />
      <DateInput label="search by required date" source="required_date" />
      {isAdmin && <ReferenceInput
        link=""
        label="Site"
        source="site_id"
        reference="sites"
        alwaysOn
      >
        <SelectInput optionText="name" resettable />
      </ReferenceInput>}
      <ReferenceInput
        link=""
        label="Plant"
        source="plant_name"
        reference="plants"
        filter={{ site_id: props.filterValues.site_id }}
        alwaysOn
      >
        <SelectInput optionText="name" resettable />
      </ReferenceInput>
    </Filter>
  );
};

const exporter = (orders) => {
  const postsForExport = orders.map((order) => {
    order.item_code = order.final_item.code; // add a field
    return order;
  });
  jsonExport(
    postsForExport,
    {
      headers: ["id", "item_code"], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "posts"); // download as 'posts.csv` file
    }
  );
};

const Orders = (props) => {
  const { permissions } = usePermissions();
  const [columns, setColumns] = React.useState(defaultColumns)
  const autoScroll = useAutoScroll(props);
  const dataProvider = useDataProvider();
  const [click, setClick] = React.useState("");
  const [rid, setRid] = React.useState("");
  const classes = useStyles();


  return (
    <div
      style={{
        // width: '83%',
        height: "100%",
        // overflow: "auto",
        // marginTop: 10,
      }}
    //  className="table-width-custom"
    >
      <List
        filters={<OrderFilter />}
        actions={<Actions columnData={{ columns, setColumns }} />}
        perPage={25}
        bulkActionButtons={<OrderBulkActionButtons />}
        exporter={exporter}
        {...props}
      >
        <ChangeDiv>
          {props => {
            return <Datagrid>

              {columns.map(renderColumn)}
              <EditButton
                className="btn_ btn_edit m-0"
                title="Click here to Edit orders"
                disabled={!permissions?.permissions.includes("orders_edit")}
              />{" "}
              <Confirmation
                text="order"
                title="Click here to delete orders"
                className="btn_ btn_delete m-0"
                // variant="contained"
                disabled={!permissions?.permissions.includes("orders_delete")}
              />{" "}
              <ResolveAnOrderDialog />

            </Datagrid>
          }}

        </ChangeDiv>

      </List>
    </div>
  );
};

const mapStateToProps = () => { };

const mapDispatchToProps = {
  showNotification: showNotification,
};

const keys = ["label", "source", "type", "visible", "sort", "link"]
const all_fields = [
  ["Id", "id", "text", false, true],
  ["Code", "code", "text", true, true],
  ["Confirmation date", "confirmation_date", "date", false, true],
  ["Required date", "required_date", "date", true, true],
  ["Plan start date", "planned_start_date", "date", true, true],
  ["Plan end date", "planned_end_date", "date", true, true],
  ["Quantity", "quantity", "text", true, true],
  ["Release number", "release_number", "text", true, true],
  ["Week number", "week_number", "text", true, true],
  ["RM Status", "rmstatus", "progress", true, false],
  ["Is dummy", "is_dummy", "bool", false, true],
  ["Item Code", "final_item_code", "customFinalItem", true, true, record => `#/items/${record.final_item}/show`],
  ["Skip Planning", "skip_planning", "skipPlanning", false, true],
  ["Plant name", "plant_name", "customPlant", true, true],
  ["Resolve failed", "resolve_failed", "bool", true, true],
  ["Resolve failed reason", "resolve_failed_reason", "text", true, true],

]

// Convers into objects. It's easier to handle in the componet
const defaultColumns = all_fields.map(row => _.reduce(keys, (obj, k, i) => ({ ...obj, [k]: row[i] }), {}))
export const Order = connect(mapStateToProps, mapDispatchToProps)(Orders);

