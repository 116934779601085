import Excel from "exceljs";
import { saveAs } from "file-saver";
import { format_reverse_oder_date, format_to_date_time, DATE_FORMAT, DATE_TIME_FORMAT_EXCEL } from "./date_time";
import _ from "lodash";
import { PLAN_EXPORT_COLUMNS as plan_export_columns } from "../constants";

//font sizes
const mainHeaderFontSize = 11;
const timeRangeFontSize = 11;
const fontSize1 = 8;
// const fontSize2 = 9;
const columnsCount = 13;

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//column order

const exportColumns = [
  "HEAD",
  "ITEM CODE",
  "BTCHS",
  "LOT BATCH NO.",
  "ALLOC RANGE",
  "TIME/BTCH",
  "TOTAL TIME",
  "DELIVERY",
  "DELAYED QTY.",
];
//map column widths
const columnWidths = {
  "ITEM CODE": 15,
  DELIVERY: 17,
  "LOT BATCH NO.": 15,
};

//map colors
const colorMappings = {
  B: "FFCCCCCC",
  BE: "FFF5F5DC",
  DB: "FFFFE4B5",
  PI: "FFFFC0CB",
  R: "FFFF7F50",
  W: "FFF8F8FF",
};


const numFormats = {
  "bucket_start_time": DATE_FORMAT,
  "Order Required Date": DATE_FORMAT,
  "DELIVERY": DATE_FORMAT,
  "bucket_start_time": DATE_FORMAT,
  "Start Time": DATE_TIME_FORMAT_EXCEL,
  "Order Required Date": DATE_FORMAT,
}

const formatData = (data) => {
  const output = data.map((record) => {
    return { ...record, Delay: " " };
  });

  return output;
};
const scomPlanExport = async (data, time, planBoard) => {

  const exportColumns = planBoard.plan_export_columns.filter(a => plan_export_columns.SCOM.includes(a));

  console.log(data);
  const formattedData = formatData(data);
  const columnsCount = exportColumns.length;

  var workbook = new Excel.Workbook();
  workbook.creator = "Auto Generated by Stack Schedular";
  var worksheet = workbook.addWorksheet("plan", {
    views: [{ showGridLines: true }],
  });

  //main header
  worksheet.getRow(1).height = 42.5;
  worksheet.mergeCells("A1", `${alphabet[columnsCount - 1]}1`);
  worksheet.getCell("A1").value = `PLAN - ${planBoard.code}`;
  worksheet.getCell("A1").font = {
    name: "Verdana",
    size: mainHeaderFontSize,
    bold: true,
  };
  worksheet.getCell("A1").border = {
    top: { style: "thick" },
    left: { style: "thick" },
    bottom: { style: "thick" },
    right: { style: "thick" },
  };
  worksheet.getCell("A1").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("A1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFFFFF00" },
  };

  const timeRange = `from : ${format_reverse_oder_date(
    time.start
  )} to : ${format_reverse_oder_date(time.end)}`;

  //time range
  worksheet.mergeCells("A2", "D2");
  worksheet.getRow(2).height = 20;
  worksheet.getCell("A2").value = timeRange;
  worksheet.getCell("A2").font = {
    name: "Verdana",
    size: timeRangeFontSize,
    bold: true,
  };

  //table headers
  const headers = exportColumns;
  worksheet.getRow(4).values = headers;
  const mergeIndex = headers.indexOf("LOT BATCH NO.");
  worksheet.mergeCells(
    `${alphabet[mergeIndex]}4`,
    `${alphabet[mergeIndex + 1]}4`
  );
  worksheet.lastRow.eachCell(
    { includeEmpty: true },
    function (cell, cellNumber) {
      if (cellNumber < columnsCount + 1) {
        if (!cell.value) {
          cell.value = "";
        }
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF9999FF" },
        };
        cell.font = {
          name: "Verdana",
          size: fontSize1,
        };
      }
    }
  );

  worksheet.columns = headers.map((i) => ({
    totalsRowFunction: "sum",
    key: i,
    width: columnWidths[i] || 8,
    style: numFormats[i] ? { numFmt: numFormats[i] } : {}
  }));

  worksheet.addRow(["PENDING"]);
  var shift_count = 0;
  formattedData.map((record, index) => {
    const item = record["ITEM CODE"];
    const bucketDateLine = record.bucketDateLine;
    const bucket = record.bucket;
    // Fix datetime utc becuse excell js has an issue with the timezone when executed. 
    //https://github.com/exceljs/exceljs/issues/486#issuecomment-432557582
    Object.keys(numFormats).forEach(k => {
      const date = record[k]
      const isDate = typeof date?.getMonth === 'function'
      if(isDate){
        record[k] = new Date( Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()))
      }
    })

    if (!data[index - 1] || data[index - 1].bucket !== bucket) {
      shift_count++;
      worksheet.addRow([`SHIFT ${shift_count}`]);
      var i;
      for (i = 0; i < columnsCount; i++) {
        worksheet.lastRow.getCell(i + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCCCCFF" },
        };
        worksheet.lastRow.getCell(i + 1).font = {
          name: "Verdana",
          size: fontSize1,
        };
      }
    }

    if (record["ITEM CODE"]) {
      worksheet.addRow(record);
    } else {
      worksheet.addRow({"HEAD":`CLEANING`, "TOTAL TIME":record["TOTAL TIME"]});
    }

    if (data[index + 1] && data[index + 1].bucketDateLine !== bucketDateLine) {
      shift_count = 0;
    }
    //    else {

    // }
  });

  worksheet.addRow([" "]);

  const rowCount = worksheet.rowCount;

  worksheet.addRow("");
  worksheet.lastRow.getCell(1).value = "TOTAL";

  const batchesIndex = headers.indexOf("BTCHS");
  worksheet.lastRow.getCell(batchesIndex + 1).value = {
    formula: `sum(${alphabet[batchesIndex]}5:${alphabet[batchesIndex]}${rowCount})`,
    result: _.sumBy(formattedData, "BTCHS"),
  };

  const totalTimeIndex = headers.indexOf("TOTAL TIME");
  worksheet.lastRow.getCell(totalTimeIndex + 1).value = {
    formula: `sum(${alphabet[totalTimeIndex]}5:${alphabet[totalTimeIndex]}${rowCount})`,
    result: _.sumBy(formattedData, "TOTAL TIME"),
  };

  //add thin borders to all table cells
  worksheet.eachRow(function (row, rowNumber) {
    if (rowNumber > 3) {
      var i;
      for (i = 1; i < columnsCount - 1; i++) {
        row.getCell(i + 1).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
        };
      }

      //first cell in a row
      row.getCell(1).border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
      };

      //last cell in a row
      row.getCell(columnsCount).border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    }
  });

  //end text
  worksheet.addRow([""]);
  worksheet.addRow([`Timestamp: ${format_to_date_time(new Date())}`]);
  worksheet.addRow([`Export type: ${planBoard.export_type}`]);

  worksheet.addRow([`Export SMV unit: ${planBoard.export_smvunit}`]);
  worksheet.addRow(["Auto generated by Stack Schedular"]);

  //export excel
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";
  const filename = `plan ${format_reverse_oder_date(
    time.start
  )}-${format_reverse_oder_date(time.end)}`;
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, filename + fileExtension);
};

export default scomPlanExport;
