import React from "react";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
  SaveButton,
  Toolbar,
} from "react-admin";
import { redirect } from "../../lib/redirect";
import { ReqInputField } from "../../components/ReqInputField";
import { makeStyles } from "@material-ui/core/styles";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

const validatePlant = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.code) {
    errors.code = "Code is required";
  }

  return errors;
};

export const CreatePlant = (props) => {
  const { permissions } = usePermissions();
  const customToast = useCustomToast({ redirect: redirect });
  return (
    <Create
      {...props}
      class={"col-md-6 mt-5"}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm
        toolbar={<CustomToolbar need={false} name="plants" text="Plant" />}
        validate={validatePlant}
      >
        <TextInput
          source="code"
          label={<ReqInputField name="Code" />}
          title="This field is mandatory"
        />
        <TextInput
          source="name"
          label={<ReqInputField name="Name" />}
          title="This field is mandatory"
        />
        <ReferenceInput
          label={<ReqInputField name="Site" />}
          source="site"
          reference="sites"
          title="This field is mandatory"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
