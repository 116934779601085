import { useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import useWindowSize from "@rehooks/window-size";
import moment from 'moment';
import React, { useEffect, useRef } from "react";
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from "react-calendar-timeline/lib";
import 'react-calendar-timeline/lib/Timeline.css';
import { connect, useSelector } from "react-redux";
import { createSelector } from 'reselect';
import { getColorFromConfiguration } from "../../lib/color";
import { getAllocationStatusColor } from '../../lib/plan_board/utils';
import { clearSelection, modelOpen, requestMoveAllocation, selectEntity, setSelectedTimeRange, shiftTimeRangeBackward, shiftTimeRangeForward } from "../../store/actions";
import { getAllocationsInSelectedTimeRangeOrSelected, getBuckets, getColorMappings, getEntity, getAllocations, getDemandOperationsObject, getAllocationsObject, getIsEditing, getLines, getSelectedEntity, getSelectedGroupId, getSelectedTimeRange, getSelection, getSelectedLineIndex } from "../../store/selectors";

// make sure you include the timeline stylesheet or the timeline will not be styled


const CalendarView2 = ({
  dataSource,
  resourceDataSource,
  onSelect,
  openModel,
  onTimeRangeChange,
  moveAllocation,
  selectedTimeRange,
  selectedGroup,
  isUserEditing,
  selection,
  height,
  onClearSelection,
  isSideBarOpen,
  goBackward,
  selectedLineIndex,
  goForward
}) => {



  // const [dragScrollTime, setDragScrollTime] = useState([0, 0])

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [dataSource, selectedTimeRange])

  const scrollRef = useRef(null)
  const { innerWidth, innerHeight } = useWindowSize();

  useEffect(() => {
    // let node = React.findDOMNode(scrollRef.current);
    // node.scrollIntoView({block: 'start', behavior: 'smooth'});
    const node = scrollRef.current
    if (node && selectedLineIndex > 4) {
      node.scrollTop = 45 * (selectedLineIndex - 4)
    }
  }, [dataSource, selectedLineIndex])


  const theme = useTheme()
  const handleTimeChange = (start_timestamp, end_timestamp, updateScrollCanvas) => {
    const maxDiff = 1000 * 3600 * 24 * 10
    end_timestamp = Math.min(maxDiff + start_timestamp, end_timestamp)
    updateScrollCanvas(start_timestamp, end_timestamp)
    onTimeRangeChange(start_timestamp, end_timestamp)
  }

  const onItemSelect = (itemId, e, time) => {
    const [type, id] = itemId.split("_")
    onSelect(type, id)
  }

  const handleMove = (itemId, dragTime, newGroupId) => {
    const lineId = Math.abs(parseInt(newGroupId)) // Negative or positive because somtime it is negative 
    const [itemType, id] = itemId.split("_")
    if (itemType === "allocations") {
      moveAllocation(id, lineId, dragTime)
      onSelect("line", lineId)
    }
  }
  // When the date range is changed calendar should be updated. If we update it using the millis of start_time and end_time somthing weired happend. So jsut use the date instead.
  // const calendarUpdateKey = `${moment(selectedTimeRange.start_time).format("MMM Do YY")}${moment(selectedTimeRange.end_time).format("MMM Do YY")}`



  // Hanadle Drag and scroll
  const handleDrag = (itemDragObject) => {
    const { eventType, time } = itemDragObject
    if (eventType === "move") {
      if (time < selectedTimeRange.start_time) {
        goBackward(24)

      } else if (time > selectedTimeRange.end_time) {
        goForward(24)
      }

    }
  }

  const fullWidth = Math.max(innerWidth - (isSideBarOpen ? 300 : 100), 100)
  const fullHeight = Math.max(height || (innerHeight - 200), 100)
  return (
    <div ref={scrollRef} style={{ width: fullWidth, overflowY: 'scroll', height: fullHeight }} >
      <Timeline
        groups={resourceDataSource}
        items={dataSource}
        visibleTimeStart={selectedTimeRange.start_time}
        visibleTimeEnd={selectedTimeRange.end_time}
        onItemSelect={onItemSelect}
        onCanvasClick={onClearSelection}
        // itemsSorted
        itemTouchSendsClick={false}
        stackItems
        itemHeightRatio={0.70}
        // lineHeight={50}
        showCursorLine
        canMove={isUserEditing === "user"}
        canResize={false}
        lineHeight={45}
        itemRenderer={props => <ItemRender {...props} />}
        groupRenderer={props => <GroupComp {...props} onSelect={onSelect} onOpenModel={openModel} />}
        onItemMove={handleMove}
        onItemDrag={handleDrag}
        // minZoom={24 * 3600 * 1000}
        // maxZoom={7 * 24 * 3600 * 1000}
        onTimeChange={handleTimeChange}
      >
        <TimelineHeaders className="sticky" style={{ backgroundColor: theme.palette.primary.main }}>
          <SidebarHeader>
            {({ getRootProps }) => {
              return <div {...getRootProps()}></div>;
            }}
          </SidebarHeader>
          <DateHeader unit="primaryHeader" />
          <DateHeader labelFormat={getFormat} />
        </TimelineHeaders>
      </Timeline>
    </div>

  )
}


const ItemRender = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps
}) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const isShift = itemContext.title === "Shift"
  const itemProps = getItemProps(item.itemProps)
  const selection = useSelector(getSelection)
  const allocationsObj = useSelector(getAllocationsObject)
  const demandOpObj = useSelector(getDemandOperationsObject)

  const getGroupCode = (allocId) => demandOpObj[allocationsObj[allocId]?.demand_operation_id]?.order_group_code

  const isBucket = item.isBucket
  const noSelection = !selection
  const allocationSelected = selection?.type === "allocations" && getGroupCode(selection.id) === getGroupCode(item.original_id)
  const demandOpSelected = selection?.type === "demand_operations" && selection.id==item.demand_operation_id

  const is_selected =isBucket || noSelection ||  allocationSelected || demandOpSelected

  const selectionStyles = {
    opacity: is_selected ? 1 : 0.4,
    borderLeftColor: is_selected ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.1)',
    borderRightColor: is_selected ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.1)',

  }
  const itemPropsStyleAdded = { ...itemProps, style: { ...itemProps.style, overflow: 'hidden', ...selectionStyles } }


  return (
    <div {...itemPropsStyleAdded} id={item.id} title={isShift ? "Shift" : `${item.configuration} : Planned:${item.quantity} Completed:${item.completed_quantity}`}>
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
      <div
        className="rct-item-content"
        style={{ maxHeight: `${itemContext.dimensions.height}`, overflow: 'hidden' }}
      >{itemContext.title}</div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
    </div>
  )
}


const getFormat = ([startTime, endTime], unit, labelWidth, formatOptions) => {
  return moment(startTime).format(format[unit].short)
}

const format = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY'
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY'
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w'
  },
  day: {
    long: 'dd D',
    mediumLong: 'dd D',
    medium: 'dd D',
    short: 'D/MM'
  },
  hour: {
    long: 'dddd, LL, HH:00',
    mediumLong: 'L, HH:00',
    medium: 'HH:00',
    short: 'HH'
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  }
}





const formatAllocation = (item, colorMappings, isUserEditing) => {
  const has_issue = item.is_delayed || item.is_early || !item.is_tools_availabile
  const animation_name = item.is_tools_availabile ? (item.is_delayed ? 'blink-delayed' : 'blink-early') : 'blink-tool-issue'
  return ({
    ...item,
    start_time: moment(item.start_time),
    end_time: moment(item.end_time),
    group: item.plan_board_line_id,
    id: `allocations_${item.id}`,
    original_id: item.id,
    type: "allocations",
    title: `${item.configuration} : ${item.quantity}`,
    tip: 'additional information',
    canMove: isUserEditing === "user" && !item.is_freezed,
    itemProps: {
      style: {
        background: `${item.demand_operation_id ? item.is_freezed ? `repeating-linear-gradient(45deg, ${getColorFromConfiguration(colorMappings, item.configuration)} 0px 5%, transparent 5% 6%)` : getColorFromConfiguration(colorMappings, item.configuration) : 'gray'}`,
        color: item.demand_operation_id ? 'black' : 'white',
        borderWidth: 1.5,
        borderTopColor: getAllocationStatusColor(item.quantity, item.completed_quantity),
        borderTopWidth: 4,
        marginTop: 5,
        ...(has_issue ? {
          animationDuration: '100ms',
          animationName: animation_name,
          animationIterationCount: 'infinite',
          animationDirection: 'alternate'
        } : {
          })
      }
    }
  })
};



const GroupComp = ({ group, onSelect, onOpenModel }) => {
  return (
    <div >
      <Button onClick={() => {
        onSelect('lines', group.id);
        onOpenModel();

      }} >
        {group.title}
      </Button>
    </div>
  )

}

const formatBucket = item => ({
  ...item,
  start_time: item.start_time,
  end_time: item.end_time,
  id: `buckets_${item.id}`,
  group: item.line_id,
  type: "buckets",
  title: `Shift`,
  canMove: false,
  isBucket: true,
  itemProps: {
    style: {
      background: 'rgba(0,0,200,0.2)',
      borderColor: 'transparent',
      color: item.allocation_completed ? 'lightblue' : 'white',
      maxHeight: 10,
      marginTop: -6,

    }
  }

});

const generateLines = (lines) => {
  const lines2 = [];
  lines.forEach(line => {

    lines2.push({ ...line, title: `${line.code}` });
  });

  const lines3 = putSubLinesAtEnd(lines2) //to place sub contract lines at the end of planboard
  return lines3
}


const putSubLinesAtEnd = (array) => {
  const otherGroups = array.filter(a => !a.sub_contract).sort((a, b) => a.code > b.code)
  const subGroups = array.filter(a => !!a.sub_contract).sort((a, b) => a.id > b.id)
  const outputArray = otherGroups.concat(subGroups)
  return outputArray
}



const getFormattedAllocations = createSelector([getAllocations, getDemandOperationsObject, getColorMappings, getIsEditing],
  (allocations, demandOpObject, colorMappings, isUserEditing) => {
    return allocations.map(a => {
      if (!a.demand_operation_id) {
        return a
      }
      const demand_operation = demandOpObject[a.demand_operation_id]
      const min_time = demand_operation.rm_ready_date || demand_operation.required_time_min
      const is_early = a.start_time < min_time
      return { ...a, is_early, is_delayed: a.end_time > demand_operation.required_time_max }
    }).map(a => formatAllocation(a, colorMappings, isUserEditing))
  })



/**
 * return -1 if no line is selected, else index of the selected line
 */
const getFormattedBuckets = createSelector([getBuckets], buckets => buckets.map(formatBucket))
const getDataSource = createSelector([getFormattedAllocations, getFormattedBuckets], (allocs, buckets) => [...allocs, ...buckets])
const getResourceDataSource = createSelector([getLines, getSelectedGroupId], (lines, selectedGroup) => generateLines(lines).filter(l => !selectedGroup || l.plan_board_group_id === selectedGroup))

const mapStateToProps = (state) => {
  const colorMappings = getColorMappings(state)
  const selection = getSelection(state)
  const selectedLineIndex = getSelectedLineIndex(state)
  const isUserEditing = getIsEditing(state)
  const dataSource = getDataSource(state);
  const resourceDataSource = getResourceDataSource(state)
  return {
    dataSource,
    selectedLineIndex,
    resourceDataSource,
    selection: selection,
    colorMappings,
    selectedTimeRange: getSelectedTimeRange(state),
    isUserEditing,
    isSideBarOpen: state.admin.ui.sidebarOpen
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onSelect: (type, id) => dispatch(selectEntity(type, id)),
    onClearSelection: () => dispatch(clearSelection()),
    openModel: () => dispatch(modelOpen()),
    onTimeRangeChange: (start_time, end_time) => dispatch(setSelectedTimeRange(start_time, end_time)),
    moveAllocation: (allocation_id, to_line_id, to_time) => dispatch(requestMoveAllocation(allocation_id, to_line_id, to_time)),
    goForward: (duration) => dispatch(shiftTimeRangeForward(duration)),
    goBackward: (duration) => dispatch(shiftTimeRangeBackward(duration))
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(CalendarView2);
