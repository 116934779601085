import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CachedIcon from '@material-ui/icons/Cached';
import React, { Component } from "react";

class RefreshButton extends Component {
  handleClick = () => {
    window.location.reload();
  };

  render() {
    return (
      <Tooltip title="Reload">
        <IconButton
          fontSize="large"
          onClick={this.handleClick}
          // edge="end"
          color="primary"
          aria-label="back"
          style={{ backgroundColor: 'rgba(255,255,255, 0.25)', borderRadius: 50, height: 35, width: 35, margin: 5 }}
        >
          <CachedIcon style={{ color: "#ffffff" }} />
          {/* <SvgIcon
        style={{color:"#ffffff"}}
          width="299.021px"
          height="299.021px"
          viewBox="0 0 299.021 299.021"
        >
          <path
            d="M292.866,254.432c-2.288,0-4.443-1.285-5.5-3.399c-0.354-0.684-28.541-52.949-146.169-54.727v51.977
			c0,2.342-1.333,4.48-3.432,5.513c-2.096,1.033-4.594,0.793-6.461-0.63L2.417,154.392C0.898,153.227,0,151.425,0,149.516
			c0-1.919,0.898-3.72,2.417-4.888l128.893-98.77c1.87-1.426,4.365-1.667,6.461-0.639c2.099,1.026,3.432,3.173,3.432,5.509v54.776
			c3.111-0.198,7.164-0.37,11.947-0.37c43.861,0,145.871,13.952,145.871,143.136c0,2.858-1.964,5.344-4.75,5.993
			C293.802,254.384,293.34,254.432,292.866,254.432z"
          />
        </SvgIcon> */}
        </IconButton>
      </Tooltip>
    );
    //      <Fab color="primary" fontSize="small" aria-label="add" >
    //     <ArrowBackIcon />
    //   </Fab>
    // <Button variant="contained" color="primary" onClick={this.handleClick} ></Button>;
  }
}

export default (RefreshButton);