import React from "react";
import {
  SaveButton,
  Toolbar,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../components/Confirmation";
import { ToolTipForDeleteButton } from "../../components/ToolTipForDelete";
import { CustomTopToolbar } from "../../components/CustomTopToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.demandOperation) {
    errors.demandOperation = "plan board group is required";
  }
  if (!values.planBoardLine) {
    errors.planBoardLine = "Plan Board Line is required";
  }
  if (!values.lineBucket) {
    errors.lineBucket = "Line Bucket  is required";
  }
  if (!values.planBoardVersion) {
    errors.planBoardVersion = "Plan Board Version board line is required";
  }
  if (!values.configuration) {
    errors.configuration = "Configuration is required";
  }
  if (!values.start_time) {
    errors.start_time = "start time is required";
  }

  if (!values.quantity) {
    errors.quantity = "Quantity is required";
  }
  return errors;
};

export const EditAllocation = (props) => {
  const Title = ({ record }) => {
    return <span>Edit Allocation | {record ? `${record.id} ` : ""}</span>;
  };
  const customToast = useCustomToast(props);
  return (
    <Edit
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
      actions={<CustomTopToolbar name="allocation" />}
      {...props}
      title={<Title />}
      class="col-md-8"
    >
      <SimpleForm
        toolbar={
          <ToolTipForDeleteButton name={"Click here to delete items"} props />
        }
        validate={validate}
      >
        <ReferenceInput
          label="Plan Board Group"
          source="demandOperation"
          reference="demandOp"
          label={<ReqInputField name="Plan Board Group" />}
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput
          label="Line Bucket"
          source="lineBucket"
          reference="lineBucket"
          label={<ReqInputField name="Line Bucket" />}
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        {/* since plan board version not require for edit allocation */}
        {/* <ReferenceInput
          label="Plan Board Version"
          source="planBoardVersion"
          reference="boardVersion"
        >
          <SelectInput optionText="id" />
        </ReferenceInput> */}
        <TextInput
          source="configuration"
          label={<ReqInputField name="Configuration" />}
        />
        <TextInput source="demand_operation" label="Demand Operation" />
        <TextInput source="total_smv" label="" />
        <TextInput source="completed_quantity" label="Completed Quantity" />
        <TextInput
          source="quantity"
          label={<ReqInputField name="Quantity" />}
        />
        <TextInput
          source="start_time"
          label="Start Time"
          label={<ReqInputField name="Start Time" />}
        />
        <TextInput
          source="end_time"
          label="End Time"
          label={<ReqInputField name="End Time" />}
        />
        <TextInput
          source="order_code"
          label="Order Code"
          label={<ReqInputField name="Order Code" />}
        />
      </SimpleForm>
    </Edit>
  );
};
