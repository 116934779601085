import React from "react";
import { BooleanInput, Edit, SimpleForm } from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

export const EditPlanBoardVersion = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Edit
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm rowClick="edit">
        <BooleanInput source="is_production" />
      </SimpleForm>
    </Edit>
  );
};
