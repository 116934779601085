import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { connect } from "react-redux";
import { format_to_date_time } from "../../lib/date_time";
import { clearSelection, requestDeleteAllocation } from "../../store/actions";
import { getEntity } from "../../store/selectors";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {},
});

function AllocationDetailed({ allocation, id, demand_operation, onClose }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const completedPercentage =
    (100 * allocation.completed_quantity) / allocation.quantity;

  const is_early =
    allocation.start_time <
    (demand_operation.rm_ready_date || demand_operation.required_time_min);
  const is_delayed = allocation.end_time > demand_operation.required_time_max;
  const is_config_change = !allocation.demand_operation_id;
  return (
    <>
      <ExpansionPanel defaultExpanded style={{ margin: 0 }}>
        <Tooltip
          style={{
            position: "absolute",
            right: 1,
            top: 1,
          }}
          title="Close"
        >
          <CloseIcon className="mt-1 mr-1" onClick={() => onClose()} />
          {/* <IconButton aria-label="close" onClick={() => onClose()}>
            <CancelPresentationIcon fontSize="large" />
          </IconButton> */}
        </Tooltip>

        {/* <IconButton aria-label="close" onClick={() => onClose()}>
            <CancelPresentationIcon fontSize="large" />
          </IconButton> */}
        <ExpansionPanelSummary
          style={{
            margin: 20,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="alloc-content"
          id="alloc"
        >
          <Typography>
            <b>Allocation Details</b>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            {is_config_change ? null : (
              <>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                ></Typography>
                <Typography color="secondary">
                  {!allocation.is_tools_availabile
                    ? "Tools/molds not available."
                    : is_early
                    ? "Allocation is planned before RM ready date."
                    : is_delayed
                    ? "Allocation is planned  after the need date."
                    : ""}
                </Typography>
                <Typography variant="h6" component="h5">
                  {allocation.quantity} of {demand_operation.item_code}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  Order: {demand_operation.order_code}
                </Typography>{" "}
                {/* <Card style={{ padding: 10 }} variant="outlined">
            </Card> */}
              </>
            )}

            <ListItemText
              style={{ fontSize: "11px" }}
              primary={`${allocation.configuration}`}
              secondary={`${format_to_date_time(
                allocation.start_time
              )} -  ${format_to_date_time(allocation.end_time)}`}
            />

            {is_config_change ? null : (
              <Typography variant="body2" component="p">
                {allocation.completed_quantity} of {allocation.quantity}{" "}
                completed ({completedPercentage}%)
                <LinearProgress
                  variant="determinate"
                  value={completedPercentage}
                />
              </Typography>
            )}
            {/* <List>
            <ListItem>
            </ListItem> */}
            {/* <ListItem>
                <Typography variant="body1" >
                  {format_to_date_time(allocation.start_time)} -  {format_to_date_time(allocation.end_time)}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" >
                  End:                </Typography>
              </ListItem>
              {is_config_change ? null : <><ListItem>
                <Typography variant="body1" >
                  smv: {allocation.total_smv} hours
              </Typography>
              </ListItem>
                <ListItem>
                  <Typography variant="body1" >
                    item smv: {allocation.item_operation_smv} hours
              </Typography>
                </ListItem>
              </> */}

            {/* </List> */}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}

const mapStateToProps = (state, props) => {
  const allocation = getEntity(state, "allocations", props.id);
  const demand_operation =
    getEntity(state, "demand_operations", allocation.demand_operation_id) || {};

  // const allocations = bucket.allocations.map(id => select_entity(state, "allocations", id))
  return {
    allocation,
    demand_operation,
    // allocations
  };
};
const mapDispatchToProps = {
  onDelete: requestDeleteAllocation,
  onClose: clearSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllocationDetailed);
