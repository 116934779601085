import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Loading, useDataProvider } from "react-admin";
import { showToastError, showToastSuccess } from '../../../../lib/toas';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ListHelper = ({
  data,
  handleClose,
  selectListData,
  setList

}) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [checked, setChecked] = useState([]);
  const [mark, setMarkAll] = useState(false);
  
  const handleToggle = (value) => {

    const newChecked = [...checked];

    if (value > -1) {
      if (newChecked.includes(value)) {
        const index = newChecked.indexOf(value);
        if (index > -1) {
          newChecked.splice(index, 1);
        }
      } else {
        newChecked.push(value);
      }

    }

    setChecked(newChecked);
  };

  const toggleAll = (value) => {
    setMarkAll(value);

    const newChecked = [];

    if(value){
      data.map((code, id) => {
        newChecked.push(id)
      });
    }

    setChecked(newChecked);

  };


  const handleSave = async () => {

    const filterData = data.filter((x, i) => {
      if (!checked.includes(i)) {
        return x
      }
    })
    
    try {

      var newArr = [].concat(...filterData);

      setList(newArr);

      showToastSuccess('Delete Success ')
      handleClose();

    } catch (error) {

      showToastError('Delete Failed ')
    }
  };

  return (
    <>
      <div>
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ alignItems: "flexStart", marginRight: "400px" }}>

            </div>
          </div>
          <div>
            <DialogContent>
              <List className={classes.root}>
                {data.map((code, id) => {

                  return (
                    <ListItem
                      key={code}
                      dense
                      button
                      onClick={() => handleToggle(id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.includes(id)}

                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={id}
                        primary={code}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </DialogContent>
          </div>
        </div>
        {/* )} */}

      </div>
      <div style={{ marginTop: "50px" }}>
        <DialogActions>
          <Button
            className="btn_ btn_secondry"
            onClick={() => toggleAll(!mark)}
            variant="contained"
            // color="primary"
            autoFocus
          >
            Select All
          </Button>
          <Button
            className="btn_ btn_delete"
            onClick={handleSave}
            variant="contained"
            color="primary"
            autoFocus
            disabled={checked.length === 0}
          >
            Delete
          </Button>

        </DialogActions>
      </div>
    </>
  );
};
