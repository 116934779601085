import React, { useState } from "react";

import FormUploadConfirmationDialog from "../FormUploadConfirmationDialog";
import Button from "@material-ui/core/Button";

 const OTB = ({toEdit, prevFormData, prevFormName, formName }) => {
  const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);
  // const [row, setrow] = React.useState(1);
  const [data, setData] = React.useState(toEdit ? prevFormData : {
    revNo: "",
    refNo: "",
    revDate: "",
    pNo: "",
    descriptionTable: [],
  });

  const onChange = (event, field) => {
    const type = event.target.type;

    const newFormData = data;
    if (type === "checkbox") {
      newFormData[field] = !data[field];
      setData(newFormData);
    } else {
      const value = event.target.value;

      newFormData[field] = value;
      setData(newFormData);
    }
  };

  const addRowTable = (inputProps) => {
  

    const setRowData = (newValue) => {
      setData((data) => ({ ...data, descriptionTable: newValue }));
    };
    const rowData = data.descriptionTable;
  
    const headers = [
      "DESIGN",
      "SEGMENT",
      "SIZE RANGE",
      "UPPER",
      "SOLE",
      "REMARKS",
    ];
  
    const onAddRowClick = () => {
      setRowData(rowData.concat(rowData.length + 1));
    };
  
  
    const removeRow = (row) => {
      const newRowData = rowData;
      rowData[row] = false;
      setRowData(newRowData);
    };
  
    return (
      <>
        <tr>
          {headers.map((header, index) => (
            <th class='form-th'>{header}</th>
          ))}
        </tr>
        {rowData.map(
          (row, index) =>
            row && (
              <tr>
                {headers.map((header) => (
                  <td class='form-td' >
                    <input class='form-input' {...inputProps(`${row}${header}`)} required={true}></input>
                  </td>
                ))}
                <td style={{border: 'none !important'}}>
                  <button
                    onClick={() => removeRow(index)}
                    style={{
                      borderRadius: 5,
                      borderColor: "transparent",
                      margin: 0,
                    }}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            )
        )}
        <tr>
          <td style={{border: 'none !important'}} colspan={headers.length} >
            <button
              onClick={onAddRowClick}
              style={{
                borderRadius: 5,
                borderColor: "transparent",
                margin: 0,
              }}
            >
              Add Row
            </button>
          </td>
        </tr>
      </>
    );
  };

  const uiProps = (id) => {
    const props = {
      required: false,
      defaultValue: data[id],
      onChange: (e) => {
        onChange(e, id);
      },
    };

    if (String(id).toLowerCase().includes("date")) {
      props["type"] = "date";
    }

    return props;
  };

  const exportProps = (field) => {
    return {value: data[field]}
  }

  const Form = ({inputProps}) => {

  
    return (
      <div>
        <table class='form-table' style={{ width: "100%" }} border={1} cellPadding={10}>
          <tbody>
            <tr>
              <td class='form-td'  style={{ width: "33%", height: "20%" }}>
                <image alt="logo" />
                DSI
              </td>
              <td class='form-td'  style={{ width: "33%", height: "20%" }}>
                <p><br/></p>
                SAMPLE REQUISITIONS FORM FOR OTHER BRAND ITEMS
                <p><br/></p>
              </td>
              <td class='form-td'  style={{ width: "33%", height: "20%" }}>
                <p>SEC.NO PCM-FORM -1 </p>
                <tr>
                  <td class='form-td' >
                    
                    <p>REV.NO</p>
                  </td>
                  <td class='form-td' >
                    <input class='form-input'
                      type="text"
                      name="revNo"
                      {...inputProps('revNo')} 
                    />
                  </td>
                </tr>
                <tr>
                  <td class='form-td' >
                    
                    <p>REV. DATE</p>
                  </td>
                  <td class='form-td' >
                    
                    <input class='form-input'
                      type="date"
                      name="revDate"
                      {...inputProps('revDate')} 
                    />
                  </td>
                </tr>
                <tr>
                  
                  <td class='form-td' >
                    <p>PAGE NO</p>
                  </td>
                  <td class='form-td' >
                    
                    <input class='form-input'
                      type="text"
                      name="pNo"
                      {...inputProps('pNo')} 
                    />
                  </td>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ margin: 20 }}>
          <h5>Details</h5>
          <p>
            1. Buyer :<input class='form-input' type="text" {...inputProps('buyer')}  name="buyer" required  />
          </p>
          <p>
            2.Received Date :<input class='form-input' type="date" {...inputProps('receivedDate')} name="receivedDate" required/>
          </p>
          <div style={{ display: "flex" }}>
            <p style={{ paddingRight: 20 }}>
              3. Target Completion date :<input class='form-input' type="date" {...inputProps('targetCompletionDate')} name="targetCompletionDate" required/>
            </p>
            <p>
              5. Form reference No :<input class='form-input' type="text" {...inputProps('FormRef')} name="FormRef" required />
            </p>
          </div>
        </div>
        <table>
          {addRowTable(inputProps)}
        </table>
      </div>
    );
  };
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      setOpenFormUploadDialog(true);
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    event.stopPropagation();
  };
  



  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Form inputProps={uiProps}/>
        <Button variant="contained" type="submit" style={{marginBlockStart: 30}}>
          Submit
        </Button>
      </form>
      <FormUploadConfirmationDialog
        isOpen={openFormUploadDialog}
        setIsOpen={setOpenFormUploadDialog}
        Form={Form({inputProps: exportProps})}
        formData={data}
        prevFormName={prevFormName}
        formName={formName}
      />
    </div>
  );
};



 export default OTB;