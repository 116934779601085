import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import RevisionList from "./RevisionList";
import { usePermissions } from "react-admin";

export default function RevisionListPopup({
  planBoardId,
  open = false,
  onClose,
}) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <RevisionList planBoardId={planBoardId} />
    </Drawer>
  );
}

export const RevisionListPopupButton = React.memo(
  ({ planBoardId, selectedRevisionId }) => {
    const { permissions } = usePermissions();
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <Button
          className="btn_ btn_primary"
          variant=""
          onClick={() => setOpen(true)}
          title="Click here to view history"
          disabled={!permissions?.permissions.includes("plan_viewHistory")}
        >
          History
        </Button>
        <RevisionListPopup
          open={open}
          onClose={() => setOpen(false)}
          planBoardId={planBoardId}
        />
      </>
    );
  }
);

RevisionListPopupButton.type.displayName = "RevisionListPopupButton";
