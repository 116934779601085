import '../../layout/styles/input.css';

import { Popover } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenWithSharpIcon from '@material-ui/icons/OpenWithSharp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { capitalizeFirstLetter } from '../../lib/common';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { format_to_date_time } from '../../lib/date_time';
import { loadNotifications, readOneNotification } from '../../store/actions/notificationActions';
import { loadTNANotifications, markNotificationRead } from '../../store/actions/tnaNotificationActions';
import { getAllNotifications } from '../../store/selectors/notificationSelectors';
import { getTNANotifications } from '../../store/selectors/tnaNotificationSelectors';

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  notificationBackground: {
    backgroundColor: 'rgba(255,255,255, 0.25)',
    borderRadius: 50,
    height: 35,
    width: 35,
    margin: 5
  }

};

const NotificationDialog = withStyles(styles)(
  ({ allNotifications, mode, onLoad, onReadOne, onTNALoad, markRead }) => {
    useEffect(() => {
      onLoad()
      onTNALoad()
    }, []);

    const [anchorEl, setAnchorEl] = useState(null);
    const [showRead, setShowRead] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [panelOpen, setPanelOpen] = useState(false);

    if (!allNotifications) {
      return null;
    }

    const unReadNotifications = allNotifications.filter((n) => !n.is_read);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const notificationPageRedirect = () => {
      handleClose()
      window.location.href = '#/notification'
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;


    function sort_by_time(b, a) {
      return (
        new Date(a.time_stamp).getTime() - new Date(b.time_stamp).getTime()
      );
    }

    //   const handleClickOpen = () => {
    //     setOpen(true);
    //   };

    //   const handleClose = () => {
    //     setOpen(false);
    //   };

    // const selected = selectedRows[0];
    const listedNotifications = allNotifications
      .filter((n) => showRead || !n.is_read)
      .sort(sort_by_time);

    const onViewClick = (notification) => {
      if (["NEED_APPROVAL", "APPROVAL_ACCEPTED", "QR_ACCEPTED", "TASK_RECEIVED"].includes(notification.notify_type)) {
        const pathMap = {
          "NEED_APPROVAL": ["/approve_tasks", "Show"],
          "APPROVAL_ACCEPTED": ["", "Read"],
          "QR_ACCEPTED": ["", "Read"],
          "TASK_RECEIVED": ["/processing", "Show"]
        }
        if (!notification.is_read) {
          markRead(notification.id)
        }
        window.location = `#${pathMap[notification.notify_type][0]}`;
      } else {
        if (!notification.is_read) {
          onReadOne(notification.id);
        }
        if (notification.notify_type.type == "transfer") {
          if (notification.notify_type.status == "pending") {
            window.location = `#/transfers/received/${notification.plant_id}`;
          } else if (notification.notify_type.status == "accepted") {
            window.location = `#/transfers/transferred/${notification.plant_id}`;
          } else if (notification.notify_type.status == "declined") {
            window.location = `#/transfers/transferred/${notification.plant_id}`;
          }
        }

        handleClose();
        onLoad()
      }
    };

    return (
      <>
        <Badge
          badgeContent={unReadNotifications ? unReadNotifications.length : 0}
          color="error"
          style={{ marginTop: 2, fontSize: "14px" }}
          overlap="circle"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Tooltip title="Notification">
            <IconButton
              fontSize="small"
              onClick={handleClick}
              aria-label="back"
              style={{ backgroundColor: 'rgba(255,255,255, 0.25)', borderRadius: 50, height: 35, width: 35, margin: 5 }}
            >
              <NotificationsIcon style={{ color: "#ffffff" }} />
            </IconButton>
          </Tooltip>
        </Badge>
        <Popover
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ minWidth: 200 }}
        >
          <Card style={{ padding: 3, backgroundColor: "primary" }}>
            <Box display="flex">
              <Grid item md={5}>
                {/* <Typography style={{padding: '5px'}}>Transfer date</Typography>
                        <Typography style={{padding: '5px'}}>Received date</Typography> */}
                <Button style={{ padding: "5px", fontSize: "14px", }} onClick={notificationPageRedirect} className="btn-success">
                  <OpenWithSharpIcon style={{ paddingRight: "5px" }} />
                  Notifications
                </Button>
                {/* {data.plant === 'sub' ? <Typography style={{padding: '5px'}}>Sub contractor's name</Typography>: null} */}
              </Grid>
              <Grid item md={2}>
                {/* <IconButton
                style={{verticalAlign:"center", alignSelf:"center"}}
                  aria-label="delete"
                  onClick={() => {
                    setPanelOpen(open => !open);
                  }}
                >
                  <FilterListIcon />
                </IconButton> */}
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item md={5}>
                <Typography style={{ alignSelf: "right", textAlign: "end" }}>
                  Show read
                  <Switch
                    color="primary"
                    value={showRead}
                    onChange={(e) => setShowRead((val) => !val)}
                  ></Switch>
                </Typography>
              </Grid>
            </Box>
            {panelOpen && (
              <Grid item md={12}>
                <Card>test</Card>
              </Grid>
            )}
            {/* <Switch
        checked={state.checkedA}
        onChange={handleChange}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      /> */}
            <Divider />
            <Box style={{ minWidth: 400, maxHeight: 400, overflowY: "auto" }}>
              {" "}
              <List dense={true}>
                {listedNotifications.map((n, i) => (
                  <React.Fragment key={i}>
                    <ListItem style={{ minWidth: 400 }}>
                      {/* <Typography>{format_to_date_time(n.timestamp)}</Typography> */}

                      <ListItemText
                        style={{ padding: 2, minWidth: 100, maxWidth: 100, color: '#333' }}
                        primary={format_to_date_time(n.time_stamp).slice(-8)}
                        secondary={format_to_date_time(n.time_stamp).slice(
                          0,
                          10
                        )}
                      />
                      <Divider orientation="vertical" flexItem />
                      <ListItemText
                        style={{ paddingLeft: 10 }}
                        primary={
                          n.notify_type.type ?
                            (n.notify_type.type
                              ? capitalizeFirstLetter(n.notify_type.type)
                              : "no type") : (n.notify_type ? capitalizeFirstLetter(n.notify_type) : "no notify type")
                        }
                        secondary={n.details ? n.details.message : "no message"}
                      />
                      <ListItemSecondaryAction>
                        {!n.is_read ? (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              onViewClick(n);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              onViewClick(n);
                            }}
                          >
                            <DoneAllIcon />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Divider />
            <ListItem>
              <ListItemText secondary={format_to_date_time()} />
              <ListItemText
                style={{ textAlign: "end" }}
                secondary={`count: ${listedNotifications.length}(${showRead ? "all" : "unread"
                  })`}
              />
            </ListItem>
          </Card>
        </Popover>

        {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">{`Transfer Setup`}</DialogTitle>
          <DialogContent>
            <p>test</p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              style={{ padding: "10px" }}
            >
              close
            </Button>
            
            
          </DialogActions>
        </Dialog>
       */}
      </>
    );
  }
);
const mapStateToProps = (state) => {
  var allNotifications = getAllNotifications(state);
  var allTNANotifications = getTNANotifications(state);
  var mode = ""
  if (allNotifications == undefined) {
    allNotifications = []
    mode = "TNA_MODE"
  }
  if (allTNANotifications == undefined) {
    allTNANotifications = []
    mode = "SCHED_MODE"
  }
  allNotifications = allNotifications.concat(allTNANotifications)
  return {
    allNotifications, mode
  };
};
const mapDispatchToProps = {
  onLoad: loadNotifications,
  onTNALoad: loadTNANotifications,
  onReadOne: readOneNotification,
  markRead: markNotificationRead
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDialog);
