import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";
import { calendarAttributesSchema } from "./attributesSchema";

const validate = (values) => {
  const errors = {};
  if (!values.config) {
    errors.config = ["config is required"];
  }

  return errors;
};
export const EditCalendar = (props) => {
  const Title = ({ record }) => {
    return <span>Edit Calendar | {record ? `${record.name} ` : ""}</span>;
  };
  const customToast = useCustomToast(props);
  return (
    <Edit
      title={<Title />}
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm validate={validate}>
        {/* <JsonEditor source="config" /> */}
        <TextInput source="name" />
        <TextInput schema={calendarAttributesSchema} source="config" />
      </SimpleForm>
    </Edit>
  );
};
