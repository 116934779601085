import { makeStyles } from "@material-ui/core/styles";
import useWindowSize from "@rehooks/window-size";
import React from "react";
import {
  CardActions,
  Datagrid,
  DateInput,
  ExportButton,
  Filter,
  List,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  usePermissions
} from "react-admin";
import CustomDateField from "../../components/field/CustomDateField";
import { theme } from "../../layout/theme";
import {ChangeDiv} from "../../components/ChangeDiv"

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

export const ActivityLog = (props) => {
  const { innerHeight, innerWidth } = useWindowSize();
  const classes = useStyles();
  const { permissions } = usePermissions();

  const Actions = () => (
    <div className={classes.buttonContainer}>
      <CardActions>
        <ExportButton
          title="Click here to Export Activity Log"
          disabled={!permissions?.permissions.includes("activityLog_export")}
          className="btn_ btn_primary "
        />
      </CardActions>
    </div>
  );

  const ActivityLogFilters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="Search user" source="user" alwaysOn />
        <TextInput label="Search action" source="change_message" alwaysOn />
        <DateInput label="search by date" source="action_time" alwaysOn />
      </Filter>
    );
  };
  const Title = () => {
    return <span>Activity Log</span>;
  };

  const LimitedLengthTextField = ({ record = {}, source }) => {
    if (record[source].length < 100) {
      return <span>{record[source]}</span>;
    } else {
      const limited_str = record[source].slice(0, 100);
      return <span>{limited_str} ...</span>;
    }
  };

  return (
    <List
      {...props}
      filters={<ActivityLogFilters />}
      title={<Title />}
      actions={<Actions />}
      perPage={25}
      bulkActionButtons={false}
    > 
      <ChangeDiv>
        {props => {
          return<Datagrid
            className="funn"
            isRowSelectable={(record) => false}
            expand={ActivityLogShow}
          >
            <CustomDateField showTime source="action_time" />
            <TextField source="user" />
            {/* <TextField source="change_message" label="Action" /> */}
            <LimitedLengthTextField source="change_message" label="Action" />
        </Datagrid>}}
      </ChangeDiv>
    </List>
  );
};

const ActivityLogShow = (props) => {
  // title for expand view
  const name = `  #${props.id}` 
  
  return(<Show title={name}
  {...props} actions={null}>
    <SimpleShowLayout>
      <RichTextField source="change_message" label="Action" />
    </SimpleShowLayout>
  </Show>
);
}
