import { REFRESH_VIEW } from "react-admin";
import { put, select, takeEvery } from "redux-saga/effects";
import dataProvider from "../../api/dataProvider";
import { loadData, loadDataSuccess, setData, setCurrentPlant } from "../actions/transferActions";
import { LOAD_DATA, REVERT_TRANSFER, SETUP_RECEIVED, SETUP_TRANSFER } from "../types/transferTypes";
import moment from 'moment'

// import { loadPlanBoardSuccess, clearPendingAction } from '../planboardReducer/actions';

export function* transferSaga() {
  yield takeEvery(LOAD_DATA, function*( action: any ) {
    
    const data = action.payload
    const userPlant = yield localStorage.getItem('plant')

    if(data.plant) {
      console.log("1")
      const resp = yield dataProvider.CUSTOM("transfer", { id: 1, action: 'get_data_new', method: "POST", body: data })
      yield put(setData(resp.data))
      yield put(loadDataSuccess())
      
    }
    else if(userPlant && userPlant !== 'null' && userPlant !== 'undefined') {
      console.log("2")
      yield put(setCurrentPlant(parseInt(userPlant)))
    }
    else {
      console.log("3")
      yield put(setCurrentPlant('all'))
    }

  });

  yield takeEvery(SETUP_TRANSFER, function*(action: any) {
      const { data } = action.payload
      const resp = yield dataProvider.CUSTOM("transfer", { id: 1, body: data, action: 'setup_transfer', method: "POST" })
      const currentPlant = yield select(
        (s: any) => s.custom.transfers.entities.currentPlant
      );
      yield put(loadData(currentPlant))
  });

  yield takeEvery(REVERT_TRANSFER, function* (action: any) {
    const { data } = action.payload
    const resp = yield dataProvider.CUSTOM("planBoards", {id:1 , body: data, action: "revert_transfer", method: "POST"})
    const currentPlant = yield select(
      (s: any) => s.custom.transfers.entities.currentPlant
    );
    yield put(loadData(currentPlant))
  })

  yield takeEvery(SETUP_RECEIVED, function* (action: any) {
    const { data } = action.payload
    const resp = yield dataProvider.CUSTOM("transfer", { id: 1, body: data, action: "setup_received", method: "POST"})
    const currentPlant = yield select(
      (s: any) => s.custom.transfers.entities.currentPlant
    );
    yield put(loadData(currentPlant))
  })

  yield takeEvery(REFRESH_VIEW, function* () {
  const path = yield select((s: any) => s.router.location.pathname)  
    if (path.search("/transfers") !== -1) {
      const currentPlant = yield select(
      (s: any) => s.custom.transfers.entities.currentPlant
    );
      yield put(loadData(currentPlant))
    }
  })
  
  
  
}

