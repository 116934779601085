import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import NavigationIcon from "@material-ui/icons/Navigation";
import React from "react";
import { Link } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    //  color:'#FFFFFF',
  },
}));
const ClosingNavigationButton = React.memo(({ handleClose, url }) => {
  const classes = useStyles();
  //  console.log("url", url);
  return (
    <div className={classes.root}>
      <Fab
        variant="extended"
        className="MuiButton-textPrimary"
        onClick={handleClose}
        color="primary"
        style={{color:'#FFFFFF', borderRadius: '4px', padding: '8px 15px',}}
        component={Link}
        to={{
          // pathname:  `/planBoard/${url}`,
          pathname: url ? `/planBoard/${url}` : `/planBoards`,
        }}
        //  disabled={url ? false : true}
        //   pathname: `/planBoard/${planBoardLink}`,
      >
        <NavigationIcon  className={classes.extendedIcon} />
        Smart Navigate
      </Fab>
    </div>
  );
});
export default ClosingNavigationButton;
