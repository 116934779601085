import React from "react";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.itemOperation) {
    errors.itemOperation = ["item operation is required"];
  }
  if (!values.planBoardLine) {
    errors.planBoardLine = ["Plan Board Line is required"];
  }
  if (!values._type) {
    errors._type = ["_type is required"];
  }
  if (!values.smv) {
    errors.smv = ["smv is required"];
  }
  if (!values.priority) {
    errors.priority = ["priority is required"];
  }

  return errors;
};

export const EditPlanBoardLineCompatibility = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Edit
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm validate={validate} rowClick="edit">
        <ReferenceInput
          label="Item operation"
          source="itemOperation"
          reference="itemOperations"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Plan Board Line"
          source="planBoardLine"
          reference="planBoardLines"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <TextInput source="performance_multiplier" />
        <TextInput source="priority" />
      </SimpleForm>
    </Edit>
  );
};
