import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import React from "react";
import {
  Datagrid,
  EditButton,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
} from "react-admin";
import { Link } from "react-router-dom";
import Confirmation from "../../components/Confirmation";

export const ShowSubContract = (props) => {
  const { permissions } = usePermissions();

  const CreateLinesButtons = ({ record }) => {
    return (
      <>
        <CreateLineWithEfficiencyButton record={record} />
        <CreateLineWithPerdayQuantityButton record={record} />
      </>
    );
  };

  const CreateLineWithEfficiencyButton = ({ record }) => (
    <Button
      disabled={!permissions?.permissions.includes("create_efficiency_line")}
      style={{ height: "auto", width: "45%", margin: "10px 2.5%" }}
      // color="primary"
      className="btn_ btn_primary"
      variant="contained"
      component={Link}
      to={{
        pathname: "/boardLine/create",
        state: {
          record: {
            sub_contract: record.id,
            code: record.code,
            withDailyQuantity: false,
          },
        },
      }}
    >
      Create line/lines to this sub contractor (with efficiency and carder)
    </Button>
  );

  const CreateLineWithPerdayQuantityButton = ({ record }) => (
    <Button
      disabled={!permissions?.permissions.includes("create_dailyquantity_line")}
      style={{ height: "auto", width: "45%", margin: "10px 2.5%" }}
      // color="primary"
      className="btn_ btn_primary"
      variant="contained"
      component={Link}
      to={{
        pathname: "/boardLine/create",
        state: {
          record: {
            sub_contract: record.id,
            code: record.code,
            withDailyQuantity: true,
          },
        },
      }}
    >
      Create line/lines to this sub contractor (with daily quantity)
    </Button>
  );

  const Title = ({ record }) => {
    return <span>Sub Contractor | {record ? `${record.code} ` : ""}</span>;
  };
  const redirect = () => redirect(-1); 

  return (
    <Show redirect={redirect} title={<Title />} {...props}>
      <SimpleShowLayout >
        <TextField
          source="id"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="code"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="name"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="phone_number"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="address"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        {["ADMIN", "SITE"].includes(permissions?.access_level) ? (
          <ReferenceField
            label="Plant"
            reference="plants"
            source="plant"
            link=""
            className="col-xl-4 col-sm-12 sub_con_lab mb-3"
          >
            <TextField
             label="Code"
              source="code"
              className="col-xl-4 col-sm-12 sub_con_lab mb-3"
            />
          </ReferenceField>
        ) : null}

        <Divider style={{ margin: "10px" }} />

        <CreateLinesButtons />

        <Divider style={{ margin: "10px" }} />
        <ReferenceManyField
          label="Lines"
          reference="boardLine"
          target="line"
          filter={{ sub_contract: props.id }}
        >
          <Datagrid>
            <TextField source="id" />

           <ReferenceField
              link=""
              label="Plan board code"
              reference="planBoards"
              source="plan_board"
            >
              <TextField source="code" />
            </ReferenceField>
            <TextField source="code" />
            <TextField source="initial_configuration" />
            <TextField source="calendar" />
            <TextField source="efficiency" />
            <TextField source="daily_quantity" />
            <TextField source="carder" />
            <EditButton className="btn_ btn_edit" />
            <Confirmation
              redirect={false}
              text="plan board line"
              title="Click here to Delete"
              disabled={
                !permissions?.permissions.includes("linesHeads")
              }
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
