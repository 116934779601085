import { FormControlLabel, styled, Switch } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { stringify } from "query-string";
import React, { useMemo, useState } from "react";
import { Link } from "react-admin";
import { connect, useDispatch } from "react-redux";
import {
  createFilter,
  Html5Table,
  useDebouncedState,
  useFilter,
  WindowTable,
} from "window-table";
import { getColorFromConfiguration } from "../../lib/color";
import { exportCsv } from "../../lib/csvExport";
import { format_to_date } from "../../lib/date_time";
import { getPlanBoardIdFromUrl } from "../../lib/getPlanBordIdFromUrl";
import { getDemandOperationStatusColor } from "../../lib/plan_board/utils";
import { selectEntity } from "../../store/actions";
import { getColorMappings, getDemandOperations } from "../../store/selectors";
import { usePermissions } from "react-admin";

const filterFn = createFilter(["code", "itemCode", "configPattern"]);

function DemandOperationGroupTable({
  data,
  columns,
  open = false,
}: {
  data: any;
  columns: any;
  open?: boolean;
}) {
  const [text, debouncedText, setText] = useDebouncedState("");
  const filteredDataBySearch = useFilter(filterFn, data, debouncedText);

  const [sortColumn, setSortColumn] = useState("needDate");
  const [sortDirection, setSortDirection] = useState(1);
  const [hideCompletedOrders, setHideCompletedOrders] = useState(false);
  const [hideClosedOrders, setHideClosedOrders] = useState(false);

  //   get currunt palanBoard id
  const planBoardId = getPlanBoardIdFromUrl();

  // Filter only incomplete jobs, if switch is on
  const completedOrderFilteredData = hideCompletedOrders
    ? filteredDataBySearch.filter((v: any) => v.total !== v.totalPlanned)
    : filteredDataBySearch;

  //closed orders filter
  const filteredData = hideClosedOrders
    ? completedOrderFilteredData.filter((v: any) => !v.isClosed)
    : completedOrderFilteredData;

  const onClickSwitch = () => setHideCompletedOrders((v) => !v);
  const onClickHideClosedOoders = () => setHideClosedOrders((v) => !v);

  /**
   * Here we use a useMemo as an optional optimization
   * So,when we click the header more than once, nothing happens
   * Depending on your requirement, this may not be the preferred way
   */
  const sorted = useMemo(() => {
    /**
     * Optional optimization
     * If we are not sorting, just return the original data
     */
    if (!sortColumn) {
      return filteredData;
    }
    /**
     * Note that we are using the mapSort library to do the sorting,
     * But that's completely optional.
     * Use whatever cool library you prefer
     *
     * However, it is important to use a sort function which
     * does not sort in place.
     */
     const order = sortDirection == 1 ? 'asc' : 'desc'
    return _.orderBy(filteredData, [sortColumn], [order])
}, [sortColumn, sortDirection, filteredData]);


  /**
   * Here we define a custom Header Cell
   * And take control of the onClick action of the header
   */
  const Heading = React.forwardRef((props: any, ref) => {
    return (
      <th
        {...props}
        style={{ ...props.style, cursor: "pointer" }}
        onClick={() => {
          // Set the clicked column's key as the sort column
          setSortDirection((v) => v * -1);
          setSortColumn(props.column.key);
        }}
      />
    );
  });

  const onExport = () => {
    const export_data = _.map(sorted, (record: any) => {
      const d = {} as any;
      const exportColumns = [
        {
          key: "orderReleaseNumber",
          title: "Release number",
        },
        ...columns,
        {
          key: "operationCode",
          title: "Operation code",
        },
      ];
      exportColumns.forEach((col: any) => {
        let value = record[col.key];
        let key = col.title;
        if (!isNaN(col.key)) {
          value = value ? value.quantity : "";
          key = `Size ${col.title}`;
        } else if (
          [
            "needDate",
            "rmReadyDate",
            "plannedStartDate",
            "plannedEndDate",
          ].indexOf(col.key) >= 0
        ) {
          value = format_to_date(value);
        }
        d[key] = value;
      });
      return d;
    });
    exportCsv(export_data, "orders-grouped.csv");
  };

  const { permissions } = usePermissions();

  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          textAlign: "right",
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            style={{ marginRight: 20 }}
            onClick={onExport}
            // color="primary"
            className="btn_ btn_primary mb-0 mr-2"
            // variant="outlined"
            title="Click here to Export"
            disabled={
              !permissions?.permissions.includes("plan_orderGroupTable_export")
            }
          >
            {" "}
            Export{" "}
          </Button>
          <Link
            to={{
              pathname: `/closedOrderGroup`,

              search: stringify({
                page: 1,
                perPage: 25,
                filter: JSON.stringify({
                  plan_board: planBoardId,
                  is_closed: true,
                  planned_quantity: 1,
                }),
              }),
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              // color="primary"
              // className="long-btn mb-0"
              className="btn_ btn_primary mb-0 mr-5"
              title="Click here to  Show closed orders"
            >
              Show closed orders
            </Button>
          </Link>
        </div>

        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <FormControlLabel
            className="ml-2"
            control={
              <Switch
                checked={hideClosedOrders}
                onChange={onClickHideClosedOoders}
                name="checkedB"
                color="primary"
              />
            }
            label="Hide closed order groups"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideCompletedOrders}
                onChange={onClickSwitch}
                name="checkedB"
                color="primary"
              />
            }
            label="Hide Completely Planned Jobs"
          />
          <div style={{ width: "50%" }}>
            <TextField
              onChange={(e) => setText(e.target.value)}
              value={text}
              id="standard-basic"
              variant="outlined"
              label="Search"
              style={{ marginRight: 20 }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "500px",
          backgroundColor: "#fff7fb",
          display: "box",
          overflow: "hidden",
        }}
      >
        {open && (
          <Html5Table
            data={sorted}
            columns={columns}
            style={{ height: "480px", width: "98%" }}
            // className="table-sm"
            // rowClassName={(index: number) =>
            //   index % 2 === 0 ? "even-row" : "odd-row"
            // }
            headerClassName="thead-dark"
            Row={Row}
            HeaderCell={Heading}
            Cell={CustomCell}
          />
        )}
      </div>
    </div>
  );
}

const CustomCell = (props: any) => {
  const { column } = props;
  const { width } = column;
  return (
    <div
      {...props}
      className={props.className}
      style={{
        ...props.style,
        overflow: "visible",
        boxSizing: "border-box",
        display: "table-cell",
        flexGrow: width,
      }}
    />
  );
};

const SizeCellRenderer = (props: any) => {
  const { children, row, column } = props;
  const dispatch = useDispatch();
  const entry = row[column.key];
  const onClick = () => {
    console.log("Dispatch", entry.id, entry);
    dispatch(selectEntity("demand_operations", entry.id));
  };

  if (!entry) {
    return null;
  }
  const backgroundColor =  entry.isClosed ? 'gray':
    getDemandOperationStatusColor(
      entry.quantity,
      entry.planned_quantity,
      entry.completed_quantity
    );

  if (
    (entry.planned_quantity &&
      entry.completed_quantity == entry.planned_quantity) ||
    entry.completed_quantity == 0
  ) {
    return (
      <Button
        style={{
          borderRadius: 15,
          backgroundColor,
        }}
        onClick={onClick}
      >
        {entry.quantity}
      </Button>
    );
  } else {
    return (
      <Button
        style={{
          padding: 5,
          borderRadius: 5,
          backgroundColor,
        }}
        onClick={onClick}
      >
        <sup>{entry.completed_quantity}</sup>/<sub>{entry.quantity}</sub>
      </Button>
    );
  }
};

const ConfigCellRenderer = (props: any) => {
  const { children, row, column } = props;
  const configuration = row[column.key];
  const colorMappings = column.colorMappings;
  const backgroundColor = getColorFromConfiguration(
    colorMappings,
    configuration
  );
  return (
    <div style={{ backgroundColor, borderRadius: 5, textAlign: "center" }}>
      {configuration}
    </div>
  );
};

const TotalRenderer = (props: any) => {
  const { children, row, column } = props;
  const { totalPlanned, total } = row;
  const backgroundColor = total == totalPlanned ? "lightgreen" : undefined;
  return (
    <div style={{ backgroundColor, borderRadius: 5, textAlign: "center" }}>
      {total}
    </div>
  );
};

const RmDateCellRenderer = (props: any) => {
  const { row, column } = props;
  const value = row[column.key];
  const backgroundColor = row.previouseOperationsPlanned
    ? "lightgreen"
    : undefined;
  return (
    <div style={{ backgroundColor, borderRadius: 5, textAlign: "center" }}>
      {format_to_date(value)}
    </div>
  );
};

const DateComponent = (props: any) => {
  const { row, column } = props;
  return <div>{format_to_date(row[column.key])}</div>;
};

const Row = (props: any) => {
  const backgroundColor = props.row?.isClosed ? "#888888" : " ";
  return (
    <tr
      {...props}
      style={{
        ...props.style,
        borderBottom: "0.1px solid #222222",
        backgroundColor,
      }}
    />
  );
};

const mapStateToProps = (state: any, props: any) => {
  const colorMappings = getColorMappings(state);
  const demandOperations = getDemandOperations(state);
  const groups = _.groupBy(
    demandOperations,
    (entry) => `${entry.order_group_code}---${entry.operation_code}`
  );
  const allSizes = new Set();
  const getSizeCode = (orderCode: string) => {
    const value = parseInt(orderCode.substr(8, 2));
    return value >= 35 ? value - 34 : value;
  };
  const data = Object.keys(groups)
    .map((groupCode) => {
      const dOps = groups[groupCode];
      const [code, operationCode] = groupCode.split("---");
      const total = _.sum(dOps.map((entry) => entry.quantity));
      const totalPlanned = _.sum(dOps.map((entry) => entry.planned_quantity));
      const itemCode = dOps[0].item_code.slice(0, 6);
      const needDate = dOps[0].required_time_max;
      const orderReleaseNumber = dOps[0].order_release_number;
      const configPattern = dOps[0].configuration
        .split(":")
        .slice(0, 2)
        .join(":");
      const rmReadyDate =
        _.max(
          dOps.filter((v) => !!v.rm_ready_date).map((v) => v.rm_ready_date)
        ) || "";
      const plannedStartDate =
        _.min(dOps.map((entry) => entry.planned_start_date)) || "";
      const plannedEndDate =
        _.max(dOps.map((entry) => entry.planned_end_date)) || "";
      const sizeObject = {} as any;
      const isClosed = dOps.every((d) => d.order_is_closed);
      let remain_quantites_in_previous_quantities = 0;
      dOps.forEach((entry) => {
        const sizeCode = getSizeCode(entry.order_code);
        sizeObject[sizeCode] = {
          quantity: entry.quantity,
          planned_quantity: entry.planned_quantity,
          completed_quantity: entry.completed_quantity,
          id: entry.id,
          isClosed: entry.order_is_closed,
          unplanned_quantity_in_previouse_operations:
            entry.unplanned_quantity_in_previouse_operations,
        };
        allSizes.add(sizeCode);
        remain_quantites_in_previous_quantities +=
          entry.unplanned_quantity_in_previouse_operations;
      });

      return {
        ...sizeObject,
        isClosed,
        total,
        totalPlanned,
        code,
        operationCode,
        itemCode,
        needDate,
        rmReadyDate,
        plannedStartDate,
        plannedEndDate,
        configPattern,
        orderReleaseNumber,
        previouseOperationsPlanned:
          remain_quantites_in_previous_quantities === 0,
      };
    })
    .filter((entry) => entry.total > 0);

  const sizeColumns = Array.from(allSizes)
    .sort((a: any, b: any) => parseInt(a) - parseInt(b))
    .map((size: any) => {
      return {
        key: size,
        title: `${size}`,
        width: 35,
        Component: SizeCellRenderer,
      };
    });

  const sortedData = _.sortBy(data, "needDateValue");
  return {
    data: sortedData,
    columns: [
      {
        key: "code",
        width: 150,
        title: "Order No",
      },
      // { key: 'operationCode', width: 80, title: 'Operation' },
      { key: "itemCode", width: 150, title: "Item No" },
      {
        key: "configPattern",
        width: 120,
        title: "Configuration",
        colorMappings,
        Component: ConfigCellRenderer,
      },
      {
        key: "needDate",
        width: 110,
        title: "Need Date",
        Component: DateComponent,
      },
      {
        key: "rmReadyDate",
        width: 110,
        title: "RM Ready",
        Component: RmDateCellRenderer,
      },
      {
        key: "plannedStartDate",
        width: 110,
        title: "Plan Start",
        Component: DateComponent,
      },
      {
        key: "plannedEndDate",
        width: 110,
        title: "Plan End",
        Component: DateComponent,
      },
      { key: "total", width: 100, title: "Quantity", Component: TotalRenderer },
      ...sizeColumns,
    ],
  };
};

export default connect(mapStateToProps)(DemandOperationGroupTable);
