import { Button, LinearProgress } from '@material-ui/core'
import React from 'react'
import { Datagrid, DateInput, Filter, List, ReferenceField, TextField } from 'react-admin'
import { RmActions } from './actionButtons'
import { RMInventoryRecordView } from './show'
import { BulkRMButtons } from './bulkAction'


const PercentageBarField = ({source, record={}}) => {
    const value = record[source]*100 >= 100 ? 100 : record[source]*100
    return <LinearProgress variant='determinate' value={value} style={{marginRight: 10}} />
}

const ViewButton = ({record={}, setViewRecord}) => {
    const handleClick = () => {
        setViewRecord(record)
    }
    return (
        <Button
            onClick={handleClick}
        >
            View
        </Button>
    )
}

const rmRowStyle = (record, index) => ({
    backgroundColor: (record.shortage > 0) ? 'yellow' : 'white',
});

const RMInventoryFilter = (props) => (
    <Filter {...props} >
        <DateInput label='Date' source='date' alwaysOn />
    </Filter>
)

export const MaterialInventory = (props) => {

    const [record, setRecord] = React.useState({
        item_code: '', 
        lead_time: '',
        safety_stock: '',
        inventory_status: 0,
        location_code: '' 
    })

    return (
        <>
            <List perPage={25} bulkActionButtons={<BulkRMButtons />} actions={<RmActions />} title='Material Inventory' filters={<RMInventoryFilter />} {...props} >
                <Datagrid rowStyle={rmRowStyle} >
                    <ReferenceField label='Item' source='item' reference='items'>
                        <TextField source='code' />
                    </ReferenceField>
                    <ReferenceField label='location' source='location' reference='materialStore'>
                        <TextField source='code' />
                    </ReferenceField>
                    <PercentageBarField source='inventory_status' />
                    <TextField source='lead_time' />
                    <TextField source='cost' />
                    <TextField source='forecast' />
                    <TextField source='availability' />
                    <TextField source='demands_quantity' />
                    <TextField source='safety_stock' />
                    <TextField source='shortage' />
                    <ViewButton setViewRecord={setRecord} />
                    {/* <EditButton /> */}
                </Datagrid>
            </List>
            <RMInventoryRecordView record={record} />
        </>
    )
}

export const MaterialRequests = (props) => {

    return (
        <div>
        <List title='Material Requests' filters={<RMInventoryFilter />} {...props} >
            <Datagrid rowStyle={rmRowStyle} >
                <TextField source='request_type' />
                <TextField source='request_time' />
                <TextField source='accept_time' />
                <TextField source='status' />
                <TextField source='need_date' />
                <TextField source='quantity' />
                <TextField source='plant_id' />
                <TextField source='location' />
            </Datagrid>
        </List>
        </div>
    )

}
