
import React from 'react'
import FormUploadButton, { formatForm, formStyles } from "./UploadPdfFormButton.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from '@material-ui/core/Button'

const FormUploadConfirmationDialog = ({ isOpen, setIsOpen, Form, formData, formName, prevFormName=false, appendParentCode = false }) => {
    // isOpen = true
    function handleCloseClick() {
      setIsOpen(false);
    }
    return (
      <Dialog
        fullWidth
        maxWidth="xl"
        open={isOpen}
        onClose={handleCloseClick}
        aria-label="Are you sure?"
      >
        <DialogTitle>{appendParentCode ? "Download Form as pdf" : "Upload Form as pdf"}</DialogTitle>
        <DialogContent>
          <div>Are you sure?</div>
        </DialogContent>
        <DialogActions>
          <FormUploadButton appendParentCode={appendParentCode} form={Form} formData={formData} prevFormName={prevFormName} formName={formName} setIsOpen={setIsOpen}/>
          <Button variant='contained' label="ra.action.cancel" onClick={handleCloseClick}>
            X
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  export default FormUploadConfirmationDialog