import React from "react";
import { BooleanInput, Create, SimpleForm, TextInput } from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";
import { redirect } from "../../lib/redirect";

const validate = (values) => {
  const errors = {};
  if (!values.code) {
    errors.code = ["Code is required"];
  }
  if (!values.unit) {
    errors.unit = ["Unit is required"];
  }

  return errors;
};
export const CreateItems = (props) => {
  const customToast = useCustomToast({ redirect: redirect });
  return (
    <Create
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm validate={validate}>
        <TextInput source="code" />
        <TextInput source="unit" />
        <BooleanInput source="is_rm" />
      </SimpleForm>
    </Create>
  );
};
