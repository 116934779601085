import React from "react";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  SelectArrayInput,
} from "react-admin";
import {
  SaveButton,
  DeleteButton,
  Toolbar,
  usePermissions,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../components/Confirmation";
import { PLAN_EXPORT_COLUMNS as plan_export_columns } from "../../constants";
import { format_to_date_for_api } from "../../lib/date_time";
import PlanExportColumnSelector from "./helperComponents/planExportColumnSelector";
import { tableview_fields } from "./Create";
import { CustomToolbar } from "../../components/CustomToolbar";
import { CustomTopToolbar } from "../../components/CustomTopToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.plant) {
    errors.plant = "Plant is required";
  }
  if (!values.calendar) {
    errors.calendar = "calendar is required";
  }
  if (!values.algorithm) {
    errors.algorithm = "algorithm is required";
  }
  if (!values.code) {
    errors.code = "code is required";
  }
  if (!values.export_smvunit) {
    errors.export_smvunit = "export smvunit is required";
  }
  if (!values.supported_group_selection_attribute) {
    errors.supported_group_selection_attribute =
      "export supported group selection attribute is required";
  }

  return errors;
};

const export_type_choices = [
  { id: "BASIC", name: "BASIC" },
  { id: "COMBINE_SIZES", name: "COMBINE_SIZES" },
  { id: "SCOM", name: "SCOM" },
  { id: "SCOM_DATEWISE", name: "SCOM_DATEWISE" },
];

const smv_units = [
  { id: "HOURS", name: "HOURS" },
  { id: "MINUTES", name: "MINUTES" },
];

export const EditPlanBoard = (props) => {
  const { permissions } = usePermissions();
  const customToast = useCustomToast(props);
  return (
    <Edit
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      actions={<CustomTopToolbar name="planboards" />}
      {...props}
      undoable={false}
      class="col-md-8"
    >
      <SimpleForm
        validate={validate}
        rowClick="edit"
        toolbar={<CustomToolbar name="planboards" text="Planboard" />}
      >
        <ReferenceInput
          label="plant"
          source="plant"
          reference="plants"
          label={<ReqInputField name="Plant" />}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="algorithm"
          label={<ReqInputField name="Algorithm" />}
        />
        <TextInput source="code" label={<ReqInputField name="Code" />} />

        <SelectInput
          label="export smv unit"
          source="export_smvunit"
          choices={smv_units}
          translateChoice={false}
          label={<ReqInputField name="Export Smv Unit" />}
        />
        <SelectArrayInput
          label="Table view default fields"
          source="tableview_attributes"
          choices={tableview_fields}
          translateChoice={false}
        />
        {/* test */}
        {/* <SelectArrayInput
          label="Table view default fields"
          source="tableview_attributes"
          choices={tableview_fields}
          translateChoice={false}
        /> */}

        <TextInput source="tool_type" label="tool type" />
        <TextInput source="tool_size" label="tool size" />
        <TextInput
          source="supported_group_selection_attribute"
          default="style"
          label={<ReqInputField name="Supported Group Selection Attribute" />}
        />
        <TextInput
          source="item_compound_selection_attribute"
          default="compound_code**"
          label={<ReqInputField name="Compound Code Selection Attribute" />}
        />
        <TextInput source="export_item_code_selection_attribute" default="" />
        <TextInput source="export_mold_selection_attribute" default="" />
        {/* 
            return(
            // <SelectArrayInput
            // {...rest}
            //   translateChoice={false}
            //   choices={
            //     formData.export_type === "SCOM"
            //       ? plan_export_columns.SCOM.filter(a => formData.plant_plan_export_columns.includes(a)).map((a) => ({ id: a, name: a }))
            //       : plan_export_columns.DSI.filter(a => formData.plant_plan_export_columns.includes(a)).map((a) => ({ id: a, name: a }))
            //   }
            //   source="plan_export_columns"
            // /> */}
        <SelectInput
          label="export type"
          source="export_type"
          choices={export_type_choices}
          translateChoice={false}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <PlanExportColumnSelector
              formData={formData}
              source="plan_export_columns"
              translateChoice={false}
              optionText="plan export columns"
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
