import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import ReactDataGrid from "react-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Datagrid,
  EditButton,
  ListView,
  NumberField,
  Pagination,
  Filter,
  ListBase,
  TextInput,
  ListToolbar,
  SingleFieldList,
  ReferenceField,
  ReferenceManyField,
  Show,
  DateField,
  SimpleShowLayout,
  TextField,
  usePermissions,
  FunctionField,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  labelFont: {
    fontSize: "30px",
    color: "#7777777",
    backgroundColor: "primary",
  },
}));

export default function SupportedGroupButton({
  action,
  meta,
  description = "Upload CSV File.",
  title,
  onSuccess,
  disabled,
  ...props
}) {
  const classes = useStyles();
  const { loading, data, exicuted } = useSelector(
    (state) => state.custom.bulkActions
  );
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    //   dispatch(bulkActionClean());
  }, [open]);

  const onOpen = () => setOpen(true);
  const onClose = () => {
    setOpen(false);
    //   dispatch(bulkActionClean());
    exicuted && onSuccess && onSuccess();
  };

  const TaskFilter = (props) => (
    <Filter {...props}>
      <TextInput
        label="Supported Group Code"
        source="supported_group"
        alwaysOn
      />
      <TextInput
        label="Supported Operation Group"
        source="supported_operation_groups"
        id='supported_operation_groups'
        alwaysOn
      />
    </Filter>
  );
  // const CustomPagination = (props) => (
  //   <Pagination
  //     perPage={10}
  //     rowsPerPageOptions={[100, 200, 500]}
  //     {...props}
  //   />
  // );

  const hasData = data.length > 1;
  // GET PLAN BOARD ID FROM URL
  const planBoardID = window.location.href.split("/")[5]
  
  return (
    <>
      <Button
        onClick={onOpen}
        disabled={disabled}
        title={description}
        className="btn_ btn_primary"
      >
        {" "}
        {title}{" "}
      </Button>

      <Dialog
        fullWidth="true"
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle>{title}</DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={onClose} />
            </div>
          </Grid>
        </Grid>
        <DialogContent>
          <Show {...props} hasEdit={false}>
            <SimpleShowLayout>
              <ReferenceManyField
                label="Planboard Lines"
                reference="boardLine"
                target="plan_board_group__plan_board_id"
                addLabel={false}
                filter={{ plan_board: planBoardID ? planBoardID : null }}
              >
                <ListBase {...props} >
                  <ListToolbar filters={<TaskFilter />} />
                  <Datagrid>
                    <TextField source="id" />
                    <TextField source="plan_board_group" />
                    <TextField source="code" />
                    <TextField source="initial_configuration" />
                    <TextField emptyText="N/A" source="supported_operation_groups" />
                    <TextField
                      source="attributes['supported_groups']"
                      label="supported groups"
                      emptyText="N/A"
                    />
                  </Datagrid>
                  <Pagination perPage={25} />
                </ListBase>
              </ReferenceManyField>
            </SimpleShowLayout>
          </Show>

          <DialogActions>

          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
