import { LOAD_ALL_TASKS, LOAD_UNASSIGNED_TASKS, SET_ALL_TASKS,SET_UNASSIGNED_TASKS, SET_TNA_TASK_LOAD_SUCCESS, SET_TASK_COMPLETE, COMPLETE_TASK, START_TASK, SET_TASK_STARTED, SET_TASK_TRANSFERRED_TO_APPROVAL, UPLOAD_FORM, SET_UPLOAD_FORM_SUCCESS, SET_UPDATED_TASK, QR_ACCEPTANCE } from "../types/tnaTaskTypes";




const initialState = {
    loading: null,
    formLoading: null,
    todoTasks: new Map(),
    processingTasks: new Map(),
    completedTasks: new Map(),
    unassignedTasks: new Map(),
    all: {},
    count: {},
    unassignedTaskCount:{},
};

function convertArrayToMap(array: any[]) {
    let dict = new Map()
    array.forEach(element => {
        dict.set(element['id'].toString(), element)
    });
    return dict

}


export const tnaTaskReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOAD_ALL_TASKS: {
            return { ...state, loading: true };
        }
        case LOAD_UNASSIGNED_TASKS: {
            return { ...state, loading: true };
        }
        case SET_ALL_TASKS: {
            const { data } = action.payload;
            const pendingArray = data.allTasks.pendingTasks
            const pendingMap = convertArrayToMap(pendingArray)
            const processingMap = convertArrayToMap(data.allTasks.processingTasks)
            const completedMap = convertArrayToMap(data.allTasks.completedTasks)

            return {
                ...state,
                todoTasks: pendingMap,
                processingTasks: processingMap,
                completedTasks: completedMap,
                count: {
                    toDoCount: data.allTasks.processingTasks.length,
                    pendingCount: data.allTasks.pendingTasks.length,
                    completedCount: data.allTasks.completedTasks.length
                }
            };
        }
        case SET_UNASSIGNED_TASKS: {
            const { data } = action.payload;
            const UnassignedArray = data.allUnassignedTasks.unAssignedTasks
            const unassignedMap = convertArrayToMap(UnassignedArray)
            return {
                ...state,
                unassignedTasks: unassignedMap,
                unassignedTaskCount: {
                    unassignedCount: data.allUnassignedTasks.unAssignedTasks.length

                }
            };
        }
        case SET_TNA_TASK_LOAD_SUCCESS: {
            return { ...state, loading: false };
        }
        case QR_ACCEPTANCE: {
            console.log("Task QR acceptance running...")
            console.log(action)
            return { ...state, loading: false };
        }
        case SET_TASK_COMPLETE: {
            console.log("Task complete saga running...")
            console.log(action)
            const completedTask = action.completedTask

            state.processingTasks.delete(completedTask.id.toString())
            state.completedTasks.set(completedTask.id.toString(), completedTask);
            window.location.href = "#/processing";
            return { ...state }



        }
        case SET_TASK_TRANSFERRED_TO_APPROVAL: {
            const transferredTask = action.transferredTask
            state.processingTasks.delete(transferredTask.id.toString())
            state.processingTasks.set(transferredTask.id.toString(), transferredTask)
            window.location.href = "#/processing";
            return { ...state }
        }
        case START_TASK: {
            return { ...state, loading: true };
        }
        case SET_TASK_STARTED: {
            const startedTask = action.startedTask

            state.processingTasks.delete(startedTask.id.toString())
            state.processingTasks.set(startedTask.id.toString(), startedTask);
            return { ...state };
        }
        case UPLOAD_FORM: {
            return { ...state, formLoading: true };
        }
        case SET_UPLOAD_FORM_SUCCESS: {
            return { ...state, formLoading: false };
        }
        case SET_UPDATED_TASK: {
            const updatedTask = action.updatedTask

            state.processingTasks.delete(updatedTask.id.toString())
            state.processingTasks.set(updatedTask.id.toString(), updatedTask);
            return { ...state };
        }
        default:
            return state;
    }
}