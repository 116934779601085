import { DELETE_HOLIDAY, DELETE_HOLIDAY_SUCCESS, DELETE_HOLIDAY_FAILED } from '../types/holidayTypes';
import moment from "moment";
import { format_to_date_for_api } from "../../lib/date_time";
import { CREATE_HOLIDAY, CREATE_HOLIDAY_FAILED, CREATE_HOLIDAY_SUCCESS, LOAD_HOLIDAYS, LOAD_HOLIDAYS_FAILED, LOAD_HOLIDAYS_SUCCESS, SET_DATE_RANGE, UPDATE_SHIFT_PATTERN, UPDATE_SHIFT_PATTERN_FAILED, UPDATE_SHIFT_PATTERN_SUCCESS, SET_SHIFT_PATTERNS } from "../types/holidayTypes";
import { CREATE_OPERATION_LOSS, CREATE_OPERATION_LOSS_FAILED, CREATE_OPERATION_LOSS_SUCCESS, DELETE_OPERATION_LOSS, DELETE_OPERATION_LOSS_FAILED, DELETE_OPERATION_LOSS_SUCCESS, LOAD_OPERATION_LOSSES, LOAD_OPERATION_LOSS_FAILED, LOAD_OPERATION_LOSS_SUCCESS } from '../types/operationLossTypes';

export const initialState = {
    date_range: {
        start_date: format_to_date_for_api(moment().startOf('month')),
        end_date: format_to_date_for_api(moment().endOf('month'))
    },
    operationLosses:[],
    loading: false,
    section: null
}

export const operationLossReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_DATE_RANGE: {
            const {date_range} = action.payload
            return {...state, date_range}
        }
        case LOAD_OPERATION_LOSSES: {
            const {section} = action.payload
            return {...state, loading: true, section: section}
        }
        case LOAD_OPERATION_LOSS_FAILED: {
            return {...state, loading: false}
        }
        case LOAD_OPERATION_LOSS_SUCCESS: {
            const {operationLoss} = action.payload
            return {...state, operationLoss, loading: false}
        }
        
        case CREATE_OPERATION_LOSS: {
            return {...state, loading: true}
        }
        case CREATE_OPERATION_LOSS_SUCCESS: {
            return {...state, loading: false}
        }
        case CREATE_OPERATION_LOSS_FAILED: {
            return {...state, loading: false}
        }
        case DELETE_OPERATION_LOSS: {
            return {...state, loading: true}
        }
        case DELETE_OPERATION_LOSS_FAILED: {
            return {...state, loading: false}
        }
        case DELETE_OPERATION_LOSS_SUCCESS: {
            return {...state, loading: false}
        }
        default:
            return state
    }
}