import { Button, Tooltip } from "@material-ui/core";
import OpenIcon from "@material-ui/icons/KeyboardReturn";
import React from "react";
import { Datagrid, List, TextField, BulkDeleteButton } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import CustomDateField from "../../../components/field/CustomDateField";
import { loadPlanBoardRevision } from "../../../store/actions";
import { Fragment } from "react";
import {
  getActiveRevisionId,
  getPublishedRevisionId,
} from "../../../store/selectors";

const PostBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} className="btn_ btn_delete m-0" />
  </Fragment>
);

export default function RevisionList({ planBoardId }) {
  const initProps = {
    basePath: "/",
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: "planRevision",
    perPage: 5,
  };

  const dispatch = useDispatch();

  const onApply = (id) => dispatch(loadPlanBoardRevision(id));
  const activeRevisionId = useSelector(getActiveRevisionId);
  const publishRevisionId = useSelector(getPublishedRevisionId);

  return (
    <>
      <List
        {...initProps}
        perPage={25}
        filter={{ plan_board_id: planBoardId }}
        exporter={false}
        bulkActionButtons={<PostBulkActionButtons className="m-0 " />}
      >
        <Datagrid>
          <CustomDateField source="time_stamp" showTime />
          <TextField source="title" />
          <ApplyButton
            activeRevisionId={activeRevisionId}
            publishRevisionId={publishRevisionId}
            onApply={onApply}
          />
        </Datagrid>
      </List>
    </>
  );
}

const ApplyButton = (props) => {
  if (!props.record) {
    return null
  }
  const { id, attributes, comments } = props.record;
  const isPublished = id === props.publishRevisionId;
  const isActive = id === props.activeRevisionId;

  const label = `${isActive ? "Opened" : "Open"}`;
  // const comments = Object.keys(attributes).map(key => `${key} : ${JSON.stringify(attributes[key])}`).join("\n") + " - "
  return (
    <Tooltip title={comments} aria-label="add">
      <Button
        className="btn_ btn_primary mb-0"
        variant="outlined"
        disabled={isActive}
        endIcon={isActive ? undefined : <OpenIcon />}
        onClick={() => props.onApply(props.record.id)}
        color="primary"
      >
        {label} {isPublished ? "(Published)" : ""}
      </Button>
    </Tooltip>
  );
};
