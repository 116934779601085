import { DELETE_HOLIDAY_SUCCESS, DELETE_HOLIDAY_FAILED } from './../types/holidayTypes';
import _ from "lodash";
import { REFRESH_VIEW } from "react-admin";
import { put, select, takeEvery } from "redux-saga/effects";
import dataProvider from "../../api/dataProvider";
import { format_to_date_for_api } from "../../lib/date_time";
import { showToastError, showToastSuccess } from "../../lib/toas";
import { loadHolidays, setHolidays, setShiftPatterns,setOperationLoss } from "../actions";
import { getDateRange } from "../selectors/holidaySelectors";
import {
  CREATE_HOLIDAY,
  CREATE_HOLIDAY_FAILED,
  CREATE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY,
  LOAD_HOLIDAYS,
  LOAD_HOLIDAYS_FAILED,
  UPDATE_SHIFT_PATTERN,
  UPDATE_SHIFT_PATTERN_FAILED,
  UPDATE_SHIFT_PATTERN_SUCCESS,
} from "../types/holidayTypes";
import { redirect } from "../../lib/redirect";

export function* holidaySaga() {
  yield takeEvery(LOAD_HOLIDAYS, function* (action: any) {
    const { section } = action.payload;
    const date_range = yield select(getDateRange);
    let node = section;
    let type = section;
    if (section && section.split("-").length == 2) {
      type = section.split("-")[0];
      node = section.split("-")[1];
    } else {
      type = "planboard";
    }
    try {
      const holidays = yield dataProvider.CUSTOM_LIST("calendar", {
        pagination: { page: 1, perPage: 1000000 },
        sort: { field: "id", order: "ASC" },
        action: "get_holidays_in_range",
        query: {
          start_date: format_to_date_for_api(date_range.start_date),
          end_date: format_to_date_for_api(date_range.end_date),
          type,
          node,
        },
      });
      yield put(setHolidays(holidays.data['holidays']));
      yield put(setShiftPatterns(holidays.data['shiftDetails']));
      yield put(setOperationLoss(holidays.data['operationLoss']));
    } catch (e) {
      yield put({ type: LOAD_HOLIDAYS_FAILED });
    }
  });

  yield takeEvery(DELETE_HOLIDAY, function* (action: any) {
    try {
      const { lines, holiday_id, section } = action.payload;
      yield dataProvider.CUSTOM_UPDATE("calendar", {
        body: {
          lines,
          holiday_id,
        },
        action: "del_holiday/",
      });
      showToastSuccess("Holiday deleted!");
      yield put ({type: DELETE_HOLIDAY_SUCCESS})
      yield put(loadHolidays(section));
    } catch (e) {
      yield put({type: DELETE_HOLIDAY_FAILED})
      showToastError("Holiday delete failed!");
    }
  });

  yield takeEvery(UPDATE_SHIFT_PATTERN, function* (action: any) {
    try {
      const { dates, lines, holidays, shifts } = action.payload;
      yield dataProvider.CUSTOM_UPDATE("calendar", {
        body: {
          start_date: dates[0],
          end_date: dates[1],
          lines,
          holidays,
          shifts,
        },
        action: "_change_calendars/",
      });
      yield put({ type: UPDATE_SHIFT_PATTERN_SUCCESS });
      showToastSuccess("Updated shift pattern successfully!");
      redirect();
      setTimeout(() => window.location.reload(), 1000);
    } catch (e) {
      yield put({ type: UPDATE_SHIFT_PATTERN_FAILED });
      showToastError("Shift pattern update failed!");
    }
  });

  yield takeEvery(CREATE_HOLIDAY, function* (action: any) {
    try {
      const { lines, holidays } = action.payload;
      yield dataProvider.CUSTOM_UPDATE("calendar", {
        body: {
          lines,
          holidays,
        },
        action: "_change_calendars/",
      });
      yield put({ type: CREATE_HOLIDAY_SUCCESS });
      showToastSuccess("Holiday created successfully!");
      redirect();
      setTimeout(() => window.location.reload(), 1000);
    } catch (e) {
      yield put({ type: CREATE_HOLIDAY_FAILED });
      showToastError("Holiday create failed!");
    }
  });

  yield takeEvery(REFRESH_VIEW, function* () {
    const path = yield select((s: any) => s.router.location.pathname);
    if (path === "/holidays") {
      const section = yield select((s: any) => s.custom.holidays.section);
      yield put(loadHolidays(section));
    }
  });
}

const groupEvents = (holidayEvents: any) => {
  const formattedEvents = [] as any[];
  let c = 0;
  let groupedByDate = _.groupBy(holidayEvents, (i) => [i.start, i.end]);
  _.map(groupedByDate, (e, startdate) => {
    let groupById = _.groupBy(e, (i) => i.holiday_id);
    let lines = [] as any[];
    _.map(groupById, (j, ji) => {
      let title = "";
      j.map((k) => {
        let line = { code: k.id.split("-")[0], id: k.Id };
        if (_.findLastIndex(lines, { id: line.id }) == -1) {
          lines.push(line);
        }
        title = k.title;
      });
      formattedEvents.push({
        id: ji,
        title,
        lines,
        start: new Date(startdate.split(",")[0]),
        end: new Date(startdate.split(",")[1]),
      });
    });
  });

  return formattedEvents;
};
