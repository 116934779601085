import React from "react";
import {
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required
} from "react-admin";

import { SaveButton, DeleteButton, Toolbar, usePermissions } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../../components/Confirmation";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { redirect } from "../../../lib/redirect";
//const redirect = (basePath, id, data) => `/planBoards/${data.plan_board}/show/tool`

export const DepartmentsEdit = (props) => {

  const Title = ({ record }) => {
    return <span>Edit Department | {record ? `${record.code} ` : ""}</span>;
  };

  const customToast = useCustomToast({redirect: redirect});

  return (
    <Edit 
      className="col-md-6" 
      {...props} 
      title={<Title />}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm
        redirect="list"
        toolbar={<CustomToolbar name="tna_departments" text="Departments" />}
      >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="code" validate={[required()]} />
        <ReferenceInput source="admin_user" reference="users">
          <SelectInput optionText="username" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
