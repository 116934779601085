import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";

export const TrackingStep = props => (
  <List {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="work_station" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);
