import React from "react";
import {
  Create,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

const validateSite = (values) => {
  const errors = {};
  if (!values.demand_operation) {
    errors.demand_operation = ["Demand operation is required"];
  }
  if (!values.tracking_step) {
    errors.tracking_step = ["code is required"];
  }
  if (!values.code) {
    errors.code = ["code is required"];
  }
  if (!values.code) {
    errors.code = ["code is required"];
  }
  if (!values.time_stamp) {
    errors.time_stamp = ["timestamp is required"];
  }
  if (!values.grade) {
    errors.grade = ["grade is required"];
  }
  if (!values.reason) {
    errors.reason = ["reason is required"];
  }
  if (!values.delay_duration) {
    errors.delay_duration = ["delay duration is required"];
  }
  return errors;
};

export const CreateTrackingRecord = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm validate={validateSite}>
        <ReferenceInput
          label="demand operation"
          source="demand_operation"
          reference="demandOp"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput
          label="tracking step"
          source="tracking_step"
          reference="trackStep"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <TextInput source="completed_quantity" />
        <DateTimeInput source="time_stamp" />
        <TextInput source="grade" />
        <TextInput source="reason" />
        <TextInput source="delay_duration" />
      </SimpleForm>
    </Create>
  );
};
