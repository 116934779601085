import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import React from "react";
import { useDataProvider } from "react-admin";
import { format_to_date } from "../../lib/date_time";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const Expand = ({ record }) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [data, setData] = React.useState("");

  const apiCall = async () => {
    const ids = await dataProvider.CUSTOM_ACTION("closedOrderGroup", {
      action: "get_demands",
      body: [record.id],
    });

    const idArray = ids.data.data.map((id) => id.id);
    const payloard = {
      ids: _.uniq(idArray),
      pagination: { page: 1, perPage: 25 },
      sort: { field: "id", order: "ASC" },
      filter: { is_closed: true },
    };
    const result = await dataProvider.getMany("demandOp", payloard);
    if (result) {
      setData(result.data);
    }
  };
  // api call
  React.useEffect(() => {
    apiCall();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table 
      //className={classes.table} 
      className="table-width-custom"
      size="small" 
      aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="right">Item code</TableCell>
            <TableCell align="right">Item operation</TableCell>
            <TableCell align="right">Order code</TableCell>
            <TableCell align="right">planned quantity</TableCell>
            <TableCell align="right">skipped quantity</TableCell>
            <TableCell align="right">smv</TableCell>
            <TableCell align="right">planned start </TableCell>
            <TableCell align="right">planned end</TableCell>
            <TableCell align="right">size</TableCell>
            <TableCell align="right">configuration</TableCell>
            <TableCell align="right">item unit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="right">{row.item_code}</TableCell>
                <TableCell align="right">{row.item_operation}</TableCell>
                <TableCell align="right">{row.order_code}</TableCell>
                <TableCell align="right">{row.planned_quantity}</TableCell>
                <TableCell align="right">{row.skipped_quantity}</TableCell>
                <TableCell align="right">{row.smv}</TableCell>
                <TableCell align="right">
                  {format_to_date(row.planned_start_date)}
                </TableCell>
                <TableCell align="right">
                  {format_to_date(row.planned_end_date)}
                </TableCell>
                <TableCell align="right">{row.attributes.size}</TableCell>
                <TableCell align="right">{row.configuration}</TableCell>
                <TableCell align="right">{row.item_unit}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
