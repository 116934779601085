import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Loading, useDataProvider } from "react-admin";
import { showToastError, showToastSuccess } from '../../../../lib/toas';
// import DialogBox from "./DialogBox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ListHelper = ({
  data,
  handleClose,
  selectListData

}) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [checked, setChecked] = useState([]);
  const [open, setOpen] = useState(false);


  const handleToggle = (value) => {

    //  const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (value > -1) {
      if (newChecked.includes(value)) {
        const index = newChecked.indexOf(value);
        if (index > -1) {
          newChecked.splice(index, 1);
          // console.log('remove')
        }
      } else {
        // console.log('add')
        newChecked.push(value);
      }

    }

    setChecked(newChecked);
    // console.log(checked)


  };
// console.log(selectListData)
  const handelSelectAll = ()=>
  {
    const arryLength = selectListData?.result.length
     const newArr = Array.from(Array(arryLength).keys())
     setChecked(newArr);


  }
// console.log(handelSelectAll())

  // const handleChange = (event) => {
  //   const id = event.target.value;
  //   const sId = groups[id].id; // select group id

  // };

  const apiCall = async (res) => {

    try {
      const ids = await dataProvider.CUSTOM_ACTION_TNA_SP("dropdown", {
        actions: "delete_inquiry_serial_keys",
        body: res,
        method: "POST"
      });
      showToastSuccess('delete success ')
      // window.location.reload();
      setTimeout(() => {
        window.location.reload();
      }, 1000);


    } catch (error) {
      showToastError('fill all fields')
    }
    ;
  }

  const handleSave = () => {
    // setOpen(true);
    const { field, newList } = selectListData
    const mapData = newList[0]
    const temp = []

    for (const property in mapData) {
      // temp.push(`${property}:${mapData[property]}`)
      temp.push([property, mapData[property]])

    }

    const filterData = temp.filter((x, i) => {
      if (checked.includes(i)) {
        return x
      }
    })
    const obj = {};
    // console.log(filterData)

    for (let i = 0; i < filterData.length; i++) {
       // console.log(filterData[i])
       obj[filterData[i][0]] = filterData[i][1];
      // obj.assign(obj,)
    }
    const schema = {
      'cluster': [],
      'year': [],
      'season': [],
      'category': [],
      'type': []

    }

    for (const key in schema) {
      if (key === field) {
        schema[key].push({})
        Object.assign(schema[key][0], obj)
        // schema[key][0]:filterData
      }
    }



    

    apiCall(schema)

  };
  const handelOpen = (value) => {
    if (value !== undefined) {
      setOpen(false);
    } else {
      setOpen(true);
    }

    return open;
  };

  console.log(selectListData)


  return (
    <>
      <div>
       
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ alignItems: "flexStart", marginRight: "400px" }}>

            </div>
          </div>
          <div>
            <DialogContent>
              <List className={classes.root}>
                {data.map((code, id) => {
                  const labelId = `checkbox-list-label-${code}`;

                  return (
                    <ListItem
                      key={code}
                      dense
                      button
                      onClick={() => handleToggle(id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          // checked={checked.indexOf(id) !== -1}
                          checked={checked.includes(id)}

                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={code}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </DialogContent>
          </div>
        </div>
        {/* )} */}

      </div>
      <div style={{ marginTop: "50px" }}>
        <DialogActions>

          <Button
            className="btn_ btn_secondry"
            onClick={ handelSelectAll}
            variant="contained"
            // color="primary"
            autoFocus
          >
            Select All
          </Button>
          <Button
            className="btn_ btn_delete"
            onClick={handleSave}
            variant="contained"
            color="primary"
            autoFocus
            disabled={checked.length === 0}
          >
            Delete
          </Button>



        </DialogActions>
      </div>
    </>
  );
};
