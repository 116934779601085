import moment from "moment"
import { SET_TIME_RANGE, SHIFT_TIME_RANGE_BACKWARD, SHIFT_TIME_RANGE_FORWARD } from "../../types"

const initialState = {
    start_time: Date.now(),
    end_time: Date.now() + 1000 * 3600 * 24 * 5,
}


export const timeRangeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_TIME_RANGE: {
            const { start_time, end_time } = action.payload
            return { ...state, start_time, end_time }
        }
        case SHIFT_TIME_RANGE_FORWARD: {
            const { durationInHours: d } = action.payload // Default is five days
            const durationInHours = d ? d : Math.max(24, moment(state.end_time).diff(state.start_time, 'hours'))
            return {
                start_time: moment(state.start_time).add(durationInHours, 'hours'),
                end_time: moment(state.end_time).add(durationInHours, 'hours'),
            }
        }

        case SHIFT_TIME_RANGE_BACKWARD: {
            const { durationInHours: d } = action.payload // Default is five days
            const durationInHours = d ? d : Math.max(24, moment(state.end_time).diff(state.start_time, 'hours'))
            return {
                start_time: moment(state.start_time).add(-durationInHours, 'hours').valueOf(),
                end_time: moment(state.end_time).add(-durationInHours, 'hours').valueOf(),
            }
        }
        default:
            return state
    }
}