import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Loading, Query, Show, SimpleShowLayout, TextField } from "react-admin";
import Tree from "react-d3-tree";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  price: { fontSize: "30px", alignSelf: "centre" },
});

const TitleField = (props) => {
  const classes = useStyles();
  return <TextField className={classes.price} {...props} />;
};

export default function KeyValueField({ source, record = {} }) {
  const obj = record[source];

  if (!obj) return false;
  return (
    <div>
      {Object.keys(obj).map((k) => (
        <Tooltip key={k} title={k}>

          <table style={{ float: 'left', margin: 3, textAlign: 'center', borderColor: 'lightgray', width: 150 }}>
            <tr bgcolor="#c5edfd" style={{ color: '#00acee', height: '35px', }}>
              <th>{k}</th>
            </tr>
            <tr bgcolor="#eee">
              <td>
                <Chip
                  style={{ margin: 2, backgroundColor: "#eee" }}
                  label={obj[k]}
                />
              </td>
            </tr>
          </table>
        </Tooltip>
      ))}
    </div>
  );
}

export const ProductTree = (props) => {
  const { id } = props.match.params;
  const Title = ({ record }) => {
    return <span>Item Structure Tree : {record ? `${record.code}` : ""}</span>;
  };
  return (
    <Show title={<Title />} {...props} actions={null}>
      <SimpleShowLayout>
        <br></br>
        <div style={{ float: 'left', padding: 10, color: '#555', fontSize: 15, fontWeight: 'bold', width: 150, marginTop: '-10px', }} >Item : </div>
        <TitleField source="code" style={{ fontSize: 15, }} />
        <br></br>

        <div style={{ float: 'left', padding: 10, color: '#555', fontSize: 15, fontWeight: 'bold', width: 150 }} >Attributes : </div>
        <KeyValueField label="Attributes" source="attributes" style={{ float: 'left' }} />
        <br></br>

        <Query
          type="CUSTOM"
          resource="items"
          payload={{
            id: id,
            action: "get_product_structure",
          }}
        >
          {({ data, loading, error }) => {

            if (loading) {
              return (
                <div style={{ float: "left" }}>
                  <br></br>
                  <Loading />
                </div>
              );
            }
            if (error) {
              return "Error";
            }
            const seperation = { siblings: 0.65, nonSiblings: 0.5 };
            const translation = { x: 1300, y: 100 }; //change later
            return (
              <>
                <br></br>
                <br></br>
                <br></br>
                <div style={{ padding: 15 }}>
                  <Typography style={{ color: '#00acee', fontSize: 18, fontWeight: 'bold' }}  >Product Structure</Typography>
                  <Title title="Product Structure Editor" style={{ fontSize: 14, }} />
                  <div
                    id="treeWrapper"
                    style={{ width: "100%", height: "100vh", overflowY: 'scroll' }}
                  >
                    <Tree
                      data={data}
                      renderCustomNodeElement={renderRectSvgNode}
                      pathFunc={step_func}
                      translate={translation}
                      depthFactor={-200}
                      separation={seperation}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Query>
      </SimpleShowLayout>
    </Show>
  );
};


const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
  const {Op, Code, Id} = nodeDatum.attributes
  const {name} = nodeDatum
  return (
    <g  onClick={toggleNode} >
      {/* <rect width="20" height="20" x="-10"/> */}
      <rect x="-30" y="-30" width="150" height="60" fill={Op ? 'yellow': '#C8C8C8'} stroke="gray" strokeWidth="1" ></rect>
      <text x="-25" y="-10" font-size="15" fill="black" strokeWidth="0">{name}</text>
      <text x="-25" y="5" font-size="12" fill="blue" strokeWidth="0">{Code}</text>
      <text x="-25" y="20" font-size="12" fill="blue" strokeWidth="0">{Op}</text>
    </g>
  );
}

const step_func = (linkData, orientation) => {
  const { source, target } = linkData;
  const deltaY = target.y - source.y;

  return orientation === "horizontal"
    ? `M${source.y},${source.x} H${source.y + deltaY / 3} V${target.x} H${target.y
    }`
    : `M${source.x},${source.y} V${source.y + deltaY / 3} H${target.x} V${target.y
    }`;
};
