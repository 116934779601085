import solver from "javascript-lp-solver"

import _, { Dictionary } from "lodash"
import { it } from "date-fns/locale"
import { PlannedCapacityRecord, TimeBucket, SupplyChainState, SalesLossRecord } from "./interfaces"

const sampleModel = {
    "optimize": "capacity",
    "opType": "max",
    "constraints": {
        "plane": { "max": 44 },
        "person": { "max": 512 },
        "cost": { "max": 300000 }
    },
    "variables": {
        "brit": {
            "capacity": 20000,
            "plane": 1,
            "person": 8,
            "cost": 5000
        },
        "yank": {
            "capacity": 30000,
            "plane": 1,
            "person": 16,
            "cost": 9000
        }
    },
}


function mapToObject<T, U>(data: T[], k1: (a: T) => any, k2: (a: T) => any, map: (a: T[]) => U) {
    return _.chain(data)
        .groupBy(k1)
        .mapValues(v => _.chain(v).groupBy(k2).mapValues(rows => map(rows)).value())
        .value()
}
function getFromObject(obj: any, keys: string[], defaultValue: number = 0): number {
    return _.get(obj, keys.join(".")) || defaultValue
}

const S = "____"
const getPlannedVariableKey = (bucketId: string, operationId: string, itemId: string) => ['planned', bucketId, operationId, itemId].join(S)
const decodePlannedVariableKey = (key: string) => {
    const [type, bucketId, operationId, itemId] = key.split(S)
    return { bucketId, operationId, itemId }
}
const getSalesLosttVariableKey = (bucketId: string, itemId: string) => ['salesloss', bucketId, itemId].join(S)
const decodeSalseLostVariableKey = (key: string) => {
    const [type, bucketId, itemId] = key.split(S)
    return { bucketId, itemId }
}
const getCapacityConstraintKey = (bucketId: string, operationId: string) => ['capacity', bucketId, operationId].join(S)
const getBufferConstraintKey = (bucketId: string, itemId: string) => ['buffer', bucketId, itemId].join(S)
const getSalesLossConstraintKey = (bucketId: string, itemId: string) => ['saleslossconstraint', bucketId, itemId].join(S)


const getLpModel = (data: SupplyChainState, ignoreItems?: Set<string>) => {
    const buckets = _.sortBy(data.time_buckets, "order");


    const constraints = {} as any
    const variables = {} as any
    const ints = {} as any

    const routingsByProducedItem = _.chain(data.routings).groupBy(b => b.item_id).value()
    const routingsByOperationId = _.chain(data.routings).groupBy(b => b.operation_id).value()
    const materialStructuresByConsumedItem = _.chain(data.material_structures)
        .groupBy(b => b.rm_item_id)
        .value()

    const supply = mapToObject(data.item_time_bucket, b => b.time_bucket_id, b => b.item_id, b => b[0].supply)
    const demand = mapToObject(data.item_time_bucket, b => b.time_bucket_id, b => b.item_id, b => b[0].demand)
    const buffer_stock = mapToObject(data.item_time_bucket, b => b.time_bucket_id, b => b.item_id, b => b[0].minimum_buffer_stock)
    const salePrice = mapToObject(data.item_time_bucket, b => b.time_bucket_id, b => b.item_id, b => b[0].selling_price)



    const openingStock = _.chain(data.items).groupBy(d => d.id).mapValues(d => d[0].openning_stock).value()
    // const holdingCosts = _.chain(data.items).groupBy(d => d.id).mapValues(d => d[0].).value()
    const allItems = data.items.map(item => item.id)

    // Interest rate for future
    const interestRateFutureSum = {} as Dictionary<number>
    data.time_buckets.forEach((b, i) => {
        const interestForThisBucket = b.interest / 2;
        const futureBuckets = data.time_buckets.slice(i + 1)
        const valueAtTheEndOfTheBucket = 1 + interestForThisBucket
        const futureInterest = futureBuckets.reduce((pre, b) => pre + pre * b.interest, valueAtTheEndOfTheBucket)
        console.log(b.id, futureInterest)
        interestRateFutureSum[b.id] = futureInterest
    })

    console.log("Interest", interestRateFutureSum)

    // Warehouse cost future sum
    const wareHouseCostForItemPerBucket = _.chain(data.item_time_bucket)
        .groupBy(row => row.item_id)
        .mapValues(itemTimeBuckets => {
            const orderedItemTimeBuckets = _.sortBy(itemTimeBuckets, v => buckets.findIndex(b => b.id == v.time_bucket_id))
            const costMatrix = {} as Dictionary<number>
            orderedItemTimeBuckets.forEach((b, i) => {

                const this_bucket_cost = b.warehouse_cost * getFromObject(interestRateFutureSum, [b.time_bucket_id]) / 2
                const future_cost = _.sumBy(orderedItemTimeBuckets.slice(i + 1), v => v.warehouse_cost * getFromObject(interestRateFutureSum, [v.time_bucket_id])) || 0
                const cost = this_bucket_cost + future_cost
                const costWithInterest = cost
                console.log(b.time_bucket_id, b, this_bucket_cost, future_cost)
                costMatrix[b.time_bucket_id] = costWithInterest
            })
            return costMatrix
        }).value()



    console.log("Warehouse", wareHouseCostForItemPerBucket)


    const addToVariables = (vKey: string, conKey: string, value: number) => {
        const innerRecord = variables[vKey] || {}
        const newValue = (innerRecord[conKey] || 0) + value
        variables[vKey] = { ...innerRecord, [conKey]: newValue }
    }

    data.capacity.forEach(capacityRecord => {
        // Todo for all items Id add to planned
        const relatedRoutes = routingsByOperationId[capacityRecord.operation_id] || []
        relatedRoutes.forEach(r => {
            const variableKey = getPlannedVariableKey(capacityRecord.time_bucket_id, capacityRecord.operation_id, r.item_id)
            const constraintKey = getCapacityConstraintKey(capacityRecord.time_bucket_id, capacityRecord.operation_id)
            constraints[constraintKey] = { max: capacityRecord.quantity, min: capacityRecord.minimum_quantity }
            addToVariables(variableKey, constraintKey, r.pack_size)
            const cost = capacityRecord.variable_cost * getFromObject(interestRateFutureSum, [capacityRecord.time_bucket_id])
            console.log("Production cost", variableKey, cost)
            addToVariables(variableKey, "cost", r.pack_size * cost)
        })
    })



    allItems.filter(item => !(ignoreItems?.has(item))).forEach(itemId => {
        buckets.forEach((bucket, i) => {
            const constraintKey = getBufferConstraintKey(bucket.id, itemId)
            const opening = openingStock[itemId] || 0
            const previousBucketsIncludingThis = buckets.slice(0, i + 1)
            const futureBuckets = buckets.slice(i + 1)

            const nextBucket = futureBuckets[0]
            const demand_buffer_for_next = !nextBucket ? 0 :
                getFromObject(demand, [nextBucket.id, itemId]) * getFromObject(buffer_stock, [bucket.id, itemId])
            const previousDemand = _.sumBy(previousBucketsIncludingThis, b => getFromObject(demand, [b.id, itemId]))
            const previousSupply = _.sumBy(previousBucketsIncludingThis, b => getFromObject(supply, [b.id, itemId]))
            const minValue = (previousDemand + demand_buffer_for_next) - (previousSupply + opening)
            constraints[constraintKey] = { min: minValue }

            

            // const variableKey = `planned_${capacityRecord.time_bucket_id}_${capacityRecord.operation_id}`
            // Add for sales loss variable
            const salesLossVariableKey = getSalesLosttVariableKey(bucket.id, itemId)
            const totalSalesLossCostPerItem = getFromObject(salePrice, [bucket.id, itemId]) * getFromObject(interestRateFutureSum, [bucket.id])
            const saleslossconstraint = getSalesLossConstraintKey(bucket.id, itemId)
            const demandInThisBucket = getFromObject(demand, [bucket.id, itemId])
            addToVariables(salesLossVariableKey, "cost", totalSalesLossCostPerItem)
            addToVariables(salesLossVariableKey, saleslossconstraint, 1)
            constraints[saleslossconstraint] = { min: 0, max:demandInThisBucket }


            // Add sales loss to previous 
            previousBucketsIncludingThis.forEach(pb => {
                const variableKey = getSalesLosttVariableKey(pb.id, itemId)
                addToVariables(variableKey, constraintKey, 1)
            })

            const producingRoutes = routingsByProducedItem[itemId]
            if (producingRoutes) {
                // For buffer stock
                const uniqueProduceRoutings = _.uniqBy(producingRoutes, r => r.operation_id)
                uniqueProduceRoutings.forEach(r => {
                    previousBucketsIncludingThis.forEach(pb => {
                        const variableKey = getPlannedVariableKey(pb.id, r.operation_id, itemId)
                        addToVariables(variableKey, constraintKey, r.pack_size)
                    })
                })

                uniqueProduceRoutings.forEach(r => {

                    // For cost
                    const totalDurationInkFuture = _.sumBy(futureBuckets, b => b.duration) + 1 //Since last bucket do not have a future duration we add one to all. 
                    const variableKey = getPlannedVariableKey(bucket.id, r.operation_id, itemId)
                    const warehouseCostPerItem = getFromObject(wareHouseCostForItemPerBucket, [itemId, bucket.id])
                    const cost = warehouseCostPerItem * r.pack_size
                    addToVariables(variableKey, "cost", cost)

                    // For greater than zero
                    ints[variableKey] = 1
                    const greater_cons_key = `greater_than_zero_${variableKey}`
                    addToVariables(variableKey, greater_cons_key, r.pack_size)
                    constraints[greater_cons_key] = { min: 0 }
                })
            }

            const consumingMaterialStructures = materialStructuresByConsumedItem[itemId]
            if (consumingMaterialStructures) {
                // For buffer stock consumption
                consumingMaterialStructures.forEach(r => {
                    previousBucketsIncludingThis.forEach(pb => {
                        const variableKey = getPlannedVariableKey(pb.id, r.operation_id, r.item_id)
                        addToVariables(variableKey, constraintKey, -r.qty_per_assembly)
                    })
                })

                consumingMaterialStructures.forEach(r => {
                    // For cost. Here it is negative because item is consumed. It is not holded anymore.
                    // const totalDurationInkFuture = _.sumBy(futureBuckets, b => b.duration) + 1 //Since last bucket do not have a future duration we add one to all. 
                    const variableKey = getPlannedVariableKey(bucket.id, r.operation_id, r.item_id)
                    const cost = -getFromObject(wareHouseCostForItemPerBucket, [r.rm_item_id, bucket.id])
                    addToVariables(variableKey, "cost", cost)
                })
            }

            if (nextBucket && consumingMaterialStructures) {
                // For buffer stock consumption next bucket buffer
                consumingMaterialStructures.forEach(r => {
                    const variableKey = getPlannedVariableKey(nextBucket.id, r.operation_id, r.item_id)
                    const bufferPresentage = getFromObject(buffer_stock, [bucket.id, itemId])
                    const value = - r.qty_per_assembly * bufferPresentage
                    addToVariables(variableKey, constraintKey, value)
                })
            }
        })
    })

    return { constraints, variables, optimize: "cost", opType: "min", ints: ints, "timeout": 1000 };
}

const decodeResult = (result: any, data: SupplyChainState) => {
    const buckets = data.time_buckets
    const routesByKey = _.chain(data.routings).groupBy(r => `${r.operation_id}__${r.item_id}`).mapValues(data => data[0]).value()
    let success = true
    const getPlannedRowFromKey = (k: string) => {
        const { bucketId, operationId, itemId } = decodePlannedVariableKey(k)
        const qty = result[k] * (routesByKey[`${operationId}__${itemId}`]?.pack_size || 1)
        const quantity = Math.round(qty)
        success = qty == quantity && success
        return new PlannedCapacityRecord(bucketId, operationId, itemId, quantity)
    }
    
    const getSalesLossRowFromKey = (k: string) => {
        const { bucketId, itemId } = decodeSalseLostVariableKey(k)
        const qty = result[k]
        const quantity = Math.round(qty)
        success = qty == quantity && success
        return new SalesLossRecord(bucketId, itemId, quantity)
    }

    const timeBucketToOrder = {} as any;
    buckets.forEach((b, i) => timeBucketToOrder[b.id] = i)
    const plannedQuantities = Object.keys(result).filter(v => v.startsWith("planned")).map(getPlannedRowFromKey)
    const planned =  _.sortBy(plannedQuantities, v => timeBucketToOrder[v.time_bucket_id], v => v.operation_id, v => v.quantity)
    const salesLossQuantities = Object.keys(result).filter(v => v.startsWith("salesloss")).map(getSalesLossRowFromKey)
    const sales_loss = _.sortBy(salesLossQuantities, v => timeBucketToOrder[v.time_bucket_id], v => v.item_id)
    return { planned, sales_loss, success }
}

export const solve = (data: SupplyChainState, ignoreItems?: Set<string>) => {
    const model = getLpModel(data, ignoreItems)
    console.log(model)
    const result = solver.Solve(model)
    console.log("Result", result)
    const { planned, sales_loss, success } = decodeResult(result, data)
    const feasible = result.feasible
    const cost = result.result

    return { planned, sales_loss, feasible, cost, success }
}


export const findInfeasibleItems = (data: SupplyChainState) => {
    let ignoredItems = new Set(data.items.map(item => item.id))
    let hasOptions = true;
    let result = null as (null | ReturnType<typeof solve>);

    while (hasOptions) {
        hasOptions = Array.from(ignoredItems).some(item => {
            const tmp = new Set(ignoredItems)
            tmp.delete(item)
            const r = solve(data, tmp)
            if (r.feasible) {
                ignoredItems = tmp
                result = r
                console.log("Update infeasible items", tmp, r)
            }
            return r.feasible
        })
    }
    if (result == null) {
        return { planned: [], success: false, feasible: false, ignoredItems, sales_loss: [] }
    }
    return { ...result, ignoredItems };
}