import React from "react";
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";
const validate = (values) => {
  const errors = {};
  if (!values.planBoardLine) {
    errors.planBoardLine = ["Line is required"];
  }
  if (!values.start_time) {
    errors.start_time = ["Start time is required"];
  }
  if (!values.end_time) {
    errors.end_time = ["End time is required"];
  }
  if (!values.efficiency) {
    errors.efficiency = ["Efficiency time is required"];
  }
  if (!values.total_smv) {
    errors.total_smv = ["Total smv time is required"];
  }
  if (!values.available_smv) {
    errors.available_smv = ["Available smv smv time is required"];
  }
  return errors;
};

export const EditLineBucket = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Edit
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm validate={validate}>
        <ReferenceInput
          label="line"
          source="planBoardLine"
          reference="planBoardLines"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <DateTimeInput source="start_time" />
        <DateTimeInput source="end_time" />
        <TextInput source=" efficiency" />
        <TextInput source="total_smv" />
        <TextInput source="unallocated_smv" />
        <TextInput source="carder" />
        <TextInput source="configuration" />
        <BooleanInput source="allocation_completed" />
      </SimpleForm>
    </Edit>
  );
};
