import React from 'react'
import img from '../../../layout/img/Group 219.png';

// profile page bottom leaf img
export const Leaf = () => {
    return (
        <div>
            <img src={img} alt='leaf'   width="140px" height="100px" />
        </div>
    )
}
