export const formsSchema = [
  {
    code: "I-TRM",
    data: [
      {
        name: "TechnicalReviewForm",
        id: 1,
        fields: 0,
      },
    ],
  },
  {
    code: "I-MDDS",
    data: [
      {
        id: 2,
        name: "Ready MadeUppers Sample Request Form",
        fields: {
          count: 1,
          values: [
            { id: 1, name: "Sample Type" },
            { id: 2, name: "Category" },
            { id: 3, name: "Sole attaching process" },
            { id: 4, name: "Upper lasting method" },
            { id: 5, name: "or new development" },
            { id: 6, name: "Comply with REACH + PAH" },
            { id: 7, name: "Phthalate free" },
            { id: 8, name: "Supplier" },
            { id: 9, name: "Mould/ last name" },
            { id: 10, name: "Upper material" },
            { id: 11, name: "Insock material" },
            { id: 12, name: "Insock thickness" },
            { id: 13, name: "Logo" },
            { id: 14, name: "Size grading interval" },
            { id: 15, name: "Physical test requirements" },
          ],
        },
      },
      {
        id: 3,
        name: "New Development InquirySheet For Flip Flops",

        fields: {
          count: 1,

          values: [
            { id: 1, name: "Component" },
            { id: 2, name: "Design/ Type" },
            { id: 3, name: "Material States" },
            { id: 4, name: "Abrasion" },
            { id: 5, name: "Compression set" },
            { id: 6, name: "Flexing" },
            { id: 7, name: "Cell 1" },
            { id: 8, name: "Cell 2" },
            { id: 9, name: "Cell 3" },

            { id: 10, name: "Split-top layer", },
            { id: 11, name: "Sole print" },
            { id: 12, name: "V-Strap print" },
            { id: 13, name: "foot bed" },
            { id: 14, name: "Side wall finishing", },

            { id: 15, name: "REACH" },
            { id: 16, name: "Phthalte free" },
            { id: 17, name: "PAH" },
            { id: 18, name: "Buyer compliance" },
            { id: 19, name: "FSC" },
            { id: 20, name: "Quality level" },



          ]


        },
      },
      {
        id: 4,
        name: "Sole Sample Request Form",

        fields: {
          values: [
            { id: 1, name: "Sample Type" },
            { id: 2, name: "Category" },
            { id: 3, name: "Sole attaching process" },
            { id: 4, name: "Upper lasting method" },
            { id: 5, name: "or new development" },
            { id: 6, name: "Comply with REACH + PAH" },
            { id: 7, name: "Phthalate free" },
            { id: 8, name: "Supplier" },
            { id: 9, name: "Mould states" },
            { id: 10, name: "Sole type" },
            { id: 11, name: "Material" },
            { id: 12, name: "Size grading interval" },
            { id: 13, name: "Last states" },
          ],
        },
      },
      {
        id: 5,
        name: "Trims Sample Request Form",
        fields: {
          values: [
            { id: 1, name: "Sample Type" },
            { id: 2, name: "Category" },
            { id: 3, name: "Sole attaching process" },
            { id: 4, name: "Upper lasting method" },
            { id: 5, name: "or new development" },
            { id: 6, name: "Comply with REACH + PAH" },
            { id: 7, name: "Phthalate free" },
            { id: 8, name: "Material" },
            { id: 9, name: "Dimensions" },
            { id: 10, name: "Use for" },
            { id: 11, name: "Physical test requirements" },
          ],
        },
      },
      {
        id: 6,
        name: "Fabric Sample Request Form",
        fields: {
          values: [
            { id: 1, name: "Sample Type" },
            { id: 2, name: "Category" },
            { id: 3, name: "Sole attaching process" },
            { id: 4, name: "Upper lasting method" },
            { id: 5, name: "or new development" },
            { id: 6, name: "Comply with REACH + PAH" },
            { id: 7, name: "Phthalate free" },
            { id: 8, name: "Material" },
            { id: 9, name: "USE" },
            { id: 10, name: "GSM" },
            { id: 11, name: "MATERIAL SHOULD BE SUITABLE FOR" },
            { id: 12, name: "Weave pattern" },
            { id: 13, name: "BACKING COLOR" },
            { id: 14, name: "Backing GSM" },
            { id: 15, name: "Lam Mec" },
            { id: 16, name: "Physical test requirements" },
          ],
        },
      },
      {
        id: 7,
        name: "Ink Sample Request Form",

        fields: {
          values: [
            { id: 1, name: "Sample Type" },
            { id: 2, name: "Category" },
            { id: 3, name: "Sole attaching process" },
            { id: 4, name: "Upper lasting method" },
            { id: 5, name: "or new development" },
            { id: 6, name: "Comply with REACH + PAH" },
            { id: 7, name: "Phthalate free" },
            { id: 8, name: "Trim type" },
            { id: 9, name: "Substrate" },
            { id: 10, name: "Process" },
          ],
        },
      },
      {
        id: 8,
        name: "Leather Sample Request Form",
        fields: {
          values: [
            { id: 1, name: "Sample Type" },
            { id: 2, name: "Category" },
            { id: 3, name: "Sole attaching process" },
            { id: 4, name: "Upper lasting method" },
            { id: 5, name: "or new development" },
            { id: 6, name: "Comply with REACH + PAH" },
            { id: 7, name: "Phthalate free" },
            { id: 8, name: "Kind of Leather" },
            { id: 9, name: "Leather type" },
            { id: 10, name: "USE" },
            { id: 11, name: "MATERIAL SHOULD BE SUITABLE FOR" },
            { id: 12, name: "Finishing type" },
            { id: 13, name: "Cross section" },
            { id: 14, name: "SURFACE APPE." },
          ],
        },
      },
      {
        id: 9,
        name: "Compound Sample Request Form",
        fields: {
          values: [
            { id: 1, name: "Sample Type" },
            { id: 2, name: "Category" },
            { id: 3, name: "Sole attaching process" },
            { id: 4, name: "Upper lasting method" },
            { id: 5, name: "or new development" },
            { id: 6, name: "Comply with REACH + PAH" },
            { id: 7, name: "Phthalate free" },
          ],
        },
      },
      {
        id: 10,
        name: "PVCPU Sample Request Form",

        fields: {
          values: [
            { id: 1, name: "Sample Type" },
            { id: 2, name: "Category" },
            { id: 3, name: "Sole attaching process" },
            { id: 4, name: "Upper lasting method" },
            { id: 5, name: "or new development" },
            { id: 6, name: "Comply with REACH + PAH" },
            { id: 7, name: "Phthalate free" },
          ],
        },
      },
    ],
  },
  {
    code: "I-RA",
    data: [
      {
        id: 11,
        name: "Risk Analysis Form",
        fields: {
          values: [
            { id: 1, name: "Sample Type" },
            { id: 2, name: "Category" },
            { id: 3, name: "Sole attaching process" },
            { id: 4, name: "Upper lasting method" },
            { id: 5, name: "or new development" },
            { id: 6, name: "Comply with REACH + PAH" },
            { id: 7, name: "Phthalate free" },
            { id: 8, name: "Supplier" },
            { id: 9, name: "Mould states" },
            { id: 10, name: "Sole type" },
            { id: 11, name: "Material" },
            { id: 12, name: "Size grading interval" },
            { id: 13, name: "Last states" },
          ],
        },
      },
    ],
  },
  {
    code: "I-IA",
    data: [
      {
        id: 12,
        name: "DSS",
        fields: 0,
      },
      {
        id: 13,
        name: "Ansel",
        fields: 0,
      },
      {
        id: 14,
        name: "EXP",
        fields: 0,
      },
      {
        id: 15,
        name: "OTB",
        fields: 0,
      },
    ],
  },
];

export const forms = [
  "RiskAnalysisForm",
  "ReadyMadeUppersSampleRequestForm",
  "NewDevelopmentInquirySheetForFlipFlops",
  "SoleSampleRequestForm",
  "TrimsSampleRequestForm",
  "FabricSampleRequestForm",
  "InkSampleRequestForm",
  "LeatherSampleRequestForm",
  "CompoundSampleRequestForm",
  "EXP",
  "ANSL",
  "OTB",
  "SampleRequisition",
  "TechnicalReviewForm",
];

export const formsMapping = {
  "I-TRM": ["TechnicalReviewForm"],
  "I-MDDS": [
    "ReadyMadeUppersSampleRequestForm",
    "NewDevelopmentInquirySheetForFlipFlops",
    "SoleSampleRequestForm",
    "TrimsSampleRequestForm",
    "FabricSampleRequestForm",
    "InkSampleRequestForm",
    "LeatherSampleRequestForm",
    "CompoundSampleRequestForm",
  ],
  "I-IA": ["DSS", "Ansel", "EXP", "OTB"],
  "I-RA": ["RiskAnalysisForm"],
};

export const schema = {
  task: {
    id: 1,
    form: {
      id: 1,
      fields: {
        id: 1,
        inputs: {
          " values": ["max", "min"],
        },
      },
    },
  },
};



