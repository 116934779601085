import React, { useMemo, useState } from "react";
import FormUploadConfirmationDialog from "../FormUploadConfirmationDialog";
import Button from "@material-ui/core/Button";
import { useDataProvider, useQuery, useMutation } from "react-admin";
import { formsSchema } from "./dropDownForm/helpers";
import _ from "lodash";

const InkSampleRequestForm = ({ task, toEdit, prevFormData, prevFormName, formName }) => {
  const [formData, setFormData] = useState({ descriptionTable: [] });
  const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);
  const [fieldData, setFieldData] = useState([]);
  const dataProvider = useDataProvider();

  const form_details = {
    tId: "I-MDDS",
    fId: 7,
  };


  // const getPrevFormData = async () => {
  //   if (toEdit) {
  //     const newData = Object.assign(formData, prevFormData);
  //     setFormData(newData)
  //   } else {
  //     try {
  //       const payload = {
  //         actions: "getFormData",
  //         query: { task_id: task.id }
  //       };
  //       const response = await dataProvider.TNA_CUSTOM_LIST("itemTasks", payload)
  //       if (response.data) {
  //         const newData = Object.assign(formData, response.data.savedFormData);
  //         setFormData(newData);
  //       }
  //     } catch (e) {
  //       console.log("FormData Retrieval Failed:" + e);
  //     }
  //   }

  // }

  const { loading, error } = useQuery(
    {
      type: "TNA_CUSTOM_LIST",
      resource: "itemTasks",
      payload: {
        action: "getFormData",
        method: "GET",
        query: { task_id: task.id, form_name: formName  },
      },
    },
    {
      onFailure: (e) => {
        console.log("FormData Retrieval Failed:" + e.body);
        if (toEdit) {
          const newData = Object.assign(formData, prevFormData);
          setFormData(newData)
        }
      },
      onSuccess: (response) => {
        if (toEdit) {
          const newData = Object.assign(formData, prevFormData);
          setFormData(newData)
        } else if (response.data) {
          const newData = Object.assign(formData, response.data.savedFormData);
          setFormData(newData);
        }
      },
    }
  );

  const getDropdownList = async (tid, fid, feid) => {
    try {
      const res = await dataProvider.getList("tna/dropdown", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        actions: "1/filter_data",
      });
      const data = res.data.filter((details) => details.code != null && details.code === "I-MDDS");
      const fieldsData = data.filter(
        (r) => r.details.form.id === fid && r.code === tid
      );
      const fieldsArray = fieldsData.map(({ details }) => details.form.fields);
      console.log(fieldsArray);
      const newarray = [];
      // console.log(flatArray(fieldsArray))
      fieldsArray.forEach((details, id) => {
        // console.log(details)
        details.forEach((element) => {
          const inputs = element.inputs.values;
          const fid = element.id;
          newarray.push({ inputs, fid });
        });
      });

      // console.log(newarray)
      const fData = formsSchema
        .filter(({ code }) => code === form_details.tId)[0]
        .data.filter(({ id }) => form_details.fId === id);

      const mapper = fData[0].fields.values.map((data) => {
        const id = data.id;
        const name = data.name;
        return newarray.map((res) => {
          const inputs = res.inputs;
          if (res.fid === data.id) {
            return { name, inputs };
          }
          return [];
        });
      });

      const na = [];
      for (let index = 0; index < mapper.length; index++) {
        const element = mapper[index];
        for (let index = 0; index < element.length; index++) {
          const el = element[index];
          if (!_.isEmpty(el)) {
            na.push(el);
          }
        }
      }
      setFieldData(mapper);
    } catch (error) {
      return [];
    }
  };
  React.useEffect(() => {
    getDropdownList(form_details.tId, form_details.fId);
  }, []);

  const flatArray = () => {
    const na = [];
    for (let index = 0; index < fieldData.length; index++) {
      const element = fieldData[index];
      for (let index = 0; index < element.length; index++) {
        const el = element[index];
        if (!_.isEmpty(el)) {
          na.push(el);
        }
      }
    }
    return na;
  };

  const mapInputs = (n) => {
    return flatArray().filter((data) => {
      if (data.name === n) {
        return data.inputs;
      }
    });
  };

  const options = {
    scale: 0.8,
  };

  const getFirstElementOFArray = (c) => mapInputs(c)[0]?.inputs[0];

  const onChange = (event, field) => {
    const id = event.target.name;
    const type = event.target.type;

    const newFormData = formData;
    if (type === "checkbox") {
      newFormData[field] = !formData[field];
      setFormData(newFormData);
    } else {
      const value = event.target.value;

      newFormData[field] = value;
      setFormData(newFormData);
    }
  };

  const addRowTable = (inputProps, isUI) => {
    const setRowData = (newValue) => {
      setFormData((formData) => ({ ...formData, descriptionTable: newValue }));
    };
    const rowData = formData.descriptionTable;

    const headers = ["Trim type", "Substrate", "Colour", "Process"];
    const tArray = ["Trim type", "Substrate", "Process"];

    const onAddRowClick = () => {
      setRowData(rowData.concat(rowData.length + 1));
    };

    const removeRow = (row) => {
      const newRowData = rowData;
      rowData[row] = false;
      setRowData(newRowData);
    };

    return (
      <>
        <tr>
          {headers.map((header, index) => (
            <th class="form-th">{header}</th>
          ))}
          <td style={{ border: "none !important" }}>
            <button disabled> remove </button>
          </td>
        </tr>
        {rowData.map(
          (row, index) =>
            row && (
              <tr>
                {headers.map((header) => (
                  <td class="form-td">
                    {tArray.includes(header) ? (
                      isUI ? (
                        <select {...inputProps(formData, `${row}${header}`)}>
                          {mapInputs(header)[0]?.inputs.length > 0 &&
                            mapInputs(header)[0]?.inputs.map((data) => (
                              <option value={data}>{data}</option>
                            ))}
                        </select>
                      ) : (
                        <input
                          class="form-input"
                          value={
                            formData[`${row}${header}`] ??
                            getFirstElementOFArray(header)
                          }
                          {...inputProps(formData, `${row}${header}`)}
                        ></input>
                      )
                    ) : (
                      <input
                        class="form-input"
                        {...inputProps(formData, `${row}${header}`)}
                      ></input>
                    )}
                  </td>
                ))}
                <td style={{ border: "none !important" }}>
                  <button
                    onClick={() => removeRow(index)}
                    style={{
                      borderRadius: 5,
                      borderColor: "transparent",
                      margin: 0,
                    }}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            )
        )}
        <tr>
          <td style={{ border: "none !important" }} colspan={headers.length}>
            <button
              onClick={onAddRowClick}
              style={{
                borderRadius: 5,
                borderColor: "transparent",
                margin: 0,
              }}
            >
              Add Row
            </button>
          </td>
        </tr>
      </>
    );
  };

  const uiInputProps = (formData, id) => {
    const props = {
      required: true,
      defaultValue: formData[id],
      // checked: formData[id],
      onChange: (e) => {
        onChange(e, id);
      },
      // key: formData[id],
      // id: id,
      // name: id,
    };

    if (id.toLowerCase().includes("date")) {
      props["type"] = "date";
    }

    return props;
  };

  const uploadInputProps = (formData, id) => {
    if (formData[id]) {
      return {
        value: formData[id],
        //   checked: true,
        //   key: formData[id],
        //   id: id,
        //   name: id,
        // };
      };
    }
  };

  const [mutate, { onSuccess, onFaliure }] = useMutation();

  const saveAutofillData = () => {
    const shouldAutofillField = new Set([
      ...tables.sections[1].flat(),
      ...tables.sections[2].flat(),
      "Physical test requirements",
      "Other:",
    ]);
    const dropdownFields = new Set(fieldsArray1);
    const toAutoFill = {};
    for (let property in formData) {
      if (shouldAutofillField.has(property) && !dropdownFields.has(property)) {
        toAutoFill[property] = formData[property];
      }
    }
    mutate(
      {
        type: "TNA_CUSTOM_ACTION",
        resource: "itemTasks",
        payload: {
          action: "saveFormAutofill",
          method: "POST",
          body: {
            formData: toAutoFill,
            task_id: task.id,
            form_name: formName 
          },
        },
      },
      {
        onFailure: (e) => {
          console.log(e);
        },
        onSuccess: (e) => {
          console.log("Autofill Submission Successful");
        },
      }
    );
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      saveAutofillData();
      setOpenFormUploadDialog(true);
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const tables = {
    sections: {
      1: [
        ["Sample Type", "Size range"],
        ["Design Inquire No.", "Customer name"],
      ],
      2: [
        ["From - Design Department / Mr", "Supplier inquiry No"],
        ["To - Supply department /Mr/MS", "Date"],
        ["Designer reference number", "Category"],
        ["Sole attaching process", "Upper lasting method"],
        ["Sample note no or IFS code", "or new development"],
      ],
      4: [
        "Requested by",
        //  "Prepared by", "Approved by R&D"
      ],
      5: ["Requested by", "Prepared by", "Approved by Procurement"],
    },
  };

  const fieldsArray1 = [
    "Sample Type",
    "Category",
    "Upper lasting method",
    "Sole attaching process",
    "or new development",
    "Comply with REACH + PAH",
    "Phthalate free",
  ];

  const getSections = ({ isUI, inputProps, section }) => {
    return section.map((row) => (
      <tr>
        {row.map((column, id) => {
          return (
            <>
              <th class="form-th" colspan="2">
                {column}
              </th>
              <td class="form-td" colspan="2">
                {fieldsArray1.includes(column) ? (
                  isUI ? (
                    <select {...inputProps(formData, column)}>
                      {mapInputs(column)[0] &&
                        mapInputs(column)[0]?.inputs.map((data) => (
                          <option value={data}>{data}</option>
                        ))}
                    </select>
                  ) : (
                    <input
                      class="form-input"
                      value={formData[column] ?? getFirstElementOFArray(column)}
                      {...inputProps(formData, column)}
                    ></input>
                  )
                ) : (
                  <input
                    class="form-input"
                    {...inputProps(formData, column)}
                  ></input>
                )}
              </td>
            </>
          );
        })}
      </tr>
    ));
  };

  const Form = ({ formData, inputProps, isUI = true }) => {
    return (
      <div>
        <table>
          <tr>
            <th class="form-th" rowspan="4" colspan="5">
              <u>INK SAMPLE REQUEST FORM</u>
            </th>
            <th class="form-th" colspan="2">
              Form no:
            </th>
            <td class="form-td" colspan="3">
              <input
                class="form-input"
                {...inputProps(formData, "Form no.")}
              ></input>
            </td>
          </tr>
          <tr>
            <th class="form-th" colspan="2">
              Date of issue:
            </th>
            <td class="form-td" colspan="3">
              <input
                class="form-input"
                {...inputProps(formData, "Date of issue:")}
              ></input>
            </td>
          </tr>
          <tr>
            <th class="form-th" colspan="2">
              Date of review:
            </th>
            <td class="form-td" colspan="3">
              <input
                class="form-input"
                {...inputProps(formData, "Date of review:")}
              ></input>
            </td>
          </tr>
          <tr>
            <th class="form-th" colspan="2">
              Revision no:
            </th>
            <td class="form-td" colspan="3">
              <input
                class="form-input"
                {...inputProps(formData, "Revision no:")}
              ></input>
            </td>
          </tr>

          {getSections({
            isUI: isUI,
            inputProps: inputProps,
            section: tables.sections["1"],
          })}
          {getSections({
            isUI: isUI,
            inputProps: inputProps,
            section: tables.sections["2"],
          })}
        </table>
        <h3>
          Main specification of material / component production process: refer
          attachment
        </h3>
        <table>
          <tr>
            <th class="form-th" rowspan="3" colspan="5">
              Compliance Requirements
            </th>
            <th class="form-th" colspan="2">
              Comply with REACH + PAH:
            </th>
            <td class="form-td" colspan="3">
              {isUI ? (
                <select {...inputProps(formData, "Comply with REACH + PAH")}>
                  {mapInputs("Comply with REACH + PAH")[0] &&
                    mapInputs("Comply with REACH + PAH")[0]?.inputs.map(
                      (data) => <option value={data}>{data}</option>
                    )}
                </select>
              ) : (
                <input
                  class="form-input"
                  value={
                    formData["Comply with REACH + PAH"] ??
                    getFirstElementOFArray("Comply with REACH + PAH")
                  }
                  {...inputProps(formData, "Comply with REACH + PAH")}
                ></input>
              )}
            </td>
          </tr>
          <tr>
            <th class="form-th" colspan="2">
              Phthalate free:
            </th>
            <td class="form-td" colspan="3">
              {isUI ? (
                <select {...inputProps(formData, "Phthalate free")}>
                  {mapInputs("Phthalate free")[0] &&
                    mapInputs("Phthalate free")[0]?.inputs.map((data) => (
                      <option value={data}>{data}</option>
                    ))}
                </select>
              ) : (
                <input
                  class="form-input"
                  value={
                    formData["Phthalate free"] ??
                    getFirstElementOFArray("Phthalate free")
                  }
                  {...inputProps(formData, "Phthalate free")}
                ></input>
              )}
            </td>
          </tr>

          <tr>
            <th class="form-th" colspan="2">
              Other:
            </th>
            <td class="form-td" colspan="3">
              <input
                class="form-input"
                {...inputProps(formData, "Other:")}
              ></input>
            </td>
          </tr>
          <tr>
            <th class="form-th" rowspan="1" colspan="5">
              Physical test requirements
            </th>
            <th class="form-th" colspan="3">
              ITF-SPEC-22-Printing.doc
            </th>
          </tr>
        </table>
        <h3>Description of material / component</h3>
        <table>{addRowTable(inputProps, isUI)}</table>
        <table>
          <tr>
            <th class="form-th" colspan="2">
              Require quantity
            </th>
            <td class="form-td" colspan="2">
              <input
                class="form-input"
                {...inputProps(formData, "Require quantity")}
              ></input>
            </td>
          </tr>
          <tr>
            <th class="form-th" colspan="2">
              Require date
            </th>
            <td class="form-td" colspan="2">
              <input
                class="form-input"
                {...inputProps(formData, "Require date")}
              ></input>
            </td>
          </tr>
        </table>
        <table
          class="form-table"
          style={{ border: "none", textAlign: "center" }}
        >
          <tr style={{ border: "none" }}>
            {tables.sections["4"].map((column) => (
              <td class="form-td" style={{ border: "none" }}>
                <input
                  class="form-input"
                  style={{ borderBottom: "2px dotted" }}
                  {...inputProps(formData, `4${column}`)}
                ></input>
              </td>
            ))}
          </tr>
          <tr style={{ border: "none" }}>
            {tables.sections["4"].map((column) => (
              <th class="form-th" style={{ border: "none" }}>
                {column}
              </th>
            ))}
          </tr>
        </table>
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Form formData={formData} inputProps={uiInputProps} />
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </form>
      <FormUploadConfirmationDialog
        isOpen={openFormUploadDialog}
        setIsOpen={setOpenFormUploadDialog}
        Form={Form({ formData, inputProps: uploadInputProps, isUI: false })}
        formData={formData}
        formName={formName}
        prevFormName={prevFormName}
      />
    </div>
  );
};

export default InkSampleRequestForm;
