import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { useDataProvider } from "react-admin";
import { useClean } from "../../../hooks/useClean";
import { format_to_date } from "../../../lib/date_time";
import { CloseButton } from "../widgets/CloseButton";
import ClosingNavigationButton from "../widgets/ClosingNavigationButton";
import PaperComponent from "../widgets/PaperComponent";


const PopupView = ({
  changeOpen,
  prop,
  dataProp,
  opArrayProp,
  reqDate,
  dataCode,
}) => {
  const dataProvider = useDataProvider();

  const [open, setOpen] = React.useState(false);
  const [unstructruredData, setUnstructruredData] = React.useState([]);
  const [structruredData, setStructruredData] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [completedArray, setCompletedArray] = React.useState([]);
  const [link, setLink] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
    changeOpen(false);
  };

  const apiCall = async (ids) => {
    if (ids.length === 0) {
      return [];
    }
    try {
      const data = await dataProvider.CUSTOM("operations", {
        body: { ids: ids },
        method: "POST",
        action: "get_custom_operations",
        id: 1,
      });
      if (data) {
        setUnstructruredData(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //filter orders
  const setDataSource = () => {
    return opArrayProp.map((id) => {
      return dataProp.filter(
        (val) => id === val.operation_id && val.order_id === prop
      );
    });
  };

  const checkPlnned = (q, p) => {
    if (!(q && p)) return false;

    if (q > p) {
      return false;
    }
    if (q === p || p > q) {
      return true;
    }
  };

  const filterPlannedDemands = (demand = []) =>
    demand.filter(({ planned_quantity, quantity }) =>
      checkPlnned(quantity, planned_quantity)
    );

  const genarateUrl = () => {
    if (completedArray.length > 0) {
      const chunks = filterPlannedDemands(completedArray);

      if (chunks.length === 0 && completedArray.length > 0) {
        return completedArray[0].plan_board_id;
      }
      const temp = chunks.sort((a, b) => b.depth - a.depth);
      return temp[0].plan_board_id;
    }
  };

  const dataSourceManager = () => {
    const dataArray = setDataSource();
    const all = [];
    dataArray.forEach(ar => {
      if (ar.length > 0) {
        ar.map((res) => {
          all.push(res);
        });
      }
    });
    return all;
  };

  const manageStrucreData = () => {
    const arr = [];
    for (let i = 0; i < unstructruredData.length; i++) {
      arr.push({ id: unstructruredData[i][0], code: unstructruredData[i][3] });
    }
    return Object.values(arr);
  };

  const dataManager = () => {
    const dataArray = setDataSource();
    const newArr = [];
    if (!dataArray || dataArray.length === 0 || structruredData.length === 0) {
      return false;
    }

    const arr = allData.map((data) => {
      return structruredData.map((res) => {
        if (data.operation_id === res.id) {
          const code = res.code;
          return { ...data, code };
        }
      });
    });

    for (let i = 0; i < arr.length; i++) {
      for (let x = 0; x < arr[i].length; x++) {
        const chunk = arr[i];
        const element = chunk[x];
        if (element) {
          newArr.push(element);
        }
      }
    }
    return newArr.sort((a, b) => b.depth - a.depth);
  };

  useEffect(() => {
    const data = manageStrucreData();
    if (useClean) {
      setStructruredData(data);
    }
  }, [unstructruredData]);

  useEffect(() => {
    const allData = dataManager();
    if (useClean) {
      setCompletedArray(allData);
    }
  }, [structruredData]);

  // -- init
  useEffect(() => {
    const arrs = dataSourceManager();
    if (useClean) {
      apiCall(opArrayProp);
      setAllData(arrs);
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (useClean) {
      const url = genarateUrl();
      setLink(url);
    }
  }, [completedArray]);

  // console.log('link',link)

  return (
    <div>
      <Dialog
         PaperProps={
          {style:{
           
            borderRadius: 10,
            display: "flex",
            justifyContent: "space-evenly ",
            
          
            minHeight:"30vh",
            minWidth: "80vw",
            position: 'absolute',
            
          }}
        }
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
         
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    
        autoDetectWindowHeight={false}
        autoScrollBodyContent={false}
        fullWidth={true}
        //  maxWidth={planBoardLink === 3 ? "sm" : "mx"}
      
        maxWidth="md"
      >


        
      
    
        <DialogTitle id="alert-dialog-title">
          {"Oder Planning Status"}
          <div style={{ display: "flex" }}>
            <div style={{ alignContent: "flex-start", marginRight: "10px" }}>
              <Tooltip title="Need date" enterDelay={500} leaveDelay={200}>
                <Typography color="textSecondary" gutterBottom>
                  {format_to_date(reqDate)}
                </Typography>
              </Tooltip>
            </div>
            <div style={{ alignContent: "flex-end" }}>
              <Tooltip title="Order code" enterDelay={500} leaveDelay={200}>
                <Typography color="textSecondary" gutterBottom>
                  {dataCode}
                </Typography>
              </Tooltip>
            </div>
          </div>
        </DialogTitle>{" "}

        <DialogActions  style={{
            position: 'absolute',
                  right: 5,
                  top: 5,
             
        }}
       onClick={handleClose}>
          <CloseButton />
        </DialogActions>
        <DialogContent>
          <PaperComponent payloard={completedArray} />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="secondary" className="MuiButton-textPrimary py-1">
            ok
          </Button> */}

          
          <ClosingNavigationButton handleClose={handleClose} url={link} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PopupView;
