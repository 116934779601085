import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getTNANotificationCount, getTNANotifications, getTNAReadNotifications, getTNAUnreadNotifications, getLoading } from "../../../store/selectors/tnaNotificationSelectors";
import { setNotificationToLast } from "../../../store/actions/tnaNotificationActions";
import Read from "./read";
import UnRead from "./unRead";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ReceivedNotifications = ({
    count,
    read,
    unread,
    loading,
    viewLater
}) => {
    //ui functions

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // React.useEffect(() => {
    //     if (recived === undefined) {
    //         window.history.back();
    //     }
    // }, []);

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab label="New " {...a11yProps(0)} />
                    <Tab label="Read" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <UnRead notify={unread ? unread : []} viewLaterFn={viewLater} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Read notify={read ? read : []} />
            </TabPanel>
        </div>
    );
};
const mapStateToProps = (state, props) => {
    const loading = getLoading(state);
    const count = getTNANotificationCount(state);
    const unread = getTNAUnreadNotifications(state);
    const read = getTNAReadNotifications(state);
    return {
        loading,
        count,
        unread,
        read,
    };
};

const mapDispatchToProps = {
    viewLater: setNotificationToLast
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceivedNotifications);
