import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { solve } from './algorithm'
import CsvLoader from './CsvLoader'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab'
import {
    Item,
    PlannedCapacityRecord,
    Routing,
    SupplyChainState,
    MaterialStructure,
    TimeBucket, ItemTimeBucket, CapacityRecord
} from './interfaces'
import { supplyChainCalculatePlan, supplyChainLoadData } from './reducer/actions'
import { getSupplyChainData } from './reducer/selectors'
import Table from './Table'
import TimeSeriesChart from './TimeSeriesChart';
import SimulationView from './SimulationView';




export const getTitleFromKey = (s: string) => {
    return s.split("_").map(s => `${s[0].toUpperCase()}${s.slice(1)}`).join(" ")
}

type SectionRecord = {
    key: keyof SupplyChainState,
    headers: string[],
    fromJson: (data: any) => any
}

export default function DataImportView() {

    const dispatch = useDispatch()
    const data = useSelector(getSupplyChainData)
    const sections: SectionRecord[] = [
        {
            headers: Item.getKeys(),
            key: "items",
            fromJson: Item.fromJson
        },
        {
            headers: Routing.getKeys(),
            key: "routings",
            fromJson: Routing.fromJson
        },
        {
            headers: MaterialStructure.getKeys(),
            key: "material_structures",
            fromJson: MaterialStructure.fromJson
        },
        {
            headers: TimeBucket.getKeys(),
            key: "time_buckets",
            fromJson: TimeBucket.fromJson
        },
        {
            headers: ItemTimeBucket.getKeys(),
            key: "item_time_bucket",
            fromJson: ItemTimeBucket.fromJson
        },
        {
            headers: CapacityRecord.getKeys(),
            key: "capacity",
            fromJson: CapacityRecord.fromJson
        },
        {
            headers: PlannedCapacityRecord.getKeys(),
            key: "planned",
            fromJson: PlannedCapacityRecord.fromJson
        },
    ]
    const loadData = (d: any[], s: any) => {
        const rows = d.map(row => {
            const record = {} as any
            row.forEach((value: any, i: number) => record[s.headers[i]] = value)

            return s.fromJson(record)
        })
        dispatch(supplyChainLoadData(s.key, rows))
    }

    const loadUpdateFromTable = (rows: any[], s: any) => {
        dispatch(supplyChainLoadData(s.key, rows.map(v => s.fromJson(v))))
    }


    const [selectedSection, setSelectedSection] = useState(0)
    const handleChangeTab = (event: any, newValue: number) => {
        setSelectedSection(newValue);
    };
    const s = sections[selectedSection];

    return (
        <div>
            <Tabs value={selectedSection} onChange={handleChangeTab} >
                {sections.map(s => <Tab key={s.key} label={getTitleFromKey(s.key)} id={s.key} />)}
            </Tabs>
            <br />
            <div>
                <CsvLoader headers={s.headers} onLoad={(d) => loadData(d, s)} template_file_name={s.key} />
                <Table data={data[s.key]} headers={s.headers} onUpdate={(d) => loadUpdateFromTable(d, s)} />
            </div>
            <br />
            <br />
            <br />
        </div>
    )
}
