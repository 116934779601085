import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import React from "react";
import { downloadCSV, Loading, useDataProvider } from "react-admin";
import { format_to_date, format_to_date_time } from "../../../lib/date_time";
import { showToastSuccess } from "../../../lib/toas";

const Popup = ({ isOpen, onClose, selectedIds }) => {
  const dataProvider = useDataProvider();

  const [open, setOpen] = React.useState(false);
  const [calOpen, setCalOpen] = React.useState(false);

  const dateTime = format_to_date_time(Date.now());

  const exporter = (demandOp) => {
    const demandOpsForExport = demandOp.map((demand) => {
      // add a field from an embedded resource
      demand.size = demand.attributes.size;
      return {
        item_code: demand.item_code,
        order_code: demand.order_code,
        planned_quantity: demand.planned_quantity,
        skipped_quantity: demand.skipped_quantity,
        smv: demand.smv,
        planned_start_date: format_to_date(demand.planned_start_date),
        planned_end_date: format_to_date(demand.planned_end_date),
        size: demand.attributes.size,
        configuration: demand.configuration,
        need_date: format_to_date(demand.required_time_max),
        item_unit: demand.item_unit,
      };
    });
    const csv = convertToCSV({
      data: demandOpsForExport,
      // select and order fields in the export
      fields: [
        "item_code",
        "order_code",
        "planned_quantity",
        "skipped_quantity",
        "smv",
        "planned_start_date",
        "planned_end_date",
        "size",
        "configuration",
        "need_date",
        "item_unit",
      ],
    });
    downloadCSV(csv, dateTime); // download as 'demands.csv` file
  };

  // ui functions

  React.useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCal = () => {
    setCalOpen((cs) => !cs);
  };
  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  // databainding
  const getDemandOp = async () => {
    const result = await dataProvider.CUSTOM_ACTION("closedOrderGroup", {
      action: "get_demands",
      body: selectedIds,
    });
    if (result) {
      // console.log(result.data.data)
      const idArray = result.data.data.map((id) => id.id);
      console.log(idArray);
      // setData(result.data.data);
      const payloard = {
        ids: idArray,
        pagination: { page: 1, perPage: 25 },
        sort: { field: "id", order: "ASC" },
        filter: { is_closed: true },
      };

      const query = await dataProvider.getMany("demandOp", payloard);
      if (query) {
        console.log(query);
        exporter(query.data);
        showToastSuccess("csv export success !");
      }
    }
  };

  const handelRequest = async () => {
    try {
      getDemandOp();
    } catch (error) {
      //showToastError("Server error occurred!");
      console.error(error);
      return false;
    }

    handleClose();
  };

  return (
    <div>
      <Dialog
        fullWidth="true"
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ paddingTop: "10px" }} id="alert-dialog-title">
              {"Bulk Orders Export View"}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>
        {/* <DialogTitle id="alert-dialog-title">
          {"Bulk Orders Export View"}
        </DialogTitle> */}
        <DialogContent>
          {selectedIds && selectedIds.length > 0 ? (
            <h5>Selected order id's are </h5>
          ) : (
            ""
          )}
          {selectedIds ? (
            selectedIds.map((id, i) => <p key={i}>{id}</p>)
          ) : (
            <Loading />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handelRequest}
            className="btn_ btn_primary"
            autoFocus
          >
            Export selected Orders
          </Button>
          {/* <Button onClick={handleClose}  autoFocus>
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Popup;
