import React from "react";
import "./styles.css";
import { Bar, char } from "react-chartjs-2";
import { showToastSuccess, showToastError } from "../../lib/toas"
import { useEffect } from "react";

export default function CapacityChart({ data, yMax }) {


    return (
        <div className="App">
            <Bar
                pointStyle="star"
                data={{
                    labels: data.labels,
                    responsive: true,
                    offset: true,
                    datasets: [
                        {
                            label: "Current Orders  ",
                            pointStyle: "rectRounded",
                            backgroundColor: "#305496",
                            barThickness: 70,
                            categoryPercentage: 1,
                            data: data["currentOrders"]
                        },
                        {
                            label: "Previous Orders",
                            pointStyle: "rectRounded",
                            backgroundColor: "#C65A11",
                            barThickness: 70,
                            categoryPercentage: 10,
                            data: data["previousOrders"]
                        },
                        {
                            label: "Advanced Orders",
                            pointStyle: "rectRounded",
                            backgroundColor: "#548235",
                            barThickness: 70,
                            categoryPercentage: 1,
                            data: data["advancedOrders"]
                        },
                        {
                            label: "Switching Hours  ",
                            pointStyle: "rectRounded",
                            backgroundColor: "#6D6D71",
                            barThickness: 70,
                            categoryPercentage: 1,
                            data: data["switchingHours"]
                        },
                        {
                            label: "Current Effective Orders",
                            pointStyle: "rectRounded",
                            backgroundColor: "#B2B8F5",
                            barThickness: 70,
                            categoryPercentage: 1,
                            data: data["currentEffectiveOrders"]
                        },
                        {
                            label: "Delay Effective Orders",
                            pointStyle: "rectRounded",
                            backgroundColor: "#F5B642",
                            barThickness: 70,
                            categoryPercentage: 1,
                            data: data["delayEffectiveOrders"]
                        },
                        {
                            label: "Monthly capacity",
                            backgroundColor: "#FFFE00",
                            barThickness: 90,
                            yAxisID: "bar-stacked",
                            categoryPercentage: 1,
                            pointStyle: "triangle",
                            data: data["monthlyCap"]
                        }
                    ]
                }}
                height={400}
                options={{
                    tooltips: {
                        enabled: true
                    },
                    offsetGridLines: true,
                    drawTicks: false,
                    layout: {
                        padding: {
                            top: 30,
                            right: 40,
                            bottom: 40
                        }
                    },
                    legend: {
                        display: true,
                        position: "right",
                        align: "start",
                        labels: {
                            usePointStyle: false
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltip: {
                        enabled: false,
                        backgroundColor: "#F4B085"

                    },
                    scales: {
                        xAxes: [
                            {
                                stacked: true,
                                ticks: {
                                    padding: 5
                                },

                                gridLines: {
                                    display: false
                                }
                            }
                        ],
                        yAxes: [
                            {
                                stacked: true,
                                gridLines: {
                                    drawBorder: false
                                },

                                ticks: {
                                    max: yMax,
                                    beginAtZero: true,
                                    maxTicksLimit: 6,
                                    padding: 20,
                                    callback(n) {
                                        if (n < 1e3) return n;
                                        if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
                                    }
                                }
                            },
                            {
                                id: "bar-stacked",
                                stacked: true,
                                gridLines: {
                                    drawBorder: false
                                },

                                ticks: {
                                    max: yMax,
                                    beginAtZero: true,
                                    display: false,
                                    padding: 20,
                                    callback(n) {
                                        if (n < 1e3) return n;
                                        if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
                                    }
                                }
                            }





                        ]
                    }
                }}
            />
        </div>
    );
}
