import React from 'react'
import styled from 'styled-components'
import { useTable, useExpanded } from 'react-table'
import { useSticky } from 'react-table-sticky';
import { makeStyles } from '@material-ui/core/styles';

const Styles = styled.div`
.table {
    border: 1px solid #bbb;
    font-size: 0.9rem;
    vertical-align: center;
  
    .tr {
      height: 30px!important;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
  
    .tr:nth-child(odd) {background: white}
    .tr:nth-child(even) {background: black}
    
    /* .td:nth-child(even) {background: rgba(0,0,0,0.01)} */
    /* .td:first-child {background: white} */
    /* .td:nth-child(odd) {background: #E2E2E2} */
  
    .th,
    .td {
      padding: 1px;
      margin: 0;
      border-bottom: 1px solid #bbb;
      border-right: 1px solid #bbb;
      background: transparent;
      overflow: hidden;
        input {
          padding-right: 1;
          margin: 0;
          text-align: right;
          border: 0;
          background: transparent;
          width: 100%;
          color: orange;
        }
        input:disabled {
          color: green
        }
  
      :last-child {
        border-right: 0;
      }
    }
    .th{
        text-align:center;
    }
  
  .th:first-child{
      width: 300px!important;
  }
  .td:first-child{
    //   width: 300px!important;
  }
  
    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: unset;
        z-index: 1;
        width: fit-content;
      }
      .th{
          color:black
      }
  
      .td{
          background: rgba(255, 255, 255, 0.95);
          padding:10px;
          text-align:center;
      }
      .th, .td:first-child {
          background: var(--table-row-color);
          font-weight: 500;
          max-width:74px;
          text-align:left;
      }
      .th, .td:nth-child(2)  {
        background: var(--table-row-color);
        font-weight: 500;
        text-align:center;
    }
      .tr:first-child {
          .th {
              font-weight: 700;
          }
      }
  
      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }
  
      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }
  
      .body {
        position: relative;
        z-index: 0;
      }
  
      [data-sticky-td] {
        position: unset;
      }
  
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }
  
      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
  `

const useStyles = makeStyles((theme) => ({
    content: {
       maxWidth:'1800px',
       overflow:'scroll',
       paddingTop:10
    },
}));

function Table({ columns: userColumns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { expanded },
    } = useTable(
        {
            columns: userColumns,
            data,
        },
        useExpanded,// Use the useExpanded plugin hook
        useSticky
    )

    return (
        <>
            <table {...getTableProps()} className="table sticky">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="th">{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="body">
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className="tr">
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()} className="td">{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

        </>
    )
}

function CapLoadingTable({ data }) {
    const classes = useStyles();

    const columns = React.useMemo(
        () => {
            let tableCols = [
                {
                    Header: "Category",
                    columns: [
                        {
                            // Build our expander column
                            id: 'expander', // Make sure it has an ID
                            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                                <span {...getToggleAllRowsExpandedProps()} style={{ display: 'inline-block',cursor:'pointer', overflowX: 'hidden', color: 'orange' }}>
                                     {isAllRowsExpanded ? "▼" : "ᐅ"}
                                </span>
                            ),
                            
                            Cell: ({ row }) =>
                                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                                // to build the toggle for expanding a row
                                row.canExpand ? (
                                    <span
                                        style={{
                                            maxWidth:`${row.depth + 1}rem`,
                                            paddingLeft: `${row.depth }rem`,
                                        }}>
                                        
                                        <span
                                            {...row.getToggleRowExpandedProps()}>
                                            &nbsp;
                                            <span style={{ display: 'inline-block', overflowX: 'hidden', color: 'orange' }}>
                                                {row.isExpanded ? "▼" : "ᐅ"}
                                                {/* <CalendarIcon /> */}
                                            </span>
                                            &nbsp;
                                            <span style={{ display: 'inline-block', overflowX: 'hidden', width: 150 }}>
                                                {row.original.groupId}
                                            </span>
                                        </span>
                                    </span>
                                ) : null
                        },
                        {
                            Header: 'Component',
                            accessor: 'component'
                        }
                    ]


                }
            ]
            const dataCols = Object.keys(data[0]).filter(function (value, index, arr) {
                return value != "component" && value != "subRows"  && value!="level";
            })
            const heads = []
            dataCols.forEach(element => {
                const colHead = element.split("-")[0]
                if (!(heads.includes(colHead))) {
                    heads.push(colHead);
                    const col = {
                        Header: colHead,
                        columns: [
                            {
                                Header: 'Used (H)',
                                accessor: colHead.concat('-').concat('used')
                            },
                            {
                                Header: 'Full (H)',
                                accessor: colHead.concat('-').concat('full')
                            }
                        ]
                    }
                    tableCols.push(col)
                }
            })
            return tableCols
        }, [data]
    )
    return (
        <Styles>
            <div className={classes.content}>
                <Table columns={columns} data={data} />
            </div>

        </Styles>

    )
}

export default CapLoadingTable
