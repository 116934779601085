import { Backdrop, Drawer } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React from 'react';

export default function DrawerPanel({ title = "Open", children, disabled, onOpen }) {
    const [isOpen, setIsOpen] = React.useState(false)

    return (
        <>
            <Button
            style={{margin: '2px'}}
                onClick={() => { setIsOpen(v => !v) ; onOpen && onOpen()}}
                variant="contained"
                disabled={disabled}
                endIcon={
                    <ArrowUpwardIcon fontSize="inherit" />
                } >{title}</Button>
            <Backdrop open={isOpen} onClick={() => setIsOpen(false)}>
            </Backdrop>
            <Drawer variant="persistent" anchor="bottom" open={isOpen}>
                {typeof children === "function" ? children(isOpen) : children}
            </Drawer>
        </>
    )
}
