import { Button } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import OpenIcon from "@material-ui/icons/OpenInBrowser";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import React, { forwardRef, useState, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getColorFromConfiguration } from "../../lib/color";
import { format_to_date } from "../../lib/date_time";
import { loadPlanBoard, selectEntity } from "../../store/actions";
import {
  getColorMappings,
  getDemandOperations,
  getPlanBoard,
} from "../../store/selectors";
import { DemandOpDialog } from "./DemandOpDialog";
import { getAllocationStatusColorLight } from "../../lib/plan_board/utils";
import { FormControlLabel, Switch } from "@material-ui/core";
import { truncate } from "lodash";
import { NoEncryption } from "@material-ui/icons";
import { findAllByDisplayValue } from "@testing-library/dom";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// item_code, order_code, rm_ready_date, quantity,  planned_quantity,
// attributes, "size":"08","color":"B","style":"NA0125","mold_size":"08","mold_type":"G5",
// is_planned, required_time_min - required_time_max

const dateRenderer = (column) => (rowData, renderType) =>
  renderType == "row" && rowData[column] ? format_to_date(rowData[column]) : "";

function Table({ data, colorMappings, onSelect }) {
  const [hideClosedOrders, setHideCloseddOrders] = useState(false);
  //const [showClosedOrders, setShowClosedOrders] = useState(false);
  const demandOperations = useSelector(getDemandOperations);
  const [open, setOpen] = useState(false);
  const planBoard = useSelector(getPlanBoard);
  const dispatch = useDispatch();
  const refresh = () => {
    dispatch(loadPlanBoard(planBoard.id));
  };
  const forceSetOpen = () => {
    setOpen(false);
    refresh();
  };
  //  Switch for hide close orders.
  function handleSwitchChange(e) {
    setHideCloseddOrders(e.target.checked);
    // Add actions here for when the switch is triggered
  }

  return (
    <MaterialTable
      icons={tableIcons}
      columns={[
        // {
        //   title: "ID",
        //   field: "id",
        //   cellStyle: { paddingLeft: 10 },
        //   headerStyle: {
        //     paddingLeft: 10
        //   }
        // },
        { title: "Item Code", field: "item_code" },
        { title: "Release Number", field: "order_release_number" },
        {
          title: "Order",
          field: "order_code",

          render: (rowData, renderType) => {
            if (rowData.is_exchange) {
              return (
                <span
                  style={{
                    padding: 5,
                    borderRadius: 5,
                    backgroundColor: "red",
                    disabled: rowData.is_exchange === true,
                  }}
                >
                  {rowData.order_code}
                </span>
              );
            } else {
              return <span> {rowData.order_code}</span>;
            }
          },
        },
        {
          title: "RM Ready",
          field: "rm_ready_date",
          type: "date",
          render: dateRenderer("rm_ready_date"),
        },
        {
          title: "Confirm Date",
          field: "order_confirmation_date",
          type: "date",
          render: dateRenderer("order_confirmation_date"),
        },
        // { title: "RM Ready", field: "rm_demand_operations", render: (rowD) => <spane>{JSON.stringify(rowD.rm_demand_operations)}</spane> },
        {
          title: "Planned Start",
          field: "planned_start_date",
          type: "date",
          render: dateRenderer("planned_start_date"),
        },
        {
          title: "Planned End",
          field: "planned_end_date",
          type: "date",
          render: dateRenderer("planned_end_date"),
        },
        {
          title: "Need Date",
          field: "required_time_max",
          type: "date",
          render: dateRenderer("required_time_max"),
        },
        // { title: "Planned Between", field: "required_date_max", type: 'date' },
        {
          title: "Delay",
          field: "delay",
          render: (rowData, renderType) => {
            if (
              renderType != "row" ||
              !rowData["planned_end_date"] ||
              !rowData["required_time_max"]
            ) {
              return "";
            }
            const days = moment(rowData["planned_end_date"]).diff(
              rowData["required_time_max"],
              "days"
            );
            if (days < 0) {
              return `${-days} days early`;
            }
            return `${days} days`;
          },
          customSort: (a, b) => {
            const score = (x) =>
              x.quantity > 0 && x.planned_end_date && x.required_time_max
                ? moment(x.planned_end_date).diff(x.required_time_max, "days")
                : -1;
            return score(a) - score(b);
          },
        },
        { title: "Order Group", field: "order_group_code" },
        {
          title: "Configuration",
          field: "configuration",
          render: (rowData, renderType) => {
            const config =
              renderType === "row" ? rowData.configuration : rowData;

            return (
              <span
                style={{
                  padding: 5,
                  borderRadius: 5,
                  backgroundColor: getColorFromConfiguration(
                    colorMappings,
                    config
                  ),
                }}
              >
                {config}{" "}
              </span>
            );
          },
        },

        {
          title: "Skipped",
          field: "skipped_quantity",
          render: (rowData, renderType) => {
            if (renderType === "row") {
              return rowData.skipped_quantity > 0
                ? parseFloat(rowData.skipped_quantity)
                : "";
            } else {
              return "";
            }
          },
        },
        {
          title: "Planned",
          field: "is_planned",
          customSort: (a, b) => a.planned_quantity - b.planned_quantity,
          // customSort: (a, b) => {
          //   const score = x => x.quantity > 0 ? x.planned_quantity - x.quantity : -1

          //   return score(a) - score(b)
          //   //  if(a.planned_quantity === b.planned_quantity){
          //   //    return a.quantity - b.quantity
          //   //  }
          //   //  return a.planned_quantity - b.planned_quantity
          // },
          render: (rowData, renderType) => {
            const is_planned =
              renderType === "row"
                ? rowData.planned_quantity === rowData.quantity
                : rowData;
            const text =
              renderType === "row"
                ? `${parseFloat(rowData.planned_quantity)}/${parseFloat(
                    rowData.quantity
                  )}`
                : is_planned
                ? "Planned"
                : "Not planned";

            return (
              <Button
                style={{
                  padding: 5,
                  borderRadius: 5,
                  backgroundColor: is_planned ? "lightgreen" : "yellow",
                }}
                endIcon={<OpenIcon color="secondary" />}
                onClick={() => onSelect(rowData.id)}
              >
                {text}
              </Button>
            );
          },
        },
        {
          title: "Completed",
          field: "completed_quantity",
          customSort: (a, b) => {
            const score = (x) =>
              x.planned_quantity > 0 ? x.completed_quantity - x.quantity : -1;

            return score(a) - score(b);
            //  if(a.planned_quantity === b.planned_quantity){
            //    return a.quantity - b.quantity
            //  }
            //  return a.planned_quantity - b.planned_quantity
          },
          render: (rowData, renderType) => {
            const text =
              renderType === "row" && rowData.planned_quantity !== 0
                ? `${parseFloat(rowData.completed_quantity)}/${parseFloat(
                    rowData.planned_quantity
                  )}`
                : "-";

            return (
              <Button
              className="btn-info"
                style={{
                  padding: 5,
                  borderRadius: 5,
                   color: "#000000",
                  // backgroundColor: getAllocationStatusColorLight(rowData.planned_quantity, rowData.completed_quantity)
                  backgroundColor: "#c5edfd",

                }}
               
                endIcon={<OpenIcon color="secondary" />}
                onClick={() => onSelect(rowData.id)}
              >
                {text}
              </Button>
            );
          },
        },
        { title: "SMV", field: "smv" },
      ]}
      data={data}
      options={{
        searchFieldStyle: { width: "25%" },
        grouping: true,
        pageSize: 10,
        doubleHorizontalScroll: true,
        maxBodyHeight: 500,
        debounceInterval: 1000,
        padding: "default",
        selection: true,
        selectionProps: (rowData) => ({
          disabled: rowData.is_exchange === true,
          backgroundColor: rowData.is_exchange ? "red" : "",
          data: rowData,
          //color: 'primary'
        }),
        rowStyle: (rowData) => ({
          backgroundColor: rowData.order_is_closed ? "#888888" : "#FFF",
          color: rowData.order_is_closed ? "#1e1f1e" : undefined,
          display: rowData.order_is_closed && hideClosedOrders ? "none" : "",
        }),
      }}
      components={{
        Toolbar: (props) => {
          const { selectedRows } = props;

          return (
            <div>
              <MTableToolbar {...props} />
              {selectedRows.length > 0 && (
                <div style={{ padding: "10px" }}>
                  <Button
                    variant="outlined"
                    className="btn_ btn_primary"
                    onClick={() => {
                      return setOpen(true);
                    }}
                  >
                    Transfer
                  </Button>
                  <DemandOpDialog
                    isOpen={open}
                    values={selectedRows}
                    forceSetOpen={forceSetOpen}
                  />
                </div>
              )}

              <div>
                {/* Switch for hide close orders. */}
                <FormControlLabel
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                  control={
                    <Switch
                      color="primary"
                      title="Hide Closed Orders"
                      value={hideClosedOrders}
                      checked={hideClosedOrders}
                      onChange={handleSwitchChange}
                    />
                  }
                  label={
                    <p
                      style={{ textAlign: "left", fontSize: 12, marginTop: 15 }}
                    >
                      Hide Closed Orders
                    </p>
                  }
                />
              </div>
            </div>
          );
        },
      }}
      title="Jobs"
    />
  );
}

function DemandOperationsTable({ data, onSelect, colorMappings }) {
  return (
    <Table data={data} colorMappings={colorMappings} onSelect={onSelect} />
  );
}

const mapStateToProps = (state, props) => {
  const data = getDemandOperations(state);
  const colorMappings = getColorMappings(state);
  return {
    data,
    colorMappings,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSelect: (id) => {
      props.onClose && props.onClose();
      dispatch(selectEntity("demand_operations", id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemandOperationsTable);
