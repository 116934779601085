import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Link,
  IconButton,
  Collapse,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useState } from "react";
import PropTypes from "prop-types";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, headers, alignHead } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {props.dialog ? <TableCell /> : null}
        {headers.map((headCell) => (
          <TableCell
            style={{ border: "1px solid rgba(0,0,0,0.1)" }}
            key={headCell.id}
            align={alignHead}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {<div style={{ width: "1.5rem" }}></div>}
              {headCell.name}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const getRowColor = (row,detailed,isFlows)=>{
  
  if ((detailed) || isFlows){
    if (row?.delays>0){
      return "dashboard_delayed_row"
    }else{
      return "dashboard_on_time_row"
    }
  }
  
}

const ExpandableRow = (props) => {
  const [open, setOpen] = useState(false);
  const style = { border: "1px solid rgba(0,0,0,0.1)" };
  return (
    <>
      <TableRow className={getRowColor(props.row,props.detailed,props.isFlows)}>
        <TableCell>
          <IconButton
            disabled={props.row.childTasks?.length === 0}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {props.columns.map((column) => (
          <TableCell
            key={column.id}
            style={style}
            align={column.align || props.alignCell}
          >
            {props.row[column.id]}
          </TableCell>
        ))}
      </TableRow>
    
      {props.row.childTasks?.length > 0 && open ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="child-tasks">
                  <TableHead>
                    <TableRow>
                      {["Task Code", "Task Name", "Assignee","Due Complete","Actual Complete Time","Delay (D)"].map(
                        (header) => {
                          return (
                            <TableCell style={style} align="center">
                              {header}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.row.childTasks?.map((child) => (
                        <TableRow key={child.id} className={getRowColor(props.row,props.detailed,props.isFlows)}>
                          <TableCell style={style} align="center">
                            {child.taskCode}
                          </TableCell>
                          <TableCell style={style} align="left">
                            {child.name}
                          </TableCell>
                          <TableCell style={style} align="left">
                            {child.user}
                          </TableCell>
                          <TableCell style={style} align="center">
                            {child.due}
                          </TableCell>
                          <TableCell style={style} align="center">
                            {child.actual || `Not completed yet`}
                          </TableCell>
                          <TableCell style={style} align="right">
                            {child.delays}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
       
      ) : null}
    </>
  );
};

const CustomTable = ({
  detailed,
  style,
  data,
  columns,
  maxHeight,
  order,
  orderBy,
  colgroups = [],
  useTableHeader = true,
  alignCell = "center",
  setOrder,
  setOrderBy,
  alignHead = "center",
  className,
  isFlows,
  showTasks,
  setShowTasks,
  setFlowDetails,
  dialog,
  setSelectedFlowId,
  setPopupDataLoading,
  currentTab
}) => {
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  
  return data.length > 0 ? (
    <TableContainer
      component={Paper}
      style={{ ...style, maxHeight: maxHeight }}
      className={className}
    >
      <Table stickyHeader aria-label="sticky table" >
        {useTableHeader && (
          <EnhancedTableHead
            dialog={dialog}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={columns}
            alignHead={alignHead}
          />
        )}
        {colgroups.length > 0 && (
          <colgroups>
            {colgroups.map((percentage) => (
              <col style={{ width: `${percentage}%` }}></col>
            ))}
          </colgroups>
        )}
        <TableBody>
          {data.length > 0 &&
            data.sort(getComparator(order, orderBy)).map((row) => {
              return dialog ? (
                <ExpandableRow
                  detailed={detailed}
                  isFlows={isFlows}
                  row={row}
                  columns={columns}
                  setShowTasks={setShowTasks}
                  setFlowDetails={setFlowDetails}
                  alignCell={alignCell}
                />
              ) : (
                <TableRow className={getRowColor(row,detailed,isFlows)}  >
                  {columns.map((column) =>
                    isFlows && ["flow_code", "order"].includes(column.id) ? (
                      <TableCell
                        key={column.id}
                        style={{ border: "1px solid rgba(0,0,0,0.1)"}}
                        align={column.align || alignCell}
                      >
                       {currentTab === "Submitted"?  row[column.id] :<Link
                          component="button"
                          variant="body1"
                          onClick={() => {
                            setShowTasks(true);
                            setPopupDataLoading(true)
                            setSelectedFlowId(row.order_id)
                            setFlowDetails(row.flow_code || row.order);
                          }}
                        >
                          {row[column.id]}
                          </Link>
                          }
                      
                      </TableCell>
                    ) : (
                      <TableCell
                        key={column.id}
                        style={{ border: "1px solid rgba(0,0,0,0.1)" }}
                        align={column.align || alignCell}
                      >
                        {row[column.id]}
                      </TableCell>
                    )
                  )}{" "}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Typography variant="h6" className="alert alert-danger text-center text-danger">No data found</Typography>
  );
};

CustomTable.propTypes = {
  maxHeight: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object.isRequired),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.any.isRequired,
  colgroups: PropTypes.arrayOf(PropTypes.number),
  useTableHeader: PropTypes.bool,
  alignCell: PropTypes.string,
  setOrder: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
};

export default CustomTable;
