import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import {
  CATEGORY_CODES,
  CLUSTER_CODES,
  getYears,
  SEASON_CODES,
  SERIAL_TYPES,
} from "./InquiryConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  SUBMIT_IQ_SERIAL,
  TEMPLATE_RESET_UI_STATE,
} from "../../../store/types/tnaTaskTypes";
import {
  getErrorSubmitIqSerial,
  getSuccessSubmitIqSerial,
} from "../../../store/selectors/tnaTaskSelectors";
import { Title, Loading, useQuery } from "react-admin";
import { showToastSuccess, showToastError } from "../../../lib/toas";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import { theme as Theme } from "../../../layout/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: "20px",
    width: "80%",
  },
  formControl: {
    // margin: theme.spacing(1),
    width: "50%",
  },
  btn: {
    backgroundColor: "primary",
    minWidth: "120px",
    margin: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
    color: Theme.palette.primary.main,
  },
  //   formWrapper:{
  //       width:
  //   }
}));

export const InquirySerialVerifier = () => {
  const classes = useStyles();
  const [cluster, setCluster] = useState();
  const [year, setYear] = useState();
  const [season, setSeason] = useState();
  const [category, setCategory] = useState();
  const [serialType, setSerialType] = useState();
  const [serialVersion, setSerialVersion] = useState();
  const [serial, setSerial] = useState();
  const [incomplete, setIncomplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState();
  const dispatch = useDispatch();
  const years = getYears();
  const success = useSelector(getSuccessSubmitIqSerial);
  const error = useSelector(getErrorSubmitIqSerial);
  const initialData = {
    cluster: [{}],
    year: [{}],
    season: [{}],
    category: [{}],
    type: [{}],
  };
  const [data, setData] = useState(initialData);

  const selectOptions = [
    {
      key: "Cluster Code",
      value: data.cluster[0],
      getter: cluster,
      setter: setCluster,
    },
    {
      key: "Year",
      value: data.year[0],
      getter: year,
      setter: setYear,
    },
    {
      key: "Season Code",
      value: data.season[0],
      getter: season,
      setter: setSeason,
    },
    {
      key: "Category Code",
      value: data.category[0],
      getter: category,
      setter: setCategory,
    },
    {
      key: "Type",
      value: data.type[0],
      getter: serialType,
      setter: setSerialType,
    },
  ];

  const code =
    (cluster || "") +
    `${cluster ? "/" : ""}` +
    (year || "") +
    `${year ? "/" : ""}` +
    (season || "") +
    `${season ? "/" : ""}` +
    (category || "") +
    `${category ? "/" : ""}` +
    (serialType || "") +
    (serialVersion || "") +
    `${serialVersion ? "/" : ""}` +
    (serial || "");

  const createHandler = () => {
    if (
      cluster &&
      year &&
      season &&
      category &&
      serialType &&
      serialVersion &&
      serial
    ) {
      setLoading(true);
      dispatch({
        type: SUBMIT_IQ_SERIAL,
        payload: { code: code, comments: comments },
      });
    } else {
      setIncomplete(true);
      showToastError("Please fill all fields to continue");
      setTimeout(() => {
        setIncomplete(false);
      }, 2000);
    }
  };

  const { Loading, Error } = useQuery(
    {
      type: "TNA_CUSTOM_ACTION",
      resource: "dropdown",
      payload: {
        action: "fetch_inquiry_serial_data",
        method: "GET",
      },
    },
    {
      onFailure: (e) => {},
      onSuccess: (response) => {
        // console.log("something", response.data.data);
        setData(response?.data?.data);
      },
    }
  );

  useEffect(() => {
    if (success.length > 0) {
      setLoading(false);
      showToastSuccess(success);
    } else if (error.length > 0) {
      setLoading(false);
    }
    setTimeout(() => {
      dispatch({ type: TEMPLATE_RESET_UI_STATE });
    }, 3000);
  }, [success, error]);
  return (
    <>
      <Title title="Inquiry Serial Submit" />
      <Grid container className={classes.root} sm={12} md={6}>
        {selectOptions.map((o) => (
          <FormControl className={classes.formControl}>
            <InputLabel>{o.key}</InputLabel>
            <Select value={o.getter} onChange={(e) => o.setter(e.target.value)}>
              {Object.keys(o.value).map((v) => (
                <MenuItem key={v} value={v}>
                  {o.value[v]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
        {/* <div style={{ minWidth: "200px", marginRight: "20px" }}> */}
        <TextField
          id="standard-basic"
          label="Version"
          variant="standard"
          value={serialVersion}
          onChange={(e) => {
            setSerialVersion(e.target.value);
          }}
        />
        <TextField
          id="standard-basic"
          label="Serial Number"
          variant="standard"
          value={serial}
          onChange={(e) => {
            setSerial(e.target.value);
          }}
        />
        <TextField
          id="standard-basic"
          label="Full Inquiry Serial"
          variant="standard"
          value={code}
          disabled
        />
        <TextField
          id="standard-basic"
          label="Comments"
          variant="standard"
          value={comments}
          onChange={(e) => {
            setComments(e.target.value);
          }}
        />
        <Grid container justify="flex-end">
          <Button
            className="btn_ btn_primary mt-3 "
            size="large"
            onClick={createHandler}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                className={classes.circularProgress}
                size={20}
              />
            )}
            Submit Serial
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
