import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Preview from "./Preview";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { LOAD_TASKS, MODIFY_FLOW } from "../../../store/types";
import CloseIcon from "@material-ui/icons/Close";
import {
  getAllUsers,
  getTasks,
  getInqDescription,
} from "../../../store/selectors/tnaTaskSelectors";
import { Loading } from "react-admin";
import { useHistory } from "react-router-dom";

export const PreviewModal = ({
  showModal,
  onSetShowModal,
  onConfirm,
  onCancel,
  onTentative,
  flowType,
  isLoading,
  parent,
}) => {
  const tasks = useSelector(getTasks);
  const tna_users = useSelector(getAllUsers);
  const inqDescription = useSelector(getInqDescription);
  const [changedList, setChangedList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const onSaveUsers = () => {
    dispatch({ type: MODIFY_FLOW, payload: changedList });
  };

  useEffect(() => {
    const unloadEvent = (e) => {
      onCancel();
    }
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (window.confirm("Do you want to go back?")) {
        history.goBack();
      } else {
        onCancel();
        history.go(1);
      }
    }
    if (showModal) {
      window.addEventListener("beforeunload", unloadEvent);
      window.addEventListener("popstate", onBackButtonEvent)
    }
    return () => {
      window.removeEventListener("beforeunload", unloadEvent);
      window.removeEventListener("popstate", onBackButtonEvent);
    }
  },[showModal])

  const onRefresh = () => {
    setChangedList(
      tasks.map((t) => {
        return { id: t.taskId, assignee: t.assignee };
      })
    );
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={showModal}
        onClose={
          parent === "tentativeFlowItem"
            ? () => {
                onSaveUsers();
                setTimeout(() => {
                  onTentative();
                }, 1000);
              }
            : onCancel
        }
        aria-label="Are you sure?"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ paddingTop: "10px" }}>
              {["IQ"].includes(flowType) && <p>{!isLoading ? inqDescription: ""}</p>}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
                cursor: "pointer",
              }}
            >
              <CloseIcon
                onClick={
                  parent === "tentativeFlowItem"
                    ? () => {
                        onSaveUsers();
                        setTimeout(() => {
                          onTentative();
                        }, 1000);
                      }
                    : onCancel
                }
              />
            </div>
          </Grid>
        </Grid>

        <DialogContent style={{ paddingTop: 0 }}>
          {isLoading ? (
            <Loading loadingSecondary="Content is loading, just a moment please" />
          ) : (
            <Preview
              tasks={tasks}
              users={tna_users}
              changedList={changedList}
              setChangedList={setChangedList}
            />
          )}
          <DialogActions>
            <Button
              disabled={isLoading}
              className={isLoading ? "btn_ btn_primary" : "btn_ btn_delete"}
              onClick={() => {
                onCancel();
              }}
            >
              Delete
            </Button>
            <Button
              disabled={isLoading}
              className="btn_ btn_primary"
              onClick={() => {
                onRefresh();
              }}
            >
              Reset to default
            </Button>
            <Button
              disabled={isLoading}
              className="btn_ btn_primary"
              onClick={() => {
                onSaveUsers();
                setTimeout(() => {
                  onTentative();
                }, 1000);
              }}
            >
              Tentative
            </Button>
            <Button
              disabled={isLoading}
              className="btn_ btn_primary"
              onClick={() => {
                onSaveUsers();
                setTimeout(() => {
                  onConfirm();
                }, 1000);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
