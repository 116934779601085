import {
  LOAD_SDN_SUCCESS,
  QR_SEND_ERROR,
  FETCH_REPEATED_ORDERS,
  LOAD_REPEATED_ORDERS,
  CONFIRM_TEMPLATE_SUCCESS,
  CONFIRM_TEMPLATE_ERROR,
  DELETE_FLOW_ERROR,
  LOAD_TEMPLATE,
  LOAD_SDN,
  DELETE_FLOW_SUCCESS,
  FLOW_STATUS_CHANGE_FAIL,
  MODIFY_FLOW_SUCCESS,
  MODIFY_FLOW_ERROR,
  LOAD_ALL_USERS_SUCCESS,
  LOAD_ALL_TASKS,
  LOAD_UNASSIGNED_TASKS,
  SET_ALL_TASKS,
  SET_UNASSIGNED_TASKS,
  SET_TNA_TASK_LOAD_SUCCESS,
  COMPLETE_TASK,
  SET_TASK_COMPLETE,
  START_TASK,
  SET_TASK_STARTED,
  SET_TASK_TRANSFERRED_TO_APPROVAL,
  UPLOAD_FORM,
  GENERATE_AND_DOWNLOAD_FORM,
  SET_UPLOAD_FORM_SUCCESS,
  SET_UPDATED_TASK,
  GENERATE_TEMPLATE_SUCCESS,
  LOAD_TEMPLATE_SUCCESS,
  QR_ACCEPTANCE,
  FETCH_TENTATIVE_ORDERS_SUCCESS,
  FETCH_SELECTED_TENTATIVE_FLOW_SUCCESS,
  SUBMIT_IQ_SERIAL_SUCCESS,
  SUBMIT_IQ_SERIAL_FAILED,
  LOAD_RP_SDN_SUCCESS,
  GENERATE_TEMPLATE_ERROR,
} from "../types/tnaTaskTypes";
import { arrangeResponse } from "../../lib/taskPreview";

export const loadUnassignedTasks = () => {
  return { type: LOAD_UNASSIGNED_TASKS, payload: {} };
};

export const loadALLTasks = () => {
  return { type: LOAD_ALL_TASKS, payload: {} };
};

export const setAllTasks = (data: any) => {
  return { type: SET_ALL_TASKS, payload: { data } };
};

export const setUnassignedTasks = (data: any) => {
  return { type: SET_UNASSIGNED_TASKS, payload: { data } };
};

export const setLoadSuccess = () => {
  return { type: SET_TNA_TASK_LOAD_SUCCESS };
};

export const completeTask = (data: any, transferedUser: any) => {
  return { type: COMPLETE_TASK, payload: { data, transferedUser } };
};

export const setTaskComplete = (data: any) => {
  return { type: SET_TASK_COMPLETE, completedTask: data };
};

export const startTask = (data: any) => {
  return { type: START_TASK, startingTask: data };
};

export const setTaskStarted = (data: any) => {
  return { type: SET_TASK_STARTED, startedTask: data };
};

export const setTaskTransferredToApproval = (data: any) => {
  return { type: SET_TASK_TRANSFERRED_TO_APPROVAL, transferredTask: data };
};

export const generateAndDownloadForm = (data: any) => {
  return { type: GENERATE_AND_DOWNLOAD_FORM, payload: data }
}

export const uploadForm = (data: any) => {
  return { type: UPLOAD_FORM, payload: data };
};

export const setUploadFormSuccess = () => {
  return { type: SET_UPLOAD_FORM_SUCCESS };
};

export const updateTask = (data: any) => {
  return { type: SET_UPDATED_TASK, updatedTask: data };
};

export const generateTemplate = (data: any) => {
  return {
    type: GENERATE_TEMPLATE_SUCCESS,
    payload: {
      successMessage: "Flow Generated Successfully",
      order: data.GeneratedFlow[0].order,
      tasks: arrangeResponse(data.GeneratedFlow),
      inqDescription: data.GeneratedFlow[0].template_description,
    },
  };
};

export const generateTemplateError = () => {
  return {
    type: GENERATE_TEMPLATE_ERROR,
    payload: {
      error: "Flow Generation Failed"
    },
  };
};

export const loadAllSuccessUsers = (data: any) => {
  return {
    type: LOAD_ALL_USERS_SUCCESS,
    payload: data.results?.filter((r: any) =>
      [
        "TNA_ADMIN",
        "TNA_CLUSTER_MANAGER",
        "TNA_DEP_ADMIN",
        "TNA_MANAGER",
        "TNA_PLANNER",
        "TNA_USER",
      ].includes(r.app_user.access_level)
    ),
  };
};

export const modifyFlowError = () => {
  return { type: MODIFY_FLOW_ERROR };
};

export const setDeleteOrderSuccess = () => {
  return {
    type: DELETE_FLOW_SUCCESS,
    payload: "Template Deleted Successfully!",
  };
};

export const setStatusChangeFail = () => {
  return {
    type: FLOW_STATUS_CHANGE_FAIL,
    payload: "Child tasks not assigned to user",
  };
};

export const loadSDNs = (data: any) => {
  return { type: LOAD_SDN, payload: data };
};

export const loadTemplate = () => {
  return { type: LOAD_TEMPLATE };
};

export const setDeleteOrderError = () => {
  return { type: DELETE_FLOW_ERROR, payload: "Error Occured. Try again!" };
};

export const setConfirmTemplateSuccess = () => {
  return { type: CONFIRM_TEMPLATE_SUCCESS, payload: "Flow Status Updated" };
};

export const setConfirmTemplateError = () => {
  return {
    type: CONFIRM_TEMPLATE_ERROR,
    payload: "Error Occured Updating Status. Try again!",
  };
};

export const setModifyFlowSuccess = () => {
  return { type: MODIFY_FLOW_SUCCESS };
};

export const setModifyFlowError = () => {
  return { type: MODIFY_FLOW_ERROR };
};

export const loadRepeatedOrders = (data: any) => {
  return { type: LOAD_REPEATED_ORDERS, payload: data };
};

export const fetchRepeatedOrders = (data: any) => {
  return { type: FETCH_REPEATED_ORDERS, payload: data };
};

export const setQRSendError = () => {
  return { type: QR_SEND_ERROR, payload: "Error Occured. Try again!" };
};

export const loadSDNSuccess = (data: any) => {
  return { type: LOAD_SDN_SUCCESS, payload: data };
};

export const loadRPSDNSuccess = (data: any) => {
  return { type: LOAD_RP_SDN_SUCCESS, payload: data };
};

export const loadTemplateSuccess = (data: any) => {
  return { type: LOAD_TEMPLATE_SUCCESS, payload: data };
};

export const qrAcceptance = (data: any) => {
  return { type: QR_ACCEPTANCE, payload: data };
};

export const fetchTentativeOrdersSuccess = (data: any) => {
  return {
    type: FETCH_TENTATIVE_ORDERS_SUCCESS,
    payload: data.tentativeOrders,
  };
};

export const fetchSelectedTentativeFlowSuccess = (data: any) => {
  return {
    type: FETCH_SELECTED_TENTATIVE_FLOW_SUCCESS,
    payload: {
      flow: arrangeResponse(data.selectedFlow),
      inqDescription: data.selectedFlow[0].template_description,
      successMessage: "Flow loaded Successfully",
    },
  };
};

export const setSubmitIQSerialSuccess = (data: any) => {
  return { type: SUBMIT_IQ_SERIAL_SUCCESS, payload: { message: data } };
};

export const setSubmitIQSerialError = (data: any) => {
  return { type: SUBMIT_IQ_SERIAL_FAILED, payload: { message: data } };
};
