import {
  Create,
  SimpleForm,
  TextInput,
  SelectArrayInput,
  ReferenceArrayInput,
  useInput,
  required,
  useDataProvider,
  Query,
} from "react-admin";
import React, { useEffect } from "react";
import { PLAN_EXPORT_COLUMNS as plan_export_columns } from "../../../constants";
import { MenuItem, Select, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

export default function PlanExportColumnSelector(props) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);

  const {
    input: { onChange, ...rest },
    meta: { touched, error, modified, initial },
    isRequired,
  } = useInput(props);

  console.log({ props, rest, initial });

  const export_type = props?.formData.export_type;

  const dataProvider = useDataProvider();

  if (props.formData.plant && props.formData.export_type) {
    return (
      <Query
        type="getOne"
        resource="plants"
        payload={{ id: props.formData.plant }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <div></div>;
          }
          if (error) {
            return <div></div>;
          }
          console.log(data);
          const plant_plan_export_columns = data.plan_export_columns;
          console.log({ plant_plan_export_columns });
          if (plant_plan_export_columns)
            return (
              <div style={{ margin: "5px" }}>
                <Typography>Plan export fields select</Typography>

                <Select
                  autoWidth
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  {...rest}
                  onChange={onChange}
                  multiple={true}
                  translateChoice={false}
                  //   onChange={(event) => onSelect(event)}
                  value={
                    rest.value[0]
                      ? rest.value.filter((a) =>
                          export_type === "SCOM"
                            ? plant_plan_export_columns
                                .filter((b) =>
                                  plan_export_columns.SCOM.includes(b)
                                )
                                .includes(a)
                            : export_type === "SCOM_DATEWISE"
                            ? plant_plan_export_columns
                                .filter((b) =>
                                  plan_export_columns.SCOM_DATEWISE.includes(b)
                                )
                                .includes(a) :
                                plant_plan_export_columns
                                .filter((b) =>
                                  plan_export_columns.DSI.includes(b)
                                )
                                .includes(a)
                        )
                      : []
                  }
                >
                  {export_type === "SCOM"
                    ? plan_export_columns.SCOM.filter((a) =>
                        plant_plan_export_columns.includes(a)
                      ).map((a) => <MenuItem value={a}>{a}</MenuItem>)
                    : export_type === "SCOM_DATEWISE"
                    ? plan_export_columns.SCOM_DATEWISE.filter((a) =>
                        plant_plan_export_columns.includes(a)
                      ).map((a) => <MenuItem value={a}>{a}</MenuItem>)
                    : plan_export_columns.DSI.filter((a) =>
                        plant_plan_export_columns.includes(a)
                      ).map((a) => <MenuItem value={a}>{a}</MenuItem>)}
                </Select>
              </div>
            );
        }}
      </Query>
    );
  } else return <div></div>;
}
