import React, { Component } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './progressLoading.css'

class ProgressLoading extends React.Component {

    render() { 
        const planLodaingStyle = { 
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "200px",
            textAlign: "center"
         };

        return (
            <div id="plan-loading-pallet" style={planLodaingStyle}>
                <div>
                    <h1>Planning</h1>
                    <p>Our system is performing some tricks. Your plan will be ready in a bit.</p>
                    <LinearProgress style={{ width: "600px" }} className={styles.MuiLinearProgressColorPrimary} variant="determinate" value={this.props.data} />
                    <br/>
                    <br/>
                    <h3>{this.props.data}%</h3>
                </div>
            </div>
        );
    }
}
 
export default ProgressLoading;