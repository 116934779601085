import React from "react";
import { Loading, Query } from "react-admin";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { formatNodes, TreeCheckbox } from "../../components/TreeCheckbox";

export const PreparedTreeCheckbox = (props) => {
  const { sub } = props;
  return (
    <Query
      type="CUSTOM_LIST"
      resource="calendar"
      payload={{
        pagination: { page: 1, perPage: 1000000 },
        sort: { field: "id", order: "ASC" },
        query: { sub },
        action: "get_lines_of_planboard",
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return "Error";
        }

        return (
          <>
            <TreeCheckbox
              nodes={formatNodes(data, sub)}
              checked={props.checked}
              expanded={props.expanded}
              setChecked={props.setChecked}
              setExpanded={props.setExpanded}
            />
          </>
        );
      }}
    </Query>
  );
};
