import React from "react";
import {
  // CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  Filter,
  List,
  sanitizeListRestProps,
  // ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  usePermissions,
  WithPermissions,
  ReferenceField,
} from "react-admin";
import CreateButton from "../../../components/CustomCreateButton";
import ShowButton from "../../../components/CustomShowButton";
import Confirmation from "../../../components/Confirmation";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../../components/ChangeDiv";
import { CustomDatagrid } from "../../../components/CustomDatagrid";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const ListActions = (props) => {
  const classes = useStyles();

  const createPermissions = () =>
    !props.permissions?.permissions.includes("tna_departments_create");
  const exportPermissions = () =>
    !props.permissions?.permissions.includes("tna_departments_export");

  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar className={classes.buttonContainer}>
      <CreateButton
        basePath={basePath}
        title="permissions"
        disabled={createPermissions()}
        variant="contained"
        color="primary"
      />
      <ExportButton
        title="permisswwions"
        disabled={exportPermissions()}
        resource={resource}
        sort={currentSort}
        maxResults={maxResults}
        variant="contained"
        // color="primary"
        className="btn_ btn_primary"
      />
    </TopToolbar>
  );
};

export const Departments = (props) => {
  const { permissions } = usePermissions();
  const Title = () => {
    return <span>TNA Departments</span>;
  };

  return (
    <List
      {...props}
      title={<Title/>}
      actions={<ListActions permissions={permissions} />}
      bulkActionButtons={false}
      filter={["TNA"].includes(permissions?.access_level) ? {} : null}
      perPage={25}
    >
      <ChangeDiv>
        {props => {
          return <CustomDatagrid resource={props.resource}>
          <TextField width="10%" source="id" />
          <TextField width="12%" source="code" />
          <TextField width="12%" source="name" label="Department" />
          <ReferenceField
          width="21%"
            label="Admin User"
            source="admin_user"
            reference="users"
            sortBy = "admin_user__username"
            emptyText="N/A"
          >
            <TextField source="username"></TextField>
          </ReferenceField>
          <EditButton
          width="15%"
            {...props}
            className="btn_ btn_edit"
            title="permissions"
            disabled={
              !permissions?.permissions.includes("tna_departments_edit")
            }
          />
          <Confirmation
          width="15%"
            title="permissions"
            disabled={
              !permissions?.permissions.includes("tna_departments_delete")
            }
            text="tna departments"
          />
          <ShowButton
          width="15%"
            title="permissions"
            disabled={
              !permissions?.permissions.includes("tna_departments_edit")
            }
          />
        </CustomDatagrid>
        }}
      </ChangeDiv>
        
     
    </List>
  );
};
