import React, { useState } from "react";

import FormUploadConfirmationDialog from "../../FormUploadConfirmationDialog";
import Button from "@material-ui/core/Button";

export const Form1 = (props) => {
  // const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);

  const exportProps = props.exportProps;
  const onHandle2 = props?.onHandle2;

  const firstColumnWidth = "21.2531%";
  const secondColumnWidth = "71.7469%";

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Sample Requisition</h2>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <label>Form Number (FN)</label>
        <input class='form-input'
          type="text"
          name="formNumber"
          onChange={(e) => onHandle2(e)}
          {...exportProps("formNumber")}
          // name="MCoordinator"

          required
        />
      </div>

      <table class='form-table' style={{ width: "100%" }} border={1} cellPadding={10}>
        <tbody>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>Category</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="category"
                onChange={(e) => onHandle2(e)}
                {...exportProps("category")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>Designer responsible</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="DesignerResponsible"
                onChange={(e) => onHandle2(e)}
                {...exportProps("DesignerResponsible")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>Requested on</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="requsedOn"
                onChange={(e) => onHandle2(e)}
                {...exportProps("requsedOn")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: "21.7469%", textAlign: "center" }}>
              <b>Sample plc, Submission</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="submittion"
                onChange={(e) => onHandle2(e)}
                {...exportProps("submittion")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  colspan="2" style={{ textAlign: "center" }}>
              <b>Product Market and Description</b>
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>Brand / sub Brand</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="subBrand"
                onChange={(e) => onHandle2(e)}
                {...exportProps("subBrand")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Propose</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: 10, paddingLeft: 30 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>Core 1</label>
                  <input class='form-input'
                    type="checkbox"
                    name="core1"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("core1")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  <label htmlFor="core1" style={{paddingRight: 5}}>Core 2</label>
                  <input class='form-input'
                    type="checkbox"
                    name="core2"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("core2")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  <label htmlFor="core1" style={{paddingRight: 5}}>Fashion 1</label>
                  
                  <input class='form-input'
                    type="checkbox"
                    name="Fashion1"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("Fashion1")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  <label htmlFor="core1" style={{paddingRight: 5}}>Fashion 2</label>
                  <input class='form-input'
                    type="checkbox"
                    name="Fashion2"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("Fashion2")}
                  />
                </div>
                <div style={{ paddingRight: 5, paddingLeft: 5 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}> High Fashion</label>
                  <input class='form-input'
                    type="checkbox"
                    name="highFashion"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("highFashion")}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Gender</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: 10, paddingLeft: 30 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>Male</label>
                  <input class='form-input'
                    type="checkbox"
                    name="male"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("male")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>Female</label>
                  <input class='form-input'
                    type="checkbox"
                    name="female"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("female")}
                  />
                </div>

                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>Boy</label>
                  <input class='form-input'
                    type="checkbox"
                    name="boy"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("boy")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  <label htmlFor="core1" style={{paddingRight: 5}}>Girl</label>
                  <input class='form-input'
                    type="checkbox"
                    name="girl"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("girl")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  <label htmlFor="core1" style={{paddingRight: 5}}>Unisex</label>
                  <input class='form-input'
                    type="checkbox"
                    name="uni"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("uni")}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>Target Market</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: 10, paddingLeft: 30 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>Low</label>
                  <input class='form-input'
                    type="checkbox"
                    name="low"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("low")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>Middle</label>
                  <input class='form-input'
                    type="checkbox"
                    name="middle"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("middle")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>Upper</label>
                  <input class='form-input'
                    type="checkbox"
                    name="upper"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("upper")}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>Demography Lifestyle</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              Occasion-
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="Occasion"
                onChange={(e) => onHandle2(e)}
                {...exportProps("Occasion")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Reason for the Requisition</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="requsition"
                onChange={(e) => onHandle2(e)}
                {...exportProps("requsition")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>If it's Replacement</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="replacement"
                onChange={(e) => onHandle2(e)}
                {...exportProps("replacement")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>If it's Market need</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="MarketNeed"
                onChange={(e) => onHandle2(e)}
                {...exportProps("MarketNeed")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Volume details (expected 4 months volume)</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="volumeDetail"
                onChange={(e) => onHandle2(e)}
                {...exportProps("volumeDetail")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>Season and relevant requirements</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: 10, paddingLeft: 30 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>S1</label>
                  <input class='form-input'
                    type="checkbox"
                    name="s1"
                    onChange={(e) => onHandle2(e)}
                    
                    {...exportProps("s1")}
                    //  checked={  {...exportProps("s1")}}


                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>S2</label>
                  <input class='form-input'
                    type="checkbox"
                    name="s2"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("s2")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>M1</label>
                  <input class='form-input'
                    type="checkbox"
                    name="m1"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("m1")}
                  />
                </div>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  
                  <label htmlFor="core1" style={{paddingRight: 5}}>M2</label>
                  <input class='form-input'
                    type="checkbox"
                    name="m2"
                    onChange={(e) => onHandle2(e)}
                    {...exportProps("m2")}
                    
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>Transfer price</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="TransferPrice"
                onChange={(e) => onHandle2(e)}
                {...exportProps("TransferPrice")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              <b>Size range</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="sizeTange"
                onChange={(e) => onHandle2(e)}
                {...exportProps("sizeTange")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Sole Type</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="soleType"
                onChange={(e) => onHandle2(e)}
                {...exportProps("soleType")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Heal Type/Height</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="HealType"
                onChange={(e) => onHandle2(e)}
                {...exportProps("HealType")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Upper Material</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="upperMat"
                onChange={(e) => onHandle2(e)}
                {...exportProps("upperMat")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Required Features</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="reqFeture"
                onChange={(e) => onHandle2(e)}
                {...exportProps("reqFeture")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Special Requirements</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="spReqiurements"
                onChange={(e) => onHandle2(e)}
                {...exportProps("spReqiurements")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  style={{ width: firstColumnWidth }}>
              
              <b>Running Item Image & Item code</b>
            </td>
            <td class='form-td'  style={{ width: secondColumnWidth }}>
              <input class='form-input'
                type="text"
                style={{ width: "100%", height: "100%" }}
                name="RunningItem"
                onChange={(e) => onHandle2(e)}
                {...exportProps("RunningItem")}
                required
              />
            </td>
          </tr>
          <tr>
            <td class='form-td'  colspan="2" style={{ textAlign: "center" }}>
              
              <b>Design Specifications</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// return (
//   <div>
//     <form onSubmit={handleSubmit}>
//       <Form />
//       <Button variant="contained" type="submit">
//         Upload
//       </Button>
//     </form>
//     <FormUploadConfirmationDialog
//       isOpen={openFormUploadDialog}
//       setIsOpen={setOpenFormUploadDialog}
//       Form={Form()}
//       formData={state}
//       formName="SampleRequisition"
//     />
//   </div>
// );


const SampleRequisition = ({toEdit, prevFormData, prevFormName, formName }) => {
  const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);
  // const [Form, setForm] = useState();

  const [data, setData] = React.useState(toEdit ? prevFormData : {
    formNumber: "",
    category: "",
    DesignerResponsible: "",
    requsedOn: "",
    submittion: "",
    subBrand: "",
    core1: "",
    core2: "",
    Fashion1: "",
    Fashion2: "",
    highFashion: "",
    male: "",
    female: "",
    boy: "",
    girl: "",
    uni: "",
    low: "",
    middle: "",
    upper: "",
    requsition: "",
    Occasion: "",
    replacement: "",
    MarketNeed: "",
    volumeDetail: "",
    relevantRequirements: "",
    s1: "",
    s2: "",
    m1: "",
    m2: "",
    TransferPrice: "",
    sizeTange: "",
    HealType: "",
    soleType: "",
    spReqiurements: "",
    desinerSpecifications: "",
    RunningItem: "",
    upperMat: "",
    reqFeture: "",
  });

  const onHandle2 = (e) => {
    const inputType=e.target.type

    if(inputType==="checkbox"){
      const isChecked=e.target.checked
      setData({
        ...data,
        [e.target.name]: isChecked,
      });
    }
    else{
      const value=e.target.value
      setData({
        ...data,
        [e.target.name]: value,
      });
    }



    // data[e.target.name] = value === "" ? true : value
    
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setOpenFormUploadDialog(true);
      event.preventDefault();
      event.stopPropagation();
    }
    setOpenFormUploadDialog(true);
    event.preventDefault();
    event.stopPropagation();
  };

  const exportProps = (field) => {
    if (data[field]===true) {
      return { 'checked': 'true', value: data[field]  };
    }
    return { value: data[field] };

  };

  const uiProps = (field) => {
    if (data[field] === true) {
      return {defaultChecked: true, defaultValue: data[field]};
    }
    return {defaultValue: data[field]}
  }


  return (
    <div>
      <form onSubmit={handleSubmit}>
        < Form1
          exportProps={uiProps}
          onHandle2={onHandle2}
        />
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </form>
      <FormUploadConfirmationDialog
        isOpen={openFormUploadDialog}
        setIsOpen={setOpenFormUploadDialog}
        Form={Form1({ exportProps, onHandle2 })}
        formData={data}
        prevFormName={prevFormName}
        formName={formName}
      />
    </div>
  );
};


export default SampleRequisition;