import React from "react";
import { TextField, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear"
import { format_to_date } from "../lib/date_time";
import moment from "moment";

import MomentUtils from "@date-io/moment";
import { makeStyles } from '@material-ui/core/styles';

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";


const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "10px"
    }
  }
});

export function NumberColumnFilter({ column: { filterValue, setFilter } }) {

  const classes = useStyles();
  return (
    <TextField
      InputProps={{ inputProps: { min: 0 } }}
      classes={{ root: classes.customTextField }}
      type="number"
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Search"
      style={{ margin: "0px", padding: "0px" }}
    />
  );
}

export function comparativeFilter(rows, id, filterValue) {
  if (filterValue.includes('<')) {
    return rows.filter(row => {
      const rowValue = row.values[id[0]]
      return parseInt(rowValue) < parseInt(filterValue.substring(1))
    })
  } else if (filterValue.includes('>')) {
    return rows.filter(row => {
      const rowValue = row.values[id[0]]
      return parseInt(rowValue) > parseInt(filterValue.substring(1))
    })
  } else return rows.filter(row => {
    const rowValue = row.values[id[0]]
    return parseInt(rowValue) == parseInt(filterValue)
  })
}

export function DateColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const onChange = (e) => {
    console.log({ e });
    const value = format_to_date(e);
    setFilter(e ? value : e);
    // if (e) {
    //   setFilter(value); // Set undefined to remove the filter entirely
    // }
  };

  const classes = useStyles();

  const handleClr = (e) => {
    e.stopPropagation();
    onChange(null);
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        autoOk
        classes={{ root: classes.customTextField }}
        variant="inline"
        format="DD/MM/YYYY"
        margin="normal"
        size="small"
        value={filterValue ? moment(filterValue, "DD/MM/YYYY") : null}
        onChange={onChange}
        style={{ margin: "0px", padding: "0px" }}
        placeholder="Date"
        InputProps={{
          endAdornment: (!filterValue ||
            (<IconButton onClick={(e) => handleClr(e)}>
              <ClearIcon fontSize="small" />
            </IconButton>)
          )
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
