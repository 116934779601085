import React, { useState, useMemo, ChangeEvent, useEffect, useContext, useCallback } from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from '@material-ui/core/AppBar';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Toolbar from '@material-ui/core/Toolbar';
import { Paper } from '@material-ui/core'
import Container from '@material-ui/core/Container';
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { ThemeProvider } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { useDataProvider,Title } from "react-admin";
import { createMuiTheme } from '@material-ui/core/styles';
import _ from "lodash"
import { showToastError, showToastSuccess } from '../../../../lib/toas';
import { NumberInput, Edit, SelectInput, usePermissions, useQuery, useRedirect, Loading, SimpleForm, TextInput } from "react-admin";
import { formsSchema, formsMapping } from './helpers';
import List from './List';


const theme = createMuiTheme();


const payload = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: "id", order: "ASC" },
};

export const DropdownCreate = () => {
  const dataProvider = useDataProvider();

  const initValues = {
    taskId: '',
    formId: '',
    fieldId: '',
    temp: ''
  }

  const [state, setstate] = React.useState(initValues)
  const [task, setTask] = useState('')
  const [listData, setListData] = React.useState([])


  const apiCall = async () => {
    try {
      const data = await dataProvider.getList("tna/tasks", payload)
      const tids = _.uniq(data.data.map(res => [res.code, res.title]));
      const filteredCode = tids.filter((val) => val[0] === 'I-MDDS')
      setTask(filteredCode)



    } catch (error) {

    }
    ;
  }


  const handleChange = () => {
    if (state.temp) {
      if(typeof listData == "undefined"){
        setListData([state.temp])
      }
      else{
        var oldList = [...listData,state.temp]
        setListData(oldList)
      }
      setstate({ ...state, temp: '' })
      showToastSuccess('input added ')
    }else{
      showToastError('please add a input')
    }
  }

  const generateFields = (id) => {
    const res = formsSchema.filter((t) => t.code === state.taskId)

    try {
      const data = res[0].data.filter((t) => t.id === id)

      if (data[0].fields.values) {
        return data[0].fields.values
      }
      return []
    } catch (error) {

    }

    return []

  }
  const generateForms = (id) => {

    const res = formsSchema.filter((t) => t.code === id)

    try {
      return res[0].data.map((a) => a)
    } catch (error) {

    }
    return []

  }

  const getFilteredList =  async (tid, fid, feid) => {
    try {
      const res = await dataProvider.getList("tna/dropdown", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        actions: "1/filter_data",
      });
      const data = res.data.filter((details) => details.code != null && details.code === "I-MDDS");
      const fieldsData = data.filter(
        (r) => r.details.form.id === fid && r.code === tid 
      );
      if(fieldsData.length){
        const fieldsArray = fieldsData.map(({ details }) => details.form.fields);

        const field = fieldsArray[0].filter(x => x.id === feid);

        const valueArray = field.map(({ inputs }) => inputs.values);

        setListData(valueArray[0]);

      }
      else{
        setListData([]);
      }

    } catch (error) {
      return [];
    }
  }

  const handleSave = async () => {

    if (typeof listData != "undefined" && state.taskId && state.fieldId !== '' && state.formId) {

      const schema = {
        taskId: state.taskId,
        formId: state.formId,
        fieldId: state.fieldId,
        inputs: listData
      }
      const res = JSON.stringify(schema)
      try {
        const ids = await dataProvider.CUSTOM_ACTION_TNA("dropdown", {
          actions: "save_data",
          body: res,
        });
        showToastSuccess('Saved ')
      } catch (error) {

      }

      return false
    } else {
      showToastError('please fill all fields')
    }


  }


  useEffect(() => {

    apiCall()


  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Title title="Form Dropdown List" />
      <CssBaseline />

      <main>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm"  >
            <Typography
              component="h4"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Form dropdown list
              </Typography>

          </Container>
        </Box>
        <Container sx={{ py: 8 }} style={{ paddingTop: 10, height: '100%', marginTop: 50 }} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={4}>

            <Grid item xs={12} sm={8} md={12}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
              >

                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Create dropdown fields input data
                      </Typography>
                  <div style={{ display: 'flex' }}>
                    <Box sx={{ minWidth: 80 }} md={{ minWidth: 80 }} style={{ width: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Task Code</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          label="Age"
                          onChange={(e) => setstate({ ...state, taskId: e.target.value })}
                        >
                          {task.length > 0 && task.map((t, id) => <MenuItem value={t[0]}>{`${t[0]}  |  ${t[1]}`}</MenuItem>)}

                          {/* <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 80 }} md={{ minWidth: 80 }} style={{ width: 120, marginLeft: 50 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Form Name</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          label="Age"
                          onChange={(e) => setstate({ ...state, formId: e.target.value })}
                        >
                          {/* {formsSchema.length > 0 && formsSchema.map((t) => <MenuItem value={t.id}>{t.name}</MenuItem>)} */}
                          {state.taskId !== '' && generateForms(state.taskId).map((t) => <MenuItem value={t.id}>{t.name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ minWidth: 80 }} md={{ minWidth: 80 }} style={{ width: 120, marginLeft: 50 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Field Id</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          label="Age"
                          onChange={ (e) => {
                              setstate({ ...state, fieldId: e.target.value });
                              getFilteredList(state.taskId, state.formId, e.target.value);
                            }
                          }
                        >
                          {state.formId !== '' && generateFields(state.formId).map((t) => <MenuItem value={t.id}>{t.name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ minWidth: 80 }} md={{ minWidth: 80 }} style={{ width: 180, marginLeft: 50 }}>
                      <FormControl fullWidth>
                        <TextField id="filled-basic" value={state.temp} onChange={(e) => setstate({ ...state, temp: e.target.value })} label="Dropdown input" variant="outlined" />


                      </FormControl>
                    </Box>
                    <Button onClick={handleChange} style={{ width: 180, marginLeft: 50 }} className="btn_secondry" size="small" variant="outlined">Add input</Button>
                  </div>
                </CardContent>
                <Typography variant="body1" component="body1" style={{ padding: 10, marginTop: 30 }}>
                                    <span style={{ color: "red" }}>*</span> Please click Save before changing the fields
                                </Typography>
                <CardActions>
                  <Button onClick={handleSave} style={{ width: 180, height: 50, marginBottom: 20, marginTop: 30 }} className="btn_edit" variant="outlined">Save</Button>
                  <div className="" style={{ marginLeft: 40, marginTop: 30 }}>
                    <List state={state} list={listData} setList={setListData} />
                  </div>
                </CardActions>
              </Card>
            </Grid>

          </Grid>
        </Container>
      </main>

    </ThemeProvider>
  );
}