

export class Item {
    constructor(
        public readonly id: string,
        public readonly category_codes: string,
        public readonly openning_stock: number,
        public readonly openning_price: number,
        ) { }

    static getKeys() {
        return ["id", "category_codes", "openning_stock", "openning_price"];
    }

    static fromJson(data: any): Item {
        data["openning_stock"] = parseFloat(data.openning_stock)
        data["openning_price"] = parseFloat(data.openning_price)
        return data as Item
    }

    static get_category_codes_list(item: Item){
        return item.category_codes.split(",")
    }
}

export class Routing {
    constructor(
        public readonly item_id: string,
        public readonly operation_id: string,
        public readonly pack_size: number,
        public readonly production_cost_per_unit: number,
        ) { }

    static getKeys() {
        return ["item_id", "operation_id", "pack_size", "production_cost_per_unit"];
    }

    static fromJson(data: any): Routing {
        data["pack_size"] = parseFloat(data.pack_size)
        data["production_cost_per_unit"] = parseFloat(data.production_cost_per_unit)
        return data as Routing
    }
}

export class MaterialStructure {
    constructor(
        public readonly item_id: string,
        public readonly operation_id: string,
        public readonly rm_item_id: string,
        public readonly qty_per_assembly: number) { }

    static getKeys() {
        return ["item_id", "operation_id", "rm_item_id", "qty_per_assembly"];
    }

    static fromJson(data: any): MaterialStructure {
        data["qty_per_assembly"] = parseFloat(data.qty_per_assembly)
        return data as MaterialStructure
    }
}

export class TimeBucket {
    constructor(
        public readonly id: string,
        public readonly label: string,
        public readonly duration: number,
        public readonly interest: number,
        ) { }
    static getKeys() {
        return ["id", "label", "duration", "interest"];
    }
    static fromJson(data: any): TimeBucket {
        data["duration"] = parseFloat(data["duration"])
        data["interest"] = parseFloat(data["interest"])
        return data as TimeBucket
    }
}


export class ItemTimeBucket {
    constructor(
        public readonly time_bucket_id: string,
        public readonly item_id: string,
        public readonly supply: number,
        public readonly buying_price: number,
        public readonly demand: number,
        public readonly selling_price: number,
        public readonly minimum_buffer_stock: number,
        public readonly warehouse_cost: number,
    ) { }
    static getKeys() {
        return ["time_bucket_id", "item_id", "supply", "buying_price", "demand", "selling_price", "minimum_buffer_stock", "warehouse_cost"];
    }
    static fromJson(data: any): ItemTimeBucket {
        data["supply"] = parseFloat(data["supply"])
        data["buying_price"] = parseFloat(data["buying_price"])
        data["demand"] = parseFloat(data["demand"])
        data["selling_price"] = parseFloat(data["selling_price"])
        data["minimum_buffer_stock"] = parseFloat(data["minimum_buffer_stock"])
        data["warehouse_cost"] = parseFloat(data["warehouse_cost"])
        return data as ItemTimeBucket
    }
}


export class CapacityRecord {
    constructor(
        public readonly time_bucket_id: string,
        public readonly operation_id: string,
        public readonly quantity: number,
        public readonly minimum_quantity: number,
        public readonly fixed_cost: number,
        public readonly variable_cost: number,

        ) { }
    static getKeys() {
        return ["time_bucket_id", "operation_id", "quantity", "minimum_quantity", "fixed_cost", "variable_cost"];
    }
    static fromJson(data: any): CapacityRecord {
        data["quantity"] = parseFloat(data["quantity"])
        data["fixed_cost"] = parseFloat(data["fixed_cost"])
        data["variable_cost"] = parseFloat(data["variable_cost"])
        data["minimum_quantity"] = parseFloat(data["minimum_quantity"])
        return data as CapacityRecord
    }
}

export class PlannedCapacityRecord {
    constructor(
        public readonly time_bucket_id: string,
        public readonly operation_id: string,
        public readonly item_id: string,
        public readonly quantity: number,

        ) { }
    static getKeys() {
        return ["time_bucket_id", "operation_id", "item_id", "quantity"];
    }
    static fromJson(data: any): PlannedCapacityRecord {
        data["quantity"] = parseFloat(data["quantity"])
        return data as PlannedCapacityRecord
    }
}

export class SalesLossRecord {
    constructor(
        public readonly time_bucket_id: string,
        public readonly item_id: string,
        public readonly quantity: number,

        ) { }
    static getKeys() {
        return ["time_bucket_id", "item_id", "quantity"];
    }
    static fromJson(data: any): SalesLossRecord {
        data["quantity"] = parseFloat(data["quantity"])
        return data as SalesLossRecord
    }
}

export interface SupplyChainState {
    items: Item[]
    routings: Routing[];
    material_structures: MaterialStructure[];
    time_buckets: TimeBucket[];

    // Map from item, time_bucket
    item_time_bucket: ItemTimeBucket[];
    capacity: CapacityRecord[];

    //
    planned: PlannedCapacityRecord[];
    sales_loss: SalesLossRecord[];
    infeasible_items: string[];
}