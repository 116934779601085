import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import React, { useEffect } from "react";
import { useInput, usePermissions } from "react-admin";
import { MENU_SECTIONS as sections } from "../../constants";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { capitalizeFirstLetter } from "../../lib/common";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});
export default function ControlledTreeView(props) {
  const classes = useStyles();
  // const [expanded, setExpanded] = React.useState(permissions.map(p => p.id));
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = React.useState({});

  const {
    input: { name, onChange, ...rest },
    meta: { touched, error, modified, initial },
    isRequired,
  } = useInput(props);

  const userPermissions = usePermissions();
  const permissions = props.choices.filter((p) =>
    userPermissions.permissions?.permissions.includes(p.code)
  );

  useEffect(() => {
    setSelected(rest.value);
  }, []);

  const onClickTreeItem = (event) => {
    event.preventDefault();
  };

  const handleSelect = (event, nodeIds) => {
    const i = selected.indexOf(nodeIds);
    const newArr = selected ? selected : [];
    if (i >= 0) {
      newArr.splice(i, 1);
    } else {
      newArr.push(nodeIds);
    }
    setSelected(newArr);
    setChecked((checked) => ({ ...checked, nodeIds: !checked[nodeIds] }));
    const change = { target: { value: newArr } };
    onChange(change);
  };

  const isChildrenChecked = (parentId) => {
    const isChildrenChecked = !!permissions.filter(
      (a) => a.parent === parentId && selected.includes(a.id)
    )[0];

    return isChildrenChecked;
  };

  const isChildrensChildrenChecked = (parentId) => {
    const children = permissions.filter((child) => child.parent == parentId);
    // .map((p) => p.id);

    const output = children.map((child) => {
      return isChildrenChecked(child.id);
    });

    const is2ndLevelChildrenSelected = output.findIndex((p) => p === true) >= 0;

    return is2ndLevelChildrenSelected;
  };

  const toggleAll = (parent) => {
    const children = permissions
      .filter((child) => child.parent == parent.id)
      .map((p) => p.id);
    const newArr = selected.filter(
      (p) => !children.includes(p) || isChildrensChildrenChecked(p)
    );

    if (
      selected.filter((value) => children.includes(value)).length !=
      children.length
    ) {
      children.map((c) => {
        if (!isChildrensChildrenChecked(c)) {
          newArr.push(c);
        }
      });
    }
    setSelected(newArr);
    // setChecked((checked) => ({ ...checked, nodeIds: !checked[nodeIds] }));
    const change = { target: { value: newArr } };
    onChange(change);
  };

  const hasChildren = (p) => {
    return !!permissions?.find((permission) => permission.parent === p.id);
  };

  const RenderChildren = ({ parent, permissions }) => {
    const isParentNotChecked =
      parent &&
      !(
        selected[0] && selected?.find((permission) => permission === parent.id)
      );

    
    return (
      <>
        <TreeItem
          // onLabelClick={onClickTreeItem}
          // onIconClick={onClickTreeItem}
          nodeId={`all${parent.id}`}
          style={{ maxWidth: "115.23px" }}
          label={
            <>
              <Button
                onClick={(event) => toggleAll(parent)}
                // checked={}
                disabled={
                  isParentNotChecked || isChildrensChildrenChecked(parent.id)
                }
                // color="primary"
                // variant="contained"
                className="btn_ btn_primary mt-1 mb-1"
              >
                Toggle all
              </Button>
            </>
          }
        ></TreeItem>
        {permissions.map((p) => {
          const isChildrenChecked = permissions.filter(
            (a) => a.parent === p.id && selected.includes(a.id)
          )[0];

          return (
            p.parent === parent.id && (
              <>
                <TreeItem
                  nodeId={p.id}
                  label={
                    <>
                      <Checkbox
                        onChange={(event) => handleSelect(event, p.id)}
                        checked={selected.indexOf(p.id) >= 0}
                        disabled={isParentNotChecked || isChildrenChecked}
                      ></Checkbox>
                      {capitalizeFirstLetter(p.name)}{" "}
                    </>
                  }
                >
                  <RenderChildren parent={p} permissions={permissions} />
                </TreeItem>
              </>
            )
          );
        })}
      </>
    );
  };

  const divideArray = (array) => {
    array.sort(function (a, b) {
      return hasChildren(a) && hasChildren(b);
    });
    const arr1 = [];
    const arr2 = [];
    array.map((p, i) => {
      if (i % 2 === 0) {
        arr1.push(p);
      } else {
        arr2.push(p);
      }
    });

    return [arr1, arr2];
  };

  // const arr = _.chunk(mainPermissions, mainPermissions.length / 2);

  return (
    <div outline="none">
      <h5>Permissions</h5>

      {Object.keys(sections).map((section) => {
        const sortFn = (a, b) => {
          return (
            sections[section].findIndex((p) => p === a.code) -
            sections[section].findIndex((p) => p === b.code)
          );
        };

        const sectionPermissions = permissions
          .filter((p) => sections[section].includes(p.code))
          .sort(sortFn);
        const mainPermissions = divideArray(
          sectionPermissions.filter((p) => !p.parent)
        );
        return (
          <div className={classes.root} style={{ padding: "5px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {/* <Divider style={{ marginBottom: "10px" }} /> */}
                <h6 className="mb-0">{section}</h6>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  {mainPermissions.map((parentPermissions) => (
                    <Grid item md={6}>
                      <div className={classes.paper}>
                        <TreeView
                          className={classes.root}
                          // disableSelection={true}
                          // multiSelect={true}
                          defaultCollapseIcon={null}
                          defaultExpandIcon={null}
                          expanded={permissions
                            .filter((p) => hasChildren(p))
                            .map((p) => p.id)}
                        >
                          {parentPermissions.map((p) => {
                            const cardStyle = {
                              marginTop: "10px",
                              marginBottom: "10px",
                            };
                            if (hasChildren(p)) {
                              const isChildrenChecked = permissions.filter(
                                (a) =>
                                  a.parent === p.id && selected.includes(a.id)
                              )[0];

                              return (
                                <Card style={cardStyle}>
                                  <TreeItem
                                    nodeId={p.id}
                                    // onLabelClick={onClickTreeItem}
                                    // onIconClick={onClickTreeItem}
                                    label={
                                      <>
                                        <Checkbox
                                          onChange={(event) =>
                                            handleSelect(event, p.id)
                                          }
                                          disabled={isChildrenChecked}
                                          checked={selected.indexOf(p.id) >= 0}
                                          // onClick={}
                                        ></Checkbox>
                                        <b>{capitalizeFirstLetter(p.name)}</b>
                                      </>
                                    }
                                  >
                                    <RenderChildren
                                      parent={p}
                                      permissions={permissions}
                                    />
                                  </TreeItem>
                                </Card>
                              );
                            } else {
                              return (
                                <Card style={cardStyle}>
                                  <TreeItem
                                    nodeId={p.id}
                                    label={
                                      <>
                                        <Checkbox
                                          onChange={(event) =>
                                            handleSelect(event, p.id)
                                          }
                                          disabled={false}
                                          checked={selected.indexOf(p.id) >= 0}
                                          // onClick={}
                                        ></Checkbox>
                                        <b>{capitalizeFirstLetter(p.name)}</b>
                                      </>
                                    }
                                  />
                                </Card>
                              );
                            }
                          })}
                        </TreeView>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
}
