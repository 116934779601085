import React from "react";
import { Edit, ReferenceInput, SelectInput, SimpleForm } from "react-admin";
import { SaveButton, DeleteButton, Toolbar, usePermissions } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../components/Confirmation";
import { CustomToolbar } from "../../components/CustomToolbar";
import { redirect } from "../../lib/redirect";
import { useCustomToast } from "../../hooks/useCustomToast";

export const OperationWorkstationsEdit = (props) => {
  const { permissions } = usePermissions();
  const useStyles = makeStyles({
    toolbar: {
      display: "flex",
      justifyContent: "flex-end",
    },
  });
  
  const Title = ({ record }) => {
    return <span>Edit Operation Workstation | {record ? `${record.id ?? ""} ` : ""}</span>;
  };

  const customToast = useCustomToast({ redirect: redirect });
  return (
    <Edit
      {...props}
      class="col-md-8"
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
      title={<Title/>}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar need={false} name="opWorkstation" text="Operation" />
        }
      >
        <ReferenceInput
          label="Workstation ID"
          source="workstation"
          reference="workstations"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput
          label="Operation ID"
          source="operation"
          reference="operations"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
