import React, { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { translate } from "ra-core";
import SettingsIcon from "@material-ui/icons/Settings";
import { crudGetOne, MenuItemLink } from "react-admin";

import { sizing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import classnames from "classnames";
import { fade } from "@material-ui/core/styles/colorManipulator";

import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import { Paper } from "@material-ui/core";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";

const Button = styled.button`
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px #999796;

  backgroundcolor: "white";
`;

class UserMenu extends React.Component {
  static propTypes = {
    children: PropTypes.node,

    logout: PropTypes.node,
    translate: PropTypes.func.isRequired,
  };
  fetchProfile = () => {
    this.props.crudGet(
      // The resource
      "userProfile",
      // The id of the resource item to fetch
      "my-profile",
      "my-profile",
      // 'profile',
      // The base path. Mainly used on failure to fetch the data
      //'/my-profile', // create bug not found
      // Wether to refresh the current view. I don't need it here
      false
    );
  };

  static defaultProps = {
    label: "ra.auth.user_menu",
  };

  state = {
    auth: true,
    anchorEl: null,
  };

  handleChange = (event, checked) => {
    this.setState({ auth: checked });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      children,
      label,
      logout,
      translate,
      crudGetOne,
      profile,
    } = this.props;
    if (!logout && !children) return null;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    function Displayname() {
      if (localStorage.getItem('first_name')) {
        return localStorage.getItem("first_name");
      }
      else {
        return localStorage.getItem("username");
      }
    }
    return (
      <div>
        <Tooltip title={label && translate(label, { _: label })}>
          <IconButton
            arial-label={label && translate(label, { _: label })}
            aria-owns={open ? "menu-appbar" : null}
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
            style={{
              backgroundColor: "rgba(255,255,255, 0.25)",
              borderRadius: 50,
              height: 35,
              width: 35,
              margin: 5,
            }}
          >
            {/* Replace this icon with whatever you want, a user avatar or another icon */}
            <AccountCircle />
          </IconButton>
        </Tooltip>

        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              backgroundImage: "url(undraw_walking_around_25f5.svg) ",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left bottom",
              backgroundSize: "200px ",
              borderRadius: 5,
              display: "flex",
              justifyContent: "space-evenly ",
              border: "none",

              minHeight: "40vh",
              maxHeight: "100vh",
              minWidth: "45vw",
              maxWidth: "100vw",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              position: "absolute",
              left: 5,
              top: 5,
            }}
          >
            <span
              style={{
                color: "#00ace",
                fontSize: 28,
                paddingLeft: 1,
                textTransform: "capitalize",
                fontWeight: "700",
              }}
            >
              Hi&nbsp;
                <Displayname />,
              <br />
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "space-evenly ",
                color: "#1c1c1a",
                fontSize: 16,
                paddingLeft: 1,
              }}
            >
              Are you sure, You want to logout ?
            </span>
          </DialogTitle>

          <DialogTitle id="alert-dialog-title">
            <IconButton
              edge="start"
              color="primery"
              onClick={this.handleClose}
              aria-label="close"
              style={{
                position: "absolute",
                right: 5,
                top: 5,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent
            style={{
              backgroundImage: "url(stack-tech-logo.png) ,url(Group219.png) ",
              backgroundRepeat: "no-repeat,no-repeat",

              backgroundPosition:
                "bottom -10px right 15px ,bottom 1px right 150px",
              backgroundSize: " 100px ,200px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              border: "none",
            }}
          >
            <DialogContent
              style={{
                maxHeight: "80%",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //  verticalAlign:"middle"
                }}
              >
                <Button className='logout_btn_group' >
                  <MenuItemLink to="" className='logout_btn_text' 
                  primaryText="Start Tour" />
                </Button>

                <Button className='logout_btn_group'>
                  <MenuItemLink to="" className='logout_btn_text'
                   primaryText="Tutorial" />
                </Button>

                <Button className='logout_btn_group'>
                  <MenuItemLink
                    to="/userProfile"
                    className='logout_btn_text'
                    primaryText="My Account"
                  />
                </Button>

                <Button className='logout_btn_group'>

                  <MenuItemLink to="" className='logout_btn_text'
                   primaryText="Contact Support" />
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  // alignItems: "center",
                  justifyContent: "center",
                  marginTop: 25,
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#ffdad7",
                    color: "#f44336",
                    boxShadow: "unset",
                    padding: "15px 15px",
                    borderRadius: "5",
                  }}
                  className="btn-danger"
                >
                  {logout}
                </Button>
              </div>
            </DialogContent>
          </DialogContent>
        </Dialog>
      </div >
    );
  }
}

export default translate(UserMenu);
