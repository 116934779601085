import React from "react";
import { Datagrid, List, TextField } from "react-admin";

export const SwitchingDetailList = (props: any) => (
  <List {...props} bulkActionButtons={false} perPage={25}>
    <Datagrid >
      <TextField source="id" />
      <TextField source="from_configuration" />
      <TextField source="to_configuration" />
      <TextField source="plan_board_id" />
    </Datagrid>
  </List>
);

