import React, { useCallback, useMemo } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { PRIMARY_COLOR, } from "../../../constants";
import styled from "styled-components";
import { getColorFromConfiguration } from "../../../lib/color";
import {
  AllocationIdType,
  IAllocation,
  IBucket,
  IDemandOperation,
} from "../../../lib/plan";
import { getAllocationStatusColor } from "../../../lib/plan_board/utils";
import {
  requestCreateAllocation,
  requestMoveAllocation,
  selectEntity,
} from "../../../store/actions";
import {
  getAllocationsByBucket,
  getAllocationsObject,
  getBucketsObject,
  getColorMappings,
  getDemandOperationsObject,
  getIsUserCanEdit,
  getSelection,
} from "../../../store/selectors";

const getDropAction = (
  type: string,
  id: any,
  drop_line_id: number,
  drop_time: Date
) => {
  if (type == "allocation") {
    return requestMoveAllocation(id, drop_line_id, drop_time);
  } else if (type == "demand_operation") {
    return requestCreateAllocation(id, drop_line_id, drop_time);
  }
};

type BucketProps = {
  bucketId: number;
};
function collect(monitor: any) {
  return {
    highlighted: monitor.canDrop(),
    hovered: monitor.isOver(),
    hoveredCurrent: monitor.isOver({ shallow: true }),
    itemType: monitor.getItemType(),
    itemId: monitor.getItem()?.id,
  };
}
const useGetDropHoverColor = () => {
  const allAllocations = useSelector(getAllocationsObject);
  const allDemandOperations = useSelector(getDemandOperationsObject);

  return (
    type: "allocation" | "demand_operation",
    id: AllocationIdType,
    bucket: IBucket
  ) => {
    const demandOpId =
      type == "allocation" ? allAllocations[id].demand_operation_id : id;
    if (!demandOpId) {
      return "gray"; //hover color for black bar
    }
    const demandOp = allDemandOperations[demandOpId];
    const rmReadyDate = demandOp.rm_ready_date;
    const needDate = demandOp.required_time_max;
    const time = bucket.start_time;
    const isLate = time > needDate;
    const beforeRm = rmReadyDate && rmReadyDate > time;
    if (isLate || beforeRm) {
      return "red";
    }
    return "lightgreen";
  };
};

export default React.memo(function Bucket({ bucketId }: BucketProps) {
  const allocations = useSelector(getAllocationsByBucket)[bucketId] || [];
  const bucket = useSelector(getBucketsObject)[bucketId];
  const selection = useSelector(getSelection);
  const isSelected = useMemo(
    () =>
      selection && selection.type === "buckets" && selection.id === bucketId,
    [selection]
  );
  const dispatch = useDispatch();
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(selectEntity("buckets", bucketId));
    },
    [dispatch, bucketId]
  );
  const getHoverColor = useGetDropHoverColor();

  const [collectedProps, drop] = useDrop({
    accept: ["allocation", "demand_operation"],
    collect,
    drop: (item: any, monitor) => {
      console.log(
        "Drop on Bucket",
        monitor.didDrop(),
        bucket.start_time,
        bucket.end_time
      );

      if (!monitor.didDrop()) {
        dispatch(
          getDropAction(item.type, item.id, bucket.line_id, bucket.start_time)
        );
      }
    },
  });
  const backgrounColor = collectedProps.hovered
    ? getHoverColor(collectedProps.itemType, collectedProps.itemId, bucket)
    : isSelected
    ? "lightblue"
    : "rgb(235, 236, 240)";
  return (
    <BucketContent color={backgrounColor} onClick={handleClick}>
      <AllocationContainer ref={drop}>
        {collectedProps.hovered ? (
          <AllocationDropContent
            style={{ width: collectedProps.hoveredCurrent ? "50%" : "10%" }}
          />
        ) : null}
        {allocations.map((a, i) => (
          <Allocation
            previousTime={
              i == 0 ? bucket.start_time : allocations[i - 1].end_time
            }
            key={a.id}
            allocation={a}
            index={i}
          ></Allocation>
        ))}
      </AllocationContainer>
    </BucketContent>
  );
});

const BucketContent = styled.div`
  height: 100%;
  background-color: ${(props) => props.color};
  padding: 1px;
  padding-top: 8px;
  flex-grow: 1;
  border-radius: 2px;
`;
const AllocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

type AllocationProps = {
  allocation: IAllocation;
  previousTime: Date;
  index: number;
};
const Allocation = React.memo(
  ({ allocation, index, previousTime }: AllocationProps) => {
    const bucket = useSelector(getBucketsObject)[allocation.line_bucket_id];
    const canEdit = useSelector(getIsUserCanEdit);
    const demandOperation = useSelector(getDemandOperationsObject)[
      allocation.demand_operation_id
    ];
    const opacity = useAllocationOpacity(allocation);
    const dispatch = useDispatch();
    const colorMappings = useSelector(getColorMappings);

    const backgroundColor = useMemo(
      () =>
        allocation.demand_operation_id
          ? getColorFromConfiguration(colorMappings, allocation.configuration)
          : allocation.type=="OPERATION_LOSS"?PRIMARY_COLOR:"gray",
      [allocation]
    );
    const [collectedProps, drag] = useDrag({
      item: { id: allocation.id, type: "allocation" },
      canDrag: () => canEdit && !allocation.is_freezed,
    });

    const [collectedDropProps, drop] = useDrop({
      accept: ["allocation", "demand_operation"],
      collect,
      drop: (item: any, monitor) => {
        console.log(
          "Drop on ALloc",
          allocation.start_time,
          allocation.end_time,
          allocation.quantity,
          allocation.configuration
        );
        dispatch(
          getDropAction(item.type, item.id, bucket.line_id, allocation.end_time)
        );
      },
    });

    const handleClick = useCallback(
      (e) => {
        e.stopPropagation();
        dispatch(selectEntity("allocations", allocation.id));
      },
      [dispatch, allocation]
    );

    const dropHovered = collectedDropProps.hovered;
    const width = useMemo(() => {
      const bucketDuration =
        bucket.end_time.valueOf() - bucket.start_time.valueOf();
      const allocDuration =
        allocation.end_time.valueOf() - allocation.start_time.valueOf();
      const widthValue = (allocDuration / bucketDuration) * 100;
      if (dropHovered) {
        return `${Math.max(50, widthValue)}%`;
      }
      return `${widthValue}%`;
    }, [allocation.start_time, allocation.end_time, dropHovered]);

    const marginLeft = useMemo(() => {
      const bucketDuration =
        bucket.end_time.valueOf() - bucket.start_time.valueOf();
      const previousDuration =
        allocation.start_time.valueOf() - previousTime.valueOf();
      const marginLeftValue = (previousDuration / bucketDuration) * 100;
      return `${marginLeftValue}%`;
    }, [previousTime, bucket, allocation]);

    const allocContentStyle = useMemo(
      () => ({
        width,
        flexGrow: dropHovered ? 2 : 0,
        marginLeft: dropHovered ? 0 : marginLeft,
      }),
      [width, dropHovered, marginLeft]
    );
    const allocInnerContentStyle = useMemo(() => {
      let animationName = undefined;

      if (demandOperation) {
        const min_time =
          demandOperation.rm_ready_date || demandOperation.required_time_min;
        const isEarly = allocation.start_time < min_time;
        const isDelayed =
          allocation.end_time > demandOperation.required_time_max;
        const hasIssue =
          isDelayed || isEarly || !allocation.is_tools_availabile;
        if (hasIssue) {
          animationName = allocation.is_tools_availabile
            ? isDelayed
              ? "blink-delayed"
              : "blink-early"
            : "blink-tool-issue";
        }
      }
      return {
        width: "10%",
        background: allocation.is_freezed
          ? `repeating-linear-gradient(45deg, ${backgroundColor} 0px 5%, transparent 5% 6%)`
          : backgroundColor,
        animationName,
        borderWidth: 1.5,
        borderTopColor: getAllocationStatusColor(
          allocation.quantity,
          allocation.completed_quantity
        ),
        borderTopWidth: 4,
        opacity,
      };
    }, [backgroundColor, allocation, opacity]);
    const dropAreaStyle = useMemo(
      () => ({ display: dropHovered ? undefined : "none" }),
      [dropHovered]
    );

    return (
      <AllocationContent ref={drop} style={allocContentStyle}>
        <AllocationInnerContent
          onClick={handleClick}
          ref={drag}
          style={allocInnerContentStyle}
        >
          {allocation.configuration}: {allocation.quantity}
        </AllocationInnerContent>
        <AllocationDropContent style={dropAreaStyle}></AllocationDropContent>
      </AllocationContent>
    );
  }
);

const useAllocationOpacity = (allocation: IAllocation) => {
  const selection = useSelector(getSelection);
  const demandOperationObj = useSelector(getDemandOperationsObject);
  const allocationObj = useSelector(getAllocationsObject);
  if (!selection) {
    return 1;
  }
  if (selection.type === "allocations" && allocationObj[selection.id]) {
    const selectedAllocation = allocationObj[selection.id];
    const selectedAllocatiosDemandOperation =
      demandOperationObj[selectedAllocation.demand_operation_id];
    const thisAllocationsDemandOperation =
      demandOperationObj[allocation.demand_operation_id];
    return selectedAllocatiosDemandOperation?.order_group_code ===
      thisAllocationsDemandOperation?.order_group_code
      ? 1
      : 0.5; // If allocation is selected we highlight the all group
  }
  if (selection.type === "demand_operations") {
    return allocation.demand_operation_id === selection.id ? 1 : 0.5;
  }
  return 1;
};

const AllocationContent = styled.div`
  flex-grow: 0;
  /* padding: 1px; */
  display: flex;
`;
const AllocationInnerContent = styled.div`
  background-color: pink;
  height: "100%";
  overflow: hidden;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  border-color: rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  animation-duration: 100ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border-style: solid;
`;
const AllocationDropContent = styled.div`
  background-color: lightblue;
  height: "100%";
  width: 90%;
  opacity: 0.4;
  flex-grow: 2;
  overflow: hidden;
`;
