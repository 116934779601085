import React, { useEffect, useState } from "react"
import { getCapacityForTimeBucket, getDemandAndConsumptionForTimeBucket, getDemandForTimeBucket, getEndingStockForTimeBucket, getMinCapacityForTimeBucket, getOpenningStockForTimeBucket, getPlannedQtyForTimeBucket, getSalseLossPerItemForTimeBucket, getSupplyForTimeBucket } from "./lib"
import { RevisionState } from "./reducer/reducer"
import _ from "lodash"
import CostExplorer from "./CostExplorer"
import { LinearProgressWithLabel, CostCard, BucketSelector, ColumnSelector } from "./SimulationView"
import { Button, Card, CardContent, Dialog, DialogContent, Popover, Grid, DialogActions } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from "react-redux"
import { getRevisions, getSupplyChainData } from "./reducer/selectors"
import { supplyChainClearRevisions } from "./reducer/actions"
import { showToast } from "../../lib/toas"
import { SupplyChainState, TimeBucket } from "./interfaces"
import { Line } from "react-chartjs-2"
import { getColor } from "./TimeSeriesChart"
import { getTitleFromKey } from "./DataImportView"

const RevisionCard = ({ revision, selectedItems, selectedBucketIndex }: { revision: RevisionState, selectedItems: string[], selectedBucketIndex: number }) => {

    const selectedBucketId = revision.value.time_buckets[selectedBucketIndex].id
    const capacity = getCapacityForTimeBucket(revision.value, selectedBucketId, selectedItems)
    const minCapacity = getMinCapacityForTimeBucket(revision.value, selectedBucketId, selectedItems)
    const planned = _.sumBy(selectedItems, item => getPlannedQtyForTimeBucket(revision.value, selectedBucketId, item))
    const openning = _.sumBy(selectedItems, item => getOpenningStockForTimeBucket(revision.value, selectedBucketId, item))
    const demand = _.sumBy(selectedItems, item => getDemandForTimeBucket(revision.value, selectedBucketId, item))

    return (
        <Card>
            <CardContent>

                <div style={{ width: '100%', height: '10%', marginBottom: '2%' }}>
                    <span style={{ fontSize: 22, fontWeight: 'bold', paddingLeft: '10px' }}>Revision: {revision.key}</span>
                </div>


                <div style={{ width: '95%', height: '10%', marginBottom: '5%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                        <span style={{ fontSize: 16, fontWeight: 'normal' }}>Demand</span>
                        <div style={{ backgroundColor: '#4b27bd', borderRadius: 10, width: '70px', padding: '7.5px', fontSize: 16, fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                            {demand}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                        <span style={{ fontSize: 16, fontWeight: 'normal' }}>Planned</span>
                        <div style={{ backgroundColor: '#4b27bd', borderRadius: 10, width: '70px', padding: '7.5px', fontSize: 16, fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                            {planned}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                        <span style={{ fontSize: 16, fontWeight: 'normal' }}>Capacity</span>
                        <div style={{ backgroundColor: '#4b27bd', borderRadius: 10, width: '70px', padding: '7.5px', fontSize: 16, fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                            {capacity}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                        <span style={{ fontSize: 16, fontWeight: 'normal' }}>In Stock</span>
                        <div style={{ backgroundColor: '#4b27bd', borderRadius: 10, width: '70px', padding: '7.5px', fontSize: 16, fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                            {openning}
                        </div>
                    </div>
                </div>
                <CostExplorer data={revision.value} selectedTimeBucketId={selectedBucketId} selectedItems={selectedItems} />
                <br />
                <div style={{ width: '100%', height: '10%', marginBottom: '2%' }}>
                    <span style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: '10px', marginBottom: '10px' }}>Capacity Utilization</span>
                    <div style={{ marginBottom: '5px', paddingLeft: '10px' }}>
                        <span style={{ fontSize: 12 }}>Agreed Capacity</span>
                        <LinearProgressWithLabel color="secondary" text={`${minCapacity} / ${capacity}`} variant="determinate" value={
                            capacity > 0 ? 100 * minCapacity / capacity : 0} />
                    </div>
                    <div style={{ marginBottom: '5px', paddingLeft: '10px' }}>
                        <span style={{ fontSize: 12 }}>Planned Capacity</span>
                        <LinearProgressWithLabel color="secondary" text={`${planned} / ${capacity}`} variant="determinate" value={
                            capacity > 0 ? 100 * planned / capacity : 0} />
                    </div>
                </div>
                <div style={{ width: '100%', height: '10%', marginBottom: '2%' }}></div>
                <div style={{ width: '100%', height: '10%', marginBottom: '2%' }}>
                    <span style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: '10px' }}>Inventory Cost</span>
                </div>
                <div style={{ width: '97.5%', height: '40%', marginBottom: '2%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ width: '55%' }}>
                            <CostCard items={selectedItems} startBucketIndex={0} hideTopics={true} />
                        </div>
                        <div style={{
                            width: '40%',
                            borderStyle: 'solid',
                            borderRadius: '10px',
                            borderColor: 'black',
                            borderWidth: '1px',
                            padding: '5px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <span style={{ fontSize: 12, fontWeight: 'bold', marginBottom: '20px', marginTop: '5px' }}>Time period</span>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', fontSize: 11, marginBottom: '10px' }}>
                                <span>Planned month:</span>
                                <span>June</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', fontSize: 11, marginBottom: '10px' }}>
                                <span>Delivery:</span>
                                <span>September</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', fontSize: 11, marginBottom: '10px' }}>
                                <span>Months in holding:</span>
                                <span>3</span>
                            </div>
                            <div style={{ marginTop: '25px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" style={{ width: '90%', color: '#fff', height: '25px', backgroundColor: '#eb445a' }} disabled>Increase in invantory cost</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

const CompareModal = ({ revisions, onClose, selectedItems }: { revisions: RevisionState[], onClose: () => void, selectedItems: string[] }) => {

    const [selectedBucketIndex, setSelectedBucketIndex] = useState(0)
    return <>
        <Dialog fullWidth maxWidth="xl" open={true} onClose={onClose}>
            <DialogActions>
                <BucketSelector onChangeIndex={setSelectedBucketIndex} buckets={revisions[0]?.value.time_buckets || []} selectedIndex={selectedBucketIndex} />
                <Button onClick={onClose} style={{ float: 'right' }} variant="text"><CloseIcon /></Button>
            </DialogActions>
            <DialogContent style={{ overflowX: 'scroll' }}>
                <CompareRevisionsChart revisions={revisions} selectedItems={selectedItems} selectedParam="openning_stock" selectedBucketIndex={selectedBucketIndex} />

                <div style={{ display: 'flex', flexDirection: 'row', width: `${30 * revisions.length}vw` }}>
                    {revisions.map(r => <div style={{ width: '29vw', paddingLeft: 2 }}>
                        <RevisionCard revision={r} selectedBucketIndex={selectedBucketIndex} selectedItems={selectedItems} />
                    </div>)}
                </div>
            </DialogContent>
            {/* <div style={{ padding: '40px', height: window.innerHeight * 0.8 }}>
                <div style={{ width: '100%', height: '5%', alignItems: 'center', marginBottom: '5px' }}>
                <div style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: '10px' }}>
                </div>
            </div> */}
        </Dialog>
    </>
}


export const CompareButton = ({ selectedItems }: { selectedItems: string[] }) => {

    const [showCompareModal, setShowCompareModal] = useState(false)
    const revisions = useSelector(getRevisions)
    const [selectedRevisions, setSelectedRevisions] = useState<string[]>([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch()
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setShowCompareModal(false)
    };

    const data = useSelector(getSupplyChainData)

    const handleCompare = () => {
        if (selectedRevisions.length > 0) {
            handleClose();
            setTimeout(() => {
                setShowCompareModal(true)
            }, 200)
        } else {
            showToast("Select at least one revision")
        }
    }

    const handleClear = () => {
        const open = Boolean(anchorEl);
        setSelectedRevisions([])
        dispatch(supplyChainClearRevisions())
    }

    const handleChange = (r: RevisionState) => {
        if (selectedRevisions.indexOf(r.key) == -1) {
            setSelectedRevisions(_.uniq([...selectedRevisions, r.key]))
        } else {
            setSelectedRevisions(_.filter(selectedRevisions, v => v != r.key))
        }
    }

    return <>
        <Button variant="contained" color="primary" onClick={handleClick}>Revisions</Button>
        <Popover
            id="rvsn"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                {revisions.map(r => {
                    return <div style={{ width: '100%' }}>

                        <label style={{ float: 'left' }}>
                            <input
                                type="checkbox"
                                checked={selectedRevisions.indexOf(r.key) >= 0}
                                onChange={() => handleChange(r)}
                            />
                            <span> {r.key} </span>
                                        &nbsp; &nbsp;
                                    </label>
                    </div>
                })}
                <div style={{ marginTop: '15px', height: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Button
                        style={{ width: '40%' }} variant="contained" color="secondary" size="small"
                        onClick={handleClear}
                    >Clear</Button>
                    <Button
                        style={{ width: '40%' }} variant="contained" color="primary" size="small"
                        onClick={handleCompare}
                    >Compare</Button>
                </div>
            </div>
        </Popover>
        { showCompareModal && <CompareModal revisions={revisions.filter(r => selectedRevisions.indexOf(r.key) >= 0)} selectedItems={selectedItems} onClose={handleClose} />}
    </>
}



const mapForBuckets = (func: any) =>
    (data: SupplyChainState, timeBuckets: TimeBucket[], items: string[]) => timeBuckets.map(b => _.sumBy(items, item => func(data, b.id, item)))
const mapForBucketsAllItems = (func: any) =>
    (data: SupplyChainState, timeBuckets: TimeBucket[], items: string[]) => timeBuckets.map(b => func(data, b.id, items))

const parameters = {
    demand: mapForBuckets(getDemandAndConsumptionForTimeBucket),
    supply: mapForBuckets(getSupplyForTimeBucket),
    planned: mapForBuckets(getPlannedQtyForTimeBucket),
    openning_stock: mapForBuckets(getOpenningStockForTimeBucket),
    sales_loss_qty: mapForBuckets(getSalseLossPerItemForTimeBucket),
    capacity: mapForBucketsAllItems(getCapacityForTimeBucket),
    min_capacity: mapForBucketsAllItems(getMinCapacityForTimeBucket)
}
type ParamType = keyof typeof parameters

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }],
        xAxes: [
            {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 20
                }
            }
        ]

    },
}

const getChartData = (revisions: RevisionState[], column: ParamType, selectedItems: string[], selectedBucketIndex: number) => {
    const timeBuckets = revisions[0].value.time_buckets.slice(selectedBucketIndex, selectedBucketIndex + 15)

    const result = {
        labels: timeBuckets.map(b => b.label),
        datasets: revisions.map((entry, i) => {
            const data = parameters[column](entry.value, timeBuckets, selectedItems)

            return ({ data, label: entry.key, ...getColor(i), borderWidth: 1, tension: 0.1, pointHitRadius: 6, pointRadius: 2, pointHoverRadius: 4 })
        })
    }

    return result
}
const CompareRevisionsChart = (props: {
    revisions: RevisionState[], selectedItems: string[],
    selectedParam: ParamType,
    selectedBucketIndex: number
}) => {

    const [hiddendColumns, setHiddenColumns] = useState<ParamType[]>([])
    const columns = Object.keys(parameters) as ParamType[]
    useEffect(() => {
        setHiddenColumns(columns.filter(col => col != props.selectedParam))
    }, [props.selectedParam])


    const selectedCols = columns.filter(col => hiddendColumns.indexOf(col) == -1)

    return <div>
        <ColumnSelector columns={columns} hiddenColumns={hiddendColumns} onChangeHiddenColumns={setHiddenColumns} />
        {
            selectedCols.map(col => {
                return <div key={col} >
                    <h6>{getTitleFromKey(col)}</h6>
                    <div style={{ height: 200 }}>
                        <Line key={col} options={chartOptions} data={getChartData(props.revisions, col, props.selectedItems, props.selectedBucketIndex)} />
                    </div>
                    <br />
                </div>
            })
        }
    </div>
}