import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPLETE_TASK,
  CONFIRM_TEMPLATE,
  DELETE_FLOW,
  FETCH_TASKS_FOR_APPROVAL,
  FETCH_TENTATIVE_ORDERS,
  LOAD_TASKS,
  QR_SEND,
  START_TASK,
} from "../../../store/types/tnaTaskTypes";
import {
  getAllTentativeOrders,
  getTaskCompleteSuccess,
} from "../../../store/selectors/tnaTaskSelectors";
import { Order, TentativeFlowItem } from "./TentativeFlowItem";
import {
  Grid,
  Switch,
  FormControlLabel,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
  FormControl,
  TableSortLabel,
  TableFooter,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Title } from "react-admin";
import { theme as Theme } from "../../../layout/theme";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: "40%",
  },
  btn: {
    backgroundColor: "primary",
    minWidth: "120px",
    margin: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  text: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: theme.palette.primary.main,
    padding: "20px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: Theme.palette.primary.main,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: Theme.palette.primary.main,
  },
}));

export const TentativeFlows = ({ history }) => {
  const tentative_orders = useSelector(getAllTentativeOrders);
  const [clickedId, setClickedId] = useState();
  const [filterOn, setFilterOn] = useState(false); // If filter is off, ORDER flow tasks are shown
  const [searchString, setSearchString] = useState("");
  const [selItemId, setSelItemId] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("asc");
  let rowCount = 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const switchRefresh = (t) =>
    dispatch({ type: FETCH_TENTATIVE_ORDERS, payload: t ? "IQ" : "" });
  const refresh = () =>
    dispatch({ type: FETCH_TENTATIVE_ORDERS, payload: filterOn ? "IQ" : "" });
  const onRefresh = (e) => {
    refresh();
    setTimeout(() => {
      e.target.complete();
    }, 1000);
  };

  useEffect(() => {
    dispatch({ type: FETCH_TENTATIVE_ORDERS, payload: filterOn ? "IQ" : "" });
  }, []);

  let tentativeList = null;
  if (tentative_orders?.length === 0) {
    rowCount = 0;
    tentativeList = (
      <Typography
        Typography
        className={classes.text}
        variant="body2"
        gutterBottom
      >
        No tentative flows.
      </Typography>
    );
  } else {
    const filteredList = tentative_orders?.filter((order) =>
      !filterOn
        ? ["NEW", "RP"].includes(order.type) &&
          (searchString.length > 0
            ? order.flow_code.includes(searchString)
            : true)
        : ["IQ"].includes(order.type) &&
          (searchString.length > 0
            ? order.flow_code.includes(searchString)
            : true)
    );
    rowCount = filteredList.length;
    if (filteredList?.length === 0) {
      tentativeList = (
        <Typography className={classes.text} variant="body2" gutterBottom>
          No {!filterOn ? `order flow` : `inquiry flow`} tentatives found.
          <p style={{ marginTop: "5px" }}>
            Toggle to view {!filterOn ? `inquiry flow` : `order flow`}{" "}
            tentatives
          </p>
        </Typography>
      );
    } else {
      tentativeList = stableSort(filteredList, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => (
          <TentativeFlowItem
            order={row}
            refresh={refresh}
            selItemId={selItemId}
            setSelItemId={setSelItemId}
          />
        ));
    }
  }

  const headCells = [
    {
      id: "id",
      numeric: true,
      label: "Id",
    },
    {
      id: "flow_code",
      numeric: false,
      label: "Flow Code",
    },
    {
      id: "template_code",
      numeric: false,
      label: "Template Code",
    },
    {
      id: "started_date",
      numeric: false,
      label: "Started date",
    },
    {
      id: "status",
      numeric: false,
      label: "Status",
    },
    {
      id: "edit",
      numeric: false,
      label: "",
    },
  ];
  return (
    <div>
      <Title title="Tentative Flow Manager" />
      <Grid
        style={{ marginBottom: "5px" }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <div style={{ minWidth: "200px", marginRight: "20px" }}>
          <TextField
            id="filled-search"
            label="Search by Flow Code"
            type="search"
            variant="filled"
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
        </div>
        <FormControlLabel
          control={
            <CustomSwitch
              onChange={() => {
                setFilterOn(!filterOn);
                switchRefresh(!filterOn);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={filterOn ? "Inquiry Flow Tentatives" : "Order Flow Tentatives"}
        />
      </Grid>

      <TableContainer style={{ overflow: "auto" }} component={Paper}>
        <Table style={{ tableLayout: "fixed" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding="normal"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    hideSortIcon={headCell.id === "edit"}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
        <div style={{ overflow: "auto", height: "700px" }}>
          <Table style={{ tableLayout: "fixed" }}>
            <TableBody>{tentativeList}</TableBody>
          </Table>
        </div>
        <Table style={{ tableLayout: "auto" }}>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                count={rowCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
