import React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
import { redirect } from "../../lib/redirect";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

export const OperationWorkstationsCreate = (props) => {
  const Title = ({ record }) => {
    return <span>Create Operation Workstation</span>;
  };
  // redirect to back
  const redirect_to = () => redirect(-3);
  const customToast = useCustomToast({ redirect: redirect_to });
  return (
    <Create
      {...props}
      class={"col-md-6 "}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      title={<Title/>}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar need={false} text="workstations" name="workstations" />
        }
      >
        <ReferenceInput
          label="workstation code"
          source="workstation"
          filterToQuery={(searchText) => ({ code: searchText })}
          reference="workstations"
          perPage={100}
        >
          <AutocompleteInput optionText="code" emptyText="empty" />
        </ReferenceInput>
        <ReferenceInput
          label="operation name"
          source="operation"
          reference="operations"
        >
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
