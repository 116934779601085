import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  WithPermissions,
} from "react-admin";
import {
  TopToolbar,
  // CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
  usePermissions,
  CloneButton,
  Filter,
  FunctionField,
  TextInput,
  SelectInput,
} from "react-admin";
import CreateButton from "../../components/CustomCreateButton";
import { cloneElement } from "react";
import Confirmation from "../../components/Confirmation";
import { makeStyles } from "@material-ui/core/styles";
import { ChangeDiv } from "../../components/ChangeDiv";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));
let accessLevels = [
  { "id": "ADMIN", "name": "ADMIN" },
  { "id": "SITE", "name": "SITE" },
  { "id": "PLANT", "name": "PLANT" },
  { "id": "SUB", "name": "SUB" },
  { "id": "BASIC", "name": "BASIC" },
  { "id": "TNA_ADMIN", "name": "TNA ADMIN" },
  { "id": "TNA_CLUSTER_MANAGER", "name": "TNA CLUSTER MANAGER" },
  { "id": "TNA_DEP_ADMIN", "name": "TNA DEPARTMENT ADMIN" },
  { "id": "TNA_MANAGER", "name": "TNA MANAGER" },
  { "id": "TNA_PLANNER", "name": "TNA PLANNER" },
  { "id": "TNA_USER", "name": "TNA USER" }
];
switch (localStorage?.access_level) {
  case "ADMIN":
    break;
  case "SITE":
    accessLevels = [{"id":"PLANT","name":"PLANT"}, {"id":"SUB","name":"SUB"}, {"id":"BASIC","name":"BASIC"}];
    break;
  case "PLANT":
    accessLevels = [{"id":"SUB","name":"SUB"}, {"id":"BASIC","name":"BASIC"}];
    break;
  case "TNA_ADMIN":
    accessLevels = [{ "id": "TNA_CLUSTER_MANAGER", "name": "TNA CLUSTER MANAGER" },{ "id": "TNA_DEP_ADMIN", "name": "TNA DEPARTMENT ADMIN" }, { "id": "TNA_MANAGER", "name": "TNA MANAGER" }, { "id": "TNA_PLANNER", "name": "TNA PLANNER" }, { "id": "TNA_USER", "name": "TNA USER" }];
    break;
  case "TNA_CLUSTER_MANAGER":
    accessLevels = [{ "id": "TNA_DEP_ADMIN", "name": "TNA DEPARTMENT ADMIN" }, { "id": "TNA_MANAGER", "name": "TNA MANAGER" }, { "id": "TNA_PLANNER", "name": "TNA PLANNER" }, { "id": "TNA_USER", "name": "TNA USER" }];
    break;
  case "TNA_DEP_ADMIN":
    accessLevels = [{ "id": "TNA_MANAGER", "name": "TNA MANAGER" }, { "id": "TNA_PLANNER", "name": "TNA PLANNER" }, { "id": "TNA_USER", "name": "TNA USER" }];
    break;
  default:
    break;
}
const choices = accessLevels.map((c) => ({ id: c.id, name: c.name }));
const RolesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Role code" source="code" alwaysOn resettable />
    <SelectInput
      label="Access level"
      source="access_level"
      alwaysOn
      resettable
      translateChoice={false}
      choices={choices}
    />
  </Filter>
);

const getAccessTevelText = (record) => {
  
  let access_level_text=""
  if (record.access_level=='TNA_DEP_ADMIN'){
    access_level_text="TNA DEPARTMENT ADMIN"
  }else{
    access_level_text=record.access_level.replace('_',' ')
  }

  return (access_level_text);
}

export const Roles = (props) => {
  const { permissions } = usePermissions();

  const Actions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const classes = useStyles();
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
    } = useListContext();

    return (
      <TopToolbar
        className={classes.buttonContainer}
        {...sanitizeListRestProps(rest)}
      >
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
            className: "btn_",
          })}
        <CreateButton
          basePath={basePath}
          title="Click here to Create Roles"
          disabled={!permissions?.permissions.includes("roles_create")}
        />
        <ExportButton
          className="btn_ btn_primary exportAlign"
          title="Click here to Export"
          disabled={!permissions?.permissions.includes("roles_export")}
        />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      perPage={25}
      bulkActionButtons={false}
      actions={<Actions />}
      filters={<RolesFilter />}
    >
      <ChangeDiv>
        {(props) => {
          return (
            <Datagrid>
              <TextField source="id" />
              <TextField source="code" />
              <FunctionField label="Access Level" render={record => getAccessTevelText(record)} />
              <EditButton
                {...props}
                title="Click here to Edit Users"
                className="btn_ btn_edit"
                disabled={!permissions?.permissions.includes("roles_edit")}

                // title='Click here to Edit Users' disabled={!permissions?.permissions.includes("roles_edit")}
              />{" "}
              <Confirmation
                title="Click here to Delete Users"
                disabled={!permissions?.permissions.includes("roles_delete")}
                text="roles"
              />{" "}
              <CloneButton
                title="permissions"
                className="btn_ btn_secondry "
                disabled={!permissions?.permissions.includes("roles_clone")}
              />
              {/* <CloneButton title='Click here to Clone Users' disabled={!permissions?.permissions.includes("roles_clone")} /> */}
            </Datagrid>
          );
        }}
      </ChangeDiv>
    </List>
  );
};
