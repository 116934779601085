import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PriceListUpload from "./PerformTasks/PriceListUpload";
import { useParams } from "react-router-dom";
import { NumberInput, Edit, SelectInput, usePermissions, useQuery, useRedirect, Loading, SimpleForm, TextInput } from "react-admin";
import FileUpload from './PerformTasks/FileUpload';
import QRTask from './PerformTasks/QRGen';
import { connect, useSelector, useDispatch } from 'react-redux';
import { getAllTasks, getProcessingTasks, getTNAFormUploadLoading, getTNATaskLoading, } from '../../store/selectors/tnaTaskSelectors';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { completeTask, loadALLTasks, qrAcceptance } from '../../store/actions/tnaTaskActions';
import { useHistory } from "react-router-dom";
import EmailSend from './PerformTasks/EmailSend'
import FormDownload from './formDownload'
import Forms from "./forms/Forms";
import { showToastSuccess, showToastError } from "../../lib/toas"
import TextField from "@material-ui/core/TextField";
import { FormControlLabel, RadioGroup, Radio, FormControl } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
    },
    detail: {
        display: 'flex'
    }
}));

const options = [];


function TaskPerform({ tasks, onLoad, formSubmitLoading, tasksLoading }) {
    const classes = useStyles();
    const params = useParams()
    const [selectedReason, setSelectedReason] = React.useState("")
    const [comment, setComment] = React.useState("");
    const [qrData, setQrData] = React.useState("");
    const [data, setData] = React.useState("");
    const { permissions } = usePermissions();
    const redirect = useRedirect();
    const { loadings, todoTasks, processingTasks, completedTasks } = useSelector(state => state.custom.tnaTasks)
    const dispatch = useDispatch()
    const [isWon, setIsWon] = React.useState("true");
    var performingTask = ""


    const createRow = (element) => {
        return {
            'value': element.id,
            'label': element.delay_reason
        }
    }

    const { loading, error } = useQuery({
        type: "TNA_CUSTOM_ACTION",
        resource: "itemTasks",
        payload: {
            action: "isTaskDelayed",
            method: "POST",
            body: { id: params.id },
        },
    }, {
        onFailure: (e) => {
            showToastError("Task Retrieval Failed:" + e.body);
        },
        onSuccess: (response) => {
            setData(response.data)
            response.data.delayReasons.forEach(element => {
                options.push(createRow(element))
            });
            // setTasksSet(rows)

        }
    });

    function isReleventTask(task) {
        if (task[0] == params.id) {
            return true
        } else {
            return false
        }
    }
    var codePrefix = ""
    var sizeArray = []

    function setItemCodes(task) {
        var computerCode = task.order.computer_code
        var sizeStr = computerCode.split("/")[1].trim()
        codePrefix = computerCode.split("/")[0].trim()
        var startSize = sizeStr.split("-")[0]
        var endSize = sizeStr.split("-")[1]

        for (let i = parseInt(startSize); i <= endSize; i++) {
            sizeArray.push(<Typography>{codePrefix.concat(i)}</Typography>)
        }
    }

    var task = null
    if (tasks.find(isReleventTask)) {
        task = tasks.find(isReleventTask)[1]
        if (task != null && task != undefined) {
            if (task.order.type == "NEW" || task.order.type == "RP") {
                setItemCodes(task)
            }
        }
    } else {
        redirect('/TnaDashboard');
    }


    if (!loadings) {
        if (params.taskMode == "todo") {
            performingTask = todoTasks.get(params.id)

        } else if (params.taskMode == "processing") {
            performingTask = processingTasks.get(params.id)

        } else if (params.taskMode == "completed") {
            performingTask = completedTasks.get(params.id)
        }
    }


    if (loading || tasksLoading || formSubmitLoading) return <Loading />;

    const submit = () => {
        var submittingData;
        if (permissions?.permissions.includes("tna_flow_complete") &&
            performingTask["task_permissions"].includes("tna_flow_complete")) {
            submittingData = {
                templateItemTaskId: params.id,
                comment: comment,
                forced: false,
                skipReason: "",
                delayReason: selectedReason,
                isWon: isWon == "true"
            };

        } else {
            submittingData = {
                templateItemTaskId: params.id,
                comment: comment,
                forced: false,
                skipReason: "",
                delayReason: selectedReason,
            };
        }

        dispatch(completeTask(submittingData));
    };

    const qrSubmit = () => {
        const submittingData = {
            parentTaskId: qrData,
            currentTaskId: params.id,
        };
        dispatch(qrAcceptance(submittingData));
    };

    return (
        <div className={classes.root}>
            {typeof performingTask != "undefined" ? (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <Typography variant='h6'>Id</Typography>
                                    <Typography>{task?.id}</Typography>
                                </div>
                                <div>
                                    <Typography variant='h6'>Template Code</Typography>
                                    <Typography>{task?.template_code}</Typography>
                                </div>
                                <div>
                                    {(task.order.type == "NEW" || task.order.type == "RP") ?
                                        <>
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <Typography variant='h6'>Sample Design</Typography>
                                                    <Typography>{task?.order?.code}</Typography>
                                                </div>
                                                <div>
                                                    <Typography variant='h6'>Item Codes</Typography>
                                                    {sizeArray}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <Typography variant='h6'>Serial Number</Typography>
                                                    <Typography>{task?.order?.inquiry_serial}</Typography>
                                                </div>
                                                <div>
                                                    <Typography variant='h6'>Inquiry Description</Typography>
                                                    <Typography>{task?.order?.description}</Typography>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid item xs={1}>
                                <label>QR ID</label>
                                <Grid>
                                    <TextField
                                        aria-label="minimum height"
                                        rowsMin={3}
                                        placeholder="Enter QR Id"
                                        onChange={(e) => setQrData(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Button variant="contained" color="primary" onClick={qrSubmit}>
                                {" "}
                                Submit Code
                            </Button>
                        </Paper>
                    </Grid>
                    {task.parent_tasks?.length > 0 && <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography variant='h6'>Forms from parent tasks</Typography>
                            {(task.parent_tasks||[]).map(t =>
                                <Paper className={classes.paper}>
                                    <Typography>Parent Task Id: {t?.id}</Typography>
                                    <FormDownload task={t} isParent={true} />
                                </Paper>)}
                        </Paper>
                    </Grid>}
                    {permissions?.permissions.includes("tna_fill_forms") && performingTask['task_permissions'].includes('tna_fill_forms') ?
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant='h6'>Forms</Typography>
                                <Forms task={task} />
                                <FormDownload task={task} />
                            </Paper>
                        </Grid>
                        : null}
                    {permissions?.permissions.includes("tna_send_email") && performingTask['task_permissions'].includes('tna_send_email') ?
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <EmailSend id={params.id} />
                            </Paper>
                        </Grid>
                        : null}
                    {permissions?.permissions.includes("tna_file_upload") &&
                        performingTask["task_permissions"].includes("tna_file_upload") ? (
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <FileUpload id={params.id} expired={data} />
                                </Paper>
                            </Grid>
                        ) : null}
                    {permissions?.permissions.includes("tna_qr_generate") &&
                        performingTask["task_permissions"].includes("tna_qr_generate") ? (
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <QRTask task={task} />
                                </Paper>
                            </Grid>
                        ) : null}
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            {data.isDelayed ? (
                                <Grid item xs={5}>
                                    <label>Give Reasons For Delay</label>
                                    <Select
                                        options={options}
                                        onChange={(e) => setSelectedReason(e.value)}
                                    ></Select>
                                </Grid>
                            ) : null}
                            {permissions?.permissions.includes("tna_flow_complete") &&
                                performingTask["task_permissions"].includes("tna_flow_complete") ? (

                                    <FormControl component="fieldset">
                                        <label>Win or Lose State</label>
                                        <RadioGroup
                                            value={isWon}
                                            onChange={(event) => setIsWon(event.target.value)}
                                        >
                                            <FormControlLabel
                                                value="true"
                                                control={<Radio />}
                                                label="Win"
                                            />
                                            <FormControlLabel
                                                value="false"
                                                control={<Radio />}
                                                label="Lose"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                ) : null}
                            <Grid item xs={5}>
                                <label>Comments</label>
                                <Grid>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        rowsMin={3}
                                        onChange={(e) => setComment(e.target.value)}
                                        placeholder="Comment on Task Completion "
                                    />
                                </Grid>
                            </Grid>

                            <Button variant="contained" color="primary" onClick={submit}>
                                {" "}
                                Complete Task
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            ) : null}
        </div>
    );
}

const mapDispatchToProps = {
    onLoad: loadALLTasks,
};

const mapStateToProps = (state) => {
    const tasks = getAllTasks(state);
    const tasksLoading = getTNATaskLoading(state);
    const formSubmitLoading = getTNAFormUploadLoading(state);
    return {
        tasks,
        tasksLoading,
        formSubmitLoading
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskPerform);
