import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useDispatch } from "react-redux";
import { format_to_date_time } from "../../../lib/date_time";
import { markNotificationRead } from "../../../store/actions/tnaNotificationActions";
import { Link } from "react-admin";

const useStyles = makeStyles({
    root: {
        width: 270,
        padding: 20,
        marginBottom: 20,
        marginTop: 20
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const UnReadNotify = ({ notify, viewLaterFn }) => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const pathMap = {
        "NEED_APPROVAL": ["/approve_tasks", "Show"],
        "APPROVAL_ACCEPTED": ["", "Read"],
        "QR_ACCEPTED": ["", "Read"],
        "TASK_RECEIVED": ["/processing", "Show"]
    }
    return (
        <div>
            {notify.length ? (
                notify.map((data) => (
                    <Card className={classes.root}>
                        <CardContent>
                            <Typography variant="body2" component="p">
                                Create date {format_to_date_time(data.time_stamp)}
                            </Typography>
                            <Typography variant="body2" component="p">
                                {data.details.message}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <div style={{ display: "flex" }}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    class="btn-success py-2 px-4 mb-3 mr-3 ml-3"
                                    color="secondary"
                                    onClick={() => dispatch(markNotificationRead(data.id))}
                                    style={{ justifyContent: "flexStart", marginRight: 20 }}
                                    component={Link}
                                    to={{
                                        pathname: pathMap[data.notify_type][0],
                                    }}
                                >
                                    {pathMap[data.notify_type][1]}
                                </Button>

                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    style={{ justifyContent: "flexEnd" }}
                                    onClick={() => dispatch(viewLaterFn(data.id))}
                                >
                                    View later
                </Button>
                            </div>
                        </CardActions>
                    </Card>
                ))
            ) : (
                <Card className={classes.root}>
                    <CardContent>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                        >
                            No Notifications
            </Typography>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default UnReadNotify;
