import { LOAD_PLANBOARD, LOAD_PLANBOARD_REVISION, LOAD_PLANBOARD_REVISION_SUCCESS, LOAD_PLANBOARD_SUCCESS, PUBLISH_PLANBOARD_REVISION, SAVE_PLAN_BOARD_CHANGES } from "../../types"

const initialState = {
    active: false,
    background: false,
}


export const loadingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOAD_PLANBOARD: {
            return { ...state, active: true }
        }

        case LOAD_PLANBOARD_SUCCESS: {
            return { ...state, active: false, background: false }

        }

        case PUBLISH_PLANBOARD_REVISION: {
            return { ...state, active: true, background: false }
        }

        case LOAD_PLANBOARD_REVISION: {
            return { ...state, background: true }
        }

        case LOAD_PLANBOARD_REVISION_SUCCESS: {
            return initialState
        }

        case SAVE_PLAN_BOARD_CHANGES: {
            return { ...state, background: true }
        }
        default:
            return state
    }
}