import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { Loading } from "react-admin";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { useDispatch, useSelector } from "react-redux";
import RawJsonSchemaEditor from "../../components/input/RawJsonSchemaEditor";
import { showToastError } from "../../lib/toas";
import { createHoliday } from "../../store/actions";
import { getLoading } from "../../store/selectors/holidaySelectors";
import { calendarAttributesSchema } from "./createHolidayAttributesSchema";
import { PreparedTreeCheckbox } from "./PreparedTreeCheckbox";
import Tooltip from "@material-ui/core/Tooltip";

export const CreateHoliday = (props) => {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [formData, setFormData] = useState({
    pattern: { weekday: [], saturday: [], sunday: [] },
  });

  // variables for sub tree
  const [subChecked, setSubChecked] = useState([]);
  const [subExpanded, setSubExpanded] = useState([]);

  const setSubCheckedWrapper = (data) => {
    setSubChecked(data);
    setChecked([]);
  };
  const setCheckedWrapper = (data) => {
    setChecked(data);
    setSubChecked([]);
  };

  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);

  const onChangeForm = (e) => {
    setFormData(e.formData);
  };

  const validateFormData = () => {
    if (checked.length === 0 && subChecked.length === 0) {
      return "Please select at least Plant or Sub Contract";
    }
    if (formData.holidays === undefined) {
      return "Please fill all the fields";
    }

    for (let i = 0; i < formData.holidays.length; i++) {
      let item = formData.holidays[i];
      if (item[0] === undefined) {
        return  "Please fill the reason field";
      }
      if (item[1] === undefined || item[2] === undefined) {
        return "Please fill both start date and end date";
      }
      if (item[1] > item[2]) {
        return "End date should be equal or later than start date"
      }
    }

    return false;
  };

  const submitData = () => {
    // validation
    const error = validateFormData();
    if (error) {
      showToastError(error);
      return false;
    }

    const checkedList = checked[0] ? checked : subChecked;
    dispatch(createHoliday(checkedList, formData.holidays));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid item xs={4}>
        <Box m={3}></Box>
        <Box m={3}>
          <PreparedTreeCheckbox
            checked={checked}
            expanded={expanded}
            setChecked={setCheckedWrapper}
            setExpanded={setExpanded}
          />
          <PreparedTreeCheckbox
            checked={subChecked}
            expanded={subExpanded}
            setChecked={setSubCheckedWrapper}
            setExpanded={setSubExpanded}
            sub={true}
          />
        </Box>
      </Grid>
      <Grid item xs={8}>
        <RawJsonSchemaEditor
          source="config"
          schema={calendarAttributesSchema}
          formData={formData}
          onChangeForm={onChangeForm}
        />
        <Tooltip title="Click here to Create Holidays">
          <Button
            variant="contained"
            className="btn_ btn_primary"
            style={{ float: "right" }}
            onClick={submitData}
            // className="btn_ btn_primary"
          >
            Create
          </Button>
        </Tooltip>

        <Box mb={5}></Box>
      </Grid>
    </Grid>
  );
};
