import React from "react";
import {
  BooleanField,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

const validateSite = (values) => {
  const errors = {};
  if (!values.work_station) {
    errors.work_station = ["work station is required"];
  }
  if (!values.name) {
    errors.name = ["Name is required"];
  }
  return errors;
};
export const EditTrackingStep = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Edit
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm validate={validateSite}>
        <ReferenceInput
          label="work station"
          source="work_station"
          reference="workstations"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <TextInput source="name" />
        <BooleanField source="is_final" />
      </SimpleForm>
    </Edit>
  );
};
