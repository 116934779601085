import React from "react";
import {
  Edit,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
  AutocompleteArrayInput,
  SaveButton,
  DeleteButton,
  Toolbar,
} from "react-admin";
import { redirect } from "../../lib/redirect";
import PermissionSelector from "./PermissionSelector";

import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../components/Confirmation";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 160,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  formErrorFix: {
    marginTop: 10,
    marginBottom: 10,
    width: 70,
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
  },
}));

const validateRole = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = "Code is required";
  }
  if (!values.access_level) {
    errors.access_level = "access level is required";
  }
  if (values.access_level!=="ADMIN" && !values.site) {
    errors.site = "site is required";
  }
  if (["PLANT","BASIC"].includes(values.access_level) && !values.plant) {
    errors.plant = "plant is required";
  }
  if (values.access_level==="BASIC" && values.plan_board_group.length===0) {
    errors.plan_board_group = "plan board group  is required";
  }
  if (values.access_level==="SUB" && values.plan_boards.length===0){
    errors.plan_boards = "plan board is required";
  }
  return errors;
};

export const EditRole = (props) => {
  const { permissions } = usePermissions();
  const usersSite = localStorage.getItem("site");
  const usersPlant = localStorage.getItem("plant");
  const classes = useStyles();

  const accessLevelChoicesArray = [
    permissions?.access_level === "ADMIN"
      ? [
        { id: "ADMIN", name: "ADMIN" },
        { id: "SITE", name: "SITE" },
        { id: "PLANT", name: "PLANT" },
        { id: "SUB", name: "SUB" },
        { id: "BASIC", name: "BASIC" },
        { id: "TNA_ADMIN", name: "TNA ADMIN" },
        { id: "TNA_CLUSTER_MANAGER", name: "TNA CLUSTER MANAGER" },
        { id: "TNA_DEP_ADMIN", name: "TNA DEPARTMENT ADMIN" },
        { id: "TNA_MANAGER", name: "TNA MANAGER" },
        { id: "TNA_PLANNER", name: "TNA PLANNER" },
        { id: "TNA_USER", name: "TNA USER" },
      ]
      : 
    ["SITE"].includes(permissions?.access_level)
      ? [
          { id: "PLANT", name: "PLANT" },
          { id: "SUB", name: "SUB" },
        ]
      : 
    permissions?.access_level === "TNA_ADMIN"
      ? [
          { id: "TNA_CLUSTER_MANAGER", name: "TNA CLUSTER MANAGER" },
          { id: "TNA_DEP_ADMIN", name: "TNA DEPARTMENT ADMIN" },
          { id: "TNA_MANAGER", name: "TNA MANAGER" },
          { id: "TNA_PLANNER", name: "TNA PLANNER" },
          { id: "TNA_USER", name: "TNA USER" },
        ]
      : 
    permissions?.access_level === "TNA_CLUSTER_MANAGER"
            ? [
              { id: "TNA_DEP_ADMIN", name: "TNA DEPARTMENT ADMIN" },
              { id: "TNA_MANAGER", name: "TNA MANAGER" },
              { id: "TNA_PLANNER", name: "TNA PLANNER" },
              { id: "TNA_USER", name: "TNA USER" },
            ] :
    permissions?.access_level === "TNA_DEP_ADMIN"
      ? [
          { id: "TNA_MANAGER", name: "TNA MANAGER" },
          { id: "TNA_PLANNER", name: "TNA PLANNER" },
          { id: "TNA_USER", name: "TNA USER" },
        ]
      : null,
  ];
  const accessLevelChoices = accessLevelChoicesArray.flat();
  //console.log("choices array after processing: ", accessLevelChoices);

  const customToast = useCustomToast({redirect: redirect});


  const CustomToolbar = (props) => {
    const { permissions } = usePermissions();
  return (
    <Toolbar {...props} classes={useStyles()}>
          <Confirmation
            title={"roles delete"}
            disabled={!permissions?.permissions.includes("roles_delete")}
            text={"roles"}
          />
        {" "}
        <SaveButton
          className="btn_ btn_edit"
          disabled={
            Object.keys(props.record).length!==0
              ? !permissions?.permissions.includes("roles_edit")
              : !permissions?.permissions.includes("roles_create")
          }
        />
    </Toolbar>
  );
  }

  const transform = (data)=>{
    const formattedData = {
      ...data,
      plan_board_group: data.access_level==="BASIC"? data.plan_board_group : [],
      plan_boards: data.access_level==="SUB"? data.plan_boards : [],
      plant: ["PLANT","BASIC"].includes(data.access_level)? data.plant : null,
      site: data.access_level!=="ADMIN"? data.site : null,
      tna_department: ["TNA_DEP_ADMIN","TNA_MANAGER","TNA_PLANNER","TNA_USER",]
                      .includes(data.access_level)? data.tna_department : null,
    }
    return formattedData
  }

  const Title = ({ record }) => {
    return <span>Edit Role | {record.id ? `${record.id ?? ""} ` : ""}</span>;
  };

  return (
    <Edit
      undoable={false}
      onSuccess={customToast.onSuccess}
      onFailure={customToast.onFaliure}
      className={"col-md-8 mt-2"}
      transform={transform}
      {...props}
      title={<Title/>}
    >
      <SimpleForm
        toolbar={<CustomToolbar name="roles" text="Role" />}
        validate={validateRole}
      >
        <TextInput
          source="code"
          className="col-md-4"
          label={<ReqInputField name="Code" />}
        />

        <SelectInput
          label="Access level"
          source="access_level"
          choices={accessLevelChoices}
          translateChoice={false}
          className={"col-md-4"}
        />
        {permissions?.access_level === "ADMIN" ? (
          <FormDataConsumer className={"col-md-4"}>
            {({ formData, ...rest }) =>
              formData.access_level &&
              formData.access_level !== "ADMIN" && (
                <ReferenceInput
                  source="site"
                  reference="sites"
                  label={<ReqInputField name="Site" />}
                  className="col-md-4"
                >
                  <SelectInput
                    optionText="code"
                    label="site"
                    translateChoice={false}
                  />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
        ) : null}

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData?.access_level &&
            [
              "TNA_DEP_ADMIN",
              "TNA_MANAGER",
              "TNA_PLANNER",
              "TNA_USER",
            ].includes(formData?.access_level) === true && (
              <ReferenceInput
                source="tna_department"
                reference="tna_departments"
                label="Department"
              >
                <SelectInput optionText="code" label="Department" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        {["ADMIN", "SITE"].includes(permissions?.access_level) === true ? (
          <FormDataConsumer className={"col-md-4"}>
            {({ formData, ...rest }) =>
              formData.access_level &&
              ["PLANT", "BASIC"].includes(formData.access_level) === true && (
                <ReferenceInput
                  source="plant"
                  reference="plants"
                  label={<ReqInputField name="Plant" />}
                  filter={{ site: formData.site }}
                  className={"col-md-4"}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
        ) : null}
        {["ADMIN", "SITE", "PLANT"].includes(permissions?.access_level) && (
          <FormDataConsumer className={"col-md-4"}>
            {({ formData, ...rest }) =>
              formData &&
              formData.access_level &&
              formData.access_level === "SUB" && (
                <ReferenceArrayInput
                  source="plan_boards"
                  reference="planBoards"
                  filter={{ site: usersSite, plant: usersPlant }}
                  className={"col-md-4"}
                >
                  <AutocompleteArrayInput
                    optionText="code"
                    //label="planboards"
                    //className="col-md-4"
                    label={<ReqInputField name="Plan boards" />}
                  />
                </ReferenceArrayInput>
              )
            }
          </FormDataConsumer>
        )}

        <FormDataConsumer className={"col-md-4"}>
          {({ formData, ...rest }) =>
            formData.access_level &&
            formData.access_level === "BASIC" && (
              <ReferenceArrayInput
                source="plan_board_group"
                reference="boardGroup"
                // label={<ReqInputField name="Planboard Group" />}
                // className={classes.formControl}
                className={"col-md-4"}
              >
                <SelectArrayInput
                  optionText="code"
                  label={<ReqInputField name="Planboard Group" />}
                />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>
        <ReferenceArrayInput
          source="permissions"
          reference="permissions"
          //  label={<ReqInputField name="Permissions" />}
          perPage={300}
          translateChoice={false}
          className={"col-md-4"}
        >
          {/* <SelectArrayInput optionText="code" /> */}
          <PermissionSelector translateChoice={false} optionText="code" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};
