import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { Loading } from "react-admin";
import dataProvider from "../../../../api/dataProvider";
import {
  showToastError,
  showToastSuccess,
  showToastWarning,
} from "../../../../lib/toas";

const Popup = ({ isOpen, onClose, selectedIds }) => {
  // const dataProvider = useDataProvider();

  const [open, setOpen] = React.useState(false);
  const [calOpen, setCalOpen] = React.useState(false);
  const [data, setData] = React.useState();
  // ui functions

  React.useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCal = () => {
    setCalOpen((cs) => !cs);
  };
  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  // databainding

  const handelRequest = () => {
    handleClose();
    dataProvider
      .CUSTOM_ACTION("orders", {
        action: "resolve_given_orders",
        body: selectedIds,
      })
      .then(({ data }) => {
        if (data.error) {
          setTimeout(() => {
            // to prevent notifications overlapping
            showToastError("Orders resolve failed");
          }, 2000);
          return false;
        }
        if (data.success) {
          showToastSuccess("Orders resolve success ! ");
          setData(data);
        } else {
          setTimeout(() => {
            // to prevent notifications overlapping
            showToastWarning("Orders already resolved");
          }, 2000);
        }
      })
      .catch((error) => {
        showToastError("Server error occurred!");
      });
  };

  return (
    <div>
      <Dialog
        fullWidth="true"
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ paddingTop: "10px" }} id="alert-dialog-title">
              {"Bulk Orders Resolve View"}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        <DialogContent>
          {selectedIds && selectedIds.length > 0 ? (
            <h6>Selected order id's are </h6>
          ) : (
            ""
          )}
          {selectedIds ? (
            selectedIds.map((id, i) => <p key={i}>{id}</p>)
          ) : (
            <Loading />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handelRequest}
            // color="secondary"
            className="btn_ btn_edit"
            style={{ fontSize: "14px" }}
            autoFocus
          >
            Resolve selected Orders
          </Button>
          {/* <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Popup;
