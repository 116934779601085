import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { usePermissions } from "react-admin";
import { useSelector, useDispatch } from 'react-redux';
import { WS_URL } from "../../../constants";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import dataProvider from "../../../api/dataProvider";
import { loadTNANotifications, } from '../../../store/actions/tnaNotificationActions';
import { getTNAUnreadNotifications, getTNAUnReadNotificationCount } from '../../../store/selectors/tnaNotificationSelectors';
import { showToastSuccess, showToastError, showToast } from "../../../lib/toas"

const payload = {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "username", order: "ASC" },
};

const TNAPushNotifications = () => {
    const [user, setUser] = useState();
    const accessLevel = usePermissions().permissions?.access_level;
    const dispatch = useDispatch()
    const notifications = useSelector(getTNAUnreadNotifications)

    const wsUrl = `${WS_URL}/ws/tna/notification/${accessLevel}/${user?.id}`
    const { lastMessage, readyState } = useWebSocket(wsUrl);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        dataProvider
            .GET_USER("userProfile", { payload: payload })
            .then((resp) => setUser(resp.data));
    }, []);

    useEffect(() => {
        dispatch(loadTNANotifications())
        if (lastMessage != null) {
            showToast(JSON.parse(lastMessage.data).notification.details.message)
        }

    }, [lastMessage])

    return (<></>)

}

export default TNAPushNotifications