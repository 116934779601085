
import React from 'react'
import { SimpleForm, TextInput, NumberInput, BooleanInput, Create } from 'react-admin'
export const TemplateItemsCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <NumberInput source="sequence_no" />
            <NumberInput source="task" />
            <NumberInput source="assigned_department" />
            <NumberInput source="assigned_user" />
            <NumberInput source="start_offset_days" />
            <NumberInput source="duration" />
            <TextInput source="dependent_item" />
            <BooleanInput source="need_approval" />
        </SimpleForm>
    </Create>
);