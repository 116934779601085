import React, { useEffect } from 'react'


/**
 * Add you background image in App.css as a class which can be assinged to body. name is as imageName-background
 * @param props 
 */
export default function BackgroundImage(props: {imageName: string}) {
    const {imageName} = props
    useEffect(() => {
        const className = `${imageName}-background`
        document.body.classList.add(className)
        return () => {
            document.body.classList.remove(className)
        }
    }, [imageName])

    return null
}
