import React from "react";

export const ChangeDiv = (props) => {
    const { children, ...rest} = props
    return <div
    className = "table-width-custom"
    style={{
      height: window.innerHeight * 0.76,
      overflowY: "auto",
    }}
    >
      {children(rest)}
    </div>
  }