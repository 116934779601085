import React, { useState, useEffect, useMemo, useCallback } from "react";
// import AdvancedTable, { Column } from "../../components/AdvancedTable";
import ReactTable from "../../components/ReactTable";
import {
  NumberColumnFilter,
  DateColumnFilter,
  comparativeFilter,
} from "../../components/ReactTableFilters";
import { useSelector, useDispatch } from "react-redux";
import {
  getDemandOperations,
  getAllocations,
  getLines,
  getIsUserCanEdit,
  getPlanBoard,
  getColorMappings,
  getBuckets,
} from "../../store/selectors";

import { createDragPreview } from "react-dnd-text-dragpreview";
import _ from "lodash";
import { format_duration, format_to_date } from "../../lib/date_time";
import moment from "moment";
import {
  Button,
  ButtonGroup,
  Switch,
  Typography,
  FormControlLabel,
  Tooltip,
  FormGroup,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import OpenIcon from "@material-ui/icons/OpenInBrowser";
import DragIcon from "@material-ui/icons/DragIndicator";
import { loadPlanBoard, selectEntity } from "../../store/actions";
import { freezeAllocations } from "../../store/actions/planActions";
import { getDemandOperationStatusColor } from "../../lib/plan_board/utils";
import { IAllocation, IDemandOperation, ILine } from "../../lib/plan";
import { DemandOpDialog } from "./DemandOpDialog";
import { ExportDialog } from "./helperComponents/TableViewExport/ExportDialog";
import { getColorFromConfiguration } from "../../lib/color";
import { DragPreviewImage, useDrag } from "react-dnd";
import { usePermissions } from "react-admin";
import { calExp } from "../../lib/calculations";
import { tableview_fields } from "./Create";
import {
  RangeFilterDialog,
  RangeFilterButton,
} from "../../components/RangeFilter";
import CloseIcon from "@material-ui/icons/Close";
import { AutoPlanErrorsPopover, DemandDetailPopOver } from "./TableRowPopup";

import { getSelectedTimeRange } from "../../store/selectors";
import { stringJoinWIthStars } from "../../components/field/ListStringJoinFiedl";

export default function TableView({
  w_height,
  w_width,
  setSelectedOrderIds,
  selectedOrderIds,
}: {
  w_height?: number;
  w_width: number;
  setSelectedOrderIds: any;
  selectedOrderIds: any[];
}) {
  const allocationTimeRange = useSelector(getSelectedTimeRange);
  const buckets = useSelector(getBuckets);
  const planBoard = useSelector(getPlanBoard);
  const demandOperations = useSelector(getDemandOperations);
  const allocations = useSelector(getAllocations);
  const allocationsByDemandOperation = _.groupBy(
    allocations,
    (d) => d.demand_operation_id
  );
  const colorMappings = useSelector(getColorMappings);
  const lines = useSelector(getLines);
  const isUserCanEdit = useSelector(getIsUserCanEdit);

  const dispatch = useDispatch();
  const { permissions } = usePermissions();

  const lineById = _.keyBy(lines, "id");

  const [isGroups, setIsGroups] = useState(false);
  const [showClosedOrders, setShowClosedOrders] = useState(false);
  const [showPlannedOrders, setShowPlannedOrders] = useState(true);
  const [showRMLessOrders, setShowRMLessOrders] = useState(false);
  const [openTransferPopup, setOpenTransferPopup] = useState(false);
  const [openExportPopup, setOpenExportPopup] = useState(false);
  const [openColumnSelectorPopup, setOpenColumnSelectorPopup] = useState(false);

  const [openRangeFilterDialog, setOpenRangeFilterDialog] = useState(false);
  const [filterByPlanboardPeriod, setFilterByPlanboardPeriod] = useState(false);
  const [rangeFilters, setRangeFilters] = useState<
    {
      column: any;
      start: any;
      end: any;
      type: string | null;
    }[]
  >([
    {
      column: "",
      start: "",
      end: "",
      type: "",
    },
  ]);

  useEffect(() => setSelectedOrderIds([]), [isGroups]);
  const refresh = () => {
    dispatch(loadPlanBoard(planBoard.id));
  };

  const formatSelectedRows = (ids: any) => {
    if (selectedOrderIds.length !== ids.length) {
      setSelectedOrderIds(ids);
    }
  };

  const forceSetOpen = () => {
    setOpenTransferPopup(false);
    setOpenExportPopup(false);
    refresh();
  };

  const onNavigateRow = useCallback(
    (row: any) => {
      if (isGroups) {
        const demandOperationsInGroup = demandOperations.filter(
          (d) => d.order_group_code === row.order_group_code
        );
        const demandOperationIds = demandOperationsInGroup.map((d) => d.id);
        const demandOperationIdsSet = new Set(demandOperationIds);
        const a = allocations.find((a) =>
          demandOperationIdsSet.has(a.demand_operation_id)
        );
        if (a) {
          dispatch(selectEntity("allocations", a.id));
        }
      } else {
        dispatch(selectEntity("demand_operations", row.id));
      }
    },
    [isGroups]
  );

  const isDemandOperationFreezed = (d: IDemandOperation) => {
    const allocs = allocationsByDemandOperation[d.id] || [];
    return allocs.length > 0 && allocs.every((a) => a.is_freezed); // allocs.map(a => a.is_freezed ? 'Y' : 'N').join(",") //
  };

  const getDelay = (row: any) => {
    if (!row.planned_end_date || !row.required_time_max) {
      return "";
    }
    const days = moment(row.planned_end_date).diff(
      row.required_time_max,
      "days"
    );
    return days;
  };

  const rows = useMemo(
    () =>
      demandOperations
        .filter(
          (d) => !showRMLessOrders || typeof d.rm_ready_date === "undefined"
        )
        .filter((d) => showClosedOrders || !d.order_is_closed)
        .filter((d) => showPlannedOrders || d.planned_quantity < d.quantity)
        .map((d) => ({
          ...d,
          isFreezed: isDemandOperationFreezed(d),
          line: _.chain(allocationsByDemandOperation[d.id] || [])
            .map((a) => a.plan_board_line_id)
            .map((lineId) => lineById[lineId].code)
            .uniq()
            .value(),
          production_time: _.chain(allocationsByDemandOperation[d.id] || [])
            .map((a) => (a.end_time.valueOf() - a.start_time.valueOf()))
            .sum()
            .value() || 0, 
        }))
        .map((v) => ({
          ...v,
          original_demand_operation_id: v.id,
          id: isGroups ? v.order_group_code : v.id,
          delay: getDelay(v),
          planned_start_date: v.planned_start_date || "",
          planned_end_date: v.planned_end_date || "",
          order_required_month: v.order_required_date
            ? moment(v.order_required_date).format("MM") || ""
            : "",
        })),
    [
      demandOperations,
      isGroups,
      allocations,
      showClosedOrders,
      showPlannedOrders,
      showRMLessOrders,
    ]
  );

  const finalRows = useMemo(() => {
    const initialRows: any = isGroups ? mergeToGroups(rows) : rows;

    const planboardTimePeriodFilter = (initialRows: any) => {
      const inRangeBucketIds = buckets.map((bucket) => bucket.id);
      const inRangeDemandOpIds = allocations
        .filter((allocation: IAllocation) =>
          inRangeBucketIds.includes(allocation.line_bucket_id)
        )
        .map((allocation: IAllocation) => allocation.demand_operation_id);
      return initialRows.filter((demandOperation: any) =>
        inRangeDemandOpIds.includes(demandOperation.id)
      );
    };

    const allRows = filterByPlanboardPeriod
      ? planboardTimePeriodFilter(initialRows)
      : initialRows;

    function filterByRangeValue(index: number, initialRows: any[]): any {
      const rangeFilter = rangeFilters[index];
      const filteredRows = initialRows.filter((row: any) => {
        const column = rangeFilter?.column;
        const val = rangeFilter && row[column?.code];
        const valInRange =
          rangeFilter &&
          (!rangeFilter?.start || val >= rangeFilter?.start) &&
          (!rangeFilter?.end || val <= rangeFilter?.end);
        return valInRange;
      });

      const rows = !index
        ? filteredRows
        : filterByRangeValue(index - 1, filteredRows);
      return rows;
    }
    // if (!openRangeFilterDialog) {
    return rangeFilters[0]
      ? filterByRangeValue(rangeFilters.length - 1, allRows)
      : allRows;
    // } else {
    //   return allRows;
    // }
  }, [
    rows,
    isGroups,
    rangeFilters,
    openRangeFilterDialog,
    filterByPlanboardPeriod,
  ]);

  const selectedAllocs = getSelectedAllocations(
    selectedOrderIds,
    allocations,
    isGroups,
    demandOperations
  );
  const toFreeze = selectedAllocs.some((v) => !v.is_freezed);
  const handleFreeze = () => {
    const allocIds = selectedAllocs.map((a) => a.id);
    dispatch(freezeAllocations(allocIds, toFreeze));
  };
  const rowStyle = useCallback(
    (row: any) => (row.order_is_closed ? "table-secondary" : ""),
    []
  );

  const isAnyAllocSelected = selectedAllocs.length > 0;
  const isAnyRowsSelected = selectedOrderIds.length > 0;

  const getDemandDetails=(demandDetail:any,rm_ready:any)=>{
    const rawDemands=demandDetail?.demands
    const forceSupply=demandDetail?.demands_force_supply_time
    const demandsIsRm=demandDetail?.demands_is_rm
    const supplyTime=demandDetail?.demands_supply_time
    let uniqueDemands:any[]=[]
    let uniqueForceSuppy:any[]=[]
    let uniqueIsRm:any[]=[]
    let uniqueSupply:any[]=[]
   
    rawDemands.forEach((element:any,index:any) => {
      if(!uniqueDemands.includes(element)){
        uniqueDemands.push(element)
        uniqueForceSuppy.push(forceSupply[index])
        uniqueIsRm.push(demandsIsRm[index])
        uniqueSupply.push(supplyTime[index])    
      }
      
    });

    return({
      demands: uniqueDemands,
      force_supply: uniqueForceSuppy,
      is_rm: uniqueIsRm,
      supply_time: uniqueSupply,
      rm_ready: rm_ready,
    })
  }

  const dateSorter = useCallback((rowA: any, rowB: any, columnId: any, desc: boolean) => {
    console.log("RowA: ", rowA)
    if (rowA.original[columnId] > rowB.original[columnId]) return -1;
    else if (rowA.original[columnId] < rowB.original[columnId]) return 1;
    else return 0;
  },[])

  const _columns = useMemo(
    () => {
      const cols = [
      {
        Header: " ",
        width: 50,
        Filter: false,
        Cell: (props: any) => {
          const row = props.cell.row.original;
          const value = row.line.join(",");

          const [collectedProps, drag, preview] = useDrag({
            item: {
              id: row.original_demand_operation_id,
              type: "demand_operation",
            },
            canDrag: () => isUserCanEdit,
            collect: (monitor) => {
              return { isDragging: monitor.isDragging() };
            },
          });
          const dragPreviewStyle = {
            backgroundColor: "rgb(68, 67, 67)",
            color: "white",
            fontSize: 15,
            paddingTop: 4,
            paddingRight: 7,
            paddingBottom: 6,
            paddingLeft: 7,
          };
          const dragImage = createDragPreview(
            `${row.order_code}`,
            dragPreviewStyle
          );

          return (
            <div style={{ display: "flex", width: "100%" }}>
              <span ref={drag}>
                <DragIcon height={5} fontSize="small" color="secondary" />
              </span>
              {/* <img src={dragImage.toDataURL()}></img> */}
              <DragPreviewImage connect={preview} src={dragImage.src} />

              <IconButton
                onClick={() => onNavigateRow(row)}
                style={{ marginTop: "0px", height: "25px" }}
              >
                <OpenIcon height={5} fontSize="small" color="secondary" />
              </IconButton>
            </div>
          );
        },
        style: { padding: "0px important!" },
        accessor: (d: any) => d.line.join(","),
      },
      {
        Header: "Line",
        width: 90,
        Cell: (props: any) => {
          const row = props.cell.row.original;
          const value = row.line.join(",");
          return (
            <div style={{ display: "flex", width: "60px" }}>
              <Tooltip title={value}>
                <p
                  className="line-scroll"
                  style={{
                    width: "60px",
                    overflow: "auto",
                    color: row.isFreezed ? "blue" : undefined,
                    scrollbarWidth: "thin",
                  }}
                >
                  {row.isFreezed ? "❄️ " : ""}
                  {value}
                </p>
              </Tooltip>
            </div>
          );
        },
        style: { padding: "0px important!" },
        accessor: (d: any) => d.line.join(","),
      },
      {
        accessor: "order_group_code",
        Header: "Order Group",
      },
      {
        Header: "Order",
        Cell: (props: any) => {
          const row = props.cell.row.original;
          if (row.is_exchange) {
            return (
              <span
                style={{
                  color: row.order_is_closed ? "gray" : undefined,
                  padding: 5,
                  borderRadius: 5,
                  backgroundColor: "red",
                  // disabled: row.is_exchange === true
                }}
              >
                {row.order_code}
              </span>
            );
          } else {
            return <span> {row.order_code}</span>;
          }
        },
        accessor: "order_code",
      },
      {
        accessor: "order_release_number",
        Header: "Release No.",
      },
      {
        accessor: "item_code",
        Header: "Item",
      },
      {
        accessor: "compound_codes",
        Header: "Compounds",
      },

      {
        accessor: "configuration",
        Header: "Configuration",
        getCellStyleFunction(row: any): any {
          const backgroundColor = getColorFromConfiguration(
            colorMappings,
            row.configuration
          );
          return { backgroundColor };
        },
      },
      {
        accessor: "order_required_month",
        code: "order_required_month",
        Header: "Month",
        filterType: "NumberColumnFilter",
        Filter: NumberColumnFilter,
      },
      {
        accessor: "order_week_number",
        code: "order_week_number",
        Header: "Week No.",
      },
      {
        Header: "Confirmation",
        code: "order_confirmation_date",
        accessor: (d: any) => {
          format_to_date(d.order_confirmation_date);
        },
      },
      {
        Header: "Order Need",
        code: "order_required_date",
        id: "order_need",
        //   accessor: (d: any) => {
        //     return format_to_date(d.order_required_date);
        //   },
        accessor: (d: any) => {
          return format_to_date(d.order_required_date);
        },
        filterType: "DateColumnFilter",
        Filter: DateColumnFilter,
        sortType: (a: any,b: any) => dateSorter(a,b,"order_required_date",false)
      },
      {
        Header: "Need Date",
        code: "required_time_max",
        accessor: (d: any) => {
          return format_to_date(d.required_time_max);
        },
        filterType: "DateColumnFilter",
        Filter: DateColumnFilter,
        sortType: (a: any,b: any) => dateSorter(a,b,"required_time_max",false),
      },
      {
        Header: "Rm Ready",
        code: "rm_ready_date",
        accessor: (d: any) => {
          return format_to_date(d.rm_ready_date);
        },
        filterType: "DateColumnFilter",
        Filter: DateColumnFilter,
        clickable: true,
        sortType: (a: any,b: any) => dateSorter(a,b,"rm_ready_date",false),
        Cell: (props: any) => {
          return (
            <DemandDetailPopOver
              demandDetails={getDemandDetails(props.cell.row.original,props.cell.row.values["Rm Ready"])}
            />
          );
        },
        getCellStyleFunction(row: any): any {
          return {
            backgroundColor: getBackgroundColorForRMReady(row),
            cursor: "pointer",
          };
        },
      },
      {
        Header: "Plan Start",
        code: "planned_start_date",
        accessor: (d: any) => {
          return format_to_date(d.planned_start_date);
        },
        filterType: "DateColumnFilter",
        Filter: DateColumnFilter
      },
      {
        Header: "Plan End",
        code: "planned_end_date",
        accessor: (d: any) => {
          return format_to_date(d.planned_end_date);
        },
        filterType: "DateColumnFilter",
        Filter: DateColumnFilter
      },
      {
        Header: "Delay(days)",
        code: "delay",
        filter: comparativeFilter,
        accessor: (d: any) => {
          const days = d.delay;
          return days >= 0 ? `${days}` : `${-days} early`;
        },
        getCellStyleFunction(row: any): any {
          return {
            backgroundColor: row.delay > 0 ? "pink" : undefined,
            cursor: "pointer",
          };
        },
      },
      {
        accessor: (d: any) => {
          return d.production_time > 0 ? format_duration(d.production_time) : ""
        },
        code: "production_time",
        Header: "Production Time",
        filterType: "NumberColumnFilter",
        Filter: NumberColumnFilter,
      },
      {
        accessor: "quantity_multiplier",
        code: "quantity_multiplier",
        Header: "Mlt",
        filterType: "NumberColumnFilter",
        Filter: NumberColumnFilter,
      },
      {
        accessor: "quantity",
        code: "quantity",
        Header: "Quantity",
        filterType: "NumberColumnFilter",
        Filter: NumberColumnFilter,
      },
      {
        accessor: "skipped_quantity",
        code: "skipped_quantity",
        Header: "Skipped",
        filterType: "NumberColumnFilter",
        Filter: NumberColumnFilter,
      },
      {
        accessor: "planned_quantity",
        code: "planned_quantity",
        Header: "Planned",
        filterType: "NumberColumnFilter",
        Filter: NumberColumnFilter,
        getCellStyleFunction(row: any): any {
          return {
            backgroundColor: getDemandOperationStatusColor(
              row.quantity,
              row.planned_quantity,
              row.completed_quantity
            ),
            cursor: "pointer",
          };
        },
      },
      {
        accessor: "completed_quantity",
        code: "completed_quantity",
        Header: "Completed",
        filterType: "NumberColumnFilter",
        Filter: NumberColumnFilter,
      },
      {
        accessor: "auto_plan_errors",
        Header: "Plan Errors",
        clickable: true,
        Cell: (props: any) => {
          const row = props.cell.row.original
          return (
            <AutoPlanErrorsPopover
              row={row}
            />
          );
        }
      },
      {
        accessor: "operation_code",
        Header: "Operation",
      },
      {
        accessor: "smv",
        code: "smv",
        Header: "SMV",
        filterType: "NumberColumnFilter",
        Filter: NumberColumnFilter,
      },
    ]
    const firstCol = cols[0]
    const colsByName = _.keyBy(tableview_fields, col => col.name)
    const restCols = _.sortBy(cols.slice(1), col => (planBoard?.tableview_attributes || []).indexOf(colsByName[col.Header].id))
    return [firstCol, ...restCols]
  },
    [isUserCanEdit, onNavigateRow, planBoard, tableview_fields]
  );
  const getBackgroundColorForRMReady = (data: any) => {
    const demands = data.demands;
    const demandsSupplyTime = data.demands_supply_time;
    const isRm=data.demands_is_rm;
    const demandsForceSupplyTime = data.demands_force_supply_time;

    // if force upload has been done
    if (demandsForceSupplyTime[0] != null) {
      for (let i = 0; i < demands.length; i++) {
        // if atleast one sfg has not been supplied
        if(!(isRm[i])){
          if (demandsSupplyTime[i] == null) {
            return "#ef8484";
          }
        }
      }
      // if all planned
      return "lightgreen";
    }
    // if force has not been done
    else {
      for (let i = 0; i < demands.length; i++) {
        // if atleast one sfg has been supplied
        if(!(isRm[i])){
          if (demandsSupplyTime[i] != null) {
            return "yellow";
          }
        }
      }
      // no one has planned
      return undefined;
    }
  };
  const mappedAttributes = React.useMemo(() => {
    if (!planBoard) {
      return [];
    }
    return tableview_fields
      .filter(
        (column: any) =>
          planBoard.tableview_attributes &&
          planBoard.tableview_attributes.includes(column.id)
      )
      .map((column: any) => column.name);
  }, [planBoard]);
  const selectedDemandOperations = useMemo(() => {
    const ids = isGroups ? selectedOrderIds : selectedOrderIds.map(Number);
    const selectedOrderIdsSet = new Set(ids);
    return finalRows.filter((row: any) => selectedOrderIdsSet.has(row.id));
  }, [finalRows, selectedOrderIds]);

  if (!planBoard) {
    return <div></div>;
  }

  const headerContent = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <RangeFilterButton
        setOpenRangeFilterDialog={setOpenRangeFilterDialog}
        rangeFilters={rangeFilters}
        w_width={w_width}
        setRangeFilters={setRangeFilters}
      />
      <Button
        size={w_width > 1500 ? `medium` : `small`}
        className="btn_ btn_primary mb-0"
        variant="contained"
        onClick={() => setOpenColumnSelectorPopup(true)}
        title="click here to select columns"
        disabled={!permissions?.permissions.includes("tableView_selectColumns")}
      >
        Select columns
      </Button>
      <ButtonGroup size="large" className="btn-group m-0" variant="contained">
        <Button
          className={!isGroups ? " btn_primary" : ""}
          style={{ marginLeft: "4px" }}
          onClick={() => setIsGroups((v) => !v)}
          variant={!isGroups ? "contained" : "outlined"}
          disabled={isGroups}
        >
          Groups
        </Button>
        <Button
          className={isGroups ? " btn_primary" : ""}
          onClick={() => setIsGroups((v) => !v)}
          variant={isGroups ? "contained" : "outlined"}
          disabled={!isGroups}
        >
          Jobs
        </Button>
      </ButtonGroup>
      {/* </div> */}

      <FormControlLabel
        style={{
          paddingLeft: calExp(w_width * 0.01),
          marginBottom: 0,
        }}
        control={
          <Switch
            checked={showPlannedOrders}
            onChange={() => setShowPlannedOrders((v) => !v)}
            title="Show Completely Planned Jobs"
            color="primary"
          />
        }
        label={
          <p
            style={{
              textAlign: "left",
              fontSize: 10,
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            Show Completely<br></br>Planned Jobs
          </p>
        }
      />
      <FormControlLabel
        style={{
          // paddingLeft: calExp(w_width * 0.015),
          marginBottom: 0,
        }}
        control={
          <Switch
            color="primary"
            value={showClosedOrders}
            onChange={() => setShowClosedOrders((v) => !v)}
            title="Show Closed Orders"
          />
        }
        label={
          <p
            style={{
              textAlign: "left",
              fontSize: 10,
              marginTop: 0,
              marginBottom: 0,
              // marginRight: calExp(w_width * 0.015),
            }}
          >
            Show<br></br>Closed Orders
          </p>
        }
      />

      <FormControlLabel
        style={{
          // paddingLeft: calExp(w_width * 0.015),
          marginBottom: 0,
        }}
        control={
          <Switch
            color="primary"
            value={showRMLessOrders}
            onChange={() => setShowRMLessOrders((v) => !v)}
            title="Show demands without RM ready"
          />
        }
        label={
          <p
            style={{
              textAlign: "left",
              fontSize: 10,
              marginTop: 0,
              marginBottom: 0,
              // marginRight: calExp(w_width * 0.015),
            }}
          >
            Demands without<br></br>RM ready
          </p>
        }
      />

      <FormControlLabel
        style={{
          // paddingLeft: calExp(w_width * 0.015),
          marginBottom: 0,
        }}
        control={
          <Switch
            checked={filterByPlanboardPeriod}
            onChange={() => setFilterByPlanboardPeriod((v) => !v)}
            title="Filter By Planboard Period"
            color="primary"
          />
        }
        label={
          <p style={{ textAlign: "left", fontSize: 10, marginTop: 15 }}>
            Filter By<br></br>Planboard Period
          </p>
        }
      />
      <Button
        size={w_width > 1500 ? `medium` : `small`}
        className="btn_ btn_primary mb-0"
        variant="contained"
        disabled={
          !isAnyRowsSelected ||
          !permissions?.permissions.includes("tableView_export")
        }
        onClick={() => setOpenExportPopup(true)}
        title="permissions"
      >
        Export
      </Button>
      <Button
        size={w_width > 1500 ? `medium` : `small`}
        color={toFreeze ? "primary" : "default"}
        // className="MuiButton-containedPrimary"
        className="btn_ btn_primary mb-0"
        variant="contained"
        disabled={
          !isAnyAllocSelected ||
          !isUserCanEdit ||
          !permissions?.permissions.includes("tableView_freeze")
        }
        onClick={handleFreeze}
        title="permissions"
      >
        {toFreeze ? `Freeze` : `Unfreeze`}
      </Button>
      <Button
        size={w_width > 1500 ? `medium` : `small`}
        className="btn_ btn_primary mb-0"
        variant="contained"
        disabled={
          !isAnyRowsSelected ||
          !permissions?.permissions.includes("tableView_transfer")
        }
        onClick={() => setOpenTransferPopup(true)}
        title="permissions"
      >
        Transfer
      </Button>
    </div>
  );

  return (
    <div>
      <ReactTable
        setRangeFilters={setRangeFilters}
        window_width={w_width}
        window_height={w_height}
        columns={_columns}
        data={finalRows}
        isOpenSelectorPopup={openColumnSelectorPopup}
        setOpenSelectorPopup={setOpenColumnSelectorPopup}
        setSelectedIds={formatSelectedRows}
        getCellProps={(cellInfo: any) => {
          const column = _columns.filter(
            (column: any) => column.Header === cellInfo.column.Header
          )[0];
          const styleFunc =
            column && column.getCellStyleFunction
              ? column.getCellStyleFunction
              : (props: any) => null;

          const style = styleFunc(cellInfo.row.original);

          return { style };
        }}
        attributes={mappedAttributes}
        headerContent={headerContent}
      />
      <RangeFilterDialog
        isOpen={openRangeFilterDialog}
        setIsOpen={setOpenRangeFilterDialog}
        columns={_columns}
        setRangeFilters={setRangeFilters}
        rangeFilters={rangeFilters}
      />

      <DemandOpDialog
        isOpen={openTransferPopup}
        values={selectedDemandOperations}
        forceSetOpen={forceSetOpen}
      />
      <ExportDialog
        isOpen={openExportPopup}
        values={selectedDemandOperations}
        setOpenExportPopup={setOpenExportPopup}
      />
    </div>
  );
}

const mergeToGroups = (rows: any[]) => {
  const groupsObject = _.groupBy(rows, (item) => item.order_group_code);
  const customizer = (
    objValue: any,
    srcValue: any,
    key: string,
    object: any,
    source: any
  ) => {
    switch (key) {
      case "order_required_date":
      case "required_time_max":
        return _.min([objValue, srcValue]);
      case "item_code":
        return stringJoinWIthStars([objValue, srcValue]);
      case "order_code":
        return stringJoinWIthStars([objValue, srcValue]);
      case "configuration":
        return stringJoinWIthStars([objValue, srcValue]);
      case "order_required_month":
        return stringJoinWIthStars([objValue, srcValue]);
      case "line":
        return _.uniq([...objValue, ...srcValue]); // return [objValue, srcValue].join(",")
      case "smv":
        return _.max([objValue, srcValue]);
      case "isFreezed":
        return objValue && srcValue;
      case "order_is_closed":
        return objValue && srcValue;

      case "quantity":
      case "planned_quantity":
      case "skipped_quantity":
      case "completed_quantity":
      case "production_time":
      case "unplanned_quantity_in_previouse_operations":
        return _.sum([objValue, srcValue]);
      case "planned_start_date":
        return _.min([objValue, srcValue]);
      case "planned_end_date":
        return _.max([objValue, srcValue]);
      case "rm_ready_date":
        return _.max([objValue, srcValue]);
    }
  };
  const mergeRows = Object.values(groupsObject).map((groupRows) =>
    _.mergeWith(groupRows[0], ...groupRows.slice(1), customizer)
  );
  return mergeRows;
};

const getSelectedAllocations = (
  selectedOrderIds: any[],
  allocations: IAllocation[],
  isGroups: boolean,
  demandOperations: IDemandOperation[]
) => {
  const demandOperationMap = _.keyBy(demandOperations, "id");
  const ids = new Set(selectedOrderIds);
  const filterFunction = isGroups
    ? (a: IAllocation) =>
      ids.has(demandOperationMap[a.demand_operation_id]?.order_group_code)
    : (a: IAllocation) => ids.has(`${a.demand_operation_id}`);
  return allocations.filter(filterFunction);
};
