import React from 'react';
import { connect } from "react-redux";
import { getSelectedEntity } from '../../store/selectors';
import AllocationDetailed from "./AllocationDetailed";
import BucketDetailed from "./BucketDetailed";
import DemandOperationDetailed from "./DemandOperationDetailed";
import LineAllocation from "./LineAllocationDetail";

function DetailsPane({ selection, selectedEntity, onClear }) {
    return (
        <div>
            {selection && selection.type === "buckets" && (
                <BucketDetailed id={selection.id} />
            )}
            {selection && selection.type === "demand_operations" && (
                <DemandOperationDetailed id={selection.id} />
            )}
            {selection && selection.type == "allocations" && (
                <>
                    <AllocationDetailed id={selection.id} />
                    <br />
                    {selectedEntity.demand_operation_id && <DemandOperationDetailed id={selectedEntity.demand_operation_id} />}
                </>
            )}
            {selection && selection.type === "lines" && (
                <LineAllocation id={selection.id} />
            )}
        </div>
    )
}


const mapStateToProps = (state) => {
    const selectedEntity = getSelectedEntity(state)
    const selection = state.custom.planBoard.selection
    return {
        selection: selectedEntity ? selection : null,
        selectedEntity
    }
}

const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(DetailsPane)