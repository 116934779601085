import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicator from "@material-ui/icons/DragIndicator";
import _ from "lodash";
import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { connect } from "react-redux";
import { getColorFromConfiguration } from "../../lib/color";
import {
  format_to_date,
  format_to_date_time,
  format_to_time,
} from "../../lib/date_time";
import {
  requestCreateAllocation,
  requestDeleteAllocation,
} from "../../store/actions";
import {
  getAllocations,
  getColorMappings,
  getDemandOperations,
  getEntity,
} from "../../store/selectors";
import { theme } from "../../layout/theme";

function CreateAllocation({
  line,
  bucket,
  config_match_demand_operations,
  config_unmatch_demand_operations,
  onAllocate,
  onChangeConfig,
  onClose,
  configuration,
  onDelete,
  allocations,
}) {
  const [selectedDemandOperation, setSelectedDemandOperation] = useState();
  const [searchStr, setSearchStr] = useState("");

  const handleDrop = (demandOperationId, time) => {
    onAllocate(demandOperationId, line.id, time);
  };

  const searchtxt = (str) => {
    if (!str) {
      return false;
    }
    if (str.order_code.includes(searchStr)) {
      return true;
    } else if (str.order_group_code.includes(searchStr)) {
      return true;
    } else if (str.configuration.includes(searchStr)) {
      return true;
    } else if (str.item_code.includes(searchStr)) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={9}>
          <h5>{line.code}</h5>
          <h4>{configuration}</h4>
        </Grid>
        <Grid item xs={3}>
          <form>
            <label>Filter by order code : </label>
            <Input
              type="text"
              placeholder="Search"
              name="search"
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value.toUpperCase())}
            />
          </form>
        </Grid>
      </Grid>

      <h6 className="mb-3">
        Demand operations which are compatible with the current configuration
      </h6>

      <DndProvider backend={Backend}>
        <Grid container spacing={5}>
          <Grid item md={4}>
            <h6
              style={{
                textAlign: "center",
                backgroundColor: theme.palette.primary.btnSecondary,
                padding: "5px",
              }}
            >
              Possible jobs to allocate with switching
            </h6>
            <div style={{ maxHeight: 300, overflow: "auto" }}>
              {config_unmatch_demand_operations[0] ? (
                config_unmatch_demand_operations
                  .filter((dO) => searchtxt(dO))
                  .map((dO) => (
                    <DemandOperationItem
                      key={dO.id}
                      bucket={bucket}
                      dO={dO}
                      selectedDemandOperation={selectedDemandOperation}
                      setSelectedDemandOperation={setSelectedDemandOperation}
                    />
                  ))
              ) : (
                <Typography>No matching orders</Typography>
              )}
            </div>
          </Grid>
          <Grid item md={4}>
            <h6
              style={{
                textAlign: "center",
                backgroundColor: theme.palette.primary.btnSecondary,
                padding: "5px",
              }}
            >
              Possible jobs to allocate without switching
            </h6>
            <div style={{ maxHeight: 300, overflow: "auto" }}>
              {config_match_demand_operations[0] ? (
                config_match_demand_operations
                  .filter((dO) => searchtxt(dO))
                  .map((dO) => (
                    <DemandOperationItem
                      key={dO.id}
                      bucket={bucket}
                      dO={dO}
                      selectedDemandOperation={selectedDemandOperation}
                      setSelectedDemandOperation={setSelectedDemandOperation}
                    />
                  ))
              ) : (
                <Typography>No matching orders</Typography>
              )}
            </div>
          </Grid>
          <Grid item md={4}>
            <h6
              style={{
                textAlign: "center",
                backgroundColor: theme.palette.primary.btnSecondary,
                padding: "5px",
              }}
            >
              Bucket current allocation
            </h6>
            <p>
              {format_to_date_time(bucket.start_time)} -{" "}
              {format_to_time(bucket.end_time)}
            </p>
            <div style={{ maxHeight: 300, overflow: "auto" }}>
              <DropLocation
                onDrop={(id) => handleDrop(id, bucket.start_time)}
              />
              {allocations.map((a) => (
                <AllocationItem
                  key={a.id}
                  onDelete={onDelete}
                  allocation={a}
                  onDrop={(id) => handleDrop(id, a.end_time)}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </DndProvider>
      <br />
      <Button
        variant="contained"
        className="btn_ btn_primary float-right mb-2"
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  );
}

const DemandOperationItem = ({
  dO,
  selectedDemandOperation,
  setSelectedDemandOperation,
  bucket,
}) => {
  const [collectedProps, drag] = useDrag({
    item: { id: dO.id, type: "demand_operations" },
  });
  const isLate = dO.required_time_max < bucket.start_time;
  return (
    <div ref={drag} onClick={() => setSelectedDemandOperation(dO)}>
      <Grid container>
        <Grid item sm={1}>
          <DragIndicator></DragIndicator>
        </Grid>
        <Grid item sm={9}>
          <b>{`${dO.item_code} of ${dO.order_code}`} </b> <br />
          <span>
            {dO.configuration}
            <span>
              RM Ready:{format_to_date(dO.rm_ready_date)} <br />
              <span style={{ color: isLate ? "red" : undefined }}>
                Plan From: {format_to_date(dO.required_time_min)} to{" "}
                {format_to_date(dO.required_time_max)}
              </span>
            </span>
          </span>
        </Grid>
        <Grid item sm={2}>
          <Chip
            color="primary"
            style={{ backgroundColor: dO.color, color: "black" }}
            label={dO.quantity - dO.planned_quantity}
          />
        </Grid>
      </Grid>
      <hr />
    </div>
  );
};

const AllocationItem = ({ allocation, onDrop, onDelete }) => {
  const isSwitch = !allocation.demand_operation;
  return (
    <div style={{ padding: 3 }}>
      <Grid container>
        <Grid item sm={6}>
          <b>{`${
            isSwitch ? "Switch to " : allocation.demand_operation.order_code
          } ${isSwitch ? allocation.configuration : ""}`}</b>
        </Grid>
        <Grid item sm={1}>
          {!isSwitch && (
            <Chip
              color="primary"
              style={{ backgroundColor: allocation.color, color: "black" }}
              label={allocation.quantity}
            />
          )}
        </Grid>
        <Grid item sm={3}>
          <IconButton
            onClick={() => onDelete(allocation.id)}
            edge="end"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <br />
        <span>
          {`${format_to_time(allocation.start_time)} ${
            allocation.configuration
          }`}
        </span>
      </Grid>
      <DropLocation onDrop={onDrop} />
      <hr />
    </div>
  );
};

const DropLocation = ({ onDrop }) => {
  const [collectedProps, drop] = useDrop({
    accept: "demand_operations",
    drop: (item) => onDrop(item.id),
    collect(monitor) {
      return {
        highlighted: monitor.canDrop(),
        hovered: monitor.isOver(),
      };
    },
  });
  return (
    <div ref={drop} style={{ minHeight: 2 }}>
      {collectedProps.highlighted ? (
        <div
          style={{
            minHeight: 30,
            backgroundColor: collectedProps.hovered ? "green" : "yellow",
          }}
        ></div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { bucket_id } = props;
  const bucket = getEntity(state, "buckets", bucket_id);
  const lastAlloc = _.maxBy(
    getAllocations(state).filter(
      (a) =>
        a.plan_board_line_id == bucket.line_id && a.start_time < bucket.end_time
    ),
    "start_time"
  );
  const configuration = lastAlloc
    ? lastAlloc.configuration
    : getEntity(state, "lines", bucket.line_id).initial_configuration;

  const line = getEntity(state, "lines", bucket.line_id);
  const colorMappings = getColorMappings(state);
  const demand_operations = getDemandOperations(state)
    .filter((dO) => {
      const min_time = dO.rm_ready_date || dO.required_time_min;
      return (
        min_time <= bucket.end_time &&
        !dO.is_exchange &&
        // && dO.required_time_max >= bucket.start_time
        dO.planned_quantity < dO.quantity &&
        !dO.order_is_closed
      );
    })
    .map((d) => {
      const color = getColorFromConfiguration(colorMappings, d.configuration);
      return { ...d, color };
    });

  const config_match_demand_operations = _.sortBy(
    demand_operations.filter((dO) => dO.configuration === configuration),
    ["required_time_max", "rm_ready_date"]
  ); // demand_operations.
  const config_unmatch_demand_operations = _.sortBy(
    demand_operations.filter((dO) => dO.configuration !== configuration),
    ["required_time_max", "rm_ready_date"]
  ); //data[1];

  const allocations = getAllocations(state);
  const selectedAllocations = allocations
    .filter((a) => a.line_bucket_id === bucket.id)
    .map((a) => {
      const dO = getEntity(state, "demand_operations", a.demand_operation_id);
      const color = getColorFromConfiguration(colorMappings, a.configuration);
      return { ...a, demand_operation: dO, color };
    })
    .sort((a, b) => a.start_time - b.start_time);

  return {
    bucket,
    line,
    configuration,
    config_match_demand_operations,
    config_unmatch_demand_operations,
    allocations: selectedAllocations,
  };
};
const mapDispatchToProps = {
  onAllocate: requestCreateAllocation,
  onDelete: requestDeleteAllocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAllocation);
