import _ from "lodash"
import { AllocationIdType, IAllocationPart, IPlan, IAllocation, ILinePart } from "../../../../lib/plan"
import { all_allocations_time_slot_forwart_selector, line_all_allocation_selector } from "../../../../lib/plan/allocation_selectors/selectors"
import { allocate_to_line } from "../../../../lib/plan/allocators/line_allocators"
import { add_allocation_to_plan, convert_allocation_parts_to_demand_operation_parts, delete_allocations_from_plan, get_calculated_fields_updated, get_next_allocation, select_first_n_by_quantity } from "../../../../lib/plan/helpers"
import { allocationSelectorMap } from "./shared"

export function editAllocationDuraitionTransformer(plan: IPlan, action: any): IPlan {
    const { allocation_id, duration } = action.payload

    const allocation = plan.allocations[allocation_id]
    const remainingStartAllocationId = get_next_allocation(plan, allocation_id)
    const remainingAllocations = all_allocations_time_slot_forwart_selector(plan, remainingStartAllocationId as AllocationIdType)

    const deletedAllocationIds = new Set<AllocationIdType>()
    remainingAllocations.forEach(a => deletedAllocationIds.add(a.allocation_id))

    const newEndTime = new Date(allocation.start_time.valueOf() + duration)

    let part_from = newEndTime;
    let part_to = part_from;
    let is_previous_alloc_freezed = false;
    const max_date = new Date(8640000000000000)
    const freezed_allocations: IAllocation[] = []

    const toLine_lineParts = remainingAllocations.reduce((parts: ILinePart[], allocation: IAllocationPart, index: number) => {
        if (allocation.is_freezed) {
            part_to = plan.allocations[allocation.allocation_id].start_time;
            if (!is_previous_alloc_freezed) {
                parts.push({
                    start_time: part_from,
                    end_time: part_to,
                    line_id: plan.allocations[allocation.allocation_id].plan_board_line_id,
                    end_config: plan.allocations[allocation.allocation_id].configuration
                } as ILinePart)
            }
            part_from = plan.allocations[allocation.allocation_id].end_time;
            freezed_allocations.push(plan.allocations[allocation.allocation_id])
        }
        is_previous_alloc_freezed = allocation.is_freezed
        if (index == remainingAllocations.length-1) {
            parts.push({
                start_time: part_from,
                end_time: max_date,
                line_id: plan.allocations[allocation.allocation_id].plan_board_line_id,
                end_config: ""
            } as ILinePart)
        }
        return parts;
    },[])

    const newAllocations = allocate_to_line(plan,
        convert_allocation_parts_to_demand_operation_parts(plan, remainingAllocations), toLine_lineParts,
        allocation.plan_board_line_id, newEndTime, allocation.configuration)
    newAllocations.push({...allocation, end_time: newEndTime })





    // const isInFreezeRange = freezed_time >= allocation.start_time

    // const selector = allocationSelectorMap[mode as (keyof typeof allocationSelectorMap)]
    // const allocationParts = selector(plan, allocation_id) as IAllocationPart[]

    // const allQuantity = _.sum(allocationParts.map(a => a.quantity))

    // const hasDifference = allQuantity != quantity && quantity
    // const selectedParts = hasDifference ? select_first_n_by_quantity(allocationParts, "quantity", quantity) : allocationParts
    // const remaingParts = hasDifference ? select_first_n_by_quantity(allocationParts.slice().reverse(), "quantity", allQuantity - quantity) : []


    // const lastAllocationId = allocationParts[allocationParts.length - 1].allocation_id
    // const fromLineRestStartAllocationId = get_next_allocation(plan, lastAllocationId)
    // const hasOtherAllocationsToArrange = fromLineRestStartAllocationId && !isInFreezeRange

    // const fromLineRest = hasOtherAllocationsToArrange ?
    //     all_allocations_time_slot_forwart_selector(plan, fromLineRestStartAllocationId as AllocationIdType)
    //     : []
    // // Move allocations
    // allocationParts.forEach(a => deletedAllocationIds.add(a.allocation_id))
    // fromLineRest.forEach(a => deletedAllocationIds.add(a.allocation_id))
    // const previousAllocationId = _.maxBy(line_all_allocation_selector(plan, allocation.plan_board_line_id)
    //     .filter(a => plan.allocations[a.allocation_id].start_time < allocation.start_time),
    //     a => plan.allocations[a.allocation_id].start_time)?.allocation_id as AllocationIdType

    // const initailConfig = plan.allocations[previousAllocationId]?.configuration || ""
    // const writeTime = plan.allocations[previousAllocationId]?.end_time || allocation.start_time

    // const allocationPartsToReallocate = [...remaingParts, ...fromLineRest]


    const addNewAllocations = (plan: IPlan) => add_allocation_to_plan(plan, newAllocations)
    const deleteOldAllocatiosn = (plan: IPlan) => delete_allocations_from_plan(plan, deletedAllocationIds)
    const addFreezedAllocations = (plan: IPlan) => add_allocation_to_plan(plan, freezed_allocations)

    return _.flow([addNewAllocations, deleteOldAllocatiosn, addFreezedAllocations, get_calculated_fields_updated])(plan)
}