import React from 'react'
import styled from 'styled-components'
import { useTable, useExpanded } from 'react-table'
import { makeStyles } from '@material-ui/core/styles';

import { useEffect } from 'react'
import Select from "@material-ui/core/Select";
import FormControl from '@material-ui/core/FormControl';
import DatePicker from "react-datepicker";
import Button from "@material-ui/core/Button";
import InputLabel from '@material-ui/core/InputLabel';
import { showToastSuccess, showToastError } from "../../lib/toas"
import { useDataProvider, useMutation, useRefresh, Loading, useQuery } from "react-admin";
import CapLoadingTable from './collapsibleCapacityTable';
import SwipeableTemporaryDrawer from "./sidePane";
import {ExportModal} from './exportModal';


const useStyles = makeStyles((theme) => ({
    meta: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '500px'
    },
    comp: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '35%'
    },
    tableInput: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '1500px'
    },
    selectInput: {
        cursor: 'pointer'
    },
    actions:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: '300px'
    }
}));
function CapacityLoadingTable() {

    const classes = useStyles();
    const [tableData, setTableData] = React.useState();
    const [selectedPlant, setSelectedPlant] = React.useState();
    const [focusedDate, setFocusedDate] = React.useState(new Date());
    const [isLoading, setIsLoading] = React.useState(false);
    const [plants, setPlants] = React.useState([{ "id": "ALL", "code": "All" }]);
    const [mutate, { mutateLoading, onSuccess, onError }] = useMutation();

    useEffect(() => {
        function initiateLoading() {
            setIsLoading(true)
        }

        initiateLoading()
    }, [])

    const handleChange = (event) => {
        const plantId = event.target.value
        setSelectedPlant(plantId)
    }

    const { plantLoading, plantError } = useQuery({
        type: "getList",
        resource: "plants",
        payload: {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: "id", order: "ASC" },
        },
    }, {
        onFailure: (e) => {
            setIsLoading(false)
            showToastError("Task Retrieval Failed:" + e.body);
        },
        onSuccess: (response) => {
            console.log(response.data)
            setIsLoading(false)
            setPlants(plants.concat(response.data))
        }
    });

    const getStartDate = (date) => {
        const startDate = new Date(date)
        startDate.setDate(date.getDate() - 3)
        return startDate.toISOString().slice(0, 10);
    }
    const getEndDate = (date) => {
        const endDate = new Date(date)
        endDate.setDate(date.getDate() + 4)
        return endDate.toISOString().slice(0, 10);
    }


    const { data, dataLoading, dataError } = useQuery(
        {
            type: "CUSTOM_LIST",
            resource: "allocations",
            payload: {
                action: "getCapacityLoadingDataForTable",
                method: "POST",
                body: {
                    plants: "ALL",
                    startDate: getStartDate(new Date()),
                    endDate: getEndDate(new Date())
                },
            },
        }, {
        onFailure: (e) => {
            showToastError("Task Retrieval Failed:" + e.body);
        },
        onSuccess: (response) => {
            setTableData(response.data)
            console.log(response.data)
        }
    });

    const isValidInputs = () => {
        if (!(selectedPlant)) {
            showToastError("Please select a Plant")
            return false;
        }
        if (!(focusedDate)) {
            showToastError("Please select a Date")
            return false;
        }
        return true
    }

    const viewTable = () => {
        setIsLoading(true)
        if (!(isValidInputs())) {
            setIsLoading(false)
            return;
        }

        mutate({
            type: 'CUSTOM_LIST',
            resource: 'allocations',
            payload: {
                action: "getCapacityLoadingDataForTable",
                method: "POST",
                body: {
                    plants: selectedPlant,
                    startDate: getStartDate(focusedDate),
                    endDate: getEndDate(focusedDate)
                },
            }
        }, {
            onFailure: (e) => {
                setIsLoading(false)
                showToastError("Capacity Loading Data Retrieval Failed:" + e.body);
            },
            onSuccess: (e) => {
                setIsLoading(false)
                setTableData(e.data)
                console.log(e.data)
            }
        });
    }

    if (!(tableData)) {
        return <Loading />
    }

    return (
        <div>
            <div className={classes.tableInput}>
                <div className={classes.meta}>

                    <Select native className={classes.selectInput}
                        onChange={handleChange}
                        value={selectedPlant}

                    >
                        <option value="">Select a Plant</option>
                        {plants.map(({ id, code }) => (
                            <option value={id}>{code}</option>
                        ))}
                    </Select>


                    <DatePicker
                        dateFormat="MMMM yyyy dd"
                        isClearable

                        placeholderText="Choose a Date"
                        selected={focusedDate} onChange={(date) => setFocusedDate(date)}
                    />
                    <Button className="btn_ btn_primary" onClick={viewTable}>Go</Button>
                </div>
                <div className={classes.actions}>
                    <ExportModal plants={plants}/>
                    <SwipeableTemporaryDrawer />
                </div>

            </div>

            {tableData ?
                !isLoading ?
                    <CapLoadingTable data={tableData} />
                    : <Loading /> : null}

        </div>
    )

}

export default CapacityLoadingTable