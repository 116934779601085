import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import IconCancel from "@material-ui/icons/Cancel";
import ActionDelete from "@material-ui/icons/Delete";
import classnames from "classnames";
import PropTypes from "prop-types";
import { crudDelete, startUndoable, translate } from "ra-core";
import React, { Component, Fragment } from "react";
import { Button } from "react-admin";
import { connect } from "react-redux";

import compose from "recompose/compose";

const styles = (theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
      close:{
        cursor: 'pointer'
      }
    },
  },
});

class DeleteButtonWithConfirmation extends Component {
  state = {
    showDialog: false,
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ showDialog: false });
    const {
      dispatchCrudDelete,
      startUndoable,
      resource,
      record,
      basePath,
      redirect,
      undoable,
    } = this.props;
    dispatchCrudDelete(resource, record.id, record, basePath, redirect);
  };

  render() {
    const { showDialog } = this.state;
    const {
      label = "ra.action.delete",
      classes = {},
      className,
      nameField,
      disabled,
      title,
    } = this.props;
    return (
      <Fragment>
        <Button
          title={title}
          disabled={disabled}
          onClick={this.handleClick}
          label={label}
          className="btn_ btn_delete mb-0"
          key="button"
        >
          <ActionDelete />
        </Button>
        <Dialog
          fullWidth="true"
          maxWidth="sm"
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Are you sure?"
        >
          <Grid container>
            <Grid item xs={12} sm={10}>
              <DialogTitle style={{ paddingTop: "10px" }}>
                Are you sure you want to delete this
              </DialogTitle>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 10,
                  marginRight: 10,
                   cursor: 'pointer'
                }}
              >
                <CloseIcon onClick={this.handleCloseClick} />
              </div>
            </Grid>
          </Grid>
         
          <DialogContent style={{ paddingTop: 0 }}>
            <div style={{ paddingTop: 0 }}>
              {`You will not able to recover this ${
                this.props.text ? this.props.text : "entity"
              }`}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDelete}
              label={label}
              className="btn_ btn_delete"
              key="button"
            >
              <ActionDelete />
            </Button>
            
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DeleteButtonWithConfirmation.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dispatchCrudDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
};

DeleteButtonWithConfirmation.defaultProps = {
  redirect: "list",
  undoable: true,
};

export default compose(
  connect(null, { startUndoable, dispatchCrudDelete: crudDelete }),
  translate,
  withStyles(styles)
)(DeleteButtonWithConfirmation);
