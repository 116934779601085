import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { SaveButton, Toolbar, usePermissions } from "react-admin";
import Confirmation from "./Confirmation";

export const ToolTipForDeleteButton = (props) => {
  const useStyles = makeStyles({
    toolbar: {
      display: "flex",
      justifyContent: "flex-end",
      alignContent: "center",
    },
  });

  return (
    <Toolbar classes={useStyles()} {...props}>
      <div className="m-2">
        <Confirmation title={props.name} />{" "}
        <SaveButton {...props} className="btn_ btn_edit mb-0" />
      </div>
    </Toolbar>
  );
};
