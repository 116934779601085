import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useState } from "react";
import { Loading, Mutation } from "react-admin";
import CSVReader from "react-csv-reader";
import { showToastSuccess } from "../../lib/toas";
import { makeStyles } from "@material-ui/core/styles";
import { usePermissions } from "react-admin";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "inline-flex",
  //   marginTop: theme.spacing(4),
  // },
  root: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
  },
}));

const UploadOperationCsvButton = ({
  title,
  description,
  onSuccess,
  onFailed,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(false);
  const classes = useStyles();

  const { permissions } = usePermissions();

  const onFileLoad = (csvdata) => {
    setData(csvdata);
  };

  const options = {
    undoable: false,
    onSuccess: () => {
      setIsOpen(false);
      onSuccess && onSuccess();
      showToastSuccess("Item groups uploaded");
    },
    onFailure: {
      notification: { body: "Error: not uploaded", level: "warning" },
    },
  };

  return (
    <div className={classes.root}>
      <Button
        className="btn_ btn_primary mt-0"
        title="Click here to upload Operation group"
        disabled={!permissions?.permissions.includes("operations_upload")}
        variant="contained"
        // color="primary"
        onClick={() => setIsOpen(true)}
      >
        Upload Operation Group
      </Button>
      <Dialog
        fullWidth="true"
        maxWidth="sm"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ alignSelf: "center" }}>
              {" "}
              Upload Operation Groups{" "}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
                cursor: 'pointer'
              }}
            >
              <CloseIcon onClick={() => setIsOpen(false)} />
            </div>
          </Grid>
        </Grid>
        <DialogContent>
          <div>{description}</div>
          {/* <br />
          <br /> */}
          <CSVReader onFileLoaded={onFileLoad} />
          {data && (
            <div>
              <br />
              Records {data.length - 1}
            </div>
          )}{" "}
          <br />
          {data && (
            <Mutation
              type="CUSTOM"
              resource="operations"
              payload={{ action: "upload_groups", method: "POST", body: data }}
              options={options}
            >
              {(action, { loading }) => (
                <div>
                  {loading && <Loading />}
                  <DialogActions>
                    <Button
                      className="btn_ btn_primary"
                      disabled={loading}
                      variant="contained"
                      // color="primary"
                      onClick={() => {
                        action();
                      }}
                    >
                      {" "}
                      Save
                    </Button>
                    {/* <Button
                      className="btn_ btn_primary"
                      variant="contained"
                      color="primary"
                      onClick={() => setIsOpen(false)}
                    >
                      Close
                    </Button> */}
                  </DialogActions>
                </div>
              )}
            </Mutation>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadOperationCsvButton;
