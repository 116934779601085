import React, { useState } from "react";
import {
  Create,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
  required
} from "react-admin";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { redirect } from "../../../lib/redirect";

export const DepartmentsCreate = (props) => {

  const Title = () => {
    return <span>Create TNA Department</span>;
  };

  const customToast = useCustomToast({redirect: redirect});

  return (
    <Create 
      className="col-md-6" 
      {...props} 
      title={<Title/>}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm
        toolbar={<CustomToolbar need={false} name="tna_departments" text="Departments" />}
      >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="code" validate={[required()]} />
        <ReferenceInput source="admin_user" reference="users">
          <SelectInput optionText="username" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
