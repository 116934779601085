import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Loading, useDataProvider } from "react-admin";
import DialogBox from "./DialogBox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ExportHelper = ({
  startDate,
  endDate,
  handleClose,
  handleSelectedGroup,
}) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [checked, setChecked] = useState([0]);
  const [groups, setGroups] = useState([]);
  const [lines, setLines] = useState([]);
  const [groupId, setGroupId] = useState();
  const [dataArray, setDataArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState([]); // this hold currunt group line ids

  const getPlanBoardId = () => {
    const urlArray = window.location.href.split("/");
    return urlArray[urlArray.length - 1];
  };

  // api calls

  const loardData = async () => {
    const id = getPlanBoardId();
    const payload = {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
      filter: { plan_board__id: id },
    };

    const data = await dataProvider.getList("boardGroup", payload);
    const allLines = await dataProvider.getList("boardLine", payload);
    const groupData = data.data.filter((res) => res.plan_board == id);
    const lineData = allLines.data.filter((res) => res.plan_board == id);

    try {
      if (groupData.length > 0) {
        setGroups(groupData);
      }
      if (lineData.length > 0) {
        setLines(lineData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loardData();
  }, []);

  useEffect(() => {
    handleSelectedGroup = { selectedGroup, checked };
  }, [selectedGroup, checked]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const manageGroups = (id) => {
    try {
      if (groups.length > 0 && lines.length > 0) {
        return lines.filter(({ plan_board_group }) => plan_board_group == id);
      }
    } catch (error) {
      return false;
    }
  };
  //handle group drop down
  const handleChange = (event) => {
    const id = event.target.value;
    const sId = groups[id].id; // select group id
    setGroupId(sId);
    const dataSource = manageGroups(sId);
    setDataArray(dataSource);
    setSelectedGroup(dataSource);
  };

  const handleSave = () => {
    setOpen(true);
  };
  const handelOpen = (value) => {
    if (value !== undefined) {
      setOpen(false);
    } else {
      setOpen(true);
    }

    return open;
  };

  return (
    <>
      <div>
        {groups.length === 0 && lines.length === 0 ? (
          <Loading />
        ) : (
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ alignItems: "flexStart", marginRight: "400px" }}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-native-simple">Groups</InputLabel>
                  <Select
                    native
                    //  value={setSelectId}
                    onChange={handleChange}
                    inputProps={{
                      name: "Groups",
                      id: "age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {groups.map(({ code }, i) => (
                      <option value={i}>{code}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div>
              <DialogContent>
                <List className={classes.root}>
                  {dataArray.map(({ code, id }) => {
                    const labelId = `checkbox-list-label-${code}`;

                    return (
                      <ListItem
                        key={code}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggle(id)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`Line item ${code}`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </DialogContent>
            </div>
          </div>
        )}
        {open && (
          <DialogBox
            handelOpen={handelOpen}
            selectedLines={checked}
            startDate={startDate}
            endDate={endDate}
            groupId={groupId}
            selectedGroup={selectedGroup}
            handleClose={handleClose}
          />
        )}
      </div>
      <div style={{ marginTop: "50px" }}>
        <DialogActions>
          <Button
            className="btn_ btn_primary"
            onClick={handleSave}
            variant="contained"
            color="primary"
            autoFocus
          >
            Export
          </Button>
          {/* <Button
            className="btn_ btn_secondry"
            onClick={handleClose}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Close
          </Button> */}
        </DialogActions>
      </div>
    </>
  );
};
