import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SingleFieldList,
  ChipField,
  SelectArrayInput,
  FormDataConsumer,
  AutocompleteInput
} from "react-admin";
import { SaveButton, Toolbar, usePermissions } from "react-admin";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { redirect } from "../../../lib/redirect";

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const TemplateItemsEdit = (props) => {
  const { permissions } = usePermissions();
  const accessLevel = permissions?.access_level;

  const disabled = accessLevel === "ADMIN" ? false : true;

  const Title = ({ record }) => {
    return (
      <span>TNA Template Item | {record ? `${record.id ?? ""} ` : ""}</span>
    );
  };

  const customToast = useCustomToast({...props, redirect: redirect});

  return (
    <Edit 
      onSuccess = {customToast.onSuccess}
      onFaliure = {customToast.onFaliure}
      undoable = {false}
      {...props} title={<Title/>}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <ReferenceInput
          link=""
          label="Task"
          reference="tna_tasks"
          source="task"
        >
          <SelectInput disabled={disabled} optionText="title" />
        </ReferenceInput>
        {/* <NumberField source="task" /> */}
        {/* <NumberField source="assigned_department" /> */}
        <ReferenceInput
          link=""
          label="Department"
          reference="tna_departments"
          source="assigned_department"
        >
          <SelectInput disabled={disabled} optionText="code" />
        </ReferenceInput>
        <NumberInput disabled={disabled} source="sequence_no" />
        <FormDataConsumer>
          {({ formData }) => {
            // console.log({formData})
            return (
              <ReferenceInput
                link=""
                label="Assigned user"
                reference="users"
                source="assigned_user"
                filterToQuery={() => ({ level: "mng" })}
                perPage={Number.MAX_SAFE_INTEGER}
                allowEmpty={true}
                filter={{ tna_department: formData.assigned_department }}
              >
                <AutocompleteInput optionText="username" />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>

        <NumberInput source="start_offset_days" />
        <NumberInput source="duration" disabled={disabled} />
        <FormDataConsumer>
          {({ formData }) => {
            // console.log({formData})
            return (
              <ReferenceArrayInput
                label="Dependent items"
                reference="tna_templateItems"
                source="dependent_item"
                filter={{ template: formData.template, not_id: props.id }}
              >
                <SelectArrayInput
                  translateChoice={false}
                  optionText="sequence_no"
                />
              </ReferenceArrayInput>
            );
          }}
        </FormDataConsumer>

        <ReferenceInput
          link=""
          label="Transferred user"
          reference="users"
          source="transferred_to"
          allowEmpty={true}
        >
          <SelectInput optionText="username" />
        </ReferenceInput>
        <BooleanInput source="need_approval" disabled={disabled} />
      </SimpleForm>
    </Edit>
  );
};
