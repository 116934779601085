// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import { makeStyles } from "@material-ui/core/styles";
// import DeviceHubIcon from "@material-ui/icons/DeviceHub";
// import React from "react";
// import { Link } from "react-admin";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

// export const MainView = () => {
//   const classes = useStyles();

//   return (
//     <List component="nav" className={classes.root} aria-label="contacts">
//       <ListItem
//         button
//         className="btn_ btn_primary"
//         component={Link}
//         to={{
//           pathname: "/TimeStampTransfers",
//         }}
//       >
//         <ListItemIcon>
//           <DeviceHubIcon />
//         </ListItemIcon>
//         <ListItemText primary="Transfer" />
//       </ListItem>
//     </List>
//   );
// };

import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link, usePermissions } from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const MainView = () => {
  const { permissions } = usePermissions();

  return (
    <div style={{ width: "30%" }}>
      {/* <h3>Settings </h3> */}

      <Card>
        <CardContent>
          <Typography
            style={{ fontSize: 14 }}
            color="textSecondary"
            gutterBottom
          >
            Time stamps
          </Typography>
        </CardContent>

        <div style={{ display: "flex" }}>
          <div style={{ justifyContent: "space-evenly" }}>
            <Button
              title="Click here to add or view reasons"
              disabled={!permissions?.permissions.includes("changeReasons")}
              // size="small"
              // color="primary"
              variant="contained"
              // className="btn_ btn_primary"
              className="btn_ btn_primary m-2"
              component={Link}
              to={{
                pathname: "/TimeStampTransfers",
              }}
              // style={{ marginRight: "%50" }}
            >
              Transfer
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
