import React from "react";
import Toolbar from "react-big-calendar/lib/Toolbar";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

export default class CalendarToolbar extends Toolbar {
  componentDidMount() {
    const view = this.props.view;
    console.log(view);
  }

  render() {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ButtonGroup className="btn-grp " size="large">
          <Button className="btn_primary" onClick={() => this.navigate("PREV")}>
            Back
          </Button>
          <Button
            className="btn_primary"
            onClick={() => this.navigate("TODAY")}
          >
            Today
          </Button>
          <Button className="btn_primary" onClick={() => this.navigate("NEXT")}>
            Next
          </Button>
        </ButtonGroup>

        <label>{this.props.label}</label>

        <ButtonGroup className="btn-grp" size="large">
          <Button
            className="btn_primary"
            onClick={this.view.bind(null, "month")}
          >
            Month
          </Button>
          <Button
            className="btn_primary"
            onClick={this.view.bind(null, "week")}
          >
            Week
          </Button>
          <Button className="btn_primary" onClick={this.view.bind(null, "day")}>
            Day
          </Button>
          <Button
            className="btn_primary"
            onClick={this.view.bind(null, "agenda")}
          >
            Agenda
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}
