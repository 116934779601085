import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import {
  SaveButton,
  DeleteButton,
  Toolbar,
  usePermissions,
  SelectArrayInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../components/Confirmation";
import { PLAN_EXPORT_COLUMNS as plan_export_columns } from "../../constants";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";

export const PlantEdit = (props) => {
  const { permissions } = usePermissions();

  const validatePlant = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.code) {
      errors.code = "Code is required";
    }

    return errors;
  };

  const Title = ({ record }) => {
    return <span>Edit Plant | {record ? `${record.code} ` : ""}</span>;
  };
  const customToast = useCustomToast(props);

  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <p className="mt-5 ml-3">Edit Plant and Plan export fields</p>
        <Edit
          {...props}
          title={<Title />}
          class={"col-md-6"}
          onSuccess={customToast.onSuccess}
          onFaliure={customToast.onFaliure}
          undoable={false}
        >
          <SimpleForm
            validate={validatePlant}
            toolbar={<CustomToolbar name="plants" text="Plant" />}
          >
            <TextInput disabled source="id" />
            <TextInput
              source="code"
              label={<ReqInputField name="Code" />}
              title="This field is mandatory"
            />
            <TextInput
              source="name"
              label={<ReqInputField name="Name" />}
              title="This field is mandatory"
            />
            {permissions?.permissions.includes("edit_planExportColumns") && (
              <SelectArrayInput
                translateChoice={false}
                choices={[
                  ...plan_export_columns.SCOM.map((a) => ({
                    id: a,
                    name: `SCOM: ${a}`,
                  })),
                  ...plan_export_columns.DSI.map((a) => ({
                    id: a,
                    name: `DSI: ${a}`,
                  })),
                  ...plan_export_columns.SCOM_DATEWISE.map((a) => ({
                    id: a,
                    name: `SCOM-DATEWISE: ${a}`,
                  })),
                ]}
                source="plan_export_columns"
                label="Plan export fields"
              />
            )}
          </SimpleForm>
        </Edit>
      </div>
    </>
  );
};
