import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Edit,
    SimpleShowLayout,
    ReferenceInput,
    ReferenceManyField,
    SelectInput,
    SimpleForm,
    Show,
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    ReferenceField,
    ChipField,
    Filter,
    ArrayField,
    NumberField,
    Pagination,
    Datagrid,
    ListToolbar,
    ListBase,
    TextInput,
} from "react-admin";

import { SaveButton, DeleteButton, Toolbar, usePermissions } from "react-admin";
import Confirmation from "../../../components/Confirmation";
import { CustomToolbar } from "../../../components/CustomToolbar";

//const redirect = (basePath, id, data) => `/planBoards/${data.plan_board}/show/tool`

const useStyles = makeStyles((theme) => ({
    numbersCol: { textAlign: 'center' },
}));
export const DepartmentsShow = (props) => {
    const classes = useStyles();

    const Title = ({ record }) => {
        return <span>Show Department | {record ? `${record.code} ` : ""}</span>;
    };

    const UserFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search by Username" source="username" alwaysOn />
            <SelectInput source="access_level" choices={[
                { id: 'TNA_MANAGER', name: 'TNA MANAGER' },
                { id: 'TNA_PLANNER', name: 'TNA PLANNER' },
                { id: 'TNA_USER', name: 'TNA USER' }
            ]} alwaysOn />
        </Filter>
    );
    return (
        <Show {...props} title={<Title />}>
            <SimpleShowLayout>
                <TextField source="name" className="col-xl-4 col-sm-12 sub_con_lab mb-3"/>
                <TextField source="code" className="col-xl-4 col-sm-12 sub_con_lab mb-3" />
                <ReferenceField source="admin_user" reference="users" className="col-xl-4 col-sm-12 sub_con_lab mb-3" allowEmpty >
                    <TextField source="username" />
                </ReferenceField>
                <ReferenceManyField
                    label="Users"
                    reference="users"
                    // pagination={<Pagination perPage={25} />}
                    sort={{ field: 'app_user__role__access_level', order: 'ASC' }}
                    filter={{ is_tna_user: true, tna_department: props.id }}
                >
                    <ListBase {...props}>
                        <ListToolbar
                            filters={<UserFilter />}
                        />
                        <Datagrid className={classes.numbersCol}>
                            <TextField source="sequence_no" sortable={false} />
                            <TextField source="username" />
                            <TextField source="app_user.access_level" sortBy="app_user__role__access_level" label="Access Level" />
                            <ArrayField source="assigned_tna_template_items" sortable={false}  label="Assigned Tasks Codes">
                                <SingleFieldList linkType={false}>
                                    <ChipField source="code" clickable={false} />
                                </SingleFieldList>
                            </ArrayField>

                            <NumberField source="assigned_task_count" sortable={false} />
                            <NumberField source="completed_task_count" sortable={false} />

                        </Datagrid>
                        <Pagination perPage={25} />
                    </ListBase>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    );
};
