import { IAllocation, IDemandOperation, ILine, ITool } from "../lib/plan/interfaces";

export type Line = ILine


export class Allocation implements IAllocation {
    id!: number | string;
    demand_operation_id!: number;
    plan_board_line_id!: number;
    line_bucket_id!: number;
    configuration!: string;
    quantity!: number;
    completed_quantity!: number;
    start_time!: Date;
    end_time!: Date;
    total_smv!: number;
    is_fixed!: boolean;
    is_freezed!: boolean;
    type!: string;
    // Local fields
    deleted!: boolean; // This is a local field used to keep track of deleted allocations
    is_tools_availabile!: boolean;
    // is_manual: boolean;
    // plan_board_version_id: number

    constructor(init: Partial<Allocation>) {
        Object.assign(this, init)
        this.start_time = new Date(this.start_time)
        this.end_time = new Date(this.end_time)
        this.line_bucket_id = parseInt(String(init.line_bucket_id))
        this.plan_board_line_id = parseInt(String(init.plan_board_line_id))
        this.is_tools_availabile = true
    }

    getTool() {
        return this.configuration.split(":").slice(1).join(":")
    }
}

export class DemandOperation implements IDemandOperation {
    id!: number;
    produced_demand_id!: number;
    item_operation_id!: number;
    item_code!: string;
    planned_start_date!: Date;
    planned_end_date!: Date;
    is_planned!: boolean;
    order_group_code!: string;
    order_code!: string;
    order_is_closed!: boolean;
    operation_code!: string;
    rm_ready_date?: Date;
    duration!: number;
    quantity!: number;
    planned_quantity!: number;
    completed_quantity!: number;
    workstation_id!: number;
    output_lead_time_min!: number;
    output_lead_time_max!: number;
    required_time_min!: Date;
    required_time_max!: Date;
    configuration!: string;
    input_lead_time!: number;
    rm_demand_operations!: number[];
    need_demand_operations!: number[];
    attributes!: any;
    is_exchange!: Boolean;
    unplanned_quantity_in_previouse_operations!: number
    order_required_date!: Date;
    order_release_number!: string;
    /**
     * SMV is the time per item
     */
    smv!: number;
    quantity_multiplier!: number;

    constructor(init: DemandOperation) {
        Object.assign(this, init)
        this.required_time_max = new Date(this.required_time_max)
        this.required_time_min = new Date(this.required_time_min)
        this.rm_ready_date = this.rm_ready_date ? (new Date(this.rm_ready_date)) : undefined
        this.planned_start_date = this.planned_start_date && new Date(this.planned_start_date)
        this.planned_end_date = this.planned_end_date && new Date(this.planned_end_date)
        this.order_required_date = this.order_required_date && new Date(this.order_required_date)
    }

}

export interface AllocatableQuantity {
    allocatable_quantity: number;
    end_time: Date;


}

export class LineBucket {
    id!: number;
    line_id!: number;
    start_time!: Date;
    end_time!: Date;
    efficiency!: number;
    total_smv!: number;
    unallocated_smv!: number;
    carder!: number;
    configuration!: string;
    allocation_completed!: boolean;

    constructor(init: LineBucket) {
        Object.assign(this, init)
        this.start_time = new Date(this.start_time)
        this.end_time = new Date(this.end_time)
    }

    get_bucket_capacity(start_time: Date, smv: number) {
        const duration_left = (this.end_time.valueOf() - start_time.valueOf()) / (3600 * 1000)
        const value = this.efficiency * this.carder * duration_left / smv
        const capacity = Math.floor(value / 1) * 1
        return capacity as number
    }

    get_end_time(start_time: Date, quantity: number, smv: number): AllocatableQuantity {
        const quantity_possible = (this.end_time.valueOf() - start_time.valueOf()) / (3600 * 1000) * smv
        const allocatable_quantity = Math.min(quantity_possible, quantity)
        const duration = allocatable_quantity * smv
        const end_time = new Date(start_time.valueOf() + duration)
        return { allocatable_quantity, end_time }
    }

}

export class LineBucketByQuantity extends LineBucket {
    daily_quantity!: number;

    constructor(init: LineBucketByQuantity) {
        super(init)
        Object.assign(this, init)

    }

    get_bucket_capacity(start_time: Date, smv: number) {
        const duration_left = (this.end_time.valueOf() - start_time.valueOf()) / (3600 * 1000)
        const total_duration = (this.end_time.valueOf() - this.start_time.valueOf()) / (3600 * 1000)
        const allocated_quantity = (duration_left / total_duration) * this.daily_quantity
        return (this.daily_quantity - allocated_quantity) as number
    }

    get_end_time(start_time: Date, quantity: number, smv: number): AllocatableQuantity {
        const quantity_possible = (this.end_time.valueOf() - start_time.valueOf()) / (this.end_time.valueOf() - this.start_time.valueOf()) * this.daily_quantity
        const allocatable_quantity = Math.min(quantity_possible, quantity)
        const duration = (allocatable_quantity / this.daily_quantity) * (this.end_time.valueOf() - this.start_time.valueOf()) / (3600 * 1000)
        const end_time = new Date(start_time.valueOf() + duration)
        return { allocatable_quantity, end_time }
    }

}

export class LineGroup {
    id!: number;
    plan_board_id!: number;
    code!: string;
    switching_time!: number;
    calendar_id!: number;

    constructor(init: LineGroup) {
        Object.assign(this, init)
    }
}

export class PlanBoard {
    plant_id!: number;
    id!: number;
    code!: string;
    algorithm!: string;
    algorithm_config!: any;
    calendar_id!: number;
    draft_revision_id!: number;
    published_revision_id!: number;
    export_type!: string

    constructor(init: PlanBoard) {
        Object.assign(this, init)
    }
}

export type Tool = ITool