import "../../layout/styles/View.css";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import ActionDelete from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import React, { useState, useEffect } from "react";
import { Mutation, Query, refreshView, usePermissions } from "react-admin";
import CSVReader from "react-csv-reader";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import dataProvider from "../../api/dataProvider";
import { showToast, showToastSuccess, showToastError } from "../../lib/toas";
import UploadButton from "../../components/BulkActionButton";
import SupportedGroupButton from "../../containers/planBoard/LineSupportedGroups";
import FormControl from '@material-ui/core/FormControl';

const LineActions = (props) => {
  const record = props.record;
  const selectedLineIds = props.props.selectedLineIds;
  const setSelectedLineIds = props.props.setSelectedLineIds;
  const isBulk = !!selectedLineIds;

  const dispatch = useDispatch();

  const { permissions } = usePermissions();

  return (
    <Card class="button-card" style={{ padding: 10 }}>
      {/* <ButtonGroup className="mb-0"> */}

      <Button
        title="Click here to add new lines/head"
        disabled={!permissions?.permissions.includes("linesHeads_create")}
        // color="primary"
        // className="mb-0 mr-0"
        className="btn_ btn_primary"
        variant="contained"
        component={Link}
        to={{
          pathname: `/boardLine/create`,
          state: { record: { plan_board: record.id } },
        }}
      >
        Add new line/head
      </Button>
      {/* <UploadLinesButton /> */}
      <UploadButton
        // title="Click here to upload lines"
        disabled={!permissions?.permissions.includes("linesHeads_upload")}
        title="Upload Lines"
        meta={{ plan_board_id: record.id }}
        onSuccess={() => dispatch(refreshView())}
        action="lines"
        // className="MuiButton-containedPrimary"
        description="Click here to upload lines"
      // color="primary"
      />
      <SetCarderEfficiencyButton
        record={record}
        title="Click here to change carder efficiency"
        disabled={
          !permissions?.permissions.includes(
            "linesHeads_changeEfficiencyCarder"
          )
        }
      />
      <SupportedGroupButton
          disabled={!permissions?.permissions.includes("linesHeads_view_supported_groups")}
          title="Supported Groups"
          meta={{ plan_board_id: record.id }}
          onSuccess={() => dispatch(refreshView())}
          action="lines"
          {...props.props}
      />

      <Button
        // color="primary"
        variant="contained"
        hidden={!isBulk}
        title="Click here to Bulk delete"
        disabled={!permissions?.permissions.includes("linesHeads_bulkDelete")}
        // className="btn-danger border-0 rounded-1 h6 px-3 ml-2"
        className="btn_ btn_delete"
        onClick={() => {
          console.log({ selectedLineIds });
          if (!!selectedLineIds.length) {
            dataProvider
              .deleteMany("boardLine", { ids: selectedLineIds })
              .then(() => {
                showToast(`Bulk delete successful`);
              });
            setSelectedLineIds([]);
            dispatch(refreshView());
          } else {
            showToastError("No lines selected");
          }
        }}
      >
        <ActionDelete />
        Bulk Delete
      </Button>
      {/* </ButtonGroup> */}
    </Card>
  );
};

const SetCarderEfficiencyButton = ({ record, disabled, title }) => {
  return (
    <Query
      type="getList"
      resource="boardLine"
      payload={{
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "id", order: "ASC" },
        filter: {
          plan_board_group__plan_board_id: record.id,
        },
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return (<Button
            variant="contained"
            // color="primary"
            className="btn_ btn_primary mb-0 mr-2"
            disabled={true}
            title={title}
          >
            Change Carder and Efficiency
          </Button>);
        }
        if (error) {
          return null;
        }
        return (
          <SetCarderEfficiencyDialog
            lines={data}
            record={record}
            disabled={disabled}
            title={title}
          />
        );
      }}
    </Query>
  );
};

const SetCarderEfficiencyDialog = ({ lines, record, disabled, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({
    efficiency: "",
    carder: "",
    lines: [],
    start_date: "",
    end_date: "",
  });

  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (event) => {
    setData((data) => ({ ...data, lines: event.target.value }));
  };

  const onChange = (e, key) => {
    const value = e.target.value;
    key === "efficiency"
      ? setData((data) => ({ ...data, efficiency: value }))
      : setData((data) => ({ ...data, carder: value }));
  };

  const options = {
    undoable: false,
    onSuccess: {
      notification: { body: "Changes saved", level: "success" },
    },
    onError: {
      notification: { body: "Error: action not performed", level: "warning" },
    },
  };

  return (
    <>
      <Button
        variant="contained"
        className="btn_ btn_primary "
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        title={title}
      >
        Change Carder and Efficiency
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        // style={{width:'1300px'}}
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle>
              Set carder and efficiency for a date period
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={() => setIsOpen(false)} />
            </div>
          </Grid>
        </Grid>

        <DialogContent>
          <form>
           
            <Card variant="outlined" style={{ padding: "5px" }}>
              <Box className="py-2" display="flex">
                <Grid item md={6}>
                  <Box display="flex">
                    <Typography>Efficiency :</Typography>
                    <Input
                      className="ml-1"
                      type="number"
                      placeholder="Efficiency"
                      name="efficiency"
                      onChange={(e) => onChange(e, "efficiency")}
                      required={true}
                      value={data ? data["efficiency"] || " " : " "}
                    />
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box display="flex">
                    <Typography>Carder :</Typography>
                    <Input
                      className="ml-1"
                      type="number"
                      placeholder="Carder"
                      name="carder"
                      onChange={(e) => onChange(e, "carder")}
                      required={true}
                      value={data ? data["carder"] || " " : " "}
                    />
                  </Box>
                </Grid>
              </Box>
              <Box className="py-1" display="flex">
                <Grid item md={5}>
                  <Card
                    className="pt-2"
                    variant="outlined"
                    style={{ minHeight: "250px", padding: "5px" ,width:'110%'}}
                  >
                    <Typography className="pb-2">
                      Select the date range :
                    </Typography>
                    <DateRangePicker
                      // calendarAriaLabel="Toggle calendar"
                      format="dd/MM/yyyy"
                      onChange={(dates) =>
                        setData((data) => ({
                          ...data,
                          start_date: dates ? dates[0].valueOf() : null,
                          end_date: dates ? dates[1].valueOf() : null,
                        }))
                      }
                      required={true}
                      value={
                        data && data.start_date && data.end_date
                          ? [new Date(data.start_date), new Date(data.end_date)]
                          : null
                      }
                      zIndex={10000}
                    />
                  </Card>
                </Grid>
                <Grid item md={5} 
                style={{marginLeft:'8%'}}
                 >
                  <Card
                    className="pt-2"
                    variant="outlined"
                    style={{ minHeight: "250px", padding: "5px", width:'120.5%' }}
                  >
                    <Typography className="pb-2">
                      Select Lines/heads :
                    </Typography>

                    <Card className='lineCard' style={{ minWidth:'50px',width: '131px' }}>
                      <Select
                       size="small"
                      style={{ minWidth:'50px',maxHeight:'144px' }}
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        value={data["lines"]}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 10 } } }}
                        renderValue={(selected) => (
                          <div className={classes.chips}  style={{ minWidth:'150px',maxHeight:'144px' }}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={
                                  lines.filter((line) => line.id === value)[0]
                                    .code
                                }
                                className={classes.chip}
                              
                              />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {lines.map((name) => (
                          <MenuItem
                            key={name.id}
                            value={name.id}
                             style={getStyles(name.id, data["lines"], theme)}
                          >
                            {name.code}
                          </MenuItem>
                        ))}
                      </Select>
                    </Card>
                  </Card>
                </Grid>
              </Box>
            </Card>
          </form>
          <DialogActions>
            {/* <Button
              className="btn-danger px-4 py-2 mb-0"
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button> */}

            <Mutation
              type="CUSTOM"
              resource="boardLine"
              payload={{
                id: `${record.id}`,
                action: "set_efficiency_carder",
                method: "POST",
                body: data,
              }}
              options={options}
            >
              {(action) => (
                <Button
                  variant="contained"
                  // color="primary"
                  className="btn_ btn_primary mb-0 mr-0"
                  disabled={
                    !(
                      data["lines"][0] &&
                      data["efficiency"] &&
                      data["carder"] &&
                      data["start_date"] &&
                      data["end_date"]
                    )
                  }
                  onClick={() => {
                    setIsOpen(false);
                    action();
                    setData({
                      efficiency: "",
                      carder: "",
                      lines: [],
                      start_date: "",
                      end_date: "",
                    });
                    showToastSuccess(`Successfully saved`);
                  }}
                >
                  {" "}
                  Set
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

const UploadLinesButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveRecord = (record) => {
    if (!record.plan_board_group) {
      return Promise.resolve(false);
    }
    return dataProvider
      .create("boardLine", {
        data: {
          plan_board_group: record.plan_board_group,
          code: record.code,
          initial_configuration: record.initial_configuration,
          efficiency: record.efficiency,
          sequence_number: record.sequence_number,
          carder: record.carder,
          attributes: { supported_groups: record.supported_groups || null },
          start_date: record.start_date || null,
          compatible_tool_groups: record.compatibale_tool_groups,
        },
      })
      .then((v) => true);
  };

  const onSave = async () => {
    if (data) {
      const columns = data[0];
      const rows = data.slice(1);
      setLoading(true);
      const objects = rows.map((row) => {
        const obj = {};
        row.forEach((v, i) => {
          obj[columns[i]] = v;
        });
        return obj;
      });

      const promises = objects.map(saveRecord);

      Promise.all(promises)
        .then((results) => {
          const saved_count = results.filter((v) => !!v).length;
          if (saved_count == 0) {
            throw new Error("Failed");
          }
          showToast(`Created ${saved_count} lines`);
          setLoading(false);
          setIsOpen(false);
        })
        .catch((e) => {
          console.log(e);
          showToastError("Failed");
          setIsOpen(false);
        });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className="mb-0"
        onClick={() => setIsOpen(true)}
      >
        {" "}
        Upload
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <CSVReader onFileLoaded={(data) => setData(data)} />
          {data && <div>Records {data.length - 1}</div>}
          <DialogActions>
            <Button
              className="btn-danger px-4 py-2 mb-0"
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSave}
              disabled={loading}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  menuPaper: {
    maxHeight: 10
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default LineActions;
