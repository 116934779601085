import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { ToolTipForDeleteButton } from "../../components/ToolTipForDelete";
import { CustomToolbar } from "../../components/CustomToolbar";
import { CustomTopToolbar } from "../../components/CustomTopToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";

export const SubContractEdit = (props) => {
  const validateSite = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.code) {
      errors.code = "Code is required";
    }
    if (!values.address) {
      errors.address = "Address is required";
    }
    if (!values.phone_number) {
      errors.phone_number = "Phone Number is required";
    }
    if (!values.plant) {
      errors.plant = "Plant is required";
    }

    return errors;
  };

  const Title = ({ record }) => {
    return (
      <span>
        Edit Sub Contractor details | {record ? `${record.code} ` : ""}
      </span>
    );
  };

  const customToast = useCustomToast(props);

  return (
    // <Edit title={<Title />} {...props} class={'col-md-6 mt-5'}>
    //   <SimpleForm redirect="list">

    <Edit
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
      actions={<CustomTopToolbar name="sub contractor" />}
      title={<Title />}
      {...props}
      class={"col-md-6 "}
    >
      <SimpleForm
        validate={validateSite}
        toolbar={<CustomToolbar name="subcontracts" text="Sub Contracts" />}
      >
        <TextInput source="name" label={<ReqInputField name="Name" />} />
        <TextInput source="code" label={<ReqInputField name="Code" />} />
        <TextInput source="address" label={<ReqInputField name="Address" />} />
        <TextInput
          source="phone_number"
          label={<ReqInputField name="Phone Number" />}
        />
        <ReferenceInput
          labe="plant"
          source="plant"
          reference="plants"
          label={<ReqInputField name="Plant" />}
        >
          <AutocompleteInput
            disabled
            optionText="code"
            translateChoice={false}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
