import { DELETE_OPERATION_LOSS_SUCCESS, DELETE_OPERATION_LOSS_FAILED, UPDATE_SHIFT_PATTERN_SUCCESS, UPDATE_SHIFT_PATTERN_FAILED } from '../types/operationLossTypes';
import _ from "lodash";
import { REFRESH_VIEW } from "react-admin";
import { put, select, takeEvery } from "redux-saga/effects";
import dataProvider from "../../api/dataProvider";
import { format_to_date_for_api } from "../../lib/date_time";
import { showToastError, showToastSuccess } from "../../lib/toas";
import { loadOperationLosses, setOperationLoss, setShiftPatterns } from "../actions";
import { getDateRangeOperationLosses } from "../selectors/operationLossSelectors";
import { redirect } from "../../lib/redirect";
import { loadHolidays } from "../actions";
import {
  CREATE_OPERATION_LOSS,
  CREATE_OPERATION_LOSS_FAILED,
  CREATE_OPERATION_LOSS_SUCCESS,
  DELETE_OPERATION_LOSS,
  LOAD_OPERATION_LOSSES,
  LOAD_OPERATION_LOSS_FAILED,
} from '../types/operationLossTypes';


export function* operationLossSaga() {
  yield takeEvery(CREATE_OPERATION_LOSS, function* (action: any) {
    try {
      const { lines, operationLoss } = action.payload;
      yield dataProvider.CUSTOM_UPDATE("calendar", {
        body: {
          lines,
          operationLoss,
        },
        action: "add_operation_loss/",
      });
      yield put({ type: CREATE_OPERATION_LOSS_SUCCESS });
      showToastSuccess("Operation loss created successfully!");
      redirect();
      setTimeout(() => window.location.reload(), 1000);
    } catch (e) {
      yield put({ type: CREATE_OPERATION_LOSS_FAILED });
      showToastError("Operation loss create failed!");
    }
  });

  // yield takeEvery(LOAD_OPERATION_LOSSES, function* (action: any) {
  //   const { section } = action.payload;
  //   const date_range = yield select(getDateRangeOperationLosses);
  //   let node = section;
  //   let type = section;
  //   if (section && section.split("-").length == 2) {
  //     type = section.split("-")[0];
  //     node = section.split("-")[1];
  //   } else {
  //     type = "planboard";
  //   }
  //   try {
  //     const operationLosses = yield dataProvider.CUSTOM_LIST("calendar", {
  //       pagination: { page: 1, perPage: 1000000 },
  //       sort: { field: "id", order: "ASC" },
  //       action: "get_operationLosses_in_range",
  //       query: {
  //         start_date: format_to_date_for_api(date_range.start_date),
  //         end_date: format_to_date_for_api(date_range.end_date),
  //         type,
  //         node,
  //       },
  //     });
  //     yield put(setOperationLoss(operationLosses.data['operationLosses']));
  //   } catch (e) {
  //     yield put({ type: LOAD_OPERATION_LOSS_FAILED });
  //   }
  // });


  yield takeEvery(DELETE_OPERATION_LOSS, function* (action: any) {
    try {
      const { lines, operationLossId, section } = action.payload;
      yield dataProvider.CUSTOM_UPDATE("calendar", {
        body: {
          lines,
          operationLossId,
        },
        action: "delete_operationLoss/",
      });
      showToastSuccess("Operation loss deleted successfully!");
      yield put({ type: DELETE_OPERATION_LOSS_SUCCESS })
      yield put(loadHolidays(section));
    } catch (e) {
      yield put({ type: DELETE_OPERATION_LOSS })
      showToastError("Operation loss delete failed!");
    }
  });



  yield takeEvery(REFRESH_VIEW, function* () {
    const path = yield select((s: any) => s.router.location.pathname);
    if (path === "/holidays") {
      const section = yield select((s: any) => s.custom.holidays.section);
      yield put(loadHolidays(section));
    }
  });

}