import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { Fragment } from 'react';
import { RequestRMForm, AddRMForm } from './dialogContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttons: {
      '& > *': {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(3)
      },
    },
    inlineFlex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
}));

export const BulkRMButtons = (props) => {
    const classes = useStyles()
    return (
    <div className={classes.inlineFlex}>
        <RequestRMButton {...props} />
        <AddRMButton {...props} />
    </div>
    )
}

const RequestRMButton = ({selectedIds}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const classes = useStyles()

    const handleClose = () => setIsOpen(false)
    const handleOpen = () => setIsOpen(true)

    return (
        <Fragment>
            <div className={classes.buttons} >
                <Button
                    onClick={handleOpen}
                    variant='contained'
                    color='primary'
                >
                    {`Request RM`}
                </Button>
            </div>
            <Dialog
                fullWidth={false}
                maxWidth={`md`}
                open={isOpen} 
                onClose={handleClose} 
            >
                <DialogTitle>{`Request RM`}</DialogTitle>
                <DialogContent>
                    <RequestRMForm selectedIds={selectedIds} closeAction={handleClose} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

const AddRMButton = ({selectedIds}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const classes = useStyles()

    const handleClose = () => setIsOpen(false)
    const handleOpen = () => setIsOpen(true)

    return (
        <Fragment>
            <div className={classes.buttons} >
                <Button
                    onClick={handleOpen}
                    variant='contained'
                    color='primary'
                >
                    {`Add RM`}
                </Button>
            </div>
            <Dialog
                fullWidth={false}
                maxWidth={`md`}
                open={isOpen} 
                onClose={handleClose} 
            >
                <DialogTitle>{`Add RM`}</DialogTitle>
                <DialogContent>
                    <AddRMForm selectedIds={selectedIds} closeAction={handleClose} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}