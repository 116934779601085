import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";


export function OperationProgress(props) {
  return (
    <div className="bar">
      <LinearProgress variant="determinate" value={props.value} />
    </div>
  );
}
export function PlanProgress(props) {
  return (
    <div className="bar">
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={props.value}
      />
    </div>
  );
}
