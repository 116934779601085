import React from "react";
import { CustomToolbar } from "../../components/CustomToolbar";
import {
  Create,
  email,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
  AutocompleteArrayInput,
} from "react-admin";
import { ReqInputField } from "../../components/ReqInputField";
import { useCustomToast } from "../../hooks/useCustomToast";
import _ from 'lodash'

const emailValidate = email();

const validateUser = (values) => {
  const { first_name, last_name, username } = values;
  const errors = {};
  if (!values.first_name) {
    errors.first_name = "Firstname is required";
  }
  if (first_name && /\s/.test(first_name)) {
    errors.first_name = "First name cannot contain spaces";
  }
  if (!values.last_name) {
    errors.last_name = "Lastname is required";
  }
  if (last_name && /\s/.test(last_name)) {
    errors.last_name = "Last name cannot contain spaces";
  }
  if (!values.username) {
    errors.username = "Username is required";
  }
  if (username && /\s/.test(username)) {
    errors.username = "Username cannot contain spaces";
  }
  if (!values.email) {
    errors.email = "Email is required";
  }
  if (!values.pass) {
    errors.pass = "Password Required";
  }
  if (_.isEmpty(values.app_user)) {

    const newOb = {
      app_user: {
        role: "Role is Required"
      }
    }

    Object.assign(errors, newOb)
  }
  if (!values.password) {
    errors.password = "Password Confirmation Required";
  } else if (values.pass !== values.password) {
    errors.password = "passwords dose not  match";
  }

  return errors;
};

export const CreateUser = (props) => {
  const { permissions } = usePermissions();
  const permissionChoicesArray = [
    permissions === "ADMIN" ? { id: "ADMIN", name: "ADMIN" } : null,
    permissions === "ADMIN" ? { id: "SITE", name: "SITE" } : null,
    permissions === "ADMIN" ? { id: "SITE", name: "SITE" } : null,
    ["ADMIN", "SITE"].includes(permissions?.access_level)
      ? { id: "PLANT", name: "PLANT" }
      : null,
    ["ADMIN", "SITE"].includes(permissions?.access_level)
      ? { id: "PLANT", name: "PLANT" }
      : null,
    ["ADMIN", "SITE", "SITE"].includes(permissions?.access_level)
      ? { id: "SUB", name: "SUB" }
      : null,
    { id: "BASIC", name: "BASIC" },
  ];
  const permissionChoices = permissionChoicesArray.filter((item) => item);

  const usersSite = localStorage.getItem("site");
  const usersPlant = localStorage.getItem("plant");
  const customToast = useCustomToast(props);
  return (
    <Create
      successMessage={`User created successfully`}
      {...props}
      class={"col-md-6 mt-5"}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm
        toolbar={<CustomToolbar need={false} name="users" text="User" />}
        validate={validateUser}
      >
        <div style={{ display: "inline-block", width: "100%" }}>
          <div
            style={{
              display: "inline-block",
              width: "48%",
              marginRight: "4px",
            }}
          >
            <TextInput
              source="first_name"
              label={<ReqInputField name="Firstname" />}
              title="This field is mandatory"
              placeholder="* Firstname"
            />
          </div>

          <div style={{ display: "inline-block", width: "50%" }}>
            <TextInput
              source="last_name"
              label={<ReqInputField name="Lastname" />}
              title="This field is mandatory"
              placeholder="* Lastname"
            />
          </div>
        </div>
        <TextInput
          source="username"
          label={<ReqInputField name="Username" />}
          title="This field is mandatory"
          placeholder="* username"
        />
        <TextInput
          source="email"
          label={<ReqInputField name="Email" />}
          title="This field is mandatory"
          validate={emailValidate}
        />
        <ReferenceInput
          source="app_user.role"
          reference="roles"
          label={<ReqInputField name="Roles" />}
          perPage={1000}
          title="This field is mandatory"
        >
          <SelectInput translateChoice={false} optionText="code" label="Role" />
        </ReferenceInput>
        <TextInput
          source="pass"
          type="password"
          label={<ReqInputField name="Password" />}
          title="This field is mandatory"
        />
        <TextInput
          source="password"
          type="password"
          label={<ReqInputField name="Confirm Password" />}
          title="This field is mandatory"
        />
      </SimpleForm>
    </Create>
  );
};
