import React from "react";
import {
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { SaveButton, DeleteButton, Toolbar, usePermissions } from "react-admin";
import Confirmation from "../../components/Confirmation";
import { makeStyles } from "@material-ui/core/styles";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

export const EditDemand = (props) => {
  const { permissions } = usePermissions();

  // const useStyles = makeStyles({
  //   toolbar: {
  //     display: "flex",
  //     justifyContent: "space-between",
  //   },
  // });

  // const CustomToolbar = (props) => (
  //   <Toolbar {...props} classes={useStyles()}>
  //     <SaveButton />
  //     <Confirmation
  //       title='click hear to delete demand' disabled={!permissions?.permissions.includes('demands_delete')}
  //       text="sites"
  //     />{" "}
  //   </Toolbar>
  // );

  const Title = ({ record }) => {
    return <span>Edit Demand | {record ? `${record.id} ` : ""}</span>;
  };
  const customToast = useCustomToast(props);
  return (
    <Edit
      {...props}
      title={<Title />}
      class={"col-md-6 mt-5"}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm toolbar={<CustomToolbar name="demands" text="Demand" />}>
        <ReferenceInput label="Order" source="order" reference="orders">
          <SelectInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput label="Item" source="item" reference="items">
          <SelectInput optionText="code" />
        </ReferenceInput>
        {/* <TextInput source="quantity" /> */}
        <TextInput source="initial_required" />
        <TextInput source="is_quality_ok" />
        {/* <BooleanInput source="is_final" /> */}
        {/* <DateTimeInput source="initial_required_time" /> */}
        {/* <DateTimeInput source="supply_time" /> */}
        <DateTimeInput source="force_supply_time" />
        {/* <DateTimeInput source="required_time" /> */}
        <NumberInput source="completed_quantity" />
      </SimpleForm>
    </Edit>
  );
};
