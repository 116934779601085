import { useEffect, useRef } from "react";
// privent set state  after component unmount
export const useClean = () => {
  const ref = useRef(true);
  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  return ref;
};
