import Excel from "exceljs";
import { saveAs } from "file-saver";
import { format_to_date_time } from "../../lib/date_time";

const alp = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const excelExport = async (headers, data) => {
  const workbook = new Excel.Workbook();
  workbook.creator = "Auto Generated by Stack Schedular";
  const worksheet = workbook.addWorksheet("Efficiency Report", {
    views: [{ showGridLines: true }],
  });

  //Title
  worksheet.getRow(2).height = 35;
  worksheet.mergeCells("B2", "F2");
  worksheet.getCell("B2").value = `Efficiency Report`;
  worksheet.getCell("B2").font = {
    name: "Verdana",
    size: 12,
  };
  worksheet.getCell("B2").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("B2").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFFFFF00" },
  };
  //Header1
  worksheet.getRow(4).height = 26;
  worksheet.mergeCells("A4", "G4");
  worksheet.mergeCells("H4", "M4");
  worksheet.mergeCells("N4", "Q4");
  worksheet.getCell("H4").value = `Output`;
  ["A4", "H4", "N4"].forEach((cell) => {
    worksheet.getCell(cell).border = {
      top: { style: "thin", color: { argb: "00919191" } },
      left: { style: "thin", color: { argb: "00919191" } },
      right: { style: "thin", color: { argb: "00919191" } },
    };
    worksheet.getCell(cell).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(cell).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF9999FF" },
    };
    worksheet.getCell(cell).font = {
      name: "Verdana",
      size: 10,
      bold: true,
    };
  });

  const columnWidths = {
    epf_no: 8,
    user: 8,
    workstation: 12,
    line_or_head: 12,
    shift_date: 15,
    shift: 12,
    input: 10,
    marked: 10,
    b_qty: 15,
    b_reason: 18,
    d_qty: 15,
    d_reason: 18,
    nc: 6,
    efficiency: 15,
    inefficiency_reason: 20,
    occurred_between: 20,
    demands: 30,
  };

  //Header2
  worksheet.getRow(5).height = 22;
  //worksheet.getRow(2).values = headers.map((a) => a.Header);
  worksheet.columns = headers.map((header) => ({
    //header: header.Header,
    key: header.accessor,
    width: columnWidths[header.accessor] || 8,
  }));
  worksheet.getRow(5).values = headers.map((a) => a.Header);
  alp
    .split("")
    .slice(0, 17)
    .forEach((cell) => {
      worksheet.getCell(`${cell}5`).font = {
        name: "Verdana",
        size: 9,
        bold: true,
      };
      worksheet.getCell(`${cell}5`).border = ["G", "M", "Q"].includes(cell)
        ? {
            right: { style: "thin", color: { argb: "00919191" } },
            bottom: { style: "thin", color: { argb: "00919191" } },
          }
        : {
            bottom: { style: "thin", color: { argb: "00919191" } },
          };
      worksheet.getCell(`${cell}5`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getCell(`${cell}5`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FFD8D6FF` },
      };
    });

  //Records
  data.map((record) => {
    record.inefficiency_reason = record.inefficiency_reason[0]
      ? record.inefficiency_reason[0]
      : "";
    record.occurred_between = record.occurred_between
      ? [0]
        ? `${record.occurred_between[0].from.replaceAll(
            "-",
            "/"
          )} - ${record.occurred_between[0].to.replaceAll("-", "/")}`
        : ""
      : "";
  });

  data.map((entry) => {
    worksheet.getRow(data.indexOf(entry) + 6).values = headers.map(
      (header) => entry[header.accessor]
    );
    const row = worksheet.getRow(data.indexOf(entry) + 6);
    row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = {
        name: "Verdana",
        size: 9,
      };
      cell.alignment = {
        vertical: "top",
        horizontal: "left",
        wrapText: true,
      };
      cell.border = {
        left: { style: "thin", color: { argb: "00C7C7C7" } },
        right: { style: "thin", color: { argb: "00C7C7C7" } },
      };
      cell.fill =
        row.number % 2 === 0
          ? {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: `00F3F3F3` },
            }
          : {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: `00FFFFFF` },
            };
    });
  });

  //footer
  worksheet.addRow([""]);

  const timeStamp = worksheet.addRow([
    `Timestamp: ${format_to_date_time(new Date())}`,
  ]);
  timeStamp.style.font = {
    name: "Verdana",
    size: 10,
  };
  const genBy = worksheet.addRow(["Auto generated by Stack Schedular"]);
  genBy.style.font = {
    name: "Verdana",
    size: 10,
  };
  [timeStamp, genBy].forEach((cell) =>
    worksheet.mergeCells(
      `A${cell._cells[0]._row._number}`,
      `D${cell._cells[0]._row._number}`
    )
  );
  //export
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";
  const filename = `Efficiency Report ${format_to_date_time(new Date())}`;
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, filename + fileExtension);
};
