import React, { ChangeEvent, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRevisions, getSupplyChainData, getSupplyChainTimeBucketsSorted } from './reducer/selectors'
import { useBlockLayout, useExpanded, useSortBy, useTable } from "react-table"
import styled from 'styled-components'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { FixedSizeList } from 'react-window'
import update from 'immutability-helper'
import { Item, SupplyChainState, TimeBucket } from './interfaces'
import { getTitleFromKey } from "./DataImportView"
import _ from "lodash"
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';

import LeftIcon from '@material-ui/icons/ArrowLeft';
import RightIcon from '@material-ui/icons/ArrowRight';
import Chip from '@material-ui/core/Chip';

import Popover from '@material-ui/core/Popover';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import EditIcon from '@material-ui/icons/Launch';
import Checkbox from '@material-ui/core/Checkbox';
import { useSticky } from 'react-table-sticky';
import { supplyChainUpdateItemTimeBucket, supplyChainManualPlan, supplyChainManualPlanFindInfeasible, supplyChainPublishRevision, supplyChainClearRevisions } from './reducer/actions'
import { getBufferPercetageForTimeBucket, getCapacityForTimeBucket, getConsumptionForTimeBucket, getDemandAndConsumptionForTimeBucket, getDemandForTimeBucket, getEndingStockForTimeBucket, getMinBufferForTimeBucket, getMinCapacityForTimeBucket, getOpenningStockForTimeBucket, getPlannedQtyForTimeBucket, getSupplyForTimeBucket } from './lib'
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Typography, withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { Chart } from './TimeSeriesChart'
import { Bar } from 'react-chartjs-2'
import { showToastSuccess, showToastWarning } from '../../lib/toas'
import { CompareButton } from "./CompareView"
import { ActionButton } from '../materialRequirements/actionButtons'
import { RevisionState } from './reducer/reducer'
import CostExplorer from './CostExplorer'
import ExportMaterial from './ExportMaterial'

export default function SimulationView({ open = true }: any) {

    const [selectedItems, setSelectedItems] = useState([] as string[])
    const [selectedBucketIndex, setSelectedBucketIndex] = useState(0)
    const data = useSelector(getSupplyChainData)
    const selectedBucketId = data.time_buckets[selectedBucketIndex].id
    const hasItemsSelected = selectedItems.length > 0

    return (
        <div style={{ display: open ? undefined : 'none' }}>
            <Grid container spacing={0} >
                <Grid md={hasItemsSelected ? 7 : 12} style={{ padding: 5 }}>
                    <Card>
                        <CardContent>
                            <Table onChangeSelectedItems={setSelectedItems} selectedItems={selectedItems} selectedBucketIndex={selectedBucketIndex}></Table>
                        </CardContent>
                    </Card>
                    <br />
                    {hasItemsSelected && <Card>
                        <CardHeader title="Cost Explorer" />
                        <CardContent>
                            <CostExplorer selectedItems={selectedItems} selectedTimeBucketId={selectedBucketId} data={data} />
                        </CardContent>
                    </Card>}
                </Grid>

                {hasItemsSelected ? <Grid md={5} style={{ padding: 5 }}>
                    <Card>
                        <CardContent>
                            <DetailsPanel selectedItems={selectedItems} selectedBucketIndex={selectedBucketIndex} setSelectedBucketIndex={setSelectedBucketIndex} />
                        </CardContent>
                    </Card>
                </Grid> : null}

            </Grid>
        </div>
    )
}



const Styles = styled.div`
.table {
  border: 1px solid #bbb;
  font-size: 0.9rem;
  vertical-align: center;

  .tr {
    height: 30px!important;
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .tr:nth-child(odd) {background: white}
  .tr:nth-child(even) {background: black}
  
  /* .td:nth-child(even) {background: rgba(0,0,0,0.01)} */
  /* .td:first-child {background: white} */
  /* .td:nth-child(odd) {background: #E2E2E2} */

  .th,
  .td {
    padding: 1px;
    margin: 0;
    border-bottom: 1px solid #bbb;
    border-right: 1px solid #bbb;
    background: transparent;
    overflow: hidden;
      input {
        padding-right: 1;
        margin: 0;
        text-align: right;
        border: 0;
        background: transparent;
        width: 100%;
        color: orange;
      }
      input:disabled {
        color: green
      }

    :last-child {
      border-right: 0;
    }
  }
  .th{
      text-align:center;
  }

.th:first-child{
    width: 300px!important;
}
.td:first-child{
    width: 300px!important;
}

  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .td{
        background: rgba(255, 255, 255, 0.95);
    }
    .th, .td:first-child {
        background: rgba(197, 237, 253, 0.95);
        font-weight: 500;
    }
    .tr:first-child {
        .th {
            font-weight: 700;
        }
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }
}
`



const columnDateSpliter = "__"
type ColumnRecord = {
    key: string
    getValue: (originalRow: OriginalRow, timeBucketId: string, supplyChainState: SupplyChainState) => string | number
    getSetValueAction?: (originalRow: OriginalRow, timeBucketId: string, newValue: string) => any
}

const timeSeriesColumns: ColumnRecord[] = [
    {
        key: "openning_stock",
        getValue: (record, timeBucketId, data) => _.sumBy(record.itemIds, item => getOpenningStockForTimeBucket(data, timeBucketId, item)),
    },
    {
        key: "demand",
        getValue: (record, timeBucketId, data) => _.sumBy(record.itemIds, item => getDemandForTimeBucket(data, timeBucketId, item)),
        getSetValueAction: (originalRow, tiemBucketId, value) => supplyChainUpdateItemTimeBucket(originalRow.id, tiemBucketId, "demand", parseFloat(value) || 0)
    },
    {
        key: "supply",
        getValue: (record, timeBucketId, data) => _.sumBy(record.itemIds, item => getSupplyForTimeBucket(data, timeBucketId, item)),
        getSetValueAction: (originalRow, tiemBucketId, value) => supplyChainUpdateItemTimeBucket(originalRow.id, tiemBucketId, "supply", parseFloat(value) || 0)
    },
    {
        key: "capacity",
        getValue: (record, timeBucketId, data) => getCapacityForTimeBucket(data, timeBucketId, record.itemIds),
    },
    {
        key: "planned",
        getValue: (record, timeBucketId, data) => _.sumBy(record.itemIds, item => getPlannedQtyForTimeBucket(data, timeBucketId, item)),
    },
    {
        key: "ending_stock",
        getValue: (record, timeBucketId, data) => _.sumBy(record.itemIds, item => getEndingStockForTimeBucket(data, timeBucketId, item)),
    },
    {
        key: "min_buff %",
        getValue: (record, timeBucketId, data) => _.round(_.meanBy(record.itemIds, item => getMinBufferForTimeBucket(data, timeBucketId, item)) * 100, 1),
        getSetValueAction: (originalRow, tiemBucketId, value) => supplyChainUpdateItemTimeBucket(originalRow.id, tiemBucketId, "minimum_buffer_stock", (parseFloat(value) / 100) || 0)
    },
    {
        key: "actual_buff %",
        getValue: (record, timeBucketId, data) => _.round(_.meanBy(record.itemIds, item => getBufferPercetageForTimeBucket(data, timeBucketId, item)) * 100, 1)
    },
]

type OriginalRow = {
    id: string
    itemIds: string[]
    subRows?: OriginalRow[]
    hasSubRows: boolean
}

const columnMap = _.chain(timeSeriesColumns).groupBy(v => v.key).mapValues(v => v[0]).value()


// Create an editable cell renderer
const Cell = ({
    value: initialValue,
    row: { original },
    column: { id: columnId },
}: any) => {
    // We need to keep and update the state of the cell normally

    const [value, setValue] = React.useState(initialValue)
    const [columnAccessId, timeBucketId] = columnId.split(columnDateSpliter)
    const data = useSelector(getSupplyChainData)
    const columnRecord = columnMap[columnAccessId]
    const dispath = useDispatch()
    const onChange = (e: any) => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if (columnRecord && columnRecord.getSetValueAction) {
            const action = columnRecord.getSetValueAction(original, timeBucketId, value)
            dispath(action)
        }
        // updateMyData(index, id, value)
    }
    const canEdit = (!original.hasSubRows) && columnRecord.getSetValueAction

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        if (columnRecord) {
            setValue(columnRecord.getValue(original, timeBucketId, data))
        }
    }, [columnRecord, timeBucketId, original])

    return <span>
        <input disabled={!canEdit} value={value} onChange={onChange} onBlur={onBlur}></input>
    </span>
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: Cell,
}

const ArrowLeft = () => <PlayCircleFilledIcon color="primary" />
const ArrowDown = () => <ArrowDropDownCircleOutlinedIcon color="secondary" />
const CalendarIcon = () => <EventNoteOutlinedIcon color="error" />
const DotIcon = () => <MoreHorizOutlinedIcon color="secondary" />

const toggleInTheList = (set: string[], changeItems: string[]) => {
    if (changeItems.every(v => set.indexOf(v) > -1)) {
        return set.filter(v => changeItems.indexOf(v) == -1)
    }
    return _.uniq([...set, ...changeItems])
}

const ExpandCheckBox = ({ original, selectedItems, onChangeSelectedItems }: any) => {
    const isSelected = (itemId: string) => selectedItems.indexOf(itemId) >= 0
    const checked = (original.itemIds as string[]).every(isSelected)
    const indetermined = !checked && original.itemIds.some(isSelected)
    const handleChange = (e: ChangeEvent) => {
        e.stopPropagation()
        onChangeSelectedItems(toggleInTheList(selectedItems, original.itemIds))
    }

    return <span style={{ display: 'inline-block', overflowX: 'hidden', width: 20 }}>
        <input
            type="checkbox"
            checked={checked}
            onChange={handleChange}
        />
    </span>
}

const ExpandRow = {
    // Build our expander column
    id: 'expander', // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
        <span {...getToggleAllRowsExpandedProps()}>
            Category
            {/* {isAllRowsExpanded ? <ArrowDown /> : <ArrowLeft />} */}
        </span>
    ),
    sticky: 'left',
    Cell: ({ row, selectedItems, onChangeSelectedItems }: any) => {


        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        const data = useSelector(getSupplyChainData)

        const hasInfeasibleItems = data.infeasible_items.some(id => row.original.itemIds.indexOf(id) >= 0)
        const hasLossItems = data.sales_loss.some(lossRecord => row.original.itemIds.indexOf(lossRecord.item_id) >= 0)

        const isRed = hasInfeasibleItems || hasLossItems

        return row.canExpand ? (
            <span
                style={{
                    paddingLeft: `${row.depth + 1}rem`,
                }}>
                <ExpandCheckBox original={row.original} selectedItems={selectedItems} onChangeSelectedItems={onChangeSelectedItems} />
                <span
                    {...row.getToggleRowExpandedProps()}>
                    &nbsp;
                    <span style={{ display: 'inline-block', overflowX: 'hidden', color: 'orange' }}>
                        {row.isExpanded ? "▼" : "ᐅ"}
                        {/* <CalendarIcon /> */}
                    </span>
                    &nbsp;
                    <span style={{ display: 'inline-block', overflowX: 'hidden', color: isRed ? 'red' : undefined, width: 150 }}>
                        {row.original.groupId}
                    </span>
                </span>
            </span>
        ) : <span style={
            {
                paddingLeft: `${row.depth * 2}rem`,
            }
        }>
                <ExpandCheckBox original={row.original} selectedItems={selectedItems} onChangeSelectedItems={onChangeSelectedItems} />
                &nbsp;
                <span style={{ display: 'inline-block', overflowX: 'hidden', color: isRed ? 'red' : undefined, }}>
                    {row.original.groupId}
                </span>
                &nbsp;
                <span style={{ display: 'inline-block', overflowX: 'hidden' }}>
                    <DotIcon />
                </span>
            </span>
    }
}

const getId = (item: Item, depth: number) => {
    const parts = [...Item.get_category_codes_list(item), item.id]
    return parts[depth]
    // return parts.slice(0, depth + 1).join(",")
}
const prepareItemHeirarchy = (items: Item[], depth = 0) => {
    const groups = _.groupBy(items, item => getId(item, depth))
    return Object.keys(groups).map(groupId => {
        const groupItems = groups[groupId]
        const hasSubRows = groupItems.length > 1
        const itemIds = groupItems.map(item => item.id)
        if (hasSubRows) {
            const subRows: any[] = prepareItemHeirarchy(groupItems, depth + 1)
            return { id: groupId, groupId, depth, subRows, hasSubRows, itemIds }
        }
        const displayId = groupId == itemIds[0] ? itemIds[0] : `${groupId} - ${itemIds[0]}`
        return { ...groupItems[0], groupId: displayId, hasSubRows, itemIds }
    }) as OriginalRow[]
}



const defaultHiddenColumns = ["capacity", "supply", "ending_stock"]
type TableProps = {
    selectedItems: string[];
    onChangeSelectedItems: (items: string[]) => void;
    selectedBucketIndex: number;
}

const getChartData = (items: string[], startBucketIndex: number, data: SupplyChainState) => {

    console.log("items: ", items)
    console.log("startBucketIndex: ", startBucketIndex)
    const timeBuckets = data.time_buckets.slice(startBucketIndex, startBucketIndex + 5)
    const mapForBuckets = (func: any) => timeBuckets.map(b => _.sumBy(items, item => func(data, b.id, item)))
    const demandData = mapForBuckets(getDemandAndConsumptionForTimeBucket)
    const datasets = [
        {
            label: "Cost",
            data: demandData,
            backgroundColor: 'rgba(0, 20, 150, 0.9)',
            borderColor: 'rgba(0,  20, 150, 1)',
        },
    ]

    const result = {
        labels: timeBuckets.map(b => b.label),
        datasets: datasets.map(entry => ({ ...entry, borderWidth: 1, tension: 0.1 }))
    }
    return result
}
const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    },
}

export const CostCard = (props: { items: string[], startBucketIndex: number, hideTopics?: boolean }) => {
    const data = useSelector(getSupplyChainData)
    return <>
        {!props.hideTopics && <><h3>Costing Information</h3>
            <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Revision 1: </span>
                <span >Pens</span>
            </div></>}
        <div style={{ height: 200, }}>
            <Bar options={options} data={getChartData(props.items, props.startBucketIndex, data)} />
        </div>
    </>
}

const Table = ({ onChangeSelectedItems, selectedItems, selectedBucketIndex }: TableProps) => {

    const data = useSelector(getSupplyChainData)
    const buckets = useSelector(getSupplyChainTimeBucketsSorted)



    const [hiddenColumns, setHiddenColumns] = useState(defaultHiddenColumns)

    const columns = React.useMemo(
        () => [
            ExpandRow,
            ...(buckets.map(b =>
                ({
                    Header: b.label, accessor: b.id,
                    columns: timeSeriesColumns
                        .filter(col => !hiddenColumns.some(key => key == col.key))
                        .map(c =>
                            ({
                                Header: getTitleFromKey(c.key),
                                accessor: c.key + columnDateSpliter + b.id,
                            }))
                })
            ))],
        [buckets, hiddenColumns]
    )

    // const records = React.useMemo(
    //     () => (data.items).map(item => ({ Header: item.id, accessor: b.id })),
    //     [buckets]
    // )
    const ref = useRef(null as any)
    const [availableWidth, setWidth] = useState(1)
    useEffect(() => {
        if (ref.current) {
            setWidth(ref.current.offsetWidth)
        }
    }, [ref.current, selectedItems.length == 0])

    const [records, setRecords] = React.useState([] as any[])

    useEffect(() => {
        setRecords(prepareItemHeirarchy(data.items))
    }, [data])

    const getRowId = React.useCallback(row => {
        return row.id
    }, [])



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        data: records,
        defaultColumn,
        columns,
        autoResetExpanded: false,
        selectedItems,
        onChangeSelectedItems
    } as any,
        useSortBy,
        useBlockLayout,
        useExpanded,
        useSticky
    )
    const height = 80 + rows.length * 30
    return (
        <Styles>
            <div ref={ref}></div>
            <div style={{ padding: '0px 0px 7px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <ColumnSelector columns={timeSeriesColumns.map(c => c.key)} onChangeHiddenColumns={setHiddenColumns} hiddenColumns={hiddenColumns} />
                <div>
                    <ActionButtons />&nbsp;
                    <CompareButton selectedItems={selectedItems} />&nbsp;
                    <ExportMaterial selectedItems={selectedItems}/>&nbsp;
                </div>
            </div>
            
            { availableWidth > 1 && <div {...getTableProps()} className="table sticky" style={{ width: availableWidth, height }}  >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <div {...headerGroup.getHeaderGroupProps()} className="tr" >
                            {headerGroup.headers.map(column => (
                                <div {...column.getHeaderProps()} className="th">{column.render('Header')}</div>
                            ))}
                        </div>
                    ))}
                </thead>
                <div {...getTableBodyProps()} className="body">
                    {rows.map(
                        (row, index) => {
                            prepareRow(row)
                            return (
                                <div {...row.getRowProps()} className="tr">
                                    {row.cells.map(cell => {
                                        return <div {...cell.getCellProps()} className="td">{cell.render('Cell')}</div>
                                    })}
                                </div>
                            )
                        }
                    )}
                </div>
            </div>}
        </Styles>
    )
}


const ActionButtons = () => {
    const dispatch = useDispatch()
    const handlePublished = () => {
        showToastWarning("Supply plan is being published...");
        setTimeout(() => {
            showToastSuccess("Plan is published to the production")
        }, 1500)
    }

    const handleSave = () => {
        dispatch(supplyChainPublishRevision())
        showToastWarning("Saving");
        setTimeout(() => {
            showToastSuccess("Plan is saved.")
        }, 500)
    }

    //&nbsp for spaces between buttons
    return <>
        <Button variant="contained" color="primary" onClick={handlePublished}>Publish </Button>&nbsp;  
        <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>&nbsp;
        <Button variant="contained" color="primary" onClick={() => dispatch(supplyChainManualPlan())}>Simulate</Button>&nbsp;
        <Button variant="contained" color="primary" onClick={() => dispatch(supplyChainManualPlanFindInfeasible())}>Find Infeasible and Plan</Button>
    </>
}




type ColumnSelectorProps = {
    columns: string[],
    onChangeHiddenColumns: any,
    hiddenColumns: string[]
}
export const ColumnSelector = ({ columns, onChangeHiddenColumns, hiddenColumns }: ColumnSelectorProps) => {
    const handleChange = (col: string) => {
        if (hiddenColumns.some(key => key == col)) {
            onChangeHiddenColumns(hiddenColumns.filter(key => key != col))
        } else {
            onChangeHiddenColumns([...hiddenColumns, col])
        }

    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return <div>
        <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
            Select Columns
        </Button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div style={{ padding: 20 }}>

                {columns.map(col => {
                    return <div>

                        <label>
                            <input
                                type="checkbox"
                                defaultChecked={!hiddenColumns.some(key => key == col)}
                                onChange={() => handleChange(col)}
                            />
                            <span> {getTitleFromKey(col)} </span>
                &nbsp; &nbsp;</label>
                    </div>
                })}
            </div>
        </Popover>
    </div>
}




const DetailsPanel = ({ selectedItems, selectedBucketIndex, setSelectedBucketIndex }: { selectedItems: string[], selectedBucketIndex: number, setSelectedBucketIndex: any }) => {
    const data = useSelector(getSupplyChainData)

    const selectedBucketId = data.time_buckets[selectedBucketIndex].id
    const chartBuckets = data.time_buckets.slice(selectedBucketIndex, selectedBucketIndex + 50)
    const capacity = getCapacityForTimeBucket(data, selectedBucketId, selectedItems)
    const minCapacity = getMinCapacityForTimeBucket(data, selectedBucketId, selectedItems)
    const planned = _.sumBy(selectedItems, item => getPlannedQtyForTimeBucket(data, selectedBucketId, item))
    const openning = _.sumBy(selectedItems, item => getOpenningStockForTimeBucket(data, selectedBucketId, item))
    const demand = _.sumBy(selectedItems, item => getDemandForTimeBucket(data, selectedBucketId, item))
    const consumption = _.sumBy(selectedItems, item => getConsumptionForTimeBucket(data, selectedBucketId, item))

    const routings = data.routings.filter(r => selectedItems.indexOf(r.item_id) >= 0)
    const operations = _.uniq(routings.map(r => r.operation_id))

    const hasInfItemsSelected = selectedItems.some(item => data.infeasible_items.indexOf(item) >= 0 || data.sales_loss.findIndex(row => row.item_id == item) >= 0)


    return <div>
        <Card >
            <CardContent >
                Date Range: <BucketSelector buckets={data.time_buckets} selectedIndex={selectedBucketIndex} onChangeIndex={setSelectedBucketIndex} />
            </CardContent >
        </Card >
        <div style={{ overflowY: 'scroll', maxHeight: '650px' }}>
            <br />
            <Card >
                <CardContent >
                    <div style={{ height: 300 }}>
                        <Chart items={selectedItems} buckets={chartBuckets} />
                    </div>
                </CardContent>
            </Card>
            <br />
            {hasInfItemsSelected && <Card>
                <CardContent>
                    <LossCard selectedItems={selectedItems} />
                </CardContent>
            </Card>}
            <br />
            <Card >
                <CardContent >
                    <CapacityCard minCapacity={minCapacity} operations={operations} capacity={capacity} planned={planned} numberOfMachines={3} machineType="INM Machine" />
                </CardContent >
            </Card >
            <br />
            <Card >
                <CardContent >
                    <InventryCard items={selectedItems} openning={openning} totalDemand={demand + consumption} timeBucketId={selectedBucketId} />
                </CardContent >
            </Card >
            <br />
            <Card >
                <CardContent >
                    <CostCard items={selectedItems} startBucketIndex={selectedBucketIndex} />
                </CardContent >
            </Card >
        </div>
    </div >
}

export const BucketSelector = ({ buckets, selectedIndex, onChangeIndex }: { buckets: TimeBucket[], onChangeIndex: (n: number) => void, selectedIndex: number }) => {
    const handleUp = () => {
        onChangeIndex(Math.min(buckets.length - 1, selectedIndex + 1))
    }

    const handleDown = () => {
        onChangeIndex(Math.max(0, selectedIndex - 1))
    }
    return <span>
        <IconButton color="primary" size="medium" onClick={handleDown}>
            <LeftIcon fontSize="large" />
        </IconButton>
        {buckets[selectedIndex].label}
        <IconButton color="primary" size="medium" onClick={handleUp}>
            <RightIcon fontSize="large" />
        </IconButton>
    </span>
}


const LinearProgressUpdated = withStyles((theme) => ({
    root: {
        height: 20,
        borderRadius: 3,
    },
    bar: {
        borderRadius: 5,
        backgroundColor: 'gold',
    },
}))(LinearProgress);

export function LinearProgressWithLabel(props: any) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="70%" mr={1}>
                <LinearProgressUpdated variant="determinate" {...props} />
            </Box>
            <Box position="relative" zIndex="tooltip" marginLeft="10px" textAlign="center" minWidth={60}>
                <Typography variant="body1" color="textSecondary">{props.text}</Typography>
            </Box>
        </Box>
    );
}

const CardTableStyles = styled.tr`
.padded {
    padding: 5px;
}
.bold {
    font-weight: bold;
}
`

type CapacityRecordProps = {
    operations: string[];
    machineType: string;
    numberOfMachines: number;
    capacity: number;
    minCapacity: number;
    planned: number;
}
const CapacityCard = (props: CapacityRecordProps) => {
    return <div>
        <h3>Capacity</h3>
        <table>
            <tbody>
                <CardTableStyles>
                    <tr>
                        <td className="padded bold">Operations</td>
                        <td className="padded">{props.operations.map(op => <OperationCardButton operationId={op} />)}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Machine Type</td>
                        <td className="padded">{props.machineType}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Number of Machines</td>
                        <td className="padded">
                            {[1, 2, 3].map(m => <Chip size="small" label={`Machine ${m}`} onDelete={() => { }} />)}
                        </td>
                    </tr>
                    <tr>
                        <td className="padded bold">Min. Operational Capacity</td>
                        <td className="padded">
                            <LinearProgressWithLabel color="secondary" text={`${props.minCapacity} / ${props.capacity}`} variant="determinate" value={
                                props.capacity > 0 ?
                                    100 * props.minCapacity / props.capacity
                                    : 0} />
                        </td>
                    </tr>
                    <tr>
                        <td className="padded bold">Planned Capacity</td>
                        <td className="padded">
                            <LinearProgressWithLabel color="secondary" text={`${props.planned} / ${props.capacity}`} variant="determinate" value={
                                props.capacity > 0 ?
                                    100 * props.planned / props.capacity
                                    : 0} />
                        </td>
                    </tr>
                </CardTableStyles>
            </tbody>
        </table>
    </div>
}

const InventryCard = (props: { openning: number, totalDemand: number, items: string[], timeBucketId: string }) => {
    const data = useSelector(getSupplyChainData)
    const openingMin = Math.min(props.openning, props.totalDemand)
    return <>
        <h3>Inventory Status</h3>
        <Typography variant="body2">At start of the time range</Typography>
        <br />
        <table>
            <tbody>
                <CardTableStyles>
                    <tr>
                        <td className="padded bold">Location</td>
                        <td className="padded">Central W/H</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Openning Stock</td>
                        <td className="padded">{props.openning}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Remaining Qty at start</td>
                        <td className="padded">{props.totalDemand - openingMin}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Completed Qty at start</td>
                        <td className="padded">
                            <LinearProgressWithLabel color="secondary" text={`${openingMin} / ${props.totalDemand}`} variant="determinate" value={
                                props.totalDemand > 0 ?
                                    100 * openingMin / props.totalDemand
                                    : 0} />
                        </td>
                    </tr>
                </CardTableStyles>
            </tbody>
        </table>
        <div style={{ marginTop: '20px' }}>
            {props.items.map(item => {

                return <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box mr={1}>
                        <div>
                            Item: <b>{item}</b>
                        </div>
                        <div>
                            Openning Stock: <b>{getOpenningStockForTimeBucket(data, props.timeBucketId, item)}</b>
                        </div>
                    </Box>
                    <Box minWidth={35}>
                        <Button size="small" variant="contained" color="primary" >View Details</Button>
                    </Box>
                </Box>
            })}
        </div>
    </>
}


const LossCard = (props: { selectedItems: string[] }) => {
    const data = useSelector(getSupplyChainData)

    const relatedSalesLossRecords = data.sales_loss.filter(row => props.selectedItems.indexOf(row.item_id) >= 0)
    const salelossItemIds = _.uniq(relatedSalesLossRecords.map(r => r.item_id))
    const lossByItemIdCost = _.chain(data.sales_loss).groupBy(row => `${row.time_bucket_id}_${row.item_id}`).mapValues(rows => rows[0].quantity).value()
    const infItemRecords = data.items.filter(item => salelossItemIds.indexOf(item.id) >= 0)
    const relatedRoutes = data.routings.filter(r => salelossItemIds.indexOf(r.item_id))

    const relatedItemsTimeBuckets = data.item_time_bucket.filter(item => salelossItemIds.indexOf(item.item_id) >= 0)
    const relatedPlannedRecords = data.planned.filter(item => salelossItemIds.indexOf(item.item_id) >= 0)
    const relatedCapacityRecords = data.capacity.filter(item => relatedRoutes.some(r => r.operation_id == item.operation_id))
    const totalDemand = _.sumBy(relatedItemsTimeBuckets, v => v.demand)
    const totalCapacity = _.sumBy(relatedCapacityRecords, v => v.quantity)
    const totalPlanned = _.sumBy(relatedPlannedRecords, v => v.quantity)
    const totalLoss = _.sumBy(relatedItemsTimeBuckets, row => (lossByItemIdCost[`${row.time_bucket_id}_${row.item_id}`] || 0) * row.selling_price)
    const categories = _.uniq(infItemRecords.map(r => r.category_codes))
    const salesLossQty = _.sumBy(relatedSalesLossRecords, row => row.quantity)



    return <div>
        <h3 style={{ color: "red" }}>Infeasible Items</h3>
        <br />
        <table>
            <tbody>
                <CardTableStyles>
                    <tr>
                        <td className="padded bold">Categories</td>
                        <td className="padded">{categories.map(c => <Chip size="small" label={c} onDelete={() => { }} />)}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Infeasible Items</td>
                        <td className="padded">{salelossItemIds.map(c => <Chip size="small" label={c} onDelete={() => { }} />)}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Total Demand</td>
                        <td className="padded">{totalDemand}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Total Capacity</td>
                        <td className="padded">{totalCapacity}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Total Planned</td>
                        <td className="padded">{totalPlanned}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Sales Loss Qty</td>
                        <td className="padded">{salesLossQty}</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Sales Loss</td>
                        <td className="padded">{totalLoss} LKR</td>
                    </tr>
                    <tr>
                        <td className="padded bold">Possible Reasons</td>
                        <td className="padded">Not enough RM <br/> Inadequate capacity</td>
                    </tr>
                    {/* <tr>
                        <td className="padded bold">Completed Qty at start</td>
                        <td className="padded">
                            <LinearProgressWithLabel color="secondary"
                                text={`${openingMin} / ${props.totalDemand}`}
                                variant="determinate" value={
                                    props.totalDemand > 0 ?
                                        100 * openingMin / props.totalDemand
                                        : 0} />
                        </td>
                    </tr> */}
                </CardTableStyles>
            </tbody>
        </table>
    </div>
}


type OperationCardButtonProps = {
    operationId: string
}
const OperationCardButton = ({ operationId }: OperationCardButtonProps) => {
    const [isOpen, setOpen] = useState(false)
    const onClose = () => setOpen(false)
    const onOpen = () => setOpen(true)

    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const data = useSelector(getSupplyChainData)

    const relatedItems = useMemo(() => _.uniq(data.routings.filter(r => r.operation_id == operationId).map(r => r.item_id)), [operationId, data])
    useEffect(() => {
        setSelectedItems(relatedItems)
    }, [relatedItems])

    const handleChange = (item: string) => {
        if (selectedItems.indexOf(item) >= 0) {
            setSelectedItems(selectedItems.filter(v => v != item))
        } else {
            setSelectedItems([...selectedItems, item])
        }
    }


    return <>
        <Button onClick={onOpen} size="small" variant="contained" color="primary"  >{operationId}  &nbsp; <EditIcon /> </Button>
        <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
            <DialogActions>
                <Button onClick={onClose} style={{ float: 'right' }} variant="text"><CloseIcon /></Button>
            </DialogActions>
            <DialogTitle>
                <Typography variant="h5">
                    {operationId}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ overflowX: 'scroll' }}>
                Operational Items: <br /> <br />
                {relatedItems.map(item => {
                    return <div key={item}><label>
                        <input
                            type="checkbox"
                            checked={selectedItems.indexOf(item) >= 0}
                            onChange={() => handleChange(item)}
                        />
                        <span> {item} </span></label><br /></div>
                })}
            </DialogContent>
        </Dialog>
    </>
}