import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  Datagrid,
  DateInput,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  usePermissions,
} from "react-admin";
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
} from "react-admin";
import { cloneElement, useMemo } from "react";
import CustomDateField from "../../components/field/CustomDateField";
import { theme } from "../../layout/theme";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../components/ChangeDiv"

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const ChangeLogFilter = (props) => {
  const { permissions } = usePermissions();

  return (
    <Filter {...props}>
      <TextInput label="Search by changes" source="changes" alwaysOn />
      <TextInput label="Search by user" source="user" alwaysOn />
      <TextInput label="Search by plan board" source="plan_board" alwaysOn />
      {["ADMIN", "SITE"].includes(permissions?.access_level) ? (
        <TextInput label="Search by plant" source="plant" alwaysOn />
      ) : null}
      <DateInput label="Search by time" source="time_stamp" alwaysOn />
    </Filter>
  );
};

const Title = ({ record }) => {
  return <span>Change Log</span>;
};

const ChangeLogExpanded = ({ id, record, resource }) => {
  const data = record["changes"];
  return (
    <div>
      <Typography>Changes:</Typography>
      {data.map((d) => (
        <div key={d[0]}>
          <Tooltip title="changed orders ">
            <Typography>{`${d[0]}: ${d[1]} (${
              d[2] || "no notes"
            })`}</Typography>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const Actions = (props) => {
  const { permissions } = usePermissions();
  const { className, exporter, filters, maxResults, ...rest } = props;
  const classes = useStyles();
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar
      className={classes.buttonContainer}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
          className: "btn_",
        })}
      {/* <div
        style={{
          // borderStyle: 'solid',
          // borderColor: theme.palette.primary.main,
          // borderRadius: 5,
          // borderWidth: 0.5,
          // maxHeight: 30,
          padding: 0,
        }}
      > */}
      <ExportButton
        title="Click here to Export Change Log"
        disabled={!permissions?.permissions.includes("changeLog_export")}
        className="btn_ btn_primary "
      />
      {/* </div> */}
    </TopToolbar>
  );
};

export const ChangeLog = (props) => (
  <List
    {...props}
    filters={<ChangeLogFilter />}
    title={<Title />}
    sort={{ field: "time_stamp", order: "DESC" }}
    perPage={25}
    bulkActionButtons={false}
    actions={<Actions />}
  >
    
      <ChangeDiv>
        {props => {
          return <Datagrid
          rowClick="edit"
          isRowSelectable={(record) => false}
          expand={ChangeLogExpanded}
        >
          <TextField source="id" />
          {/* <ChangesField source="changes" /> */}
          <CustomDateField source="time_stamp" showTime />
          <TextField label="User" source="user_name" sortBy="user__username" />
          <ReferenceField
            link=""
            label="Plan board"
            source="plan_board"
            reference="planBoards"
          >
            <TextField source="code" />
          </ReferenceField>
        </Datagrid>
        }}
      </ChangeDiv>
  </List>
);
