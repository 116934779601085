import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  EditButton,
  Filter,
  SelectInput,
  ReferenceInput,
  List,
  ReferenceField,
  TextField,
  NumberField,
  TextInput,
  usePermissions,
  BulkDeleteButton,
  downloadCSV,
  CardActions,
} from "react-admin";
import {
  TopToolbar,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
} from "react-admin";
import CreateButton from "../../components/CustomCreateButton";
import { cloneElement, Fragment } from "react";
import Confirmation from "../../components/Confirmation";
import CustomDateField from "../../components/field/CustomDateField";
import { useAutoScroll } from "../../hooks/useScroll";
import { format_to_date, format_to_date_time } from "../../lib/date_time";
import { CustomToolbar } from "../../components/CustomToolbar";
import _ from "lodash"

import { CustomDateFieldModified } from "../../components/field/CoustomeDateFieldModified";
import { vi } from "date-fns/locale";
import { Popover, Button } from "@material-ui/core";
import KeyValueField from "../../components/field/KeyValueField";
import { makeStyles } from "@material-ui/core/styles";
import { DriveEta } from "@material-ui/icons";
import ListStringJoinField, { stringJoinWIthStars } from "../../components/field/ListStringJoinFiedl";
import jsonExport from 'jsonexport/dist';
import LinkField from "../../components/field/LinkField";
import {ChangeDiv} from "../../components/ChangeDiv"


const typeFormatters = {
  "text": v => v,
  "link": v => v,
  "stringlist": stringJoinWIthStars,
  "number": parseInt,
  "date": format_to_date,
  "bool": v => v ? "Yes" : "No"
}

const get_exporter = columns => posts => {
  const visibleColumns = columns.filter(col => col.visible)

  const postsForExport = posts.map(post => {
    return _.chain(visibleColumns).keyBy(col => col.label).mapValues(col => {
      // console.log(col.type, typeFormatters[col.type])
      const formatter = typeFormatters[col.type] || typeFormatters["text"]
      return formatter(post[col.source])
    }).value()
  });
  jsonExport(postsForExport, {
    headers: visibleColumns.map(col => col.label) // order fields in the export
  }, (err, csv) => {
    downloadCSV(csv, 'order item plan'); // download as 'posts.csv` file
  });
};


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: 0,
  },
  buttonContainer: {
    display: "flex",
    // width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const CustomField = ({ source, record = {}, fields }) => {
  let obj = record[source];

  fields.forEach((f) => (obj = obj ? obj[f] : null));
  return <span>{obj || "N/A"}</span>;
};

const DemandFilter = (props) => {
  const classes = useStyles();
return(
  <div className={classes.root}>
  <Filter title="Click here to add filter" {...props}>
    <TextInput label="Search by Item" source="item" alwaysOn />
    <TextInput label="Search by Order" source="order" alwaysOn />
    <TextInput label="Release Number" source="order_release_number" alwaysOn />
    <ReferenceInput
      link=""
      label="Plant"
      source="plant"
      reference="plants"
    >
      <SelectInput optionText="name" resettable />
    </ReferenceInput>
    <SelectInput label="Order State"
      initialValue={false}
      choices={[
        { id: true, name: 'Closed Orders' },
        { id: false, name: 'Open Orders' },
        { id: undefined, name: 'Both' },
      ]} source="order_is_closed" />
    <SelectInput label="Groups"
      initialValue={"-"}
      choices={[
        { id: 'groupby_order_group', name: 'Order Group' },
        { id: '-', name: 'Orders' },
      ]} source="groupby"  />
    <SelectInput label="RM"
      initialValue={false}
      choices={[
        { id: false, name: 'Do not include RM' },
        { id: true, name: 'RM Only' },
        { id: undefined, name: 'Both' },
      ]} source="is_rm"  />

    <SelectInput label="Operations"
      initialValue={false}
      choices={[
        { id: false, name: 'Only Planned Operations' },
        { id: true, name: 'Only Unplanned Operations' },
        { id: undefined, name: 'Both' },
      ]} source="is_not_planned"  />
    {/* <BooleanInput label="Is Final" source="is_final" /> */}
  </Filter>
  </div>
)};


const SupplyTime = ({ source, record = {} }) => {
  const formated_date = format_to_date_time(record.supply_time); // if data not passed to this function then return "Invalid date"
  return (
    <>
      <div
        style={{
          backgroundColor:
            record.required_time < record.supply_time ? "#fc8079" : "#d7ff80",
          padding: formated_date === "Invalid date" ? " " : "3px ",
          border: formated_date === "Invalid date" ? " " : "2px solid white ",
          color: "#fff",
        }}
      >
        <Tooltip
          placement="top"
          title={record.required_time < record.supply_time ? "Delay" : ""}
        >
          <Typography variant="body2" gutterBottom>
            {formated_date === "Invalid date" ? "" : formated_date}
          </Typography>
        </Tooltip>
      </div>
    </>
  );
};

const Actions = (props) => {
  const { permissions } = usePermissions();
  const { className, exporter, filters, maxResults, columnData, ...rest } = props;
  const { columns, setColumns } = columnData
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer} {...sanitizeListRestProps(rest)}>
        <CardActions>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
          className: "btn_",
        })}
      <CreateButton
        basePath={basePath}
        title="Click here to create demands"
        disabled={!permissions?.permissions.includes("demands_create")}
      />
      <ColumnSelector columns={columns} setColumns={setColumns} />
      <ExportButton
        className="btn_ btn_primary"
        title="Click here to Export Demands "
        disabled={!permissions?.permissions.includes("demands_export")}
      />
      </CardActions>
      </div>
   
  );
};

export const Demand = (props) => {
  const { permissions } = usePermissions();
  const [columns, setColumns] = useState(defaultColumns)
  const classes = useStyles();

  const BulkActionButtons = (props) => (
    <div className={classes.root}>
      {/* default bulk delete action */}
      <BulkDeleteButton
        className="btn_ btn_delete "
        title="Click here to delete demands"
        disabled={!permissions?.permissions.includes("demands_bulkDelete")}
        {...props}
      />
    </div>
  );

  const autoScroll = useAutoScroll(props);
  return (
    <div
      style={{
        height: "100%",
        //width: '93%',
        //overflow: 'auto',
        //marginTop: 10
      }}
    >
      {/* {columns.filter(col => col.visible).map(col => <div>{col.source}</div>)} */}
      <List
        {...props}
        perPage={25}
        actions={<Actions columns={columns} columnData={{ columns, setColumns }} />}
        bulkActionButtons={<BulkActionButtons />}
        filters={<DemandFilter />}
        filterDefaultValues={{ order_closed: false, groupby: '-', is_rm: false, is_not_planned: false }}
        exporter={get_exporter(columns)}
      >

        <ChangeDiv>
          {props =>{
              return <Datagrid>
              {columns.map(renderColumn)}
            </Datagrid>
          }}
          
          </ChangeDiv>
      </List>
    </div>
  );
};


const renderColumn = (column) => {
  const { type, label, source, visible, sort, link } = column
  const props = {
    label: label || sourceToTitle(source),
    source,
    sortable: !!sort,
  }
  if (!visible) {
    return null;
  }
  else if (type == "text") {
    return <TextField {...props} />
  }
  else if (type == "number") {
    return <NumberField {...props} options={{ maximumFractionDigits: 1 }} />
  } else if (type == "date") {
    return <CustomDateField {...props} />
  } else if (type == "bool") {
    return <BooleanField {...props} />
  } else if (type == "keyvalue") {
    return <KeyValueField {...props} />
  } else if (type == "stringlist") {
    return <ListStringJoinField {...props} />
  } else if (type == "link") {
    return <LinkField {...props} getLink={link} />
  }
  return null

}


const ColumnSelector = ({ columns, setColumns }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeColumnState = (source) => {
    const newColumns = columns.map(col => col.source == source ? ({ ...col, visible: !col.visible }) : col)
    setColumns(newColumns)
  }

  return <div>
    <Button variant="contained"
    style={{whiteSpace: 'nowrap'}}
      className="btn_ btn_primary"
      color="primary" onClick={handleClick}>Select Columns</Button>
    <Popover
      id="rvsn"
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div style={{ padding: 10 }}>
        {columns.map(r => {
          return <div key={r.source} style={{ width: '100%' }}>
            <div >
              <input
                type="checkbox"
                checked={r.visible}
                onChange={() => changeColumnState(r.source)}
              />
              <span> {r.label || sourceToTitle(r.source)} </span> <br />
            </div>
          </div>
        })}
      </div>
    </Popover>
  </div>
}

const sourceToTitle = (txt = "") => txt.replaceAll(".", " ➡️ ").split(/[\s_]+/).map(txt => txt[0].toUpperCase() + txt.slice(1)).join(" ")
/*

Deamnd:

Demand Operation:
  : 

 * 
 */


const keys = ["label", "source", "type", "visible", "sort", "link"]
const all_fields = [
  ["Order Group", "order_group_code", "text", true, false],
  ["Order", "order_code", "stringlist", true, false],
  ["Order Need Date", "order_need_date", "date", true, true],
  ["Release Number", "order_release_number", "text", true, false, false],
  ["Order Qty", "order_quantity", "text", true, false],
  ["Plan Board", "plan_board_code", "link", true, false, record => `#/planBoard/${record.plan_board_id}`],
  ["RM Ready", "rm_ready_date", "date", true, false],
  ["Skipped Qty", "skipped_quantity", "number", true, false],
  ["Planned Qty", "planned_quantity", "number", true, false],
  ["Completed Qty", "completed_quantity", "number", true, false],
  ["Plan Start", "planned_start_date", "date", true, true],
  ["Plan End", "planned_end_date", "date", true, true],
  ["Need Date", "need_date", "date", true, true],
  ["Configuration", "configuration", "text", true, false],
  ["Item", "item_code", "stringlist", true, false],

  ["Skipped", "order_skipped", "bool", false, false],
  ["Closed", "order_closed", "bool", false, false],
  ["Order Plant", "order_plant_code", "text", false, false],
  ["Is New", "order_is_new", "bool", false, false],
  ["Order Resolved", "order_resolved", "bool", false, false],
  ["Order Item", "order_item_code", "text", false, false],
  ["Order Confrm Date", "order_confirmation_date", "date", false],
  ["Operation", "operation_code", "stringlist", false, false],
  ["Week Number", "order_week_number", "text", false, false],
  ["Id", "id", "number", false, false],
  ["Required Quantity", "demand_quantity", "number", false, false], /* Demand Qty */
  ["Quality OK", "is_quality_ok", "bool", false, false],
  ["Is Final", "is_final", "bool", false, false],
  ["Workstation", "workstaion_code", "text", false, false],
  ["Planable Qty", "plan_quantity", "number", false, false],
  ["Is transfer", "is_transfer", "bool", false, false],
  ["Item Unit", "item_unit", "text", false, false],

  //Past
  // ["", "order.order_group.id", "number"],
  // ["", "order.order_group.rm_ready_date", "date"],
  // ["", "order.id", "number"],
  // ["", "order.is_dummy", "bool"],
  // ["", "order.resolve_failed", "bool"],
  // ["", "order.resolve_failed_reason", "text"],
  // ["Completed Quantity", "completed_quantity", "number", false, true, true],
  // ["", "initial_required_time", "date"],
  // ["", "required_time", "date", true, false, "required_time"],
  // ["", "force_supply_time", "date", false, false, false],
  // ["", "supply_time", "date", false, false, false],
  // ["Is Planned", "demand_operation.is_planned", "bool", false, false, false],
  // ["", "demand_operation.required_time_min", "date"],
  // ["", "demand_operation.attributes", "keyvalue"],
  // ["", "demand_operation.depth", "number"],
  // ["", "item.id", "number"],
  // ["", "item.attributes", "keyvalue", false, false, false],
]

// Convers into objects. It's easier to handle in the componet
const defaultColumns = all_fields.map(row => _.reduce(keys, (obj, k, i) => ({ ...obj, [k]: row[i] }), {}))
// const columns = all_fields.map(row => row[0])