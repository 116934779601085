import _, { get } from "lodash";
import { createSelector } from "reselect";
import { IPlan } from "../../lib/plan";
import {
  demand_operation_allocation_selector,
  demand_operation_group_allocation_selector,
  same_config_allocation_selector,
  single_allocation_selector,
} from "../../lib/plan/allocation_selectors/selectors";
import {
  demand_operation_group_selector,
  demand_operation_selector,
} from "../../lib/plan/demand_operation_selectors/selectors";
import { get_active_allocations } from "../../lib/plan/helpers";
import { RootReducerType } from "../reducers";
import { PlanBoardStateType } from "../reducers/planBoardReducer";
import { CREATE_ALLOCATIONS } from "../types";

type State = { custom: RootReducerType };

export const getPlan = (state: State) => state.custom.plan.current || {};
export const getPlanBoardState = (state: State) =>
  state.custom.planBoard as PlanBoardStateType;
const getLinesObject = createSelector(
  [getPlan],
  (plan: IPlan) => plan.lines || {}
);
export const getBucketsObject = createSelector(
  [getPlan],
  (plan: IPlan) => plan.buckets || {}
);
export const getToolsObject = createSelector(
  [getPlan],
  (plan: IPlan) => plan.tools || {}
);
export const getGroupsObject = createSelector(
  [getPlan],
  (plan: IPlan) => plan.groups || {}
);
export const getAllocationsObject = createSelector(
  [getPlan],
  (plan: IPlan) => plan.allocations || {}
);
export const getDemandOperationsObject = createSelector(
  [getPlan],
  (plan: IPlan) => plan.demand_operations || {}
);

// Undo redo
export const canUndo = (state: any) => state.custom.plan.past.length > 0;
export const canRedo = (state: any) => state.custom.plan.future.length > 0;

// Plan Board Relted
export const getLines = createSelector([getLinesObject], (linesObject) =>
  Object.values(linesObject).sort(
    (a, b) => a.sequence_number - b.sequence_number
  )
);
export const getIsPlanLoading = createSelector(
  [getPlanBoardState],
  (planBoard) => planBoard.loading.active
);
export const getIsPlanBackgroundLoading = createSelector(
  [getPlanBoardState],
  (planBoard) => planBoard.loading.background
);
export const getIsPlanning = createSelector(
  [getPlanBoardState],
  (planBoard) => planBoard.planning.isPlanning
);
export const getPlanningProgress = createSelector(
  [getPlanBoardState],
  (planBoard) => planBoard.planning.progress
);
export const getSelection = createSelector(
  [getPlanBoardState],
  (planBoard) => planBoard.selection
);
export const getPendingAction = createSelector(
  [getPlanBoardState],
  (planBoard) => planBoard.pendingAction
);
export const getSelectedTimeRange = createSelector(
  [getPlanBoardState],
  (planBoard) => planBoard.timeRange
);
export const getSelectedDemandOperationId = createSelector(
  [getSelection, getAllocationsObject],
  (selection, allocaitonsObject) => {
    if (selection?.type === "demand_operations") return selection.id;
    else if (selection?.type === "allocations")
      return allocaitonsObject[selection.id]?.demand_operation_id;
  }
);
export const getSelectedLineIndex = createSelector(
  [getLines, getSelection, getAllocationsObject],
  (lines, selection, allocationsObj) => {
    if (!selection) {
      return -1;
    }
    let line_id = null as any;
    if (selection.type === "demand_operations") {
      const allocs = Object.values(allocationsObj).filter(
        (a) => a.demand_operation_id === selection.id
      );
      line_id = allocs[0]?.plan_board_line_id;
    }
    if (selection.type === "allocations") {
      line_id = allocationsObj[selection.id]?.plan_board_line_id;
    }
    return lines.map((l) => l.id).indexOf(line_id);
  }
);

// Plan Related
export const getEntity = (
  state: any,
  type: Exclude<keyof IPlan, "planboard">,
  id: any
) => getPlan(state)[type][id as number];
export const getPlanBoard = createSelector(
  [getPlan],
  (plan: IPlan) => plan.planboard
);
export const getAllocations = createSelector([getPlan], (plan) =>
  plan.allocations ? get_active_allocations(plan) : []
);
export const getAllocationsInSelectedTimeRangeOrSelected = createSelector(
  [getAllocations, getSelectedTimeRange, getSelection],
  (allocs, { start_time, end_time }, selection) => {
    return allocs.filter((alloc) => {
      const withinSelectedRange =
        alloc.end_time.valueOf() > start_time &&
        alloc.start_time.valueOf() < end_time;
      const isSelected = selection && alloc.id == selection.id;
      return withinSelectedRange || isSelected;
    });
  }
);

export const getAllocationsByBucket = createSelector(
  [getAllocations],
  (allocations) =>
    _.chain(allocations)
      .groupBy((a) => a.line_bucket_id)
      .mapValues((allocs) => _.sortBy(allocs, (a) => a.start_time))
      .value()
);

export const getDemandOperations = createSelector(
  [getDemandOperationsObject],
  (obj) => Object.values(obj)
);
export const getTools = createSelector([getToolsObject], (obj) =>
  Object.values(obj)
);
export const getBuckets = createSelector(
  [getBucketsObject, getSelectedTimeRange],
  (obj, { start_time, end_time }) =>
    Object.values(obj).filter(
      (b) => b.end_time.valueOf() > start_time && b.start_time < end_time
    )
);

export const getBucketsNoDateRange = createSelector([getBucketsObject], (obj) =>
  Object.values(obj)
);
export const getColorMappings = createSelector([getPlanBoard], (planBoard) => {
  if (!planBoard) {
    return {};
  }
  const colors = planBoard.algorithm_config.colors || [];
  const colorMappings = {} as any;
  colors.forEach(([code, color]: string[]) => (colorMappings[code] = color));
  return colorMappings;
});
export const getSelectedEntity = createSelector(
  [getSelection, getPlan],
  (selection, plan: IPlan) => {
    const selection_type = selection?.type;
    const id = selection?.id;
    return selection ? get(plan, `${selection_type}.${id}`) : null;
  }
);
export const getIsEditing = createSelector(
  [getPlanBoardState],
  (s) => s.editing
);
export const getIsUserCanEdit = createSelector(
  [getPlanBoardState],
  (s) => s.editing === "user"
);
export const getGroups = createSelector([getGroupsObject], (groupsObject) =>
  Object.values(groupsObject)
);
export const getSelectedGroupId = createSelector(
  [getPlanBoardState],
  (s) => s.selectedGroupId
);
export const getActiveRevisionId = createSelector(
  [getPlanBoardState],
  (s) => s.activeRevision.id
);
export const getPublishedRevisionId = createSelector(
  [getPlanBoard],
  (planBoard) => planBoard.published_revision_id
);
export const getChangedOrders = createSelector(
  [getPlanBoardState],
  (planBoard) => planBoard.changedOrderCodes.current || {}
);
export const getChangedOrderCodes = createSelector(
  [getChangedOrders],
  (changeOrders) => Object.keys(changeOrders)
);

export const getIsPlanBoardModelOpen = createSelector(
  [getPlanBoardState],
  (planBoard) => planBoard.model.open
);
/*
1. Demand Operations by filter
2. Allocations by bucket
3. Buckets start time range
4. line
5. line groups
6. plan board
 */
export const getPendingActionOptions = createSelector(
  [getPendingAction, getPlan],
  (pending_action: any, plan: IPlan) => {
    const allocationOptions = [
      {
        mode: "allocation",
        title: "Allocation",
        description: "(Only this allocation will be processed)",
        selector: single_allocation_selector,
      },
      {
        title: "Order",
        mode: "demand_operation",
        description: "(Order of the selected allocation will be processed)",
        selector: demand_operation_allocation_selector,
      },
      {
        title: "Order Group",
        mode: "order_group",
        description:
          "(Entire order group of the selected allocation will be processed)",
        selector: demand_operation_group_allocation_selector,
      },
      {
        title: "Same Configuration",
        mode: "same_config",
        description: "Same configuration allocations",
        selector: same_config_allocation_selector,
      },
    ] as any[];

    const demandOperationOptions = [
      {
        title: "Order",
        mode: "demand_operation",
        description: "(Order of the selected allocation will be processed)",
        selector: demand_operation_selector,
      },
      {
        title: "Order Group",
        mode: "order_group",
        description:
          "(Entire order group of the selected allocation will be processed)",
        selector: demand_operation_group_selector,
      },
    ] as any[];

    const getRelatedOptions = () => {
      if (pending_action.type === CREATE_ALLOCATIONS) {
        return demandOperationOptions;
      }
      const allocation_id = pending_action?.payload?.allocation_id;
      const demandOperationId =
        plan.allocations[allocation_id].demand_operation_id;
      if (demandOperationId) {
        return allocationOptions.filter(
          (option) => option.mode !== "same_config"
        );
      }
      return allocationOptions.filter(
        (option) => option.mode === "same_config"
      );
    };

    const relatedOptions = getRelatedOptions();

    const id =
      pending_action.type === CREATE_ALLOCATIONS
        ? pending_action.payload.demand_operation_id
        : pending_action.payload.allocation_id;

    const options = relatedOptions.map((option: any) => {
      const parts = option.selector(plan, id);
      const quantity = _.sum(parts.map((v: any) => v.quantity));
      return { ...option, quantity: quantity };
    });

    return options;
  }
);
