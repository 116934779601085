import Excel from "exceljs";
import { saveAs } from "file-saver";
import { DATE_FORMAT_EXCEL, DATE_TIME_FORMAT_EXCEL, format_reverse_oder_date, format_to_date_time } from "./date_time";
import { PLAN_EXPORT_COLUMNS as plan_export_columns } from "../constants";
import moment from "moment";

//font sizes
const mainHeaderFontSize = 11;
const timeRangeFontSize = 11;
const fontSize1 = 8;
// const fontSize2 = 9;
const columnsCount = 13;

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//column order
// const exportColumns = [
//   "head",
//   "Mold",
//   "Order Code",
//   "Release number",
//   "Item Code",
//   "Quantity",
//   "Unit",
//   "Start Time",
//   "P.S.D.",
//   "RM Ready Date",
//   "Need Date(max)",
//   "Operation Code",
//   "SMV",
//   "Total shift quantity",
//   "Shifts",
//   "Planned O. G. S. D.",
//   "Planned O. G. E. D.",
//   "P. O. G. S. D. - 1",
//   "P. O. G. E. D. + 1",
//   "Efficiency",
//   "Carder",
//   "Shift Time (H)"
// ];

//map column widths
const columnWidths = {
  "Item Code": 15,
  Mold: 10,
  "Order Required Date": 15,
  "Need Date(max)": 15,
  "Order Code": 15,
  "P.S.D.": 17,
  "P.E.D.": 17,
  Quantity: 8,
  "RM Ready Date": 15,
  "Start Time": 23,
  Unit: 6,
  head: 8,
  "End Time": 23,
  "Operation Code": 15,
  Shifts: 8,
  "Total shift quantity": 15,
  "Release number": 13,
  SMV: 7,
  "P. O. G. S. D. - 1": 16,
  "P. O. G. E. D. + 1": 16,
  "Planned O. G. S. D.": 17,
  "Planned O. G. E. D.": 17,
};

//map colors
const colorMappings = {
  B: "FFCCCCCC",
  BE: "FFF5F5DC",
  DB: "FFFFE4B5",
  PI: "FFFFC0CB",
  R: "FFFF7F50",
  W: "FFF8F8FF",
};


const numFormats = {
  "bucket_start_time": DATE_FORMAT_EXCEL,
  "Order Required Date": DATE_FORMAT_EXCEL,
  "DELIVERY": DATE_FORMAT_EXCEL,
  "bucket_start_time": DATE_FORMAT_EXCEL,
  "Order Required Date": DATE_FORMAT_EXCEL,
  "P.S.D.": DATE_FORMAT_EXCEL,
  "RM Ready Date": DATE_FORMAT_EXCEL,
  "Order Required Date": DATE_FORMAT_EXCEL,
  "Need Date(max)": DATE_FORMAT_EXCEL,
  "Planned O. G. S. D.": DATE_FORMAT_EXCEL,
  "Planned O. G. E. D.": DATE_FORMAT_EXCEL,
  "P. O. G. S. D. - 1": DATE_FORMAT_EXCEL,
  "P. O. G. E. D. + 1": DATE_FORMAT_EXCEL,
  "Start Time": DATE_TIME_FORMAT_EXCEL,
}

const dsiPlanExport = async (data, time, planBoard) => {
  const exportColumns = planBoard.plan_export_columns.filter(a => plan_export_columns.DSI.includes(a));

  const columnsCount = exportColumns.length;

  var workbook = new Excel.Workbook();
  workbook.creator = "Auto Generated by Stack Schedular";
  var worksheet = workbook.addWorksheet("plan", {
    views: [{ showGridLines: true }],
  });
  //main header
  worksheet.getRow(1).height = 42.5;
  worksheet.mergeCells("A1", `${alphabet[columnsCount - 1]}1`);
  worksheet.getCell("A1").value = `PLAN - ${planBoard.code}`;
  worksheet.getCell("A1").font = {
    name: "Verdana",
    size: mainHeaderFontSize,
    bold: true,
  };
  worksheet.getCell("A1").border = {
    top: { style: "thick" },
    left: { style: "thick" },
    bottom: { style: "thick" },
    right: { style: "thick" },
  };
  worksheet.getCell("A1").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("A1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFFFFF00" },
  };

  const timeRange = `from : ${format_reverse_oder_date(
    time.start
  )} to : ${format_reverse_oder_date(time.end)}`;

  //time range
  worksheet.mergeCells("A2", "D2");
  worksheet.getRow(2).height = 20;
  worksheet.getCell("A2").value = timeRange;
  worksheet.getCell("A2").font = {
    name: "Verdana",
    size: timeRangeFontSize,
    bold: true,
  };

  //table headers
  const headers = exportColumns;
  worksheet.getRow(4).values = headers;
  worksheet.lastRow.eachCell(
    { includeEmpty: true },
    function (cell, cellNumber) {
      if (cellNumber < columnsCount + 1) {
        if (!cell.value) {
          cell.value = "";
        }
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF9999FF" },
        };
        cell.font = {
          name: "Verdana",
          size: fontSize1,
        };
      }
    }
  );
  worksheet.columns = headers.map((i) => ({
    key: i,
    width: columnWidths[i] || 8,
    style: numFormats[i] ? { numFmt: numFormats[i] } : {}
  }));

  //table data
  const machine = ["initial"];
  var j = 0;
  data.map((O, index) => {
    const newMachine = O.head.split(".")[0].split(":")[0];
    //Line group row
    if (machine[0] !== newMachine) {
      machine.pop();
      machine.push(newMachine);
      worksheet.addRow(machine);
      j = 0;
      var i;
      for (i = 0; i < columnsCount; i++) {
        worksheet.lastRow.getCell(i + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCCCCFF" },
        };
        worksheet.lastRow.getCell(i + 1).font = {
          name: "Verdana",
          size: fontSize1,
        };
      }
    }
    // Fix datetime utc becuse excell js has an issue with the timezone when executed. 
    //https://github.com/exceljs/exceljs/issues/486#issuecomment-432557582
    Object.keys(numFormats).forEach(k => {
      const date = O[k]
      const isDate = typeof date?.getMonth === 'function'
      if(isDate){
        O[k] = new Date( Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()))
      }
    })
    //Allocations
    if (O["Order Code"]) {
      worksheet.addRow(O);
      worksheet.lastRow.eachCell(function (cell, cellNumber) {
        cell.font = {
          name: "Verdana",
          size: fontSize1,
        };
      });
      if (j % 2 === 0) {
        for (i = 0; i < columnsCount; i++) {
          worksheet.lastRow.getCell(i + 1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFEEEEFF" },
          };
        }
      }
      worksheet.lastRow.getCell(2).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: colorMappings[O.Mold.split(":")[0]] },
      };
      //Mold changes
    } else {
      O["Order Code"] = "SWITCHING";
      O["Item Code"] = O["Mold"];
      O["Mold"] = data[index - 1] ? data[index - 1]["Mold"] : " ";
      O["Quantity"] = " ";
      worksheet.addRow(O);
      worksheet.lastRow.eachCell(function (cell, cellNumber) {
        cell.font = {
          name: "Verdana",
          size: fontSize1,
          italic: true,
          bold: true,
        };
      });
      worksheet.lastRow.getCell(2).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: colorMappings[O.Mold.split(":")[0]] },
      };
      if (j % 2 === 0) {
        for (i = 0; i < columnsCount; i++) {
          worksheet.lastRow.getCell(i + 1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFEEEEFF" },
          };
        }
      }
      worksheet.lastRow.getCell(2).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: colorMappings[O.Mold.split(":")[0]] },
      };
    }
    j = j + 1;
  });

  //add thin borders to all table cells
  worksheet.eachRow(function (row, rowNumber) {
    if (rowNumber > 3) {
      var i;
      for (i = 1; i < columnsCount - 1; i++) {
        row.getCell(i + 1).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
        };
      }

      //first cell in a row
      row.getCell(1).border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
      };

      //last cell in a row
      row.getCell(columnsCount).border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    }
  });

  //end text
  worksheet.addRow([""]);
  worksheet.addRow([`Timestamp: ${format_to_date_time(new Date())}`]);
  worksheet.addRow([`Export type: ${planBoard.export_type}`]);

  worksheet.addRow([`Export SMV unit: ${planBoard.export_smvunit}`]);
  worksheet.addRow(["Auto generated by Stack Schedular"]);

  //export excel
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";
  const filename = `plan ${format_reverse_oder_date(
    time.start
  )}-${format_reverse_oder_date(time.end)}`;
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, filename + fileExtension);
};

export default dsiPlanExport;
