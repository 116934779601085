import React, { useEffect } from 'react'
import { useSortBy, useTable } from "react-table"
import styled from 'styled-components'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { FixedSizeList } from 'react-window'
import update from 'immutability-helper'
import { Button } from '@material-ui/core'

interface Props {
    data: any[]
    headers: string[],
    onUpdate?: (data: any) => void
}


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
`


// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}: any) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    const onChange = (e: any) => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return <input disabled={!updateMyData} value={value} onChange={onChange} onBlur={onBlur} />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}

const Table = ({ headers, data, onUpdate }: Props) => {
    const [records, setRecords] = React.useState([] as any[])
    const columns = React.useMemo(
        () => headers.map(h => ({ Header: h, accessor: h })),
        [headers, data]
    )


    useEffect(() => {
        const addCustomIdField = (row: any, id: number) => {
            return { ...row, _id: id }
        }
        setRecords(data.map(addCustomIdField))
    }, [data])

    const getRowId = React.useCallback(row => {
        return row._id
    }, [])

    const updateMyData = onUpdate && ((index: number, columnId: string, value: any) => {
        const updatedRecords = records.map((r, i) => i == index ? ({ ...r, [columnId]: value }) : r)
        onUpdate(updatedRecords)
    })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        data: records,
        defaultColumn,
        columns,
        getRowId,
        updateMyData
    } as any,
        useSortBy
    )

    const moveRow = (dragIndex: number, hoverIndex: number) => {
        const dragRecord = records[dragIndex]
        setRecords(
            update(records, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            })
        )
    }


    const downloadData = () => {
        const element = document.createElement('a')
        const headersText = headers.join(",")
        const content = data.map(row => headers.map(h => row[h]).join(", ")).join("\n")
        const text = headersText + "\n" + content
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
        element.setAttribute('download', `data.csv`)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }

    return (
        <Styles>
            <DndProvider backend={HTML5Backend}>
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={downloadData} > Download</Button>
                </div>
                <table {...getTableProps({style: {width: '100%'}})}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {/* <th></th> */}
                                {headerGroup.headers.map((column: any) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(
                            (row, index) => {
                                prepareRow(row)
                                return (
                                    <Row
                                        index={index}
                                        row={row}
                                        moveRow={moveRow}
                                        {...row.getRowProps()}
                                    />
                                )
                            }
                        )}
                    </tbody>
                </table>
            </DndProvider>
        </Styles>
    )
}

const DND_ITEM_TYPE = 'row'

const Row = ({ row, index, moveRow }: any) => {
    const dropRef = React.useRef(null as any)
    const dragRef = React.useRef(null)

    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item: any, monitor) {
            if (!dropRef.current) {
                return
            }
            // dropRef = dropRef as any;
            const dragIndex = (item as any).index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = (clientOffset?.y || 0) - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: DND_ITEM_TYPE, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)

    return (
        <tr ref={dropRef} style={{ opacity }}>
            {/* <td ref={dragRef}>move</td> */}
            {row.cells.map((cell: any) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
        </tr>
    )
}

export default Table
// const App = () => {
//     const columns = React.useMemo(
//         () => [
//             {
//                 Header: 'ID',
//                 accessor: 'id',
//             },
//             {
//                 Header: 'Name',
//                 columns: [
//                     {
//                         Header: 'First Name',
//                         accessor: 'firstName',
//                     },
//                     {
//                         Header: 'Last Name',
//                         accessor: 'lastName',
//                     },
//                 ],
//             },
//             {
//                 Header: 'Info',
//                 columns: [
//                     {
//                         Header: 'Age',
//                         accessor: 'age',
//                     },
//                     {
//                         Header: 'Visits',
//                         accessor: 'visits',
//                     },
//                     {
//                         Header: 'Status',
//                         accessor: 'status',
//                     },
//                     {
//                         Header: 'Profile Progress',
//                         accessor: 'progress',
//                     },
//                 ],
//             },
//         ],
//         []
//     )

//     const data = React.useMemo(() => makeData(20), [])

//     return (
//         <Styles>
//             <Table columns={columns} data={data} />
//         </Styles>
//     )
// }

// export default App
