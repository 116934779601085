import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "./constants";
import { LightenDarkenColor } from "./lib/shadeColors";

ReactDOM.render(<App />, document.getElementById("root"));

var bodyStyles = document.body.style;
bodyStyles.setProperty("--primary-color", PRIMARY_COLOR);
bodyStyles.setProperty("--secondary-color", SECONDARY_COLOR);
bodyStyles.setProperty(
  "--table-row-color",
  LightenDarkenColor(PRIMARY_COLOR, 0.65)
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
