import React from "react";
import ReadyMadeUppersSampleRequestForm from "./ReadyMadeUppersSampleRequestForm";
import NewDevelopmentInquirySheetForFlipFlops from "./NewDevelopmentInquirySheetForFlipFlops";
import SoleSampleRequestForm from "./SoleSampleRequestForm";
import TrimsSampleRequestForm from "./TrimsSampleRequestForm";
import FabricSampleRequestForm from "./FabricSampleRequestForm";
import InkSampleRequestForm from "./InkSampleRequestForm";
import LeatherSampleRequestForm from "./LeatherSampleRequestForm";
import CompoundSampleRequestForm from "./CompoundSampleRequestForm";
import EXP from "./DSS/EXP";
import ANSL from "./DSS/ANSL";
import SampleRequisition from "./DSS/Sample-Requisition";
import TechnicalReviewForm from "./TechnicalReviewForm";
import OTB from "./OTB";

import RiskAnalysisForm from "./RiskAnalysisForm";
import PVCPUSampleRequestForm from "./PVCPUSampleRequestForm";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

export const Forms = ({ task }) => {
  const [form, setForm] = React.useState("");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setForm(newValue);
  };

  const forms = [
    "RiskAnalysisForm",
    "ReadyMadeUppersSampleRequestForm",
    "NewDevelopmentInquirySheetForFlipFlops",
    "SoleSampleRequestForm",
    "TrimsSampleRequestForm",
    "FabricSampleRequestForm",
    "InkSampleRequestForm",
    "LeatherSampleRequestForm",
    "CompoundSampleRequestForm",
    "EXP",
    "ANSL",
    "OTB",
    "SampleRequisition",
    "TechnicalReviewForm",
    "PVCPUSampleRequestForm",
  ];

  const formsMapping = {
    'I-TRM': ["TechnicalReviewForm"],
    'I-MDDS': [
      "ReadyMadeUppersSampleRequestForm",
      "NewDevelopmentInquirySheetForFlipFlops",
      "SoleSampleRequestForm",
      "TrimsSampleRequestForm",
      "FabricSampleRequestForm",
      "InkSampleRequestForm",
      "LeatherSampleRequestForm",
      "CompoundSampleRequestForm",
      "PVCPUSampleRequestForm",
    ],
    'I-IA': ["DSS", "Ansel", "EXP", "OTB",],
    'I-RA': ["RiskAnalysisForm"],
  };

  const toHumanReadable = (str) => {
    if (str.toUpperCase() === str) {
      return str
    } else {
      var words = str.match(/[A-Za-z][a-z]*/g) || [];
      return words.join(" ");
    }
  };


  return (
    <div>
      {formsMapping[task.task_code] ? (
        <>
          <Typography>
            Select form
            <Select
              value={form}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              style={{ padding: "5px" }}
            >
              {formsMapping[task.task_code].map((form) => (
                <MenuItem value={form}>{toHumanReadable(form)}</MenuItem>
              ))}
            </Select>
          </Typography>
          {form === "RiskAnalysisForm" && (
            <RiskAnalysisForm formName={form} />
          )}
          {form === "ReadyMadeUppersSampleRequestForm" && (
            <ReadyMadeUppersSampleRequestForm task={task} formName={form} />
          )}
          {form === "NewDevelopmentInquirySheetForFlipFlops" && (
            <NewDevelopmentInquirySheetForFlipFlops task={task} formName={form} />
          )}
          {form === "SoleSampleRequestForm" && (
            <SoleSampleRequestForm task={task} formName={form} />
          )}
          {form === "TrimsSampleRequestForm" && (
            <TrimsSampleRequestForm task={task} formName={form} />
          )}
          {form === "FabricSampleRequestForm" && (
            <FabricSampleRequestForm task={task} formName={form} />
          )}
          {form === "InkSampleRequestForm" && (
            <InkSampleRequestForm task={task} formName={form} />
          )}
          {form === "LeatherSampleRequestForm" && (
            <LeatherSampleRequestForm task={task} formName={form} />
          )}
          {form === "CompoundSampleRequestForm" && (
            <CompoundSampleRequestForm task={task} formName={form} />
          )}
          {form === "EXP" && <EXP formName={form} />}
          {form === "Ansel" && <ANSL formName={form} />}
          {form === "DSS" && <SampleRequisition formName={form} />}
          {form === "TechnicalReviewForm" && (
            <TechnicalReviewForm formName={form} />
          )}
          {form === "OTB" && <OTB formName={form} />}
          {form === "PVCPUSampleRequestForm" && <PVCPUSampleRequestForm task={task} formName={form} />}
        </>
      ) : (
        <Typography>*No forms for this task.</Typography>
      )}
    </div>
  );
};

export default Forms;
