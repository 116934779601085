import React from "react";
import { MainView } from "./MainView";

export const TimeStamp = () => {
  return (
    <>
      {/* <h3>Time stamps</h3> */}

      <MainView />
    </>
  );
};
