import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

import { SaveButton, DeleteButton, Toolbar, usePermissions } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../../components/Confirmation";
import { NewConfirmationButton } from "../../../components/NewConfermationButon";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { ReqInputField } from "../../../components/ReqInputField";
import { useCustomToast } from "../../../hooks/useCustomToast";


export const EditReason = (props) => {
  const { permissions } = usePermissions();

  const validateReason = (values) => {
    const errors = {};
    if (!values.reason) {
      errors.reason = "Reason is required";
    }
    if (!values.text) {
      errors.text = "Text is required";
    }
    return errors;
  };


  const Title = ({ record }) => {
    return <span>Edit Reason | {record ? `${record.reason ?? ""} ` : ""}</span>;
  };
  const customToast = useCustomToast(props);

  return (
    <div className="col-md-6">
      <Edit title={<Title />} {...props}
        onSuccess={customToast.onSuccess}
        onFaliure={customToast.onFaliure}>
        <SimpleForm
          validate={validateReason}
          toolbar={<CustomToolbar name="changeReasons" text="Reason" />}
        >
          <TextInput source="reason" label={<ReqInputField name="Reason" />} />
          <TextInput source="text" label={<ReqInputField name="Text" />} />
        </SimpleForm>
      </Edit>
    </div>
  );
};
