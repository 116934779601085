import { Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useDataProvider } from "react-admin";
import { showToast } from "../../lib/toas";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
}));
const params = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: "id", order: "ASC" },
};

const SelectedReasonInput = ({ order, onChange }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [list, setList] = React.useState(["No options found "]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [input, setInput] = React.useState("");
  const [isSet, setIsSet] = React.useState(true);
  const [isInputChange, setIsInputChange] = React.useState(false);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getOptions = () => {
    if (list.length < 0) {
      return ["No options found  "];
    }

    const options = list.map(({ reason }) => reason);
    return options;
  };
  const options = getOptions();

  React.useEffect(() => {
    onChange(order, options[selectedIndex], input);
  }, [anchorEl, isInputChange]);

  React.useEffect(() => {
    dataProvider
      .getList("changeReason", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
      })
      .then(({ data }) => {
        setList(data);
      })
      .catch((error) => {
      });
  }, []);

  //const onClick =()

  return (
    <>
      <Typography variant="body1" display="block" gutterBottom>
        {" "}
        {order}
      </Typography>

      <div className={classes.root}>
        <List
          component="nav"
          aria-label="Device settings"
          style={{ alignContent: "space-between", width: "50%" }}
        >
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="when input is empty"
            onClick={handleClickListItem}
          >
            <ListItemText
              primary="Select option"
              secondary={options[selectedIndex]}
              style={{ width: "50" }}
            />
          </ListItem>
        </List>

        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              // disabled={index === 0}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
        <TextField
          id="standard-basic"
          onChange={(e) => setInput(e.target.value)}
          label="Note"
        />
        {input && isSet ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsInputChange(true);
              setIsSet(false);
              showToast("Note Saved !");
            }}
            style={{
              maxWidth: "50px",
              maxHeight: "30px",
              minWidth: "50px",
              minHeight: "30px",
            }}
          >
            Apply
          </Button>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default SelectedReasonInput;
