export const SET_DATE_RANGE = "SET_DATE_RANGE"
export const SET_SECTION = "SET_SECTION"

export const LOAD_OPERATION_LOSSES = "LOAD_OPERATION_LOSSES"
export const LOAD_OPERATION_LOSS_SUCCESS = "LOAD_OPERATION_LOSS_SUCCESS"
export const LOAD_OPERATION_LOSS_FAILED = "LOAD_OPERATION_LOSS_FAILED"
export const SET_SHIFT_PATTERNS = "SET_SHIFT_PATTERNS"

export const SET_FORMATTED_OPERATION_LOSS = "SET_FORMATTED_OPERATION_LOSS"

export const DELETE_OPERATION_LOSS = "DELETE_OPERATION_LOSS"
export const DELETE_OPERATION_LOSS_SUCCESS = "DELETE_OPERATION_LOSS_SUCCESS"
export const DELETE_OPERATION_LOSS_FAILED = "DELETE_OPERATION_LOSS_FAILED"

export const UPDATE_SHIFT_PATTERN = "UPDATE_SHIFT_PATTERN"
export const UPDATE_SHIFT_PATTERN_SUCCESS = "UPDATE_SHIFT_PATTERN_SUCCESS"
export const UPDATE_SHIFT_PATTERN_FAILED = "UPDATE_SHIFT_PATTERN_FAILED"

export const CREATE_OPERATION_LOSS = "CREATE_OPERATION_LOSS"
export const CREATE_OPERATION_LOSS_SUCCESS = "CREATE_OPERATION_LOSS_SUCCESS"
export const CREATE_OPERATION_LOSS_FAILED = "CREATE_OPERATION_LOSS_FAILED"