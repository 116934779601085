import React, { useState } from "react";
import FormUploadConfirmationDialog from "../FormUploadConfirmationDialog";
import Button from "@material-ui/core/Button";
import { useDataProvider, useQuery, useMutation } from "react-admin";
import { formsSchema } from './dropDownForm/helpers'
import _ from 'lodash'


const NewDevelopmentInquirySheetForFlipFlops = ({task, prevFormData, toEdit=false, appendParentCode=false, prevFormName, formName }) => {
  const [formData, setFormData] = useState({ descriptionTable: [], descriptionTableSS: [] });
  const [openFormUploadDialog, setOpenFormUploadDialog] = useState(false);
  const [fieldData, setFieldData] = useState([]);
  // const [image, setImage] = useState('')
  const dataProvider = useDataProvider();



  const form_details = {
    tId: 'I-MDDS',
    fId: 3
  }

  const { loading, error } = useQuery(
    {
      type: "TNA_CUSTOM_LIST",
      resource: "itemTasks",
      payload: {
        action: "getFormData",
        method: "GET",
        query: { task_id: task.id, form_name: formName },
      },
    },
    {
      onFailure: (e) => {
        console.log("FormData Retrieval Failed:" + e.body);
        if (toEdit) {
          const newData = Object.assign(formData, prevFormData);
          setFormData(newData)
        }
      },
      onSuccess: (response) => {
        if (toEdit) {
          console.log(prevFormData)
          const newData = Object.assign(formData, prevFormData);
          setFormData(newData)
        } else if (response.data) {
          const newData = Object.assign(formData, response.data.savedFormData);
          setFormData(newData);
        }
      },
    }
  );



  const getDropdownList = async (tid, fid, feid) => {
    try {


      const res = await dataProvider.getList("tna/dropdown", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        actions: "1/filter_data",
      });
      const data = res.data.filter((details) => details.code != null && details.code === "I-MDDS")
      const fieldsData = data.filter((r) => r.details.form.id === fid && r.code === tid)
      const fieldsArray = fieldsData.map(({ details }) => details.form.fields)
      console.log(fieldsArray)
      const newarray = []
      // console.log(flatArray(fieldsArray))
      fieldsArray.forEach((details, id) => {
        // console.log(details)
        details.forEach(element => {
          const inputs = element.inputs.values
          const fid = element.id
          newarray.push({ inputs, fid })
        });




      });

      // console.log(newarray)
      const fData = formsSchema.filter(({ code }) => code ===
        form_details.tId)[0].data.filter(({ id }) => form_details.fId === id)

      const mapper = fData[0].fields.values.map((data) => {
        const id = data.id
        const name = data.name
        return newarray.map((res) => {
          const inputs = res.inputs
          if (res.fid === data.id) {
            return { name, inputs }
          }
          return []
        })
      })

      const na = []
      for (let index = 0; index < mapper.length; index++) {
        const element = mapper[index];
        for (let index = 0; index < element.length; index++) {
          const el = element[index];
          if (!_.isEmpty(el)) {
            na.push(el)
          }

        }

      }
      setFieldData(mapper)

    } catch (error) {
      return []
    }

  }
  React.useEffect(() => {
    getDropdownList(form_details.tId, form_details.fId)
  }, [])


  const flatArray = () => {


    const na = []
    for (let index = 0; index < fieldData.length; index++) {
      const element = fieldData[index];
      for (let index = 0; index < element.length; index++) {
        const el = element[index];
        if (!_.isEmpty(el)) {
          na.push(el)
        }

      }

    }
    return na

  }

  const mapInputs = (n) => {
    return flatArray().filter((data) => {
      if (data.name === n) {
        return data.inputs
      }
    })
  }



  const options = {
    scale: 0.8,
  };

  const onChange = (event, field) => {
    const id = event.target.name;
    const type = event.target.type;

    const newFormData = formData;
    if (type === "checkbox") {
      newFormData[field] = !formData[field];
      setFormData(newFormData);
    } else {
      const value = event.target.value;

      newFormData[field] = value;
      setFormData(newFormData);
    }
  };
  const getFirstElementOFArray = (c) => mapInputs(c)[0]?.inputs[0]

  const fieldsArray1 = [
    "Abrasion",
    "Compression set",
    "Flexing",
    "Cell 1",
    "Cell 2",
    "Cell 3",
    "Split-top layer",
    "Sole print",
    "V-Strap print",
    "foot bed",
    "Side wall finishing",
    "REACH",
    "Phthalte free",
    "PAH",
    "Buyer compliance",
    "FSC",
    "Quality level",
  ]
  // console.log(getFirstElementOFArray("Abrasion"))

  const addRowTable = (inputProps, isUI) => {
    const setRowData = (newValue) => {
      setFormData((formData) => ({ ...formData, descriptionTable: newValue }));
    };
    const rowData = formData.descriptionTable;

    const headers = [
      "Sample No",
      "Component",
      "Design/ Type",
      "Size/ Area",
      "Colour",
      "Drawing",
      "Qty for Sampling",
      "Reference/ CODE",
      "Material States",
    ];

    const tArray = [
      "Component",
      "Design/ Type",
      "Material States",
    ];

    const onAddRowClick = () => {
      setRowData(rowData.concat(rowData.length + 1));
    };

    const removeRow = (row) => {
      const newRowData = rowData;
      rowData[row] = false;
      setRowData(newRowData);
    };

    const midHeaders = [
      "Design/ Type",
      "Component",
      "Material States",
    ]

    const resizeImage = (base64Str, maxWidth = 200, maxHeight = 200) => {
      return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = async () => {
          let canvas = document.createElement('canvas')
          const MAX_WIDTH = maxWidth
          const MAX_HEIGHT = maxHeight
          let width = img.width
          let height = img.height

          height *= MAX_WIDTH / width
          width   = MAX_WIDTH

          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL())
        }
      })
    }

    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(resizeImage(fileReader.result));
        }
        fileReader.onerror = (error) => {
          reject(error);
        }
      })
    }

    const uploadImage = async (row, header, event) => {
      const file = event.target.files[0]
      const base64 = await convertBase64(file)
      const newFormData = formData

      newFormData[`${row}${header}`] = base64
      // setImage(prevDict => ({ ...prevDict, [row]: base64 }))
      setFormData(newFormData);

    }


    return (
      <>
        <tr>
          {headers.map((header, index) => (
            <th class='form-th' style={
              header === "Reference/ CODE"
                ? { backgroundColor: "#f5d142" }
                : {}
            }>{header}</th>
          ))}
        </tr>
        {rowData.map(
          (row, index) =>
            <>
              {row && (
                <tr>
                  {headers.map((header) => (
                    <td class='form-td' style={
                      header === "Reference/ CODE"
                        ? { backgroundColor: "#f5d142" }
                        : {}
                    }>
                      {header === "Drawing"
                        ? <div class='form-img' size="20">

                          {isUI && <input
                            type="file"
                            name="file"
                            placeholder="Upload an image"
                            onChange={(event) => uploadImage(row, header, event)}
                            size="50"
                            accept="image/png, image/jpeg"
                            style={{ width: '18', color: "transparent" }}
                           required={isUI ? formData[`${row}${header}`] ? false : true: false}
                           disabled={isUI ? appendParentCode : false}
                          />}
                          {(
                            <div size="20" style={{ "text-align": "center" }}>
                              <img src={formData[`${row}${header}`]} alt="" style={{
                                objectFit: 'contain',
                                margin: "auto",
                              }} />
                            </div>
                          )}
                        </div>
                        : header === "Reference/ CODE"
                          ? <input class='form-input' size="8" style={{ backgroundColor: "#f5d142" }}
                            {...inputProps(formData, `${row}${header}`)} required={appendParentCode} disabled={false}></input>
                          // :
                          : tArray.includes(header) ? isUI ? <select {...inputProps(formData, `${row}${header}`)} >
                            {mapInputs(header)[0]?.inputs.length > 0 && mapInputs(header)[0]?.inputs.map((data) =>
                              <option value={data}>{data}</option>)}</select> :
                            <input class='form-input'
                              size="7"
                              value={
                                formData[`${row}${header}`] ??
                                getFirstElementOFArray(header)
                              }
                              {...inputProps(formData, `${row}${header}`)}></input> : <input
                              class="form-input"
                              size="16"
                              {...inputProps(formData, `${row}${header}`)}
                            ></input>}

                    </td>
                  ))}
                  <td style={{ border: 'none !important' }}>
                    <button
                    disabled={isUI ? appendParentCode: false}
                      onClick={() => removeRow(index)}
                      style={{
                        borderRadius: 5,
                        borderColor: "transparent",
                        margin: 0,
                      }}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              )}
            </>
        )}
        <tr>
          <td style={{ border: 'none !important' }} colspan={headers.length}>
            <button
            disabled={isUI ? appendParentCode: false}
              onClick={onAddRowClick}
              style={{
                borderRadius: 5,
                borderColor: "transparent",
                margin: 0,
              }}
            >
              Add Row
            </button>
          </td>
        </tr>
      </>
    );
  };

  const addRowSoleSheetTable = (inputProps, isUI) => {
    const setRowData = (newValue) => {
      setFormData((formData) => ({ ...formData, descriptionTableSS: newValue }));
    };
    const rowData = formData.descriptionTableSS;

    const headers = [
      "EVA Insole",
      "Thickness",
      "Hardness",
    ];

    const fakeArray = [
      "Cell 1", "Cell 2", "Cell 3"
    ]

    const onAddRowClick = () => {
      setRowData(rowData.concat(rowData.length + 1));
    };

    const removeRow = (row) => {
      const newRowData = rowData;
      rowData[row] = false;
      setRowData(newRowData);
    };

    return (
      <>
        {rowData.map(
          (row, index) =>
            <>
              {row && (
                <tr>
                  {fakeArray.map((header) => (

                    <td class='form-td'>

                    {fieldsArray1.includes(header) ? (
                      isUI ? (
                        <select {...inputProps(formData, `${row}${header}`)}>
                          {mapInputs(header)[0]?.inputs.length > 0 &&
                            mapInputs(header)[0]?.inputs.map((data) => (
                              <option value={data}>{data}</option>
                            ))}
                        </select>
                      ) : (
                        <input
                          class="form-input"
                          value={
                            formData[`${row}${header}`] ??
                            getFirstElementOFArray(header)
                          }
                          {...inputProps(formData, `${row}${header}`)}
                        ></input>
                      )
                    ) : (
                      <input
                        class="form-input"
                        {...inputProps(formData, `${row}${header}`)}
                      ></input>
                    )}

                    </td>
                  ))}
                  <td style={{ border: 'none !important' }}>
                    <button
                      onClick={() => removeRow(index)}
                      style={{
                        borderRadius: 5,
                        borderColor: "transparent",
                        margin: 0,
                      }}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              )}
            </>
        )}
        <tr>
          <td style={{ border: 'none !important' }} colspan={headers.length}>
            <button
              onClick={onAddRowClick}
              style={{
                borderRadius: 5,
                borderColor: "transparent",
                margin: 0,
              }}
            >
              Add Row
            </button>
          </td>
        </tr>
      </>
    );
  };

  const uiInputProps = (formData, id) => {
    const props = {
      required: true,
      defaultValue: formData[id],
      disabled: appendParentCode,
      // checked: formData[id],
      onChange: (e) => {
        onChange(e, id);
      },
    //   key: formData[id],
    //   id: id,
    //   name: id,
    }

    if (id.toLowerCase().includes("date")) {
      props['type'] = 'date'
    }

    return props
  }

  const uploadInputProps = (formData, id) => {
    if (formData[id]) {
      return {
        value: formData[id],
        // checked: true,
        // key: formData[id],
        // id: id,
        // name: id,
      };
    }
  };

  const validateCode = () => {
    let isValid = true
    if (appendParentCode) {
      for (let i of formData.descriptionTable) {
        if (i && !formData[`${i}Reference/ CODE`]) {
          alert("Please Fill the Reference/CODE Header before downlaoding the PDF")
          isValid = false
        }
      }
    }
    return isValid
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() && validateCode()) {
      setOpenFormUploadDialog(true);
      if (!appendParentCode) {
        saveAutofillData();
      }
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const tables = {
    sections: {
      1: [
            ["Designer:", "Date:", "Form No: R/ Form"],
            ["Inquiry No:", "Buyer:", "Date of issue:"],
            ["Sample No:", "Sample type:", "Date of review:"],
            ["Size range:", "Sample size:", "Revision no:"],
            ["Cluster", "Country:"],
          ],
      2: ["Sample submission date:", "Material need date"],
      3: ["Target price", "Abrasion", "Compression set", "Flexing"],
      4: ["Cutter type:", "Vstrap:"],
      5: ["Cutter size", "Sole size", "V-strap size"],
      6: ["Target price", "Quality level"],
      7: ["Split-top layer", "Sole print", "V-Strap print", "foot bed", "Side wall finishing"],
      8: ["REACH", "Phthalte free", "PAH", "Buyer compliance", "FSC"],
      9: ["Prepared by:", "Approved by:"]
    },
  };

  const [mutate, { onSuccess, onFaliure }] = useMutation();

  const saveAutofillData = () => {
    const shouldAutofillField = new Set([
      ...tables.sections[1].flat(),
      ...tables.sections[2].flat(),
    ]);
    const notAutofill = new Set(["Form No: R/ Form", "Date of issue:", "Date of review:", "Revision no:"])
    const dropdownFields = new Set(fieldsArray1);
    const toAutoFill = {};
    for (let property in formData) {
      if (shouldAutofillField.has(property) && !dropdownFields.has(property) && !notAutofill.has(property)) {
        toAutoFill[property] = formData[property];
      }
    }
    mutate(
      {
        type: "TNA_CUSTOM_ACTION",
        resource: "itemTasks",
        payload: {
          action: "saveFormAutofill",
          method: "POST",
          body: {
            formData: toAutoFill,
            task_id: task.id,
            form_name: formName 
          },
        },
      },
      {
        onFailure: (e) => {
          console.log(e);
        },
        onSuccess: (e) => {
          console.log("Autofill Submission Successful");
        },
      }
    );
  };

  const Form = ({ formData, inputProps, isUI = true }) => {
    return (
      <div>
        <table>
          <tr>
            <th class='form-th' colspan="8">
              <h3>
                <u>New Development Inquiry Sheet for Flip Flops</u>
              </h3>
            </th>
            <th class='form-th' colspan="4">
              <h1>DSI</h1>
            </th>
          </tr>
          {tables.sections[1].map((row) => (
            <tr>
              {row.map((column) => (
                <>
                  <th class='form-th' colspan="2">{column}</th>
                  <td class='form-td' colspan="2">
                    <input class='form-input' {...inputProps(formData, column)}></input>
                  </td>
                </>
              ))}
            </tr>
          ))}{" "}
          <tr style={{ backgroundColor: "#24AE2B" }}>
            {tables.sections[2].map((column) => (
              <>
                <th class='form-th' colspan="3">{column}</th>
                <td class='form-td' colspan="3">
                  <input class='form-input' {...inputProps(formData, column)}></input>
                </td>
              </>
            ))}
          </tr>
        </table>
        <table style={{ "margin-top": "25px" }}>
          {addRowTable(inputProps, isUI)}
        </table>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "50px" }}>
            <table>
              <tr>
                <th class='form-th' colspan="3" style={{ textAlign: "center" }}>
                  Sole Sheet Properties
                </th>
              </tr>
              {tables.sections[3].map(
                (column) => (
                  <tr>
                    <th class='form-th'>{column}</th>
                    <td class='form-td' colspan="2">
                    {fieldsArray1.includes(column) ? (
                  isUI ? (
                    <select {...inputProps(formData, `SP-${column}`)}>
                      {mapInputs(column)[0] &&
                        mapInputs(column)[0]?.inputs.map((data) => (
                          <option value={data}>{data}</option>
                        ))}
                    </select>
                  ) : (
                    <input
                      class="form-input"
                      value={formData[`SP-${column}`] ?? getFirstElementOFArray(column)}
                      {...inputProps(formData, `SP-${column}`)}
                    ></input>
                  )
                ) : (
                  <input
                    class="form-input"
                    {...inputProps(formData, `SP-${column}`)}
                  ></input>
                )}
                    </td>
                  </tr>
                )
              )}
              <tr>
                <th class='form-th'></th>
                <th class='form-th'>Thickness</th>
                <th class='form-th'>Hardness</th>
              </tr>
              {addRowSoleSheetTable(inputProps, isUI)}
            </table>

            <table class='form-brtable'>
              {tables.sections[4].map((row) => (
                <tr>
                  <th class='form-th'>{row}</th>
                  <td class='form-td' colspan="2">
                    <input class='form-input' {...inputProps(formData, `a${row}`)}></input>
                  </td>
                </tr>
              ))}
            </table>
          </div>

          <div style={{ width: "50%" }}>
            <table class='form-brtable'>
              <tr>
                {tables.sections[5].map((column) => (
                  <th class='form-th'>{column}</th>
                ))}
              </tr>
              {_.range(1,8).map((row) => (
                <tr>
                  {tables.sections[5].map(
                    (column) => (
                      <td class='form-td' >
                        <input class='form-input'
                          {...inputProps(formData, `${row}${column}`)}
                        ></input>
                      </td>
                    )
                  )}
                </tr>
              ))}
            </table>
            <table class='form-brtable'>
              <tr>
                <th class='form-th' colspan="3" style={{ textAlign: "center" }}>
                  V Strap Properties
                </th>
              </tr>
              {tables.sections[6].map((column) => (
                <tr>
                  <th class='form-th'>{column}</th>
                  <td class='form-td' colspan="2">
                  {fieldsArray1.includes(column) ? (
                  isUI ? (
                    <select {...inputProps(formData, `VSP-${column}`)}>
                      {mapInputs(column)[0] &&
                        mapInputs(column)[0]?.inputs.map((data) => (
                          <option value={data}>{data}</option>
                        ))}
                    </select>
                  ) : (
                    <input
                      class="form-input"
                      value={formData[`VSP-${column}`] ?? getFirstElementOFArray(column)}
                      {...inputProps(formData, `VSP-${column}`)}
                    ></input>
                  )
                ) : (
                  <input
                    class="form-input"
                    {...inputProps(formData, `VSP-${column}`)}
                  ></input>
                )}
                  </td>
                </tr>
              ))}
            </table>

            <table class='form-brtable'>
              <tr>
                <th class='form-th' colspan="5" style={{ textAlign: "center" }}>
                  Features in final sample
                </th>
                <th class='form-th' colspan="1" style={{ textAlign: "center" }}>
                  MM
                </th>
              </tr>
              {tables.sections[7].map((column) => (
                <tr>
                  <th class='form-th'>{column}</th>
                  <td class='form-td' colspan="4">
                  {fieldsArray1.includes(column) ? (
                  isUI ? (
                    <select {...inputProps(formData, column)}>
                      {mapInputs(column)[0] &&
                        mapInputs(column)[0]?.inputs.map((data) => (
                          <option value={data}>{data}</option>
                        ))}
                    </select>
                  ) : (
                    <input
                      class="form-input"
                      value={formData[column] ?? getFirstElementOFArray(column)}
                      {...inputProps(formData, column)}
                    ></input>
                  )
                ) : (
                  <input
                    class="form-input"
                    {...inputProps(formData, column)}
                  ></input>
                )}
                  </td>
                  <td class='form-td' colspan="1">
                    <input class='form-input' {...inputProps(formData, `MM${column}`)}></input>
                  </td>
                </tr>
              ))}
            </table>

            <table class='form-brtable'>
              <tr>
                <th class='form-th' colspan="3" style={{ textAlign: "center" }}>
                  Chemical compliance
                </th>
              </tr>
              {tables.sections[8].map(
                (column) => (
                  <tr>
                    <th class='form-th'>{column}</th>
                    <td class='form-td' colspan="2">
                    {fieldsArray1.includes(column) ? (
                  isUI ? (
                    <select {...inputProps(formData, column)}>
                      {mapInputs(column)[0] &&
                        mapInputs(column)[0]?.inputs.map((data) => (
                          <option value={data}>{data}</option>
                        ))}
                    </select>
                  ) : (
                    <input
                      class="form-input"
                      value={formData[column] ?? getFirstElementOFArray(column)}
                      {...inputProps(formData, column)}
                    ></input>
                  )
                ) : (
                  <input
                    class="form-input"
                    {...inputProps(formData, column)}
                  ></input>
                )}
                    </td>
                  </tr>
                )
              )}
            </table>
          </div>
        </div>

        <table class='form-brtable'>
          <tr>
            <th class='form-th' style={{ textAlign: "center" }}>
              Special Construction Details
            </th>
          </tr>
          <tr>
            <td class='form-td' style={{ backgroundColor: "#f5d142" }}>
            {isUI ? (
                <textarea class='form-textarea'
                  {...inputProps(formData, "Special Construction Details")}
                ></textarea>
              ) : (
                <input
                  class="form-input"
                  {...inputProps(formData, "Special Construction Details")}
                ></input>
              )}
            </td>
          </tr>
        </table>
        <table class="form-brtable">
          {tables.sections[9].map((row) => (
            <tr>
              <th class='form-th'>{row}</th>
              <td class='form-td' >
                <input class='form-input' {...inputProps(formData, row)}></input>
              </td>
            </tr>
          ))}
        </table>
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Form formData={formData} inputProps={uiInputProps} />
        <Button style={{marginBlock: 15}} variant="contained" type="submit">
          {appendParentCode ? "Download Copy" : "Submit"}
        </Button>
      </form>
      <FormUploadConfirmationDialog
        isOpen={openFormUploadDialog}
        setIsOpen={setOpenFormUploadDialog}
        Form={Form({ formData, inputProps: uploadInputProps, isUI: false })}
        formData={formData}
        formName={formName}
        prevFormName={prevFormName}
        appendParentCode={appendParentCode}
      />
    </div>
  );
};

export default NewDevelopmentInquirySheetForFlipFlops;
