import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import { tableViewExport } from "./ExcelExport";
import { makeStyles } from "@material-ui/core/styles";
// import classes from "*.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  buttonOne: {
    justifyContent: "flex-start",
  },
  buttonTwo: {
    justifyContent: "flex-end",
  },
}));

export const ExportDialog = ({ isOpen, values, setOpenExportPopup }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenExportPopup(false);
  };

  useEffect(() => {
    if (isOpen) {
      handleClickOpen();
    }
  });

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Table View Export</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {values.length} demand operations are selected to export.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <div className={classes.root}>
            <div className={classes.buttonOne}>
              <Button
                className="btn_ btn_primary"
                onClick={() => {
                  tableViewExport(values);
                  handleClose();
                }}
                // variant="outlined"
                // color="secondary"
              >
                Export
              </Button>
            </div>
            <div className={classes.buttonTwo}>
              <Button className="btn_ btn_primary" onClick={handleClose}>
                Close
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
