import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { format_to_date_for_api } from "../../lib/date_time";
import "react-datepicker/dist/react-datepicker.css";

export const CustomClearableDateField = (props) => {
  const { filterName, text, sortOrder, sortField, className } = props;

  const { filterValues, setFilters, setSort } = props.props;

  const [givenDate, setGivenDate] = useState(null);

  useEffect(() => {
    const formatedDate = format_to_date_for_api(givenDate);

    if (!formatedDate || formatedDate !== "Invalid date") {
      const filter = {
        ...filterValues,
        [filterName]: formatedDate,
      };
      try {
        setFilters(filter, true);
        if (sortOrder && sortOrder) {
          setSort(sortField, sortOrder);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      if (filterValues[filterName]) {
        delete filterValues[filterName];
      }

      setFilters({ ...filterValues }, true);
    }
  }, [givenDate]);

  return (
    <>
      <DatePicker
        className={className}
        selected={givenDate}
        placeholderText={text}
        onChange={(date) => setGivenDate(date)}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        isClearable
      />
    </>
  );
};
