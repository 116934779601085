import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { redirect } from "../../../../lib/redirect";

const useStyles = makeStyles({
  table: {
    maxWidth: 50,
  },
});

export const PopOver = (props) => {
  const classes = useStyles();
  const [payload, setPayload] = React.useState(null);

  React.useEffect(() => {
    if (props.location.state) {
      setPayload(props.location.state.row.order_code);
    } else {
      redirect();
    }
  }, []);

  return (
    <div style={{ marginTop: "20px", marginRight: "20px" }}>
      <Table className={classes.table} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell alignCenter>Oder code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payload &&
            payload.map((code, i) => (
              <TableRow key={i}>
                {" "}
                <TableCell alignCenter component="th" scope="row">
                  {code}
                </TableCell>{" "}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};
