import React from "react";
import { Loading, Query } from "react-admin";
import { format_to_date_time } from "../../../lib/date_time";
import PopupView from "./PoupView";

export const OderCard = ({ prop, dataProp, opArrayProp }) => {
  const [open, setOpen] = React.useState(false);

  const onOpen = (st) => {
    if (typeof st === "boolean") {
      setOpen(st);
    } else {
      return open;
    }
  };


  return (
    <Query type="getOne" resource="orders" payload={{ id: prop }}>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <p>Not found</p>;
        }
        return (
          <>
            {open && (
              <PopupView
                changeOpen={onOpen}
                prop={prop}
                dataProp={dataProp}
                opArrayProp={opArrayProp}
                reqDate={data.required_date}
                dataCode={data.code}
              />
            )}
            <div
              onClick={() => setOpen(true)}
              style={{
                borderWidth: "1px",
                borderColor: "#000000",
                margin: "5px",
              }}
            >
              <p style={{ textAlign: "center" }}>{data.code?data.code:'' } </p>
              <p style={{ textAlign: "center" }}>
                {format_to_date_time(data.required_date)}
              </p>
            </div>
          </>
        );
      }}
    </Query>
  );
};
