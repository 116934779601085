import React, { useEffect, useState } from "react";
import { usePermissions } from "react-admin";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import dataProvider from "../../api/dataProvider";
import { WS_URL } from "../../constants";
import { loadNotifications, refreshView } from "../../store/actions/notificationActions";
import { getLoading, getNotificationCount } from "../../store/selectors/notificationSelectors";




const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
};
const payload = {
  pagination: { page: 1, perPage: 1 },
  sort: { field: "username", order: "ASC" },
};

const PushNotification = ({ currentPath, onRefresh }) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState();
  const [data, setData] = useState();
  const [props, setProps] = useState({ message: null, type: null })

  const dispatch = useDispatch()


  const permissions = usePermissions().permissions?.access_level;
  useEffect(() => {
    dataProvider
      .GET_USER("userProfile", { payload: payload })
      .then((resp) => setUser(resp.data));
  }, []);

  useEffect(() => {
    notificationSocket();
  }, [permissions, user]);

  const checkNotifyType = (obj) => {
    // filter and get type
    // return type
  };

  // const renderAccodingToType = ()=>
  // {
  //    switch ('sucess') {
  //      case 'sucess':

  //        break;

  //      default:
  //        break;
  //    }
  // }

  const notify = () => {
    if (data) {
      const notification = data.notification;
      if (notification.notify_type.type == "transfer") {
        if (notification.notify_type.status == "pending") {
          setProps({ message: "New transfer request!. Click here to setup.", type: "warning", redirectUrl: `#/transfers/received/${notification.plant}` })
        } else if (notification.notify_type.status == "accepted") {
          setProps({ message: "Your transfer request has been accepted!", type: "success", redirectUrl: `#/transfers/transferred/${notification.plant}` })
        } else if (notification.notify_type.status == "declined") {
          setProps({ message: "Your transfer request has been declined! Click here to take action.", type: "error", redirectUrl: `#/transfers/transferred/${notification.plant}` })
        }
        if (currentPath.search("/transfers") !== 1) {
          onRefresh()
        }
      }
    } else {
      return null;
    }
  };

  const onClick = () => {
    if (data && props && props.redirectUrl) {
      window.location = props.redirectUrl
    }
  }

  useEffect(() => {
    if (props.message) {
      toast(props.message, { position: toast.POSITION.BOTTOM_RIGHT, type: props.type, onClick: () => onClick() })
    }
  }, [props])

  useEffect(() => {
    if (data) {
      notify()
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const socketRestart = (event) => {
    setTimeout(() => notificationSocket(), 5000)
  }

  const notificationSocket = () => {
    if (
      user &&
      [
        "ADMIN",
        "SITE",
        "SITE",
        "PLANT",
        "PLANT",
        "SUB"
      ].includes(permissions)
    ) {

      const chatSocket = new WebSocket(
        `${WS_URL}/ws/notification/${permissions}/${user.id}`
      );

      if (chatSocket.readyState == 1) {
        chatSocket.send({ user_permissions: permissions });
      }

      chatSocket.onmessage = function (event) {
        setData(JSON.parse(event.data));
        setOpen(true);
        dispatch(loadNotifications())
      };
      chatSocket.addEventListener('close', socketRestart)
    }
  };
  return (
    <>
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ marginTop: "25px" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="This is a success message!"
      /> */}
      {null}
    </>
  );
};
const mapStateToProps = (state, props) => {
  const loading = getLoading(state);
  const count = getNotificationCount(state);
  const currentPath = state.router.location.pathname
  return {
    loading,
    count,
    currentPath
  };
};

const mapDispatchToProps = {
  onLoad: loadNotifications,
  onRefresh: refreshView
};

export default connect(mapStateToProps, mapDispatchToProps)(PushNotification);
