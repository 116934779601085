import { LOAD_DATA, LOAD_DATA_SUCCESS, REVERT_TRANSFER, SETUP_RECEIVED, SETUP_TRANSFER, SET_CURRENT_PLANT, SET_DATA } from "../types/transferTypes";

export const loadData = (plant: any) => {
    return { type: LOAD_DATA, payload: { plant: plant } }
}


export const loadDataSuccess = () => {
    return { type: LOAD_DATA_SUCCESS } 
}

export const setData = (entities: any) => {
    return { type: SET_DATA, payload: { entities } }
}

export const setupTransfer = (data: any) => {
    return { type: SETUP_TRANSFER, payload: { data }}
}

export const revertTransfer = (data: any) => {
    return { type: REVERT_TRANSFER, payload: { data }}
}

export const setupReceived = (data: any) => {
    return { type: SETUP_RECEIVED, payload: { data }}
}

export const setCurrentPlant = (plant: any) => {
    return { type: SET_CURRENT_PLANT, payload: { plant: plant }}
}
