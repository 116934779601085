import moment from "moment"

export const format_to_date_for_api = datetime => {
    return moment(datetime).format("YYYY-MM-DD")
}

export const format_to_date_time_for_api = datetime => {
    return moment(datetime).format("YYYY-MM-DDThh:mm")
}

export const format_to_date = datetime => {
    if (datetime){
    return moment(datetime).format("DD/MM/YYYY")
    }
    else {
        return " "
    }
}


export const DATE_FORMAT = "DD/MM/YYYY"
export const DATE_TIME_FORMAT = "DD/MM/YYYY, hh:mm A"
export const DATE_FORMAT_EXCEL = "dd/mm/yyyy"
export const DATE_TIME_FORMAT_EXCEL = "dd/mm/yyyy hh:mm:ss\\ AM/PM"

export const format_to_date_time = datetime => {
    return moment(datetime).format(DATE_TIME_FORMAT)
}

export const format_to_time = datetime => {
    return moment(datetime).format("hh:mm a")
}

export const format_duration = millis => {
    return moment.duration(millis).days() + ":" + moment.utc(millis).format("HH:mm");
}

export const getTimestamp = (time) => {
    return moment(time).valueOf()
}

export const getTime = (tiemstamp) => {
    return moment(tiemstamp).toDate()
}
export const format_to_custom_needs = datetime => {
    return moment(datetime).format("YYYY,MM,DD")
}
export const format_reverse_oder_date = datetime => {
    return moment(datetime).format(DATE_FORMAT)
}
export const format_to_twelve = datetime => {
    return moment(datetime,'h:mm a').format("HH:mm")
}
