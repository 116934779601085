import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useWindowSize from "@rehooks/window-size";
import React from "react";
import { AppBar } from "react-admin";
import { connect } from "react-redux";
import NotificationDialog from "../containers/Notification/NotificationDialog";
import PushNotification from "../containers/Notification/PushNotification";
import TNAPushNotifications from "../containers/TNA/notifications/tnaPushNotifications";
import { loadNotifications } from "../store/actions/notificationActions";
import {
  getLoading,
  getNotificationCount,
} from "../store/selectors/notificationSelectors";
import { AppBarLogo } from "./AppBarLogo";
import BackButton from "./BackButton";
import RefreshButton from "./RefreshButton";
import MyUserMenu from "./UserMenu";
import { useSelector, useDispatch } from "react-redux";

import { Layout, Sidebar, setSidebarVisibility } from "react-admin";

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
    height: 50,
  },
  appBar: {
    width: "100%",
    height: 60,
  },
};

const MyAppBar = withStyles(styles)(({ classes, ...props }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, []);
  const { innerWidth, innerHeight } = useWindowSize();
  return (
    <>
      <PushNotification />
      <TNAPushNotifications />
      <AppBar
        color="primary"
        className={classes.appBar}
        {...props}
        userMenu={<MyUserMenu />}
      >
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <AppBarLogo />
        <span className={classes.spacer} />
        <NotificationDialog />
        {/*
        <Badge badgeContent={props.count ? props.count : 0}
          color="secondary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClick={() => props.onLoad}
          style={{ justifyContent: "flexStart", height: 10 }}
        >
          <Notification />
        </Badge>
        */}

        <BackButton />
        <RefreshButton />
      </AppBar>
    </>
  );
});
const mapStateToProps = (state, props) => {
  const loading = getLoading(state);
  const count = getNotificationCount(state);
  return {
    loading: loading ? "true" : "false",
    count,
  };
};

const mapDispatchToProps = {
  onLoad: loadNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAppBar);
