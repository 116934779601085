import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { connect } from "react-redux";
import { getCurrentPlant, getPlants, getSentTransfers } from "../../store/selectors/transferSelectors";
import SetupTransfersTable from "./SetupTransfersTable";
import TransfersTable from "./TransfersTable";
import { usePermissions } from 'react-admin'


const treeData = (ts, dOs) => {


  const treeData = [];
  const _ts = ts.map(transfer =>  ({...transfer, Id: transfer.id, id: `t${transfer.id}`}))
  treeData.push(..._ts)
  const ts_ids = ts.map(t => t.id)
  const _dOs = dOs.filter(dO => ts_ids.includes(dO.transfer_id)).map(dO => ({ ...dO, parentId: `t${dO.transfer_id}`}))
  treeData.push(..._dOs)
  // ts.map(transfer => {
  //   const values = [];
  //   const t = { ...transfer, Id: transfer.id, id: `t${transfer.id}` };
  //   const data = dOs
  //     .filter(dO => transfer.demand_ops.includes(dO.id))
  //     .map(dO => ({ ...dO, parentId: t.id }));
  //   values.push(t);
  //   values.push(...data);
  //   treeData.push(...values);
  // });
  return treeData;
};

const TransferredTab = ({ dOs, plants,  sentTransfers, currentPlant }) => {

  const { permissions } = usePermissions()
  // const filterFunc = dO => {
  //   if (filter === "all") return true;
  //   else if (filter === "sub") return false;
  //   else return dO.plant_id === filter;
  // };

  const filteredData = dOs
  // .filter(dO => filterFunc(dO));
  

  const pendingDOs = filteredData.filter(dO => dO.is_exchange && dO.plant_id == currentPlant);
  const setupDOs = filteredData.filter(dO => dO.transfer_status);
  const transfers = sentTransfers.filter(t => true) // t.status !== 'reverted')
  const transferDOs = treeData(transfers, setupDOs);
  return (
    <>
      {permissions?.permissions.includes('tranferredTab_pendingSetupTable') && (pendingDOs[0] ? (
        <SetupTransfersTable
          setup={true}
          title="Pending setup"
          data={pendingDOs}
          plants={plants}
          // colorMappings={colorMappings} onSelect={onSelect}
        />
      ) : (
        <Card style={{ marginBottom:"10px", marginTop:"10px" }}>
        <Typography style={{ padding: "20px" }} color="secondary">
          *No transfer demand operations to setup or you dont have permissions.
        </Typography>
        </Card>
      ))}
      <Divider />

      <TransfersTable
        setup={false}
        title="Transfers"
        data={transferDOs}
        // colorMappings={colorMappings} onSelect={onSelect}
      />
    </>
  );
};

const mapStateToProps = state => {
  const plants = getPlants(state);
  const sentTransfers = getSentTransfers(state);
  const currentPlant = getCurrentPlant(state)
  return {
    plants,
    sentTransfers,
    currentPlant
  };
};

export default connect(mapStateToProps, null)(TransferredTab);
