import React, { useEffect } from "react";
import {
  useTable,
  useBlockLayout,
  useRowSelect,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { FixedSizeList } from "react-window";

import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import { ColumnSelectorDialog } from "../containers/planBoard/helperComponents/ColumnSelectorDialog";
import { calExp } from "../lib/calculations";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../layout/theme";

const scrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute(
    "style",
    "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;"
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const TableCell = withStyles(
  {
    root: {
      borderBottom: "none",
    },
  },
  { index: 1 }
)(MuiTableCell);

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <TextField
        size="small"
        value={globalFilter || ""}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={`Search all`}
        // style={{ margin: "0px", padding: "0px" }}
        className="ml-2"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const useStyles = makeStyles({
    customTextField: {
      "& input::placeholder": {
        fontSize: "10px",
      },
    },
  });

  const classes = useStyles();
  return (
    <TextField
      classes={{ root: classes.customTextField }}
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search`}
      style={{ margin: "0px", padding: "0px" }}
    />
  );
}

const getColumnWidth = (rows, accessor, headerText, filterType) => {
  const maxWidth = 400;
  const minWidth = filterType === "DateColumnFilter" ? 135 : 115;
  const magicSpacing = 11;
  const cellLength = Math.max(
    ...rows.map((row) => (`${row[accessor]}` || "").length),
    headerText.length,
    minWidth / magicSpacing
  );
  return Math.min(maxWidth, cellLength * magicSpacing);
};

const defaultPropGetter = () => ({});

function Table({
  columns,
  data,
  isOpenSelectorPopup,
  setOpenSelectorPopup,
  setSelectedIds,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps,
  attributes,
  headerContent = null,
  window_width,
  window_height,
  setRangeFilters,
}) {
  // Use the state and functions returned from useTable to build your UI

  const columnsWithWidth = React.useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        width: column.width
          ? column.width
          : getColumnWidth(data, column.accessor, column.Header, column.filterType),
      })),
    [columns, data]
  );

  const defaultColumn = React.useMemo(
    () => ({
      width: 40,
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // Or, override the default text filter to use
      // "startWith"
      startsWith: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    totalColumnsWidth,
    prepareRow,
    allColumns,
    selectedFlatRows,
    visibleColumns,
    state: { selectedRowIds },
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
  } = useTable(
    {
      columns: columnsWithWidth,
      data,
      defaultColumn,
      filterTypes,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useBlockLayout,
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox
              {...getToggleAllRowsSelectedProps()}
              style={{ margin: "0px", padding: "0px" }}
            />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              style={{ margin: "0px", padding: "0px" }}
            />
          ),
        },
        ...columns,
      ]);
    }
  );

  const [selectColumns, setSelectColumns] = React.useState({});

  useEffect(() => {
    const initialSelectColumns = {};
    if (attributes[0]) {
      allColumns.map((column, index) => {
        initialSelectColumns[column.Header] =
          attributes.includes(column.Header) || column.Header === " ";
      });
    } else {
      allColumns.map((column, index) => {
        initialSelectColumns[column.Header] = true;
      });
    }
    setSelectColumns(initialSelectColumns);
  }, [attributes]);

  useEffect(() => {
    const selectedIds = selectedFlatRows.map((row) => `${row.original.id}`);
    setSelectedIds(selectedIds);
  }, [selectedFlatRows, setSelectedIds]);

  useEffect(() => {
    allColumns
      .filter((column) => column.id !== "selection")
      .map((column) => {
        const e = { target: { checked: selectColumns[column.Header] } };
        column.getToggleHiddenProps().onChange(e);
      });
  }, [allColumns, selectColumns]);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      const rowStyle = row.original.order_is_closed
        ? { backgroundColor: "#bbbbbb", borderRadius: "5px" }
        : {};

      return (
        <div style={style}>
          <TableRow
            {...row.getRowProps([getRowProps(row)])}
            className="ab"
            style={{ ...rowStyle }}
          >
            {row.cells.map((cell) => {
              return (
                <TableCell
                  className="td"
                  {...cell.getCellProps([
                    getColumnProps(cell.column),
                    getCellProps(cell),
                  ])}
                >
                  {cell.render("Cell")}
                </TableCell>
              );
            })}
          </TableRow>
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds]
  );

  // Render the UI for your table
  return (
    <>
      <ColumnSelectorDialog
        isOpen={isOpenSelectorPopup}
        selectColumns={selectColumns}
        allColumns={allColumns}
        setOpenSelectorPopup={setOpenSelectorPopup}
        setSelectColumns={setSelectColumns}
      />
      <MaUTable {...getTableProps()} className="table table-sm">
        <div
          style={{
            width: window_width,
            backgroundColor: "#fff",
          }}
        >
          <TableHead>
            <TableRow className="tr">
              <TableCell
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left",
                  maxHeight: 50,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "12px",
                    }}
                    className={"mb-0"}
                    variant="contained"
                    style={{ marginRight: calExp(window_width * 0.015) }}
                  >
                    <h6
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Plan<br></br> Table
                    </h6>

                    {/* <br></br> */}
                    {selectedFlatRows.length > 0 && (
                      <div
                        style={{
                          paddingTop: 1,
                          paddingBottom: 1,
                          paddingLeft: 5,
                          paddingRight: 5,
                          borderRadius: 2,
                          backgroundColor: "#848484 ",
                          fontSize: 10,
                          color: "white",
                          marginTop: -5,
                        }}
                      >
                        {selectedFlatRows.length}
                      </div>
                    )}
                  </div>
                  <div>
                    <Button
                      size={window_width > 1500 ? `medium` : `small`}
                      onClick={() => {
                        setAllFilters([]);
                        setGlobalFilter(undefined);
                        // setRangeFilters([{
                        //   column: "",
                        //   start: "",
                        //   end: "",
                        //   type: ""
                        // }]);
                      }}
                      variant="contained"
                      className="btn_ btn_primary mb-0"
                      title="Click here to Reset filters"
                    // style={{ marginRight: calExp(window_width * 0.015) }}
                    >
                      Reset filters
                    </Button>
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </div>
                  <div style={{ marginLeft: calExp(window_width * 0.015) }}>
                    {headerContent}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
        </div>
        <div style={{ width: window_width, overflowX: "auto" }}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => (
                  <TableCell
                    className="th pr-0"
                    {...column.getHeaderProps(
                      getColumnProps(column),
                      getHeaderProps(column)
                    )}
                  >
                    <span
                      {...column.getSortByToggleProps()}
                      Title="Click to sort"
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon fontSize="small" />
                        ) : (
                          <ArrowUpwardIcon fontSize="small" />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            <FixedSizeList
              height={window_height - 250}
              itemCount={rows.length}
              itemSize={30}
              width={totalColumnsWidth + scrollBarSize + 1}
            >
              {RenderRow}
            </FixedSizeList>
          </TableBody>
        </div>
      </MaUTable>
    </>
  );
}

export default Table;
