import React from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
// import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';

import { connect } from "react-redux";

import { forwardRef } from 'react';
import { Error, Loading, usePermissions } from "react-admin";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
// import ViewColumn from '@material-ui/icons/View';
import { useHistory } from 'react-router-dom';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />)
    // ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '4%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));



function CSVDataViewer({ data, itemImages }) {

    const location = useLocation()
    const classes = useStyles();
    const columnData = data[4]
    const indexofFirstCol = columnData.indexOf("Computer Code")
    const indexOfTransferPriceCol = columnData.indexOf("Transfer Price to DSS")

    const createRow = (value) => {

        const row = {}
        if (value[indexofFirstCol] != null && typeof (value[indexOfTransferPriceCol]) == "number") {
            for (var i = 0; i < columnData.length; i++) {

                if (columnData[i] != null) {
                    if (i != indexOfTransferPriceCol) {
                        row[columnData[i]] = value[i]
                    } else {
                        if (typeof (value[i]) == "number") {
                            row[columnData[i]] = value[i]?.toFixed(2)
                        }
                    }
                }
            }
            return row
        }


    }

    const createCols = (value) => {
        var col = null;
        console.log(itemImages)
        if (value != null) {
            if (value == "Item Photo") {
                col = {
                    title: value,
                    field: "image",
                    render: item => item["Item Photo"] == "None" ? <p></p> : item["Item Photo"] ? <img src={'data:image/jpeg;base64,' + item["Item Photo"]} /> : <Loading />

                }
            } else {
                col = {
                    title: value,
                    field: value
                }
            }

            return col
        }

    }
    function setImagesToRows(rows) {
        var dataRows = rows.filter(x => { return (x["Sample Design Number"] != null) })
        var infoRows = rows.filter(x => { return (x["Sample Design Number"] == null) })
        dataRows.forEach(data => {
            const sdn = data["Sample Design Number"]
            const formattedSDN = sdn.replaceAll("/", "|").concat(".png")
            data["Item Photo"] = itemImages && itemImages[formattedSDN] ? itemImages[formattedSDN]?.image : "None"
        });
        infoRows.forEach(data => {
            data["Item Photo"] = "None"
        })
        return rows

    }
    const dataRows = data.slice(10).map(createRow)
    const refinedRows = dataRows.filter(x => x !== undefined)
    // refinedRows.pop()
    // const rows = refinedRows
    const rows = setImagesToRows(refinedRows)
    const columns = columnData.map(createCols)
    const refinedCols = columns.filter(x => x !== undefined)


    return (
        <div className={classes.root}>
            <MaterialTable
                icons={tableIcons}
                title="Price List"
                columns={refinedCols}
                data={rows}
                options={{
                    sorting: true
                }}
            />
        </div>
    )

}

export default CSVDataViewer;