import { CapacityRecord, ItemTimeBucket, SupplyChainState } from "../interfaces";
import moment from "moment"

const range = (n: number) => [...[...Array.from(Array(n).keys())]]
const timebucketTimeRanges = range(50).map(v => moment().endOf('month').add('weeks', v * 2).add("day", 1)).map(m => ({ m, id: m.format("YYYY-ww") }))
export default () => ({
    items: [
        {
            id: "WF1000401",
            category_codes: "Pens,Chooty Gel,Rs.20",
            openning_price: 1,
            openning_stock: 200
        },
        {
            id: "WF1000402",
            category_codes: "Pens,Chooty Gel,Rs.20",
            openning_price: 1,
            openning_stock: 1
        },
        {
            id: "P",
            category_codes: "RM",
            openning_price: 1,
            openning_stock: 5
        },
        {
            id: "Q",
            category_codes: "RM",
            openning_price: 1,
            openning_stock: 2000
        },
        // ...[...Array.from(Array(500).keys())].map(v => ({ id: `Item${v}`, category_codes: `Cat${Math.floor(v / 200)},Cat${v % 50}`, holding_cost_per_time_unit: 1, openning_stock: v }))
    ],

    routings: [
        {
            item_id: "WF1000401",
            operation_id: "Chooty Gel",
            pack_size: 3,
            production_cost_per_unit: 0
        },
        {
            item_id: "WF1000402",
            operation_id: "Chooty Gel",
            pack_size: 3,
            production_cost_per_unit: 0
        },
        {
            item_id: "WF1000402",
            operation_id: "Chooty Gel 2",
            pack_size: 3,
            production_cost_per_unit: 0
        },
        {
            item_id: "P",
            operation_id: "Y",
            pack_size: 5,
            production_cost_per_unit: 0
        }
    ],
    material_structures: [
        {
            item_id: "WF1000401",
            operation_id: "Chooty Gel",
            rm_item_id: "P",
            qty_per_assembly: 6,
        },
        {
            item_id: "WF1000402",
            operation_id: "Chooty Gel",
            rm_item_id: "Q",
            qty_per_assembly: 1,
        },
        {
            item_id: "WF1000402",
            operation_id: "Chooty Gel 2",
            rm_item_id: "Q",
            qty_per_assembly: 1,
        },
        {
            item_id: "P",
            operation_id: "Y",
            rm_item_id: "Q",
            qty_per_assembly: 10,
        }
    ],
    time_buckets: timebucketTimeRanges
        .map(({ m, id }) => ({
            id, label: id,
            duration: 14,
            interest: 0.02
        })),
    item_time_bucket: [
        ...timebucketTimeRanges.map(({ id: bucketId }, i) => {
            return {
                time_bucket_id: bucketId,
                item_id: "WF1000401",
                supply: 0,
                buying_price: 0,
                warehouse_cost: 0.1,
                minimum_buffer_stock: 0,
                demand: Math.floor(180 + Math.sin(Math.PI * i / 10 - Math.PI / 3) * 100 + Math.random() * 30),
                selling_price: 100,
            } as ItemTimeBucket
        }),
        ...timebucketTimeRanges.map(({ id: bucketId }, i) => {
            return {
                time_bucket_id: bucketId,
                item_id: "WF1000402",
                supply: 0,
                buying_price: 0,
                warehouse_cost: 0.1,
                minimum_buffer_stock: 0,
                demand: Math.floor(50 + Math.sin(Math.PI * i / 10 - Math.PI / 3) * 30 + Math.random() * 20),
                selling_price: 200,
            } as ItemTimeBucket
        }),
        ...timebucketTimeRanges.map(({ id: bucketId }, i) => {
            return {
                time_bucket_id: bucketId,
                item_id: "Q",
                demand: 0,
                selling_price: 0,
                supply: 800,
                buying_price: 2,
                warehouse_cost: 0.1,
                minimum_buffer_stock: 0
            } as ItemTimeBucket
        })
    ],
    capacity: [
        ...timebucketTimeRanges.map(({ id: bucketId }, i) => {
            return {
                time_bucket_id: bucketId,
                operation_id: "Chooty Gel",
                quantity: 200,
                minimum_quantity: 100,
                fixed_cost: 250 + i * 5,
                variable_cost: 1,
            } as CapacityRecord
        }),
        ...timebucketTimeRanges.map(({ id: bucketId }, i) => {
            return {
                time_bucket_id: bucketId,
                operation_id: "Chooty Gel 2",
                quantity: 200,
                minimum_quantity: 10,
                fixed_cost: 200 + i * 5,
                variable_cost: 1.5,
            }
        }),
        ...timebucketTimeRanges.map(({ id: bucketId }, i) => {
            return {
                time_bucket_id: bucketId,
                operation_id: "Y",
                quantity: 1000,
                minimum_quantity: 10,
                fixed_cost: 500,
                variable_cost: 2,
            }
        }),
    ],

    planned: [],
    infeasible_items: [],
    sales_loss: []

}) as SupplyChainState