import React from "react";
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  useNotify,
} from "react-admin";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { showToastWarning } from "../../lib/toas";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = "Code is required";
  }
  return errors;
};

export const CreateOrderGroup = (props) => {
  const onFailure = (error) => {
    if (error === "TypeError") {
      showToastWarning(
        `Could not Create OrderGroup this order code already used`
      );
    }
  };
  const customToast = useCustomToast(props);
  return (
    <div style={{ width: "50%" }}>
      <Create
        onSuccess={customToast.onSuccess}
        onFailure={onFailure}
        {...props}
      >
        <SimpleForm
          validate={validate}
          toolbar={<CustomToolbar name="demands" text="Demand" need={false} />}
        >
          <TextInput source="code" label={<ReqInputField name="Code" />} />
          <BooleanInput source="skip_planning" label="Skip Planning" />
        </SimpleForm>
      </Create>
    </div>
  );
};
