import Button from "@material-ui/core/Button";
import { ListController } from "ra-core";
import React from "react";
import {
  Datagrid,
  EditButton,
  ListView,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
} from "react-admin";
import { Link } from "react-router-dom";
import Confirmation from "../../components/Confirmation";

export const WorkstationShow = (props) => {
  const { permissions } = usePermissions();

  const CreateOpWorkstationButton = ({ record }) => (
    <Button
      title="Click here to Link an Operation to this workstation"
      disabled={!permissions?.permissions.includes("link_operation")}
      // color="primary"
      className="btn_ btn_primary"
      variant="contained"
      component={Link}
      to={{
        pathname: "/opWorkstations/create",
        state: { record: { workstation: record.id } },
      }}
    >
      Link an Operation to this Workstation
    </Button>
  );

  const Title = ({ record }) => {
    return <span>Workstation | {record ? `${record.code} ` : ""}</span>;
  };

  return (
    <Show {...props} actions={null} className="col-md-12" title={<Title/>}>
      <SimpleShowLayout className="pt-4">
        <TextField
          source="id"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <TextField
          source="code"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <NumberField
          source="plant"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <NumberField
          source="plan_board"
          className="col-xl-4 col-sm-12 sub_con_lab mb-3"
        />
        <ReferenceManyField
          label="Operation Workstations"
          reference="opWorkstations"
          pagination={<Pagination perPage={25} />}
          target="workstation"
        >
          <Datagrid>
            <TextField source="id" />
            <TextField source="operation" />
            <ReferenceField
              link=""
              label="Operation Code"
              source="operation"
              reference="operations"
            >
              <TextField source="code" />
            </ReferenceField>
            <ReferenceField
              link=""
              label="Operation Description"
              source="operation"
              reference="operations"
            >
              <TextField source="description" />
            </ReferenceField>
            <EditButton
              title="Click here to Edit"
              className="btn_ btn_edit"
              disabled={
                !permissions?.permissions.includes("opWorkstation_edit")
              }
            />
            <Confirmation
              redirect={false}
              text="operations"
              title="Click here to Delete"
              disabled={
                !permissions?.permissions.includes("opWorkstation_edit")
              }
            />
          </Datagrid>
        </ReferenceManyField>
        <CreateOpWorkstationButton />
      </SimpleShowLayout>
    </Show>
  );
};

const DumbList = (props) => (
  <ListController {...props}>
    {(controllerProps) => {
      let { data } = props;
      const ids = Object.keys(data || {});
      const total = ids.length;
      return (
        <ListView
          {...props}
          pagination={false}
          // This is important, otherwise bulkActionsToolbar ends up on very top of the page
          classes={{ card: "relative" }}
          {...Object.assign(controllerProps, { data, ids, total })}
        />
      );
    }}
  </ListController>
);
