import React, { useState } from "react";

import { connect } from "react-redux";

import { loadALLTasks, uploadForm, generateAndDownloadForm } from "../../store/actions/tnaTaskActions";
import Button from "@material-ui/core/Button";
import reactElementToJSXString from "react-element-to-jsx-string";
import { showToastError, showToastSuccess } from "../../lib/toas";
import moment from "moment";

import { getAllTasks } from "../../store/selectors/tnaTaskSelectors";

import { useParams } from "react-router-dom";

export const formStyles = `               
    table, tr, th, td {
      border-collapse: collapse;
      border: none;
  }
  .form-table, .form-th, .form-td { 
padding: 5px; border: 1px solid black !important; border-collapse: collapse; text-align: left
}
.form-table {
margin: 5px; margin-bottom: 30px; width: 100%; page-break-inside: avoid;
margin-bottom: 50px; 
}  
.form-input {
border: none;
border-bottom: 1px solid black;
background: none;
}
.form-textarea {
width: 100%;
background: none;
}
input { border: none }
table { page-break-inside:auto }
tr { page-break-inside:avoid; page-break-after:auto }
thead { display:table-header-group }
tfoot { display:table-footer-group }
.form-brtable { page-break-inside:avoid; page-break-after:auto; }
button {
visibility: hidden !important;
}`;

export const formatForm = (form) => {
  const formattedRawForm = reactElementToJSXString(form);
  const formattedForm = formattedRawForm
    .replace(`{' '}`, "")
    .replace(`{''}`, "")
    .replace(`{'  '}`, "");

  return formattedForm
}

function UploadPdfFormButton({
  onGenerate,
  onUpload,
  form,
  formName,
  formData,
  prevFormName,
  appendParentCode,
  setIsOpen,
  tasks,
  onLoad,
}) {

  const params = useParams();

  const taskId = params.id;
  const formattedForm = formatForm(form)
  const fullFormName = `${taskId}--${formName}--`;
  // console.log({ fullFormName });

  return (
    <Button
      onClick={() => {
        appendParentCode ? onGenerate({
          form: formattedForm,
          styles: formStyles,
          prev_name: prevFormName,
          form_name: fullFormName,
        }) :
        onUpload({
          form_data: formData,
          form: formattedForm,
          styles: formStyles,
          form_name: fullFormName,
          task_id: taskId,
          prev_name: prevFormName,
        });
        setIsOpen(false);
        onLoad();
      }}
    >
      Yes
    </Button>
  );
}

const mapDispatchToProps = {
  onGenerate: generateAndDownloadForm,
  onUpload: uploadForm,
  onLoad: loadALLTasks,
};

const mapStateToProps = (state) => {
  const tasks = getAllTasks(state);
  return {
    tasks,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadPdfFormButton);
