import { MODEL_CLOSE, MODEL_OPEN } from "../../types"

const initialState = {
    open: false,
}

export const modelReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MODEL_CLOSE: {
            return { ...state, open: false }
        }

        case MODEL_OPEN: {
            return { ...state, open: true }
        }
        default:
            return state
    }
}