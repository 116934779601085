import React from "react"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Login, LoginForm } from 'react-admin';
import backgroundImage from '../../layout/img/login-side.png'
import loginLogoImage from '../../layout/img/login-logo.png'
import AppInfo from '../AppInfo';
import { Grid } from "@material-ui/core";
import ExpandableCard from "../loading/ExpandableCard";
import { theme } from "../../layout/theme";
import CustomLogin from './CustomLogin'

const styles = ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        width: 400,
        hight: 400,
        minWidth: 300,
    },
    avatar: {
        margin: '1em',
        textAlign: 'center ',
    },
    form: {
        padding: '2em 2em 2em 2em',
    },
    input: {
        display: 'flex',
    },
    hint: {
        textAlign: 'center',
        marginTop: '1em',
        color: '#ccc',
    },
});

const CustomLoginForm = withStyles({
})(CustomLogin);

const CustomLoginPage = props => (
    <Grid container>
        <Grid item md={6} style={{ backgroundColor: 'white', backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover'  }}>
        </Grid>
        <Grid item container md={6} >
            {/* <Grid item xs={12} style={{padding: 20}}>
                <img src={loginLogoImage} style={{ width: '30%' }} ></img>
            </Grid> */}
            <Grid item md={12} style={{textAlign: 'center'}}>
            <img src={loginLogoImage} style={{ width: '40%', display: 'block' , marginLeft: '30%' , marginTop: '15%' , marginBottom: '5%' }} ></img>
                <b style={{ fontSize: '30px' , color: '#00acee' }}>Schedule | Visualize and Execute </b>
                <div style={{ fontSize: '20px' }}>Your production schedule with ease</div>
            </Grid>
            <Grid item sm={3}>
                </Grid>
            <Grid item sm={6}>
                <div style={{ flexDirection: 'row', justifyContent: 'center', }}>
                        <ThemeProvider theme={theme}>
                            <CustomLoginForm />
                        </ThemeProvider>
                </div>
            </Grid>
        </Grid>
    </Grid >
);

export default CustomLoginPage;