import { Dictionary } from "lodash"
import { createUndoableReducer } from "../../../lib/undoable_reducer"
import { ADD_ORDER_CODE_TO_CHANGED_ORDERS, LOAD_PLAN } from "../../types"

const initialState = {} as Dictionary<string>

const originalChangedOrderCodeReducer = (state = initialState, action: any) => {
    if (action?.type === LOAD_PLAN) {
        return initialState
    }

    if (action.type === ADD_ORDER_CODE_TO_CHANGED_ORDERS) {
        return { ...state, [action.payload.code]: true }
    }

    return state
}

export const changedOrderCodesReducer = createUndoableReducer(originalChangedOrderCodeReducer, "PLAN_", LOAD_PLAN)