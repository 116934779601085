import React from 'react'
import { Badge, Chip, Divider, Grid } from '@material-ui/core'

export default function ShiftPatternView(props: { shiftPatterns: { codesWithRange: { codes: any[], dateRange: any[] }[], config: { pattern: any } }[] }) {
    return (
        <div>
            {props.shiftPatterns.map(shiftPatternGroup => <div>
                <Grid container style={{ margin: 10, width: '120%' }}>
                    <Grid item xs={2}>
                        {shiftPatternGroup.config.pattern && Object.keys(shiftPatternGroup.config.pattern).map(k => <ShiftGroup name={k} shifts={shiftPatternGroup.config.pattern[k]} />)}
                    </Grid>
                    <Grid item xs={8}>
                        {shiftPatternGroup.codesWithRange && shiftPatternGroup.codesWithRange.map(rangeLineObj =>
                            <>
                                <Grid container item xs={12} style={{ marginBottom: 30 }}>
                                    <Grid item xs={8}>
                                        {rangeLineObj.codes && rangeLineObj.codes.map(code => <Chip variant="outlined" size="small" label={code} style={{ margin: "2px" }} />)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {rangeLineObj.dateRange && rangeLineObj.dateRange.map(dates => <Chip variant="outlined" size="medium" color="primary" label={dates} style={{ margin: "2px" }} />)}
                                    </Grid>
                                </Grid>
                            </>

                        )}
                    </Grid>
                </Grid>
                <Divider />
            </div>)}
        </div>
    )
}

const ShiftGroup = ({ name, shifts }: { name: string, shifts: any[] }) => <div>
    {name[0].toUpperCase()}{name.substring(1)}: {shifts.map(shift => <Shift data={shift} />)}
</div>

const Shift = ({ data }: any) => {
    return <Chip variant="outlined" size="small" color="primary" label={`${data[0]} (${data[1]}h)`} style={{ margin: 5 }} />
}