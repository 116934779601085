import React from "react";
import {
  // CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  List,
  TextField,
  TopToolbar,
  useListContext,
  usePermissions,
} from "react-admin";
import CreateButton from "../../../components/CustomCreateButton";
import Confirmation from "../../../components/Confirmation";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../../components/ChangeDiv";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const ListActions = (props) => {
  const classes = useStyles();

  const createPermissions = () =>
    !props.permissions?.permissions.includes("tna_delay_reasons_create");
  const exportPermissions = () =>
    !props.permissions?.permissions.includes("tna_delay_reasons_export");

  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    basePath,
  } = useListContext();

  return (
    <TopToolbar className={classes.buttonContainer}>
      <CreateButton
        basePath={basePath}
        title="permissions"
        disabled={createPermissions()}
        variant="contained"
        color="primary"
      />
      <ExportButton
        title="permissions"
        disabled={exportPermissions()}
        resource={resource}
        sort={currentSort}
        maxResults={maxResults}
        variant="contained"
        // color="primary"
        className="btn_ btn_primary"
      />
    </TopToolbar>
  );
};

export const DelayReasons = (props) => {
  const { permissions } = usePermissions();
  const Title = () => {
    return <span>TNA Delay Reasons</span>;
  };

  return (
    <List
      {...props}
      title={<Title/>}
      actions={<ListActions permissions={permissions} />}
      bulkActionButtons={false}
      filter={["TNA"].includes(permissions?.access_level) ? {} : null}
      perPage={25}
    >
      <ChangeDiv>
        {props => {
          return <Datagrid>
          <TextField source="id" />
          <TextField source="delay_reason" />

          <EditButton
            {...props}
            className="btn_ btn_edit"
            title="permissions"
            disabled={
              !permissions?.permissions.includes("tna_delay_reasons_edit")
            }
          />
          <Confirmation
            title="permissions"
            disabled={
              !permissions?.permissions.includes("tna_delay_reasons_delete")
            }
            text="tna delay reasons"
          />
        </Datagrid>
        }}
      </ChangeDiv>
        
     
    </List>
  );
};
