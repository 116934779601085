import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";

export const TrackingRecord = props => (
  <List {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="demand_operation" />
      <TextField source="tracking_step" />
      <TextField source="completed_quantity" />
      <TextField source="time_stamp" />
      <TextField source="grade" />
      <TextField source="reason" />
      <TextField source="delay_duration" />
      <EditButton />
    </Datagrid>
  </List>
);
