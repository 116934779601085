import { SET_IS_EDITING } from "../../types"

const initialState = "no" as string

export const editingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_IS_EDITING: {
            const { string } = action.payload
            return string
        }
        default:
            return state
    }
}