import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import _ from "lodash"



export default function KeyValueField({ source, record = {} }) {
    const obj = _.get(record, source)

    if (!obj) return false
    return (
        <div>
            {Object.keys(obj).filter(k => !!obj[k]).map(k =>
                <Tooltip key={k} title={k}><Chip style={{ margin: 2 }} label={obj[k]} /></Tooltip>)}
        </div>
    )
}

 
