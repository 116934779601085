import React from 'react'
import { useSelector } from 'react-redux'
import { getSupplyChainData } from './reducer/selectors'
import _ from "lodash"
import { Bar, Pie } from "react-chartjs-2"
import { getInterestCost, getProductionCostPerItem, getProductionFixedCost, getProductionVariableCost, getSalseLossCostPerItemForTimeBucket, getSalseLossPerItemForTimeBucket, getWareHouseCost } from './lib'
import { SupplyChainState, TimeBucket } from './interfaces'
import { Grid, Typography, Card, CardContent, CardHeader } from '@material-ui/core'
import { getColor } from './TimeSeriesChart'

type Props = {
    selectedItems: string[];
    selectedTimeBucketId: string;
    data: SupplyChainState
}
export default function CostExplorer({ selectedItems, selectedTimeBucketId, data }: Props) {
    const relatedOperations = _.chain(data.routings).filter(r => selectedItems.indexOf(r.item_id) >= 0).map(v => v.operation_id).uniq().value()
    return (
        <div>
            <Typography variant="h5">
                Production Cost
            </Typography>
            <br />
            <Grid container spacing={2} >
                {relatedOperations.map(op =>
                    <Grid key={op} item sm={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="body2">Operation: {op}</Typography>
                                <VariableCostChart key={op} operationId={op} timeBucketId={selectedTimeBucketId} supplyChainData={data} />
                            </CardContent>
                        </Card>
                    </Grid>
                )}

            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={12} md={12}>
                    <Card>
                        <CardContent>
                            <VariableCostBarChart supplyChaingData={data} operationIds={relatedOperations} timeBucketId={selectedTimeBucketId} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={12} md={12}>
                    <Card>
                        <CardContent>
                            <ProductionCostBarChart timeBucketId={selectedTimeBucketId} relatedOperations={relatedOperations} supplyChaingData={data} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <br />
            <Typography variant="h5">
                Warehouse Cost and Interest Costs
            </Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item sm={12} md={12}>
                    <Card>
                        <CardContent>
                            <WareHouseCostBarChart supplyChaingData={data} selectedItems={selectedItems} timeBucketId={selectedTimeBucketId} />
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </div >
    )
}


type VariableCostProps = {
    operationId: string,
    timeBucketId: string,
    supplyChainData: SupplyChainState
}

const VariableCostChart = (props: VariableCostProps) => {

    const fixedCost = getProductionFixedCost(props.supplyChainData, props.timeBucketId, props.operationId)
    const variableCost = getProductionVariableCost(props.supplyChainData, props.timeBucketId, props.operationId)

    const getPercentage = (v: number) => Math.round(v * 100 / (fixedCost + variableCost))
    const data = {
        labels: [`Fixed Cost ${getPercentage(fixedCost)}%`, `Variable Cost ${getPercentage(variableCost)}%`],
        datasets: [{
            label: 'Cost',
            data: [fixedCost, variableCost],
            backgroundColor: [0, 1].map(getColor).map(v => v.backgroundColor),
            borderColor: [0, 1].map(getColor).map(v => v.borderColor),
        }]
    } as any;
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                algn: "start",
                textAlign: "left",
                title: {
                    display: true,
                    text: props.operationId,
                }
            }
        }
    }

    return <div><Pie options={options} type="pie" data={data}></Pie></div>
}

type VariableCostBarChardProps = {
    operationIds: string[],
    timeBucketId: string,
    supplyChaingData: SupplyChainState
}
const getChartVariableFixedData = (operations: string[], data: SupplyChainState, buckets: (TimeBucket[] | null) = null) => {

    // const getTimeRecord = (b: TimeBucket) => data.item_time_bucket.find(d => d.time_bucket_id == b.id && d.item_id == item)
    const timeBuckets = buckets || data.time_buckets
    const mapForBuckets = (func: any) => timeBuckets.map(b => _.sumBy(operations, op => func(data, b.id, op)))

    const fixedCost = mapForBuckets(getProductionFixedCost),
        variableCost = mapForBuckets(getProductionVariableCost);

    const datasets = [
        {
            label: "Fixed Cost",
            data: fixedCost,
            ...getColor(0)
        },
        {
            label: "Variable Cost",
            data: variableCost,
            ...getColor(1)
        },
    ]

    const result = {
        labels: timeBuckets.map(b => b.label),
        datasets: datasets.map(entry => ({ ...entry, borderWidth: 1, tension: 0.1, pointHitRadius: 6, pointRadius: 2, pointHoverRadius: 4 }))
    }
    return result
}


const VariableCostBarChart = (props: VariableCostBarChardProps) => {
    const bucketIndex = props.supplyChaingData.time_buckets.findIndex(b => b.id == props.timeBucketId)
    const timeBuckets = props.supplyChaingData.time_buckets.slice(bucketIndex, bucketIndex + 10)
    const data = getChartVariableFixedData(props.operationIds, props.supplyChaingData, timeBuckets)


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                stacked: true,
            }],
            xAxes: [
                {
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 20
                    },
                    stacked: true,
                }
            ],
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        },
    }

    return <div style={{ height: 250 }}>
        <Typography variant="body2">Selected Operations: {props.operationIds.join(", ")}</Typography>
        <Bar options={options} data={data} />
    </div>
}

const getProductionCostPerItemData = (operations: string[], data: SupplyChainState, buckets: (TimeBucket[] | null) = null) => {

    // const getTimeRecord = (b: TimeBucket) => data.item_time_bucket.find(d => d.time_bucket_id == b.id && d.item_id == item)
    const timeBuckets = buckets || data.time_buckets
    const mapForBuckets = (func: any) => timeBuckets.map(b => _.sumBy(operations, item => func(data, b.id, item)))
    const productionCost = mapForBuckets(getProductionCostPerItem)

    const datasets = [
        {
            label: "Production Cost Per Item",
            data: productionCost,
            ...getColor(4)
        },
    ]

    const result = {
        labels: timeBuckets.map(b => b.label),
        datasets: datasets.map(entry => ({ ...entry, borderWidth: 1, tension: 0.1, pointHitRadius: 6, pointRadius: 2, pointHoverRadius: 4 }))
    }
    return result
}
type ProductionCostChartProps = {
    relatedOperations: string[],
    timeBucketId: string,
    supplyChaingData: SupplyChainState
}

const ProductionCostBarChart = (props: ProductionCostChartProps) => {
    const bucketIndex = props.supplyChaingData.time_buckets.findIndex(b => b.id == props.timeBucketId)
    const timeBuckets = props.supplyChaingData.time_buckets.slice(bucketIndex, bucketIndex + 10)
    const data = getProductionCostPerItemData(props.relatedOperations, props.supplyChaingData, timeBuckets)

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                stacked: true,
            }],
            xAxes: [
                {
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 20
                    },
                    stacked: true,
                }
            ],
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        },
    }

    return <div style={{ height: 250 }}>
        <Typography variant="body2">Production Cost Per Item of {props.relatedOperations.join(", ")}</Typography>
        <Bar options={options} data={data} />
    </div>
}



const getChartWarehouseData = (items: string[], data: SupplyChainState, buckets: (TimeBucket[] | null) = null) => {

    // const getTimeRecord = (b: TimeBucket) => data.item_time_bucket.find(d => d.time_bucket_id == b.id && d.item_id == item)
    const timeBuckets = buckets || data.time_buckets
    const mapForBuckets = (func: any) => timeBuckets.map(b => _.sumBy(items, op => func(data, b.id, op)))

    const warehouseCost = mapForBuckets(getWareHouseCost)
    const interestCost = mapForBuckets(getInterestCost)
    // const salesLossCost = mapForBuckets(getSalseLossCostPerItemForTimeBucket)
    // variableCost = mapForBuckets(getProductionVariableCost);

    const datasets = [
        {
            label: "Warehouse Cost",
            data: warehouseCost,
            ...getColor(2)
        },
        {
            label: "Interest Cost",
            data: interestCost,
            ...getColor(3)
        },
        // {
        //     label: "Sales Loss Cost",
        //     data: salesLossCost,
        //     ...getColor(4)
        // },
    ]

    const result = {
        labels: timeBuckets.map(b => b.label),
        datasets: datasets.map(entry => ({ ...entry, borderWidth: 1, tension: 0.1, pointHitRadius: 6, pointRadius: 2, pointHoverRadius: 4 }))
    }
    return result
}
type WarehouseCostChartProps = {
    selectedItems: string[],
    timeBucketId: string,
    supplyChaingData: SupplyChainState
}

const WareHouseCostBarChart = (props: WarehouseCostChartProps) => {
    const bucketIndex = props.supplyChaingData.time_buckets.findIndex(b => b.id == props.timeBucketId)
    const timeBuckets = props.supplyChaingData.time_buckets.slice(bucketIndex, bucketIndex + 10)
    const data = getChartWarehouseData(props.selectedItems, props.supplyChaingData, timeBuckets)

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                stacked: true,
            }],
            xAxes: [
                {
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 20
                    },
                    stacked: true,
                }
            ],
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        },
    }

    return <div style={{ height: 250 }}>
        <Typography variant="body2">Warehouse Cost: {props.selectedItems.join(", ")}</Typography>
        <Bar options={options} data={data} />
    </div>
}