import { API_URL } from '../constants';
import { urlChanger } from '../lib/urlChanger';

const authProvider = {
    login: params => {
        const { username, password } = params;
        const request = new Request(`${API_URL}/token-auth/`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json, */*' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 | response.status >= 300) {
                    console.log("error")
                    throw new Error(response.statusText);
                }
                console.log("1")
                return response.json();
            })
            .then(({ token, user }) => {
                const currentTime = new Date().getTime()
                console.log(JSON.stringify(user))
                localStorage.setItem('username', user.username)
                localStorage.setItem('first_name', user.first_name)
                localStorage.setItem('currentTime', currentTime)
                localStorage.setItem('token', token);
                localStorage.setItem('role', user.app_user.role_code)
                localStorage.setItem('access_level', user.app_user.access_level)
                localStorage.setItem('permissions', JSON.stringify(user.app_user.permissions))
                localStorage.setItem('site', user.app_user.site)
                localStorage.setItem('plant', user.app_user.plant)
                localStorage.setItem('plant_code', user.app_user.plant_code)
                localStorage.setItem('planBoards', user.app_user.planboards)
                localStorage.setItem('tna_department', user.app_user.tna_department?.code)
                //  urlChanger redirect to dashboard 
                // removing this code will enable after login redirect to last vist page 
                urlChanger()
            })
            .then(() => {
                return Promise.resolve()
            });
    },
    logout: params => {
        localStorage.removeItem('username')
        localStorage.removeItem('first_name')
        localStorage.removeItem('token');
        localStorage.removeItem('currentTime');
        localStorage.removeItem('role')
        localStorage.removeItem('access_level')
        localStorage.removeItem('permissions')
        localStorage.removeItem('site')
        localStorage.removeItem('plant')
        localStorage.removeItem('planboards')
        localStorage.removeItem('plant_code')
        return Promise.resolve();
    },
    checkAuth: params => {
        const { resource } = params;
        return localStorage.getItem('token')
            ? Promise.resolve()
                .then(() => {
                    const lastTime = localStorage.getItem('currentTime')
                    const currentTime = new Date().getTime()
                    if (Number(currentTime) > Number(lastTime) + 300000) {
                        const token = localStorage.getItem('token')
                        return fetch(`${API_URL}/refresh-token/`, {
                            method: 'POST',
                            body: (JSON.stringify({ token })),
                            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json, */*' }),
                        })
                            .then(response => {
                                if (response.status < 200 | response.status >= 300) {
                                    throw new Error(response.statusText);
                                }
                                return response.json();
                            })
                            .then(({ token }) => {
                                localStorage.setItem('currentTime', currentTime)
                                localStorage.setItem('token', token)
                            })
                    }

                })
            : Promise.reject();
    },
    checkError: error => {
        const { status } = error;
        if (status === 401) {
            localStorage.removeItem('token');
            return Promise.reject();
        } else if (status === 403) {
            // return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: params => {
        const role = { access_level: localStorage.getItem("access_level"), permissions: JSON.parse(localStorage.getItem('permissions')) };
        return role ? Promise.resolve(role) : Promise.reject();
    }
};


export default authProvider;
