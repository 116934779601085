import React from "react";
import {
  Edit,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  hideNotification,
  number,
  regex,
  email,
  choices,
  useRedirect,
} from "react-admin";
import { SaveButton, DeleteButton, Toolbar, useNotify } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Confirmation from "../../components/Confirmation";
// import { CustomToolbar } from "../../components/CustomToolbar";
import { ReqInputField } from "../../components/ReqInputField";
import { showToastError } from "../../lib/toas";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useCustomToast } from "../../hooks/useCustomToast";

// const useStyles = makeStyles({
//   toolbar: {
//     display: "flex",
//     justifyContent: "flex-end",
//     marginTop: 10,
//     paddingRight: 10,
//   },
// });
const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
  },
});

// const showTost = () => {
//   showToastError("please fill all the fields");
// };

export const UserEdit = (props) => {
  const { permissions } = usePermissions();
  const [error, seterror] = React.useState(false);
  const [submit, setsubmit] = React.useState(false);
  //  const numCompletedTodos = useSelector(SHOW_NOTIFICATIONS(null))
  // showNotification(false)

  const validateUser = (values) => {
    const { password, first_name, last_name, username, app_user } = values;
    const errors = {};
    if (first_name && /\s/.test(first_name)) {
      errors.first_name = "First name cannot contain spaces";
    }
    if (!first_name) {
      errors.first_name = "Required";
    }
    if (last_name && /\s/.test(last_name)) {
      errors.last_name = "Last name cannot contain spaces";
    }
    if (!last_name) {
      errors.last_name = "Required";
    }
    if (username && /\s/.test(username)) {
      errors.username = "Username cannot contain spaces";
    }
    if (!password) {
      errors.password = "Required";
    }
    if (
      app_user.permission_level === "SUB" &&
      app_user.plan_boards &&
      app_user.plan_boards.length === 0
    ) {
      errors.plan_boards = "Required";
    }
    // check errors object empty or not
    if (!_.isEmpty(errors)) {
      seterror(true);
    } else {
      seterror(false);
      return errors;
    }
  };

  React.useEffect(() => {
    if (submit === true && error === true) {
      showToastError("please fill all the fields");
    }
    setsubmit(false);
  }, [error, submit]);

  const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()} className="mr-4 mb-2">
      <Confirmation
        title="permissions"
        disabled={!permissions?.permissions.includes("users_delete")}
        text="sites"
        className="mr-2"
      />
      <SaveButton
        disabled={!permissions?.permissions.includes("users_edit")}
        onClick={() => setsubmit(true)}
        className="btn_ btn_edit "
      />
    </Toolbar>
  );
  const permissionChoices = [
    permissions === "ADMIN" ? { id: "ADMIN", name: "ADMIN" } : null,
    permissions === "ADMIN" ? { id: "SITE", name: "SITE" } : null,
    permissions === "ADMIN" ? { id: "SITE", name: "SITE" } : null,
    ["ADMIN", "SITE"].includes(permissions?.access_level)
      ? { id: "PLANT", name: "PLANT" }
      : null,
    ["ADMIN", "SITE"].includes(permissions?.access_level)
      ? { id: "PLANT", name: "PLANT" }
      : null,
    ["ADMIN", "SITE", "SITE", "PLANT", "PLANT"].includes(
      permissions?.access_level
    )
      ? { id: "SUB", name: "SUB" }
      : null,
    { id: "BASIC", name: "BASIC" },
  ];

  const usersSite = localStorage.getItem("site");
  const usersPlant = localStorage.getItem("plant");

  const Title = ({ record }) => {
    return <span>Edit User | {record ? `${record.username} ` : ""}</span>;
  };

  const customToast = useCustomToast(props);

  return (
    <Edit
      undoable={false}
      // successMessage={`User updated successfully`}
      onSuccess={customToast.onSuccess}
      // onFailure={customToast.onFaliure}
      {...props}
      title={<Title />}
      className={"col-md-6 mt-5"}
      // showNotification={false}
    >
      <SimpleForm
        validate={validateUser}
        toolbar={<CustomToolbar name="users" text="User" />}
      >
        <TextInput disabled source="id" />
        <TextInput
          source="first_name"
          label={<ReqInputField name="First name" />}
        />

        <TextInput
          source="last_name"
          label={<ReqInputField name="Last name" />}
        />
        <TextInput
          source="username"
          label={<ReqInputField name="Username" />}
        />
        <TextInput source="email" label={<ReqInputField name="Email" />} />
        <ReferenceInput
          source="app_user.role"
          reference="roles"
          label={<ReqInputField name="Role" />}
          perPage={1000}
        >
          <SelectInput translateChoice={false} optionText="code" label="role" />
        </ReferenceInput>
        {["ADMIN", "SITE", "TNA_ADMIN","TNA_CLUSTER_MANAGER","TNA_DEP_ADMIN"].includes(
          permissions?.access_level
        ) === true ? (
          <TextInput
            source="password"
            label={<ReqInputField name="Password" />}
          />
        ) : null}
      </SimpleForm>
    </Edit>
  );
};
