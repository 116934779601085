import { DemandOperation } from "./../../../models/index";
import {
  getColorMappings,
  getDemandOperations,
} from "./../../selectors/planBoardSelectors";
// import { PlanBoardManager } from "../../lib/plan_board/planBoardManager";
import _ from "lodash";
import moment from "moment";
import { call, cancel, fork, put, select } from "redux-saga/effects";
import dataProvider from "../../../api/dataProvider";
import { format_to_date, format_to_date_time } from "../../../lib/date_time";
import { exportExcel } from "../../../lib/excelExport";
import { IAllocation, IPlan } from "../../../lib/plan";
import { showToastError, showToastSuccess } from "../../../lib/toas";
import { Allocation, Line } from "../../../models";
import {
  addChangedOrderCode,
  clearPendingAction,
  deleteSection,
  loadPlan,
  loadPlanBoard,
  loadPlanBoardRevision,
  loadPlanBoardRevisionSuccess,
  loadPlanBoardSuccess,
  setIsEditing,
  setProgress,
  setSelectedGroup,
  setSelectedTimeRange,
} from "../../actions";
import {
  getActiveRevisionId,
  getAllocations,
  getEntity,
  getIsEditing,
  getPendingAction,
  getPlan,
  getPlanBoard,
  getSelectedEntity,
  getSelectedGroupId,
  getSelectedTimeRange,
} from "../../selectors";
import {
  CREATE_ALLOCATIONS,
  DELETE_ALLOCATIONS,
  MOVE_ALLOCATIONS,
} from "../../types";
import { prepare_revision, pre_process_data } from "./preProcess";
import { isWeekend } from "date-fns";
import { AddLocation } from "@material-ui/icons";

export const loadPlanSaga = function* (action: any) {
  try {
    yield put(setProgress(0));
    const { id, start_time: timeStart, end_time: timeEnd } = action.payload;
    const resp = yield dataProvider.CUSTOM("planBoards", {
      id,
      method: "GET",
      action: `get_planboard_data_new`,
      query: { timeStart, timeEnd },
    });
    const resp2 = yield dataProvider.CUSTOM("planBoards", {
      id: id,
      method: "POST",
      action: "user_plan_edit",
      body: "load",
    });
    if (resp2.data[0] === "already_editing") {
      yield put(setIsEditing(resp2.data[1]));
    } else {
      yield put(setIsEditing("no"));
    }

    const plan = pre_process_data(resp.data);
    yield put(loadPlanBoardSuccess(plan));
    yield put(loadPlan(plan));
    yield put(loadPlanBoardRevision(plan.planboard.draft_revision_id));
  } catch (e) {
    showToastError("Failed to load plan board");
  }
};

export const loadRevisionSaga = function* (action: any) {
  const { id } = action.payload;
  const planBoard = yield select(getPlanBoard);
  if (planBoard) {
    const planBoardId = planBoard.id;
    const entities = yield select(getPlan);
    const resp = yield dataProvider.CUSTOM("planRevision", {
      id,
      action: "get_one",
    });
    const data = resp.data[0].data;
    // console.log(resp.data)
    const revision = prepare_revision(data, entities);
    yield put(loadPlan(revision));
    yield put(loadPlanBoardRevisionSuccess(id, revision));
  }

  // yield dataProvider.update("planRevision", { id: planBoardId, data: {draft_revision_id: id} });
};

export const selectEntitySaga = function* (action: any) {
  const { type, id } = action.payload;
  const selectedEntity = yield select(getSelectedEntity);
  const selectedGroup = yield select(getSelectedGroupId);
  let scrol_time = null; // Timestap to scroll
  let selected_group_to_change = null;
  if (type === "allocations") {
    scrol_time = selectedEntity.start_time.valueOf();
    const line = (yield select((state) =>
      getEntity(state, "lines", selectedEntity.plan_board_line_id)
    )) as Line;
    selected_group_to_change = line.plan_board_group_id;
  } else if (type === "demand_operations") {
    scrol_time = selectedEntity.planned_start_date?.valueOf();
    const allocations = (yield select((state) =>
      getAllocations(state)
    )) as Allocation[];
    const getAllocasFromSameDemandOps = (a: Allocation) =>
      a.demand_operation_id === selectedEntity?.id;
    const first_alloc = _.minBy(
      allocations.filter(getAllocasFromSameDemandOps),
      "start_time"
    );
    const line = (yield select((state) =>
      getEntity(state, "lines", first_alloc?.plan_board_line_id)
    )) as Line;
    selected_group_to_change = line?.plan_board_group_id;
  }

  if (selected_group_to_change && selectedGroup) {
    yield put(setSelectedGroup(selected_group_to_change));
  }

  if (scrol_time) {
    const { start_time, end_time } = yield select(getSelectedTimeRange);

    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));

    if (
      scrol_time < start_time ||
      scrol_time > end_time ||
      type === "demand_operations"
    ) {
      yield delay(50);
      const duration_millis = end_time - start_time;
      const padding = duration_millis / 10;
      const new_start = scrol_time - padding,
        new_end = scrol_time + duration_millis - padding;
      yield put(setSelectedTimeRange(new_start, new_end));
    }
  }
};

export const toggleEditSage = function* (action: any) {
  // const { id, data } = action.payload;
  const planBoard = yield select(getPlanBoard);
  const planBoardId = planBoard.id;
  const is_user_editing = yield select(getIsEditing);
  const resp = yield dataProvider.CUSTOM("planBoards", {
    id: planBoardId,
    method: "POST",
    action: "user_plan_edit",
    body: "load",
  });
  if (resp.data == "can_edit") {
    if (is_user_editing === "no") {
      const resp = yield dataProvider.CUSTOM("planBoards", {
        id: planBoardId,
        method: "POST",
        action: "user_plan_edit",
        body: "start",
      });
      yield put(setIsEditing("user"));
    }
  } else if (is_user_editing === "user") {
    const resp = yield dataProvider.CUSTOM("planBoards", {
      id: planBoardId,
      method: "POST",
      action: "user_plan_edit",
      body: "stop",
    });
    yield put(setIsEditing("no"));
  } else {
    yield put(loadPlanBoard(planBoardId));
  }
  // yield put(loadPlanBoard(id));
};

export const savePlanBoardSaga = function* (action: any) {
  const { change_reasons } = action.payload;
  const currentAllocations: IAllocation[] = yield select(getAllocations);
  const { id: planBoardId } = yield select(getPlanBoard);
  // const changedAllocationIds = Object.keys(currentAllocations).filter(
  //     (k) => !currentAllocations[k].deleted
  // );
  // GEt allocations. ANd remove the is_tools_available_attribute.
  const changedAllocations = currentAllocations
    .filter((a) => !a.deleted)
    .map(({ is_tools_availabile, ...rest }) => rest)
    .map((entry: any) => ({
      ...entry,
      start_time: entry.start_time.valueOf(),
      end_time: entry.end_time.valueOf(),
    }))
    .map(({ id, ...rest }) => (isNaN(id) ? rest : { id, ...rest })); // Drop the id field if it is not a number. New allocations
  const resp = yield dataProvider.CUSTOM("planBoards", {
    id: planBoardId,
    method: "POST",
    action: "save_changes_new",
    body: {
      allocations: changedAllocations,
      message: ["lkj"],
      change_reasons: change_reasons,
    },
  });

  if ((resp.data.success = true)) {
    showToastSuccess("Saved");
    yield put(loadPlanBoardRevision(resp.data.data.revision));
  }
  // yield put(loadPlanBoard(planBoardId));
};

export const exportPlanBoardSage = function* (action: any) {
  const entities = yield select(getPlan);
  const { start, end, lines } = action.payload;

  

  const buckets = entities.buckets;
  const bucketsArray = Object.values(buckets);

  const allAlo = (yield select((state) =>
    getAllocations(state)
  )) as Allocation[];
  const planboard = yield select(getPlanBoard);
  const publishedAllocations = yield select(
    (state) => state.custom.plan.current.published_allocations
  );
  const demand_operations = Object.values(
    (yield select((state) => getDemandOperations(state))) as DemandOperation[]
  );

 
  

  //filter allocations given date range
  const filterAllocations = () => {
    const releventAllocs=allAlo.filter((element)=>element.type!="OPERATION_LOSS")
    const Allallocs = Object.values(releventAllocs);

    //format data for csv
    const AloArray = Allallocs.filter(
      ({ end_time, start_time, plan_board_line_id, line_bucket_id }) => {
        // if have line ids
        const bucket = entities.buckets[line_bucket_id];
        if (lines.length > 0) {
          return (
            moment(bucket?.start_time).startOf("day").valueOf() >= start &&
            moment(bucket?.start_time).startOf("day").valueOf() < end &&
            lines.includes(plan_board_line_id)
          );
        } else {
          return (
            moment(bucket.start_time).startOf("day").valueOf() >= start &&
            moment(bucket.start_time).startOf("day").valueOf() < end
          );
        }
      }
    );

    const a = AloArray.map((data) => data);
    const allocs = AloArray.map((allocation: any) => {
      const publishedAllo = publishedAllocations[allocation.id];
      const { start_time, end_time, line_bucket_id } = allocation;
      const demand_operation =
        entities.demand_operations[allocation.demand_operation_id] || {};

      const order_group_demand_operations = demand_operations.filter(
        (d: any) => d.order_group_code === demand_operation.order_group_code
      );

      const order_group_planned_start_date = _.minBy(
        order_group_demand_operations,
        "planned_start_date"
      )?.planned_start_date;
      const order_group_planned_end_date = _.maxBy(
        order_group_demand_operations,
        "planned_end_date"
      )?.planned_end_date;
      // console.log(
      //   order_group_demand_operations.map((d: any) => ({
      //     a: d.planned_start_date,
      //     b: d.planned_end_date,
      //   })),
      //   order_group_planned_start_date,
      //   order_group_planned_end_date
      // );
      const line = entities.lines[allocation.plan_board_line_id];
      const filteredBuckets = bucketsArray.filter(
        (b: any) => b.line_id === line.id
      );
      const bucketsArrayAsc = filteredBuckets.sort(
        (a: any, b: any) =>
          moment(a.start_time).valueOf() - moment(b.start_time).valueOf()
      );
      const bucketsArrayDesc = filteredBuckets.map((a: any) => a);
      bucketsArrayDesc.reverse();
      const bucket = buckets[line_bucket_id];


      
      const {
        item_code,
        order_code,
        item_unit,
        compound_codes,
        planned_start_date,
        rm_ready_date,
        required_time_max,
        operation_code,
        smv,
        order_release_number,
        order_week_number,
        item_attributes,
        order_required_date,
      } = demand_operation;
      // console.log(item_attributes);
      const head = line ? line.code : "-";
      const efficiency = bucket ? bucket.efficiency : 0;
      const carder = bucket ? bucket.carder : 0;
      const closestWorkingDay = (backwards: boolean, date: any) => {
        const factor = backwards ? 0 : 1;

        var currentDate = moment(date).add(factor, "days").startOf("day");
        const buckets = !backwards ? bucketsArrayAsc : bucketsArrayDesc;
        //
        const filteredBuckets = buckets;
        const val: any = !backwards
          ? filteredBuckets.find((b: any) => b && b.start_time > currentDate)
          : filteredBuckets.find((b: any) => b && b.start_time < currentDate);
        return val ? val.start_time : "-";
      };

      const getPrevAllocQuantities = () => {
        const prevAllocs = allocation.demand_operation_id
          ? Allallocs.filter(
              (a: any) =>
                a.demand_operation_id === allocation.demand_operation_id &&
                a.start_time < allocation.start_time
            )
          : [{ quantity: 0 }];
        const prevAllocSum = _.sumBy(prevAllocs, "quantity");
        // console.log({prevAllocs, prevAllocSum})
        return `${prevAllocSum}-${prevAllocSum + allocation.quantity}`;
      };

      const unit_changed_smv =
        smv && planboard.export_smvunit === "HOURS"
          ? smv
          : smv && planboard.export_smvunit === "MINUTES"
          ? smv * 60
          : 0;

      const alloc_duration =
        start_time && end_time && planboard.export_smvunit === "HOURS"
          ? (end_time - start_time) / (1000 * 60 * 60)
          : start_time && end_time && planboard.export_smvunit === "MINUTES"
          ? (end_time - start_time) / (1000 * 60)
          : 0;

      const bucket_start_time = moment(bucket.start_time);

      const mold =
        planboard.export_mold_selection_attribute !== "" &&
        item_attributes &&
        planboard.export_mold_selection_attribute in item_attributes
          ? item_attributes[planboard.export_mold_selection_attribute]
          : allocation.configuration;

      const itemCode =
        planboard.export_item_code_selection_attribute !== "" &&
        item_attributes &&
        planboard.export_item_code_selection_attribute in item_attributes
          ? item_attributes[planboard.export_item_code_selection_attribute]
          : item_code;


          

      if (planboard.export_type === "SCOM") {
        return {
          HEAD: head,
          "ITEM CODE": itemCode,

          bucket: line_bucket_id,
          bucketDateLine: `${bucket_start_time.startOf("day")}}-${
            bucket.line_id
          }`,
          BTCHS: allocation.quantity,
          start_time,
          bucket_start_time: bucket.start_time,
          "LOT BATCH NO.": order_code,
          "ALLOC RANGE": getPrevAllocQuantities(),
          "TIME/BTCH": (unit_changed_smv / efficiency).toFixed(2),
          DELIVERY: required_time_max,
          "TOTAL TIME": Math.round(alloc_duration * 100) / 100,
          "Order Required Date":  order_required_date,
        };
      } else if (planboard.export_type === "SCOM_DATEWISE") {
        const line = planboard.plan_export_columns.includes("Head")
          ? head
          : null;

        return {
          Head: line,
          "Order No.": order_code,
          "Part No.": itemCode,
          Compound: compound_codes,
          Description: " ",
          "Order Qty": allocation.quantity,
          Time: Math.round(alloc_duration * 100) / 100,
          bucket_start_time: bucket.start_time,
          mold: mold,
          "Start Time": start_time,
          "Order Required Date":  moment(order_required_date).format("DD-MM-YYYY"),
        };
      } else {
        return {
          head,
          Mold: mold,
          "Order Code": order_code,
          "Release number": order_release_number
            ? isNaN(order_release_number)
              ? order_release_number
              : Number(order_release_number)
            : "",
          "Item Code": itemCode,
          Quantity: allocation.quantity,
          Unit: item_unit,
          "Week No": order_week_number,
          "Start Time": start_time,
          // "End Time": format_to_date_time(end_time),
          "P.S.D.": planned_start_date,
          "RM Ready Date": rm_ready_date,
          "Order Required Date": order_required_date,
          "Need Date(max)": required_time_max,
          "Operation Code": operation_code,
          SMV: unit_changed_smv,
          bucket: line_bucket_id,
          bucketDateLine: `${moment(bucket.start_time).startOf("day")}-${
            bucket.line_id
          }`,
          // "Total shift quantity": total_in_shift,
          // "Shifts": shift_count
          "Planned O. G. S. D.": order_group_planned_start_date || "",
          "Planned O. G. E. D.": order_group_planned_end_date || "",
          "P. O. G. S. D. - 1": order_group_planned_start_date
            ? closestWorkingDay(true, order_group_planned_start_date)
            : "",
          "P. O. G. E. D. + 1": order_group_planned_end_date
            ? closestWorkingDay(false, order_group_planned_end_date)
            : "",
          Efficiency: efficiency,
          Carder: carder,
          "Shift Time (H)":
            (bucket.end_time.valueOf() - bucket.start_time.valueOf()) /
            (3600 * 1000),
          "Sequence No.": parseFloat(line.sequence_number)
        };
      }
    });
    // sort data

    const sortedAlloc = _.sortBy(allocs, [
      "Sequence No.",
      "head",
      "HEAD",
      "Head",
      "Start Time",
      'start_time'
    ]).map((alloc) => ({
      ...alloc,
    }));

    if (!planboard.plan_export_columns[0]) {
      //if no plan_export_columns are selected return error toast
      return showToastError(
        "No columns selected to export! Please select columns from 'planboard edit' section"
      );
    } else return exportExcel(sortedAlloc, { start, end }, planboard, "plan");
    // return exportCsv(sortedAlloc, "plan");
  };

  filterAllocations();
};

export const planAutomaticSaga = function* (action: any) {
  const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));
  function* tick() {
    const planBoard = yield select(getPlanBoard);
    const { id: planBoardId } = planBoard;

    while (true) {
      yield call(delay, 10000);

      const data = yield dataProvider
        .getOne("planBoards", { id: planBoardId })
        .then(({ data }: any) => {
          return data;
        });
      yield put(setProgress(data.progress));
      yield put({ type: "TICK" });
    }
  }
  const { id, data } = action.payload;
  const revisionId = yield select(getActiveRevisionId);
  // const bgSyncTask = yield fork(tick);

  
  try {
    const resp = yield dataProvider
      .CUSTOM("planBoards", {
        id,
        method: "POST",
        action: `plan`,
        body: { ...data, revision_id: revisionId },
      })
      .catch(function* () {
        alert("Failed to plan");
        yield put(loadPlanBoard(id));
      });
    const plan_response = resp.data;
    if (!plan_response.success) {
      yield put(setProgress(0));
      showToastError("Planning Failed");
      yield put(loadPlanBoard(id));
    } 
    else {
      yield put(setProgress(100));
      yield call(delay, 500);
      const { revision_id } = plan_response.data;
      showToastSuccess("Planning Success");
      yield put(loadPlanBoardRevision(revision_id));
    }
  } catch (e) {
    showToastError("Planning Failed");
    yield put(loadPlanBoard(id));
  }
};

export const publishPlanBoarRevisionSaga = function* (action: any) {
  const revisionId = yield select(getActiveRevisionId);
  const { id } = yield select(getPlanBoard);
  try {
    const resp = yield dataProvider
      .CUSTOM("planBoards", {
        id,
        method: "POST",
        action: `publish`,
        body: { revision_id: revisionId },
      })
      .catch(function* () {
        alert("Failed to plan");
        yield put(loadPlanBoard(id));
      });
    showToastSuccess("Publish Success");
    yield put(loadPlanBoard(id));
  } catch (e) {
    showToastError("Publish Failed");
    yield put(loadPlanBoard(id));
  }
};

export const deletePlanSaga = function* (action: any) {
  const { id, data } = action.payload;
  const { start_time, end_time, lines } = data;
  // const allocations = yield select((state) => getAllocations(state));
  const start = new Date(start_time);
  yield put(deleteSection(start, lines));
  // const filter_allocations = (allocation: Allocation) => {
  //     return (
  //         allocation.start_time > start &&
  //         (!lines ||
  //             lines.length == 0 ||
  //             lines.indexOf(`${allocation.plan_board_line_id}`) > -1)
  //     );
  // };
  // const deleted_allocations = allocations.filter(filter_allocations);
  // const change = _.keyBy(
  //     deleted_allocations.map((d: any) => ({ ...d, deleted: true })),
  //     "id"
  // );
  // yield put((change));
  // const resp = yield dataProvider.CUSTOM("planBoards", {
  //   id,
  //   method: "POST",
  //   action: `delete`,
  //   body: data,
  // });
  // yield put(loadPlanBoard(id));
};

const getChangedOrderCode = (plan: IPlan, pendingAction: any) => {
  const { type, payload } = pendingAction;
  const getCode = (demandOpId: number) => {
    return plan.demand_operations[demandOpId]?.order_group_code;
  };

  if (type === MOVE_ALLOCATIONS || type === DELETE_ALLOCATIONS) {
    return getCode(plan.allocations[payload.allocation_id].demand_operation_id);
  }
  if (type === CREATE_ALLOCATIONS) {
    return getCode(payload.demand_operation_id);
  }
};

export const applyPendingActionSaga = function* (action: any) {
  const pendingAction = yield select(getPendingAction);
  if (pendingAction) {
    const plan = yield select(getPlan);
    const changedOrderCode = getChangedOrderCode(plan, pendingAction);
    yield put(pendingAction);
    if (changedOrderCode) {
      yield put(addChangedOrderCode(changedOrderCode));
    }
    yield put(clearPendingAction());
  }
};
