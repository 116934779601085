import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import Confirm from "../../components/Confirmation";


export const Calendar = (props) => {

  return(
  <List {...props}>
    <Datagrid rowClick={"show"}>
      <TextField source="id" />
      <TextField source="name" />
      <Confirm text="calendar." />
    </Datagrid>
  </List>
);
  };
