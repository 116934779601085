import React from "react";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.itemOperation) {
    errors.itemOperation = ["item operation is required"];
  }
  if (!values.planBoardLine) {
    errors.planBoardLine = ["Plan Board Line is required"];
  }
  if (!values._type) {
    errors._type = ["_type is required"];
  }
  if (!values.smv) {
    errors.smv = ["smv is required"];
  }
  if (!values.priority) {
    errors.priority = ["priority is required"];
  }

  return errors;
};

export const CreatePlanBoardLineCompatibility = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Create
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm validate={validate} rowClick="edit" redirect="list">
        <ReferenceInput
          label="Item operation"
          source="item_operation"
          reference="itemOp"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput
          label="Plan Board Line"
          source="plan_board_line"
          reference="boardLine"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <TextInput source="performance_multiplier" />
        <TextInput source="priority" />
      </SimpleForm>
    </Create>
  );
};
