import React, { useState } from 'react'
import CSVReader from "react-csv-reader"
import { useSelector } from 'react-redux'
import { keys } from 'ts-transformer-keys'


interface Props<T> {
    onLoad: (data: any) => void
    headers: string[]
    template_file_name: string
}

export default function CsvLoader<T extends object>({ onLoad, headers, template_file_name }: Props<T>) {
    const [selectorKey, setSelectorKey] = useState(0)
    const onFileLoad = (data: any) => {
        let cleanData = data as any[]
        cleanData = cleanData.filter(v => v.length == cleanData[0].length)
        onLoad(cleanData.slice(1))
        setSelectorKey(v => v + 1)
    }
    const downloadTemplate = () => {
        const element = document.createElement('a')
        const text = headers.join(",")
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
        element.setAttribute('download', `${template_file_name}.csv`)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button onClick={downloadTemplate}>Download Template</button>
            <span>|</span>
            <CSVReader key={selectorKey} onFileLoaded={onFileLoad}></CSVReader>
        </div>
    )
}
