
export function createUndoableReducer<A>(reducer: (state: A | undefined, action?: any) => A, actionPrifix: string = "", setActionType: string){

    type State = {
        current: A;
        past: A[];
        future: A[];

    }

    const getInitialState = (state: A | undefined, action: any) => {
        return {
            current: reducer(state, action),
            past: [] as A[],
            future: [] as A[]
        } as State
    }

    return function (state: State | undefined, action: any): State {
        if (state === undefined) {
            return getInitialState(state, action) as State
        }
        if (action.type === `${actionPrifix}UNDO`) {
            const past = state.past.slice(0, state.past.length - 1)
            const current = state.past[state.past.length - 1]
            const future = [...state.future, state.current]
            return { ...state, current, past, future }
        }
        if (action.type === `${actionPrifix}REDO`) {
            const past = [...state.past, state.current]
            const current = state.future[state.future.length - 1]
            const future = state.future.slice(0, state.future.length - 1)
            return { ...state, past, current, future }
        }
        if (action.type === `${actionPrifix}CLEAR`) {
            const past = [] as any[]
            const current = state.current
            const future = [] as any[]
            return { ...state, past, current, future }
        }
        const nextState = reducer(state.current, action)
        if (nextState != state.current) {
            if (setActionType === action.type) {
                return { ...getInitialState(state.current, action), current: nextState }
            }
            return { ...state, current: nextState, past: [...state.past, state.current], future: [] }
        }
        return state
    }
}

export function getCurrentState<A>(state: { current: A }) {
    return state.current
}