import React from "react";
import { Layout } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";
import Error from "./ErrorPage";
import FooterStripe from "../components/footer/FooterStripe";

const CustomLayout = (props) => (
  <>
    <Layout {...props} appBar={AppBar} error={Error} menu={Menu} />
    <FooterStripe />
  </>
);

export default CustomLayout;
