import { SAVE_HOLIDAYS_SUCCESS, SET_INITIAL_STATE } from "../types/tnaHolidayTypes";

export const initialState = {
    success: null
}

export const tnaHolidayReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SAVE_HOLIDAYS_SUCCESS: {
            return {...state, success: action.payload}
        }
        case SET_INITIAL_STATE: {
            return initialState
        }
        default:
            return state
    }
}