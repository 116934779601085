import React from "react";
import {
  BooleanInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.order) {
    errors.order = ["Order is required"];
  }
  if (!values.demand) {
    errors.demand = ["demand is required"];
  }

  return errors;
};

export const EditOrderResolveOption = (props) => {
  const customToast = useCustomToast(props);
  return (
    <Edit
      {...props}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm validate={validate} rowClick="edit">
        <ReferenceInput label="order" source="order" reference="orders">
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput
          label="order demand"
          source="demand"
          reference="demands"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <BooleanInput source="order_demand" />
        <BooleanInput source="force_select" />
        <BooleanInput source="selected" />
      </SimpleForm>
    </Edit>
  );
};
