import React from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  usePermissions,
} from "react-admin";
import Confirmation from "../../components/Confirmation";
import CreateButton from "../../components/CustomCreateButton";
import {
  TopToolbar,
  // CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
} from "react-admin";
import { cloneElement, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../components/ChangeDiv";
import { CustomDatagrid } from "../../components/CustomDatagrid";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by username" source="username" alwaysOn />
  </Filter>
);

const CustomField = ({ source, record = {}, fields }) => {
  let obj = record[source];

  fields.forEach((f) => (obj = obj ? obj[f] : null));
  return <span>{obj || "N/A"}</span>;
};

export const Users = (props) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const Actions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
    } = useListContext();

    return (
      <TopToolbar
        className={classes.buttonContainer}
        {...sanitizeListRestProps(rest)}
      >
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
            className: "btn_",
          })}
        <CreateButton
          basePath={basePath}
          title="Click here to create Users"
          disabled={!permissions?.permissions.includes("users_create")}
        />
        <ExportButton
          className="btn_ btn_primary"
          title="Click here to export"
          disabled={!permissions?.permissions.includes("users_export")}
        />
      </TopToolbar>
    );
  };

  return (
    <List
      filters={<UserFilter />}
      actions={<Actions />}
      perPage={25}
      {...props}
      bulkActionButtons={false}
    >
      <ChangeDiv>
        {props => {
          return <CustomDatagrid resource={props.resource}>
          <TextField width="15%" source="username" />
          <TextField width="15%" source="email" />
          <CustomField  width="16%" source="app_user" label="Role" fields={["role_code"]} sortBy ="app_user__role__code" />
          {permissions?.permissions.some((p) => p.includes("tna_")) && (
            <CustomField
            width="12%"
              source="app_user"
              label="Department"
              fields={["tna_department", "name"]}
              sortBy = "app_user__role__tna_department"
            />
          )}
          {permissions?.permissions.includes("users_edit") ? (
            <EditButton
            width="8%"
              title="Click here to Edit Users"
              className="btn_ btn_edit"
            />
          ) : null}
          {permissions?.permissions.includes("users_delete") ? (
            <Confirmation
            width="8%"
              text="user"
              title="Click here to Delete Users"
              nameField="username"
            />
          ) : null}
        </CustomDatagrid>
        }}
      </ChangeDiv>
      
    </List>
  );
};
