import _ from "lodash"
import moment from "moment"
import { IAllocation, IBucket, IDemandOperation, IPlan, ITool } from "../../../lib/plan"
import { get_bucket_sequence, get_calculated_fields_updated } from "../../../lib/plan/helpers"
import { getUuid } from "../../../lib/plan_board/utils"


const fixDate = (v: any) => v ? new Date(v) : undefined
const mapKeys = (object: any, keys: string[], func: (a: any) => any) => {
    const update = {} as any
    keys.map(k => {
        update[k] = func(object[k])
    })

    return { ...object, ...update }
}

const formatDemandOperation = (d: IDemandOperation) => {
    const dateFields = ["required_time_max",
        "required_time_min",
        "rm_ready_date",
        "planned_start_date",
        "order_required_date",
        "order_confirmation_date",
        "planned_end_date"]
    return { ...mapKeys(d, dateFields, fixDate), configuration: d.configuration.toUpperCase() } as IDemandOperation
}

const formatAllocation = (d: IAllocation) => {
    const dateFields = ["start_time", "end_time"]
    return mapKeys(d, dateFields, fixDate)
}

const formatTool = (t: ITool) => {
    return t
}

const formatBucket = (b: IBucket) => {
    return mapKeys(b, ["start_time", "end_time"], fixDate)
}



export const pre_process_data = (entities: any) => {

    entities = getConsolidatedComponents(entities);


    const allocations = _.keyBy(entities.allocations.map(formatAllocation), 'id')
    const plan = {
        ...entities,
        demand_operations: _.keyBy(entities.demand_operations.map(formatDemandOperation), 'id'),
        groups: _.keyBy(entities.groups, 'id'),
        lines: _.keyBy(entities.lines, 'id'),
        buckets: _.keyBy(entities.buckets.map(formatBucket), 'id'),
        allocations: {},
        tools: _.keyBy(entities.tools.map(formatTool), 'id'),
        published_allocations: allocations,
        // .filter((item: Allocation) => item.plan_board_line_id === 38 || item.plan_board_line_id == 34), 'id')
        changed_order_group_codes: {}
    } as IPlan

    return get_calculated_fields_updated(plan)
}





const getConsolidatedComponents = (entities: any) => {

    entities.demand_operations.forEach((operation: any) => {
        operation.compound_codes = operation.compound_codes.join(", ")
    });

    return entities;
}

const dropSameTimeConfigChanges = (allocs: IAllocation[]) => {
    const job_allocations = _.filter(allocs, a => !!a.demand_operation_id)
    const config_changes = _.filter(allocs, a => !a.demand_operation_id)
    const unique_by_time_allocs = _.unionBy(config_changes, a => `${a.start_time.valueOf()}-${a.end_time.valueOf()}-${a.plan_board_line_id}`)
    return [...job_allocations, ...unique_by_time_allocs]
}

export const prepare_revision = (allocations: any[], plan: IPlan) => {
    const fixBuckeMissingAllocation = (allocation: IAllocation) => {
        if (!plan.buckets[allocation.line_bucket_id]) {
            const line_buckets = get_bucket_sequence(plan, allocation.plan_board_line_id, allocation.start_time)
            if (line_buckets.length > 0) {
                return { ...allocation, line_bucket_id: line_buckets[0].id } as IAllocation
            }
            console.log("Fail to fix bucket", allocation);
        }
        return allocation
    }
    const filterImpossibleAllocations = (allocation: IAllocation) => {
        if (!plan.buckets[allocation.line_bucket_id]) {
            console.log("Drop allocation because bucket is missing", allocation)
            return false
        }

        // Config change
        if (!allocation.demand_operation_id) {
            if (moment(allocation.end_time) < moment().subtract(14, 'day')) {
                return false
            }
            return true
        }

        const demand_operation = plan.demand_operations[allocation.demand_operation_id]
        return (!!demand_operation && !!plan.lines[allocation.plan_board_line_id] && !demand_operation.is_exchange)
    }
    const allocList = allocations.map((d: any) => {
        if (!d.id) {
            d.id = getUuid()
        }
        if (d.id === 'None') {
            d.id = getUuid()
        }
        d.start_time = d.start_time * 1000
        d.end_time = d.end_time * 1000
        d.is_freezed = !!d.is_freezed
        d.completed_quantity = plan.published_allocations[d.id]?.completed_quantity || 0
        return d
    }).map(fixBuckeMissingAllocation).filter(filterImpossibleAllocations).map(formatAllocation)
    const allocsListUniq = dropSameTimeConfigChanges(allocList)

    const allocs = _.keyBy(allocsListUniq, 'id')
    const updatedPlan = { ...plan, allocations: allocs } as IPlan
    return get_calculated_fields_updated(updatedPlan)

}