import React, { Component } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './progressLoading.css'
import { PRIMARY_COLOR } from '../../constants';

class FooterPlanboardLoading extends React.Component {

    render() {
        //prevent progress from progress
        const path = window.location.href;
        const pathAry = path.split("/");
        const endName = pathAry[pathAry.length - 1];

        const planboards = this.props.planboards;
        const isLoginPage = endName == "login"

        if (isLoginPage || planboards.length == 0) {
            return null
        }

        return (
            <section style={footerMainStyle}>
                <table>
                    <tbody>
                        {planboards.map((p, i) => <PlanningBar key={i} planboard={p} />)}
                    </tbody>
                </table>
            </section>
        )
    }
}
const footerMainStyle = {
    left: "20vw",
    width: "60vw",
    opacity: 0.7,
    backgroundColor: PRIMARY_COLOR,
    color: 'white',
    borderRadius: 5,
    position: 'fixed',
    padding: 5,
    bottom: 10,
    zIndex: 1000
};
const planLodaingStyle = {
    padding: "5px",
};

const PlanningBar = ({ planboard }) => <tr id="plan-loading-pallet" style={planLodaingStyle}>
    <td style={{ textAlign: "center", width: "16%" }}>
        <span>{planboard.planboardname}</span>
    </td>
    <td style={{width: "70%"}}>
        <LinearProgress style={{ width: "100%" }} className={styles.MuiLinearProgressColorPrimary} variant="determinate" value={planboard.progress} />
    </td>
    <td style={{ textAlign: "center", width: "15%"  }}>
        <span >{planboard.progress}%</span>
    </td>
</tr>

export default FooterPlanboardLoading;