export const SET_DATE_RANGE = "SET_DATE_RANGE"
export const SET_SECTION = "SET_SECTION"

export const LOAD_HOLIDAYS = "LOAD_HOLIDAYS"
export const LOAD_HOLIDAYS_SUCCESS = "LOAD_HOLIDAYS_SUCCESS"
export const LOAD_HOLIDAYS_FAILED = "LOAD_HOLIDAYS_FAILED"
export const SET_SHIFT_PATTERNS = "SET_SHIFT_PATTERNS"

export const SET_FORMATTED_HOLIDAYS = "SET_FORMATTED_HOLIDAYS"

export const DELETE_HOLIDAY = "DELETE_HOLIDAY"
export const DELETE_HOLIDAY_SUCCESS = "DELETE_HOLIDAY_SUCCESS"
export const DELETE_HOLIDAY_FAILED = "DELETE_HOLIDAY_FAILED"

export const UPDATE_SHIFT_PATTERN = "UPDATE_SHIFT_PATTERN"
export const UPDATE_SHIFT_PATTERN_SUCCESS = "UPDATE_SHIFT_PATTERN_SUCCESS"
export const UPDATE_SHIFT_PATTERN_FAILED = "UPDATE_SHIFT_PATTERN_FAILED"

export const CREATE_HOLIDAY = "CREATE_HOLIDAY"
export const CREATE_HOLIDAY_SUCCESS = "CREATE_HOLIDAY_SUCCESS"
export const CREATE_HOLIDAY_FAILED = "CREATE_HOLIDAY_FAILED"