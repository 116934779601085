import _ from "lodash"
import { get_active_allocations } from "../helpers"
import { AllocationIdType, IAllocationPart, IAllocationSelection, IPlan } from "../interfaces"

export const demand_operation_allocation_selector = (plan: IPlan, allocation_id: AllocationIdType) => {
    const allocation = plan.allocations[allocation_id]
    const filter_same_demand_operation = (entry: IAllocationPart) => plan.allocations[entry.allocation_id].demand_operation_id === allocation.demand_operation_id
    return all_allocations_time_slot_forwart_selector(plan, allocation_id).filter(filter_same_demand_operation) as IAllocationSelection
}

export const same_config_allocation_selector = (plan: IPlan, allocation_id: AllocationIdType) => {
    const allocation = plan.allocations[allocation_id]
    const filter_same_demand_operation = (entry: IAllocationPart) => plan.allocations[entry.allocation_id].configuration === allocation.configuration
    return all_allocations_time_slot_forwart_selector(plan, allocation_id).filter(filter_same_demand_operation) as IAllocationSelection
}

export const demand_operation_group_allocation_selector = (plan: IPlan, allocation_id: AllocationIdType) => {
    const allocation = plan.allocations[allocation_id]
    const this_demand_operation = plan.demand_operations[allocation.demand_operation_id]
    const filter_same_demand_operation_group = (entry: IAllocationPart) => {
        const demand_operation = plan.demand_operations[plan.allocations[entry.allocation_id].demand_operation_id]
        return demand_operation && demand_operation.order_group_code === this_demand_operation?.order_group_code
    }
    return all_allocations_time_slot_forwart_selector(plan, allocation_id).filter(filter_same_demand_operation_group) as IAllocationSelection
}

export const single_allocation_selector = (plan: IPlan, allocation_id: AllocationIdType) => {
    const allocation = plan.allocations[allocation_id]
    return [{ allocation_id: allocation.id, quantity: plan.allocations[allocation_id].quantity, is_freezed: plan.allocations[allocation_id].is_freezed }] as IAllocationSelection
}

export const quantity_allocation_selector = (plan: IPlan, allocation_id: AllocationIdType, quantity: number) => {
    return [{ allocation_id: allocation_id, quantity: quantity }] as IAllocationSelection
}

export const all_allocations_time_slot_forwart_selector = (plan: IPlan, allocation_id: AllocationIdType) => {
    const allocation = plan.allocations[allocation_id]
    const line_id = allocation.plan_board_line_id
    return line_all_allocation_selector(plan, line_id).filter(a => plan.allocations[a.allocation_id].start_time >= allocation.start_time) as IAllocationSelection
}

export const line_all_allocation_selector = (plan: IPlan, line_id: number) => {

    const allocs = _.sortBy(
        get_active_allocations(plan)
            .filter(a => a.plan_board_line_id === line_id), "start_time")

    return allocs.map(alloc => ({ allocation_id: alloc.id, quantity: alloc.quantity, is_freezed: alloc.is_freezed })) as IAllocationSelection
}
