import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

const PromptButton = React.memo(function PromptButton({
  label = "Button",
  color,
  variant = "contained",
  prompt = "Are you sure?",
  promptDetails = false,
  onAccept,
  onCancel,
  disabled,
  title,
}) {
  const [show, setShow] = React.useState(false);
  const handleClose = () => {
    setShow(false);
    onCancel && onCancel();
  };
  const handleAccept = () => {
    setShow(false);
    onAccept && onAccept();
  };
  return (
    <>
      <Button
        className="btn_ btn_primary"
        onClick={() => setShow(true)}
        title={title}
        variant={variant}
        disabled={disabled}
      >
        {label}
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={show}
        onClose={handleClose}
        aria-label="Are you sure?"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle>{prompt}</DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>

        {promptDetails && <DialogContent>{promptDetails}</DialogContent>}
        <DialogActions>
          {/* <Button
            className="btn_ btn_primary"
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button> */}
          <Button
            className="btn_ btn_primary"
            onClick={handleAccept}
            variant="contained"
            key="button"
          >
            {label}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

PromptButton.type.displayName = "PromptButton";
export default PromptButton;
