import React, { useState, useEffect } from "react";
import { GRAFANA_URL } from "../../constants";
import { Error, Query, usePermissions } from "react-admin";
import SmallLoading from "../loading/SmallLoading";
import { MySelect } from "./Components";
import TextField from "@material-ui/core/TextField";
import useWindowSize from "@rehooks/window-size";
import TnaDashboard from "../../containers/TNA/dashboard";
import { theme as customTheme } from "../../layout/theme";
import { LightenDarkenColor } from "../../lib/shadeColors";
import _ from "lodash"
import NewCustomDash from "./NewCustomDash";

function GrafanaDashboard(props) {
  const payload = {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "code", order: "ASC" },
  };
  const [plant, setPlant] = useState("All");
  const handlePlantChange = (event) => {
    setPlant(event.target.value);
  };
  const handleStartDateChange = (event) => {
    setStart(new Date(event.target.value));
  };
  const handleEndDateChange = (event) => {
    setEnd(new Date(event.target.value));
  };
  const getFormattedDate = (temp) => {
    let year = temp.getFullYear();
    let date_ = temp.getDate();
    let month = temp.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    if (date_ < 10) {
      date_ = `0${date_}`;
    }
    const formatted_str = `${year}-${month}-${date_}`;
    return formatted_str;
  };
  
  const init_end = new Date();
  init_end.setHours(0, 0, 0, 0);
  const init_start = new Date();
  init_start.setHours(0, 0, 0, 0);
  init_start.setTime(init_end.getTime() - 7 * 24 * 3600 * 1000);
  const [start, setStart] = useState(init_start);
  const [end, setEnd] = useState(init_end);
  const [site, setSite] = useState();
  const handleSiteChange = (event) => {
    setSite(event.target.value);
    setPlant("All")
  };
  const { innerWidth, innerHeight } = useWindowSize();

  const [currentTime, setCurrentTime] = useState(new Date());
  const updateTime = () => setCurrentTime(new Date());
  useEffect(() => {
    var timer = setInterval(() => updateTime(), 30000);

    return function cleanup() {
      clearInterval(timer);
    };
  });

  const { permissions } = usePermissions();
  if (permissions?.permissions.includes("dashboard")) {
    return (
      <Query type="getList" resource="sites" payload={payload}>
        {({ data, loading, error }) => {
          if (loading) {
            return <SmallLoading />;
          }
          if (error) {
            return <Error />;
          }

          if(!site){
            const userSiteId = localStorage.getItem('site');
            setSite(_.find(data, row => row.id === userSiteId) ? userSiteId : data[0].id);
            return null
          }

          const siteList = data.map(row => ({ value: row.id, label: row.code }))

          return (
            <Query type="getList" resource="plants" payload={payload}>
              {({ data, loading, error }) => {
                if (loading) {
                  return <SmallLoading />;
                }
                if (error) {
                  return <Error />;
                }

                const plantList = data.filter(p => p.site === site).map((p) => {
                  return { value: p.code, label: p.code };
                });
                if (["ADMIN", "SITE"].includes(permissions?.access_level)) {
                  plantList.push({ value: "All", label: "All" });
                } else {
                  setPlant(plantList[0].value);
                }

                return (
                  <div style={{ marginLeft: 5 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: innerHeight * 0.225,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: innerHeight * 0.1,
                          width: "100%",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          //marginTop: innerHeight*0.0075,
                          // marginBottom: innerHeight*0.01,
                        }}
                      >
                        <div
                          style={{
                            width: "86%",
                            // backgroundColor: '#25368b',
                            // borderRadius: 15,
                            // boxShadow: '10px 10px 5px #999',
                            display: "flex",
                            flexDirection: "row",
                            paddingLeft: "0.5%",
                            paddingRight: "3%",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "baseline",
                            }}
                          >
                            <div
                              style={{
                                color: customTheme.palette.primary.main,
                                fontSize: 36,
                                fontWeight: 700,
                                paddingTop: 5,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                //textTransform: 'uppercase',
                              }}
                            >
                              <p>Welcome to Stack Scheduler</p>
                              <p
                                style={{
                                  fontSize: 13,
                                  paddingLeft: 5,
                                  paddingBottom: 25,
                                }}
                              >
                                TM
                              </p>
                            </div>
                            <div
                              style={{
                                color: "#555",
                                fontWeight: "500",
                                marginLeft: 10,
                                fontSize: 22,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: LightenDarkenColor(
                              customTheme.palette.primary.btnSecondary,
                              0.6
                            ),
                            width: "10%",
                            height: "80%",
                            marginRight: 5,
                            borderRadius: 10,
                            borderWidth: 0.5,
                            borderStyle: "solid",
                            borderColor:
                              customTheme.palette.primary.btnSecondary,
                            //boxShadow: '0px 2px 2px #999',
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#000",
                          }}
                        >
                          <div
                            style={{
                              color: "#333",
                              fontSize: 20,
                              fontWeight: "700",
                            }}
                          >
                            {currentTime.toLocaleString("default", {
                              timeStyle: "short",
                            })}
                          </div>
                          <div
                            style={{
                              //   color: "#555",
                              fontWeight: "500",
                              fontSize: 12,
                              color: customTheme.palette.primary.main,
                            }}
                          >
                            {currentTime.toLocaleString("default", {
                              dateStyle: "long",
                            })}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          height: innerHeight * 0.11,
                          width: "100%",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Query
                          type="CUSTOM_LIST"
                          resource="dashboard"
                          payload={{
                            action: "get_order_completion_stats",
                            query: { plant: plant },
                          }}
                        >
                          {({ data, loading, error }) => {
                            if (error) {
                              console.log(data, "$$$");
                              return <Error />;
                            }
                            if (loading) {
                              return <SmallLoading />;
                            }
                            const {
                              completed_orders,
                              new_orders,
                              delayed_orders,
                              processing_orders,
                            } = data;

                            return (
                              <div
                                style={{
                                  height: "85%",
                                  width: "60%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    width: "24%",
                                    height: "100px",
                                    backgroundColor: "#aae6fd",
                                    borderRadius: 12,
                                    boxShadow: "0px 5px 5px #ececec",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={require("./asset1.png")}
                                    height="60%"
                                    style={{ marginRight: 5, marginLeft: 15 }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "100%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#333",
                                        fontSize: 30,
                                        fontWeight: "bold",
                                        lineHeight: "36px",
                                      }}
                                    >
                                      {new_orders}
                                    </div>
                                    <div
                                      style={{
                                        color: "#1295c7",
                                        fontSize: 14,
                                        //fontWeight: 700,
                                      }}
                                    >
                                      New orders
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "24%",
                                    height: "100px",
                                    backgroundColor: "#97dead",
                                    borderRadius: 15,
                                    boxShadow: "0px 5px 5px #ececec",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={require("./asset2.png")}
                                    height="35%"
                                    style={{ marginRight: 15, marginLeft: 25 }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "100%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#333",
                                        fontSize: 30,
                                        fontWeight: "bold",
                                        lineHeight: "36px",
                                      }}
                                    >
                                      {completed_orders}
                                    </div>
                                    <div
                                      style={{
                                        color: "#1c9642",
                                        fontSize: 14,
                                      }}
                                    >
                                      Completed Orders
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "24%",
                                    height: "100px",
                                    backgroundColor: "#f38080",
                                    borderRadius: 15,
                                    boxShadow: "0px 5px 5px #ececec",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={require("./asset3.png")}
                                    height="35%"
                                    style={{ marginRight: 15, marginLeft: 25 }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "100%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#333",
                                        fontSize: 30,
                                        fontWeight: "bold",
                                        lineHeight: "36px",
                                      }}
                                    >
                                      {delayed_orders}
                                    </div>
                                    <div
                                      style={{
                                        color: "#ad1f1f",
                                        fontSize: 14,
                                      }}
                                    >
                                      Delayed Orders
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "24%",
                                    height: "100px",
                                    backgroundColor: "#f9da85",
                                    borderRadius: 15,
                                    boxShadow: "0px 5px 5px #ececec",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={require("./asset4.png")}
                                    height="35%"
                                    style={{ marginRight: 15, marginLeft: 25 }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "100%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#333",
                                        fontSize: 30,
                                        fontWeight: "bold",
                                        lineHeight: "36px",
                                      }}
                                    >
                                      {processing_orders}
                                    </div>
                                    <div
                                      style={{
                                        color: "#9c770f",
                                        fontSize: 14,
                                      }}
                                    >
                                      Processing Orders
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        </Query>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: innerHeight * 0.085,
                            width: "35%",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 16,
                              color: "#333",
                              fontWeight: "500",
                              paddingBottom: 2.5,
                              marginBottom: "10px",
                            }}
                          >
                            Change Parameters and Visualize Graphs
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // height: '100%',
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {permissions.access_level === "ADMIN" && (
                              <>
                                <MySelect
                                  label="Site"
                                  menuItems={siteList}
                                  value={site}
                                  onChange={handleSiteChange}
                                  style={{ fontSize: "14px" }}
                                />
                                <div style={{ width: "30px" }} />
                              </>
                            )}
                            <MySelect
                              label="Plant"
                              menuItems={plantList}
                              value={plant}
                              onChange={handlePlantChange}
                              style={{ fontSize: "14px" }}
                            />
                            <TextField
                              id="start_time"
                              label="Start time"
                              type="date"
                              value={getFormattedDate(start)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                width: "35%",
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                              onChange={(e) => handleStartDateChange(e)}
                            />
                            <TextField
                              id="end_time"
                              label="End time"
                              type="date"
                              value={getFormattedDate(end)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              style={{
                                width: "35%",
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                              onChange={(e) => handleEndDateChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <Spacer /> */}
                      <NewCustomDash
                        {...{
                          site: siteList.find((val) => val.value === site).label,
                          plant,
                          start_date: start.getTime() / 1000,
                          end_date: end.getTime() / 1000,
                        }}
                      />
                  </div>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  } else if (permissions?.permissions.includes("tna_dashboard")) {
    return <TnaDashboard />;
  } else {
    return <p>You do not have permissions to view the dashboard.</p>;
  }
}

export default GrafanaDashboard;
