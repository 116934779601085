import React from "react";
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";
import { redirect } from "../../lib/redirect";

const validate = (values) => {
  const errors = {};
  if (!values.plan_board) {
    errors.plan_board = "PlanBoard is required";
  }
  if (!values.code) {
    errors.code = "Unique Code is required";
  }

  return errors;
};

export const EditPlanBoardLineGroup = (props) => {
  const Title = ({ record }) => {
    return (
      <span>Edit Planboard Group | {record ? `${record.code} ` : ""}</span>
    );
  };
  const customToast = useCustomToast({ redirect: redirect });
  return (
    <Edit
      {...props}
      title={<Title />}
      className="col-xl-6 col-sm-12"
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar need={false} name="planBoardGroups" text="planBoardGroups" />
        }
        validate={validate}
        // rowClick="edit"
      >
        <ReferenceInput
          label={<ReqInputField name="plan board" />}
          source="plan_board"
          reference="planBoards"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>

        <TextInput source="code" label={<ReqInputField name="code" />} />
        <BooleanInput source="consider_group_smv" label="Consider Group SMV" />
        <BooleanInput
          source="is_switching_time_script"
          label="Enable script for switching time"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.is_switching_time_script ? (
              <TextInput
                source="switching_time_script"
                {...rest}
                label="Switching Script"
              />
            ) : (
              <NumberInput
                source="switching_time"
                {...rest}
                label="Switching Time (Hours)"
              />
            )
          }
        </FormDataConsumer>
        <TextInput
          source="supported_colors"
          label="Supported Colors (Comma Separated)"
        />
        <NumberInput
          source="supported_color_count"
          label="Supported Color Count"
        />
      </SimpleForm>
    </Edit>
  );
};
