import { pl } from "date-fns/locale"
import _ from "lodash"
import { AllocationIdType, IPlan, ILinePart, IAllocationPart, IAllocation } from "../../../../lib/plan"
import { line_all_allocation_selector } from "../../../../lib/plan/allocation_selectors/selectors"
import { allocate_to_line } from "../../../../lib/plan/allocators/line_allocators"
import { add_allocation_to_plan, convert_allocation_parts_to_demand_operation_parts, delete_allocations_from_plan, get_calculated_fields_updated, select_first_n_by_quantity } from "../../../../lib/plan/helpers"
import { demandOperationSelectorMap } from "./shared"

export function createAllocationTransformer(plan: IPlan, action: any): IPlan {
    // TODO implement
    const { mode, quantity, demand_operation_id, to_line_id, to_time } = action.payload
    const selector = demandOperationSelectorMap[mode as (keyof typeof demandOperationSelectorMap)]
    const demandOparts = selector(plan, demand_operation_id)
    const toCreate = quantity ? select_first_n_by_quantity(demandOparts, "quantity", quantity) : demandOparts
    const toLineRest = line_all_allocation_selector(plan, to_line_id).sort(a => plan.allocations[a.allocation_id].start_time.getMilliseconds())
        .filter(a => plan.allocations[a.allocation_id].start_time >= to_time) // From the dropped location onward

    const partsToPushForward = convert_allocation_parts_to_demand_operation_parts(plan, toLineRest)

    const toPreviousAllocationId = _.maxBy(line_all_allocation_selector(plan, to_line_id)
        .filter(a => plan.allocations[a.allocation_id].start_time < to_time),
        a => plan.allocations[a.allocation_id].start_time)?.allocation_id as AllocationIdType

    const deletedAllocationIds = new Set<AllocationIdType>()
    const initConfig = plan.allocations[toPreviousAllocationId]?.configuration || ""
    const toWriteTime = new Date(_.max([to_time, plan.allocations[toPreviousAllocationId]?.end_time]))

    let part_from = toWriteTime;
    let part_to = part_from;
    let is_previous_alloc_freezed = false;
    const max_date = new Date(8640000000000000)
    const freezed_allocations: IAllocation[] = []

    const toLine_lineParts = toLineRest.length > 0 ? toLineRest.reduce((parts: ILinePart[], allocation: IAllocationPart, index: number) => {
        if (allocation.is_freezed) {
            part_to = plan.allocations[allocation.allocation_id].start_time;
            if (!is_previous_alloc_freezed) {
                parts.push({
                    start_time: part_from,
                    end_time: part_to,
                    line_id: plan.allocations[allocation.allocation_id].plan_board_line_id,
                    end_config: plan.allocations[allocation.allocation_id].configuration
                } as ILinePart)
            }
            part_from = plan.allocations[allocation.allocation_id].end_time;
            freezed_allocations.push(plan.allocations[allocation.allocation_id])
        }
        is_previous_alloc_freezed = allocation.is_freezed
        if (index == toLineRest.length-1) {
            parts.push({
                start_time: part_from,
                end_time: max_date,
                line_id: plan.allocations[allocation.allocation_id].plan_board_line_id,
                end_config: ""
            } as ILinePart)
        }
        return parts;
    },[]) : [{ start_time: part_from, end_time: max_date, end_config: '', line_id: to_line_id } as ILinePart]

    const newAllocations = allocate_to_line(plan, [...toCreate, ...partsToPushForward], toLine_lineParts, to_line_id, toWriteTime, initConfig)
    // Delete the rest of the allocation
    toLineRest.forEach(a => deletedAllocationIds.add(a.allocation_id))

    const addNewAllocations = (plan: IPlan) => add_allocation_to_plan(plan, newAllocations)
    const deleteOldAllocations = (plan: IPlan) => delete_allocations_from_plan(plan, deletedAllocationIds)
    const addFreezedAllocations = (plan: IPlan) => add_allocation_to_plan(plan, freezed_allocations)
    return _.flow([addNewAllocations, deleteOldAllocations, addFreezedAllocations, get_calculated_fields_updated])(plan)
}