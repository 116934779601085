import React, { cloneElement } from "react";
import {
  // CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  Filter,
  List,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  DateField,
  TextInput,
  TopToolbar,
  useListContext,
  usePermissions,
  WithPermissions,
  ReferenceField,
  CloneButton,
} from "react-admin";
import CreateButton from "../../../components/CustomCreateButton";
import ShowButton from "../../../components/CustomShowButton";
import Confirmation from "../../../components/Confirmation";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../../components/ChangeDiv";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const ListActions = (props) => {
  const classes = useStyles();

  const createPermissions = () =>
    !props.permissions?.permissions.includes("tna_templates_create");
  const exportPermissions = () =>
    !props.permissions?.permissions.includes("tna_templates_export");

  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar
      className={classes.buttonContainer}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
          className: "btn_",
        })}
      <CreateButton
        basePath={basePath}
        title="permissions"
        disabled={createPermissions()}
        variant="contained"
        color="primary"
      />
      <ExportButton
        className="btn_ btn_primary"
        title="permissions"
        disabled={exportPermissions()}
        resource={resource}
        sort={currentSort}
        maxResults={maxResults}
        // variant="contained"
        // color="primary"
      />
    </TopToolbar>
  );
};

const TemplatesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search Template Code" source="code" alwaysOn />
    <SelectInput
      source="type"
      choices={[
        { id: "All", name: "All" },
        { id: "ORDER_FLOW", name: "Order Templates" },
        { id: "INQUIRY_FLOW", name: "Inquiry Templates" },
      ]}
      alwaysOn
    />
    <SelectInput source="status"  choices={[
        { id: 'Active', name: 'Active' },
        { id: 'Inactive', name: 'Inactive' },
        { id: "All", name: "All" }
    ]}
    alwaysOn 
    />
  </Filter>
);

export const Templates = (props) => {
  const { permissions } = usePermissions();
  const Title = () => {
    return <span>TNA Templates</span>;
  };

  return (
    <List
      {...props}
      title={<Title/>}
      actions={<ListActions permissions={permissions} />}
      filters={<TemplatesFilter />}
      perPage={25}
    >
      <ChangeDiv>
        {props => {
          return <Datagrid>
          <TextField source="id" />
          <TextField source="type" />
          <TextField label="Template Code" source="code" />
          <TextField label="Description" source="description" />
          <TextField source="created_user" />
          <TextField source="modified_user" />
          <DateField label="Created time" source="created_timestamp" showTime={true} />
          <DateField label="Last modified time" source="modified_timestamp" showTime={true}/>
          <TextField source="status" />
          <CloneButton
            className="btn_ btn_secondry "
            disabled={
              !permissions?.permissions.includes("tna_templates_create")
            }
          />
          <ShowButton
            title="permissions"
            disabled={!permissions?.permissions.includes("tna_templates_show")}
          />
        </Datagrid>
        }}
      </ChangeDiv>
        
     
    </List>
  );
};
