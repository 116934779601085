import "../../layout/styles/input.css";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { usePermissions } from "react-admin";

import { setupReceived } from "../../store/actions";
import {
  getCurrentPlant,
  getDemandOps,
  getPlants,
  getWorkstations,
} from "../../store/selectors/transferSelectors";

const ReceivedDialog = ({
  selectedTransfer,
  plants,
  currentPlant,
  workstations,
  onSetupReceived,
  demandOperations,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const { permissions } = usePermissions();

  const operation_id_of_DOs = demandOperations
    ? demandOperations.filter((dO) =>
        selectedTransfer.demand_ops.includes(dO.id)
      )[0]
      ? demandOperations.filter((dO) =>
          selectedTransfer.demand_ops.includes(dO.id)
        )[0].operation_id
      : null
    : null;

  const compatible_workstations_of_DOs = demandOperations
    ? demandOperations.filter((dO) =>
        selectedTransfer.demand_ops.includes(dO.id)
      )[0]
      ? demandOperations.filter((dO) =>
          selectedTransfer.demand_ops.includes(dO.id)
        )[0].compatible_workstations
      : null
    : null;

  const handleClose = () => {
    setOpen(false);
  };

  const compatibleWorkstations = workstations
    ? workstations.filter((w) => w.operation === operation_id_of_DOs)
    : null;
  //   const selectedDoIds = selectedRows.map(dO => dO.id)

  const compatibleWorkstationsNew = workstations
    ? workstations.filter((w) =>
        selectedTransfer.compatible_workstations.includes(w.id)
      )
    : null;

  useEffect(() => {
    if (data == null) {
      setData({
        transferred_date: selectedTransfer.transfer_date,
        transfer_id: selectedTransfer.Id,
        // possible_start_date: new Date()
      });
    }
    // if (compatibleWorkstationsNew[0]) {
    //   setData(data => ({ ...data, workstation: compatibleWorkstationsNew[0].id}));
    // }
  }, []);

  // const selected = selectedRows[0];

  const onChange = (key, input) => {
    const value = input;
    setData((data) => ({ ...data, [key]: value }));
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        color="secondary"
        title="permissions"
        className="btn-success px-3 py-2"
        disabled={
          !permissions?.permissions.includes(
            "pendingReceivedTable_acceptDecline"
          )
        }
      >
        Accept/decline
      </Button>
      {data && selectedTransfer ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">{`Transfer Setup`}</DialogTitle>
          <DialogContent>
            <form>
              <Grid item md={12}>
                <Typography>Fill details for the transfer</Typography>
              </Grid>
              <Box display="flex">
                {/* <Grid item md={6}>
                <Card
                  variant="outlined"
                  style={{ minHeight: "250px", padding: "10px" }}
                >
                  <Box display="flex">
                    <Grid item md={3}>
                      <Typography>{`Id `}</Typography>
                      <Typography>{`Order code `}</Typography>
                      <Typography>{`Item code `}</Typography>
                      <Typography>{`Quantity `}</Typography>
                      <Typography>{`Need date `}</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography>{`: ${selected.id}`}</Typography>
                      <Typography>{`: ${selected.order_code}`}</Typography>
                      <Typography>{`: ${selected.item_code}`}</Typography>
                      <Typography>{`: ${selected.quantity}`}</Typography>
                      <Typography>{`: ${format_to_date_time(
                        selected.required_time_max
                      )}`}</Typography>
                    </Grid>
                  </Box>
                </Card>
              </Grid> */}
                <Grid item md={12}>
                  <Card
                    variant="outlined"
                    style={{ minHeight: "250px", padding: "10px" }}
                  >
                    {/* <MultipleSelect lines={lines}/> */}
                    {/* <Select
                      multiple
                      open={true}
                        value={age}
                    >
                      
                        <MenuItem value={"test"}>test</MenuItem>
            
                    </Select> */}
                    <Box display="flex">
                      <Grid item md={3}>
                        {/* <Typography style={{padding: '5px'}}>Transfer date</Typography>
                        <Typography style={{padding: '5px'}}>Received date</Typography> */}
                        <Typography style={{ padding: "5px" }}>
                          Select Workstation
                        </Typography>
                        {/* <Typography style={{ padding: "5px" }}>
                          Possible start date
                        </Typography> */}
                        {/* {data.plant === 'sub' ? <Typography style={{padding: '5px'}}>Sub contractor's name</Typography>: null} */}
                      </Grid>
                      <Grid item md={9}>
                        {/* <Typography>
                          :{" "}
                          <Input
                            disabled
                            type="date"
                            value={data.transfer_date}
                            onChange={e =>
                              onChange("transfer_date", e.target.value)
                            }
                          />
                        </Typography>
                        <Typography>
                          :{" "}
                          <Input
                            type="date"
                            value={data.received_date}
                            onChange={e =>
                              onChange("received_date", e.target.value)
                            }
                          />{" "}
                        </Typography> */}
                        <Typography>
                          :{" "}
                          <Select
                            style={{ paddingTop: "10px" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.workstation}
                            onChange={(e) =>
                              onChange("workstation", e.target.value)
                            }
                          >
                            {compatibleWorkstationsNew &&
                              compatibleWorkstationsNew.map((workstation) => (
                                <MenuItem value={workstation.id}>
                                  {workstation.code}
                                </MenuItem>
                              ))}
                          </Select>
                        </Typography>
                        {/* <Typography>
                          :{" "}
                          <DateInput
                            type="date"
                            value={data.possible_start_date}
                            onChange={e =>
                              onChange("possible_start_date", e)
                            }
                          />{" "}
                        </Typography> */}
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              style={{ padding: "10px" }}
            >
              close
            </Button>
            <Button
              variant="contained"
              className="btn-danger px-3 py-2 mb-3 mr-2"
              onClick={() => {
                onSetupReceived({
                  ...data,
                  status: "declined",
                  // workstation: compatibleWorkstationsNew[0]
                  //   ? compatibleWorkstationsNew[0].id
                  //   : null
                }); //workstation set to get the plant later if wanted
                handleClose();
                setData(null);
              }}
              color="secondary"
              style={{fontSize: "14px"}}
            >
              Decline
            </Button>
            <Button
              disabled={!(data && data.workstation)}
              variant="contained"
              className="btn-success px-3 py-2 mb-3 mr-2"
              onClick={() => {
                onSetupReceived({ ...data, status: "accepted" });
                handleClose();
                setData(null);
              }}
              color="secondary"
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  const plants = getPlants(state);
  const currentPlant = getCurrentPlant(state);
  const workstations = getWorkstations(state);
  const demandOperations = getDemandOps(state);
  return {
    plants,
    currentPlant,
    workstations,
    demandOperations,
  };
};
const mapDispatchToProps = {
  onSetupReceived: setupReceived,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceivedDialog);
