import FileUpload from '../PerformTasks/FileUpload';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
}));

const PriceListImageUpload = () => {
    const classes = useStyles();
    const [imageCode, setImageCode] = React.useState("");

    const setFormattedImageCode = (code) => {
        const formattedCode = code.replaceAll("/", "|")
        setImageCode(formattedCode)

    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid>
                    <TextField label="Item Code" onChange={(e) => setFormattedImageCode(e.target.value)}></TextField>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FileUpload imageRestrict={true} imageCode={imageCode} />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default PriceListImageUpload;