import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  usePermissions,
} from "react-admin";
import Confirmation from "../../../components/Confirmation";
import CreateButton from "../../../components/CustomCreateButton";
import {
  TopToolbar,
  // CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
  CardActions,
} from "react-admin";
import { cloneElement, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../../components/ChangeDiv";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    // width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
}));

const Title = () => {
  return <span> Change Reasons</span>;
};

export const Reason = (props) => {
  const { permissions } = usePermissions();
  const Actions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
    } = useListContext();
    const classes = useStyles();

    return (
      <div className={classes.buttonContainer} {...sanitizeListRestProps(rest)}>
        <CardActions>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
            className: "btn_"
          })}
        <CreateButton
          basePath={basePath}
          title="Click here to create reasons"
          disabled={!permissions?.permissions.includes("changeReasons_create")}
          className="btn_ btn_primary"
        />
        <ExportButton
          title="Click here to export reasons"
          disabled={!permissions?.permissions.includes("changeReasons_export")}
          className="btn_ btn_primary"
        />
      </CardActions>
      </div>
    );
  };

  return (
    <List
      perPage={25}
      {...props}
      actions={<Actions />}
      bulkActionButtons={false}
      title={<Title />}
    >
      <ChangeDiv>
        {props => {
          return <Datagrid>
          <TextField source="id" />
          <TextField source="reason" />
          <TextField source="text" />
  
          <EditButton
            title="Click here to edit reasons"
            className="btn_ btn_edit permission-edit-btn"
            style={{marginRight:"4500px", float: "right" }} 
          />
  
          <Confirmation
            text="reason"
            title="Click here to Delete reasons"
            // className="btn_delete permission-edit-btn"
            style={{marginRight:"100px", float: "right"}} 
           
          />
        </Datagrid>
        }}
      </ChangeDiv>
      
    </List>
  );
};
