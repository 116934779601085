
import React, { useMemo, useEffect, useState } from 'react';
// import { useTable, useFilters, usePagination } from 'react-table';
import MOCK_DATA from './MOCK_DATA.json';
import { usePermissions } from 'react-admin'
import TableView from './TableView';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select"
// import { useDataProvider } from "react-admin";
import dataProvider from "../../api/dataProvider";
import './table.css';
import _ from 'lodash'
import styled from 'styled-components'

const Styles = styled.div`
.input{
  background-color: white;
}
.table {
    border: 1px solid #bbb;
    font-size: 0.9rem;
    vertical-align: center;
  
    .tr {
      height: 30px!important;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
  
    .tr:nth-child(odd) {background: white}
    .tr:nth-child(even) {background: var(--table-row-color)}
    
    /* .td:nth-child(even) {background: rgba(0,0,0,0.01)} */
    /* .td:first-child {background: white} */
    /* .td:nth-child(odd) {background: #E2E2E2} */
  
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginDense input{
      background: var(--table-row-color);

    }

    .th,
    .td {
      padding: 1px;
      margin: 0;
      border-bottom: 1px solid #bbb;
      border-right: 1px solid #bbb;
      background: transparent;
      overflow: hidden;
        input {
          padding-right: 1;
          margin: 0;
          text-align: left;
          border: 1;
          background: transparent;
          width: 90%;
          color: black;
          background-color: white;
        }
        input[type=text]:focus {
          border: 1px solid #555;
        }
        input:disabled {
          color: green
        }
  
      :last-child {
        border-right: 0;
      }
    }
    .th{
        text-align:center;
    }
  
  .th:first-child{
      width: 300px!important;
  }
  .td:first-child{
    //   width: 300px!important;
  }
  
    // &.sticky {
    //   overflow: scroll;
    //   .header,
    //   .footer {
    //     position: unset;
    //     z-index: 1;
    //     width: fit-content;
    //   }
      .th{
          color:black
      }
  
      .td{
          background: rgba(255, 255, 255, 0.95);
          padding:10px;
          text-align:center;
      }
      .th, .td:first-child {
          background: var(--table-row-color);
          font-weight: 500;
          max-width:74px;
          text-align:left;
      }
      .th, .td:nth-child(2)  {
        background: var(--table-row-color);
        font-weight: 500;
        text-align:center;
    }
      .tr:first-child {
          .th {
              font-weight: 700;
          }
      }
  
      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }
  
      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }
  
      .body {
        position: relative;
        z-index: 0;
      }
  
      [data-sticky-td] {
        position: unset;
      }
  
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }
  
      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
      
    }
  }
  `



function findSelectWorkstation(dataSet) {
  let data = [];

  for (let index = 0; index < dataSet.length; index++) {
    let hasValue = "No";
    for (let y = 0; y < data.length; y++) {
      if (data[y] == dataSet[index].workstation_code) {
        hasValue = "Yes";
      }
    }
    if (hasValue == "No") {
      data.push(dataSet[index].workstation_code);
    }
  }

  return data;
}


// not implemented
function findSelectLineHead(dataSet) {
  let data = [];

  return data;
}


// not implemented
function findSelectInefficiency(dataSet) {
  let data = [];

  return data;
}

// not implemented
function findSelectInefficiencyReason(dataSet) {
  let data = [];

  return data;
}


function efficiencyDataRefactor(dataSet) {
  const data = [];

  for (let index = 0; index < dataSet.length; index++) {
    const b_grade_qty = _.sumBy(_.filter(dataSet[index].grades, { "type": "B-GRADE" }), "quantity")
    const b_reasons = _.map(_.filter(dataSet[index].grades, { "type": "B-GRADE" }), 'reason').join(', ')
    const d_grade_qty = _.sumBy(_.filter(dataSet[index].grades, { "type": "D-GRADE" }), "quantity")
    const d_reasons = _.map(_.filter(dataSet[index].grades, { "type": "D-GRADE" }), 'reason').join(', ')
    data.push(
      {
        "id": dataSet[index].id,
        "epf_no": dataSet[index].epf,
        "user": dataSet[index].user,
        "workstation": dataSet[index].workstation_code,
        "line_or_head": dataSet[index].line_code,
        "shift_date": dataSet[index].shift_date,
        "shift": dataSet[index].shift,
        "input": dataSet[index].input,
        "marked": dataSet[index].marked_output["marked_output"],
        "b_qty": b_grade_qty,
        "b_reason": b_reasons,
        "d_qty": d_grade_qty,
        "d_reason": d_reasons,
        "nc": dataSet[index].output["nc"],
        "efficiency": Math.round(dataSet[index].output["output"] * 100 / dataSet[index].input),
        "inefficiency_reason": _.map(JSON.parse(dataSet[index].inefficiencies), "reason"),
        "occurred_between": JSON.parse(dataSet[index].inefficiencies)?.map((v) => { return { from: v.from, to: v.to } }),
        "demands": dataSet[index].demands.join(", ")
      });
  }

  return data;
}





export default function EfficiencyTable() {

  const [loadingData, setLoadingData] = useState(true);
  const [efficiencyData, setEfficiencyData] = useState(null);
  const [data, setData] = useState()
  const [allPlants, setAllPlants] = useState([])
  const [selectedPlant, setSelectedPlant] = useState()
  const permissions = usePermissions()

  //filters
  const [selectWorkstation, setSelectWorkstation] = useState(null);
  const [selectLineHead, setSelectLineHead] = useState(null);
  const [selectInefficiency, setSelectInefficiency] = useState(null);
  const [selectInefficiencyReason, setSelectInefficiencyReason] = useState(null);

  const payload = {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "username", order: "ASC" },
  };

  useEffect(() => {
    dataProvider
      .GET_EFFICIENCY("efficiency", { payload: payload })
      .then((resp) => {
        setData(resp.data.results)
      });
    dataProvider
      .getList("plants", {
        pagination: { page: 1, perPage: 10 }, sort: { field: "code", order: "ASC" },
      }).then((resp) => {
        console.log("plants resp: ", resp)
        setAllPlants(resp.data)
      })
  }, []);

  useEffect(() => {
    if (data) {
      let filteredData = data;
      if (selectedPlant && selectedPlant != 'ALL') {
        filteredData = data.filter((o) => o.plant.code == selectedPlant)
      }
      setSelectWorkstation(findSelectWorkstation(filteredData));
      setSelectLineHead(findSelectLineHead(filteredData));
      setSelectInefficiency(findSelectInefficiency(filteredData));
      setSelectInefficiencyReason(findSelectInefficiencyReason(filteredData));
      setEfficiencyData(efficiencyDataRefactor(filteredData));
      setLoadingData(false);
    }
  }, [data, selectedPlant])


  const selectors = {
    selectWorkstation
  };

  return (
    <div className="App">
      {loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
          <Styles>

            <TableView selectPlants={["ADMIN", "SITE"].includes(permissions?.permissions.access_level)} allPlants={allPlants} selectedPlant={selectedPlant} setSelectedPlant={setSelectedPlant} data={efficiencyData} selectors={selectors} />
          </Styles>
        )}
    </div>
  )
}


