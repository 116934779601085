import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import React, { useState } from "react";
import { Loading } from "react-admin";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { useDispatch, useSelector } from "react-redux";
import RawJsonSchemaEditor from "../../components/input/RawJsonSchemaEditor";
import { format_to_date_for_api } from "../../lib/date_time";
import { showToastError } from "../../lib/toas";
import { updateShiftPattern } from "../../store/actions";
import { getLoading } from "../../store/selectors/holidaySelectors";
import { calendarAttributesSchema } from "./createShiftPatternAttributesSchema";
import { PreparedTreeCheckbox } from "./PreparedTreeCheckbox";
import Tooltip from "@material-ui/core/Tooltip";

export const CreateShiftPattern = (props) => {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  // variables for sub tree
  const [subChecked, setSubChecked] = useState([]);
  const [subExpanded, setSubExpanded] = useState([]);

  const setSubCheckedWrapper = (data) => {
    setSubChecked(data);
    setChecked([]);
  };
  const setCheckedWrapper = (data) => {
    setChecked(data);
    setSubChecked([]);
  };

  const [formData, setFormData] = useState({
    pattern: { weekday: [], saturday: [], sunday: [] },
  });
  const [dates, setDates] = useState([
    format_to_date_for_api(),
    format_to_date_for_api(moment().add(7, "days")),
  ]);
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);

  const onChangeForm = (e) => {
    setFormData(e.formData);
  };

  const validateData = () => {
    const { weekday, sunday, saturday } = formData.pattern;

    const dateArray = [weekday, sunday, saturday];

    if (checked.length === 0 && subChecked.length === 0) {
      showToastError("select at least one line/head");
      return false;
    }

    dateArray.forEach((element) => {
      console.log(element);
      if (element && element.length > 0) {
        if (element[0].includes(undefined)) {
          showToastError("fill both fields");
          return false;
        }



        if (!element[0][0].includes(":")) {
          showToastError("Invalid format use HH:mm");
          return false;
        }
      }
    });
    return true;
  };

  const submitData = () => {
    const validate = validateData();

    if (!validate) {
      return false;
    }
    console.log(formData.holidays, formData.pattern);
    if (checked.length === 0) {
      dispatch(
        updateShiftPattern(
          dates,
          subChecked,
          formData.holidays,
          formData.pattern
        )
      );
    } else {
      dispatch(
        updateShiftPattern(dates, checked, formData.holidays, formData.pattern)
      );
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid item xs={4}>
        <Box m={3}>
          <DateRangePicker
            format="dd/MM/yyyy"
            onChange={(date) => {
              setDates([
                date
                  ? format_to_date_for_api(date[0])
                  : format_to_date_for_api(),
                date
                  ? format_to_date_for_api(date[1])
                  : format_to_date_for_api(moment().add(7, "days")),
              ]);
            }}
            value={[new Date(dates[0]), new Date(dates[1])]}
            style={{ marginTop: "10px" }}
          />
        </Box>
        <Box m={3}>
          <PreparedTreeCheckbox
            checked={checked}
            expanded={expanded}
            setChecked={setCheckedWrapper}
            setExpanded={setExpanded}
            sub={false}
          />
          <PreparedTreeCheckbox
            checked={subChecked}
            expanded={subExpanded}
            setChecked={setSubCheckedWrapper}
            setExpanded={setSubExpanded}
            sub={true}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <RawJsonSchemaEditor
          source="config"
          schema={calendarAttributesSchema}
          formData={formData}
          onChangeForm={onChangeForm}
        />
        <Tooltip title="Click here to Create shfit patterns">
          <Button
            className="btn_ btn_primary"
            variant="contained"
            // color="primary"
            style={{ float: "right" }}
            onClick={submitData}
          >
            Create
          </Button>
        </Tooltip>
        <Box mb={5}></Box>
      </Grid>
    </Grid>
  );
};
