import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DirectionsIcon from "@material-ui/icons/Directions";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import HistoryIcon from "@material-ui/icons/History";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import LabelIcon from "@material-ui/icons/Label";
import PersonIcon from "@material-ui/icons/Person";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import StoreIcon from "@material-ui/icons/Store";
import WorkIcon from "@material-ui/icons/Work";
import PeopleIcon from "@material-ui/icons/People";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import LockIcon from "@material-ui/icons/Lock";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import PublishIcon from "@material-ui/icons/Publish";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import BallotIcon from "@material-ui/icons/Ballot";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import TableChartIcon from "@material-ui/icons/TableChart";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PageviewIcon from "@material-ui/icons/Pageview";
import EventNoteIcon from "@material-ui/icons/EventNote";
import EventIcon from "@material-ui/icons/Event";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FeedbackIcon from "@material-ui/icons/Feedback";
import BusinessIcon from "@material-ui/icons/Business";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Tune from "@material-ui/icons/Tune";
import PostAddOutlined from "@material-ui/icons/PostAddOutlined";
import ArrowDropDownCircleOutlined from "@material-ui/icons/ArrowDropDownCircleOutlined";
import NoteAddOutlined from "@material-ui/icons/NoteAddOutlined";
import React, { useCallback, useMemo, useState } from "react";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import {
  DashboardMenuItem,
  getResources,
  MenuItemLink,
  usePermissions,
  setSidebarVisibility,
} from "react-admin";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import SubMenu from "./Submanu";
import { MENU_SECTIONS, TNA_MENU_SECTIONS } from "../constants";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useDispatch } from "react-redux";

const MenuContent = ({
  onMenuClick,
  state,
  permissions,
  handleToggle,
  open,
}) => {
  const dispatch = useDispatch();

  const window_height = window.innerHeight;
  return (
    <div
      style={{ marginTop: window_height * 0.05 }}
      onMouseEnter={() => {
        dispatch(setSidebarVisibility(true));
      }}
      onMouseLeave={() => {
        dispatch(setSidebarVisibility(false));
      }}
    >
      <DashboardMenuItem
        title="permissions"
        hidden={!permissions?.permissions.includes("dashboard")}
        onClick={onMenuClick}
      />
      {/* <MenuItemLink
      
        title='permissions'
        to={`/supply_chain`}
        primaryText={`Supply Chain`}
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
      /> */}
      <SubMenu
        hidden={
          !permissions?.permissions.filter((p) =>
            MENU_SECTIONS["Company"].includes(p)
          )[0]
        }
        handleToggle={() => handleToggle("menuCompany")}
        isOpen={state.menuCompany}
        sidebarIsOpen={open}
        name="Organization"
        icon={<AccountBalanceIcon />}
      >
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("sites")}
          to={`/sites`}
          primaryText={`Sites`}
          leftIcon={<HomeWorkIcon />}
          onClick={onMenuClick}
        />

        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("plants")}
          to={`/plants`}
          primaryText={`Plants`}
          leftIcon={<StoreIcon />}
          onClick={onMenuClick}
        />

        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("workstations")}
          to={`/workstations`}
          primaryText={`Work Stations`}
          leftIcon={<WorkIcon />}
          onClick={onMenuClick}
        />

        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("users")}
          to={`/users`}
          primaryText={`Users`}
          leftIcon={<PeopleIcon />}
          onClick={onMenuClick}
        />

        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("roles")}
          to={`/roles`}
          primaryText={`Roles`}
          leftIcon={<SupervisedUserCircleIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("permissions")}
          to={`/permissions`}
          primaryText={`Permissions`}
          leftIcon={<LockIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("calendars")}
          to={`/holidays`}
          primaryText={`Calendars`}
          leftIcon={<CalendarTodayIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_calender")}
          to={`/tna_calendar`}
          primaryText={`TNA Calendar`}
          leftIcon={<DateRangeIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("subcontracts")}
          to={`/subContracts`}
          primaryText={`Subcontracts`}
          leftIcon={<ContactPhoneIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("settings")}
          to={`/settings`}
          primaryText={`Settings`}
          leftIcon={<SettingsApplicationsIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu
        hidden={
          !permissions?.permissions.filter((p) =>
            MENU_SECTIONS["Items and Routings"].includes(p)
          )[0]
        }
        handleToggle={() => handleToggle("menuItems")}
        isOpen={state.menuItems}
        sidebarIsOpen={open}
        name="Items and Routing"
        icon={<DirectionsIcon />}
      >
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("items")}
          to={`/items`}
          primaryText={`Items`}
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("operations")}
          to={`/operations`}
          primaryText={`Operations`}
          leftIcon={<LowPriorityIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("itemStructureUpload")}
          to={`/itemStructureUpload`}
          primaryText={`Item structure upload`}
          leftIcon={<PublishIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      {/* <SubMenu
        hidden={!permissions?.permissions.filter(p => MENU_SECTIONS['Inventory'].includes(p))[0]}
        handleToggle={() => handleToggle("menuInventory")}
        isOpen={state.menuInventory}
        sidebarIsOpen={open}
        name="Inventory"
        icon={<ShowChartIcon />}
      >
        <MenuItemLink
          to={`materialInventory`}
          primaryText={`Material Inventory`}
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
        />

        <MenuItemLink
          to={'rmRequest'}
          primaryText={`Material Requests`}
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
        />
      </SubMenu> */}
      <SubMenu
        hidden={["TNA_ADMIN","TNA_CLUSTER_MANAGER","TNA_DEP_ADMIN", "TNA_USER"].some((p) =>
          permissions?.access_level.includes(p)
        )}
        handleToggle={() => handleToggle("menuOrders")}
        isOpen={state.menuOrders}
        sidebarIsOpen={open}
        name="Orders"
        icon={<ViewHeadlineIcon />}
      >
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("orders")}
          to={`/orders`}
          primaryText={`Orders`}
          leftIcon={<PlaylistAddIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("transfers")}
          to={`/transfers`}
          primaryText={`Transfers`}
          leftIcon={<SwapHorizontalCircleIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("orderGroups")}
          to={`/orderGroups`}
          primaryText={`Orders Groups`}
          leftIcon={<BallotIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("drillDownView")}
          to={`/demandOps`}
          primaryText={`Drill Down View`}
          leftIcon={<UnfoldMoreIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("demands")}
          to={`/demand`}
          primaryText={`Demands`}
          leftIcon={<TrendingUpIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("orderGroups")}
          to={`/delayQuantities`}
          primaryText={`Delayed Orders`}
          leftIcon={<AssignmentLateIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu
        hidden={
          !permissions?.permissions.filter((p) =>
            MENU_SECTIONS["Plan"].includes(p)
          )[0]
        }
        handleToggle={() => handleToggle("menuPlan")}
        isOpen={state.menuPlan}
        sidebarIsOpen={open}
        name="Plan"
        icon={<FeaturedPlayListIcon />}
      >
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("planboards")}
          to={`/planBoards`}
          primaryText={`Plan Boards`}
          leftIcon={<TableChartIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu
        hidden={
          !permissions?.permissions.filter((p) =>
            MENU_SECTIONS["Execution"].includes(p)
          )[0]
        }
        handleToggle={() => handleToggle("menuExecution")}
        isOpen={state.menuExecution}
        sidebarIsOpen={open}
        name="Execution"
        icon={<GraphicEqIcon />}
      >
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("progress")}
          to={`/allocations`}
          primaryText={`Progress`}
          leftIcon={<AssessmentIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("floorView")}
          to={`/floorView`}
          primaryText={`Floor View`}
          leftIcon={<PageviewIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>

      <SubMenu
        hidden={
          !permissions?.permissions.filter((p) =>
            MENU_SECTIONS["Reports"].includes(p)
          )[0]
        }
        handleToggle={() => handleToggle("menuReports")}
        isOpen={state.menuReports}
        sidebarIsOpen={open}
        name="Reports"
        icon={<EqualizerIcon />}
      >
        {/* <MenuItemLink
          to={`/Charts`}
          primaryText="Charts"
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        /> */}
        {/* <MenuItemLink
          to={`/Quantity`}
          primaryText={`Delay Quantity Report`}
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
        /> */}
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("activityLog")}
          to={`/CapacityReports`}
          primaryText={`Capacity Utilization`}
          leftIcon={<EventNoteIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("activityLog")}
          to={`/ActivityLog`}
          primaryText={`Activity Log`}
          leftIcon={<EventNoteIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("changeLog")}
          to={`/changeLog`}
          primaryText={`Change Log`}
          leftIcon={<EventIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("rmTimestamp")}
          to={`/rmTimeStamp`}
          primaryText={`Time Stamp`}
          leftIcon={<HourglassEmptyIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu
        hidden={
          !permissions?.permissions.filter((p) =>
            MENU_SECTIONS["Integration"].includes(p)
          )[0]
        }
        handleToggle={() => handleToggle("menuIntegration")}
        isOpen={state.menuIntegration}
        sidebarIsOpen={open}
        name="Integration"
        icon={<HistoryIcon />}
      >
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("integrationRecord")}
          to={`/integrationRecord`}
          primaryText={`Integration`}
          leftIcon={<AddAlarmIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      {/* <SubMenu
        hidden={!permissions?.permissions.filter(p => MENU_SECTIONS['Documentation'].includes(p))[0]}
        handleToggle={() => handleToggle("menuDocumentation")}
        isOpen={state.menuDocumentation}
        sidebarIsOpen={open}
        name="Documentation"
        icon={<ImportContactsIcon />}
      >
             
      </SubMenu> */}
      <SubMenu
        hidden={
          !permissions?.permissions.filter((p) =>
            MENU_SECTIONS["Profile"].includes(p)
          )[0]
        }
        handleToggle={() => handleToggle("menuProfile")}
        isOpen={state.menuProfile}
        sidebarIsOpen={open}
        name="Profile"
        icon={<PersonIcon />}
      >
        <MenuItemLink
          title="permissions"
          to={`/userProfile`}
          primaryText={`Profile`}
          leftIcon={<AccountCircleIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          to={`/about`}
          primaryText={`About`}
          leftIcon={<FeedbackIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      <MenuItemLink
        title="permissions"
        hidden={!permissions?.permissions.includes("tna_dashboard")}
        to={`/TnaDashboard`}
        primaryText={`TNA Dashboard`}
        leftIcon={<DashboardIcon />}
      />

      <SubMenu
        hidden={
          !permissions?.permissions.filter((p) =>
            MENU_SECTIONS["TNA Admin Panel"].includes(p)
          )[0]
          // || permissions?.access_level == "TNA_USER"
        }
        handleToggle={() => handleToggle("menuTNAAdmin")}
        isOpen={state.menuTNAAdmin}
        sidebarIsOpen={open}
        name="TNA Feature Panel"
        icon={<DirectionsIcon />}
      >
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_departments")}
          to={`/tna_departments`}
          primaryText={`Departments`}
          leftIcon={<BusinessIcon />}
          onClick={onMenuClick}
        />

        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_flow_generation")}
          to={`/flow_generation`}
          primaryText={`Flow Generation`}
          leftIcon={<PostAddOutlined />}
          onClick={onMenuClick}
        />

        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_delay_reasons")}
          to={`/tna_delayreasons`}
          primaryText={`Delay Reasons`}
          leftIcon={<AvTimerIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_templates")}
          to={`/tna_templates`}
          primaryText={`Templates`}
          leftIcon={<LibraryBooksIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_tentative_flows")}
          to={`/tentativeOrders`}
          primaryText={`Tentative Flow Manager`}
          leftIcon={<Tune />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_item_image_uploader")}
          to={`/itemImageList`}
          primaryText={`Item Image Uploader`}
          leftIcon={<CloudUploadIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_flow")}
          to={`/tna_orders`}
          primaryText={`Admin Task Browser`}
          leftIcon={<AssignmentIndIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_tasks_assign")}
          to={`/tna_createSubTasks`}
          primaryText={`Update Assignees`}
          leftIcon={<ListAltIcon />}
          onClick={onMenuClick}
        />

        <MenuItemLink
          title="permissions"
          hidden={
            !permissions?.permissions.includes("tna_dropdown_list_item_create")
          }
          to={`/drop`}
          primaryText={`Form dropdown list`}
          leftIcon={<ArrowDropDownCircleOutlined />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={
            !permissions?.permissions.includes(
              "tna_inquiry_serial_dropdown_creator"
            )
          }
          to={`/inquiry_serial`}
          primaryText={`Inquiry serial creator`}
          leftIcon={<NoteAddOutlined />}
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu
        hidden={!permissions?.permissions.includes("tna_dashboard")}
        handleToggle={() => handleToggle("menuTNATasks")}
        isOpen={state.menuTNATasks}
        sidebarIsOpen={open}
        name="TNA Tasks"
        icon={<FormatListBulletedIcon />}
      >
        <MenuItemLink
          title="permissions"
          hidden={false}
          to={`/todo`}
          primaryText={`ToDo`}
          leftIcon={<FormatListNumberedIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={false}
          to={`/processing`}
          primaryText={`Processing`}
          leftIcon={<DonutLargeIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={false}
          to={`/completed`}
          primaryText={`Completed`}
          leftIcon={<CheckCircleOutlineIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={
            !permissions?.permissions.includes("tna_submit_inquiry_serial")
          }
          to={`/inquirySerialSubmit`}
          primaryText={`Inquiry Serial Submit`}
          leftIcon={<LibraryBooksIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_csv_extract")}
          to={`/price_list_upload`}
          primaryText={`Price List Upload`}
          leftIcon={<PublishIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          title="permissions"
          hidden={!permissions?.permissions.includes("tna_task_approval")}
          to={`/approve_tasks`}
          primaryText={`Tasks Approval`}
          leftIcon={<PlaylistAddCheckIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
    </div>
  );
};

const Menu = ({ onMenuClick }) => {
  const [state, setState] = useState({
    menuReports: false,
    menuCompany: false,
    menuItems: false,
    menuInventory: false,
    menuOrders: false,
    menuPlan: false,
    menuExecution: false,
    menuIntegration: false,
    menuDocumentation: false,
    menuTNAAdmin: false,
    menuFlowGeneration: false,
    menuTNAOrders: false,
  });

  const { permissions } = usePermissions();

  const handleToggle = useCallback(
    (menu) => {
      //this is the initial setup
      // setState((state) => ({ ...state, [menu]: !state[menu] }));
      // to close other manu after selecting manu issue fixed
      setState((state) => ({ [menu]: !state[menu] }));
    },
    [setState]
  );
  //  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const menuProps = { open, permissions, handleToggle, state };

  return <MenuContent {...menuProps} />;
};

export default withRouter(Menu);
