import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PreviewModal } from "../../TNA/flowGeneration/PreviewModal";
import {
  CONFIRM_TEMPLATE,
  DELETE_FLOW,
  FETCH_SELECTED_TENTATIVE_FLOW,
  LOAD_ALL_USERS,
  TEMPLATE_RESET_UI_STATE,
} from "../../../store/types/tnaTaskTypes";
import {
  getErrorGenerate,
  getSuccessGenerate,
} from "../../../store/selectors/tnaTaskSelectors";
import { styled } from "@material-ui/core/styles";
import { TableCell, TableRow, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { showToastError, showToastSuccess } from "../../../lib/toas";
const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: "40%",
  },
  btn: {
    backgroundColor: "primary",
    minWidth: "120px",
    margin: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TentativeFlowItem = ({
  order,
  refresh,
  selItemId,
  setSelItemId,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const successMessage = useSelector(getSuccessGenerate);
  const errorMessage = useSelector(getErrorGenerate);
  const dispatch = useDispatch();

  const onTentativeHandler = () => {
    //Tentative Call
    setIsLoading(true);
    dispatch({
      type: CONFIRM_TEMPLATE,
      payload: { status: "tentative", late: true, orderId: order.id },
    });
    setSelItemId(order.id);
  };

  const onConfirmHandler = () => {
    //Confirm Call
    setIsLoading(true);
    dispatch({
      type: CONFIRM_TEMPLATE,
      payload: { status: "fixed", late: true, orderId: order.id },
    });
    setSelItemId(order.id);
  };

  const onCancelHandler = () => {
    setIsLoading(true);
    dispatch({ type: DELETE_FLOW, payload: { late: true, orderId: order.id } });
    setSelItemId(order.id);
  };

  const onClick = () => {
    setIsOpenModal(true);
    setIsLoading(true);
    dispatch({
      type: LOAD_ALL_USERS,
      payload: { pagination: { page: 1, perPage: 1000 } },
    });
    dispatch({ type: FETCH_SELECTED_TENTATIVE_FLOW, payload: order.id });
  };

  useEffect(() => {
    if (successMessage === "Flow loaded Successfully") {
      setIsLoading(false);
    }
    if (selItemId === order.id) {
      switch (successMessage) {
        case "Flow Status Updated":
          setIsLoading(false);
          setIsOpenModal(false);
          showToastSuccess(successMessage);
          setTimeout(() => {
            dispatch({ type: TEMPLATE_RESET_UI_STATE });
          }, 1000);
          refresh();
          break;
        case "Template Deleted Successfully!":
          setIsLoading(false);
          setIsOpenModal(false);
          showToastSuccess(successMessage);
          setTimeout(() => {
            dispatch({ type: TEMPLATE_RESET_UI_STATE });
          }, 2000);
          refresh();
          break;
        default:
      }
    }
  }, [successMessage]);

  useEffect(() => {
    if (selItemId === order.id) {
      switch (errorMessage) {
        case "Child tasks not assigned to user":
          setIsOpenModal(false);
          break;
        case "Error Occured Updating Status. Try again!":
          setIsOpenModal(true);
          showToastError(errorMessage);
          break;
        default:
      }
    }
  }, [errorMessage]);
  return (

      <StyledTableRow>
        <TableCell align="left">{order.id}</TableCell>
        <TableCell align="left">{order.flow_code}</TableCell>
        <TableCell align="left">{order.template_code}</TableCell>
        <TableCell align="left">{order.started_date?.substring(0, 10)} {order.started_date?.substring(11, 19)}</TableCell>
        <TableCell align="left">{order.status}</TableCell>
        <TableCell align="left">
          <Button
            className="btn_ btn_edit"
            onClick={() => {
              onClick();
            }}
          >
            Edit
          </Button>
        </TableCell>
        <PreviewModal
          isLoading={isLoading}
          showModal={isOpenModal}
          onSetShowModal={setIsOpenModal}
          onConfirm={onConfirmHandler}
          onCancel={onCancelHandler}
          onTentative={onTentativeHandler}
          error={""}
          status={order.flow_status}
          flowType={order.type}
          parent="tentativeFlowItem"
        />
      </StyledTableRow>
    
  );
};
