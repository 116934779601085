export const calendarAttributesSchema = {
    title: "",
    type: ["object", "null"],

    properties: {
        holidays: {
            type: "array",
            title: "Holidays",
            description: "click 'ADD ITEM' to add holidays",
            // required: ["date"],
            items: {
                type: "array",
                title: "Holiday",
                items: [
                    {
                        type: "string",
                        title: "Reason",
                        description: "Enter reason"
                    },
                    {
                        type: "string",
                        required: [""],
                        format: "date",
                        description: "Start Date:",
                        title: "Date"
                    },
                    {
                        type: "string",
                        required: [""],
                        format: "date",
                        description: "End Date:",
                        title: "Date"
                    }
                ]

            }
        }
    },
}
    ;

