import { AllocationIdType } from "../../lib/plan";
import { ADD_ORDER_CODE_TO_CHANGED_ORDERS, APPLY_PENDING_ACTION, CLEAR_PENDING_ACTION, CLEAR_SELECTION, DELETE_AND_PLAN_AUTOMATIC, DELETE_PLAN, EXPORT_PLAN_BOARD_DATA, LOAD_PLANBOARD, LOAD_PLANBOARD_REVISION, LOAD_PLANBOARD_REVISION_FAILED, LOAD_PLANBOARD_REVISION_SUCCESS, LOAD_PLANBOARD_SUCCESS, MODEL_CLOSE, MODEL_OPEN, PLAN_AUTOMATIC, PLAN_REDO, PLAN_UNDO, PUBLISH_PLANBOARD_REVISION, SAVE_PLAN_BOARD_CHANGES, SELECT_ENTITY, SET_IS_EDITING, SET_PENDING_ACTION, SET_PROGRESS, SET_SELECTED_GROUP, SET_TIME_RANGE, SHIFT_TIME_RANGE_BACKWARD, SHIFT_TIME_RANGE_FORWARD, TOGGLE_EDIT_MODE } from "../types";
import { createAllocations, deleteAllocations, moveAllocations, editAllocationDuration } from "./planActions";

// Load plan board
export const loadPlanBoard = (id: any) => {
  return { type: LOAD_PLANBOARD, payload: { id } };
};



export const loadPlanBoardSuccess = (entities: any) => {
  return { type: LOAD_PLANBOARD_SUCCESS, payload: { entities } };
};

export const loadPlanBoardRevision = (id: number) => {
  return { type: LOAD_PLANBOARD_REVISION, payload: { id } }
}

export const loadPlanBoardRevisionSuccess = (revision_id: number, plan: any) => {
  return { type: LOAD_PLANBOARD_REVISION_SUCCESS, payload: { plan, revision_id } };
};

export const loadPlanBoardRevisionFailed = (entities: any) => {
  return { type: LOAD_PLANBOARD_REVISION_FAILED, payload: { entities } };
};

export const publishPlanBoardRevision = () => {
  return { type: PUBLISH_PLANBOARD_REVISION };
};

// Select actions
export const selectEntity = (type: string, id: any) => {
  return {
    type: SELECT_ENTITY,
    payload: { type, id }
  };
};

export const clearSelection = () => {
  return {
    type: CLEAR_SELECTION
  }
}

// set selected time range
export const setSelectedTimeRange = (start_time: number, end_time: number) => {
  return {
    type: SET_TIME_RANGE,
    payload: { start_time, end_time }
  };
};

export const savePlanboardChanges = (change_reasons: any) => {
  return { type: SAVE_PLAN_BOARD_CHANGES, payload: { change_reasons } };
};


// Plan
export function undo() {
  return { type: PLAN_UNDO };
}

export function redo() {
  return { type: PLAN_REDO };
}

export function exportPlanBoardData(start: number, end: number, lines?: number[]) {
  return { type: EXPORT_PLAN_BOARD_DATA, payload: { start, end, lines } };
}

export function planAutomatic(id: any, data: any) {
  return { type: PLAN_AUTOMATIC, payload: { id, data } };
}

export function deleteAndPlanAutomatic(id: any, data: any) {
  return { type: DELETE_AND_PLAN_AUTOMATIC, payload: { id, data } };
}
export function deletePlan(id: any, data: any) {
  return { type: DELETE_PLAN, payload: { id, data } };
}

export const setProgress = (progress: any) => {
  return { type: SET_PROGRESS, payload: { progress } }
}

export const setIsEditing = (string: any) => {
  return { type: SET_IS_EDITING, payload: { string } }
}

export const toggleEditMode = () => {
  return { type: TOGGLE_EDIT_MODE }
}



export function setSelectedGroup(id: any) {
  return { type: SET_SELECTED_GROUP, payload: { id } }
}

export function modelOpen() {
  return { type: MODEL_OPEN };
}

export function modelClose() {
  return { type: MODEL_CLOSE };
}


export function shiftTimeRangeForward(durationInHours?: number) {
  return { type: SHIFT_TIME_RANGE_FORWARD, payload: { durationInHours } }
}

export function shiftTimeRangeBackward(durationInHours?: number) {
  return { type: SHIFT_TIME_RANGE_BACKWARD, payload: { durationInHours } }
}

export function setPendingAction(pendingAction: any) {
  return { type: SET_PENDING_ACTION, payload: pendingAction }
}

export function clearPendingAction() {
  return { type: CLEAR_PENDING_ACTION }
}

export function applyPendingAction() {
  return { type: APPLY_PENDING_ACTION }
}

export function requestDeleteAllocation(allocationId: AllocationIdType) {
  return setPendingAction(deleteAllocations(allocationId, "allocation"))
}

export function requestEditAllocationDuration(allocationId: AllocationIdType) {
  return setPendingAction(editAllocationDuration(allocationId, 1000*60*11))
}

export function requestCreateAllocation(demand_operation_id: number, to_line_id: number, to_time: Date) {
  return setPendingAction(createAllocations(demand_operation_id, to_line_id, to_time, "demand_operation"))
}

export function requestMoveAllocation(allocation_id: AllocationIdType, to_line_id: number, to_time: Date) {
  return setPendingAction(moveAllocations(allocation_id, to_line_id, to_time, "allocation"))
}

export function updatePendingAction(pendingAction: any, update: any) {
  return setPendingAction({ ...pendingAction, payload: { ...pendingAction.payload, ...update } })
}

export function addChangedOrderCode(code: string) {
  return { type: ADD_ORDER_CODE_TO_CHANGED_ORDERS, payload: { code } }
}