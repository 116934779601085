import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Error, Loading, usePermissions, useQuery, Query } from "react-admin";
import { Title } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  getAllTasks,
  getTodoTasks,
  getProcessingTasks,
  getUnassignedTasks,
  getCompletedTasks,
  getTNATaskLoading,
  getTasksCounts,
  getUnassignedTasksCounts,
} from "../../store/selectors/tnaTaskSelectors";
import {
  loadALLTasks,
  loadUnassignedTasks,
} from "../../store/actions/tnaTaskActions";
import { connect, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { values } from "lodash";
import List from "@material-ui/core/List";
import TaskCard from "./taskCard";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Completed from "../../layout/img/completed.svg";
import Pending from "../../layout/img/pending.svg";
import ToDo from "../../layout/img/todo.svg";
import Unassigned from "../../layout/img/ap.svg";
// import Unassigned from "../../layout/img/Unassigned.png";
import CustomTable from "../../components/dashboard/CustomTable";
import SmallLoading from "../../components/loading/SmallLoading";
import _ from "lodash";
import dataProvider from "../../api/dataProvider";
import { showToastError } from "../../lib/toas";
import { theme } from "../../layout/theme";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { styled } from "@material-ui/core/styles";
import { theme as Theme } from "../../layout/theme";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  Checkbox,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloseIcon from "@material-ui/icons/Close";
import {
  Bar,
  BarChart,
  Area,
  AreaChart,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { SECONDARY_COLOR } from "../../constants";
import "./dashboardStyles.css"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // paddingTop: "4%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    height: 400,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 24,
  },
  analyticsPaper: {
    padding: theme.spacing(1),
    textAlign: "center",
    height: 700,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 24,
  },
  link: {
    height: "25%",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: 28,
    fontWeight: "bold",
  },
  select: {
    color: theme.palette.primary.main
  }
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: Theme.palette.primary.main,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: Theme.palette.primary.main,
  },
}));

const Chart = (props) => {
  const outerHeight = window.outerHeight;
  return (
    <ResponsiveContainer width="100%" height={outerHeight / 2}>
      <BarChart
        data={props.data}
        margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
      >
        <CartesianGrid stroke="#ddd" />
        <XAxis dataKey="clusterKey" tick={{ fontSize: 13 }} name="Cluster" />
        <YAxis tick={{ fontSize: 13 }} />
        <Tooltip />
        <Bar dataKey="count" fill={SECONDARY_COLOR} name="No. of flows" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const TaskDialog = (props) => {
  let tasks = props.data
    ? props.selectedFlowType === "INQ"
      ? props.data.filter((t) => t.order.inquiry_serial === props.flowDetails)
      : props.data.filter((t) => t.order.code === props.flowDetails)
    : props.data;
    
  return (
    <Dialog className="modal_task_table"
      fullWidth={true}
      maxWidth="lg"
      open={props.showTasks}
      onClose={() => {
        props.setShowTasks(false);
        props.setSelectedFlowId(null);
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={10}>
          <DialogTitle style={{ paddingTop: "10px" }}>
            Flow: {`${props.flowDetails}`}
          </DialogTitle>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
              marginRight: 10,
              cursor: "pointer",
            }}
          >
            <CloseIcon onClick={() => {
              props.setShowTasks(false);
              props.setSelectedFlowId(null);
            }} />
          </div>
        </Grid>
      </Grid>
      <DialogContent style={{ paddingTop: 0 }}>
        <TasksTable
          isFlows={true}
          detailed={true}
          dialog={true}
          taskCodes={props.taskCodes}
          data={tasks}
          filters={[]}
          height={500}
          popupDataLoading={props.popupDataLoading}
        />
      </DialogContent>
    </Dialog>
  );
};

const DateClusterSelect = ({
  selectedCluster,
  handleClusterChange,
  handleDateRangeChange,
  clusters,
  dateRange,
  selectedFlowType
}) => {
  const classes = useStyles()
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <>
      <Grid item xs={4} style={{ fontSize: 14, fontWeight: 400 }}>
        <div
          style={{
            marginTop: "4px",
            padding: "10px 0px 10px",
            border: "1px solid",
            borderRadius: "5px",
          }}
        >
          <DateRangePicker
            format="y/MM/dd"
            onChange={handleDateRangeChange}
            value={[new Date(dateRange[0]), new Date(dateRange[1])]}
            calendarIcon={<CalendarTodayIcon />}
            clearIcon={<CloseIcon />}
          />
        </div>
      </Grid>
      <Grid item xs={4} className="pl-0">
        <FormControl>
          <InputLabel
            style={{ marginTop: -7, marginLeft: 12 }}
            id="demo-multiple-checkbox-label"
          >
            Clusters
          </InputLabel>

          <Select
            disabled={selectedFlowType !== "INQ"}
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedCluster}
            onChange={handleClusterChange}
            input={<OutlinedInput label="Clusters" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            classes={{
              root: classes.select
            }}
          >
            {Object.values(clusters).map((c) => (
              <MenuItem key={c} value={c}>
                <Checkbox checked={selectedCluster.indexOf(c) > -1} />
                <ListItemText primary={c} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const getDayDiff = (day1, day2) => {
  return Math.floor(Math.abs(day1 - day2) / (1000 * 60 * 60 * 24));
};

const FlowsView = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(null);
  let result = props.data;
  let preparedResult;
  const formatDate = (date) => _.join(_.reverse(_.split(date, "/", 3)), "-");

  result = result
    ? !props.flows
      ? props.tab === "Processing"
        ? result.filter((t) =>
            _.inRange(
              Date.parse(t.order.received_time.substr(0, 10)),
              Date.parse(props.dateRange[0]),
              Date.parse(props.dateRange[1])
            )
          )
        : result.filter((t) =>
            _.inRange(
              Date.parse(formatDate(t.actual_complete_time?.substr(0, 10))),
              Date.parse(props.dateRange[0]),
              Date.parse(props.dateRange[1])
            )
          )
      : result.filter((t) =>
          _.inRange(
            Date.parse(t.received_time.substr(0, 10)),
            Date.parse(props.dateRange[0]),
            Date.parse(props.dateRange[1])
          )
        )
    : result;

  const getRelatedFlowLastCompletedTask = (tasks, order_code) => {
    let relatedFlowTasks = tasks.filter(
      (task) => task.order_code == order_code
    );
    relatedFlowTasks=relatedFlowTasks.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    const lastCompletedTask = relatedFlowTasks.reduce(function (prev, current) {
      if (
        prev?.actual_complete_time == null &&
        current?.actual_complete_time == null
      ) {
        return prev;
      } else if (prev?.actual_complete_time == null) {
        return current;
      } else if (current?.actual_complete_time == null) {
        return prev;
      } else {
        return moment(prev.actual_complete_time,"DD/MM/YYYY, HH:mm:ss")  > moment(current.actual_complete_time,"DD/MM/YYYY, HH:mm:ss")
          ? prev
          : current;
      }
    });

    return lastCompletedTask;
  };
  const getRelatedFlowLastDueCompleteTask = (tasks, order_code) => {
    const relatedFlowTasks = tasks.filter(
      (task) => task.order_code == order_code
    );
    const lastDueCompleteTask = relatedFlowTasks.reduce(function (
      prev,
      current
    ) {
      return Date.parse(prev.due_complete_datetime?.substr(0, 10)) >
        Date.parse(current.due_complete_datetime?.substr(0, 10))
        ? prev
        : current;
    });

    return lastDueCompleteTask;
  };

  const flowDelay = (tasks, order_code) => {
    const lastCompletedTask = getRelatedFlowLastCompletedTask(
      tasks,
      order_code
    );
    const delayedDays=lastCompletedTask?.delayAsFlow
    return delayedDays;
  };

  if (!props.data) return <SmallLoading />;
  else {
    if (!props.flows) {
      props.filters.map((f) => {
        result = result.filter(f);
      });
      preparedResult = _.uniqBy(
        result.map((r) => {
          return {
            order: r.order_code,
            order_id: r.order.id,
            template: props?.templates?.filter(
              (t) => t.id === r.order.template
            )[0]?.code,
            submit_date: r.order.received_time?.substr(0, 10),
            flow_type: r.order.type,
            due_complete_date: formatDate(
              getRelatedFlowLastDueCompleteTask(result, r.order_code)
                ?.due_complete_datetime
            ),
            actual_complete_time: formatDate(
              getRelatedFlowLastCompletedTask(
                result,
                r.order_code
              )?.actual_complete_time?.substr(0, 10)
            ),
            delays: flowDelay(result, r.order_code),
          };
        }),
        (e) => {
          return [e.order, e.template].join();
        }
      );
    } else {
      result = result.map((d) => {
        return {
          ...d,
          template_code: "No template",
          submit_date: d.received_time?.substr(0, 10),
          isSubmitted:d.isSubmitted?"Yes":"No",
          delays: d.delays,
        };
      });
    }
    const setFlowData = (flowType) =>
      props.flows
        ? result.filter((r) => {
            return r.type === flowType;
          })
        : preparedResult !== undefined
        ? preparedResult.filter((r) => r.flow_type === flowType)
        : [];
    const chartData = props.selectedCluster.map((cluster) => {
      const clusterKey = Object.keys(props.clusters).find(
        (key) => props.clusters[key] === cluster
      );
      const count = setFlowData("IQ").filter((x) =>
        x.flow_code
          ? _.split(x.flow_code, "/", 1).includes(clusterKey)
          : _.split(x.order, "/", 1).includes(clusterKey)
      ).length;
      return { cluster: cluster, clusterKey: clusterKey, count: count };
    });
    const getClusterFilteredData = () => {
      let cData = [];
      props.selectedCluster.map((cluster) => {
        const clusterKey = Object.keys(props.clusters).find(
          (key) => props.clusters[key] === cluster
        );
        const data = setFlowData("IQ").filter((x) =>
          x.flow_code
            ? _.split(x.flow_code, "/", 1).includes(clusterKey)
            : _.split(x.order, "/", 1).includes(clusterKey)
        );
        cData = cData.concat(data);
        return data;
      });
      cData = !["ODR", "INQ"].includes(props.selectedFlowType)
        ? cData.concat(setFlowData("NEW"))
        : cData;
      return cData;
    };
    const formatFlowData = () =>
      props.selectedFlowType === "ODR"
        ? setFlowData("NEW")
        : getClusterFilteredData();
    return props.showGraph ? (
      <Chart data={chartData} />
    ) : (
      <CustomTable
          setPopupDataLoading={props.setPopupDataLoading}
          setSelectedFlowId={props.setSelectedFlowId}
        setFlowDetails={props.setFlowDetails}
        isFlows={true}
        showTasks={props.showTasks}
        setShowTasks={props.setShowTasks}
        maxHeight={500}
        order={order}
        orderBy={orderBy}
        currentTab={props.tab}
        columns={
          props.tab === "Submitted"
            ? [
                {
                  id: `${props.flows ? "flow_code" : "order"}`,
                  name: "Flow Code",
                  align: "left",
                },
                { id: "submit_date", name: "Email Received" },
                { id: "isSubmitted", name: "Submitted" },
                { id: "delays", name: "Delay (D)", align: "right" },
              ]
            : props.tab === "Processing"
            ? [
                {
                  id: `${props.flows ? "flow_code" : "order"}`,
                  name: "Flow Code",
                  align: "left",
                },
                { id: "template", name: "Template Name", align: "left" },
                { id: "submit_date", name: "Submitted Date" },
                { id: "due_complete_date", name: "Due Complete Date" },
                { id: "delays", name: "Delay (D)", align: "right" },
              ]
            : [
                {
                  id: `${props.flows ? "flow_code" : "order"}`,
                  name: "Flow Code",
                  align: "left",
                },
                { id: "template", name: "Template Name", align: "left" },
                { id: "submit_date", name: "Submitted Date" },
                { id: "actual_complete_time", name: "Completed Date" },
                { id: "delays", name: "Delays (D)", align: "right" },
              ]
        }
        data={formatFlowData()}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
      />
    );
  }
};

const calculateDelay = ({
  due_start,
  received_time,
  due_complete,
  actual_complete,
}) => {
  const dueCompleteDate = Date.parse(due_complete);
  const dueStartDate = Date.parse(due_start);
  const actualCompleteDate = actual_complete
    ? moment(actual_complete.split(",")[0], "DD/MM/YYYY")
    : Date.now();
  const receivedDate = received_time
    ? Date.parse(received_time.split("T")[0])
    : // moment(received_time.split("T")[0], "DD/MM/YYYY")
      Date.now();
  const dueDiff = (dueCompleteDate - dueStartDate) / (1000 * 60 * 60 * 24);
  const actualDiff =
    (actualCompleteDate - receivedDate) / (1000 * 60 * 60 * 24);
  const delay = actualDiff  - dueDiff;
  // const actualDiff = (actualDate - dueDate) / (1000 * 60 * 60 * 24);
  return delay > 0 ? Math.round(delay) : 0;
};

const TasksTable = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(null);
  let result = props.data;
  const formatDate = (date) => _.join(_.reverse(_.split(date, "/", 3)), "-");
  if (!props.data || props.popupDataLoading) return <SmallLoading />;
  else {
    props.filters.map((f) => {
      result = result.filter(f);
    });
    let preparedResult = result.map((r) => {
      if (props.detailed) {
        let res = {
          order: r.order_code,
          order_id: r.order.id,
          name: r.task_name,
          user: r.assignee,
          due: r.due_complete_datetime,
          actual: r.actual_complete_time
            ? formatDate(r.actual_complete_time.split(",")[0])
            : "Not completed yet",
          delays: r.delays,
          taskCode:
            r.order.type === "NEW"
              ? props.taskCodes?.filter(
                  (t) => t.title === r.task_name && !t.is_inquiry_task
                )[0]?.code
              : props.taskCodes?.filter(
                  (t) => t.title === r.task_name && t.is_inquiry_task
                )[0]?.code,
        };
        if (props.dialog) {
          res = { ...res, id:r.id, childTasks: r.childTasks, main_task: r.main_task };
        }
        return res;
      } else
        return {
          name: r.task_name,
          order: r.order_code,
          order_id: r.order.id,
          user: r.assignee,
          due_complete_date: r.due_complete_datetime,
          actual_complete_date: r.actual_complete_time,
          delays:r.delays,
        };
    });
    if (props.detailed && !props.dialog) {
      preparedResult = _.orderBy(preparedResult, [(o) => o?.delays], ["desc"]);
    }
    preparedResult = props.detailed
      ? !["All", undefined].includes(props.selectedTaskCode)
        ? preparedResult.filter((p) => p.taskCode === props.selectedTaskCode)
        : preparedResult
      : preparedResult;
    
    if (props.dialog){
      if (preparedResult) {
        let filteredChildTasks = _.groupBy(preparedResult.filter(t => t.main_task),(t => t.main_task))
        preparedResult = preparedResult.filter(t => !t.main_task).map(t => {
          if(t.id in filteredChildTasks){
            return {...t,childTasks:filteredChildTasks[t.id]}
          }else{
            return {...t}
          }
        })
      }
    }
    return (
      <CustomTable
        detailed={props.detailed}
        isFlows={props.isFlows}
        dialog={props.dialog}
        searchColumn={"order"}
        maxHeight={props.height}
        order={order}
        orderBy={orderBy}
        columns={
          props.detailed
            ? !props.isFlows
              ? [
                  { id: "order", name: "Flow Code", align: "left" },
                  { id: "taskCode", name: "Task Code" },
                  { id: "name", name: "Task Name", align: "left" },
                  { id: "user", name: "Assignee", align: "left" },
                  { id: "due", name: "Due Complete" },
                  { id: "actual", name: "Actual Complete Time" },
                  { id: "delays", name: "Delay (D)", align: "right" },
                ]
              : [
                  { id: "taskCode", name: "Task Code" },
                  { id: "name", name: "Task Name", align: "left" },
                  { id: "user", name: "Assignee", align: "left" },
                  { id: "due", name: "Due Complete" },
                  { id: "actual", name: "Actual Complete Time" },
                  { id: "delays", name: "Delay (D)", align: "right" },
                ]
            : props.panel === "To be Received"
            ? [
                { id: "order", name: "Flow Code", align: "left" },
                { id: "name", name: "Task Name", align: "left" },
                { id: "user", name: "Assignee", align: "left" },
              ]
            : props.panel === "Processing"
            ? [
                { id: "order", name: "Flow Code", align: "left" },
                { id: "name", name: "Task Name", align: "left" },
                { id: "user", name: "Assignee", align: "left" },
                { id: "due_complete_date", name: "Due Complete Date" },
                { id: "delays", name: "Delay (D)", align: "right" },
              ]
            : props.panel === "In Approval"
            ? [
                { id: "order", name: "Flow Code", align: "left" },
                { id: "name", name: "Task Name", align: "left" },
                { id: "user", name: "Assignee", align: "left" },
                { id: "due_complete_date", name: "Due Complete Date" },
                { id: "delays", name: "Delay (D)", align: "right" },
              ]
            : [
                { id: "order", name: "Flow Code", align: "left" },
                { id: "name", name: "Task Name", align: "left" },
                { id: "user", name: "Assignee", align: "left" },
                { id: "actual_complete_date", name: "Complete Date" },
                { id: "delays", name: "Delay (D)", align: "right" },
              ]
        }
        data={preparedResult}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
      />
    );
  }
};

const DepartmentsSelector = ({
  selectedDept,
  setSelectedDept,
  departments,
  setDepartments,
  dataRecieved,
}) => {
  const classes = useStyles();
  useQuery(
    {
      type: "TNA_CUSTOM_ACTION",
      resource: "departments",
      payload: {
        action: null,
        method: "GET",
      },
    },
    {
      onFailure: (e) => {
        showToastError("Departments Retrieval Failed");
      },
      onSuccess: (response) => {
        setDepartments(response.data.results.length === 1 ? response.data.results : [{ admin_user: null, code: "All", id: 0, name: "All", plant: null }].concat(response.data.results));
        // setDepartments(departments?.length === 1? departments.concat(response.data.results): departments);
      },
    }
  );

  return (
    <FormControl variant="outlined" style={{ float: "left" }}>
      <InputLabel
        style={{ marginTop: 1, marginLeft: -3 }}
        id="demo-simple-select-label"
      >
        Department
      </InputLabel>
      <Select
        disabled={!dataRecieved}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedDept ? selectedDept : departments.length === 1 && departments[0].code !== "All" ? departments[0].code : "All"}
        label="Department"
        onChange={(e) => {
          setSelectedDept(e.target.value);
        }}
        classes={{
          root: classes.select
        }}
      >
        {departments?.map((d) => (
          <MenuItem value={d.code}>{d.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function TnaDashboard({
  toDo,
  processing,
  completed,
  unassigned,
  loading,
  onLoad,
  onLoadUnassignTask,
}) {
  useEffect(() => {
    onLoad();
    onLoadUnassignTask();
  }, []);
  const classes = useStyles();
  const { permissions } = usePermissions();
  const counts = useSelector(getTasksCounts);
  const unassignedTaskCounts = useSelector(getUnassignedTasksCounts);
  const [flowsTabValue, setFlowsTabValue] = useState(0);
  const [tasksTabValue, setTasksTabValue] = useState(0);
  const [delaysTabValue, setDelaysTabValue] = useState(0);
  const [nestedTasksTabValue, setnestedTasksTabValue] = useState(0);
  const [departments, setDepartments] = useState([
    { admin_user: null, code: "All", id: 0, name: "All", plant: null },
  ]);
  const [taskSelectedDept, setTaskSelectedDept] = useState();
  const [indTaskSelectedDept, setIndTaskSelectedDept] = useState();
  const [filteredTasks, setFilteredTasks] = useState();
  const [filteredTasksInFlows, setFilteredTasksInFlows] = useState();
  const [filteredDetailedTasks, setFilteredDetailedTasks] = useState();
  const [allTasks, setAllTasks] = useState();
  const [selectedFlowType, setSelectedFlowType] = useState("INQ");
  const [notStartedFlows, setNotStartedFlows] = useState();
  const [dataRecieved, setDataRecieved] = useState(false);
  const [graph, setGraph] = useState(false);
  const [clusterInFlows, setClusterInFlows] = useState([]);
  const [clusterInTasks, setClusterInTasks] = useState([]);
  const [dateInFlows, setDateInFlows] = useState([
    moment().subtract(30, "days"),
    new Date(),
  ]);
  const [dateInTasks, setDateInTasks] = useState([
    moment().subtract(30, "days"),
    new Date(),
  ]);
  const [clusters, setClusters] = useState({});
  const [taskCodes, setTaskCodes] = useState([
    {
      code: "All",
      title: "All",
    },
  ]);
  const [flowTypeTaskCodes, setFlowTypeTaskCodes] = useState([
    {
      code: "All",
      title: "All",
    },
  ]);
  const [selectedTaskCode, setSelectedTaskCode] = useState();
  const [searchedVal, setSearchedVal] = useState();
  const [templates, setTemplates] = useState();
  const [showTasks, setShowTasks] = useState(false);
  const [flowDetails, setFlowDetails] = useState();
  const [selectedFlowTasks, setSelectedFlowTasks] = useState();
  const [popupDataLoading, setPopupDataLoading] = useState()
  let allFormattedFlowTasks = selectedFlowTasks
  if (selectedFlowTasks && allTasks) {
    allFormattedFlowTasks = selectedFlowTasks?.map(t => {
      let task = allTasks?.filter(a => a.id === t.id)
      return {...t, 
        delays: task[0]?.delays,
        delayAsFlow: task[0]?.delayAsFlow
      } 
    } )
  }
  const [selectedFlowId, setSelectedFlowId] = useState(null)

  // useEffect(() => {
  //   if(!showTasks) {
  //     setSelectedFlowTasks(null)
  //   }
  // }, [showTasks])



  const handleFlowsClusterChange = (event) => {
    const {
      target: { value },
    } = event;
    setClusterInFlows(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleTasksClusterChange = (event) => {
    const {
      target: { value },
    } = event;
    setClusterInTasks(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleFlowsDateRangeChange = (range) => {
    if (!range) {
      range = [new Date(), moment().add(4, "days")];
    }
    setDateInFlows(range);
  };
  const handleTasksDateRangeChange = (range) => {
    if (!range) {
      range = [new Date(), moment().add(4, "days")];
    }
    setDateInTasks(range);
  };

  useEffect(() => {
    async function fetchTasks() {
      const resp = await dataProvider.TNA_CUSTOM_LIST("itemTasks", {
        action: "fetchTasksAll",
      });
      if (resp) {
        setDataRecieved(true);
        // console.log("First task: ", resp.data.tasks[0]);
        setAllTasks(resp?.data?.tasks);
        setFilteredTasks(resp?.data?.tasks);
        setFilteredTasksInFlows(resp?.data?.tasks);
        setFilteredDetailedTasks(resp?.data?.tasks);
      }
    }
    async function fetchClusters() {
      const resp = await dataProvider.TNA_CUSTOM_LIST("dropdown", {
        action: "fetch_inquiry_serial_data",
      });
      if (resp) {
        setClusters(resp.data?.data?.cluster[0]);
        setClusterInFlows(Object.values(resp.data?.data?.cluster[0]));
        setClusterInTasks(Object.values(resp.data?.data?.cluster[0]));
      }
    }

    async function fetchOrders() {
      const resp = await dataProvider.TNA_CUSTOM_LIST("orders", {
        action: "getNotStartedFlows",
      });
      if (resp) {
        setNotStartedFlows(resp.data?.orders);
      }
    }

    async function fetchTaskCodes() {
      const resp = await dataProvider.getList("tna/tasks", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
      });
      if (resp) {
        setFlowTypeTaskCodes(taskCodes.concat(resp.data));
        setTaskCodes(
          taskCodes.concat(resp.data.filter((t) => t.is_inquiry_task))
        );
      }
    }

    async function fetchTemplates() {
      const resp = await dataProvider.getList("tna/templates", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        // filter: {  },
      });
      if (resp) {
        setTemplates(resp.data);
      }
    }

    // async function fetchAllOrders() {
    //   const resp = await dataProvider.getList("tna_itemTasks", {
    //     sort: { field: "id", order: "ASC" },
    //     pagination: { page: 1, perPage: 1000 },
    //     // filter: {order:213}
    //   });
    //   if (resp) {
    //     setAllFlowTasks(resp.data);
    //   }
    // }

    fetchTasks();
    fetchOrders();
    fetchClusters();
    fetchTaskCodes();
    fetchTemplates();
    // fetchAllOrders();
  }, []);

  useEffect(() => {
    async function fetchAllOrders(flowId) {
      if (flowId) {
      const resp = await dataProvider.getList("tna_itemTasks", {
        sort: { field: "id", order: "ASC" },
        pagination: { page: 1, perPage: 100 },
        filter: { order: flowId }
      });
      if (resp) {
        setPopupDataLoading(false)
        setSelectedFlowTasks(resp.data);
      }
    }
    }
    fetchAllOrders(selectedFlowId)
  }, [selectedFlowId])


  useEffect(() => {
    if (taskSelectedDept) {
      setFilteredTasks(
        taskSelectedDept === "All"
          ? allTasks
          : allTasks.filter((t) => t.tna_department === taskSelectedDept)
      );
    }
  }, [taskSelectedDept]);

  useEffect(() => {
    if (indTaskSelectedDept) {
      setFilteredDetailedTasks(
        searchedVal === undefined || searchedVal === ""
          ? indTaskSelectedDept === "All"
            ? allTasks
            : allTasks.filter((t) => t.tna_department === indTaskSelectedDept)
          : requestSearch(
              searchedVal,
              "order_code",
              indTaskSelectedDept === "All"
                ? allTasks
                : allTasks.filter(
                    (t) => t.tna_department === indTaskSelectedDept
                  )
            )
      );
    }
  }, [indTaskSelectedDept, searchedVal]);
  useEffect(() => {
    setTaskCodes(
      // selectedFlowType
      //   ?
      selectedFlowType === "INQ"
        ? [
            {
              code: "All",
              title: "All",
            },
          ].concat(flowTypeTaskCodes.filter((t) => t.is_inquiry_task))
        : flowTypeTaskCodes.filter((t) => !t.is_inquiry_task)
      // : flowTypeTaskCodes
    );
  }, [selectedFlowType]);

  if (loading) return <Loading />;

  function flowsTabHandleChange(event, newValue) {
    setFlowsTabValue(newValue);
  }

  function tasksTabHandleChange(event, newValue) {
    setTasksTabValue(newValue);
  }

  function nestedTasksTabValueChange(event, newValue) {
    setnestedTasksTabValue(newValue);
  }

  function delaysTabHandleChange(event, newValue) {
    setDelaysTabValue(newValue);
  }

  const flowsSubmittedFilter = (o) => !o.order.template;
  const flowsCompletedFilter = (o) => o.order.is_complete != null;
  const flowsProcessingFilter = (o) =>
    o.order.template && o.order.is_complete == null;
  const tasksProcessingFilter = (o) =>
    o.task_status == "Started" || o.task_status == "Received";
  const tasksPendingFilter = (o) => o.task_status == "Not Received";
  const tasksCompletedFilter = (o) => o.task_status == "Completed";
  const tasksInApprovalFilter = (o) =>
    o.task_status == "Transferred for Approval";
  const delayFilter = (o) => o.delays === 0;
  // const taskProcessingDelayFilter = (o) =>
  //   Date.parse(o.due_complete_datetime) <= Date.now();
  // const taskPendingDelayFilter = (o) =>
  //   Date.now() <= Date.parse(o.due_start_datetime);
  // const taskCompletedDelayFilter = (o) =>
  //   Date.parse(o.actual_complete_time) <= Date.parse(o.due_complete_datetime);
  // const taskInApprovalDelayFilter = (o) =>
  //   Date.now() <= Date.parse(o.due_complete_time);
  const inquiryFilter = (o) => o.order.type == "IQ";
  const orderFilter = (o) => ["NEW", "RP"].includes(o.order.type);
  const requestSearch = (searchedVal, searchColumn, tasks) => {
    let filData = tasks;
    if (searchedVal) {
      filData = filData.filter((task) => {
        return task[searchColumn]
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      });
    }
    return filData;
  };

  const taskMap = {
    ToDo: toDo,
    Processing: processing,
    Completed: completed,
    Unassigned: unassigned,
  };

  const NestedPanel = (props) => {
    const negationDelayFilter = (o) => {
      return !props.filters[1](o);
    };
    const panelFilters =
      selectedFlowType == "INQ" ? [inquiryFilter] : [orderFilter];
    let panelData = filteredTasks;
    panelFilters.map((f) => {
      panelData = panelData?.filter(f);
    });
    const formatDate = (date) => _.join(_.split(date, "/", 3), "-");
    panelData = panelData
      ? props.panel === "To be Received"
        ? panelData.filter((t) =>
            _.inRange(
              Date.parse(t.due_start_datetime),
              Date.parse(props.dateRange[0]),
              Date.parse(props.dateRange[1])
            )
          )
        : ["Processing", "In Approval"].includes(props.panel)
        ? panelData.filter((t) =>
            _.inRange(
              Date.parse(t.due_complete_datetime),
              Date.parse(props.dateRange[0]),
              Date.parse(props.dateRange[1])
            )
          )
        : props.panel === "Completed"
        ? panelData.filter((t) =>
            _.inRange(
              Date.parse(t.actual_complete_time),
              Date.parse(props.dateRange[0]),
              Date.parse(props.dateRange[1])
            )
          )
        : panelData
      : panelData;
    const setFlowData = (flowType) =>
      panelData.filter((p) => {
        return p.order.type === flowType;
      });

    const getClusterFilteredData = () => {
      if (!panelData) return panelData;
      let cData = [];
      clusterInTasks.map((cluster) => {
        const clusterKey = Object.keys(clusters).find(
          (key) => clusters[key] === cluster
        );
        const data = panelData.filter(
          (x) =>
            x.order.inquiry_serial &&
            _.split(x.order_code, "/", 1).includes(clusterKey)
        );
        cData = cData.concat(data);
        return data;
      });
      cData = !["ODR", "INQ"].includes(props.selectedFlowType)
        ? cData.concat(setFlowData("NEW"))
        : cData;
      return cData;
    };
    panelData = getClusterFilteredData();
    return (
      <>
        <Tabs
          value={nestedTasksTabValue}
          onChange={nestedTasksTabValueChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable auto tabs example"
          className="px-0"
        >
          <Tab label="On Time" className="sub_tab" />
          <Tab label="Delayed" className="sub_tab" />
        </Tabs>
        <TabPanel value={nestedTasksTabValue} index={0} className="px-0">
          <TasksTable
            panel={props.panel}
            data={panelData}
            filters={props.filters}
            height={420}
          />
        </TabPanel>
        <TabPanel value={nestedTasksTabValue} index={1}>
          <TasksTable
            panel={props.panel}
            data={panelData}
            filters={[props.filters[0], negationDelayFilter]}
            height={420}
          />
        </TabPanel>
      </>
    );
  };
  const cardWidth = permissions?.permissions.includes("tna_tasks_assign") ? 3 : 4;

  return (
    <>
      <Title title="Welcome to TNA" />
        <div className={classes.root}>
          <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 4 }}>
          <Grid item xs={12} sm={6} md={6} lg={cardWidth} className="gridblock">
              <Paper className={classes.paper}>
                <h3>Pending Tasks</h3>
                <div
                  style={{
                    background: `url(${Pending})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    width: "100%",
                    height: "55%",
                    marginBottom: "15px",
                  }}
                />
                <Button
                  component={Link}
                  to={{ pathname: "/todo" }}
                  className={classes.link}
                >
                  {counts?.pendingCount}
                </Button>
              </Paper>
            </Grid>
          <Grid item xs={12} sm={6} md={6} lg={cardWidth} className="gridblock">
              <Paper className={classes.paper}>
              <h3>Processing Tasks</h3>
                <div
                  style={{
                    background: `url(${ToDo})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    width: "100%",
                    height: "55%",
                    marginBottom: "15px",
                  }}
                />
                <Button
                  component={Link}
                  to={{ pathname: "/processing" }}
                  className={classes.link}
                >
                  {counts?.toDoCount}
                </Button>
              </Paper>
            </Grid>
          <Grid item xs={12} sm={6} md={6} lg={cardWidth} className="gridblock">
              <Paper className={classes.paper}>
              <h3>Completed Tasks</h3>
                <div
                  style={{
                    background: `url(${Completed})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    width: "100%",
                    height: "55%",
                    marginBottom: "15px",
                  }}
                />
                <Button
                  component={Link}
                  to={{ pathname: "/completed" }}
                  className={classes.link}
                >
                  {counts?.completedCount}
                </Button>
              </Paper>
            </Grid>
            {/* {permissions?.permissions?.access_level != 'TNA_USER' &&  */}
          {permissions?.permissions.includes("tna_tasks_assign") && <Grid item xs={12} sm={6} md={6} lg={cardWidth} className="gridblock">
              <Paper className={classes.paper}>
              <h3>Update Assignees</h3>
                <div
                  style={{
                    background: `url(${Unassigned})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    width: "100%",
                    height: "55%",
                    marginBottom: "15px",
                  }}
                />
                <Button
                  component={Link}
                  to={{ pathname: "/unassigned" }}
                  className={classes.link}
                >
                  {unassignedTaskCounts?.unassignedCount}
                </Button>
              </Paper>
          </Grid>}
            {permissions?.access_level != "TNA_USER" && (
              <Grid item xs={12} className="analytics_wrapper">
                <Paper className={classes.paper} style={{ height: 1500 }} className="analytics_div">
                  <h3>Analytics</h3>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={1}>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-label" className="type-dropdown">
                          Type
                        </InputLabel>
                        <Select
                          disabled={!dataRecieved}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedFlowType ? selectedFlowType : "All"}
                          label="Type"
                          onChange={(e) => {
                            if (e.target.value === "ODR") {
                              setGraph(false);
                            }
                            setSelectedFlowType(e.target.value);
                          }}
                        >
                          {[
                            // { code: "A", name: "All" },
                            { code: "INQ", name: "Inquiry" },
                            { code: "ODR", name: "Order" },
                          ].map((d) => (
                            <MenuItem value={d.code}>
                              <span
                                style={{
                                  fontSize: 14,
                                  color: theme.palette.primary.dark,
                                }}
                              >
                                {d.name}
                              </span>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <div>
                    <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 6 }}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Paper className={classes.analyticsPaper} className="flows">
                          <Grid container spacing={2} columns={{ xs: 4, sm: 1, md: 6 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Typography className="mt-1 pl-3" variant="h5">
                               <h3> {selectedFlowType === "INQ"
                                  ? "Inquiry Flows"
                                  : "Order Flows"}
                                </h3>
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              spacing={8}
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              className="mb-1 px-4"
                            >
                              <DateClusterSelect
                                selectedFlowType={selectedFlowType}
                                clusters={clusters}
                                handleClusterChange={handleFlowsClusterChange}
                                handleDateRangeChange={
                                  handleFlowsDateRangeChange
                                }
                                selectedCluster={clusterInFlows}
                                dateRange={dateInFlows}
                              />
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <CustomSwitch
                                      checked={
                                        selectedFlowType === "ODR"
                                          ? false
                                          : graph
                                      }
                                      disabled={selectedFlowType === "ODR"}
                                      onChange={(event) => {
                                        setGraph(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={"Graphical view"}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Tabs
                            value={flowsTabValue}
                            onChange={flowsTabHandleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable auto tabs example"
                            left
                            className="ml-4 mt-4"
                          >
                            <Tab label="Submitted" />
                            <Tab label="Processing" />
                            <Tab label="Completed" />
                          </Tabs>
                          <TabPanel value={flowsTabValue} index={0}>
                            <FlowsView
                              showTasks={showTasks}
                              setShowTasks={setShowTasks}
                              showGraph={graph}
                              data={notStartedFlows}
                              filters={[]}
                              flows={true}
                              tab="Submitted"
                              selectedCluster={clusterInFlows}
                              clusters={clusters}
                              selectedFlowType={selectedFlowType}
                              dateRange={dateInFlows}
                              setFlowDetails={setFlowDetails}
                            />
                          </TabPanel>
                          <TabPanel value={flowsTabValue} index={1}>
                            <FlowsView
                              showTasks={showTasks}
                              setShowTasks={setShowTasks}
                              templates={templates}
                              showGraph={graph}
                              data={filteredTasksInFlows}
                              filters={[flowsProcessingFilter]}
                              tab="Processing"
                              selectedCluster={clusterInFlows}
                              clusters={clusters}
                              selectedFlowType={selectedFlowType}
                              dateRange={dateInFlows}
                              setFlowDetails={setFlowDetails}
                              setSelectedFlowId={setSelectedFlowId}
                              setPopupDataLoading={setPopupDataLoading}
                              popupDataLoading={popupDataLoading}
                            />
                          </TabPanel>
                          <TabPanel value={flowsTabValue} index={2}>
                            <FlowsView
                              showTasks={showTasks}
                              setShowTasks={setShowTasks}
                              templates={templates}
                              showGraph={graph}
                              data={filteredTasksInFlows}
                              filters={[flowsCompletedFilter]}
                              tab="Completed"
                              selectedCluster={clusterInFlows}
                              clusters={clusters}
                              selectedFlowType={selectedFlowType}
                              dateRange={dateInFlows}
                              setFlowDetails={setFlowDetails}
                              setSelectedFlowId={setSelectedFlowId}
                              setPopupDataLoading={setPopupDataLoading}
                            />
                          </TabPanel>
                        </Paper>
                        <TaskDialog
                          taskCodes={taskCodes}
                          data={allFormattedFlowTasks}
                          flowDetails={flowDetails}
                          showTasks={showTasks}
                          setShowTasks={setShowTasks}
                          selectedFlowType={selectedFlowType}
                          popupDataLoading={popupDataLoading}
                          setSelectedFlowId={setSelectedFlowId}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Paper className={classes.analyticsPaper} className="tasks-wrapper">
                          <Grid container spacing={2} columns={{ xs: 4, sm: 1, md: 6 }}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                              <Typography className="mt-1 ml-3" variant="h5">
                                <h3>Tasks</h3>
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              spacing={8}
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              className="mb-1 px-4"
                            >
                              <Grid className="pr-0" item xs={3}>
                                <DepartmentsSelector
                                  dataRecieved={dataRecieved}
                                  selectedDept={taskSelectedDept}
                                  setSelectedDept={setTaskSelectedDept}
                                  departments={departments}
                                  setDepartments={setDepartments}
                                />
                              </Grid>
                              <DateClusterSelect
                                selectedFlowType={selectedFlowType}
                                clusters={clusters}
                                handleClusterChange={handleTasksClusterChange}
                                handleDateRangeChange={
                                  handleTasksDateRangeChange
                                }
                                selectedCluster={clusterInTasks}
                                dateRange={dateInTasks}
                              />
                            </Grid>
                          </Grid>
                          <Tabs
                            value={tasksTabValue}
                            onChange={tasksTabHandleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable auto tabs example"
                            left
                            className="ml-4 mt-4"
                          >
                            <Tab label="To be Received" />
                            <Tab label="Processing" />
                            <Tab label="In Approval" />
                            <Tab label="Completed" />
                          </Tabs>
                          <TabPanel value={tasksTabValue} index={0}>
                            <NestedPanel
                              panel="To be Received"
                              filters={[tasksPendingFilter, delayFilter]}
                              dateRange={dateInTasks}
                            />
                          </TabPanel>
                          <TabPanel value={tasksTabValue} index={1}>
                            <NestedPanel
                              panel="Processing"
                              filters={[tasksProcessingFilter, delayFilter]}
                              dateRange={dateInTasks}
                            />
                          </TabPanel>
                          <TabPanel value={tasksTabValue} index={2}>
                            <NestedPanel
                              panel="In Approval"
                              filters={[tasksInApprovalFilter, delayFilter]}
                              dateRange={dateInTasks}
                            />
                          </TabPanel>
                          <TabPanel value={tasksTabValue} index={3}>
                            <NestedPanel
                              panel="Completed"
                              filters={[tasksCompletedFilter, delayFilter]}
                              dateRange={dateInTasks}
                            />
                          </TabPanel>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper
                          className={classes.analyticsPaper}
                          style={{ height: 630 }}
                          className="individual_task px-4 pb-4"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography className="mt-1" variant="h5">
                                <h3>Individual Tasks Detailed View</h3>
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              className="mb-3"
                            >
                              <Grid item xs={2}>
                                <div className="mx-2">
                                  <FormControl variant="outlined">
                                    <InputLabel id="demo-simple-select-label">
                                      Task Code
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={
                                        selectedTaskCode
                                          ? selectedTaskCode
                                          : "All"
                                      }
                                      label="Task Code"
                                      onChange={(e) => {
                                        return setSelectedTaskCode(
                                          e.target.value
                                        );
                                      }}
                                    >
                                      {taskCodes?.map((t) => (
                                        <MenuItem value={t.code}>
                                          {t.code === "All"
                                            ? t.code
                                            : `${t.code} | ${t.title}`}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <DepartmentsSelector
                                  dataRecieved={dataRecieved}
                                  selectedDept={indTaskSelectedDept}
                                  setSelectedDept={setIndTaskSelectedDept}
                                  departments={departments}
                                  setDepartments={setDepartments}
                                />
                              </Grid>
                              <Grid item className="ml-3" xs={2}>
                                <TextField
                                  id="outlined-search"
                                  label="Search by flow ID"
                                  type="search"
                                  onChange={(e) =>
                                    setSearchedVal(e.target.value)
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <TasksTable
                            taskCodes={taskCodes}
                            selectedTaskCode={selectedTaskCode}
                            data={filteredDetailedTasks}
                            filters={
                              selectedFlowType == "A"
                                ? []
                                : selectedFlowType == "INQ"
                                ? [inquiryFilter]
                                : [orderFilter]
                            }
                            height={500}
                            detailed={true}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            )}
          </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const toDo = getTodoTasks(state);
  const processing = getProcessingTasks(state);
  const completed = getCompletedTasks(state);
  const unassigned = getUnassignedTasks(state);
  const loading = getTNATaskLoading(state);

  return {
    toDo,
    processing,
    completed,
    unassigned,
    loading,
  };
};

const mapDispatchToProps = {
  onLoad: loadALLTasks,
  onLoadUnassignTask: loadUnassignedTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(TnaDashboard);
