import { LOAD_DATA, LOAD_DATA_SUCCESS, SET_CURRENT_PLANT, SET_DATA } from "../types/transferTypes";

const initialState = {
  // entities: null, // normalizePlanBoardTree(sampelData.tree).entities,
  loading: null,
  entities: {
    demandOps: null,
    plants: null,
    currentPlant : null
  }
};

export const transferReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOAD_DATA: {
      return { ...state, loading: true };
    }

    case SET_DATA: {
      const { entities } = action.payload;
      return { ...state, entities: entities };
    }
    case LOAD_DATA_SUCCESS: {
      return { ...state, loading: false };
    }

    case SET_CURRENT_PLANT: {
      const { plant } = action.payload;
      return { ...state, entities: { ...state.entities, currentPlant: plant } };
      
    }
    default:
      // code block
      return state;
  }
};
