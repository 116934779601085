import { TrendingUpRounded } from "@material-ui/icons";
import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  WithPermissions,
  usePermissions,
} from "react-admin";
import {
  TopToolbar,
  // CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
} from "react-admin";
import CreateButton from "../../components/CustomCreateButton";
import { cloneElement, useMemo } from "react";
import Confirmation from "../../components/Confirmation";
import { makeStyles } from "@material-ui/core/styles";
import {ChangeDiv} from "../../components/ChangeDiv";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    width: window.innerWidth * 0.1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
  },
  mr: {
    marginRight: "0.5%",
  },
}));

export const Sites = (props) => {
  const { permissions } = usePermissions();

  const Actions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const classes = useStyles();
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,

      basePath,
      selectedIds,
      showFilter,
      total,
    } = useListContext();

    return (
      <TopToolbar
        className={classes.buttonContainer}
        {...sanitizeListRestProps(rest)}
      >
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
            className: "btn_",
          })}
        <CreateButton
          basePath={basePath}
          title="Click here to create site"
          disabled={!permissions?.permissions.includes("sites_create")}
        />
        <ExportButton
          className="btn_ btn_primary exportAlign"
          title="Click here to Export Sites"
          disabled={!permissions?.permissions.includes("sites_export")}
        />
      </TopToolbar>
    );
  };

  return (
    <List {...props} actions={<Actions />} bulkActionButtons={false} perPage={25}>
      <ChangeDiv>
        {props => {
          return <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="code" />
          <EditButton
            {...props}
            title="permissions"
            className="btn_ btn_edit permission-edit-btn "
            style={{ marginRight: "100px", float: "right" }}
            disabled={!permissions?.permissions.includes("sites_edit")}
          />{" "}
          <Confirmation
            //  className="permission-edit-btn "
            //  style={{ marginRight: "100px", float: "right" }}
            disabled={!permissions?.permissions.includes("sites_delete")}
            title="Click here to Edit site"
          />{" "}
        </Datagrid>
        }}
      </ChangeDiv>
      
    </List>
  );
};
