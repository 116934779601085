import React from 'react';
import Error from '../layout/img/error.png';
const ErrorPage = () => {
    return (
        <div>
            <img src={Error}  width="100%" height="" />
            
        </div>
    );
};

export default ErrorPage;