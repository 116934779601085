import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import {
  BooleanField,
  Datagrid,
  List,
  Mutation,
  TextField,
  usePermissions,
} from "react-admin";
import { ChangeDiv } from "../../components/ChangeDiv";
import CustomDateField from "../../components/field/CustomDateField";

export const IntegrationRecord = (props) => {
  const Title = ({ record }) => {
    return <span>Integration</span>;
  };

  return (
    <List
      {...props}
      actions={<Actions />}
      title={<Title />}
      bulkActionButtons={false}
      perPage={25}
    >
      <ChangeDiv>
          {props => {
        return <Datagrid rowClick="show" expand={<Message />}>
          <TextField source="id" sortable={false} />
          <TextField source="state" sortable={false} />
          <BooleanField source="active" className="pr-3" sortable={false} />
          <CustomDateField source="start_time" showTime sortable={false} />
          <CustomDateField
            source="end_time"
            showTime
            sortable={false}
            // className="permission-edit-btn "
            // style={{ marginRight: "100px", float: "left" }}
          />
          {/* <TextField source="message" /> */}
        </Datagrid>}}
       </ChangeDiv> 
    </List>
  );
};

const Message = ({ id, record, resource }) => (
  <div style={{ padding: 20 }}>
    <code>{record.message}</code>
  </div>
);

// const permissions = localStorage.getItem('role')

const Actions = () => {
  const [open, setOpen] = React.useState(false);
  const [dilogOpen, setDilogtOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    open && setOpen(false);
    dilogOpen && setDilogtOpen(false);
  };

  const { permissions } = usePermissions();

  const SyncButton = ({ sync, title, disabled }) => {
    // creating payloard body based on sync
    let body = {};
    if (sync) {
      body.sync = true;
    } else {
      body.sync = false;
    }

    const options = {
      undoable: false,
      onSuccess: {
        notification: {
          body: "Data sync started. Click refresh button to view progress of new sync job",
          level: "info",
        },
        refresh: true,
      },
      onError: {
        notification: { body: "Error: action not performed", level: "warning" },
        refresh: true,
      },
    };

    return (
      <Mutation
        type="CUSTOM"
        resource="integrationRecord"
        payload={{ action: "sync", method: "POST", body }}
        options={options}
      >
        {(action) => (
          <Button
            variant="contained"
            class="btn-success px-5 py-2 border-0"
            color="Secondary"
            onClick={() => {
              action();
              handleClose();
            }}
          >
            Yes
          </Button>
        )}
      </Mutation>
    );
  };

  return (
    <div>
      <Button
        // style={{ marginRight: "10px" }}
        variant="contained"
        // color="primary"
        className="btn_ btn_edit"
        onClick={handleClickOpen}
        title="Click here to Sync"
        disabled={!permissions?.permissions.includes("integrationRecord_sync")}
      >
        Sync
      </Button>
      <Dialog
        open={open || dilogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will sync data. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            // color="primary"
            className="btn_ btn_primary"
          >
            Cancel
          </Button>
          <SyncButton sync={open ? false : true} />
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        // color="primary"
        className="btn_ btn_delete"
        onClick={() => setDilogtOpen(true)}
        title="Click here to Orders Sync"
        disabled={
          !permissions?.permissions.includes("integrationRecord_ordersSync")
        }
      >
        Orders Sync
      </Button>
    </div>
  );
};
