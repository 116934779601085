import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import { format_to_date } from "../lib/date_time";
import FilterListIcon from "@material-ui/icons/FilterList";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

export const RangeFilterButton = ({
  setOpenRangeFilterDialog,
  rangeFilters,
  w_width,
  setRangeFilters,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const RangeFilterDetailsPopover = () => {
    return (
      <Popover
        style={{ pointerEvents: "none" }}
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Card style={{ padding: "10px" }}>
          {rangeFilters
            .filter((rangeFilter) => rangeFilter.column)
            .map((rangeFilter) => (
              <Typography style={{ padding: "10px" }}>
                {`${rangeFilter.column?.Header} ${rangeFilter.column?.filterType === "DateColumnFilter"
                    ? `(${format_to_date(rangeFilter.start)} - ${format_to_date(
                      rangeFilter.end
                    )})`
                    : `(${rangeFilter.start} - ${rangeFilter.end})`
                  }`}
              </Typography>
            ))}
        </Card>
      </Popover>
    );
  };
  const handlePopoverOpen = (event) => {
    if (rangeFilters[0].column) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    if (rangeFilters[0].column) {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const filterLength = rangeFilters.filter((filter) => filter.column).length;

  return (
    <>
      <Button
        aria-owns={open ? "mouse-over-popover" : undefined}
        className="btn_ btn_primary mb-0"
        onClick={() => setOpenRangeFilterDialog(true)}
        onMouseLeave={handlePopoverClose}
        onMouseEnter={handlePopoverOpen}
      >
        {`Range filters `}
        <Chip
          className="range_filter_chip"
          hidden={!filterLength}
          color="secondary"
          label={filterLength}
          style={{ marginLeft: "5px" }}
        />
      </Button>
      <RangeFilterDetailsPopover />
    </>
  );
};

export const RangeFilterDialog = ({
  setRangeFilters,
  rangeFilters,
  columns,
  isOpen,
  setIsOpen,
}) => {
  const selectableColumns = columns.filter((c) => c.hasOwnProperty("Filter"));

  const handleClose = () => {
    setIsOpen(false);
  };

  const RangeFilterInputs = ({ filterName, setRangeFilter, rangeFilter }) => {
    const [start, setStart] = React.useState(
      rangeFilter?.type === "date"
        ? moment(rangeFilter.start).format("YYYY-MM-DD")
        : rangeFilter.start
    );
    const [end, setEnd] = React.useState(
      rangeFilter?.type === "date"
        ? moment(rangeFilter.end).format("YYYY-MM-DD")
        : rangeFilter.end
    );
    const [type, setType] = React.useState(rangeFilter.type);

    const onBlurDate = () => {
      setRangeFilter({
        ...rangeFilter,
        start: moment(start),
        end: moment(end),
        type,
      });
    };
    const onBlurNumber = () => {
      setRangeFilter({
        ...rangeFilter,
        start,
        end,
        type,
      });
    };

    return (
      <div>
        {filterName === "DateColumnFilter" ? (
          <div>
            <Typography style={{ margin: "10px" }}>
              Dates after :{" "}
              <Input
                onBlur={onBlurDate}
                value={start}
                required
                type="date"
                onChange={(e) => {
                  const val = e.target.value;
                  setStart(val);
                  setType("date");
                }}
              />{" "}
            </Typography>
            <Typography style={{ margin: "10px" }}>
              Dates before :{" "}
              <Input
                onBlur={onBlurDate}
                required
                value={end}
                type="date"
                onChange={(e) => {
                  const val = e.target.value;
                  setEnd(val);
                  setType("date");
                }}
              />
            </Typography>
          </div>
        ) : filterName === "NumberColumnFilter" ? (
          <Typography>
            <Typography style={{ margin: "10px" }}>
              Values greater than :{" "}
              <input
                min='0'
                onBlur={onBlurNumber}
                defaultValue={start}
                step='1'
                required
                type="number"
                onChange={(e) => {
                  const val = e.target.value;
                  setStart(Number(val));
                }}
              />{" "}
            </Typography>
            <Typography style={{ margin: "10px" }}>
              Values less than :{" "}
              <input
                min='0'
                step='1'
                onBlur={onBlurNumber}
                required
                defaultValue={end}
                type="number"
                onChange={(e) => {
                  const val = e.target.value;
                  setEnd(Number(val));
                }}
              />
            </Typography>
          </Typography>
        ) : (
              <Typography>Select a column in the above dropdown.</Typography>
            )}
      </div>
    );
  };

  const addRow = () => {
    setRangeFilters(
      rangeFilters.concat({
        column: "",
        start: "",
        end: "",
        type: "",
      })
    );
  };

  const removeRow = (index) => {
    setRangeFilters([
      ...rangeFilters.slice(0, index),
      ...rangeFilters.slice(index + 1),
    ]);
  };

  const Field = ({ index, rangeFilter, rangeFilters, setRangeFilters }) => {
    const setRangeFilter = (value) => {
      setRangeFilters([
        ...rangeFilters.slice(0, index),
        value,
        ...rangeFilters.slice(index + 1),
      ]);
    };

    return (
      <DialogContent>
        <Divider />
        <Grid container item xs={12} spacing={0}>
          <Typography style={{ margin: "10px" }}>Select column : </Typography>
          <Select
            onChange={(e) =>
              setRangeFilter({
                column: e.target.value,
                start: null,
                end: null,
              })
            }
            value={rangeFilter?.column}
          >
            {selectableColumns.map((c) => (
              <MenuItem style={{ fontSize: 15 }} value={c}>
                {c.Header}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            color="primary"
            style={{ align: "flex-end" }}
            onClick={() => removeRow(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Typography style={{ margin: "10px" }}>
          Select range :{" \n"}
        </Typography>{" "}
        <RangeFilterInputs
          filterName={rangeFilter.column?.filterType}
          setRangeFilter={setRangeFilter}
          rangeFilter={rangeFilter}
        />
      </DialogContent>
    );
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minHeight: "300px" }}
      >
        <DialogTitle id="alert-dialog-title">Range filters</DialogTitle>
        {rangeFilters &&
          rangeFilters[0] &&
          rangeFilters.map((field, index) => {
            return (
              <Field
                key={index}
                index={index}
                rangeFilter={field}
                rangeFilters={rangeFilters}
                setRangeFilters={setRangeFilters}
              />
            );
          })}
        <DialogActions>
          <Button
            startIcon={<AddIcon />}
            className="btn_ btn_primary"
            onClick={addRow}
            style={{ marginLeft: "15px" }}
          >
            Add range filter
          </Button>
          <div style={{ flex: "1 0 0" }} />
        </DialogActions>
        <DialogActions>
          <Button className="btn_ btn_primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
