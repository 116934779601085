import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { forwardRef } from "react";
import { BooleanInput, Filter, TextInput, useDataProvider } from "react-admin";
import { connect } from "react-redux";
import { format_to_date } from "../../lib/date_time";
import {
  getPendingTransfers,
  getPlants,
  getReceivedTransfers,
} from "../../store/selectors/transferSelectors";
import ReceivedDialog from "./ReceivedDialog";
import { usePermissions } from "react-admin";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const updateData = (array) => {
  const newArray = array.map((data) => {
    if (data.is_exchange === false) {
      data.is_exchange = true;
      return data;
    }
  });
  return newArray;
};

const getIdArray = (array) => {
  const newArray = array.map((data) => {
    return data.id;
  });
  return newArray;
};

const sortValue = {
  accepted: 2,
  pending: 1,
  reverted: 3,
  declined: 4,
};

function Table({ data, colorMappings, onSelect, title }) {
  //const posibleDateRander = (data)=>data.transfer_status !== "pending"
  
  const dataProvider = useDataProvider();
  return (
    <MaterialTable
      style={{ marginBottom:"10px", marginTop:"10px" }}
      icons={tableIcons}
      columns={[
        {
          title: "status",
          field: "transfer_status",
          customSort: (a, b) => sortValue[a.status] - sortValue[b.status],
          defaultSort: "asc",
          render: (rowData, renderType) => {
            if (rowData.status && renderType === "row") {
              return (
                <span
                  style={{
                    padding: 5,
                    borderRadius: 5,
                    backgroundColor:
                      rowData.status === "accepted"
                        ? "lightgreen"
                        : rowData.status === "declined"
                        ? "#FF7F50"
                        : "yellow",
                  }}
                >
                  {rowData.status}
                </span>
              );
            } else {
              return "";
            }
          },
        },
        {
          title: "ID",
          field: "id",
          cellStyle: { paddingLeft: 10 },
          headerStyle: {
            paddingLeft: 10,
          },
        },
        { title: "Item Code", field: "item_code" },
        { title: "Order Code", field: "order_code" },
        { title: "Order Group", field: "order_group_code" },
        {
          title: title === "Received transfers" ? "Action Date" : "",
          field: "action_date",
          render: (rowData, renderType) => {
            if (rowData.action_date) {
              return <span>{format_to_date(rowData.action_date)}</span>;
            } else {
              return "";
            }
          },
        },
        {
          title: title === "Received transfers" ? "Possible Start Date" : "",
          field: "received_date",
          type: "date",
          render: (rowData, renderType) => {
            if (rowData.rm_ready_date && title === "Received transfers") {
              return <span>{format_to_date(rowData.rm_ready_date)}</span>;
            } else {
              return "";
            }
          },
        },

        {
          title: "Need Date",
          field: "required_time_max",
          type: "date",
          render: (rowData, renderType) => {
            if (rowData.required_time_max) {
              return <span>{format_to_date(rowData.required_time_max)}</span>;
            } else {
              return "";
            }
          },
        },
        // { title: "Transferred date", field: "transfer_date", type: "date" },
        {
          title: "Transfer need date",
          field: "transfer_date",
          type: "date",
          render: (rowData, renderType) => {
            if (rowData.received_date) {
              return <span>{format_to_date(rowData.received_date)}</span>;
            } else {
              return "";
            }
          },
        },
        // { title: "Need Date", field: "required_time_max", type: "date" },
        {
          title: "Received From",
          field: "sent_workstation__code",
        },
        {
          title: title === "Received transfers" ? "Received Workstation" : "",
          field: "received_workstation__code",
        },
        { title: "Quantity", field: "quantity" },
        // {
        //   title: "Configuration",
        //   field: "configuration",
        //   render: (rowData, renderType) => {
        //     const config =
        //       renderType === "row" ? rowData.configuration : rowData;

        //     return (
        //       <span
        //         style={{
        //           padding: 5,
        //           borderRadius: 5,
        //         }}
        //       >
        //         {config}{" "}
        //       </span>
        //     );
        //   },

        {
          title: title === "Received pending transfers" ? "Actions" : "",
          render: (rowData, renderType) => {
            if (
              renderType === "row" &&
              rowData.status &&
              rowData.status === "pending" &&
              title === "Received pending transfers"
            ) {
              return <ReceivedDialog selectedTransfer={rowData} />;
            } else {
              return "";
            }
          },
        },
      ]}
      data={data}
      parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
      options={{
        searchFieldStyle:{maxWidth:200},
        defaultExpanded: true,
        grouping: true,
        pageSize: 10,
        doubleHorizontalScroll: true,
        maxBodyHeight: 500,
        debounceInterval: 1000,
        padding: "default",
        selection: false,
        selectionProps: (rowData) => ({
          disabled: rowData.is_exchange === true,
          data: rowData,
          color: "primary",
        }),
      }}
      components={{
        Toolbar: (props) => {
          const { selectedRows } = props;
          return (
            <div>
              <MTableToolbar {...props} />
              {selectedRows.length > 0 && (
                <div style={{ padding: "10px" }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      const data = updateData(selectedRows);
                      dataProvider.CUSTOM_UPDATE_MENY("demandOp", { data });
                    }}
                  >
                    Transfer
                  </Button>
                </div>
              )}
            </div>
          );
        },
      }}
      title={title}
    />
  );
}

const DemandOperationFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search by item operation"
      source="item_operation"
      alwaysOn
    />
    <BooleanInput label="Is planned" source="is_planned" alwaysOn />
    <TextInput source="title" />
  </Filter>
);

const ReceivedTab = ({ data, pendingTransfers, receivedTransfers }) => {
  const { permissions } = usePermissions();
  const treeData = (ts, dOs) => {
    const treeData = [];
    const _ts = ts.map((transfer) => ({
      ...transfer,
      Id: transfer.id,
      id: `t${transfer.id}`,
    }));
    treeData.push(..._ts);
    const ts_ids = ts.map((t) => t.id);
    const _dOs = dOs
      .filter((dO) => ts_ids.includes(dO.transfer_id))
      .map((dO) => ({ ...dO, parentId: `t${dO.transfer_id}` }));
    treeData.push(..._dOs);
    // ts.map(transfer => {
    //   const values = [];
    //   const t = { ...transfer, Id: transfer.id, id: `t${transfer.id}` };
    //   const data = dOs
    //     .filter(dO => transfer.demand_ops.includes(dO.id))
    //     .map(dO => ({ ...dO, parentId: t.id }));
    //   values.push(t);
    //   values.push(...data);
    //   treeData.push(...values);
    // });
    return treeData;
  };

  const pendingReceived = treeData(pendingTransfers, data);

  const transfersReceived = treeData(receivedTransfers, data);

  return (
    <>
      {permissions?.permissions.includes("receivedTab_pendingReceivedTable") &&
        (pendingReceived[0] || true ? (
          <Table
            title="Received pending transfers"
            data={pendingReceived}

            // colorMappings={colorMappings} onSelect={onSelect}
          />
        ) : (
          <Card style={{ marginBottom:"10px", marginTop:"10px" }}>
            <Typography style={{ padding: "20px" }} color="secondary">
              *No pending transfer requests.
            </Typography>
          </Card>
        ))}

     
      <Table
        title="Received transfers"
        data={transfersReceived}
        // colorMappings={colorMappings} onSelect={onSelect}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const plants = getPlants(state);
  const pendingTransfers = getPendingTransfers(state);
  const receivedTransfers = getReceivedTransfers(state);
  return {
    plants,
    pendingTransfers,
    receivedTransfers,
  };
};

export default connect(mapStateToProps, null)(ReceivedTab);
