export const getTodoTasks = (state: any) => state.custom.tnaTasks.todoTasks
export const getProcessingTasks = (state: any) => state.custom.tnaTasks.processingTasks
export const getCompletedTasks = (state: any) => state.custom.tnaTasks.completedTasks
export const getUnassignedTasks = (state: any) => state.custom.tnaTasks.unassignedTasks
export const getTNATaskLoading = (state: any) => state.custom.tnaTasks.loading
export const getTNAFormUploadLoading = (state: any) => state.custom.tnaTasks.formLoading;
export const getAllTasks = (state: any) => [...state.custom.tnaTasks.completedTasks, ...state.custom.tnaTasks.processingTasks, ...state.custom.tnaTasks.todoTasks]
export const getTasksCounts = (state: any) => state.custom.tnaTasks.count
export const getUnassignedTasksCounts = (state: any) => state.custom.tnaTasks.unassignedTaskCount
//Tamplate related
export const getAllUsers = (state: any) => state.custom.template.tnaUsers
export const getSuccessGenerate = (state: any) => state.custom.template.success
export const getErrorGenerate = (state: any) => state.custom.template.error
export const getInqDescription = (state: any) => state.custom.template.inqDescription
export const getSDNArray = (state: any) => state.custom.template.sdns
export const getSDNArrayLoading = (state: any) => state.custom.template.sdnsLoading
export const getRPSDNArray = (state: any) => state.custom.template.RPsdns
export const getRPSDNArrayLoading = (state: any) => state.custom.template.RPsdnsLoading

export const getTemplateArray = (state: any) => state.custom.template.templateIDs
export const getRepeatedOrders = (state: any) => state.custom.template.repeatedOrders
export const getTasks = (state: any) => state.custom.template.tasks
export const getSuccessSubmitIqSerial = (state: any) => state.custom.template.success
export const getErrorSubmitIqSerial = (state: any) => state.custom.template.error
export const getAllTentativeOrders = (state: any) => state.custom.template.tentativeOrders
export const getSelectedOrder = (state: any) => state.custom.template.selectedOrder