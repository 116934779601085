import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import CSVReader from "react-csv-reader";
import ReactDataGrid from "react-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { exportCsv } from "../lib/csvExport";

import {
  bulkActionClean,
  bulkActionSave,
  bulkActionSetData,
} from "../store/actions/bulkActions";

const StatusFormatter = ({ value }) => {
  if (value === true) {
    return <CheckCircle style={{ color: "green" }} color="green" />;
  }
  if (value === false) {
    return <Cancel color="error" />;
  }
  return <div title={value}>{value}</div>;
};

const SkipStatusFormatter = ({ value }) => {
  if (value === true) {
    return <CheckCircle style={{ color: "green" }} color="green" />;
  }
  if (value === false) {
    return <Cancel color="error" />;
  }
  if ( typeof(value) =="string" && value.trim().indexOf(' ') != -1) {
    return (
      <div>
        <Cancel color="error" />
        Invalid Format of Data
      </div>
    );
  }
  return <div title={value}>{value}</div>;
};

const CSVDataGrid = ({ data }) => {
  if (data.length <= 1) {
    return null;
  }
  const columns = data[0].map((v, i) => {
    if (!data[0].includes("message")) {
      return {
        key: i,
        name: v,
        resizable: true,
        formatter: SkipStatusFormatter,
      };
    } else {
      return {
        key: i,
        name: v,
        resizable: true,
        formatter: StatusFormatter,
      };
    }
  });

  return (
    <ReactDataGrid
      key={data}
      columns={columns}
      rowGetter={(i) => data[i + 1]}
      rowsCount={data.length - 1}
      minHeight={350}
    />
  );
};

export default function BulkActionButton({
  action,
  meta,
  description = "Upload CSV File.",
  title,
  onSuccess,
  disabled,
  maxWidth = "xl",
}) {
  const { loading, data, exicuted } = useSelector(
    (state) => state.custom.bulkActions
  );
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(bulkActionClean());
  }, [open]);

  const onOpen = () => setOpen(true);
  const onClose = () => {
    setOpen(false);
    dispatch(bulkActionClean());
    exicuted && onSuccess && onSuccess();
  };

  const onFileLoad = (data) => {
    let cleanData = data;
    if (data.length > 0) {
      const columnCount = data[0].length;
      cleanData = data.filter((d) => d.length == columnCount);
    }
    dispatch(bulkActionSetData(action, cleanData, meta));
  };

  const onSubmit = () => {
    dispatch(bulkActionSave(action, data, meta));
  };

  const onExport = () => {
    const columns = data[0];
    const exportData = data
      .slice(1)
      .map((row) =>
        columns.reduce((pre, k, i) => ({ ...pre, [k]: row[i] }), {})
      );
    exportCsv(exportData, `${title}-result`);
  };

  const hasData = data.length > 1;
  return (
    <>
      <Button
        onClick={onOpen}
        disabled={disabled}
        title={description}
        className="btn_ btn_primary"
      >
        {" "}
        {title}{" "}
      </Button>

      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle>{title}</DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={onClose} />
            </div>
          </Grid>
        </Grid>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          <CSVReader onFileLoaded={onFileLoad}></CSVReader>
          <br />
          <CSVDataGrid data={data} />
          <DialogActions>
            {exicuted && (
              <Button
                className="btn_ btn_primary"
                disabled={loading || !hasData}
                onClick={onExport}
              >
                {" "}
                Export Result{" "}
              </Button>
            )}
            <Button
              disabled={loading || !hasData || exicuted}
              onClick={onSubmit}
              className="btn_ btn_primary"
              variant="contained"
            >
              {" "}
              Upload{" "}
            </Button>
            {/* <Button
              className="btn_ btn_primary"
              disabled={loading}
              onClick={onClose}
            >
              {" "}
              Close{" "}
            </Button> */}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
