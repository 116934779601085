import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: "40%",
  },
  btn: {
    backgroundColor: "primary",
    minWidth: "120px",
    margin: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  //   formWrapper:{
  //       width:
  //   }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Preview = ({ tasks, users, changedList, setChangedList }) => {
  const [assign, setAssign] = useState(null);
  const classes = useStyles();
  const current_user = localStorage.getItem("username");
  const onChangeAssignee = (e, value, task) => {
    setAssign({
      id: task.taskId,
      assign: value,
    });
    let newList = changedList;
    if (newList[newList.findIndex((o) => o.id === task.taskId)])
      newList[newList.findIndex((o) => o.id === task.taskId)].assignee =
        value;
    setChangedList(newList);
  };
  const getOptions = (task, id) => {
    if (task.taskName === "Generate tasks flow"){
      var names = [current_user]
    }
    else{
      const taskUsers = users.filter((u) => u.app_user.tna_department?.code === task.dept);
      const getProp = prop => obj => obj[prop];
      const getnames = getProp('username');
      var names = taskUsers.map(getnames);
    }
    names = ['', ...names]
    return names;
  }

  useEffect(() => {
    setChangedList(
      tasks.map((t) => {
        return { id: t.taskId, assignee: t.assignee };
      })
    );
  }, [tasks]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Task</StyledTableCell>
              <StyledTableCell align="left">Start Date</StyledTableCell>
              <StyledTableCell align="ltft">Due Date</StyledTableCell>
              <StyledTableCell align="left">Task User</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, id) => {
              return (
                <StyledTableRow>
                  <StyledTableCell align="left">
                    {task.taskName}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {task.dueStartDatetime}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {task.dueCompleteDatetime}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        disabled={id === 0}
                        disableClearable
                        id="combo-box-demo"
                        getOptionLabel={(user) => user }
                        options={ getOptions(task, id) }
                        value={
                          id === 0
                            ? current_user
                            : changedList && changedList.length > 0
                            ? changedList.filter((i) => i.id === task.taskId)[0]
                                ?.assignee
                            : ''
                        }
                        defaultValue={
                          id === 0
                            ? current_user
                            : getOptions(task, id)
                              .find((v) => v === task.assignee)
                        } 
                        onChange={(e, value) => { onChangeAssignee(e, value, task); }}
                        renderInput={
                          (params) => <TextField 
                            {...params} 
                            fullWidth 
                            InputProps={{ ...params.InputProps, disableUnderline: true }} 
                          />}
                        style={{ width: 200 }}
                      />
                    </FormControl>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Preview;
