export const operationLossAttributesSchema = {
    title: "",
    type: ["object", "null"],

    properties: {
        operationLosses: {
            type: "array",
            title: "Operation Loss",
            description: "click 'ADD ITEM' to add operation loss",
            // required: ["date"],
            items: {
                type: "array",
                title: "Operation Loss",
                items: [
                    {
                        type: "string",
                        title: "Reason",
                        description: "Enter reason"
                    },
                    {
                        type: "string",
                        description: "Select type",
                        enum: [
                            "Scheduled",
                            "Flow Stopper",
                        ],

                    },
                    {
                        type: "string",
                        required: [""],
                        format: "datetime",
                        description: "Start Date Time:",
                        title: "Date"
                    },
                    {
                        type: "string",
                        required: [""],
                        format: "datetime",
                        description: "End Date Time:",
                        title: "Date"
                    }
                ]

            }
        }
    },
}
    ;

