import React, { useEffect } from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  SaveButton,
  Toolbar,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  FormDataConsumer,
} from "react-admin";
import JsonSchemaEditor from "../../components/input/JsonSchemaEditor";
import { lineAttributesSchema } from "./lineAttributesSchema";
import { ToolTipForDeleteButton } from "../../components/ToolTipForDelete";
import { makeStyles } from "@material-ui/core/styles";
import { redirect } from "../../lib/redirect";
import { useCustomToast } from "../../hooks/useCustomToast";

const validate = (values) => {
  const errors = {};
  if (!values.plan_board_group) {
    errors.plan_board_group = "plan board group is required";
  }
  if (!values.calendar) {
    errors.calendar = "calendar Board Line is required";
  }
  if (!values.code) {
    errors.code = "code is required";
  }

  return errors;
};

export const EditPlanBoardLine = (props) => {
  const Title = ({ record }) => {
    return <span>Edit Line | {record ? `${record.code} ` : ""}</span>;
  };

  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.record &&
      props.location.state.record.plan_board
    ) {
      window.localStorage.setItem(
        "plan_board",
        props.location.state.record.plan_board
      );
    }
  }, []);

  const useStyles = makeStyles({
    toolbar: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 10,
      paddingRight: 10,
    },
  });
  const plan_board_id = window.localStorage.getItem("plan_board");

  const customToast = useCustomToast({ redirect: redirect });

  const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton className="btn_ btn_save" />
    </Toolbar>
  );
  return (
    <Edit
      {...props}
      title={<Title />}
      className="col-xl-6 col-sm-12"
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
      undoable={false}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <ReferenceInput
          label="Plan board group"
          source="plan_board_group"
          reference="boardGroup"
          filter={plan_board_id ? { plan_board: plan_board_id } : {}}
        >
          <SelectInput optionText="code" />
        </ReferenceInput>

        <TextInput source="code" />
        <TextInput source="initial_configuration" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.daily_quantity ? (
              <>
                <TextInput source="daily_quantity" />
                <TextInput source="daily_op_line_smv" />
              </>
            ) : (
              <>
                <TextInput source="efficiency" />
                <TextInput source="carder" />
              </>
            )
          }
        </FormDataConsumer>
        <BooleanInput source="skip_planning" />
        <TextInput source="sequence_number" />
        <NumberInput source="tool_rotation_time" />
        <TextInput source="compatible_tool_groups" />
        <TextInput source="supported_operation_groups" />
        <DateInput source="start_date" />
        <BooleanInput source="support_item_groups_by_default" />
        <JsonSchemaEditor schema={lineAttributesSchema} source="attributes" />
      </SimpleForm>
    </Edit>
  );
};
