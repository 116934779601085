import moment from "moment";
import { VariableSizeGrid as Grid, FixedSizeList as List } from "react-window";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "@rehooks/window-size";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import {
  getBuckets,
  getBucketsNoDateRange,
  getLines,
  getSelectedLineIndex,
  getSelectedTimeRange,
  getSelectedGroupId,
} from "../../../store/selectors";
import _, { Dictionary } from "lodash";
import {
  clearSelection,
  modelOpen,
  selectEntity,
  setSelectedTimeRange,
} from "../../../store/actions";
import styled from "styled-components";
import { IBucket, ILine } from "../../../lib/plan";
import Bucket from "./Bucket";

type PlanBoardCalendarProps = {
  height: number;
  width: number;
  setSplitSize: any;
};
const PlanBoardCalendar = ({
  height,
  width,
  setSplitSize,
}: PlanBoardCalendarProps) => {
  const buckets = useSelector(getBucketsNoDateRange);
  const selectedTimeRange = useSelector(getSelectedTimeRange);
  const lines = useSelector(getLines);
  const selectedGroupId = useSelector(getSelectedGroupId);
  const selectedLines = useMemo(
    () =>
      !selectedGroupId
        ? lines
        : lines.filter((line) => line.plan_board_group_id === selectedGroupId),
    [lines, selectedGroupId]
  );
  const lineIndexById = useMemo(
    () =>
      selectedLines.reduce(
        (res: any, line, i) => ({ ...res, [line.id]: i }),
        {} as any
      ),
    [selectedLines]
  );
  const selectedRowIndex = useSelector(getSelectedLineIndex);

  const selectedStartTime = selectedTimeRange.start_time;
  const selectedEndTime = selectedTimeRange.end_time;
  const startTime = useMemo(
    () => _.min(buckets.map((b) => b.start_time)),
    [buckets]
  ) as Date;
  const endTime = useMemo(
    () => _.max(buckets.map((b) => b.end_time)),
    [buckets]
  ) as Date;

  const dispatch = useDispatch();
  const handleLineClick = useCallback(
    (line_id: number) => {
      console.log("Line", line_id);
      dispatch(selectEntity("lines", line_id));
      dispatch(modelOpen());
    },
    [dispatch]
  );
  const plantBoardStartTime = moment(
    startTime ? startTime.valueOf() : undefined
  )
    .startOf("day")
    .toDate();

  const bucketsByIndex = useMemo(
    () =>
      _.chain(buckets)
        .groupBy((b) => lineIndexById[b.line_id])
        .mapValues((lineBuckets) =>
          _.groupBy(lineBuckets, (b) =>
            getNumberOfDays(plantBoardStartTime, b.start_time)
          )
        )
        .value(),
    [lineIndexById, buckets, plantBoardStartTime]
  );
  useEffect(
    () => setSplitSize(Math.min(20 + selectedLines.length * 41 + 10, 400)),
    [selectedLines, setSplitSize]
  );

  if (lines.length == 0 && buckets.length == 0) {
    return <div> No buckets or lines </div>;
  }

  return (
    <div>
      <Calendar
        onLineClick={handleLineClick}
        height={height}
        width={width}
        startTime={plantBoardStartTime}
        endTime={endTime || selectedEndTime}
        selectedEndTime={selectedEndTime}
        selectedStartTime={selectedStartTime}
        rowCount={selectedLines.length}
        bucketsByIndex={bucketsByIndex}
        selectedRowIndex={selectedRowIndex}
        selectedLines={selectedLines}
      />
    </div>
  );
};

export default PlanBoardCalendar;

type CalendarProp = {
  height: number;
  width: number;
  startTime: Date;
  endTime: Date;
  selectedStartTime: Date;
  selectedEndTime: Date;
  rowCount: number;
  selectedRowIndex: number;
  onLineClick: (line_id: number) => void;
  bucketsByIndex: Dictionary<Dictionary<IBucket[]>>;
  selectedLines: ILine[];
};

const CalendarContext = React.createContext({} as CalendarProp);

const getNumberOfDays = (from: number | Date, to: number | Date) =>
  moment(to).diff(from, "days");
export const CalendarDragDropContext = ({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) => <DndProvider backend={HTML5Backend}>{children}</DndProvider>;

const Calendar = React.memo((props: CalendarProp) => {
  const {
    height,
    width,
    startTime,
    endTime,
    selectedEndTime,
    selectedStartTime,
    rowCount,
    selectedRowIndex,
  } = props;
  const dayCount = moment(endTime).diff(startTime, "days") + 1;
  const headerRef = useRef(null as any);
  const rowHeaderRef = useRef(null as any);
  const gridRef = useRef(null as any);
  const dispatch = useDispatch();

  const selectedStartIndex = getNumberOfDays(startTime, selectedStartTime);
  const cellsInTheSelectedSection =
    getNumberOfDays(selectedStartTime, selectedEndTime) + 1;
  const rowHeaderWidth = 100;
  const headerHeight = 20;
  const gridWidth = width - rowHeaderWidth;
  const columnWidth = Math.max(
    Math.floor(gridWidth / cellsInTheSelectedSection),
    10
  );
  const getColumnWidth = useCallback(() => columnWidth, [columnWidth]);
  const rowHeight = 40;
  const getRowHeight = useCallback(() => rowHeight, []);
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollToItem({
        align: "start",
        columnIndex: selectedStartIndex,
      });
    }
    if (headerRef.current) {
      headerRef.current.scrollToItem(selectedStartIndex, "start");
    }
  }, [selectedStartIndex, cellsInTheSelectedSection]);

  const selection = useSelector(getSelection);
  useEffect(() => {
    if (selectedRowIndex >= 0 && gridRef.current && rowHeaderRef.current) {
      rowHeaderRef.current.scrollToItem(selectedRowIndex, "smart");
      gridRef.current.scrollToItem({
        align: "smart",
        rowIndex: selectedRowIndex,
      });
    }
  }, [selectedRowIndex, selection]);

  const handleTimeChange = useMemo(
    () =>
      _.debounce((startTime: number, endTime: number) => {
        dispatch(setSelectedTimeRange(startTime, endTime));
      }, 200),
    [dispatch]
  );

  const handleUpdateTime = useCallback(
    (scrollLeft) => {
      const startIndex = scrollLeft / columnWidth;
      if (startIndex <= 0) {
        return;
      }
      const newStartTime = moment(startTime).add(startIndex, "day");
      const newEndTime = moment(newStartTime).add(
        cellsInTheSelectedSection - 1,
        "day"
      );
      handleTimeChange(newStartTime.valueOf(), newEndTime.valueOf());
    },
    [columnWidth, startTime, cellsInTheSelectedSection, handleTimeChange]
  );

  const handleClickContainer = useCallback(
    () => dispatch(clearSelection()),
    [dispatch]
  );

  const onScrollGrid = useCallback(
    ({ scrollLeft, scrollTop, scrollUpdateWasRequested }) => {
      if (headerRef.current && !scrollUpdateWasRequested) {
        headerRef.current.scrollTo(scrollLeft);
      }
      if (rowHeaderRef.current && !scrollUpdateWasRequested) {
        rowHeaderRef.current.scrollTo(scrollTop);
      }
      if (!scrollUpdateWasRequested) {
        handleUpdateTime(scrollLeft);
      }
    },
    [headerRef, handleUpdateTime]
  );

  const onScrollHeader = useCallback(
    ({ scrollOffset, scrollUpdateWasRequested }) => {
      if (gridRef.current && !scrollUpdateWasRequested) {
        gridRef.current.scrollTo({ scrollLeft: scrollOffset });
      }
      if (!scrollUpdateWasRequested) {
        handleUpdateTime(scrollOffset);
      }
    },
    [gridRef, handleUpdateTime]
  );

  const onScrollRowHeader = useCallback(
    ({ scrollOffset, scrollUpdateWasRequested }) => {
      if (gridRef.current && !scrollUpdateWasRequested) {
        gridRef.current.scrollTo({ scrollTop: scrollOffset });
      }
    },
    [gridRef]
  );

  return (
    <CalendarContext.Provider value={props}>
      <List
        ref={headerRef}
        height={headerHeight}
        style={{ marginLeft: rowHeaderWidth }}
        width={gridWidth}
        itemCount={dayCount}
        itemSize={columnWidth}
        layout="horizontal"
        className="no-scrollbars"
        onScroll={onScrollHeader}
      >
        {HeaderCell}
      </List>
      <div>
        <GridContainer>
          <List
            ref={rowHeaderRef}
            width={rowHeaderWidth}
            height={height - 35} // This number is manually set considering the height of the scroll bars
            itemCount={rowCount}
            itemSize={rowHeight}
            layout="vertical"
            className="no-scrollbars"
            onScroll={onScrollRowHeader}
          >
            {RowHeaderCell}
          </List>
          <div onClick={handleClickContainer}>
            <Grid
              ref={gridRef}
              key={columnWidth}
              height={height - headerHeight}
              width={gridWidth}
              columnCount={dayCount}
              columnWidth={getColumnWidth}
              rowCount={rowCount}
              rowHeight={getRowHeight}
              onScroll={onScrollGrid}
            >
              {Cell}
            </Grid>
          </div>
        </GridContainer>
      </div>
    </CalendarContext.Provider>
  );
});

const GridContainer = styled.div`
  display: flex;
`;
//

// Header
const DateContent = styled.div`
  margin: 1px;
  border-radius: 2px 2px 0px 0px;
  text-align: center;
  background-color: lightblue;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
`;
const MonthContent = styled.div`
  padding: 2px;
  text-align: center;
  background-color: lightblue;
`;
type HeaderCellProp = {
  index: number;
  style: any;
};
const HeaderCell = React.memo(({ index, style }: HeaderCellProp) => {
  const calendarProps = useContext(CalendarContext);
  return (
    <div style={style}>
      {/* <MonthContent>
                {moment(calendarProps.startTime).add(index, 'days').format("MM")}
            </MonthContent> */}
      <DateContent>
        {moment(calendarProps.startTime)
          .add(index, "days")
          .format("DD/MM/YYYY")}
      </DateContent>
    </div>
  );
});

// Row Header
const RowTitleContainer = styled.div`
  padding: 1px;
  display: flex;
`;
const RowTitleContent = styled.div`
  background-color: lightblue;
  padding: 8px;
  border-radius: 2px 0px 0px 2px;
  flex-grow: 1;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  cursor: pointer;
  :hover {
    background-color: lightgreen;
  }
`;
type RowHeaderCellProp = {
  index: number;
  style: any;
};
const RowHeaderCell = React.memo(({ index, style }: RowHeaderCellProp) => {
  const calendarProps = useContext(CalendarContext);
  // const line = useSelector(getLines)[index];
  const line = calendarProps.selectedLines[index];
  const onClick = useCallback(() => {
    calendarProps.onLineClick(line.id);
  }, [line, calendarProps]);
  return (
    <RowTitleContainer onClick={onClick} style={style}>
      <RowTitleContent>{line.code}</RowTitleContent>
    </RowTitleContainer>
  );
});

// Cell
type CellProp = {
  columnIndex: number;
  rowIndex: number;
  style: any;
};

const EmptyDayCell = styled.div`
  text-align: center;
  color: gray;
`;
const CellContent = styled.div`
  overflow-x: visible;
  position: relative;
  height: 100%;
`;

const getWidth = (b: IBucket) => {
  return `${((b.end_time.valueOf() - b.start_time.valueOf()) * 100) / (1000 * 3600 * 24)
    }%`;
};

const getLeft = (b: IBucket, cellStartTime: any) => {
  return `${((b.start_time.valueOf() - cellStartTime.valueOf()) * 100) /
    (1000 * 3600 * 24)
    }%`;
};

const Cell = React.memo(({ columnIndex, rowIndex, style }: CellProp) => {
  const calendarProps = useContext(CalendarContext);
  const buckets = calendarProps.bucketsByIndex[rowIndex]
    ? calendarProps.bucketsByIndex[rowIndex][columnIndex]
    : [];
  const cellStartTime = moment(calendarProps.startTime).add(
    columnIndex,
    "days"
  );
  if (!buckets) {
    return (
      <div style={style}>
        <EmptyDayCell>-</EmptyDayCell>
      </div>
    );
  }

  return (
    <div style={style}>
      <CellContent>
        {buckets.map((b, i) => (
          <div
            key={i}
            style={{
              position: "absolute",
              width: getWidth(b),
              left: getLeft(b, cellStartTime),
              height: "100%",
              padding: 1,
              zIndex: 10,
            }}
          >
            <Bucket key={i} bucketId={b.id}></Bucket>
          </div>
        ))}
      </CellContent>
    </div>
  );
});
