import { PRIMARY_COLOR, SECONDARY_COLOR } from "../constants";

import { createMuiTheme } from "@material-ui/core";

// console.log(SECONDARY_COLOR)
export const theme = createMuiTheme({
  shadows: [...new Array(25)].map(
    (v) =>
      "0px 0px 0px 0px rgba(0,0,0,0),0px 1px 1px 0px rgba(0,0,0,0),0px 1px 3px 0px rgba(0,0,0,)"
  ),
  palette: {
    primary: {
      main: SECONDARY_COLOR || "#00acee", // this is the app bar color
      btnSecondary: PRIMARY_COLOR || "#c5edfd", //primaty button color before hovering
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#657786",
      main: "#657786",
      dark: "#657786",
      contrastText: "#ffffff",
    },
    background: {
      paper: "#F5F8FA",
    },
    warning: {
      main: "#f7b216",
    },
  },
  typography: {
    fontSize: 12,
    allVariants: {
      color: "#657786",
    },
    button: {
      textTransform: "none",
      color: "#ffffff",
    },
  },
  overrides: {
    MuiTableRow: {
      head: {
        position: "sticky",
        backgroundColor: "#fff",
        paddingLeft: 10,
        backgroundColor: "lightgray",
        zIndex: 999,
        "& > th ": {
          color: "black",
          backgroundColor: "lightgray",
          fontWeight: "bold",
          fontSize: "0.625rem",
        },
      },
    },

    sidebar: {
      width: 200, // The default value is 240
      closedWidth: 50, // The default value is 55
    },
    MuiListItemText: {
      primary: {
        fontSize: "0.625rem",
      },
      secondary: {
        // fontSize: '13px'
        fontSize: "0.625rem",
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      sizeSmall: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiAppBar: {},
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
  },
  props: {
    MuiList: {
      dense: true,
      disablePadding: true,
    },
    MuiButton: {
      size: "small",
    },
    MuiFilledInput: {
      margin: "dense",
    },
    MuiFormControl: {
      margin: "dense",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiListItem: {
      disablePadding: true,
    },
    MuiOutlinedInput: {
      margin: "dense",
    },
    MuiFab: {
      size: "small",
    },
    MuiTable: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
    },
    MuiToolbar: {
      variant: "dense",
    },
  },
});
