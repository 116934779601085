import React from "react";
import {
  Toolbar,
  BooleanInput,
  Create,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  SaveButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { redirect } from "../../lib/redirect";
import { ReqInputField } from "../../components/ReqInputField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useCustomToast } from "../../hooks/useCustomToast";

const validateOrder = (values) => {
  const errors = {};
  if (!values.final_item) {
    errors.final_item = "Final item is required";
  }
  if (!values.code) {
    errors.code = "Code is required";
  }
  if (!values.required_date) {
    errors.required_date = "Date is required";
  }
  if (!values.quantity) {
    errors.quantity = "Quantity is required";
  }

  return errors;
};

export const CreateOrder = (props) => {
  const customToast = useCustomToast({ redirect: redirect });
  return (
    <Create
      {...props}
      class={"col-md-6 mt-5"}
      onSuccess={customToast.onSuccess}
      onFaliure={customToast.onFaliure}
    >
      <SimpleForm
        toolbar={<CustomToolbar need={false} name="orders" text="Order" />}
        validate={validateOrder}
      >
        <ReferenceInput
          source="final_item"
          label={<ReqInputField name="final item" />}
          reference="items"
          sort={{ field: "code", order: "ASC" }}
          filterToQuery={searchText => ({ code: String(searchText) })}
        >
          <AutocompleteInput optionText="code"/>
        </ReferenceInput>
        <ReferenceInput
          source="order_group"
          label={<ReqInputField name="order group" />}
          reference="orderGroups"
          sort={{ field: "code", order: "ASC" }}
          filterToQuery={searchText => ({ code: String(searchText) })}
        >
          <AutocompleteInput optionText="code"/>
        </ReferenceInput>
        <TextInput source="code" label={<ReqInputField name="code" />} />
        <TextInput
          source="quantity"
          label={<ReqInputField name="quantity" />}
        />
        <BooleanInput source="skip_planning" />
        <BooleanInput source="is_dummy" />
        <DateTimeInput
          source="required_date"
          label={<ReqInputField name="required date" />}
        />
        <TextInput source="resolve_failed_reason" />
        <BooleanInput source="resolve_failed" />
      </SimpleForm>
    </Create>
  );
};
