import React from "react";

export const ReqInputField = ({ name }) => {
  return (
    <div>
      <p>
        <span style={{ color: "red" }}>*</span> {name}
      </p>
    </div>
  );
};
