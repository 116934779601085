import React, { useEffect } from 'react';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import './fileUpload.css'
import { useMutation, useRefresh, Mutation, FileInput, Loading, ImageField, useRedirect, FileField, SimpleForm } from 'react-admin';
import { showToastSuccess, showToastError } from "../../../lib/toas"
import { getTodoTasks, getProcessingTasks, getCompletedTasks, getTNATaskLoading } from "../../../store/selectors/tnaTaskSelectors";
import { Typography } from '@material-ui/core';
import { loadALLTasks } from "../../../store/actions/tnaTaskActions";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    imgThumb: {
        maxWidth: '800px',
        maxHeight: '800px'
    },
    fileTagContainer: {
        display: 'flex',
    },
    fileTag: {
        padding: theme.spacing(2)
    },
    close: {
        cursor: 'pointer',
        color: 'red',
        fontWeight: 'bold'

    }
}));



const FileUpload = ({ id = null, onLoad, initLoading, imageRestrict = false, imageCode = "" }) => {
    const classes = useStyles();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [mutate, { loading, onSuccess, onError }] = useMutation();
    const params = useParams()

    const [uploadedFile, setUploadedFile] = React.useState([]);
    const [isImage, setIsImage] = React.useState(false)
    // const [imageCode, setImageCode] = React.useState("")

    const [formData, setFormData] = React.useState(new FormData())

    let history = useHistory();
    if (loading) return <Loading />;

    const fileHandler = (event) => {
        event.persist();
        if (event.target?.files[0]) {
            if (imageRestrict) {
                setUploadedFile(event.target?.files[0]);
            } else {
                if (uploadedFile != undefined) {
                    setUploadedFile(uploadedFile => [...uploadedFile, event.target.files[0]]);
                }
            }

        } else {
            return false
        }

        const uploaded = event.target?.files[0]
        let uploadedFileType = uploaded?.type
        let uploaddedFileFormat = null

        setFormData(formData)
        if (uploadedFileType != undefined) {
            uploaddedFileFormat = uploadedFileType?.split("/")[1]
            uploadedFileType = uploadedFileType?.split("/")[0]
        }
        if (imageRestrict) {
            if (uploadedFileType == "image" && ["jpeg", "png", "jpg"]?.includes(uploaddedFileFormat)) {
                setIsImage(true)
                formData.append('file', uploaded)
            } else if ((uploadedFileType == "application")) {
                showToastError("Please Upload Images Only")
            }
        } else {
            if (uploadedFileType == "image") {
                setIsImage(true)
            } else if ((uploadedFileType == "application")) {
                setIsImage(false)
            }
        }

    };

    const submit = event => {
        if (imageRestrict) {
            if (isImage) {
                if (imageCode != "") {
                    formData.append("type", "priceListImage")
                    formData.append("imageCode", imageCode)
                } else {
                    showToastError("Please Give Image Code")
                    return null
                }

            } else {
                showToastError("Please Upload PNG or JPG Images Only")
                return null
            }
        } else {
            formData.append("templateItemTaskId", id)
            formData.append("type", "taskFileUpload")
            uploadedFile.forEach(file => formData.append('file', file))
        }
        mutate({
            type: 'TNA_FORM_ACTION',
            resource: 'itemTasks',
            payload: {
                action: "fileUpload",
                method: "POST",
                body: formData,
            }
        }, {
            onFailure: (e) => {
                showToastError("Upload Failed:" + e.body);
            },
            onSuccess: (e) => {
                showToastSuccess("Upload Success");
                setFormData(new FormData())
                setUploadedFile([])
                if (imageRestrict) {
                    redirect('/itemImageList');
                }
                console.log(uploadedFile)

            }
        });
    }

    const ImageThumb = ({ image }) => {
        return <img src={image ? URL.createObjectURL(image) : null} className={classes.imgThumb} alt={image?.name} />;
    };

    const UploadedAttachmentFile = ({ file }) => {
        const removeFile = (fileName) => {
            var copyUploadedFile = [...uploadedFile]
            var filteredFiles = copyUploadedFile.filter(x => x?.name != fileName)
            setUploadedFile(filteredFiles)
        }

        return (
            <div className={classes.fileTagContainer}>
                <div className={classes.fileTag}>{file?.name}</div>
                <div className={classes.close} data-file={file?.name} onClick={() => removeFile(file?.name)}>x</div>
            </div>
        )
    }



    return (
        <div>
            <div className="files">

                <label>Upload File of Proof</label>
                <input type="file" onChange={fileHandler} />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={submit}
                >
                    {" "}
                      Submit
                        </Button>

                <Grid item xs={12}>
                    {isImage && <ImageThumb image={uploadedFile[0]} />}
                    {(isImage == false && uploadedFile != null) ?
                        <Grid item xs={12}>
                            {uploadedFile.map(function (file) {
                                return (<UploadedAttachmentFile file={file}></UploadedAttachmentFile>)
                            })}
                        </Grid> : null}
                </Grid>



            </div>
        </div>
    );


}

const mapStateToProps = (state) => {
    const initLoading = getTNATaskLoading(state)

    return {
        initLoading
    }
}

const mapDispatchToProps = {
    onLoad: loadALLTasks
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);;