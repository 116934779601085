import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import BulkActionButton from "../../components/BulkActionButton";
import PromptButton from "../../components/PromptButton";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  clearSelection,
  deleteAndPlanAutomatic,
  deletePlan,
  exportPlanBoardData,
  loadPlanBoard,
  planAutomatic,
  publishPlanBoardRevision,
  redo,
  savePlanboardChanges,
  setSelectedGroup,
  setSelectedTimeRange,
  shiftTimeRangeBackward,
  shiftTimeRangeForward,
  toggleEditMode,
  undo,
} from "../../store/actions";
import {
  canRedo,
  canUndo,
  getChangedOrderCodes,
  getChangedOrders,
  getGroups,
  getIsEditing,
  getLines,
  getPlanBoard,
  getSelectedGroupId,
  getSelectedTimeRange,
  getSelection,
} from "../../store/selectors";
import ExportPopup from "./ExportPopup";
import PlanButton from "./PlanButton";
import { RevisionListPopupButton } from "./planRevision/RevisionListPopup";
import SelectedReasonInput from "./SelectedReasonInput";
import { usePermissions } from "react-admin";

const Actions = React.memo(function ({
  onSave,
  planBoardId: id,
  onUndo,
  onRedo,
  canUndo,
  canRedo,
  onPublish,
  selectedTimeRange,
  onTimeRangeChange,
  changedOrders,
  lines,
  onLoad,
  onPlan,
  onDeleteAndPlan,
  onDeletePlan,
  selectedGroup,
  setSelectedGroup,
  selectedOrderIds,
  onToggleEdit,
  isUserEditing,
  groups,
  shiftTimeRangeForward,
  shiftTimeRangeBackward,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { permissions } = usePermissions();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTimeRangeChange = (range) => {
    if (!range) {
      range = [new Date(), moment().add(4, "days")];
    }
    const formatted = range.map((v) => v.valueOf());
    onTimeRangeChange(formatted[0], formatted[1]);
  };

  const forwardTime = () => {
    // onTimeRangeChange(moment(selectedTimeRange.start_time).add(5, 'days'), moment(selectedTimeRange.end_time).add(5, 'days'))
    shiftTimeRangeForward();
  };

  const backwardTime = () => {
    // onTimeRangeChange(moment(selectedTimeRange.start_time).add(-5, 'days'), moment(selectedTimeRange.end_time).add(-5, 'days'))
    shiftTimeRangeBackward();
  };

  return (
    <CardActions style={{ fontSize: "13px" }}>
      {/* <PlanButton id={id} lines={lines} /> */}
      <Button
        // color="secondary"
        // className={
        //   isUserEditing === "user" ? "btn_ btn_secondry" : "btn_ btn_primary"
        // }
        className="btn_ btn_primary ml-0"
        style={{ marginRight: "20px" }}
        // variant={isUserEditing === "no" ? "outlined" : "contained"}
        title="Click to toggle edit mode (permissions)"
        onClick={onToggleEdit}
        disabled={
          typeof isUserEditing === "object" ||
          !permissions?.permissions.includes("plan_edit")
        } //is_user_editing is an array when another user is editing
      >
        {isUserEditing === "user" ? "Stop edit" : "Edit"}
      </Button>
      {typeof isUserEditing === "object" ? (
        <Typography color="secondary">{`*This planboard is locked. ${isUserEditing[1]} is editing this planboard.`}</Typography>
      ) : null}
      {/* <Box display="flex"><Typography>Edit:</Typography>
      <Switch
        // checked={}
        // onChange={}
        value="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      </Box>  */}
      <PlanBoardGroupSelector
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        groups={groups}
      />

      <Box
      // style={{ marginLeft: "20px", marginRight: "20px" }}
      >
        <PromptButton
          onAccept={onPublish}
          label="Publish"
          prompt="Are you sure to publish?"
          title="Click here to publish"
          disabled={
            canUndo || !permissions?.permissions.includes("plan_publish")
          }
        />
      </Box>
      {isUserEditing === "user" ? (
        <div
          style={{
            marginLeft: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <PlanButton
            id={id}
            lines={lines}
            onPlan={onPlan}
            onDeleteAndPlan={onDeleteAndPlan}
            onDeletePlan={onDeletePlan}
            planDisabled={canUndo}
            selectedOrderIds={selectedOrderIds}
          />
          {/* <Button onClick={onExport}>Export</Button> */}
          <Button
            className="btn_ btn_primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            variant="contained"
            onClick={handleClick}
            title="click hear to upload"
            disabled={!permissions?.permissions.includes("plan_uploads")}
          >
            Uploads
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <BulkActionButton
                title="Upload RM dates"
                meta={{ plan_board_id: id }}
                onSuccess={() => onLoad(id)}
                action="rm_dates"
                description="Upload RM ready dates in a csv file, in following order: Order No, Item No, RM Ready Date"
              />
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <BulkActionButton
                meta={{ plan_board_id: id }}
                action="skip_demand_operations"
                onSuccess={() => onLoad(id)}
                title="Upload Skipped Orders"
                description="Upload skipped orders in a csv file, in following order: Order No, Skip quantity"
              />
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>
              <UploadCsvButton
                planBoardId={id}
                onSuccess={() => onLoad(id)}
                resource="planBoards"
                action="save_rm_dates"
                title="Upload rm ready dates"
              />
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <UploadCsvButton
                planBoardId={id}
                onSuccess={() => onLoad(id)}
                resource="planBoards"
                action="skip_orders"
                title="Upload Skipped Orders"
                description="Upload skipped orders in a csv file, in following order: Order No, Skip quantity"
              />
            </MenuItem> */}
          </Menu>
          <Button
            title="click here to undo"
            onClick={onUndo}
            disabled={!canUndo}
            className="btn_ btn_primary"
          >
            {/* variant="outlined" */}
            Undo
          </Button>
          <Button
            title="click here to redo"
            onClick={onRedo}
            disabled={!canRedo}
            className="btn_ btn_primary"
          >
            Redo
          </Button>
          <SaveChangesButton
            onSave={onSave}
            changedOrders={changedOrders}
            canSave={canUndo}
          />
        </div>
      ) : null}
      <Box style={{ marginLeft: "5px", marginRight: "5px" }}>
        <ButtonGroup variant="outlined" style={{ margin: 5 }} color="primary">
          <DateRangePicker
            format="dd/MM/yyyy"
            onChange={
              handleTimeRangeChange
              // date => {
              // setPlanBoardPeriod({ start_time: moment(date[0].valueOf()), end_time: moment(date[1].valueOf()) })
              // setPlanBoardPeriod({
              //   ...planBoardPeriod,
              //   "start_time": date ? moment(date[0]).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
              //   "end_time": date ? moment(date[1]).format('YYYY-MM-DD') : moment().add(7, 'days').format('YYYY-MM-DD')
              // });
              // }
            }
            value={[
              new Date(selectedTimeRange.start_time),
              new Date(selectedTimeRange.end_time),
            ]}
            style={{ zIndex: 100000, origin: 0 }}
            calendarIcon={<CalendarTodayIcon />}
            clearIcon={<CloseIcon />}
          />

          <Button
            endIcon={<NavigateBeforeIcon fontSize="large" />}
            onClick={backwardTime}
            className="m-0"
          ></Button>
          <Button
            startIcon={<NavigateNextIcon fontSize="large" />}
            onClick={forwardTime}
          ></Button>
        </ButtonGroup>
      </Box>
      {/* <Button //onClick={onExport} 
      variant="outlined" >Export</Button> */}
      <Box
      // style={{ marginLeft: "20px", marginRight: "20px" }}
      >
        <ExportPopup />
      </Box>

      <RevisionListPopupButton planBoardId={id} />
    </CardActions>
  );
});

const SaveChangesButton = ({ onSave, changedOrders, canSave }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});

  const { permissions } = usePermissions();

  const onChange = (order, reason, input) => {
    setData((data) => ({ ...data, [order]: { reason, input } }));
    // setData({ ...data, [order]: { reason, input}})
  };

  return (
    <div>
      <Button
        className="btn_ btn_primary"
        variant="contained"
        disabled={
          !canSave ||
          !permissions?.permissions.includes("plan_edit_saveChanges")
        }
        onClick={() => setIsOpen(true)}
        title="click here to save changes"
      >
        Save Changes
      </Button>
      {/*disable when no changes */}
      <Dialog
        fullWidth
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <DialogTitle id="alert-dialog-title">
              {"Please state the reason for the following changes"}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <CloseIcon onClick={() => setIsOpen(false)} />
            </div>
          </Grid>
        </Grid>

        <DialogContent>
          {/* <div> */}
          {/* {JSON.stringify(changedOrders)} */}
          {/* Please state the reason for the following changes
          </div> */}
          {/* <br /> */}
          <form>
            {changedOrders.map((order, index) => (
              <div key={order}>
                {/* <div>{order} : </div> */}
                {/* <Input
                  type="text"
                  placeholder="Reason"
                  name="search"
                  onChange={e => onChange(order, e)}
                  required={true}
                  value={data[order] || " "}
                /> */}
                <SelectedReasonInput onChange={onChange} order={order} />
              </div>
            ))}
            {/* {JSON.stringify(data)} */}
          </form>
          <br />

          <DialogActions>
            {/* <Button
              variant="contained"
              className="btn_ btn_primary"
              color="primary"
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button> */}
            <Button
              variant="contained"
              className="btn_ btn_secondry"
              onClick={() => {
                onSave(data);
                setIsOpen(false);
              }}
            >
              Save Changes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const PlanBoardGroupSelector = React.memo(
  ({ groups, setSelectedGroup, selectedGroup }) => {
    const handleChange = (event) => {
      const { value } = event.target;
      setSelectedGroup(value);
    };
    return (
      <>
        {groups.length > 1 ? (
          <>
            <Typography component={"div"}>Machine: </Typography>
            <Select
              style={{ marginRight: 10, fontSize: 15, width: 75 }}
              onChange={handleChange}
              value={selectedGroup}
            >
              <MenuItem style={{ fontSize: 15 }} key="0" value={false}>
                All
              </MenuItem>
              {groups.map((v) => (
                <MenuItem style={{ fontSize: 15 }} key={v.id} value={v.id}>
                  {v.code}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
);

const mapStateToProps = (state) => {
  const isUserEditing = getIsEditing(state);
  return {
    planBoardId: getPlanBoard(state)?.id,
    selectedTimeRange: getSelectedTimeRange(state),
    changedOrders: getChangedOrderCodes(state),
    canUndo: canUndo(state),
    canRedo: canRedo(state),
    lines: getLines(state),
    isUserEditing,
    groups: getGroups(state),
    selectedGroup: getSelectedGroupId(state),
  };
};

const mapDispatchToProps = {
  onLoad: loadPlanBoard,
  onSave: savePlanboardChanges,
  onUndo: undo,
  onRedo: redo,
  onTimeRangeChange: setSelectedTimeRange,
  onExport: exportPlanBoardData,
  onPlan: planAutomatic,
  onDeleteAndPlan: deleteAndPlanAutomatic,
  onDeletePlan: deletePlan,
  onToggleEdit: toggleEditMode,
  setSelectedGroup: setSelectedGroup,
  onClear: clearSelection,
  onPublish: publishPlanBoardRevision,
  shiftTimeRangeForward: shiftTimeRangeForward,
  shiftTimeRangeBackward: shiftTimeRangeBackward,
};
export default connect(mapStateToProps, mapDispatchToProps)(Actions);
