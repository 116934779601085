import { put, takeEvery } from "redux-saga/effects";
import dataProvider from "../../api/dataProvider";
import { showToastError, showToastSuccess, showToastWarning } from "../../lib/toas";
import { bulkActionSaveError, bulkActionSaveSuccess } from "../actions/bulkActions";
import { BULK_ACTION_SAVE } from "../types";

//toast.configure();

export function* bulkActionSaga() {
    yield takeEvery(BULK_ACTION_SAVE, function* (action: any) {
        const { data, meta = {}, key } = action.payload
        try {
            const resp = yield dataProvider.CUSTOM("bulkAction", {
                id: key,
                method: "POST",
                action: `upload`,
                body: { meta, data },
            });
            const { success } = resp.data
            if (success) {
                showToastSuccess("Upload success")
            } else {
                showToastWarning("Upload finished with errors.")
            }
            yield put(bulkActionSaveSuccess(key, resp.data.data))
        } catch (error) {
            showToastError("Upload failed")
            yield put(bulkActionSaveError(key, error))
        }
    })
}
