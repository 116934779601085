//loading
export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const LOAD_NOTIFICATIONS_SUCCESS = "LOAD_NOTIFICATIONS_SUCCESS";
export const LOAD_STATUS_NOTIFICATIONS = "LOAD_STATUS_NOTIFICATIONS";
export const LORD_READ_NOTIFICATIONS = "LORD_READ_NOTIFICATIONS";
//set
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_STATUS_NOTIFICATIONS = "SET_STATUS_NOTIFICATIONS";
//check
export const CHECK_NOTIFICATIONS = "CHECK_NOTIFICATIONS";
//read many
export const READ_NOTIFICATION_OLD = "READ_NOTIFICATION_OLD";
// read one
export const READ_NOTIFICATION = "READ_NOTIFICATION";